import { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { useAppSelector, useAppDispatch } from '@gym-redux/store';
import GymHeader from '@gym-organisms/GymHeader/GymHeader';
import { GymDetailsHeaderTabNames } from '@gym-particles/types/GymDetails';
import { getGymById } from '@gym-redux/slices/gymsSlice';
import { UserRole } from '@gym-particles/types/User';

const MainColumn = () => {
  const { chainId, gymId, tab } = useParams<{ chainId: string; tab: string; gymId: string }>();
  return (
    <GymHeader
      currentGymChainId={+chainId}
      currentGymId={+gymId}
      currentTab={tab as GymDetailsHeaderTabNames}
    />
  );
};

const GymDetailsPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { chainId, gymId } = useParams<{ chainId: string; gymId: string }>();
  const dispatch = useAppDispatch();

  const gymName = useAppSelector(
    (state) =>
      state.gyms.items.find((g) => g.chainId === +chainId)?.items.find((a) => a.gymId === +gymId)
        ?.gymName
  );
  const fetchFailed = useAppSelector((state) => state.gyms.fetchGymByIdFailed);
  const userRole = useAppSelector((state) => state.user.userRole);

  /** Fetches the gym for this page */
  useEffect(() => {
    dispatch(getGymById({ gymId: +gymId, gymChainId: +chainId }));
  }, []);

  /** When an invalid gym ID is entered, redirect to gyms list */
  useEffect(() => {
    if (fetchFailed) {
      history.push(`/gymChains/${chainId}/gyms`);
    }
  }, [fetchFailed]);

  return (
    <TwoColTemplate
      title={gymName || t('COMMON.LOADING')}
      col1={<MainColumn />}
      alertRef={alertRef}
      backButtonHandler={() => {
        userRole !== UserRole.GYM_USER || chainId === '4'
          ? history.push(`/gymChains/${chainId}/gyms`)
          : history.push('/home');
      }}
      showBackButton={userRole !== UserRole.GYM_USER || chainId === '4' ? true : false}
      gymChainId={+chainId}
    />
  );
};

export default GymDetailsPage;
