import { useEffect, useRef, useState } from 'react';
import { Card } from 'primereact/card';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { AlertType } from '@gym-atoms/Alert/Alert';
import DashboardCounts from '@gym-molecules/DashboardCounts/DashboardCounts';
import { useAppSelector, useAppDispatch } from '@gym-redux/store';
import { MembershipsListTable } from './GymChainMembershipList';
import { GymListTable } from './GymsOfGymChain';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';
import { GymChainGymSchedulesList } from './GymChainGymSchedulesList';
import GymChainActivity from './GymChainActivity';
import GymChainTasks from './GymChainTasks';
import { fetchMyTaskCountForSite } from '@gym-redux/slices/gymChainActivitySlice';
import ProvisionUserPage from './ProvisionUserPage';
import TabView from '@gym-atoms/TabView/TabView';
import Loader from '@gym-atoms/Loader/Loader';
import { Settings } from './Settings';
import { UserRole } from '@gym-particles/types/User';
import GymChainPricingRelationships from './GymChainPricingRelationships';

const MainColumn = () => {
  const { chainId, tab } = useParams<{ chainId: string; tab: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const userId = useAppSelector((state) => state.user.userId);
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((state) => state.user.userRole);
  const [activeTab, setActiveTab] = useState(1);
  const userRoleId = useAppSelector((state) => state.user.roleId);
  const gymChainMembershipsImported = useAppSelector(
    (state) => state.gymChain.items.find((g) => g.id === +chainId)?.membershipsImported
  );
  console.log(activeTab);

  const currentGymChain = useAppSelector((state) => state.gymChain.currentGymChain);
  const currentGymChainPending = useAppSelector((state) => state.gymChain.fetchGymChainByIdPending);

  const gymChainProviderConnected = useAppSelector(
    (state) => state.gymChain.items.find((g) => g.id === +chainId)?.providerConnected
  );

  const gymChainProviderId = useAppSelector(
    (state) => state.gymChain.items.find((g) => g.id === +chainId)?.providerId
  );
  const storedChainId = parseInt(window.localStorage.getItem('chainId') || '-1');
  const storedProviderId = parseInt(window.localStorage.getItem('providerId') || '-1');
  const [isCountChanged, setIsCountChanged] = useState(false);
  const tabNames = [
    'activity',
    'inquiries',
    'gyms',
    'memberships',
    gymChainProviderId === 1 || (storedChainId === +chainId && +storedProviderId === 1)
      ? 'pricingRelationships'
      : undefined,
    'gymSchedules',
    gymChainProviderId === 1 || (storedChainId === +chainId && +storedProviderId === 1)
      ? 'settings'
      : undefined
  ].filter((tab) => tab);

  useEffect(() => {
    const index = tabNames.findIndex((name) => name === tab);
    if (index === -1) {
      history.push(`/gymChains/${chainId}/gyms`);
    } else {
      userRole === UserRole.GYM_USER ? setActiveTab(0) : setActiveTab(index);
    }
  }, [tab, tabNames]);

  useEffect(() => {
    window.localStorage.setItem('chainId', chainId.toString() || '');
    window.localStorage.setItem('providerId', gymChainProviderId?.toString() || '');
  }, [chainId, gymChainProviderId]);

  useEffect(() => {
    dispatch(
      fetchMyTaskCountForSite({
        userId: userId,
        roleId: userRoleId,
        siteId: +chainId,
        platformAdmin: userRole === UserRole.SYSTEM_ADMIN
      })
    );
  }, [userId, chainId, isCountChanged]);

  const myTasksCount = useAppSelector((state) => state.gymChainActivity.myTaskCount);

  const tabViewItems = [
    {
      header: t('GYM_LIST.GYM_LIST_PAGE.TAB_VIEW.ACTIVITY'),
      content: <GymChainActivity countChange={setIsCountChanged} isCountChanged={isCountChanged} />
    },
    {
      header: t('GYM_LIST.GYM_LIST_PAGE.TAB_VIEW.INQUIRIES'),
      content: <GymChainTasks countChange={setIsCountChanged} isCountChanged={isCountChanged} />,
      count: myTasksCount || undefined
    },
    {
      header: t('GYM_LIST.GYM_LIST_PAGE.TAB_VIEW.GYMS'),
      content: <GymListTable gymChainId={+chainId} />
    },
    {
      header: t('GYM_LIST.GYM_LIST_PAGE.TAB_VIEW.MEMBERSHIPS'),
      content: (
        <MembershipsListTable
          chain={+chainId}
          membershipsImported={gymChainMembershipsImported ? true : false}
        />
      )
    }
  ];

  const gymUserTabViewItems = [
    {
      header: t('GYM_LIST.GYM_LIST_PAGE.TAB_VIEW.GYMS'),
      content: <GymListTable gymChainId={+chainId} />
    }
  ];

  if (gymChainProviderId === 1) {
    tabViewItems.push({
      header: t('GYM_LIST.GYM_LIST_PAGE.TAB_VIEW.PRICING_RELATIONSHIPS'),
      content: <GymChainPricingRelationships />
    });
  }
  tabViewItems.push({
    header: t('GYM_LIST.GYM_LIST_PAGE.TAB_VIEW.GYM_SCHEDULES'),
    content: <GymChainGymSchedulesList gymChainId={+chainId} />
  });
  if (gymChainProviderId === 1) {
    tabViewItems.push({
      header: t('GYM_LIST.GYM_LIST_PAGE.TAB_VIEW.SETTINGS'),
      content: <Settings gymChainId={+chainId} />
    });
  }

  const isLoading = useAppSelector((state) => state.gymChain.isGymchainsLoading);

  return (
    <Card className="p-card-w-table">
      {isLoading || currentGymChainPending ? (
        <div className="mt10">
          <div className="d-flex justify-content-center">
            <div className="mb20 mt50">
              <Loader shape={'circle'} radius={'200px'} />
            </div>
          </div>
          <div className="mt10 d-flex justify-content-center">
            <Loader shape="rectangle" width="half" />
          </div>
          <div className="row mt20  d-flex justify-content-center">
            <div className="col-3 d-flex justify-content-center mb25">
              <Loader shape="rectangle" width="full" />
            </div>
            <div className="col-3 d-flex justify-content-center mb25">
              <Loader shape="rectangle" width="full" />
            </div>
          </div>
        </div>
      ) : currentGymChain?.providerConnected || gymChainProviderConnected ? (
        <TabView
          items={userRole === UserRole.GYM_USER ? gymUserTabViewItems : tabViewItems}
          activeTab={activeTab}
          onTabChange={(e) => {
            history.push(`/gymChains/${chainId}/${tabNames[e.index]}`);
          }}
        />
      ) : (
        <ProvisionUserPage siteId={+chainId} />
      )}
    </Card>
  );
};

const SecondColumn = () => {
  const todayUserCount = 200;
  const activeUserCount = 300532;
  const errorCount = 30;

  return (
    <div>
      <DashboardCounts
        todayUserCount={todayUserCount}
        activeUserCount={activeUserCount}
        errorCount={errorCount}
      />
    </div>
  );
};

const ListGymsPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { chainId } = useParams<{ chainId: string }>();
  const dispatch = useAppDispatch();

  const gymChainName = useAppSelector(
    (state) => state.gymChain.items.find((g) => g.id === +chainId)?.name
  );
  const fetchFailed = useAppSelector((state) => state.gymChain.fetchGymChainByIdFailed);

  const currentChain = useAppSelector((state) => state.gymChain.currentGymChain);

  /** Fetches the gym chain for this page */
  useEffect(() => {
    dispatch(getGymChainById(+chainId));
  }, []);

  /** When an invalid gym chain ID is entered, redirect to gymChains list */
  useEffect(() => {
    if (fetchFailed) {
      history.push('/gymChains');
    }
  }, [fetchFailed]);

  return (
    <TwoColTemplate
      title={gymChainName || (currentChain && currentChain.name) || t('COMMON.LOADING')}
      col1={<MainColumn />}
      //--Sodvin Release--
      //col2={<SecondColumn />}
      alertRef={alertRef}
      backButtonHandler={() => history.push('/gymChains')}
    />
  );
};

export default ListGymsPage;
