import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import type { Gym, SiteLocation } from '@gym-src/API';
import type { GymType, PaginatedQueryParams } from '@gym-particles/types/models';
import { deleteGym as deleteGymMutation } from '@gym-graphql/mutations';
import { getGymsOfGymChain, getGymById as getGymByIdQuery, getGyms } from '@gym-graphql/queries';
dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isGymsLoading: boolean;
  items: Array<{
    chainId: number;
    items: GymType[];
    totalRecords: number;
  }>;
  fetchGymByIdFailed: boolean;
} = {
  isGymsLoading: false,
  items: [],
  fetchGymByIdFailed: false
};

type paramType = {
  id?: number;
  pagination: PaginatedQueryParams;
};

export const fetchGyms = createAsyncThunk('/gym/getGyms', async (params: paramType) => {
  const response = await (API.graphql({
    query: getGymsOfGymChain,
    variables: {
      id: params.id,
      offset: params.pagination.offset,
      pageSize: params.pagination.pageSize,
      sortField: params.pagination.sortField,
      sortOrder: params.pagination.sortOrder === 1 ? 'ASC' : 'DESC',
      search: {
        searchField: params.pagination.search?.searchField,
        searchText: params.pagination.search?.searchText
      },
      userId: params.pagination.userId
    }
  }) as Promise<{
    data: {
      getGymsOfGymChain: {
        items: Gym[];
        totalRecords: { totalRecords: number };
      };
    };
  }>);
  return response;
});

export const fetchGymsbyUserId = createAsyncThunk(
  '/gym/getGymsbyUserId',
  async (params: { userId: number }) => {
    const response = await (API.graphql({
      query: getGyms,
      variables: {
        offset: 0,
        pageSize: 10,
        sortField: 'gymChainId',
        sortOrder: 'DESC',
        search: {
          searchField: '',
          searchText: ''
        },
        userId: params.userId
      }
    }) as Promise<{
      data: {
        getGyms: { items: Gym[]; totalRecords: { totalRecords: number } };
      };
    }>);
    return response;
  }
);

export const deleteGym = createAsyncThunk('SiteLocation/deleteGym', async (id: number) => {
  const response = await (API.graphql({
    query: deleteGymMutation,
    variables: {
      id: id
    }
  }) as Promise<{
    data: { deleteGym: SiteLocation };
  }>);
  return response;
});

export const getGymById = createAsyncThunk(
  'gym/getGymById',
  async (params: { gymId: number; gymChainId: number }) => {
    const response = await (API.graphql({
      query: getGymByIdQuery,
      variables: {
        id: params.gymId
      }
    }) as Promise<{
      data: { getGymById: Gym[] };
    }>);
    return response;
  }
);

export const gymSlice = createSlice({
  name: 'gym',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGyms.fulfilled, (state, action) => {
      state.isGymsLoading = false;
      if (action.payload.data.getGymsOfGymChain.items === null) {
        return;
      }

      const items = action.payload.data.getGymsOfGymChain.items.map(getFormattedGym);
      const totalRecords = action.payload.data.getGymsOfGymChain.totalRecords.totalRecords;
      const chainId = action.meta.arg.id;
      const fetchedGymChainInfo = {
        chainId: chainId,
        totalRecords: totalRecords,
        items: items
      };
      if (state.items.length === 0) {
        state.items.push(fetchedGymChainInfo);
      } else {
        // Check if we are updating an already fetched gym chain or fetching new one
        const update = state.items.find(
          (gymChainInfo) => gymChainInfo.chainId === fetchedGymChainInfo.chainId
        );
        if (update) {
          const newState = state.items.map((gymChainInfo) => {
            if (gymChainInfo.chainId === fetchedGymChainInfo.chainId) {
              return fetchedGymChainInfo;
            }
            return gymChainInfo;
          });
          Object.assign(state.items, newState);
        } else {
          state.items.push(fetchedGymChainInfo);
        }
      }
    });

    builder.addCase(fetchGyms.pending, (state, action) => {
      state.isGymsLoading = true;
      return state;
    });
    builder.addCase(fetchGymsbyUserId.fulfilled, (state, action) => {
      state.isGymsLoading = false;
      if (action.payload.data.getGyms.items === null) {
        return;
      }

      const items = action.payload.data.getGyms.items.map(getFormattedGym);
      const totalRecords = action.payload.data.getGyms.totalRecords.totalRecords;
      const chainId = action.payload.data.getGyms.items[0].gymChainId;
      const fetchedGymChainInfo = {
        chainId: chainId,
        totalRecords: totalRecords,
        items: items
      };
      console.log({ fetchedGymChainInfo });
      if (state.items.length === 0) {
        state.items.push(fetchedGymChainInfo);
      } else {
        // Check if we are updating an already fetched gym chain or fetching new one
        const update = state.items.find(
          (gymChainInfo) => gymChainInfo.chainId === fetchedGymChainInfo.chainId
        );
        if (update) {
          const newState = state.items.map((gymChainInfo) => {
            if (gymChainInfo.chainId === fetchedGymChainInfo.chainId) {
              return fetchedGymChainInfo;
            }
            return gymChainInfo;
          });
          Object.assign(state.items, newState);
        } else {
          state.items.push(fetchedGymChainInfo);
        }
      }

      console.log(state.items[0].items);
    });

    builder.addCase(fetchGymsbyUserId.pending, (state, action) => {
      state.isGymsLoading = true;
      return state;
    });

    builder.addCase(deleteGym.fulfilled, (state, action) => {
      state.isGymsLoading = false;
      const gymId = action.meta.arg;
      const filtered = state.items.map((gymObj) => {
        const filteredGym = gymObj.items.filter((gym) => gym.gymId !== gymId);
        gymObj.items = filteredGym;
        gymObj.totalRecords = gymObj.totalRecords - 1;
        return gymObj;
      });

      state.items = filtered;
    });

    builder.addCase(deleteGym.pending, (state, action) => {
      state.isGymsLoading = true;
      return state;
    });

    builder.addCase(getGymById.fulfilled, (state, action) => {
      const fetchedData = action.payload.data.getGymById;
      const gymChainId = action.meta.arg.gymChainId;
      const gymId = action.meta.arg.gymId;

      if (fetchedData.length === 0) {
        // TO-DO: inform user about the error (When alert system is implemented).
        state.fetchGymByIdFailed = true;
        console.error(`Failed to fetch gym with id: ${gymId}`);
        return state;
      }
      state.fetchGymByIdFailed = false;

      const parsedGym = getFormattedGym(fetchedData[0]);

      // Search for relevant object in the array
      let gymInfoObjectPresent = false;
      const newStore = state.items.map((gymInfoObject) => {
        if (gymInfoObject.chainId === gymChainId) {
          // If the object is already there, check its gyms
          // and either replace or push the fetched gym
          gymInfoObjectPresent = true;
          let gymPresent = false;
          const newGymInfoItems = gymInfoObject.items.map((gymObject) => {
            if (gymObject.gymId === gymId) {
              gymPresent = true;
              return parsedGym;
            }
            return gymObject;
          });
          if (!gymPresent) {
            newGymInfoItems.push(parsedGym);
          }
          gymInfoObject.items = newGymInfoItems;
        }
        return gymInfoObject;
      });

      // If the relevant object is not there, create one
      if (!gymInfoObjectPresent) {
        const gymInfoObject = {
          chainId: gymChainId,
          items: [parsedGym],
          totalRecords: 1
        };
        newStore.push(gymInfoObject);
      }

      state.items = newStore;
      return state;
    });
    builder.addCase(getGymById.rejected, (state, action) => {
      state.fetchGymByIdFailed = true;
      return state;
    });
  }
});

/** Formats a gym from the GraphQL type to the type used in the React app */
const getFormattedGym = (gym: Gym): GymType => {
  return {
    gymChainId: gym.gymChainId || 0,
    gymChainName: gym.gymChainName || '',
    gymId: gym.gymId || 0,
    gymIdApi: gym.gymIdApi || 0,
    gymName: gym.gymName || '',
    contactPerson: gym.contactPerson || '',
    email: gym.email || '',
    phoneNumber: gym.phoneNumber || '',
    address: gym.address || '',
    status: gym.status || '',
    createdDate: gym.createdDate ? dayjs(gym.createdDate || '').format('DD MMM YYYY') : '',
    createdBy: gym.createdBy || 0,
    lastModifiedDate: gym.lastModifiedDate || '',
    lastModifiedBy: gym.lastModifiedBy || 0,
    lastImportedDate: gym.lastImportedDate ? dayjs().to(dayjs.utc(gym.lastImportedDate || '')) : '',
    imageUrl: gym.imageUrl || '',
    // try to find a way to refactor these
    city: gym.city || undefined,
    latitude: gym.latitude || undefined,
    longitude: gym.longitude || undefined,
    twitterLink: gym.twitterLink || undefined,
    websiteLink: gym.websiteLink || undefined,
    facebookLink: gym.facebookLink || undefined,
    instagramLink: gym.instagramLink || undefined,
    trialMembershipId:
      gym.trialMembershipId === -1 ? undefined : gym.trialMembershipId || undefined,
    membershipOnSignup: gym.membershipOnSignup || undefined,
    membershipForVisits: gym.membershipForVisits || undefined,
    syncAllVisitsWithGenericSubscription: gym.syncAllVisitsWithGenericSubscription || undefined,
    primaryAccessMethod: gym.primaryAccessMethod || undefined,
    externalBookingsLink: gym.externalBookingsLink || undefined,
    gracePeriodWhenExpired: gym.gracePeriodWhenExpired || 0,
    accessPerDayWhenExpired: gym.accessPerDayWhenExpired || 0,
    gracePeriodWhenDeclined: gym.gracePeriodWhenDeclined || 0,
    accessPerDayWhenDeclined: gym.accessPerDayWhenDeclined || 0,
    gracePeriodWhenSuspended: gym.gracePeriodWhenSuspended || 0,
    accessPerDayWhenSuspended: gym.accessPerDayWhenSuspended || 0,
    isMobileCredentialAllowed: gym.isMobileCredentialAllowed ? true : false,
    defaultLocationDetectionMethod: gym.defaultLocationDetectionMethod || undefined,
    handbackTime: gym.handbackTime || undefined,
    signUpUrl: gym.signUpUrl || undefined,
    arxReferenceId: gym.arxReferenceId || undefined,
    staffMembershipId: gym.staffMembershipId || undefined,
    sodvinCompanyId: gym.sodvinCompanyId || '',
    displayStatus: gym.status ? gym.status.charAt(0).toUpperCase() + gym.status.slice(1) : '',
    allowConvertingAccessCards: gym.allowConvertingAccessCards ? true : false,
    cardType: gym.cardType || '',
    addArrivalAuto: gym.addArrivalAuto ? true : false,
    addVisitAuto: gym.addVisitAuto ? true : false,
    prioratizeClasses: gym.prioratizeClasses ? true : false,
    sessionCheckTimeLimit: gym.sessionCheckTimeLimit || 0,
    sessionCheckTimeLimitUpper: gym.sessionCheckTimeLimitUpper || 0,
    timeZone: gym.timeZone || undefined,
    blockTime: gym.blockTime || 0,
    markArrivalFirst: gym.markArrivalFirst ? true : false,
    openWithoutArrivalMarked: gym.openWithoutArrivalMarked ? true : false,
    markMultipleAttendanceAuto: gym.markMultipleAttendanceAuto ? true : false,
    markArrivalsWhenThereAreVisits: gym.markArrivalsWhenThereAreVisits || false
  };
};

export default gymSlice.reducer;
