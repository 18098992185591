import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { getWebUsers } from '@gym-graphql/queries';
import { WebUser } from '@gym-src/API';
import { UserType } from '@gym-particles/types/models';

const initialState: { items: Array<UserType>; totalRecords: number; isUserLoading: boolean } = {
  items: [],
  totalRecords: 0,
  isUserLoading: false
};

export const fetchUsers = createAsyncThunk(
  '/userManagement/getWebUsers',
  async (userId: number) => {
    const response = await (API.graphql({
      query: getWebUsers,
      variables: {
        userId: userId
      }
    }) as Promise<{
      data: { getWebUsers: { items: WebUser[]; totalRecords: { totalRecords: number } } };
    }>);
    return response;
  }
);

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isUserLoading = false;
      if (action.payload.data.getWebUsers.items === null) {
        return;
      }

      state.items = action.payload.data.getWebUsers.items.map(
        (e: WebUser): UserType => {
          return {
            userId: e.userId || 0,
            memberId: e.memberId || 0,
            firstName: e.firstName || '',
            lastName: e.lastName || '',
            name: (e.firstName || '') + ' ' + (e.lastName || ''),
            phone: e.phone || '',
            email: e.email || '',
            roleId: e.roleId || -1,
            isActive: e.isActive || false,
            createdDate: e.createdDate || '',
            createdBy: e.createdBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            profilePicturePath: e.profilePicturePath || '',
            roleName: e.roleName || '',
            siteId: e.siteId || 0,
            siteName: e.siteName || '',
            siteLocationId: e.siteLocationId || 0,
            siteLocationName: e.siteLocationName || '',
            language: e.language || '',
            memberArxReferenceId: e.memberArxReferenceId || ''
          };
        }
      );
      state.totalRecords = action.payload.data.getWebUsers.totalRecords.totalRecords;
      return state;
    });
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.isUserLoading = true;
      return state;
    });

    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.isUserLoading = false;
      return state;
    });
  }
});

export default userManagementSlice.reducer;
