import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import TwoColTemplate from '../../templates/TwoColTemplate/TwoColTemplate';
import { AlertType } from '../../atoms/Alert/Alert';
import GymChainInfoTemplate from '@gym-templates/GymChainInfo/GymChainInfoTemplate';

const MainColumn = () => {
  return (
    <Card className="pt30 pl40 pr40 pb30">
      <GymChainInfoTemplate type="new" />
    </Card>
  );
};

const AddNewGymChainPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  return (
    <TwoColTemplate
      title={t('ADD_NEW_GYM_CHAIN.PAGE_TITLE')}
      col1={<MainColumn />}
      alertRef={alertRef}
      backButtonHandler={() => history.push('/gymChains')}
    />
  );
};

export default AddNewGymChainPage;
