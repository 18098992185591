import { useTranslation } from 'react-i18next';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { useParams } from 'react-router';
import { API, graphqlOperation } from 'aws-amplify';
import { onClassPortalUpdate } from '@gym-graphql/subscriptions';
import type { Observable } from 'redux';
import type { ClassPortalData } from '@gym-src/API';
import type { GymType } from '@gym-particles/types/Gym';
import { fetchGyms } from '@gym-redux/slices/gymsSlice';
import { UserRole } from '@gym-particles/types/User';
import Avatar from '@gym-atoms/Avatar/Avatar';
import moment from 'moment';
import { getClassPortalData } from '@gym-graphql/queries';
import staffPlaceholder from '@gym-assets/images/staff-image-placeholder.jpg';

type ClassItem = {
  name: string;
  resourceLogo: string;
  instructorName: string;
  instructorLogo: string;
  classStart: string;
  classEnd: string;
  slots: string;
  imageUrl: string;
  key: string;
  gymUrl: string;
};

const staffImageOrPlaceholder = (url: string) => url || staffPlaceholder;
const classImageOrPlaceholder = (url: string, gym: GymType, id: number) =>
  url || gym.imageUrl || `https://picsum.photos/${1000}/${1000}?random=${id}`;
const imageUrl = (url: string, id: number) =>
  url || `https://picsum.photos/${600}/${200}?random=${id}`;
const getTimeString = () => moment().format('dddd D MMMM h:mm a');

const MainColumn = () => {
  const fullScreenHandle = useFullScreenHandle();
  const { siteLocationId, gymChainId } = useParams<{
    siteLocationId: string;
    gymChainId: string;
  }>();
  const [classes, setClasses] = useState<ClassPortalData[]>([]);
  const [gym, setGym] = useState<GymType>(null);
  const gyms = useAppSelector((state) => state.gyms.items);
  const dispatch = useAppDispatch();
  const { userRole, userId } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const [timeString, setTimeString] = useState(getTimeString());
  const [classData, setClassData] = useState<ClassItem[]>([]);

  useEffect(() => {
    dispatch(
      fetchGyms({
        id: +gymChainId,
        pagination: {
          offset: 0,
          pageSize: 0,
          sortField: 'siteId',
          sortOrder: 1,
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        }
      })
    );
  }, [dispatch, gymChainId, userRole, userId]);

  useEffect(() => {
    const extractedGyms = gyms.map((g) => g.items.find((_g) => _g.gymId === +siteLocationId));
    const selectedGym = extractedGyms.find((g) => g && g.gymId === +siteLocationId);
    if (selectedGym) setGym(selectedGym);
  }, [gyms, siteLocationId]);

  useEffect(() => {
    if (!siteLocationId) return;
    (async () => {
      const data = (await API.graphql(
        graphqlOperation(getClassPortalData, { siteLocationId: +siteLocationId })
      )) as { data: { getClassPortalData: ClassPortalData[] } };
      setClasses(
        data.data.getClassPortalData.filter((cls) => cls.siteLocationId === +siteLocationId)
      );
    })();

    const subscription = ((API.graphql(
      graphqlOperation(onClassPortalUpdate)
    ) as unknown) as Observable<{
      value: { data: { onClassPortalUpdate: ClassPortalData[] } };
    }>).subscribe({
      next: (data) =>
        setClasses(
          data.value.data.onClassPortalUpdate.filter(
            (cls) => cls.siteLocationId === +siteLocationId
          )
        )
    });
    return () => subscription.unsubscribe();
  }, [siteLocationId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeString(getTimeString());
    }, 1000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (!gym) return;
    setClassData(
      classes
        .filter(
          (cls) =>
            moment(cls.startDateTime).isBefore(moment().add(24, 'hour')) &&
            moment(cls.startDateTime).isAfter(moment().subtract(15, 'minute'))
        )
        .sort((a, b) => moment(a.startDateTime).diff(moment(b.startDateTime)).valueOf())
        .slice(0, 5)
        .map((cls, idx, _) => ({
          name: cls.name,
          slots: `${cls.maxCapacity - cls.totalBooked} / ${cls.maxCapacity}`,
          classStart: moment(cls.startDateTime).format('hh:mm A'),
          classEnd: moment(cls.endDateTime).format('hh:mm A'),
          instructorLogo: staffImageOrPlaceholder(cls.staffImageUrl),
          instructorName: cls.staffName,
          resourceLogo: imageUrl(cls.resourceImageUrl, cls.resourceId),
          imageUrl: classImageOrPlaceholder(cls.imageUrl, gym, cls.id),
          key: `${cls.name}_${idx}`,
          gymUrl: gym.imageUrl
        }))
    );
  }, [classes, gym]);

  return (
    <FullScreen handle={fullScreenHandle}>
      {siteLocationId && gym ? (
        <div>
          {classData.length > 0 ? (
            <div
              className={`bg-[#000000] text-slate-100 ${
                fullScreenHandle.active ? 'w-screen h-screen' : 'w-full h-full'
              }`}>
              {!fullScreenHandle.active && (
                <button
                  type="button"
                  onClick={() => fullScreenHandle.enter()}
                  className="block p-4 text-center bg-[#000000] hover:cursor-pointer w-full">
                  {t('CLASS_PORTAL_PAGE.ENTER_FULL_SCREEN')}
                </button>
              )}

              <div className="h-full w-full">
                <div>
                  <div className="flex flex-row justify-center items-center bg-[#fbac19]">
                    {gym.imageUrl ? (
                      <img
                        src={gym.imageUrl}
                        className="rounded-full w-12 md:w-14 lg:w-16 xl:w-20 2xl:w-24 h-12 md:h-14 lg:h-16 xl:h-20 2xl:h-24 object-cover p-0.5 lg:p-1 xl:p-1.5 2xl:p-2"
                        alt="resource logo"
                      />
                    ) : (
                      <Avatar size="xlarge" label={gym.gymName[0]} />
                    )}
                    <p className="text-lg lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl">
                      {gym.gymName}
                    </p>
                  </div>
                  <div className="flex flex-row gap-4 justify-center items-center bg-[#ffffff]">
                    <p className="w-full text-lg lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl text-slate-950 text-center">
                      {timeString}
                    </p>
                  </div>
                </div>
                <div className="h-full w-full">
                  <div className="grid grid-cols-8 divide-x h-full">
                    <div className="col-span-3 flex flex-col flex-1 items-center">
                      <ClassCarousel items={classData} />
                    </div>
                    <div className="col-span-5 flex flex-col h-full border-[#fbac19]">
                      {classData.map((item) => card(item))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`bg-[#000000] text-slate-100 ${
                fullScreenHandle.active ? 'w-screen h-screen' : 'w-screen h-screen'
              }`}>
              {!fullScreenHandle.active && (
                <button
                  type="button"
                  onClick={() => fullScreenHandle.enter()}
                  className="block p-4 text-center bg-[#000000] hover:cursor-pointer w-full">
                  {t('CLASS_PORTAL_PAGE.ENTER_FULL_SCREEN')}
                </button>
              )}

              <div className="h-full w-full">
                <div className="flex flex-row justify-center items-center bg-[#fbac19]">
                  {gym.imageUrl ? (
                    <img
                      src={gym.imageUrl}
                      className="rounded-full w-16 h-16 object-cover p-2"
                      alt="resource logo"
                    />
                  ) : (
                    <Avatar size="xlarge" label={gym.gymName[0]} />
                  )}
                  <p className="text-3xl">{gym.gymName}</p>
                </div>
                <div className="flex flex-row justify-center items-center bg-[#ffffff]">
                  <p className="w-full text-2xl text-slate-950 text-center">{timeString}</p>
                </div>
              </div>
              <div className="text-center">
                <div className="h-full flex align-items-center">
                  <p className="w-full text-3xl">No classes available</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </FullScreen>
  );
};

const CarouselItem = ({
  name,
  resourceLogo,
  instructorName,
  instructorLogo,
  classStart,
  classEnd,
  slots,
  imageUrl,
  key,
  gymUrl
}: ClassItem) => (
  <div className="flex flex-1 flex-col gap-1.5 lg:gap-2 xl:gap-3.5 2xl:gap-4 3xl:gap-6 text-center items-center justify-center align-middle self-center h-auto">
    <p className="font-bold text-lg lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl">
      Upcoming classes
    </p>
    <span className="text-center">
      <img
        src={gymUrl}
        className="rounded-full w-16 lg:w-20 xl:w-24 2xl:w-32 3xl:w-36 h-16 lg:h-20 xl:h-24 2xl:h-32 3xl:h-36 object-cover ring-2 ring-[#fbac19] "
        alt="instructor"
      />
    </span>
    <span className="flex justify-center items-center w-[80%] h-auto">
      <img
        src={resourceLogo}
        className="rounded-md w-[100%] h-16 lg:h-20 xl:h-24 2xl:h-32 3xl:h-36 object-contain"
        alt="resource logo"
      />
    </span>
    <p className="text-lg lg:text-2xl xl:text-3xl 2xl:text-4xl 3xl:text-5xl font-semibold text-center p-0">
      {classStart} - {classEnd}
    </p>
    <p className="text-base lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl font-semibold text-center">
      {name}
    </p>
    <p className="text-base lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl text-center">
      Slots available
    </p>
    <p className="text-base lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl font-semibold text-center">
      {slots}
    </p>
    <span className="text-center">
      <img
        src={instructorLogo}
        className="rounded-full w-16 lg:w-20 xl:w-24 2xl:w-32 3xl:w-36 h-16 lg:h-20 xl:h-24 2xl:h-32 3xl:h-36 object-cover"
        alt="instructor"
      />
    </span>
    <p className="text-base lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl font-semibold text-center">
      {instructorName}
    </p>
  </div>
);

const card = ({
  name,
  resourceLogo,
  instructorName,
  instructorLogo,
  classStart,
  classEnd,
  slots,
  imageUrl
}: ClassItem) => (
  <div className="grid grid-cols-8 border-b-[#fbac19] border-b divide-x p-1 w-[100%] h-1/6">
    <div className="col-span-2 flex flex-col items-center">
      <p className="text-sm lg:text-xl xl:text-2xl 2xl:text-2xl 3xl:text-3xl font-semibold text-center line-clamp-1">
        {name}
      </p>
      <img
        src={resourceLogo}
        alt=""
        className="rounded-md w-[95%] h-12 lg:h-12 xl:h-20 2xl:h-28 3xl:h-32 object-contain p-1 "
      />
    </div>
    <div className="col-span-6 border-[#fbac19]">
      <div className="grid grid-cols-7 gap-1 h-full">
        <div className="col-span-1 flex self-center flex-col overflow-hidden p-1 m-0 items-center justify-center content-center place-content-center">
          <img
            src={instructorLogo}
            alt=""
            className="inline-block ring-2 ring-[#fbac19] rounded-full w-12 lg:w-14 xl:w-16 2xl:w-20 3xl:w-24 h-12 lg:h-14 xl:h-16 2xl:h-20 3xl:h-24 object-cover"
          />
        </div>
        <div className="col-span-4 flex flex-col items-start justify-center p-1">
          <p className="mt-0 text-base lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl font-semibold">
            {classStart} - {classEnd}
          </p>
          <p className="mt-0 text-sm lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-3xl">
            Instructor:
          </p>
          <div className="flex flex-row items-stretch justify-between w-full">
            <p className="mt-0 text-base lg:text-xl xl:text-2xl 2xl:text-3xl 3xl:text-4xl font-semibold">
              {instructorName}
            </p>
          </div>
        </div>
        <div className="col-span-2 flex flex-col items-start justify-center items-center">
          <p className="mt-0 text-base lg:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl text-center">
            Slots available:
          </p>
          <p className="mt-0 text-lg lg:text-2xl xl:text-3xl 2xl:text-4xl 3xl:text-5xl text-center font-semibold">
            {slots}
          </p>
        </div>
      </div>
    </div>
  </div>
);

const ClassCarousel = ({ items }: { items: ClassItem[] }) => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % items.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [items]);

  return (
    <div className="h-full w-full" key={String(index)}>
      <CarouselItem {...items[index]} />
    </div>
  );
};

const ClassPortalPage = () => {
  return <MainColumn />;
};

export default ClassPortalPage;
