import Avatar from '@gym-atoms/Avatar/Avatar';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { AccessPoints } from '@gym-particles/types/AccessPoints';
import { MemberAccessCard } from '@gym-particles/types/Member';
import { useCallback } from 'react';

const AccessCardDashboardView = ({ member, onClickCallBack }: AccessCardDashboardViewProps) => {
  const handleClick = useCallback(
    (e: number) => {
      onClickCallBack(e);
    },
    [onClickCallBack]
  );

  return (
    <>
      <div className="d-flex align-items-center mb10">
        <Avatar shape="circle" image={member.imageUrl} size={'default'} />

        <p className="text-truncate ml10">{member.firstName + ' ' + member.lastName}</p>

        <div className="text-nowrap ms-auto">
          <Button
            label={'Issue Card'}
            variant={ButtonVariants.textonlyslim}
            size={ButtonSizes.small}
            onClick={() => handleClick(member.memberId)}
          />
        </div>
      </div>
    </>
  );
};

export default AccessCardDashboardView;

export interface AccessCardDashboardViewProps {
  member: MemberAccessCard;
  accessPoints?: AccessPoints[];
  onClickCallBack: (e: number) => void;
}
