import { API } from '@aws-amplify/api';
import { getAccessRules } from '@gym-graphql/queries';
import { SettingsElement } from '@gym-particles/types/GymChainSettings';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface SettingsState {
  items: SettingsElement[];
  isSettingsLoading: boolean;
}

export interface ParamType {
  siteId: number;
}

const initialState: SettingsState = {
  items: [],
  isSettingsLoading: false
};

export const fetchGymChainSettings = createAsyncThunk(
  '/gymChain/settings',
  async (param: ParamType) => {
    const response = await (API.graphql({
      query: getAccessRules,
      variables: {
        siteId: param.siteId
      }
    }) as Promise<{
      data: { getAccessRules: SettingsElement[] };
    }>);
    return response;
  }
);

export const gymChainSettingsSlice = createSlice({
  name: 'gymChainSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGymChainSettings.pending, (state, action) => {
      state.isSettingsLoading = true;
      return state;
    });

    builder.addCase(fetchGymChainSettings.fulfilled, (state, action) => {
      console.log(action);
      state.isSettingsLoading = false;
      state.items = action.payload.data.getAccessRules;
      return state;
    });
  }
});

export default gymChainSettingsSlice.reducer;
