import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface User {
  userName: string;
  isLogged: boolean;
  cognitoUser: any;
  userId: number;
  roleId: number;
  userRole: string;
  userFirstName: string;
  userLastName: string;
  language: string;
  siteCount: number;
  locationCount: number;
}

const initialState = {
  userName: '',
  isLogged: false,
  cognitoUser: null,
  userId: -1,
  roleId: -1,
  userRole: '',
  userFirstName: '',
  userLastName: '',
  language: 'en',
  siteCount: -1,
  locationCount: -1
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      const {
        userName,
        isLogged,
        cognitoUser,
        userId,
        roleId,
        userRole,
        userFirstName,
        userLastName,
        language,
        siteCount,
        locationCount
      } = action.payload;
      state.userName = userName;
      state.isLogged = isLogged;
      state.cognitoUser = cognitoUser;
      state.userId = userId;
      state.roleId = roleId;
      state.userRole = userRole;
      state.userFirstName = userFirstName;
      state.userLastName = userLastName;
      state.language = language;
      state.siteCount = siteCount;
      state.locationCount = locationCount;
    },
    logout: (state) => {
      state.userName = '';
      state.isLogged = false;
      state.cognitoUser = null;
      state.userId = -1;
      state.roleId = -1;
      state.userRole = '';
      state.userFirstName = '';
      state.userLastName = '';
      state.language = 'en';
      state.siteCount = -1;
      state.locationCount = -1;
    }
  }
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
