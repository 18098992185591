import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import './App.scss';
import { privateRoutes, publicRoutes, sharedRoutes } from '@gym-particles/routing';
import { useAppSelector, useAppDispatch } from '@gym-redux/store';
import { login, logout } from '@gym-redux/slices/userSlice';
import { fetchGymChains, fetchSidebarGymChains } from '@gym-redux/slices/gymChainSlice';
import Sidebar from '@gym-organisms/Sidebar/Sidebar';
import { getLoggedInUserWeb } from '@gym-graphql/queries';
import { LoggedInUser } from './API';
import { UserRole } from '@gym-particles/types/User';
import i18n from '@gym-particles/i18next';
import { setFeatures } from '@gym-redux/slices/configurationSlice';
import { GymxsFeatures } from '@gym-particles/types/models';
import { sidebarPropsSlice } from '@gym-redux/slices/sidebarPropsSlice';
import { fetchScheduleRequests } from '@gym-redux/slices/scheduleRequestsSlice';
import { fetchGymsbyUserId } from '@gym-redux/slices/gymsSlice';

import './sass/tailwind.css';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector((state) => state.user.isLogged);
  const history = useHistory();
  const [initialPath, setInitialPath] = useState<string>(history.location.pathname);
  const userId = useAppSelector((state) => state.user.userId);
  const userRole = useAppSelector((state) => state.user.userRole);

  useEffect(() => {
    loggedIn &&
      dispatch(
        fetchGymChains({
          offset: 0,
          pageSize: 10,
          sortField: '   ',
          sortOrder: -1,
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        })
      );
    loggedIn &&
      dispatch(
        fetchGymsbyUserId({
          userId: userId
        })
      );
    dispatch(fetchScheduleRequests());
  }, [loggedIn, userId]);

  useEffect(() => {
    loggedIn &&
      dispatch(fetchSidebarGymChains({ userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId }));
    loggedIn && dispatch(fetchGymsbyUserId({ userId }));
  }, [loggedIn, userId]);

  const sideBarGymChains = useAppSelector((state) => state.gymChain.sideBarGymChains);
  const sideBarGyms = useAppSelector((state) =>
    state.gyms.items.length > 0 ? state.gyms.items[0].items : []
  );
  const menuSidebarVisible = useAppSelector((state) => state.sidebarProps.show);

  const checkAuthUser = async () => {
    await Auth.currentAuthenticatedUser()
      .then(async (user) => {
        const result = await (API.graphql({
          query: getLoggedInUserWeb,
          variables: {
            email: user.attributes.email
          }
        }) as Promise<{
          data: { getLoggedInUserWeb: LoggedInUser };
        }>);

        dispatch(
          login({
            userName: user.attributes.email,
            isLogged: true,
            cognitoUser: user,
            // Query error is handled through VTL and it'll go to the catch block
            userId: result.data.getLoggedInUserWeb.userId || -1,
            roleId: result.data.getLoggedInUserWeb.roleId || -1,
            userRole: result.data.getLoggedInUserWeb.roleName || '',
            userFirstName: result.data.getLoggedInUserWeb.firstName || '',
            userLastName: result.data.getLoggedInUserWeb.lastName || '',
            language: result.data.getLoggedInUserWeb.language || '',
            siteCount: result.data.getLoggedInUserWeb.siteCount || -1,
            locationCount: result.data.getLoggedInUserWeb.locationCount || -1
          })
        );
        i18n.changeLanguage(result.data.getLoggedInUserWeb.language?.toLowerCase());
        history.push(initialPath);
      })
      .catch((err) => {
        console.log('Current Auth User: ', err);
        dispatch(logout());
      });
  };

  /** Run checkAuthUser once when App.tsx mounts */
  useEffect(() => {
    checkAuthUser();
  }, []);

  useEffect(() => {
    const envFeatures = (process.env.REACT_APP_FEATURES?.split(' ') as GymxsFeatures[]) || [
      'sodvin',
      'mindbody'
    ];
    dispatch(setFeatures(envFeatures));
  }, []);

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.pageX > 270) {
        dispatch(sidebarPropsSlice.actions.hide());
      }
    });
  }, []);

  if (loggedIn) {
    return (
      <div className="page-wrapper d-flex">
        <div className={menuSidebarVisible ? 'page-sidebar open' : 'page-sidebar'}>
          <Sidebar gymChains={sideBarGymChains} gyms={sideBarGyms} />
        </div>
        <div className="App">
          <Switch>
            {sharedRoutes.map((entry, index) => {
              return <Route key={index} {...entry} />;
            })}
            {privateRoutes.map((entry, index) => {
              return <Route key={index} {...entry} />;
            })}
            <Redirect from="*" to="/home" />
          </Switch>
        </div>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Switch>
          {sharedRoutes.map((entry, index) => {
            return <Route key={index} {...entry} />;
          })}
          {publicRoutes.map((entry, index) => {
            return <Route key={index} {...entry} />;
          })}
          <Redirect from="*" to="/login" />
        </Switch>
      </div>
    );
  }
};

export default App;
