import { useCallback, useEffect, useRef, useState } from 'react';
import Image from '@gym-atoms/Image/Image';
import ListBox from '@gym-atoms/ListBox/ListBox';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { useHistory, useParams } from 'react-router-dom';
import {
  AccessCategoriesMapping,
  GymType,
  Gym,
  GymScheduleAccessCategoryMapping,
  GymSchedulesAccessPointMapping,
  GymSchedulesGymZoneMapping,
  GymScheduleZoneMapping,
  GymScheduleAccessPointMapping,
  AccessPointMapping,
  ZoneMapping
} from '@gym-particles/types/models';
import {
  SiteLocationScheduleForAccessCategoryInput,
  SiteLocationScheduleForLocationAccessPointInput
} from '@gym-src/API';
import {
  addSiteLocationSchedulesForAccessCategory,
  addSiteLocationSchedulesForLocationAccessPoint,
  removeSiteLocationScheduleAccessCategoryRecord,
  removeSiteLocationScheduleAccessPointRecord
} from '@gym-graphql/mutations';
import { API } from 'aws-amplify';
import { useAppSelector } from '@gym-redux/store';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Alert, { AlertType, createAlert } from '@gym-atoms/Alert/Alert';
import Avatar from '@gym-atoms/Avatar/Avatar';
import { acronym } from '@gym-particles/acronym';

const GymSchedulesZoneMapping = (props: GymZoneMappingProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const alertRef = useRef<AlertType>(null);
  const userId = useAppSelector((state) => state.user.userId);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const [isInserted, setIsInserted] = useState(false);

  // Populate List Boxes

  const [gymZoneMappings, setGymZoneMappings] = useState<Array<GymScheduleZoneMapping>>([]);
  const [gymAccessCategoryMappings, setGymAccessCategoryMappings] = useState<
    Array<GymScheduleAccessCategoryMapping>
  >([]);
  const [gymAccessPointMappings, setGymAccessPointMappings] = useState<
    Array<GymScheduleAccessPointMapping>
  >([]);

  const { chainId, gymScheduleId } = useParams<{ chainId: string; gymScheduleId: string }>();

  useEffect(() => {
    createAlert(
      alertRef,
      {
        content: t('GYM_ZONE_MAPPING.ALERT_MESSAGE_TOP'),
        sticky: true,
        closable: false,
        severity: 'info'
      },
      'replace'
    );
  }, []);
  const mapZones = (selectedGyms: GymType[]) => {
    const mappedZones: GymScheduleZoneMapping[] = [];

    selectedGyms.forEach((gym) => {
      const filteredZones = props.gymZones.filter((zone) => {
        return zone.siteLocationId === gym.gymId;
      });

      filteredZones.forEach((zone) => {
        const index = mappedZones.findIndex((filteredZone) => {
          return filteredZone.siteLocationId === zone.siteLocationId;
        });

        if (index === -1) {
          mappedZones.push({
            locationName: zone.locationName,
            siteLocationId: zone.siteLocationId,
            locationZoneId: zone.locationZoneId,
            zones: [
              {
                siteLocationId: zone.siteLocationId,
                imageUrl: gym.imageUrl,
                zoneName: zone.zoneName,
                locationZoneId: zone.locationZoneId
              }
            ]
          });
        } else {
          const zoneIndex = mappedZones[index].zones.findIndex((mappedZone) => {
            return mappedZone.locationZoneId === zone.locationZoneId;
          });

          if (zoneIndex === -1) {
            mappedZones[index].zones.push({
              siteLocationId: zone.siteLocationId,
              imageUrl: gym.imageUrl,
              zoneName: zone.zoneName,
              locationZoneId: zone.locationZoneId
            });
          }
        }
      });
    });

    setGymZoneMappings(mappedZones);
  };

  const mapAccessCategories = (selectedZones: GymScheduleZoneMapping[]) => {
    const mappedAccessCategories: GymScheduleAccessCategoryMapping[] = [];

    selectedZones.forEach((selectedZone) => {
      const filteredAccessCategories = props.gymZones.filter((zone) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return zone.locationZoneId === selectedZone.locationZoneId;
      });

      filteredAccessCategories.forEach((accessCategory) => {
        const index = mappedAccessCategories.findIndex((filteredAccessCategories) => {
          return filteredAccessCategories.locationZoneId === accessCategory.locationZoneId;
        });

        if (index === -1) {
          mappedAccessCategories.push({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            imageUrl: selectedZone.imageUrl,
            zoneName: accessCategory.zoneName,
            locationZoneId: accessCategory.locationZoneId,
            accessCategories: [
              {
                locationZoneId: accessCategory.locationZoneId,
                siteLocationId: accessCategory.siteLocationId,
                locationAccessCategoryId: accessCategory.locationAccessCategoryId,
                locationAccessCategoryName: accessCategory.locationAccessCategoryName,
                locationArxAccessCategoryId: accessCategory.locationArxAccessCategoryId
              }
            ]
          });
        } else {
          mappedAccessCategories[index].accessCategories.push({
            locationZoneId: accessCategory.locationZoneId,
            siteLocationId: accessCategory.siteLocationId,
            locationAccessCategoryId: accessCategory.locationAccessCategoryId,
            locationAccessCategoryName: accessCategory.locationAccessCategoryName,
            locationArxAccessCategoryId: accessCategory.locationArxAccessCategoryId
          });
        }
      });
    });

    setGymAccessCategoryMappings(mappedAccessCategories);
  };

  const mapAccessPoints = (selectedZones: GymScheduleZoneMapping[]) => {
    const mappedAccessPoints: GymScheduleAccessPointMapping[] = [];

    selectedZones.forEach((selectedZone) => {
      const filteredAccessPoints = props.gymAccessPoints.filter((zone) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return zone.siteLocationId === selectedZone.siteLocationId;
      });

      const filtereZonedAccessPoints = filteredAccessPoints.filter((z) => {
        return z.locationZoneId === selectedZone.locationZoneId;
      });

      filtereZonedAccessPoints.forEach((accessPoint) => {
        const index = mappedAccessPoints.findIndex((filteredAccessPoints) => {
          return filteredAccessPoints.locationZoneId === accessPoint.locationZoneId;
        });

        if (index === -1) {
          mappedAccessPoints.push({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            imageUrl: selectedZone.imageUrl,
            zoneName: accessPoint.locationZoneName,
            locationZoneId: accessPoint.locationZoneId,
            accessPoints: [
              {
                siteLocationId: accessPoint.siteLocationId,
                locationZoneId: accessPoint.locationZoneId,
                doorARXId: accessPoint.doorARXId,
                doorName: accessPoint.doorName,
                locationAccessPointId: accessPoint.locationAccessPointId
              }
            ]
          });
        } else {
          mappedAccessPoints[index].accessPoints.push({
            siteLocationId: accessPoint.siteLocationId,
            locationZoneId: accessPoint.locationZoneId,
            doorARXId: accessPoint.doorARXId,
            doorName: accessPoint.doorName,
            locationAccessPointId: accessPoint.locationAccessPointId
          });
        }
      });
    });

    setGymAccessPointMappings(mappedAccessPoints);
  };

  const removeSelectedZones = () => {
    let filteredZones: GymScheduleZoneMapping[] = [];

    props.selectedGyms.forEach((gym) => {
      const filteredZonesForGym = props.selectedZones.filter((zone) => {
        return zone.siteLocationId === gym.gymId;
      });

      filteredZones = filteredZones.concat(filteredZonesForGym);
    });

    props.setSelectedZones(filteredZones);
  };

  const removeSelectedAccessCategories = () => {
    let filteredAccessCategories: GymScheduleAccessCategoryMapping[] = [];

    props.selectedZones.forEach((zone) => {
      const filteredAccessCategoriesForZone = props.selectedAccessCategory.filter(
        (accessCategory) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return accessCategory.locationZoneId === zone.locationZoneId;
        }
      );

      filteredAccessCategories = filteredAccessCategories.concat(filteredAccessCategoriesForZone);
    });

    props.setSelectedAccessCategory(filteredAccessCategories);
  };

  const removeSelectedAccessPoints = () => {
    let filteredAccessPoints: GymScheduleAccessPointMapping[] = [];

    props.selectedZones.forEach((zone) => {
      const filteredAccessPointsForZone = props.selectedAccessPoint.filter((accessPoint) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return accessPoint.locationZoneId === zone.locationZoneId;
      });

      filteredAccessPoints = filteredAccessPoints.concat(filteredAccessPointsForZone);
    });

    props.setSelectedAccessPoint(filteredAccessPoints);
  };

  useEffect(() => {
    mapZones(props.selectedGyms);
    removeSelectedZones();
    removeSelectedAccessCategories();
  }, [props.selectedGyms]);

  useEffect(() => {
    if (props.isDoorSchedule) {
      mapAccessPoints(props.selectedZones);
      removeSelectedAccessPoints();
    } else {
      mapAccessCategories(props.selectedZones);
      removeSelectedAccessCategories();
    }
  }, [props.selectedZones]);

  const stepperChangeHandler = useCallback(
    (state) => {
      props.setActiveIndex(state);
    },
    [props.setActiveIndex]
  );

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t('ADD_NEW_GYM_SCHEDULE.FORM_FAILURE_DIALOG_HEADER')}
          dialogFooter={
            <Button
              label={t('ADD_NEW_GYM_SCHEDULE.FORM_FAILURE_DIALOG_BUTTON')}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t('ADD_NEW_GYM_SCHEDULE.FORM_FAILURE_DIALOG_BODY')}</Text>}
        />
      </div>
    );
  };
  const addSiteLocationSchedulesAccessPoints = async (
    siteScheduleId: number,
    data: GymScheduleAccessPointMapping
  ) => {
    const addSiteLocationSchedulesAccessPointsInput: SiteLocationScheduleForLocationAccessPointInput = {
      siteScheduleId: siteScheduleId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      siteLocationId: data.siteLocationId,
      zoneId: data.locationZoneId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      locationAccessPointId: data.locationAccessPointId,
      createdBy: userId,
      lastModifiedBy: userId,
      status: 'active'
    };

    try {
      await API.graphql({
        query: addSiteLocationSchedulesForLocationAccessPoint,
        variables: {
          input: addSiteLocationSchedulesAccessPointsInput
        }
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const addSiteLocationSchedulesAccessCategories = async (
    siteScheduleId: number,
    data: GymScheduleAccessCategoryMapping
  ) => {
    const addSiteLocationSchedulesAccessCategoriesInput: SiteLocationScheduleForAccessCategoryInput = {
      siteScheduleId: siteScheduleId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      siteLocationId: data.siteLocationId,
      zoneId: data.locationZoneId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      siteLocationAccessCategoryId: data.locationAccessCategoryId,
      createdBy: userId,
      lastModifiedBy: userId,
      status: 'active'
    };

    try {
      await API.graphql({
        query: addSiteLocationSchedulesForAccessCategory,
        variables: {
          input: addSiteLocationSchedulesAccessCategoriesInput
        }
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const addSiteLocationSchedules = async () => {
    setIsInserted(true);
    const siteSchedulePromises: Promise<any>[] = [];

    if (
      props.scheduleType === 'opening hour schedule' ||
      props.scheduleType === 'holiday schedule'
    ) {
      if (props.selectedAccessCategory.length === 0) {
        return;
      }

      props.selectedAccessCategory.forEach((accessCategory) => {
        siteSchedulePromises.push(
          addSiteLocationSchedulesAccessCategories(props.scheduleId, accessCategory)
        );
      });
    } else {
      if (props.selectedAccessPoint.length === 0) {
        return;
      }
      props.selectedAccessPoint.forEach((accessPoint) => {
        siteSchedulePromises.push(
          addSiteLocationSchedulesAccessPoints(props.scheduleId, accessPoint)
        );
      });
    }

    try {
      await Promise.all(siteSchedulePromises);
      setIsInserted(false);
      props.activeIndex && stepperChangeHandler(props.activeIndex + 1);
    } catch (error) {
      setIsInserted(false);
      console.log(error);
      setFailedDialogVisible(true);
    }
  };

  const removeSiteLocationSchedulesAccessCategoryRecord = async (id: number, zoneId: number) => {
    return API.graphql({
      query: removeSiteLocationScheduleAccessCategoryRecord,
      variables: {
        locationAccessCategoryId: id,
        zoneId: zoneId,
        scheduleId: gymScheduleId,
        userId: userId,
        siteId: chainId
      }
    });
  };
  const removeSiteLocationSchedulesAccessPointRecord = async (id: number, zoneId: number) => {
    return API.graphql({
      query: removeSiteLocationScheduleAccessPointRecord,
      variables: {
        locationAccessPointId: id,
        zoneId: zoneId,
        scheduleId: gymScheduleId,
        userId: userId,
        siteId: chainId
      }
    });
  };

  const modifySiteLocationSchedules = async () => {
    try {
      const addedAccessCategories: any[] = [];

      props.selectedAccessCategory.forEach((e) => {
        const matchingRecord = props.defaultSelectedAccessCategory?.find((i) => {
          return (
            // eslint-disable-next-line prettier/prettier
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            i.locationAccessCategoryId === e.locationAccessCategoryId &&
            i.locationZoneId === e.locationZoneId
          );
        });
        if (!matchingRecord) {
          addedAccessCategories.push(e);
        }
      });

      const removedAccessCategory: any[] = [];

      props.defaultSelectedAccessCategory?.forEach((e) => {
        const matchingRecord = props.selectedAccessCategory.find((i) => {
          return (
            // eslint-disable-next-line prettier/prettier
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            i.locationAccessCategoryId === e.locationAccessCategoryId &&
            i.locationZoneId === e.locationZoneId
          );
        });
        if (!matchingRecord) {
          removedAccessCategory.push(e);
        }
      });

      const addedAccessPoints: any[] = [];

      props.selectedAccessPoint.forEach((e) => {
        const matchingRecord = props.defaultSelectedAccessPoint?.find((i) => {
          return (
            // eslint-disable-next-line prettier/prettier
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            i.locationAccessPointId === e.locationAccessPointId &&
            i.locationZoneId === e.locationZoneId
          );
        });
        if (!matchingRecord) {
          addedAccessPoints.push(e);
        }
      });

      const removedAccessPoint: any[] = [];

      props.defaultSelectedAccessPoint?.forEach((e) => {
        const matchingRecord = props.selectedAccessPoint.find((i) => {
          return (
            // eslint-disable-next-line prettier/prettier
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            i.locationAccessPointId === e.locationAccessPointId &&
            i.locationZoneId === e.locationZoneId
          );
        });
        if (!matchingRecord) {
          removedAccessPoint.push(e);
        }
      });

      try {
        if (addedAccessCategories) {
          const addedAccessCategoriesPromises: Promise<any>[] = [];

          addedAccessCategories.forEach((accessCategory) => {
            addedAccessCategoriesPromises.push(
              addSiteLocationSchedulesAccessCategories(+props.scheduleId, accessCategory)
            );
          });

          await Promise.all(addedAccessCategoriesPromises);
        }

        if (addedAccessPoints) {
          const addedAccessPointsPromises: Promise<any>[] = [];

          addedAccessPoints.forEach((accessPoint) => {
            addedAccessPointsPromises.push(
              addSiteLocationSchedulesAccessPoints(+props.scheduleId, accessPoint)
            );
          });

          await Promise.all(addedAccessPointsPromises);
        }

        if (removedAccessCategory) {
          const removedAccessCategoryPromises: Promise<any>[] = [];

          removedAccessCategory.forEach((record) => {
            removedAccessCategoryPromises.push(
              removeSiteLocationSchedulesAccessCategoryRecord(
                record.locationAccessCategoryId,
                record.locationZoneId
              )
            );
          });

          await Promise.all(removedAccessCategoryPromises);
        }

        if (removedAccessPoint) {
          const removedAccessPointPromises: Promise<any>[] = [];

          removedAccessPoint.forEach((record) => {
            removedAccessPointPromises.push(
              removeSiteLocationSchedulesAccessPointRecord(
                record.locationAccessPointId,
                record.locationZoneId
              )
            );
          });

          await Promise.all(removedAccessPointPromises);
        }
        props.activeIndex && stepperChangeHandler(props.activeIndex + 1);
      } catch (error) {
        setFailedDialogVisible(true);
        console.log(error);
      }
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const submitHandler = () => {
    if (props.isBacked || props.type === 'modify') {
      modifySiteLocationSchedules();
    } else if (props.type === 'new') {
      addSiteLocationSchedules();
    }
  };

  const disableNext = () => {
    if (
      props.scheduleType === 'door unlock schedule' &&
      props.selectedGyms.length > 0 &&
      props.selectedZones.length > 0 &&
      props.selectedAccessPoint.length > 0
    ) {
      return false;
    } else if (
      props.selectedGyms.length > 0 &&
      props.selectedZones.length > 0 &&
      props.selectedAccessCategory.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const GymListBox = () => {
    const gymInfoTemplate = (option: Gym) => {
      return (
        <div className="d-flex">
          {option.imageUrl ? (
            <Image source={option.imageUrl || ''} width={22} height={22} circle />
          ) : (
            <Avatar
              size="custom"
              shape="circle"
              label={acronym(option.gymName || '')}
              width={22}
              height={22}
              fontSize=".75em"
              color="#e0f4fe"
            />
          )}

          <div className="ps-2">{option.gymName}</div>
        </div>
      );
    };

    return (
      <div className="listbox-wrapper">
        <ListBox
          value={props.selectedGyms}
          options={props.gyms}
          optionLabel="gymName"
          itemTemplate={gymInfoTemplate}
          multiSelect={true}
          checkBoxLabel={t('GYM_ZONE_MAPPING.LISTBOX_CHECKBOX_LABEL')}
          setSelected={props.setSelectedGyms}
        />
      </div>
    );
  };

  const GymZoneList = () => {
    const zoneInfoTemplate = (gymZone: GymScheduleZoneMapping) => {
      return (
        <div className="d-flex">
          {gymZone.zones[0].imageUrl ? (
            <Image source={gymZone.zones[0].imageUrl || ''} width={22} height={22} circle={true} />
          ) : (
            <Avatar
              size="custom"
              shape="circle"
              label={acronym(gymZone.locationName || '')}
              width={22}
              height={22}
              fontSize=".75em"
              color="#e0f4fe"
            />
          )}

          <div className="ps-2">{gymZone.locationName}</div>
        </div>
      );
    };

    return (
      <div className="listbox-wrapper listbox-wrapper--group">
        <ListBox
          value={props.selectedZones}
          options={gymZoneMappings}
          optionLabel="zoneName"
          optionGroupLabel="locationName"
          optionGroupChildren="zones"
          optionGroupTemplate={zoneInfoTemplate}
          multiSelect={true}
          checkBoxLabel={t('GYM_ZONE_MAPPING.LISTBOX_CHECKBOX_LABEL')}
          setSelected={props.setSelectedZones}
        />
      </div>
    );
  };

  const AccessPointsList = () => {
    const accessPointInfoTemplate = (accessPoint: GymScheduleAccessPointMapping) => {
      return (
        <div className="d-flex">
          <Avatar
            size="custom"
            shape="circle"
            label={acronym(accessPoint.zoneName || '')}
            width={22}
            height={22}
            fontSize=".75em"
            color="#e0f4fe"
          />
          <div className="ps-2">{accessPoint.zoneName}</div>
        </div>
      );
    };

    return (
      <div>
        <Text className="text-center mb10" bold={true} size={TextSizes.t14}>
          {t('GYM_ZONE_MAPPING.LISTBOX_ACCESS_POINTS_TITLE')}
        </Text>
        <div className="listbox-wrapper listbox-wrapper--group">
          <ListBox
            value={props.selectedAccessPoint}
            options={gymAccessPointMappings}
            optionLabel="doorName"
            optionGroupLabel="zoneName"
            optionGroupChildren="accessPoints"
            optionGroupTemplate={accessPointInfoTemplate}
            multiSelect={true}
            checkBoxLabel={t('GYM_ZONE_MAPPING.LISTBOX_CHECKBOX_LABEL')}
            setSelected={props.setSelectedAccessPoint}
          />
        </div>
      </div>
    );
  };

  const AccessCategoryList = () => {
    const accessCategoryInfoTemplate = (accessCategory: GymScheduleAccessCategoryMapping) => {
      return (
        <div className="d-flex">
          <Avatar
            size="custom"
            shape="circle"
            label={acronym(accessCategory.zoneName || '')}
            width={22}
            height={22}
            fontSize=".75em"
            color="#e0f4fe"
          />
          <div className="ps-2">{accessCategory.zoneName}</div>
        </div>
      );
    };

    return (
      <div>
        <Text className="text-center mb10" bold={true} size={TextSizes.t14}>
          {t('GYM_ZONE_MAPPING.LISTBOX_ACCESS_CATEGORIES_TITLE')}
        </Text>
        <div className="listbox-wrapper listbox-wrapper--group">
          <ListBox
            value={props.selectedAccessCategory}
            options={gymAccessCategoryMappings}
            optionLabel="locationAccessCategoryName"
            optionGroupLabel="zoneName"
            optionGroupChildren="accessCategories"
            optionGroupTemplate={accessCategoryInfoTemplate}
            multiSelect={true}
            checkBoxLabel={t('GYM_ZONE_MAPPING.LISTBOX_CHECKBOX_LABEL')}
            setSelected={props.setSelectedAccessCategory}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Alert refProp={alertRef} />
      </div>
      <div className="d-flex justify-content-between mt30">
        <div className="col-md-4 pr15 pl15">
          <div>
            <Text className="text-center mb10" bold={true} size={TextSizes.t14}>
              {t('GYM_ZONE_MAPPING.SELECT_GYMS_LISTBOX_TITLE')}
            </Text>
            <GymListBox />
          </div>
        </div>

        <div className="col-md-4 pr15 pl15">
          <div>
            <Text className="text-center mb10" bold={true} size={TextSizes.t14}>
              {t('GYM_ZONE_MAPPING.SELECT_GYM_ZONES_LISTBOX_TITLE')}
            </Text>
            <GymZoneList />
          </div>
        </div>

        <div className="col-md-4 pr15 pl15">
          {props.isDoorSchedule ? <AccessPointsList /> : <AccessCategoryList />}
        </div>
      </div>
      <div>
        <div className="row mt40">
          <div className="col-12">
            <div className="d-flex btn-min-w-110">
              <div className="mr15">
                <Button
                  label={t('GYM_ZONE_MAPPING.FORM_BTN_LABEL_PREVIOUS')}
                  size={ButtonSizes.medium}
                  onClick={() => {
                    stepperChangeHandler(props.activeIndex - 1);
                  }}
                />
              </div>
              <div className="mr15">
                <Button
                  label={t('GYM_ZONE_MAPPING.FORM_BTN_LABEL_NEXT')}
                  size={ButtonSizes.medium}
                  icon={isInserted ? 'pi-spinner pi-spin' : ''}
                  onClick={submitHandler}
                  disabled={disableNext()}
                />
              </div>
              <Button
                data-cy={'gym-schedules-form-btn-cancel'}
                label={t('GYM_ZONE_MAPPING.FORM_BTN_LABEL_CANCEL')}
                variant={ButtonVariants.textonly}
                onClick={() => history.push(`/gymChains/${chainId}/gymSchedules`)}
                disabled={isInserted}
              />
            </div>
          </div>
        </div>
      </div>
      <ActionFailedDialog />
    </div>
  );
};

type GymSchedulesZoneMappingType = 'new' | 'modify';
interface GymZoneMappingProps {
  type: GymSchedulesZoneMappingType;
  activeIndex: number;
  setActiveIndex: (e: number) => void;
  gymChainId?: number;
  isDoorSchedule: boolean;
  gyms: GymType[];
  //To-Do: Rename this to show that this type carries more than zones. It contains Access Categories and Access Points as well.
  gymZones: GymSchedulesGymZoneMapping[];
  gymAccessPoints: GymSchedulesAccessPointMapping[];
  scheduleId: number;
  scheduleType: string;
  defaultSelectedGyms?: GymType[];
  defaultSelectedZones?: ZoneMapping[];
  defaultSelectedAccessCategory?: AccessCategoriesMapping[];
  defaultSelectedAccessPoint?: AccessPointMapping[];
  isBacked?: boolean;
  selectedGyms: GymType[];
  setSelectedGyms: (e: GymType[]) => void;
  selectedZones: GymScheduleZoneMapping[];
  setSelectedZones: (e: GymScheduleZoneMapping[]) => void;
  selectedAccessCategory: GymScheduleAccessCategoryMapping[];
  setSelectedAccessCategory: (e: GymScheduleAccessCategoryMapping[]) => void;
  selectedAccessPoint: GymScheduleAccessPointMapping[];
  setSelectedAccessPoint: (e: GymScheduleAccessPointMapping[]) => void;
}

export default GymSchedulesZoneMapping;
