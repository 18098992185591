export interface GymType {
  gymChainId: number;
  gymChainName: string;
  gymId: number;
  gymIdApi?: number;
  gymName: string;
  contactPerson: string;
  email: string;
  phoneNumber: string;
  address: string;
  city?: string;
  status: string;
  createdDate: string;
  createdBy: number;
  lastModifiedDate: string;
  lastModifiedBy: number;
  lastImportedDate?: string | null;
  latitude?: number;
  longitude?: number;
  freePassId?: number;
  trialMembershipId?: number;
  membershipOnSignup?: number;
  membershipForVisits?: number;
  syncAllVisitsWithGenericSubscription?: boolean;
  staffMembershipId?: number;
  handbackTime?: number;
  defaultLocationDetectionMethod?: string;
  primaryAccessMethod?: string;
  externalBookingsLink?: string;
  facebookLink?: string;
  twitterLink?: string;
  instagramLink?: string;
  websiteLink?: string;
  gracePeriodWhenDeclined?: number;
  accessPerDayWhenDeclined?: number;
  gracePeriodWhenExpired?: number;
  accessPerDayWhenExpired?: number;
  gracePeriodWhenSuspended?: number;
  accessPerDayWhenSuspended?: number;
  isMobileCredentialAllowed?: boolean;
  imageUrl?: string;
  signUpUrl?: string;
  arxReferenceId?: string;
  sodvinCompanyId?: string;
  displayStatus?: string;
  cardType?: string;
  allowConvertingAccessCards?: boolean;
  addArrivalAuto?: boolean;
  addVisitAuto?: boolean;
  sessionCheckTimeLimit?: number;
  sessionCheckTimeLimitUpper?: number;
  timeZone?: string;
  prioratizeClasses?: boolean;
  blockTime?: number;
  markArrivalFirst?: boolean;
  openWithoutArrivalMarked?: boolean;
  markMultipleAttendanceAuto?: boolean;
  markArrivalsWhenThereAreVisits?: boolean;
}

export interface partialGymType {
  gymId: number;
  gymName: string;
  status: string;
}

export type GymFormInputs = {
  gymIdApi: number;
  gymName: string;
  arxReferenceId?: string;
  contactPerson: string;
  address: string;
  email: string;
  phoneNumber: string;
  longitude?: number;
  latitude?: number;
  facebookLink?: string;
  instagramLink?: string;
  twitterLink?: string;
  websiteLink?: string;
  externalBookingsLink?: string;
  signUpUrl?: string;
  membershipOnSignup?: number;
  syncAllVisitsWithGenericSubscription?: boolean;
  membershipForVisits: number;
  primaryAccessMethod: string;
  trialMembershipId: number;
  staffMembershipId: number;
  handbackTime?: number;
  gracePeriodWhenDeclined?: number;
  accessPerDayWhenDeclined?: number;
  gracePeriodWhenExpired?: number;
  accessPerDayWhenExpired?: number;
  gracePeriodWhenSuspended?: number;
  accessPerDayWhenSuspended?: number;
  defaultLocationDetectionMethod: string;
  isMobileCredentialAllowed?: boolean;
  lastImportedDate?: string | null;
  status: string;
  imageUrl?: string;
  city?: string;
  sodvinCompanyId?: string;
  cardType?: string;
  allowConvertingAccessCards?: boolean;
  addArrivalAuto?: boolean;
  addVisitAuto?: boolean;
  markArrivalsWhenThereAreVisits?: boolean;
  sessionCheckTimeLimit?: number;
  sessionCheckTimeLimitUpper?: number;
  timeZone: string;
  prioratizeClasses?: boolean;
  blockTime?: number;
  markArrivalFirst?: boolean;
  openWithoutArrivalMarked?: boolean;
  markMultipleAttendanceAuto?: boolean;
};

export const gymDefaultValues: GymFormInputs = {
  sodvinCompanyId: '0',
  gymIdApi: 0,
  gymName: '',
  arxReferenceId: '',
  contactPerson: '',
  address: '',
  email: '',
  phoneNumber: '',
  longitude: undefined,
  latitude: undefined,
  facebookLink: '',
  instagramLink: '',
  twitterLink: '',
  websiteLink: '',
  externalBookingsLink: '',
  signUpUrl: '',
  primaryAccessMethod: '',
  trialMembershipId: -1,
  membershipOnSignup: -1,
  membershipForVisits: -1,
  syncAllVisitsWithGenericSubscription: false,
  staffMembershipId: -1,
  handbackTime: -1,
  gracePeriodWhenDeclined: 0,
  accessPerDayWhenDeclined: 0,
  gracePeriodWhenExpired: 0,
  accessPerDayWhenExpired: 0,
  gracePeriodWhenSuspended: 0,
  accessPerDayWhenSuspended: 0,
  defaultLocationDetectionMethod: 'gps',
  isMobileCredentialAllowed: true,
  lastImportedDate: null,
  status: 'active',
  imageUrl: '',
  city: '',
  cardType: '',
  allowConvertingAccessCards: false,
  addArrivalAuto: false,
  addVisitAuto: false,
  sessionCheckTimeLimit: 0,
  sessionCheckTimeLimitUpper: 0,
  timeZone: '',
  prioratizeClasses: false,
  blockTime: 0,
  markArrivalFirst: false,
  openWithoutArrivalMarked: false,
  markMultipleAttendanceAuto: false,
  markArrivalsWhenThereAreVisits: false
};
