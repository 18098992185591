import { RefObject, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { Card } from 'primereact/card';
import MembershipInfoTemplate from '@gym-templates/MembershipInfo/MembershipInfoTemplate';
import { useAppSelector } from '@gym-redux/store';
import { Membership as MembershipType } from '@gym-particles/types/Membership';

const MainColumn = (props: { alertRef: RefObject<AlertType> }) => {
  const { membershipId, chainId } = useParams<{ membershipId: string; chainId: string }>();

  const membership = useAppSelector((state) =>
    state.membership.membershipsForSite
      .find((g) => g.chainId === +chainId)
      ?.items.find((g) => g.id === +membershipId)
  );
  const { t } = useTranslation();
  const [membershipState, setMembershipState] = useState<MembershipType>();

  /**
   * This function sets siteLocations as an empty array.
   * It needs to be populated by another API call.
   * Due to some issues with async behaviour, that API call is
   * done inside MembershipInfoTemplate.
   */
  const createMembership = async () => {
    const items = {
      id: membership?.id || 0,
      siteId: membership?.siteId || 0,
      importedId: membership?.importedId || 0,
      membershipName: membership?.membershipName || '',
      membershipTypeId: membership?.membershipTypeId || 0,
      membershipType: membership?.membershipType || '',
      isImported: membership?.isImported || false,
      validityPeriod: membership?.validityPeriod || 0,
      status: membership?.status || 'inactive',
      createdBy: membership?.createdBy || 0,
      createdDate: membership?.createdDate || '',
      lastModifiedBy: membership?.lastModifiedBy || 0,
      lastModifiedDate: membership?.lastModifiedDate || '',
      siteLocations: []
    };

    setMembershipState(items);
  };

  useEffect(() => {
    createMembership();
  }, []);

  return (
    <Card className="pt30 pl40 pr40 pb30">
      <MembershipInfoTemplate
        type="modify"
        alertRef={props.alertRef}
        alertMessage={t('MODIFY_MEMBERSHIP.ALERT_MESSAGE_SUCCESS')}
        membership={membershipState}
      />
    </Card>
  );
};

const ModifyMembershipPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { chainId } = useParams<{ chainId: string }>();

  return (
    <TwoColTemplate
      title={t('MODIFY_MEMBERSHIP.PAGE_TITLE')}
      gymChainId={+chainId}
      col1={<MainColumn alertRef={alertRef} />}
      alertRef={alertRef}
      backButtonHandler={() => history.push(`/gymChains/${chainId}/memberships`)}
    />
  );
};
export default ModifyMembershipPage;
