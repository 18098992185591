import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Loader from '@gym-atoms/Loader/Loader';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import Text from '@gym-atoms/Text/Text';
import { AccessCategoryMapping } from '@gym-particles/types/AccessCategoryMapping';
import { deleteAccessCategory, fetchAccessCategories } from '@gym-redux/slices/accessCategorySlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'aws-amplify';
import { isAccessCategoryLinked } from '@gym-graphql/queries';
import { CountResponse } from '@gym-src/API';

const AccessCategoryMappingList = (props: AccessCategoryMappingListProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [
    selectedAccessCategoryRow,
    setSelectedAccessCategoryRow
  ] = useState<AccessCategoryMapping>();
  const [accessCategorySortField, setAccessCategorySortField] = useState('id');
  const [accessCategorySortOrder, setAccessCategorySortOrder] = useState(-1);
  const [accessCategoryOffset, setAccessCategoryOffset] = useState(0);
  const [accessCategoryPageSize, setAccessCategoryPageSize] = useState(10);
  const isAccessCategoryLoading = useAppSelector(
    (state) => state.accessCategory.isAccessCategoriesLoading
  );
  const [accessCategorySearchTerm, setAccessCategorySearchTerm] = useState('');
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [accessCategoryDeletableCheckLoading, setAccessCategoryDeletableCheckLoading] = useState(
    false
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [accessCategoryDeletableStatus, setAccessCategoryDeletableStatus] = useState(false);
  const userRole = useAppSelector((state) => state.user.userRole);
  const totalAccessCategoryCount =
    useAppSelector(
      (state) =>
        state.accessCategory.items.find((g) => g.siteLocationId === props.gymId)?.totalRecords
    ) || 0;

  const accessCategory = useAppSelector(
    (state) => state.accessCategory.items.find((e) => e.siteLocationId === props.gymId)?.items
  );

  useEffect(() => {
    dispatch(
      fetchAccessCategories({
        siteLocationId: props.gymId
      })
    );
  }, []);

  const templateTypeChangeHandler = useCallback(
    (state) => {
      props.templateType(state);
    },
    [props.templateType]
  );

  const templateZoneHandler = useCallback(
    (state) => {
      props.templateAccessCategory(state);
    },
    [props.templateAccessCategory]
  );

  const menuItem = [
    {
      label: t('COMMON.MODIFY'),
      command: () => {
        templateTypeChangeHandler(true);
        templateZoneHandler(selectedAccessCategoryRow);
      }
    },
    {
      label: t('COMMON.DELETE'),
      command: () => {
        templateTypeChangeHandler(false);
        checkAccessCategoryDeletable();
      }
    }
  ];

  const checkAccessCategoryDeletable = async () => {
    setIsDeleteDialogVisible(true);
    setAccessCategoryDeletableCheckLoading(true);
    try {
      const response = await (API.graphql({
        query: isAccessCategoryLinked,
        variables: {
          locationAccessCategoryId: selectedAccessCategoryRow?.id
        }
      }) as Promise<{
        data: { isAccessCategoryLinked: CountResponse };
      }>);
      if (
        response.data.isAccessCategoryLinked.count !== undefined &&
        response.data.isAccessCategoryLinked.count !== null
      ) {
        response.data.isAccessCategoryLinked.count > 0
          ? setAccessCategoryDeletableStatus(false)
          : setAccessCategoryDeletableStatus(true);
      } else {
        console.log('Error Delete Validation Query');
        setErrorDialogVisible(true);
      }
      setAccessCategoryDeletableCheckLoading(false);
    } catch (error) {
      setErrorDialogVisible(true);
      setIsDeleteDialogVisible(false);
      console.log('Catch: ', error);
      setAccessCategoryDeletableStatus(false);
      setAccessCategoryDeletableCheckLoading(false);
    }
  };

  const tablePropsAccessCategory: TableProps<AccessCategoryMapping> = {
    exportFileName: t('ACCESS_CATEGORY.EXPORT_EXCEL_FILE_NAME'),
    setPageSize: setAccessCategoryPageSize,
    setOffset: setAccessCategoryOffset,
    pageSize: accessCategoryPageSize,
    offset: accessCategoryOffset,
    setSortField: setAccessCategorySortField,
    setSortOrder: setAccessCategorySortOrder,
    sortField: accessCategorySortField,
    sortOrder: accessCategorySortOrder,
    totalRecords: totalAccessCategoryCount,
    uniqueFieldName: 'id',
    data: accessCategory || undefined,
    headerText: props.heading,
    searchTerm: accessCategorySearchTerm,
    setSearchTerm: setAccessCategorySearchTerm,
    emptyStateTexts: [t('ACCESS_CATEGORY.EMPTY_STATE_TEXT')],
    columns: [
      {
        field: 'name',
        header: t('ACCESS_CATEGORY.TABLE.HEADER_ACCESS_CATEGORY_NAME'),
        toggable: false,
        truncateText: true,
        sortable: true
      },
      {
        field: 'membershipName',
        header: t('ACCESS_CATEGORY.TABLE.HEADER_ACCESS_CATEGORY_MEMBERSHIP_NAME'),
        toggable: true,
        sortable: true,
        truncateText: true
      },
      {
        field: 'arxCategoryId',
        header: t('ACCESS_CATEGORY.TABLE.HEADER_ACCESS_CATEGORY_ARX_ID'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'program',
        header: t('ACCESS_CATEGORY.TABLE.HEADER_ACCESS_CATEGORY_PROGRAM'),
        toggable: true,
        truncateText: true,
        sortable: true
      },
      {
        field: 'scheduleType',
        header: t('ACCESS_CATEGORY.TABLE.HEADER_ACCESS_CATEGORY_SCHEDULE_TYPE'),
        toggable: true,
        truncateText: true,
        sortable: true
      },
      {
        field: 'schedulesOnly',
        header: t('ACCESS_CATEGORY.TABLE.HEADER_ACCESS_CATEGORY_SCHEDULES_ONLY'),
        toggable: true,
        truncateText: true,
        sortable: true
      },
      {
        field: 'displayStatus',
        header: t('ACCESS_CATEGORY.TABLE.HEADER_ACCESS_CATEGORY_STATUS'),
        toggable: true,
        truncateText: true
      }
    ],
    searchPlaceholderText: t('ACCESS_CATEGORY.SEARCH_FIELD_TEXT'),
    excelBtntext: t('ACCESS_CATEGORY.EXPORT_EXCEL_BUTTON_LABEL'),
    selectedRow: selectedAccessCategoryRow,
    setSelectedRow: setSelectedAccessCategoryRow,
    isContextMenu: true,
    menuItem: menuItem,
    lazy: false
  };

  const deleteDialogHandler = async () => {
    setIsDeleting(true);
    selectedAccessCategoryRow &&
      (await dispatch(deleteAccessCategory(selectedAccessCategoryRow?.id)));
    setIsDeleting(false);
    setIsDeleteDialogVisible(false);
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        {accessCategoryDeletableCheckLoading ? (
          <></>
        ) : !accessCategoryDeletableStatus ? (
          <Button
            label={t('COMMON.OK')}
            variant={ButtonVariants.basic}
            onClick={() => setIsDeleteDialogVisible(false)}
          />
        ) : (
          <>
            <Button
              label={t('COMMON.CANCEL')}
              variant={ButtonVariants.textonly}
              onClick={() => setIsDeleteDialogVisible(false)}
            />
            <Button
              label={t('COMMON.DELETE')}
              variant={ButtonVariants.danger}
              onClick={deleteDialogHandler}
              icon={isDeleting ? 'pi-spinner pi-spin' : ''}
            />
          </>
        )}
      </div>
    );
  };

  const ErrorDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.OK')}
          variant={ButtonVariants.textonly}
          onClick={() => setErrorDialogVisible(false)}
        />
      </div>
    );
  };

  return (
    <>
      <DialogBox
        dialogVisible={isDeleteDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setIsDeleteDialogVisible(false)}
        dialogFooter={<DeleteDialogFooter />}
        dialogClosable={false}
        dialogHeader={t('COMMON.DELETE')}
        content={
          accessCategoryDeletableCheckLoading ? (
            <>
              <div className="mb-3">
                <Loader shape="rectangle" width="full" />
              </div>
              <div className="mb-3">
                <Loader shape="rectangle" width="full" />
              </div>
              <div className="mb-3">
                <Loader shape="rectangle" width="half" />
              </div>
            </>
          ) : (
            <Text>
              {accessCategoryDeletableStatus
                ? `${t('ACCESS_CATEGORY.DELETE_MODAL_CONFIRM')} ${selectedAccessCategoryRow?.name}?`
                : t('ACCESS_CATEGORY.DELETE_MODAL_CANNOT')}
            </Text>
          )
        }
      />
      <DialogBox
        dialogVisible={errorDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setErrorDialogVisible(false)}
        dialogFooter={<ErrorDialogFooter />}
        dialogClosable={false}
        dialogHeader={t('ACCESS_CATEGORY.ERROR_HEADER')}
        content={<Text>{t('ACCESS_CATEGORY.ERROR_MESSAGE')}</Text>}
      />
      {isAccessCategoryLoading ? <Loader shape="table" /> : <Table {...tablePropsAccessCategory} />}
    </>
  );
};

export interface AccessCategoryMappingListProps {
  gymId: number;
  templateType: (e: boolean) => void;
  templateAccessCategory: (e: AccessCategoryMapping) => void;
  heading?: string;
}

export default AccessCategoryMappingList;
