export interface UserType {
  userId: number;
  name: string;
  firstName: string;
  lastName: string;
  memberId: number;
  phone: string;
  email: string;
  roleId: number;
  isActive: boolean;
  createdDate: string;
  createdBy: number | null;
  lastModifiedDate: string;
  lastModifiedBy: number | null;
  profilePicturePath: string;
  roleName: string;
  siteId: number;
  siteName: string;
  siteLocationId: number;
  siteLocationName: string;
  language: string;
  memberArxReferenceId: string;
}

export interface UserTableType {
  userId: number;
  name: string;
  firstName: string;
  lastName: string;
  memberId: number;
  phone: string;
  email: string;
  roleId: number;
  isActive: boolean;
  createdDate: string;
  createdBy: number | null;
  lastModifiedDate: string;
  lastModifiedBy: number | null;
  profilePicturePath: string;
  roleName: string;
  locationInfo: [
    {
      siteId: number;
      siteName: string;
      siteLocationId: number;
      siteLocationName: string;
    }
  ];
}

export type UserFormInput = {
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phone: string;
  password: string;
  confirmPassword: string;
  role: number;
  gymChainId: string[];
  gym: any[];
  profilePicturePath: string | null | undefined;
  accesViaMembership: boolean;
  language: string;
};

export type SiteLocationUserType = {
  id?: number;
  siteId?: number;
  locationId?: number;
  userId?: number;
};

export type MemberLocationType = {
  id?: number | null;
  siteLocationId?: number | null;
  memberId?: number | null;
  status?: string | null;
  isBlocked?: boolean | null;
  contractExpiryDate?: string | null;
};

export type groupedChildrenType = {
  label: string;
  value: {
    siteId: number;
    siteLocationId: number;
  };
};

export type groupedType = {
  label: string;
  items: groupedChildrenType[];
};

export enum UserRole {
  SYSTEM_ADMIN = 'system admin',
  GYM_CHAIN_USER = 'gym chain admin',
  GYM_USER = 'gym admin'
}

export interface Role {
  roleId: number;
  roleName: string;
  roleDescription: string;
}

export type staffAccessMembershipGymType = {
  siteId: number;
  siteLocationId: number;
  siteLocationName: string;
  staffAccessMembershipId: number;
};
