import Text from '@gym-atoms/Text/Text';
import Avatar from '@gym-atoms/Avatar/Avatar';
import Image, { ImageProps } from '@gym-atoms/Image/Image';
import Label from '@gym-atoms/Label/Label';
import { acronym } from '@gym-particles/acronym';

import styles from './SidebarMenuItem.module.scss';

const SidebarMenuItem = ({
  label,
  avatarUrl,
  imageProps,
  count,
  active = false,
  onClick,
  color = 'white',
  avatarFallback = false,
  icon
}: SidebarMenuItemProps) => {
  const getImageOrAvatar = () => {
    if (imageProps) {
      return <Image {...imageProps} />;
    } else if (avatarUrl) {
      return <Avatar image={avatarUrl} size="default" />;
    } else {
      return <Avatar label={acronym(label)} size="default" />;
    }
  };

  const getCountLabel = () => {
    if (!count || count < 0) return null;

    let countStr = '';
    if (count <= 99) {
      countStr = String(count);
    } else {
      countStr = '99+';
    }

    return <Label value={countStr} severity="info" />;
  };

  return (
    <div>
      {icon ? (
        <button
          className={`${styles.sidebarMenuItem} ${active ? styles.active : ''}`}
          onClick={onClick}>
          {avatarUrl || imageProps || avatarFallback ? (
            <div className="sidebarMenuItem-icon">{getImageOrAvatar()}</div>
          ) : null}
          <div className="mr5 sidebarMenuItem-name w-100">
            <Text color={color} bold={true} className="sidebarMenuItem-text">
              {label}
            </Text>
          </div>
          <div className="sidebarMenuItem-count">{getCountLabel()}</div>
        </button>
      ) : (
        <button
          className={`${styles.sidebarMenuItemTextOnly} ${active ? styles.active : ''}`}
          onClick={onClick}>
          <div className="mr5 sidebarMenuItem-textOnly">
            <Text color={color} bold={true} className="sidebarMenuItem-text">
              {label}
            </Text>
          </div>
        </button>
      )}
    </div>
  );
};

export interface SidebarMenuItemProps {
  /** Label to be shown */
  label: string;
  /** URL of the avatar, if applicable */
  avatarUrl?: string;
  /** Props to pass to the `Image` atom, if an image is used instead of an avatar */
  imageProps?: ImageProps;
  /** Number of notifications/alerts (to show in the label). Maxes out at 99 and shows 99+ from there onwards */
  count?: number;
  /** Whether to mark this item as active or not */
  active?: boolean;
  /** Click handler */
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** Color to pass into the Text atom */
  color?: string;
  /** If the avatar URL is not given, show `label`s acronyms as a fallback */
  avatarFallback?: boolean;
  /** If the sidebar item contains icon */
  icon?: boolean;
}

export default SidebarMenuItem;
