import { ReactNode } from 'react';
import { TabView as PrimeTabView, TabPanel } from 'primereact/tabview';
import Label from '@gym-atoms/Label/Label';
import Button from '@gym-atoms/Button/Button';

export interface TabViewItem {
  className?: string;
  header: string;
  count?: number;
  content?: ReactNode;
  headerButton?: boolean;
}

const getTabHeader = (header: string, count?: number, headerButton?: boolean) => {
  return (
    <>
      {headerButton ? (
        <div>
          <Button label={header}></Button>
        </div>
      ) : (
        <div>
          {header}
          <span className="ml10">
            {count && <Label value={count > 99 ? '99+' : String(count)} severity="danger" />}
          </span>
        </div>
      )}
    </>
  );
};

const TabView = ({ items, activeTab, onTabChange, headerClassName }: TabViewProps) => {
  return (
    <PrimeTabView activeIndex={activeTab} onTabChange={onTabChange}>
      {items.map((item, index) => (
        <TabPanel
          headerClassName={`${headerClassName} ${item.className}`}
          key={index}
          header={getTabHeader(item.header, item.count, item.headerButton)}>
          <>{item.content}</>
        </TabPanel>
      ))}
    </PrimeTabView>
  );
};

export interface OnTabChangeEvent {
  originalEvent: Event;
  index: number;
}

export interface TabViewProps {
  /** Items to display in the tabs. Should be of the `TabViewItem` type */
  items: Array<TabViewItem>;
  /*
   * Sets the current active tab by index. Starts from 0. If current tab
   * needs to be changed programmatically, `activeTab` and `onTabChange`
   * should be provided.
   */
  activeTab?: number;
  /** Runs when active tab is changed */
  onTabChange?: (e: OnTabChangeEvent) => void;
  /** Header class name */
  headerClassName?: string;
}

export default TabView;
