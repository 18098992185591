import Text, { TextSizes } from '@gym-atoms/Text/Text';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Input from '@gym-atoms/Input/Input';
import {
  AccessCategoryMapping,
  AccessCategoryMappingFormInputs
} from '@gym-particles/types/models';
import { useTranslation } from 'react-i18next';
import Dropdown, { DropdownChangeEvent, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { API } from 'aws-amplify';
import { getAllMembershipsForSiteLocation } from '@gym-graphql/queries';
import {
  AddSiteLocationAccessCategoryInput,
  LambdaResponse,
  MembershipInfo,
  SiteLocationAccessCategoryInput
} from '@gym-src/API';
import { fetchAccessCategories } from '@gym-redux/slices/accessCategorySlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';
import { addAccessCategory, modifyAccessCategory } from '@gym-graphql/mutations';
import Checkbox from '@gym-atoms/Checkbox/Checkbox';

const GymAccessCategoryMappingTemplate = (props: GymAccessCategoryMappingTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { chainId, gymId } = useParams<{ chainId: string; gymId: string }>();
  const [isInserted, setIsInserted] = useState(false);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const [defaultSelectedMembershipId, setDefaultSelectedMembershipId] = useState(-1);
  const [schedulesOnly, setSchedulesOnly] = useState(
    props.accessCategoryMapping?.schedulesOnly === 'true' ? true : false
  );
  const dispatch = useAppDispatch();
  const templateTypeChangeHandler = useCallback(
    (state) => {
      if (props.templateType) {
        props.templateType(state);
      }
    },
    [props.templateType]
  );
  const userId = useAppSelector((state) => state.user.userId);

  useEffect(() => {
    dispatch(getGymChainById(+chainId));
  }, [chainId]);

  const getTranslationKey = (e: string): string => {
    return props.type === 'new' ? 'ADD_NEW_ACCESS_CATEGORY.' + e : 'MODIFY_ACCESS_CATEGORY.' + e;
  };

  const accessCategoryDefaultValues: AccessCategoryMappingFormInputs = {
    name: props.accessCategoryMapping?.name || '',
    arxCategoryId: props.accessCategoryMapping?.arxCategoryId || '',
    gender: '',
    membershipIds: props.accessCategoryMapping?.membershipId
      ? props.accessCategoryMapping.membershipId
      : [],
    scheduleType: props.accessCategoryMapping?.scheduleType
      ? props.accessCategoryMapping.scheduleType
      : [],
    program: props.accessCategoryMapping?.program ? props.accessCategoryMapping.program : [],
    status: props.accessCategoryMapping?.status || '',
    schedulesOnly: props.accessCategoryMapping?.schedulesOnly === 'true' ? true : false
  };

  const [formValues, setFormValues] = useState(accessCategoryDefaultValues);
  const [membershipsDropDownItems, setMembershipsDropDownItems] = useState<Array<SelectItem>>([]);
  const [scheduleTypeDropDownItems, setScheduleTypeDropDownItems] = useState<Array<SelectItem>>([]);
  const [currentScheduleType, setCurrentScheduleType] = useState<Array<string>>([]);
  const [programDropDownItems, setProgramDropDownItems] = useState<Array<SelectItem>>([]);
  const [currentProgram, setCurrentProgram] = useState<Array<string>>([]);
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    reset,
    getValues
  } = useForm<AccessCategoryMappingFormInputs>({
    defaultValues: accessCategoryDefaultValues
  });

  const genderDropDownItems = [
    {
      label: t(getTranslationKey('FORM_GENDER_DROPDOWN_MALE')),
      value: 'male'
    },
    {
      label: t(getTranslationKey('FORM_GENDER_DROPDOWN_FEMALE')),
      value: 'female'
    },
    {
      label: t(getTranslationKey('FORM_GENDER_DROPDOWN_NA')),
      value: 'n/a'
    }
  ];

  const statusDropDownItems = [
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN_ACTIVE')),
      value: 'active'
    },
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN_INACTIVE')),
      value: 'inactive'
    }
  ];

  const emptyValues: AccessCategoryMappingFormInputs = {
    name: '',
    membershipIds: [],
    program: '',
    scheduleType: '',
    arxCategoryId: '',
    gender: '',
    status: ''
  };

  const inputFieldChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof AccessCategoryMappingFormInputs, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  const dropdownChangeHandler = (e: DropdownChangeEvent) => {
    console.log(e);
    setValue(e.target.name as keyof AccessCategoryMappingFormInputs, e.value);
    setFormValues({ ...formValues, [e.target.name]: e.value });
    if (e.target.name === 'scheduleType') {
      setCurrentScheduleType(e.value);
      setCurrentProgram([]);
    }
    if (e.target.name === 'program') {
      setCurrentProgram(e.value);
    }
  };

  useEffect(() => {
    register('name', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('membershipIds', {
      validate: (value) => {
        if (value === -1) {
          return false;
        } else if (Array.isArray(value) && value.length === 0) {
          return false;
        } else {
          return true;
        }
      }
    });
    register('arxCategoryId', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('status', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
  }, [register]);

  useEffect(() => {
    setValue('membershipId', props.accessCategoryMapping?.membershipId || -1);
    getMemberships();
  }, [currentScheduleType, setCurrentScheduleType, currentProgram, setCurrentProgram]);

  const getMemberships = async () => {
    console.log(currentScheduleType);
    console.log(currentProgram);
    try {
      const response = (API.graphql({
        query: getAllMembershipsForSiteLocation,
        variables: {
          siteLocationId: gymId
        }
      }) as Promise<{
        data: { getAllMembershipsForSiteLocation: MembershipInfo[] };
      }>).then((e) => {
        console.log(currentScheduleType.length);
        console.log(currentProgram.length);
        console.log(e);
        let membershipDropDownItems: SelectItem[];
        if (currentScheduleType.length === 0 && currentProgram.length === 0) {
          membershipDropDownItems =
            e.data.getAllMembershipsForSiteLocation.map((data) => {
              return {
                label: data.membershipName + ' (' + data.importedId + ') - ' + data.program || '',
                value: data.siteLocationMembershipId || -1
              };
            }) || [];
        } else {
          membershipDropDownItems =
            e.data.getAllMembershipsForSiteLocation
              .filter((x) =>
                currentScheduleType.length > 0 && currentProgram.length > 0 && x.program !== null
                  ? currentScheduleType.includes(x.scheduleType) &&
                    currentProgram.includes(x.program)
                  : currentScheduleType.length > 0 &&
                    currentProgram.length > 0 &&
                    x.program === null
                  ? currentScheduleType.includes(x.scheduleType)
                  : currentScheduleType.length > 0
                  ? currentScheduleType.includes(x.scheduleType)
                  : currentProgram.length > 0
                  ? currentProgram.includes(x.program)
                  : []
              )
              .map((data) => {
                return {
                  label: data.membershipName + ' (' + data.importedId + ') - ' + data.program || '',
                  value: data.siteLocationMembershipId || -1
                };
              }) || [];
        }
        const scheduleTypeDropDownItems: SelectItem[] =
          [...new Set(e.data.getAllMembershipsForSiteLocation.map((data) => data.scheduleType))]
            .map((scheduleType) => {
              return e.data.getAllMembershipsForSiteLocation.find(
                (obj) => obj.scheduleType === scheduleType
              );
            })
            .map((data) => {
              return {
                label: data.scheduleType || '',
                value: data.scheduleType || ''
              };
            }) || [];
        setScheduleTypeDropDownItems(scheduleTypeDropDownItems);
        let programDropDownItems: SelectItem[];
        if (currentScheduleType.length === 0) {
          programDropDownItems =
            [...new Set(e.data.getAllMembershipsForSiteLocation.map((data) => data.program))]
              .map((program) => {
                return e.data.getAllMembershipsForSiteLocation.find(
                  (obj) => obj.program === program
                );
              })
              .map((data) => {
                return {
                  label: data.program || '',
                  value: data.program || ''
                };
              }) || [];
        } else {
          programDropDownItems =
            [...new Set(e.data.getAllMembershipsForSiteLocation.map((data) => data.program))]
              .map((program) => {
                return e.data.getAllMembershipsForSiteLocation.find(
                  (obj) => obj.program === program
                );
              })
              .filter((x) => currentScheduleType.includes(x.scheduleType))
              .map((data) => {
                return {
                  label: data.program || '',
                  value: data.program || ''
                };
              }) || [];
        }
        setProgramDropDownItems(programDropDownItems);
        setMembershipsDropDownItems(membershipDropDownItems);

        props.type === 'modify' &&
          setDefaultSelectedMembershipId(props.accessCategoryMapping?.membershipId || -1);
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  // setting up selected memberships when modifying
  useEffect(() => {
    if (!defaultSelectedMembershipId) {
      return;
    }

    setFormValues({
      ...formValues,
      membershipIds: props.accessCategoryMapping?.membershipId
        ? props.accessCategoryMapping.membershipId
        : [],
      scheduleType: props.accessCategoryMapping?.scheduleType
        ? props.accessCategoryMapping.scheduleType
        : [],
      program: props.accessCategoryMapping?.program ? props.accessCategoryMapping.program : []
    });
    setCurrentScheduleType(
      props.accessCategoryMapping?.scheduleType ? [props.accessCategoryMapping.scheduleType] : []
    );
    setCurrentProgram(
      props.accessCategoryMapping?.program ? [props.accessCategoryMapping.program] : []
    );
    setValue('membershipId', props.accessCategoryMapping?.membershipId || -1);
  }, [defaultSelectedMembershipId]);

  // fetch all the memberships relavent to selected gym
  useEffect(() => {
    getMemberships();
  }, []);

  useEffect(() => {
    setFormValues(accessCategoryDefaultValues);
    reset(accessCategoryDefaultValues);
    props.type === 'modify' &&
      setDefaultSelectedMembershipId(props.accessCategoryMapping?.membershipId || -1);
    setSchedulesOnly(props.accessCategoryMapping?.schedulesOnly === 'true' ? true : false);
  }, [props]);

  const clearForm = () => {
    resetForm();
    dispatch(
      fetchAccessCategories({
        siteLocationId: +gymId
      })
    );
  };

  const resetForm = () => {
    setFormValues(emptyValues);
    reset(emptyValues);
    setSchedulesOnly(false);
  };

  const addAccessCategoryMappingData = async (accessCategory: AccessCategoryMappingFormInputs) => {
    const addAccessCategoryInput: AddSiteLocationAccessCategoryInput = {
      name: accessCategory.name.replace(/'/g, "''"),
      siteLocationMembershipIds: Array.isArray(accessCategory.membershipIds)
        ? accessCategory.membershipIds.map((id) => +id)
        : [accessCategory.membershipIds],
      gender: '',
      arxAccessCategoryId: accessCategory.arxCategoryId.replace(/'/g, "''"),
      status: accessCategory.status,
      createdBy: userId,
      lastModifiedBy: userId,
      schedulesOnly: schedulesOnly,
      siteLocationMemberships: []
    };

    addAccessCategoryInput.siteLocationMemberships = addAccessCategoryInput.siteLocationMembershipIds.map(
      (id) => membershipsDropDownItems.find((m) => m.value === id)?.label || ''
    );

    try {
      const result = await (API.graphql({
        query: addAccessCategory,
        variables: {
          input: addAccessCategoryInput
        }
      }) as Promise<{
        data: { addAccessCategory: LambdaResponse };
      }>);
      if (result.data.addAccessCategory.error) {
        throw result.data.addAccessCategory;
      }
      resetForm();
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const modifyAccessCategoryMappingData = async (
    accessCategory: AccessCategoryMappingFormInputs
  ) => {
    const modifyAccessCategoryInput: SiteLocationAccessCategoryInput = {
      id: props.accessCategoryMapping?.id,
      name: accessCategory.name.replace(/'/g, "''"),
      siteLocationMembershipId: Array.isArray(accessCategory.membershipIds)
        ? +accessCategory.membershipIds[0]
        : accessCategory.membershipIds,
      gender: '',
      arxAccessCategoryId: accessCategory.arxCategoryId.replace(/'/g, "''"),
      status: accessCategory.status,
      createdBy: props.accessCategoryMapping?.createdBy || -1,
      lastModifiedBy: userId,
      schedulesOnly: schedulesOnly
    };

    try {
      await API.graphql({
        query: modifyAccessCategory,
        variables: {
          input: modifyAccessCategoryInput
        }
      });
      resetForm();
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const onSubmitHandler = async (data: AccessCategoryMappingFormInputs) => {
    setIsInserted(true);
    if (props.type === 'new') {
      await addAccessCategoryMappingData(data);
    } else {
      await modifyAccessCategoryMappingData(data);
      templateTypeChangeHandler(false);
    }
    dispatch(
      fetchAccessCategories({
        siteLocationId: +gymId
      })
    );
    setCurrentScheduleType([]);
    setCurrentProgram([]);
    setIsInserted(false);
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}</Text>}
        />
      </div>
    );
  };

  return (
    <div className="ml30 mt10">
      <div>
        <Text bold={true} size={TextSizes.large} className="text-2xl">
          {props.heading}
        </Text>
      </div>
      <div className="col-md-6">
        <form className="input-mb20 py-4" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <div className="row">
            <div className="col-12">
              <Input
                label={t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_NAME_LABEL'))}
                variant="basic"
                name="name"
                value={formValues.name}
                onChange={inputFieldChangeHandler}
                error={errors.name ? true : false}
                errorMessage={errors.name?.message}
                placeholder={t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_NAME_PLACEHOLDER'))}
                required={true}
              />
            </div>
          </div>
          <div className="row mb25">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_SCHEDULE_TYPE_LABEL'))}
              </label>
              <Dropdown
                chips={props.type === 'new' ? true : false}
                grouped={props.type === 'new' ? true : false}
                name="scheduleType"
                options={scheduleTypeDropDownItems}
                onChange={dropdownChangeHandler}
                value={formValues.scheduleType}
                placeholder={t(
                  getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_SCHEDULE_TYPE_PLACEHOLDER')
                )}
              />
            </div>
          </div>
          <div className="row mb25">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_PROGRAM_LABEL'))}
              </label>
              <Dropdown
                chips={props.type === 'new' ? true : false}
                grouped={props.type === 'new' ? true : false}
                name="program"
                options={programDropDownItems}
                onChange={dropdownChangeHandler}
                value={formValues.program}
                placeholder={t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_PROGRAM_PLACEHOLDER'))}
              />
            </div>
          </div>
          <div className="row mb25">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_MEMBERSHIP_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                chips={props.type === 'new' ? true : false}
                grouped={props.type === 'new' ? true : false}
                name="membershipIds"
                options={membershipsDropDownItems}
                onChange={dropdownChangeHandler}
                value={formValues.membershipIds}
                error={errors.membershipIds ? true : false}
                disabled={currentScheduleType.length === 0 && currentProgram.length === 0}
                placeholder={t(
                  getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_MEMBERSHIP_PLACEHOLDER')
                )}
              />
              {errors.membershipIds && (
                <Text className="p-error mt10">
                  {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                </Text>
              )}
            </div>
          </div>
          <div className="row mb20" hidden>
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_GENDER_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                name="gender"
                options={genderDropDownItems}
                value={formValues.gender}
                onChange={dropdownChangeHandler}
                placeholder={t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_GENDER_PLACEHOLDER'))}
                error={errors.gender ? true : false}
              />
            </div>
            {errors.gender && (
              <Text className="p-error mt10">
                {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
              </Text>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <Input
                label={t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_ARX_ID_LABEL'))}
                variant="basic"
                name="arxCategoryId"
                value={formValues.arxCategoryId}
                onChange={inputFieldChangeHandler}
                error={errors.arxCategoryId ? true : false}
                errorMessage={errors.arxCategoryId?.message}
                placeholder={t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_ARX_ID_PLACEHOLDER'))}
                required={true}
              />
            </div>
          </div>
          <div className="row mb40">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_STATUS_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                name="status"
                options={statusDropDownItems}
                value={formValues.status}
                onChange={dropdownChangeHandler}
                placeholder={t(getTranslationKey('FORM_INPUT_ACCESS_CATEGORY_STATUS_PLACEHOLDER'))}
                error={errors.status ? true : false}
              />
            </div>
            {errors.status && (
              <Text className="p-error mt10">
                {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
              </Text>
            )}
            <div className="mt25">
              <Checkbox
                name="schedulesOnly"
                label={t(getTranslationKey('SCHEDULES_ONLY_CHECKBOX'))}
                checked={schedulesOnly}
                onChange={() => {
                  setSchedulesOnly(!schedulesOnly);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex btn-min-w-110">
                <div className="mr15">
                  <Button
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                    label={t(getTranslationKey('FORM_BTN_LABEL_SAVE'))}
                    size={ButtonSizes.medium}
                    disabled={isInserted}
                  />
                </div>
                <Button
                  label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
                  variant={ButtonVariants.textonly}
                  onClick={(e) => {
                    e.preventDefault();
                    clearForm();
                    templateTypeChangeHandler(false);
                  }}
                  disabled={isInserted}
                />
              </div>
              <ActionFailedDialog />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

type GymAccessCategoryMappingTemplateType = 'new' | 'modify';

export interface GymAccessCategoryMappingTemplateProps {
  /** Type of the template: new or modify */
  type: GymAccessCategoryMappingTemplateType;
  /** Heading of the template */
  heading?: string;
  /** form values*/
  accessCategoryMapping?: AccessCategoryMapping | undefined;
  /** template change function */
  templateType?: (e: boolean) => void;
}

export default GymAccessCategoryMappingTemplate;
