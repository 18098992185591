import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { useAppSelector, useAppDispatch } from '@gym-redux/store';
import Loader from '@gym-atoms/Loader/Loader';
import { DaySchedule } from '@gym-particles/types/DaySchedules';
import {
  deleteDaySchedule,
  fetchDayScheduleLinkCount,
  fetchDaySchedules
} from '@gym-redux/slices/daySchedulesSlice';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Text from '@gym-atoms/Text/Text';
import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import { UserRole } from '@gym-particles/types/User';

const DaySchedulesList = (props: DaySchedulesListProps) => {
  const isDayScheduleLoading = useAppSelector((state) => state.daySchedules.isDayScheduleLoading);
  const dayScheduleList = useAppSelector((state) => state.daySchedules.items);
  const totalDaySchedules = useAppSelector((state) => state.daySchedules.totalRecords);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<DaySchedule>();
  const [sortField, setSortField] = useState('createdDate');
  const [sortOrder, setSortOrder] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);

  useEffect(() => {
    dispatch(
      fetchDaySchedules({
        offset: searchTerm !== '' ? 0 : offset,
        pageSize: pageSize,
        sortField:
          sortField === 'weekdayName'
            ? 'weekdayId'
            : sortField === 'startTime' || sortField === 'endTime'
            ? `TIME(${sortField})`
            : sortField,
        sortOrder: sortOrder,
        search: { searchField: '', searchText: searchTerm }
      })
    );
  }, [sortField, sortOrder, offset, pageSize, searchTerm]);

  useEffect(() => {
    selectedRow && dispatch(fetchDayScheduleLinkCount(selectedRow?.id));
  }, [selectedRow]);

  const dayScheduleLinkCount = useAppSelector((state) => state.daySchedules.dayScheduleLinkCount);

  const templateTypeChangeHandler = useCallback(
    (state) => {
      props.templateType(state);
    },
    [props.templateType]
  );

  const templateDayScheduleHandler = useCallback(
    (state) => {
      props.templateDay(state);
    },
    [props.templateDay]
  );

  const deleteDialogHandler = () => {
    selectedRow && dispatch(deleteDaySchedule(selectedRow?.id));
    setDeleteDialogVisible(false);
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        {dayScheduleLinkCount && dayScheduleLinkCount > 0 ? (
          <Button
            label={t('COMMON.OK')}
            variant={ButtonVariants.basic}
            onClick={() => setDeleteDialogVisible(false)}
          />
        ) : (
          <>
            <Button
              label={t('COMMON.CANCEL')}
              variant={ButtonVariants.textonly}
              onClick={() => setDeleteDialogVisible(false)}
            />
            <Button
              label={t('COMMON.DELETE')}
              variant={ButtonVariants.danger}
              onClick={deleteDialogHandler}
            />
          </>
        )}
      </div>
    );
  };
  const userRole = useAppSelector((state) => state.user.userRole);
  const tableProps: TableProps<DaySchedule> = {
    exportFileName: t('DAYSCHEDULES.EXPORT_EXCEL_FILE_NAME'),
    setPageSize: setPageSize,
    setOffset: setOffset,
    pageSize: pageSize,
    offset: offset,
    setSortField: setSortField,
    setSortOrder: setSortOrder,
    sortField: sortField,
    sortOrder: sortOrder,
    totalRecords: totalDaySchedules,
    data: userRole === UserRole.SYSTEM_ADMIN ? dayScheduleList : [],
    searchTerm: searchTerm,
    setSearchTerm: setSearchTerm,
    columns: [
      {
        field: 'name',
        header: t('DAYSCHEDULES.TABLE_HEADER_NAME'),
        toggable: false,
        sortable: true,
        imageField: 'imageUrl'
      },
      {
        field: 'weekdayName',
        header: t('DAYSCHEDULES.TABLE_HEADER_DAY'),
        toggable: true,
        sortable: true,
        truncateText: true
      },
      {
        field: 'arxReferenceId',
        header: t('DAYSCHEDULES.TABLE_HEADER_ARX_SCHEDULE_ID'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'startTime',
        header: t('DAYSCHEDULES.TABLE_HEADER_START_TIME'),
        toggable: true,
        sortable: true,
        truncateText: true
      },
      {
        field: 'endTime',
        header: t('DAYSCHEDULES.TABLE_HEADER_END_TIME'),
        toggable: true,
        sortable: true,
        truncateText: true
      },
      {
        field: 'displayStatus',
        header: t('DAYSCHEDULES.TABLE_HEADER_STATUS'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'createdDate',
        header: t('DAYSCHEDULES.TABLE_HEADER_CREATED_DATE'),
        toggable: true,
        truncateText: true
      }
    ],
    searchPlaceholderText: t('DAYSCHEDULES.SEARCH_PLACEHOLDER'),
    excelBtntext: t('DAYSCHEDULES.EXPORT_EXCEL_BUTTON_LABEL'),
    selectedRow: selectedRow,
    setSelectedRow: setSelectedRow,
    isContextMenu: userRole === UserRole.SYSTEM_ADMIN ? true : false,
    headerText: t('DAYSCHEDULES.HEADER_TEXT'),
    emptyStateTexts: [t('DAYSCHEDULES.EMPTY_STATE_TEXT')],
    menuItem:
      userRole === UserRole.SYSTEM_ADMIN
        ? [
            {
              label: t('COMMON.MODIFY'),
              command: () => {
                templateTypeChangeHandler(true);
                templateDayScheduleHandler(selectedRow);
              }
            },
            {
              label: t('COMMON.DELETE'),
              command: () => setDeleteDialogVisible(true)
            }
          ]
        : []
  };

  return (
    <div>
      <DialogBox
        dialogVisible={deleteDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setDeleteDialogVisible(false)}
        dialogFooter={<DeleteDialogFooter />}
        dialogClosable={false}
        dialogHeader={
          dayScheduleLinkCount && dayScheduleLinkCount > 0
            ? t('DAYSCHEDULES.DELETE_MODAL_HEADER_HAVE_GYM_SCHEDULES')
            : t('COMMON.DELETE')
        }
        content={
          <Text>
            {dayScheduleLinkCount && dayScheduleLinkCount > 0
              ? t('DAYSCHEDULES.DELETE_MODAL_MESSAGE_HAVE_GYM_SCHEDULES')
              : t('DAYSCHEDULES.DELETE_MODAL_CONFIRM')}{' '}
            <span className="fw-bold">{selectedRow?.name}</span> ?
          </Text>
        }
      />
      {isDayScheduleLoading ? (
        <Loader shape="table" />
      ) : (
        <>{dayScheduleList && <Table {...tableProps} />}</>
      )}
    </div>
  );
};

export default DaySchedulesList;

interface DaySchedulesListProps {
  templateType: (e: boolean) => void;
  templateDay: (e: DaySchedule) => void;
}
