import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { InputTextarea } from 'primereact/inputtextarea';
import { FormEvent } from 'react';

type InputVariants = 'basic' | 'textarea' | 'icon_left' | 'icon_right' | 'password';

const Input = ({
  icon,
  label,
  errorMessage,
  error = false,
  disabled = false,
  required = false,
  variant = 'basic',
  noMarginBottom = false,
  onKeyPress,
  ...props
}: InputProps) => {
  const finalProps = {
    className: error ? 'p-invalid' : undefined,
    disabled,
    required,
    ...props
  };

  const getInputField = (variantType: InputVariants) => {
    switch (variantType) {
      case 'basic':
        return <InputText id={label} {...finalProps} onKeyPress={onKeyPress} />;
      case 'textarea': {
        const txtAreaOnChange = finalProps.onChangeTA;
        delete finalProps.onChange;
        delete finalProps.onBlur;
        delete finalProps.onChangeTA;
        return (
          <InputTextarea
            id={label}
            {...finalProps}
            onChange={txtAreaOnChange}
            onBlur={finalProps.onBlurTA}
          />
        );
      }
      case 'icon_right':
        return (
          <div className="p-input-icon-right w-100">
            <i className={`pi ${icon}`} />
            <InputText id={label} {...finalProps} />
          </div>
        );
      case 'icon_left':
        return (
          <div className="p-input-icon-left w-100">
            <i className={`pi ${icon}`} />
            <InputText id={label} {...finalProps} />
          </div>
        );
      case 'password':
        return <Password id={label} {...finalProps} feedback={false} onKeyPress={onKeyPress} />;
    }
  };

  return (
    <>
      <div className={noMarginBottom ? '' : 'mb25'}>
        <label htmlFor={label} className="fw-bold">
          {label} {required && <span className="p-error">*</span>}
        </label>
        <br />
        {getInputField(variant)}
        {error && <p className={`p-error ${noMarginBottom ? '' : 'mb15'}`}>{errorMessage}</p>}
      </div>
    </>
  );
};

export interface InputProps {
  /** Label to be displayed with the field */
  label?: string;
  /** Whether this field contains an error */
  error?: boolean;
  /** Error message to show (only when `error = true`) */
  errorMessage?: string;
  /** Disable typing on this field */
  disabled?: boolean;
  /** Placeholder when the field is empty */
  placeholder?: string;
  /** Whether this is a required field */
  required?: boolean;
  /** Which variant to use */
  variant?: InputVariants;
  /**
   * Which icon to use from the PrimeIcons library. (Try: pi-search, pi-cog, pi-save)
   * For a complete list, see [here](https://www.primefaces.org/primereact/showcase/#/icons)
   */
  icon?: string;
  /** Name is needed when working with forms */
  name?: string;
  /**
   * Change handler is needed when working with forms. (See example stories)
   * For handling changes in the textarea variant, see `onChangeTA` prop
   */
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  /** If the component is a textarea variant, use this change handler instead of `onChange` */
  onChangeTA?: (e: FormEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FormEvent<HTMLInputElement>) => void;
  onBlurTA?: (e: FormEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
  /**
   * Value/content of the Input atom. This is required for controlled components.
   * When using controlled components, use a state as value, and its set state function as
   * onChange handler.
   * Eg: `<Input value={someState} onChange={(e) => setSomeState(e.currentTarget.value)} />`
   */
  value?: string;
  /** onKeyPress is needed when submit data with pressing a key Eg: Input user credentials and press enter for submit */
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  /* Set to true if the bottom margins of input & error need to be removed */
  noMarginBottom?: boolean;
}

export default Input;
