import { RefObject, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { Card } from 'primereact/card';
import MembershipInfoTemplate from '@gym-templates/MembershipInfo/MembershipInfoTemplate';

interface chainIdType {
  chainId: string;
}

const MainColumn = (props: { alertRef: RefObject<AlertType> }) => {
  const { t } = useTranslation();
  return (
    <Card className="pt30 pl40 pr40 pb30">
      <MembershipInfoTemplate
        type="new"
        alertRef={props.alertRef}
        alertMessage={t('ADD_NEW_MEMBERSHIP.ALERT_MESSAGE_SUCCESS')}
      />
    </Card>
  );
};

const AddNewMembershipPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { chainId } = useParams<chainIdType>();

  return (
    <TwoColTemplate
      title={t('ADD_NEW_MEMBERSHIP.PAGE_TITLE')}
      gymChainId={+chainId}
      col1={<MainColumn alertRef={alertRef} />}
      alertRef={alertRef}
      backButtonHandler={() => history.push(`/gymChains/${chainId}/memberships`)}
    />
  );
};

export default AddNewMembershipPage;
