import { AlertType } from '@gym-atoms/Alert/Alert';
import { AccessCategoryMapping } from '@gym-particles/types/AccessCategoryMapping';
import GymAccessCategoryMappingTemplate from '@gym-templates/GymAccessCategoryMapping/GymAccessCategoryMappingTemplate';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const AccessCategoryMappingForm = (props: AccessCategoryMappingFormProps) => {
  const alertRef = useRef<AlertType>(null);
  const { t } = useTranslation();

  return (
    <GymAccessCategoryMappingTemplate
      type={props.type}
      heading={t(`${props.type === 'new' ? 'ADD_NEW' : 'MODIFY'}_ACCESS_CATEGORY.PAGE_TITLE`)}
      accessCategoryMapping={props.accessCategoryData}
      templateType={props.templateType}
    />
  );
};

export default AccessCategoryMappingForm;

type AccessCategoryTemplateType = 'new' | 'modify';

export interface AccessCategoryMappingFormProps {
  type: AccessCategoryTemplateType;
  accessCategoryData?: AccessCategoryMapping;
  templateType?: (e: boolean) => void;
}
