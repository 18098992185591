import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootReducer from './rootReducer';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: ['user/login'],
    ignoredPaths: ['user.cognitoUser']
  },
  immutableCheck: {
    ignoredPaths: ['user.cognitoUser']
  }
});

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// use this typed function instead of useSelector
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
