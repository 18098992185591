import PricingRelationshipForm from '@gym-organisms/PricingRelationships/PricingRelationshipForm';
import PricingRelationshipList from '@gym-organisms/PricingRelationships/PricingRelationshipList';
import { PricingRelationshipTable } from '@gym-particles/types/PricingRelationships';
import { UserRole } from '@gym-particles/types/User';
import { useAppSelector } from '@gym-redux/store';
import { Card } from 'primereact/card';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const GymChainPricingRelationshipsPage = () => {
  const [isModifyTemplate, setIsModifyTemplate] = useState(false);
  const { t } = useTranslation();
  const { chainId } = useParams<{ chainId: string }>();
  const [programs, setPrograms] = useState<PricingRelationshipTable>();
  const userRole = useAppSelector((state) => state.user.userRole);

  return (
    <>
      {(userRole === UserRole.SYSTEM_ADMIN || userRole === UserRole.GYM_USER) && (
        <Card className="p-card-w-table">
          <PricingRelationshipForm
            type={isModifyTemplate ? 'modify' : 'new'}
            programData={isModifyTemplate ? programs : undefined}
            templateType={setIsModifyTemplate}
          />
        </Card>
      )}
      <Card className="p-card-w-table">
        <PricingRelationshipList
          templateType={setIsModifyTemplate}
          chainId={+chainId}
          heading={t('PRICING_RELATIONSHIPS.TABLE_HEADING_PRICING_RELATIONSHIPS')}
          templatePrograms={setPrograms}
        />
      </Card>
    </>
  );
};

export default GymChainPricingRelationshipsPage;
