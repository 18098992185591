import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from '@gym-assets/images/sidebarlogo.svg';
import type { GymChain, GymType } from '@gym-particles/types/models';
import { UserRole } from '@gym-particles/types/User';
import { useAppSelector } from '@gym-redux/store';
import Image from '@gym-atoms/Image/Image';
import Text from '@gym-atoms/Text/Text';
import type { ResetPasswordFormValues } from '@gym-molecules/ResetPassword/ResetPassword';
import SidebarMenuItem from '@gym-molecules/SidebarMenuItem/SidebarMenuItem';
import SidebarProfile from '@gym-molecules/SidebarProfile/SidebarProfile';
import { logout } from '@gym-redux/slices/userSlice';
import { sidebarPropsSlice } from '@gym-redux/slices/sidebarPropsSlice';
import styles from './Sidebar.module.scss';
import { isMindbodyEnabled } from '@gym-particles/util';

const Sidebar = ({ gymChains, gyms }: SidebarProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user);
  const features = useAppSelector((state) => state.configuration.features);

  const [sidebarSelected, setSidebarSelected] = useState<string | number | undefined>('home');
  const homeButtonImageProps = {
    source: 'https://www.iconpacks.net/icons/1/free-home-icon-189-thumb.png',
    width: 22,
    height: 22
  };

  /**
   * Handles click events on the sidebar menu
   * and routes to pages as needed.
   * @param clickedItem identifier for the menu item
   */
  const sidebarClickHandler = (
    clickedItem: string | number | undefined,
    isGymChain = false,
    isGym = false,
    gymChainId: string | number | undefined = 0
  ) => {
    setSidebarSelected(clickedItem);
    dispatch(sidebarPropsSlice.actions.hide());
    if (isGymChain) {
      userRole === UserRole.GYM_USER
        ? history.push(`/gymChains/${clickedItem}/gyms`)
        : history.push(`/gymChains/${clickedItem}/activity`);
      return;
    }
    if (isGym) {
      console.log(`/gymChains/${gymChainId}/gyms/${clickedItem}/dashboard`);
      history.push(`/gymChains/${gymChainId}/gyms/${clickedItem}/dashboard`);
      return;
    }
    switch (clickedItem) {
      case 'home':
        history.push('/home');
        break;
      case 'addNewGym':
        history.push('/addGymChain');
        break;
      case 'userMgt':
        history.push('/users');
        break;
      case 'notifications':
      case 'faqs':
        history.push('/admin');
        break;
      case 'manageDaySchedules':
        history.push('/daySchedules');
        break;
      case 'daySchedulesRequests':
        history.push('/scheduleRequests');
        break;
      default:
        history.push('/accounts');
    }
  };

  /** This function will be passed down to SidebarProfile molecule */
  const handleSignOut = () => {
    dispatch(logout());
    Auth.signOut();
    history.push('/login');
  };

  /** This function will be passed down to SidebarProfile molecule */
  const handleResetPassword = (
    data: ResetPasswordFormValues,
    callback: (error: boolean, errorCode?: string) => void
  ) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, data.oldPassword, data.password);
      })
      .then(() => {
        callback(false);
        handleSignOut();
      })
      .catch((err) => {
        callback(true, err.code || 'err');
        console.log('Changed password failed: ', err);
      });
  };

  const userRole = useAppSelector((state) => state.user.userRole);

  const formatUserRole = (roleName: string) => {
    const roleArr = roleName.split(' ');
    for (let i = 0; i < roleArr.length; i++) {
      roleArr[i] = roleArr[i].charAt(0).toUpperCase() + roleArr[i].slice(1);
    }
    return roleArr.join(' ');
  };

  const formattedRoleName = formatUserRole(
    userRole === UserRole.SYSTEM_ADMIN
      ? t('USER_ROLE.SYSTEM_ADMIN')
      : userRole === UserRole.GYM_CHAIN_USER
      ? t('USER_ROLE.GYM_CHAIN_USER')
      : userRole === UserRole.GYM_USER
      ? t('USER_ROLE.GYM_USER')
      : ''
  );

  const scheduleRequestsCount = useAppSelector((state) => state.scheduleRequests.totalRecords);
  return (
    <div data-cy={'sidebar-organism'} className={styles.sidebarWrapper}>
      {/* GymAxess logo */}
      {/* biome-ignore lint/style/noCommaOperator: <explanation> */}
      <div className={(styles.menuSection, styles.logoContainer)}>
        <Image source={Logo} alt="GymAxess Logo" data-cy={'sidebar-logo'} />
      </div>

      {/* Profile molecule */}
      <div className={styles.menuSection}>
        <SidebarProfile
          image="https://picsum.photos/50"
          profileName={`${user.userFirstName} ${user.userLastName}`}
          profileType={`${formattedRoleName}`}
          logOut={handleSignOut}
          passwordReset={handleResetPassword}
        />
      </div>

      {/* Home button */}
      {userRole !== UserRole.GYM_USER ? (
        <div className={styles.menuSection}>
          <SidebarMenuItem
            label={t('SIDEBAR.BUTTON_HOME')}
            imageProps={homeButtonImageProps}
            active={sidebarSelected === 'home'}
            onClick={() => sidebarClickHandler('home')}
            icon={true}
          />
        </div>
      ) : (
        <></>
      )}

      {/* Gym chains */}
      {userRole !== UserRole.GYM_USER ? (
        <div className={styles.menuSection}>
          <div className="d-flex justify-content-between pl30 mt14 mb8 pr25">
            <Text bold={true} className="text-uppercase fw-bold" color="#A0AEC0">
              {t('SIDEBAR.HEADER_GYM_CHAINS')}
            </Text>
            <Text className={styles.seeAllLink}>
              <Link
                to="/gymChains"
                onClick={() => {
                  setSidebarSelected('');
                  dispatch(sidebarPropsSlice.actions.hide());
                }}>
                {t('SIDEBAR.MENU_VIEW_ALL')}
              </Link>
            </Text>
          </div>

          {gymChains.slice(0, 5).map((gymChain) => (
            <div key={gymChain.id}>
              <SidebarMenuItem
                label={gymChain.name}
                avatarUrl={gymChain.avatar}
                count={gymChain.gymCount}
                onClick={() => sidebarClickHandler(gymChain.id, true)}
                active={sidebarSelected === gymChain.id}
                avatarFallback={true}
                icon={true}
              />
            </div>
          ))}
          {userRole === UserRole.SYSTEM_ADMIN && (
            <SidebarMenuItem
              label={t('SIDEBAR.MENU_ADD_CHAIN')}
              color="#A0AEC0"
              onClick={() => sidebarClickHandler('addNewGym')}
              active={sidebarSelected === 'addNewGym'}
            />
          )}
        </div>
      ) : (
        <></>
      )}

      {/* Gyms */}
      {userRole === UserRole.GYM_USER ? (
        <div className={styles.menuSection}>
          <div className="d-flex justify-content-between pl30 mt14 mb8 pr25">
            <Text bold={true} className="text-uppercase fw-bold" color="#A0AEC0">
              {t('SIDEBAR.HEADER_GYMS')}
            </Text>
          </div>

          {gyms.slice(0, 5).map((gym) => (
            <div key={gym.gymId}>
              <SidebarMenuItem
                label={gym.gymName}
                avatarUrl={gym.imageUrl ? gym.imageUrl : ''}
                onClick={() => sidebarClickHandler(gym.gymId, false, true, gym.gymChainId)}
                active={sidebarSelected === gym.gymId}
                avatarFallback={true}
                icon={true}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}

      {/* Accounts */}
      {userRole !== UserRole.GYM_USER ? (
        <div className={styles.menuSection}>
          <div className="pl30 mt14 mb8">
            <Text bold={true} className="text-uppercase fw-bold" color="#A0AEC0">
              {t('SIDEBAR.HEADER_ACCOUNTS')}
            </Text>
          </div>
          <div className="sidebarMenuItem-padding">
            <SidebarMenuItem
              label={t('SIDEBAR.MENU_REGISTERED_ACCOUNTS')}
              onClick={() => sidebarClickHandler('registeredAccounts')}
              active={sidebarSelected === 'registeredAccounts'}
              icon={false}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Admin */}
      {(userRole === UserRole.SYSTEM_ADMIN || userRole === UserRole.GYM_CHAIN_USER) && (
        <div className={styles.menuSection}>
          <div className="pl30 mt14 mb8">
            <Text bold={true} className="text-uppercase fw-bold" color="#A0AEC0">
              {t('SIDEBAR.HEADER_ADMIN')}
            </Text>
          </div>
          <div className="sidebarMenuItem-padding">
            <SidebarMenuItem
              label={t('SIDEBAR.MENU_USER_MANAGEMENT')}
              onClick={() => sidebarClickHandler('userMgt')}
              active={sidebarSelected === 'userMgt'}
              icon={false}
            />
          </div>

          {/* --Sodvin Release-- */}
          {/* <SidebarMenuItem
          label={t('SIDEBAR.MENU_NOTIFICATIONS')}
          onClick={() => sidebarClickHandler('notifications')}
          active={sidebarSelected === 'notifications'}
        />
        <SidebarMenuItem
          label={t('SIDEBAR.MENU_FAQS')}
          onClick={() => sidebarClickHandler('faqs')}
          active={sidebarSelected === 'faqs'}
        /> */}
        </div>
      )}

      {/* Day Schedules */}
      {isMindbodyEnabled() && userRole === UserRole.SYSTEM_ADMIN && (
        <div className={styles.menuSection}>
          <div className="pl30 mt14 mb8">
            <Text bold={true} className="text-uppercase fw-bold" color="#A0AEC0">
              {t('SIDEBAR.HEADER_DAY_SCHEDULES')}
            </Text>
          </div>
          <div className="sidebarMenuItem-padding">
            <SidebarMenuItem
              label={t('SIDEBAR.MENU_MANAGE_DAY_SCHEDULES_REQUESTS')}
              onClick={() => sidebarClickHandler('daySchedulesRequests')}
              active={sidebarSelected === 'daySchedulesRequests'}
              icon={userRole === UserRole.SYSTEM_ADMIN ? true : false}
              count={userRole === UserRole.SYSTEM_ADMIN ? scheduleRequestsCount : 0}
            />
          </div>

          <div className="sidebarMenuItem-padding">
            <SidebarMenuItem
              label={t('SIDEBAR.MENU_MANAGE_DAY_SCHEDULES')}
              onClick={() => sidebarClickHandler('manageDaySchedules')}
              active={sidebarSelected === 'manageDaySchedules'}
              icon={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export interface SidebarProps {
  /** Supply the gym chains as an array, to display in the sidebar's GYM CHAINS section */
  gymChains: Array<GymChain>;
  gyms: Array<GymType>;
}

export default Sidebar;
