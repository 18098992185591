import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import VerticalMenu, { VerticalMenuType } from '@gym-atoms/VerticalMenu/VerticalMenu';
import IssueModal, { IssueModalVariants } from '@gym-organisms/IssueModals/IssueModals';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import { Activity, ActivityLogGroup, ActivityLogGroupEntry } from '@gym-particles/types/models';
import { UserRole } from '@gym-particles/types/User';
import { ActivityEventTypes } from '@gym-particles/types/ActivityFeed';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import {
  fetchMemberEventStatusTypes,
  incrementMyTaskCount as gymIncrementMyTaskCount,
  decrementMyTaskCount as gymDecrementMyTaskCount
} from '@gym-redux/slices/activitySlice';
import {
  incrementMyTaskCount as gymChainIncrementMyTaskCount,
  decrementMyTaskCount as gymChainDecrementMyTaskCount
} from '@gym-redux/slices/gymChainActivitySlice';
import {
  incrementMyTaskCount as homeIncrementMyTaskCount,
  decrementMyTaskCount as homeDecrementMyTaskCount
} from '@gym-redux/slices/homeActivitySlice';
import {
  closeMemberEvent,
  resolveMemberEvent,
  assignMemberEvent,
  updateMemberEventStatus
} from '@gym-graphql/mutations';
import { getAllUsers, getReceivedInformationForMember } from '@gym-graphql/queries';
import { GetAllUsersResponse, ReceivedInformationForMemberResponse } from '@gym-src/API';
import { API } from 'aws-amplify';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { sendIssueCloseEmail } from '@gym-graphql/mutations';
import TemporaryAccessModal from '@gym-organisms/TemporaryAccessModal/TemporaryAccessModal';
import ReceivedInformationModal from '@gym-organisms/ReceivedInformationModal/ReceivedInformationModal';
import { Account, CurrentInformationType } from '@gym-particles/types/Account';
import { fetchAllRoles } from '@gym-redux/slices/userRolesSlice';

const ActivityFeed = (props: ActivityFeedProps) => {
  const menuRef = useRef<VerticalMenuType>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const memberEventStatuses = useAppSelector((state) => state.activity.memberEventStatus);
  const userId = useAppSelector((state) => state.user.userId);
  const userRole = useAppSelector((state) => state.user.userRole);
  const language = useAppSelector((state) => state.user.language);
  const [selectedActivity, setSelectedActivity] = useState<ActivityLogGroupEntry>();
  const [issueModalVariant, setIssueModalVariant] = useState<IssueModalVariants>();
  const [issueModalVisible, setIssueModalVisible] = useState(false);
  const [resultDialogVisible, setResultDialogVisible] = useState(false);
  const [resultDialogMode, setResultDialogMode] = useState<
    'success' | 'failure' | 'emailFaliure'
  >();
  const [usersDropsdownItems, setUsersDropdownItems] = useState<SelectItem[]>([]);
  const [forcedAccessModalVisible, setForcedAccessModalVisible] = useState(false);
  const [receivedInformationData, setReceivedInformationData] = useState<CurrentInformationType>();
  const forcedAccessModalToggle = () => setForcedAccessModalVisible(!forcedAccessModalVisible);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  /** On load, fetch MemberEventStatusTypes to the redux store, and get all users for dropdown */
  useEffect(() => {
    dispatch(fetchMemberEventStatusTypes());
    fetchAllUsersForDropdown();
    dispatch(fetchAllRoles());
  }, []);

  const userRoles = useAppSelector((state) => state.userRoles);

  const fetchAllUsersForDropdown = async () => {
    const result = await (API.graphql({
      query: getAllUsers
    }) as Promise<{
      data: { getAllUsers: GetAllUsersResponse[] };
    }>);
    const dropdownItems = result.data.getAllUsers
      .filter((el) => el.isPlatformUser)
      .map((user) => ({
        value: user.id,
        label: [user.firstName, user.lastName].join(' ')
      }));
    setUsersDropdownItems(dropdownItems);
  };

  useEffect(() => {
    if (selectedActivity?.informationReceivedEvent) {
      getReceivedInformationData();
    }
  }, [selectedActivity]);

  const getReceivedInformationData = async () => {
    try {
      const response = await (API.graphql({
        query: getReceivedInformationForMember,
        variables: {
          memberId: selectedActivity?.memberId
        }
      }) as Promise<{
        data: { getReceivedInformationForMember: ReceivedInformationForMemberResponse };
      }>);

      if (response.data.getReceivedInformationForMember !== null) {
        setReceivedInformationData({
          id: response.data.getReceivedInformationForMember.id || 0,
          currentFirstName: response.data.getReceivedInformationForMember.firstName || '',
          currentLastName: response.data.getReceivedInformationForMember.lastName || '',
          currentEmail: response.data.getReceivedInformationForMember.email || '',
          currentPhoneNumber: response.data.getReceivedInformationForMember.phone || '',
          imageUrl: response.data.getReceivedInformationForMember.imageUrl || ''
        });
      } else {
        setResultDialogMode('failure');
        setResultDialogVisible(true);
      }
    } catch (error) {
      setResultDialogMode('failure');
      console.log(error);
    }
  };

  const resultDialogOnHideCallback = () => {
    setResultDialogVisible(false);
    countChangeHandler(!props.isCountChanged);
    if (refreshNeeded) {
      setRefreshNeeded(false);
      props.refreshCallBack && props.refreshCallBack();
    }
  };

  const issueModalOnHideCallback = () => {
    setIssueModalVisible(false);
  };

  const issueModalSubmitHandler = (data: string) => {
    switch (issueModalVariant) {
      case IssueModalVariants.close:
        issueCloseHandler(data);
        break;
      default:
        console.error('Issue modal type not set');
    }
    // close the modal once done
    issueModalOnHideCallback();
  };

  const countChangeHandler = useCallback(
    (state) => {
      props.countChange && props.countChange(state);
    },
    [props.countChange]
  );

  const issueAssignToUserHandler = async (data: string) => {
    const assignmentHistoryId = selectedActivity?.assignmentHistoryId;
    const assignedUserId = data;
    const assignedBy = userId;
    const createdBy = userId;
    const lastModifiedBy = userId;
    const assignEventId = memberEventStatuses?.find((status) => status.description === 'assigned')
      ?.id;

    if (!assignEventId) {
      console.error('Member event status types not fetched!');
      return;
    }
    try {
      const result_assign = await (API.graphql({
        query: assignMemberEvent,
        variables: {
          input: {
            assignedBy,
            assignedUserId,
            assignedRoleId: -1,
            createdBy,
            lastModifiedBy,
            memberAssignmentId: assignmentHistoryId || -1,
            memberEventId: selectedActivity?.memberEventId
          }
        }
      }) as Promise<{
        data: { assignMemberEvent: number };
      }>);

      const result_updateStatus = await (API.graphql({
        query: updateMemberEventStatus,
        variables: {
          memberEventId: selectedActivity?.memberEventId,
          memberEventStatusId: assignEventId
        }
      }) as Promise<{
        data: { updateMemberEventStatus: number };
      }>);

      if (
        result_assign.data.assignMemberEvent === 1 &&
        result_updateStatus.data.updateMemberEventStatus === 1
      ) {
        setResultDialogMode('success');
        setRefreshNeeded(true);
        // if assigned to current user, increment task count badge
        if (+assignedUserId === userId) {
          if (props.stage === 'home') {
            dispatch(homeIncrementMyTaskCount());
          }
          if (props.stage === 'site') {
            dispatch(gymChainIncrementMyTaskCount());
          }
          if (props.stage === 'siteLocation') {
            dispatch(gymIncrementMyTaskCount());
          }
        }

        // if it was previously assigned to current user, decrement task count badge
        if (selectedActivity?.assignedUserId === userId) {
          if (props.stage === 'home') {
            dispatch(homeDecrementMyTaskCount());
          }
          if (props.stage === 'site') {
            dispatch(gymChainDecrementMyTaskCount());
          }
          if (props.stage === 'siteLocation') {
            dispatch(gymDecrementMyTaskCount());
          }
        }
      } else {
        console.error('Failed to assign issue:', result_assign, result_updateStatus);
        setResultDialogMode('failure');
      }
    } catch (e) {
      console.error('Failed to assign issue:', e);
      setResultDialogMode('failure');
    } finally {
      setResultDialogVisible(true);
      props.onAssigned && props.onAssigned();
    }
  };

  const issueMarkAsResolvedHandler = async (data: string) => {
    const resolveEventId = memberEventStatuses?.find((status) => status.description === 'resolved')
      ?.id;
    const assignmentHistoryId = selectedActivity?.assignmentHistoryId;

    if (!resolveEventId) {
      console.error('Member event status types not fetched!');
      return;
    }

    try {
      const resultResolve = await (API.graphql({
        query: resolveMemberEvent,
        variables: {
          resolvedDescription: data,
          memberAssignmentId: assignmentHistoryId || -1,
          memberEventId: selectedActivity?.memberEventId,
          memberEventStatusId: resolveEventId,
          assignedRoleId: userRoles.find((role) => role.roleName === UserRole.SYSTEM_ADMIN)?.roleId,
          userId: userId
        }
      }) as Promise<{
        data: { resolveMemberEvent: number };
      }>);

      if (resultResolve.data.resolveMemberEvent === 1) {
        setResultDialogMode('success');
      } else {
        console.error('Failed to mark event as resolved:', resultResolve);
        setResultDialogMode('failure');
      }
    } catch (e) {
      console.error('Failed to mark event as resolved:', e);
      setResultDialogMode('failure');
    } finally {
      setRefreshNeeded(true);
      setResultDialogVisible(true);
    }
  };

  const issueCloseEmailFn = async (
    memberName: string,
    memberEmail: string,
    gymName: string,
    issueDescription: string,
    closeDescription: string,
    reportedDate: string,
    closedDate: string,
    language: string
  ) => {
    const response = await (API.graphql({
      query: sendIssueCloseEmail,
      variables: {
        input: {
          userName: memberName,
          userEmail: memberEmail.toLowerCase(),
          gymName: gymName,
          issueDescription: issueDescription,
          closeDescription: closeDescription,
          reportedDate: reportedDate,
          closedDate: closedDate,
          language: language.toLowerCase() || 'en'
        }
      }
    }) as Promise<{
      data: {
        sendIssueCloseEmail: {
          error: boolean;
          errorMessage: string | null;
        };
      };
    }>);

    return response;
  };

  const issueCloseHandler = async (data: string) => {
    const closeEventId = memberEventStatuses?.find((status) => status.description === 'closed')?.id;
    const assignmentHistoryId = selectedActivity?.assignmentHistoryId;

    if (!closeEventId) {
      console.error('Member event status types not fetched!');
      return;
    }

    try {
      const result = await (API.graphql({
        query: closeMemberEvent,
        variables: {
          closedDescription: data,
          memberAssignmentId: assignmentHistoryId || -1,
          memberEventId: selectedActivity?.memberEventId,
          memberEventStatusId: closeEventId,
          lastModifiedBy: userId
        }
      }) as Promise<{
        data: { closeMemberEvent: number };
      }>);

      if (result.data.closeMemberEvent === 1) {
        try {
          const emailResponse = await issueCloseEmailFn(
            `${selectedActivity?.firstName}` || '',
            selectedActivity?.memberEmail || '',
            selectedActivity?.locationName || '',
            selectedActivity?.eventDescription || '',
            data,
            dayjs(selectedActivity?.activityCreatedDate).format('MMMM DD, YYYY') || '',
            dayjs(new Date()).format('MMMM DD, YYYY') || '',
            selectedActivity?.language || ''
          );

          if (!emailResponse.data.sendIssueCloseEmail.error) {
            setResultDialogMode('success');
          } else {
            console.error(
              'Failed to send close event email:',
              emailResponse.data.sendIssueCloseEmail.errorMessage
            );
            setResultDialogMode('emailFaliure');
          }
        } catch (error) {
          console.error('Failed to send close event email:', error);
          setResultDialogMode('failure');
        }
      } else {
        console.error('Failed to close event:', result);
        setResultDialogMode('failure');
      }
    } catch (e) {
      console.error('Failed to close event:', e);
      setResultDialogMode('failure');
    } finally {
      setResultDialogVisible(true);
      setRefreshNeeded(true);
    }
  };

  const groupActivities = () => {
    const groupedActivities: ActivityLogGroup[] = [];

    props.activities.forEach((activity) => {
      const today = dayjs(new Date()).format('DD MMM YYYY');
      let formattedDate: string;
      if (activity.activityDate === today) {
        formattedDate = t('GYM_DASHBOARD.ACTIVITY_FEED_TODAY_LABEL');
      } else {
        // format: "Monday, November 05, 2012"
        formattedDate = dayjs(activity.activityDate).format('dddd, MMMM DD, YYYY');
      }

      const groupIndex = groupedActivities.findIndex((groupedActivity) => {
        return groupedActivity.date === formattedDate;
      });

      if (groupIndex === -1) {
        groupedActivities.push({
          date: formattedDate,
          activities: [
            {
              type: activity.eventType,
              activitystatus: activity.memberEventStatusTypeId,
              time: activity.createdTime,
              memberEventId: activity.memberEventId,
              accessCardUID: activity.accessCardUID,
              assignmentHistoryId: activity.assignmentHistoryId,
              inquiryDescription: activity.inquiryDescription,
              firstName: activity.firstName,
              lastName: activity.lastName,
              memberEmail: activity.memberEmail,
              eventDescription: activity.eventDescription,
              locationName: activity.locationName,
              activity: `${activity.firstName} ${activity.lastName} - ${activity.eventDescription} at ${activity.locationName}`,
              assignedUserId: activity.assignedUserId || 0,
              memberId: activity.memberId,
              siteLocationId: activity.siteLocationId,
              memberLocationId: activity.memberLocationId,
              informationReceivedEvent: activity.informationReceivedEvent,
              activityCreatedDate: activity.activityDate,
              language: activity.language || '',
              userId: activity.userId,
              en: activity.en || '',
              nor: activity.nor || '',
              nl: activity.nl || ''
            }
          ]
        });
      } else {
        groupedActivities[groupIndex].activities.push({
          type: activity.eventType,
          activitystatus: activity.memberEventStatusTypeId,
          time: activity.createdTime,
          memberEventId: activity.memberEventId,
          accessCardUID: activity.accessCardUID,
          assignmentHistoryId: activity.assignmentHistoryId,
          inquiryDescription: activity.inquiryDescription,
          firstName: activity.firstName,
          lastName: activity.lastName,
          memberEmail: activity.memberEmail,
          eventDescription: activity.eventDescription,
          locationName: activity.locationName,
          activity: `${activity.firstName} ${activity.lastName} - ${activity.eventDescription} at ${activity.locationName}`,
          memberId: activity.memberId,
          siteLocationId: activity.siteLocationId,
          memberLocationId: activity.memberLocationId,
          assignedUserId: activity.assignedUserId || 0,
          informationReceivedEvent: activity.informationReceivedEvent,
          activityCreatedDate: activity.activityDate,
          language: activity.language || '',
          userId: activity.userId,
          en: activity.en || '',
          nor: activity.nor || '',
          nl: activity.nl || ''
        });
      }
    });

    groupedActivities.sort((initialItem, nextItem) => {
      const keyA = new Date(initialItem.date),
        keyB = new Date(nextItem.date);
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });

    return groupedActivities;
  };

  const groupedActivities = groupActivities();

  const showForcedAccess = () => {
    setForcedAccessModalVisible(true);
  };

  const menuItem = [
    {
      label: t('GYM_DASHBOARD.CLOSE_ISSUE'),
      command: () => {
        setIssueModalVariant(IssueModalVariants.close);
        setIssueModalVisible(true);
      }
    }
  ];

  if (
    selectedActivity?.siteLocationId &&
    selectedActivity.memberId &&
    selectedActivity.memberLocationId &&
    userRole === UserRole.GYM_USER
  ) {
    menuItem.push({
      label: t('GYM_DASHBOARD.GIVE_FORCE_ACCESS'),
      command: () => {
        showForcedAccess();
      }
    });
  }

  if (selectedActivity?.informationReceivedEvent) {
    menuItem.push({
      label: t('GYM_DASHBOARD.VIEW_RECEIVED_INFORMATION'),
      command: () => setReceivedInfoModalVisible(true)
    });
  }

  const actionsOnClick = (e: React.MouseEvent, index: number, activity: ActivityLogGroupEntry) => {
    setSelectedActivity(activity);
    menuRef.current?.toggle(e);
  };

  const ActionResultDialog = () => {
    return (
      <DialogBox
        dialogVisible={resultDialogVisible}
        onHideCallback={resultDialogOnHideCallback}
        variant={DialogBoxVariants.basic}
        dialogClosable={false}
        dialogDismissableMask={false}
        dialogHeader={
          resultDialogMode === 'success'
            ? t('GYM_DASHBOARD.RESULT_MODAL_HEADER_SUCCESS')
            : t('GYM_DASHBOARD.RESULT_MODAL_HEADER_FAILURE')
        }
        content={
          resultDialogMode === 'success'
            ? t('GYM_DASHBOARD.RESULT_MODAL_CONTENT_SUCCESS')
            : resultDialogMode === 'emailFaliure'
            ? t('GYM_DASHBOARD.RESULT_MODAL_CONTENT_EMAIL_FAILURE')
            : t('GYM_DASHBOARD.RESULT_MODAL_CONTENT_FAILURE')
        }
        dialogFooter={<Button label={t('COMMON.OK')} onClick={resultDialogOnHideCallback} />}
      />
    );
  };

  const [receivedInfoModalVisible, setReceivedInfoModalVisible] = useState(false);
  const infoModalOnHide = () => {
    setReceivedInfoModalVisible(!receivedInfoModalVisible);
  };

  return (
    <>
      <div className="all-activity">
        {groupedActivities.map((activityGroup, index) => {
          return (
            <div className="pb10" key={index}>
              <div className="activity-date-wrapper mb20">
                <Text
                  data-cy={'activity-feed-date-text'}
                  bold={true}
                  size={TextSizes.t14}
                  className="activity-date">
                  {activityGroup.date}
                </Text>
              </div>
              <div className="activity-list-wrapper">
                <ul className="activity-list">
                  {activityGroup.activities.map((activity, index) => {
                    return (
                      <li className="pb10" key={index}>
                        <div className="d-flex align-items-center">
                          <div className="icon-img-wrapper pr20">
                            {activity.type === ActivityEventTypes.EVENTS &&
                            activity.eventDescription !== 'Signed in to class(es)' &&
                            activity.eventDescription !== 'Not signed in to class(es)' ? (
                              <div className="icon-mock-img-wrapper icon-blue">
                                <i
                                  data-cy={'activity-feed-event-icon'}
                                  className="pi pi-user-plus icon-mock-img"></i>
                              </div>
                            ) : (
                              activity.type === ActivityEventTypes.EVENTS && (
                                <div className="icon-mock-img-wrapper icon-blue">
                                  <i
                                    data-cy={'activity-feed-event-icon'}
                                    className="pi pi-id-card icon-mock-img"></i>
                                </div>
                              )
                            )}
                            {activity.type === ActivityEventTypes.VISITS &&
                            activity.eventDescription.includes('Access Denied') ? (
                              <div className="icon-mock-img-wrapper icon-red">
                                <i
                                  data-cy={'activity-feed-errors-icon'}
                                  className="pi pi-exclamation-triangle icon-mock-img"></i>
                              </div>
                            ) : activity.type === ActivityEventTypes.VISITS &&
                              activity.eventDescription === 'Gym session with card ended' ? (
                              <div className="icon-mock-img-wrapper icon-red">
                                <i
                                  data-cy={'activity-feed-visits-icon'}
                                  className="pi pi-times-circle icon-mock-img"></i>
                              </div>
                            ) : (
                              activity.type === ActivityEventTypes.VISITS && (
                                <div className="icon-mock-img-wrapper icon-green">
                                  <i
                                    data-cy={'activity-feed-visits-icon'}
                                    className="pi pi-check icon-mock-img"></i>
                                </div>
                              )
                            )}
                            {activity.type === ActivityEventTypes.ERRORS && (
                              <div className="icon-mock-img-wrapper icon-red">
                                <i
                                  data-cy={'activity-feed-errors-icon'}
                                  className="pi pi-exclamation-triangle icon-mock-img"></i>
                              </div>
                            )}
                            {activity.type === ActivityEventTypes.INQUIRIES && (
                              <div className="icon-mock-img-wrapper icon-blue">
                                <i className="pi pi-comments icon-mock-img"></i>
                              </div>
                            )}
                          </div>
                          <div className="activity-time-wrapper d-flex pr20 flex-shrink-0">
                            <Text
                              data-cy={'activity-feed-activity-time-text'}
                              size={TextSizes.t14}
                              className="activity-time">
                              {activity.time}
                            </Text>
                          </div>
                          <div className="activity-text-wrapper d-flex">
                            <Text
                              data-cy={'activity-feed-activity-text-text'}
                              size={TextSizes.t14}
                              className="activity-text">
                              <span
                                style={{ color: 'blue', fontWeight: 'bold', marginRight: '6px' }}>
                                {`${activity.firstName} ${activity.lastName}`}
                              </span>
                              <span style={{ color: 'blue', fontWeight: 'bold' }}></span>
                              {activity.inquiryDescription ? (
                                <span
                                  style={{ color: 'red', fontWeight: 'bold', marginRight: '6px' }}>
                                  {activity.inquiryDescription.replaceAll('<br/>', ' ')}
                                </span>
                              ) : null}
                              <span style={{ marginRight: '6px' }}>
                                {language === 'EN'
                                  ? activity.en
                                  : language === 'NOR'
                                  ? activity.nor
                                  : activity.nl}
                              </span>
                              {activity.locationName && (
                                <>
                                  <span style={{ marginRight: '6px' }}>
                                    {t('GYM_DASHBOARD.AT')}
                                  </span>
                                  <span
                                    style={{
                                      color: 'blue',
                                      fontWeight: 'bold',
                                      marginRight: '6px'
                                    }}>
                                    {activity.locationName}
                                  </span>
                                </>
                              )}
                              {activity.accessCardUID && (
                                <>
                                  <span style={{ marginRight: '6px' }}>
                                    {t('GYM_DASHBOARD.WITH_CARD')}
                                  </span>
                                  <span
                                    style={{
                                      color: 'blue',
                                      fontWeight: 'bold',
                                      marginRight: '6px'
                                    }}>
                                    {activity.accessCardUID}
                                  </span>
                                </>
                              )}

                              {activity.type !== ActivityEventTypes.INQUIRIES ||
                              activity.activitystatus === 4 ? (
                                <></>
                              ) : (
                                <>
                                  <span>
                                    <Button
                                      variant={ButtonVariants.textonlyslim}
                                      icon="pi-angle-down"
                                      iconPos="right"
                                      label={t('GYM_DASHBOARD.ACTIONS')}
                                      onClick={(e) => {
                                        actionsOnClick(e, index, activity);
                                      }}></Button>
                                  </span>
                                  <VerticalMenu
                                    className={'data-table-overlay-menu'}
                                    popup
                                    refProp={menuRef}
                                    items={menuItem}
                                  />
                                </>
                              )}
                            </Text>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
      {issueModalVariant && (
        <IssueModal
          variant={issueModalVariant}
          visible={issueModalVisible}
          onHideCallback={issueModalOnHideCallback}
          onSubmitHander={issueModalSubmitHandler}
          dropdownOptions={usersDropsdownItems}
          selectedActivity={selectedActivity}
        />
      )}
      {selectedActivity && forcedAccessModalVisible && (
        <TemporaryAccessModal
          visible={forcedAccessModalVisible}
          onHideCallback={forcedAccessModalToggle}
          gymId={selectedActivity.siteLocationId}
          memberId={selectedActivity.memberId}
          memberLocationId={selectedActivity.memberLocationId}
        />
      )}
      {receivedInfoModalVisible && receivedInformationData && (
        <ReceivedInformationModal
          visible={receivedInfoModalVisible}
          modalOnHide={infoModalOnHide}
          receivedInformation={receivedInformationData}
          memberEventId={selectedActivity?.memberEventId}
          memberAssignmentId={selectedActivity?.assignmentHistoryId}
        />
      )}
      <ActionResultDialog />
    </>
  );
};

type ActivityFeedType = 'home' | 'site' | 'siteLocation' | 'account';
export interface ActivityFeedProps {
  activities: Activity[];
  stage: ActivityFeedType;
  onAssigned?: () => void;
  /** Account data to populate the modal */
  account?: Account;
  /* Callback to trigger a refresh on success */
  refreshCallBack?: () => void;

  countChange?: (e: boolean) => void;
  isCountChanged?: boolean;
}

export default ActivityFeed;
