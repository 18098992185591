import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import { DateTimePickerChangeEvent } from '@gym-atoms/DateTimePicker/DateTimePicker';
import Loader from '@gym-atoms/Loader/Loader';
import ActivityFeed from '@gym-molecules/ActivityFeed/ActivityFeed';
import ActivityFeedFilters from '@gym-molecules/ActivityFeedFilters/ActivityFeedFilters';
import EmptyState from '@gym-molecules/EmptyState/EmptyState';
import { UserRole } from '@gym-particles/types/User';
import { fetchActivityForSite } from '@gym-redux/slices/gymChainActivitySlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

enum eventTypes {
  all = 'all',
  errors = 'Errors',
  visits = 'Visit Activity',
  inquiry = 'Inquiry'
}

const GymChainActivity = (props: ActivityProps) => {
  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const { t } = useTranslation();
  const userId = useAppSelector((state) => state.user.userId);
  const { chainId } = useParams<{ chainId: string }>();
  const [allActivity, setAllActivity] = useState(true);
  const [errorActivity, setErrorActivity] = useState(false);
  const [accessActivity, setAccessActivity] = useState(false);
  const [inquiry, setInquiry] = useState(false);
  const [startingDate, setStartingDate] = useState<Date>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [endingDate, setEndingDate] = useState<Date>(new Date());
  const [selected, setSelected] = useState<number>(0);
  const [eventType, setEventType] = useState<eventTypes>(eventTypes.all);
  const isActivityLoading = useAppSelector((state) => state.gymChainActivity.isActivityLoading);
  const [refresh, setRefresh] = useState(false);
  const totalActivityCount =
    useAppSelector(
      (state) =>
        state.gymChainActivity.items.find((g) => g.userId === userId && g.siteId === +chainId)
          ?.activityItems.totalRecords
    ) || 0;
  const userRole = useAppSelector((state) => state.user.userRole);

  useEffect(() => {
    dispatch(
      fetchActivityForSite({
        userId: userId,
        siteId: +chainId,
        pageSize: pageSize,
        offset: offset,
        dateFrom: `${dayjs(startingDate).format('YYYY-MM-DD')} 00:00:00`,
        dateTo: `${dayjs(endingDate).format('YYYY-MM-DD')} 23:59:59`,
        stageId: selected,
        eventType: `${eventType}`,
        platformAdmin: userRole === UserRole.SYSTEM_ADMIN
      })
    );
  }, [
    userId,
    chainId,
    offset,
    pageSize,
    allActivity,
    errorActivity,
    accessActivity,
    inquiry,
    startingDate,
    endingDate,
    selected,
    refresh
  ]);

  const activities = useAppSelector(
    (state) =>
      state.gymChainActivity.items.find((e) => e.userId === userId && e.siteId === +chainId)
        ?.activityItems.items
  );

  const allActivityChangeHandler = () => {
    setOffset(0);
    setEventType(eventTypes.all);
    setAllActivity(true);
    setErrorActivity(false);
    setAccessActivity(false);
  };
  const errorActivityChangeHandler = () => {
    setOffset(0);
    setEventType(eventTypes.errors);
    setAllActivity(false);
    setErrorActivity(true);
    setAccessActivity(false);
  };
  const accessActivityChangeHandler = () => {
    setOffset(0);
    setEventType(eventTypes.visits);
    setAllActivity(false);
    setErrorActivity(false);
    setAccessActivity(true);
  };

  const startDateChangeHandler = (e: DateTimePickerChangeEvent) => {
    setOffset(0);
    setStartingDate(e.target.value);
  };
  const endDateChangeHandler = (e: DateTimePickerChangeEvent) => {
    setOffset(0);
    setEndingDate(e.target.value);
  };

  const dropdownOptions = [
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.ALL'),
      value: 0
    },
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.LOGIN'),
      value: 1
    },
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.SIGNUP'),
      value: 2
    },
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.ACCESS_DOORS'),
      value: 3
    },
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.ACCOUNTS'),
      value: 4
    },
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.SETTINGS'),
      value: 5
    }
  ];

  return (
    <>
      {isActivityLoading ? (
        <Loader shape="activity" />
      ) : (
        <>
          <div className="mt15">
            <ActivityFeedFilters
              allActivity={allActivity}
              errorActivity={errorActivity}
              accessActivity={accessActivity}
              dateFrom={startingDate}
              dateTo={endingDate}
              allActivityOnChange={allActivityChangeHandler}
              errorActivityOnChange={errorActivityChangeHandler}
              accessActivityOnChange={accessActivityChangeHandler}
              dateFromOnChange={startDateChangeHandler}
              dateToOnChange={endDateChangeHandler}
              dropdownOptions={dropdownOptions}
              selectedValue={selected}
              dropdownOnChange={(e) => setSelected(e.value)}
            />
          </div>
          <div className="activty-feed">
            <ActivityFeed
              activities={activities || []}
              stage={'site'}
              refreshCallBack={() => setRefresh(!refresh)}
              countChange={props.countChange}
              isCountChanged={props.isCountChanged}></ActivityFeed>
          </div>
          <div className="text-center">
            {activities?.length === 0 ? (
              <EmptyState textItems={[t('GYM_CHAIN_PAGE.EMPTY_STATE_TEXT')]} />
            ) : (
              <div className="d-flex button-view-all justify-content-center">
                <div className="mr15 mb10">
                  <Button
                    variant={ButtonVariants.textonlyslim}
                    label={t('GYM_CHAIN_PAGE.VIEW_PREVIOUS_ACTIVITY')}
                    icon="pi-angle-left"
                    iconPos="left"
                    disabled={offset === 0}
                    onClick={() => setOffset(offset - pageSize)}></Button>
                </div>
                <div>
                  <Button
                    variant={ButtonVariants.textonlyslim}
                    label={t('GYM_CHAIN_PAGE.VIEW_NEXT_ACTIVITY')}
                    icon="pi-angle-right"
                    iconPos="right"
                    disabled={offset + pageSize >= totalActivityCount}
                    onClick={() => setOffset(offset + pageSize)}></Button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default GymChainActivity;

interface ActivityProps {
  countChange?: (e: boolean) => void;
  isCountChanged?: boolean;
}
