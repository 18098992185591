import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import Loader from '@gym-atoms/Loader/Loader';
import ActivityFeed from '@gym-molecules/ActivityFeed/ActivityFeed';
import EmptyState from '@gym-molecules/EmptyState/EmptyState';
import InquiriesFilters from '@gym-molecules/InquiriesFilters/InquiriesFilters';
import { UserRole } from '@gym-particles/types/User';
import { fetchActivityMyTasksForSite } from '@gym-redux/slices/gymChainActivitySlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

enum inquiryTypes {
  open = 'Open',
  closed = 'Closed'
}

const GymChainTasks = (props: GymChainTasksProps) => {
  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const { t } = useTranslation();
  const userId = useAppSelector((state) => state.user.userId);
  const { chainId } = useParams<{ chainId: string }>();
  const [refresh, setRefresh] = useState(false);
  const [openInquiries, setOpenInquiries] = useState(true);
  const [closedInquiries, setClosedInquiries] = useState(false);
  const [closedFilter, setClosedFilter] = useState(false);
  const isActivityLoading = useAppSelector((state) => state.gymChainActivity.isActivityLoading);
  const userRole = useAppSelector((state) => state.user.userRole);
  const userRoleId = useAppSelector((state) => state.user.roleId);

  const totalActivityCount =
    useAppSelector(
      (state) =>
        state.gymChainActivity.items.find((g) => g.userId === userId && g.siteId === +chainId)
          ?.myTaskItems.totalRecords
    ) || 0;

  const openInquiriesChangeHandler = () => {
    setClosedFilter(false);
    setOpenInquiries(true);
    setClosedInquiries(false);
  };
  const closedInquiriesChangeHandler = () => {
    setClosedFilter(true);
    setOpenInquiries(false);
    setClosedInquiries(true);
  };
  const fetchTasks = () => {
    dispatch(
      fetchActivityMyTasksForSite({
        userId: userId,
        roleId: userRoleId,
        siteId: +chainId,
        pageSize: pageSize,
        offset: offset,
        closedInquiries: closedFilter,
        platformAdmin: userRole === UserRole.SYSTEM_ADMIN
      })
    );
  };

  useEffect(() => {
    fetchTasks();
  }, [userId, chainId, offset, pageSize, closedFilter, refresh]);

  const activities = useAppSelector(
    (state) =>
      state.gymChainActivity.items.find((e) => e.userId === userId && e.siteId === +chainId)
        ?.myTaskItems.items
  );

  return (
    <>
      {isActivityLoading ? (
        <Loader shape="activity" />
      ) : (
        <>
          <div className="mt15">
            <InquiriesFilters
              openInquiries={openInquiries}
              closedInquiries={closedInquiries}
              openInquiriesOnChange={openInquiriesChangeHandler}
              closedInquiriesOnChange={closedInquiriesChangeHandler}
            />
          </div>
          <div className="activty-feed">
            <ActivityFeed
              activities={activities || []}
              stage={'site'}
              refreshCallBack={() => setRefresh(!refresh)}
              countChange={props.countChange}
              isCountChanged={props.isCountChanged}></ActivityFeed>
          </div>
          <div className="text-center">
            {activities?.length === 0 ? (
              <EmptyState textItems={[t('GYM_CHAIN_PAGE.EMPTY_STATE_TEXT')]} />
            ) : (
              <div className="button-view-all">
                <Button
                  variant={ButtonVariants.textonlyslim}
                  label={t('GYM_CHAIN_PAGE.VIEW_PREVIOUS_ACTIVITY')}
                  icon="pi-angle-left"
                  iconPos="left"
                  disabled={offset === 0}
                  onClick={() => setOffset(offset - pageSize)}></Button>
                <Button
                  variant={ButtonVariants.textonlyslim}
                  label={t('GYM_CHAIN_PAGE.VIEW_NEXT_ACTIVITY')}
                  icon="pi-angle-right"
                  iconPos="right"
                  disabled={offset + pageSize >= totalActivityCount}
                  onClick={() => setOffset(offset + pageSize)}></Button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export interface GymChainTasksProps {
  countChange?: (e: boolean) => void;
  isCountChanged?: boolean;
}

export default GymChainTasks;
