import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Input from '@gym-atoms/Input/Input';
import Text from '@gym-atoms/Text/Text';
import {
  KisiDoors as Door,
  KisiDoorsFormInput as FormInputs,
  KisiDoorsDefaultValues
} from '@gym-particles/types/models.d';
import { UserRole } from '@gym-particles/types/User';
import {
  MindbodyImportedLocation,
  Gym as GymResponse,
  GymInput,
  SiteLocationUserRecordInput,
  KisiDoorInput,
  KisiImportedDoor
} from '@gym-src/API';
import {
  getAllAccessCardTypes,
  getGymByApiId,
  getGymByCompanyId,
  importKisiDoor,
  importLocation,
  importSodvinDepartment,
  importSodvinLocation,
  uploadImageUrlResolver
} from '@gym-graphql/queries';
import {
  addGym,
  addKisiDoor,
  addSiteLocationUserRecord,
  modifyGym,
  modifyKisiDoor
} from '@gym-graphql/mutations';
import { fetchGyms } from '@gym-redux/slices/gymsSlice';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import * as Validator from '@gym-particles/validations/Validator';
import { fetchMembershipsForSiteLocation } from '@gym-redux/slices/membershipSlice';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';
import axios from 'axios';
import base64toFile from '@gym-particles/base64ToFile';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { fetchKisiDoors } from '@gym-redux/slices/kisiDoorSlice';
dayjs.extend(utc);
dayjs.extend(timezone);

const DoorInfoTemplate = (props: DoorInfoTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [importErrorMessage, setImportErrorMessage] = useState<string | null>(null);
  const [dialogErrorMessage, setDialogErrorMessage] = useState<string | null>(null);
  const [importing, setImporting] = useState(false);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const userId = useAppSelector((state) => state.user.userId);
  const [isInserted, setIsInserted] = useState(false);
  const { chainId } = useParams<{ chainId: string }>();
  const defaultValues: FormInputs = {
    ...KisiDoorsDefaultValues,
    ...props.door
  };

  useEffect(() => {
    dispatch(getGymChainById(+chainId));
  }, [chainId]);

  useEffect(() => {
    dispatch(
      fetchMembershipsForSiteLocation({
        siteLocationId: props.door?.id || 0,
        pagination: {
          offset: 0,
          pageSize: 100,
          sortField: 'membershipName',
          sortOrder: 1
        }
      })
    );
  }, []);

  const [formValues, setFormValues] = useState(defaultValues);

  /** Default values used for importing from 3rd party APIs */
  const [importParams, setImportParams] = useState<{
    siteId: number;
    doorId: string;
  }>({
    siteId: props.gymChainId || -1,
    doorId: props.door ? String(props.door.id) : ''
  });

  const { register, handleSubmit, setValue, errors, reset } = useForm<FormInputs>({
    defaultValues: defaultValues
  });

  const getTranslationKey = (e: string): string => {
    return 'EMPLOYEE_DASHBOARD_PAGE.KISI_DOORS_ADD_MODIFY.' + e;
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={
            <Text>
              {dialogErrorMessage
                ? dialogErrorMessage
                : t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}
            </Text>
          }
        />
      </div>
    );
  };

  /** Returns true if successful, false otherwise */
  const createDoorApiCall = async (data: FormInputs) => {
    const { ...newDoor } = {
      siteId: props.gymChainId,
      siteLocationId: props.siteLocationId,
      kisiDoorId: data.kisiDoorId || -1,
      kisiDoorName: data.kisiDoorName.replace(/'/g, "''"),
      kisiPlaceId: data.kisiPlaceId || -1,
      kisiPlaceName: data.kisiPlaceName.replace(/'/g, "''"),
      createdBy: userId,
      lastModifiedBy: userId
    };

    try {
      const response = await (API.graphql({
        query: addKisiDoor,
        variables: {
          input: newDoor
        }
      }) as Promise<{
        data: { addKisiDoor: Door };
      }>);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  /** Runs on form submit, if validation has passed */
  const submitHandler = async (data: FormInputs) => {
    setIsInserted(true);
    let actionSuccess = false;
    actionSuccess = await createDoorApiCall(data);

    if (actionSuccess === false) {
      setFailedDialogVisible(true);
    } else {
      await dispatch(
        fetchKisiDoors({
          siteLocationId: +props.siteLocationId
        })
      );
      history.push(`/employeeDashboard/${props.gymChainId}/${props.siteLocationId}`);
    }
    setIsInserted(false);
  };

  const changeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof FormInputs, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      switch (key) {
        case 'kisiDoorId':
          register(key as keyof FormInputs, {
            required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
          });
          break;
        case 'kisiDoorName':
        case 'kisiPlaceId':
        case 'kisiPlaceName':
        default:
          register(key as keyof FormInputs);
      }
    });
  }, [register]);

  const resetForm = () => {
    setFormValues(defaultValues);
    reset(defaultValues);
  };

  /** Parses data imported from 3rd party APIs */
  const parseImportedData = (importedDoor: KisiImportedDoor) => {
    console.log(1);
    const updatedFormVals = formValues;

    setValue('kisiDoorId', importedDoor.id);
    console.log(2);
    if (importedDoor.name) {
      setValue('kisiDoorName', importedDoor.name);
      updatedFormVals.kisiDoorName = importedDoor.name;
    }
    console.log(3);
    if (importedDoor.placeId) {
      setValue('kisiPlaceId', importedDoor.placeId);
      updatedFormVals.kisiPlaceId = importedDoor.placeId;
    }
    console.log(4);
    if (importedDoor.placeName) {
      setValue('kisiPlaceName', importedDoor.placeName);
      updatedFormVals.kisiPlaceName = importedDoor.placeName;
    }
    console.log(5);

    setFormValues(updatedFormVals);
  };

  const kisiDoorImportHandler = async () => {
    setImporting(true);

    const importDoorId = parseInt(importParams.doorId);
    if (isNaN(importDoorId)) {
      setImportErrorMessage(t(getTranslationKey('FORM_IMPORT_ERROR')));
      setImporting(false);
      resetForm();
      return;
    }
    try {
      const res = await (API.graphql({
        query: importKisiDoor,
        variables: {
          siteId: importParams.siteId,
          doorId: importDoorId
        }
      }) as Promise<{ data: { importKisiDoor: KisiImportedDoor } }>);

      console.log(res);
      if (res.data.importKisiDoor === null) {
        console.log('Import error', res);
        setImportErrorMessage(t(getTranslationKey('FORM_IMPORT_ERROR')));
        setImporting(false);
        resetForm();
        return;
      }

      const importedDoor = res.data.importKisiDoor;
      if (importedDoor === null || importedDoor === undefined) {
        console.log('Import error', res);
        setImportErrorMessage(t(getTranslationKey('FORM_IMPORT_ERROR')));
        resetForm();
      } else {
        console.log('import Data:', importedDoor);
        parseImportedData(importedDoor);
      }
      setImporting(false);
    } catch (e) {
      setImportErrorMessage(t(getTranslationKey('FORM_IMPORT_ERROR')));
      setImporting(false);
      resetForm();
      console.log('Import error', e);
    }
  };

  return (
    <div className="row">
      <div className="col-md-7">
        <div className="d-flex flex-xxl-row flex-column align-items-xxl-end">
          <div className="mr15 mb18 flex-grow-1 input-mb0">
            <Input
              data-cy={'gym-info-temp-form-input-kisi-door-id'}
              name="kisiDoorId"
              label={t(getTranslationKey('KISI_DOOR_ID_HEADER'))}
              placeholder={t(getTranslationKey('KISI_DOOR_ID_HEADER_PLACEHOLDER'))}
              error={errors.kisiDoorId ? true : false}
              errorMessage={errors.kisiDoorId?.message}
              onChange={(e) => setImportParams({ ...importParams, doorId: e.currentTarget.value })}
              value={importParams.doorId}
              disabled={props.type === 'modify'}
            />
          </div>
          <div>
            <div className="mb18">
              <Button
                data-cy={'gym-info-temp-form-btn-import'}
                label={t(getTranslationKey('FORM_BTN_LABEL_IMPORT'))}
                variant={ButtonVariants.outline}
                size={ButtonSizes.large}
                icon={importing ? 'pi-spinner pi-spin' : ''}
                onClick={kisiDoorImportHandler}
                disabled={props.type === 'modify'}
              />
            </div>
          </div>
        </div>
        <div className="row mb18">
          {importErrorMessage && (
            <p data-cy={'gym-info-temp-form-btn-import-error'} className="p-error mb-15">
              {importErrorMessage}
            </p>
          )}
        </div>
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit(submitHandler)} noValidate>
              <Input
                data-cy={'gym-info-temp-form-input-contact-person'}
                name="kisiDoorName"
                label={t(getTranslationKey('KISI_DOOR_NAME_HEADER'))}
                value={formValues.kisiDoorName}
                onChange={changeHandler}
                error={errors.kisiDoorName ? true : false}
                errorMessage={errors.kisiDoorName?.message}
                disabled={true}
              />
              <Input
                data-cy={'gym-info-temp-form-input-email'}
                name="kisiPlaceId"
                label={t(getTranslationKey('KISI_PLACE_ID_HEADER'))}
                value={formValues.kisiPlaceId ? formValues.kisiPlaceId.toString() : ''}
                onChange={changeHandler}
                error={errors.kisiPlaceId ? true : false}
                errorMessage={errors.kisiPlaceId?.message}
                disabled={true}
              />
              <Input
                data-cy={'gym-info-temp-form-input-email'}
                name="kisiPlaceName"
                label={t(getTranslationKey('KISI_PLACE_NAME_HEADER'))}
                value={formValues.kisiPlaceName.toString()}
                onChange={changeHandler}
                error={errors.kisiPlaceName ? true : false}
                errorMessage={errors.kisiPlaceName?.message}
                disabled={true}
              />

              <div className="d-flex flex-row mt35 btn-min-w-110">
                <div className="mr15">
                  <Button
                    data-cy={'gym-info-temp-form-btn-save'}
                    label={t(getTranslationKey('FORM_BTN_LABEL_SAVE'))}
                    size={ButtonSizes.medium}
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                  />
                </div>
                <Button
                  data-cy={'gym-info-temp-form-btn-cancel'}
                  label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
                  variant={ButtonVariants.textonly}
                  onClick={() =>
                    history.push(`/employeeDashboard/${props.gymChainId}/${props.siteLocationId}`)
                  }
                  disabled={isInserted}
                />
                <ActionFailedDialog />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

type DoorInfoTemplateType = 'new' | 'modify';

export interface DoorInfoTemplateProps {
  /** Type of the template: new or modify */
  type: DoorInfoTemplateType;
  /** ID of the selected gym chain */
  gymChainId: number;
  /** ID of the selected gym chain */
  siteLocationId: number;
  /** Door to modify, only for the modify scenario */
  door?: Door;
}

export default DoorInfoTemplate;
