import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import {
  getMemberships,
  getMembershipsForSiteLocation,
  getMembershipTypes
} from '@gym-graphql/queries';
import { Membership, MembershipInfo, MembershipSite, MembershipType } from '@gym-src/API';
import {
  MembershipSite as typeOfMembershipSite,
  PaginatedQueryParams
} from '@gym-particles/types/models';
import { MemberShipType as typesOfMembership } from '@gym-particles/types/MembershipType';
import { MembershipSiteLocation as typeOfMembershipSiteLocation } from '@gym-particles/types/Membership';
import i18n from '@gym-particles/i18next';
import { deleteMembership as deleteMembershipMutation } from '@gym-graphql/mutations';

dayjs.extend(relativeTime);

const initialState: {
  isMembershipsLoading: boolean;
  isMembershipTypesLoading: boolean;
  isMembershipSiteLocationLoading: boolean;
  membershipTypes: Array<typesOfMembership>;
  membershipsForSite: Array<{
    chainId: number;
    items: typeOfMembershipSite[];
    totalRecords: number;
  }>;
  membershipSiteLocation: Array<{
    siteLocationId: number;
    items: typeOfMembershipSiteLocation[];
    totalRecords: number;
  }>;
} = {
  isMembershipsLoading: false,
  isMembershipTypesLoading: false,
  isMembershipSiteLocationLoading: false,
  membershipTypes: [],
  membershipsForSite: [],
  membershipSiteLocation: []
};

type membershipsForSiteParamType = {
  siteId: number;
  pagination: PaginatedQueryParams;
  isMindbody: boolean;
};
type membershipsForSiteLocationParamType = {
  siteLocationId: number;
  pagination: PaginatedQueryParams;
};

export const fetchMembershipsForSite = createAsyncThunk(
  'membership/getMemberships',
  async (params: membershipsForSiteParamType) => {
    const response = await (API.graphql({
      query: getMemberships,
      variables: {
        siteId: params.siteId,
        offset: params.pagination.offset,
        pageSize: params.pagination.pageSize,
        sortField: params.pagination.sortField,
        sortOrder: params.pagination.sortOrder === 1 ? 'ASC' : 'DESC',
        search: {
          searchField: params.pagination.search?.searchField,
          searchText: params.pagination.search?.searchText
        },
        isMindbody: params.isMindbody || false
      }
    }) as Promise<{
      data: { getMemberships: { items: MembershipSite[]; totalRecords: { totalRecords: number } } };
    }>);
    return response;
  }
);

export const fetchMembershipsForSiteLocation = createAsyncThunk(
  'membership/getMembershipsBySiteLocationId',
  async (params: membershipsForSiteLocationParamType) => {
    const response = await (API.graphql({
      query: getMembershipsForSiteLocation,
      variables: {
        siteLocationId: params.siteLocationId,
        offset: params.pagination.offset,
        pageSize: params.pagination.pageSize,
        sortField: params.pagination.sortField,
        sortOrder: params.pagination.sortOrder === 1 ? 'ASC' : 'DESC',
        search: {
          searchField: params.pagination.search?.searchField,
          searchText: params.pagination.search?.searchText
        }
      }
    }) as Promise<{
      data: {
        getMembershipsForSiteLocation: {
          items: MembershipInfo[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const fetchMembershipTypes = createAsyncThunk(
  'membershipType/getMembershipTypes',
  async () => {
    const response = await (API.graphql({
      query: getMembershipTypes
    }) as Promise<{
      data: { getMembershipTypes: MembershipType[] };
    }>);
    return response;
  }
);

export const deleteMembership = createAsyncThunk(
  'SiteLocation/deleteMembership',
  async (args: { id: number; gymChainId: number }) => {
    const response = await (API.graphql({
      query: deleteMembershipMutation,
      variables: {
        id: args.id
      }
    }) as Promise<{
      data: { deleteMembership: Membership };
    }>);
    return response;
  }
);

export const membershipForSiteSlice = createSlice({
  name: 'membershipForSite',
  initialState,
  reducers: {
    toggleLoading: (state) => {
      state.isMembershipsLoading = !state.isMembershipsLoading;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMembershipsForSite.fulfilled, (state, action) => {
      state.isMembershipsLoading = false;
      if (action.payload.data.getMemberships === null) {
        return;
      }
      const items = action.payload.data.getMemberships.items.map(
        (e: MembershipSite): typeOfMembershipSite => {
          return {
            id: e.id || 0,
            siteId: e.siteId || 0,
            membershipName: e.membershipName || '',
            membershipTypeId: e.membershipTypeId || 0,
            membershipType: e.membershipType || '',
            isImported: e.isImported || false,
            importedId: e.importedId,
            validityPeriod: e.validityPeriod || 0,
            status: e.status || '',
            siteLocations: e.siteLocations || '',
            program: e.program || '',
            displayStatus: e.status ? e.status.charAt(0).toUpperCase() + e.status.slice(1) : '',
            createdBy: e.createdBy || 0,
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            displayIsImported: e.isImported ? i18n.t('COMMON.YES') : i18n.t('COMMON.NO'),
            createdUserName: `${e.firstName} ${e.lastName}` || '',
            displayHasRelatedAccessCategory: e.hasRelatedAccessCategory ? 'Yes' : 'No',
            scheduleType: e.scheduleType || null,
            isContractOnly: e.isContractOnly || false
          };
        }
      );

      const totalRecords = action.payload.data.getMemberships.totalRecords.totalRecords;
      const chainId = action.meta.arg.siteId;
      const fetchedMembershipInfo = {
        chainId: chainId,
        totalRecords: totalRecords,
        items: items
      };
      if (state.membershipsForSite.length === 0) {
        state.membershipsForSite.push(fetchedMembershipInfo);
      } else {
        const update = state.membershipsForSite.find(
          (membershipInfo) => membershipInfo.chainId === fetchedMembershipInfo.chainId
        );
        if (update) {
          const newState = state.membershipsForSite.map((membershipInfo) => {
            if (membershipInfo.chainId === fetchedMembershipInfo.chainId) {
              return fetchedMembershipInfo;
            } else {
              return membershipInfo;
            }
          });
          Object.assign(state.membershipsForSite, newState);
        } else {
          state.membershipsForSite.push(fetchedMembershipInfo);
        }
      }
    });

    builder.addCase(fetchMembershipsForSite.pending, (state, action) => {
      state.isMembershipsLoading = true;
      return state;
    });

    builder.addCase(fetchMembershipsForSiteLocation.fulfilled, (state, action) => {
      state.isMembershipSiteLocationLoading = false;

      if (action.payload.data.getMembershipsForSiteLocation === null) {
        return;
      }
      const items = action.payload.data.getMembershipsForSiteLocation.items.map(
        (e: MembershipInfo): typeOfMembershipSiteLocation => {
          return {
            SiteLocationMembershipId: e.siteLocationMembershipId || 0,
            siteLocationId: e.siteLocationId || 0,
            membershipId: e.membershipId || 0,
            membershipName: e.membershipName || '',
            membershipTypeName: e.membershipTypeName || '',
            validityPeriod: e.validityPeriod || 0,
            isImported: e.isImported || false,
            importedId: e.importedId,
            status: e.status || '',
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            displayValidityPeriod: e.validityPeriod
              ? e.validityPeriod > 30
                ? Math.floor(e.validityPeriod / 30) + ' Months'
                : e.validityPeriod + ' Days'
              : '',
            displayStatus: e.status ? e.status.charAt(0).toUpperCase() + e.status.slice(1) : '',
            displayIsImported: e.isImported ? i18n.t('COMMON.YES') : i18n.t('COMMON.NO')
          };
        }
      );
      const totalRecords =
        action.payload.data.getMembershipsForSiteLocation.totalRecords.totalRecords;

      const siteLocationId = action.meta.arg.siteLocationId;

      const fetchedMembershipSiteLocationInfo = {
        siteLocationId: siteLocationId,
        totalRecords: totalRecords,
        items: items
      };

      if (state.membershipSiteLocation.length === 0) {
        state.membershipSiteLocation.push(fetchedMembershipSiteLocationInfo);
      } else {
        const update = state.membershipSiteLocation.find(
          (membershipSiteLocationInfo) =>
            membershipSiteLocationInfo.siteLocationId ===
            fetchedMembershipSiteLocationInfo.siteLocationId
        );
        if (update) {
          const newState = state.membershipSiteLocation.map((membershipSiteLocationInfo) => {
            if (
              membershipSiteLocationInfo.siteLocationId ===
              fetchedMembershipSiteLocationInfo.siteLocationId
            ) {
              return fetchedMembershipSiteLocationInfo;
            } else {
              return membershipSiteLocationInfo;
            }
          });
          Object.assign(state.membershipSiteLocation, newState);
        } else {
          state.membershipSiteLocation.push(fetchedMembershipSiteLocationInfo);
        }
      }
    });

    builder.addCase(fetchMembershipsForSiteLocation.pending, (state, action) => {
      state.isMembershipSiteLocationLoading = true;
      return state;
    });

    builder.addCase(fetchMembershipsForSiteLocation.rejected, (state, action) => {
      state.isMembershipSiteLocationLoading = false;
      console.error('fetchMembershipsForSiteLocation', action);
      return state;
    });

    builder.addCase(fetchMembershipTypes.fulfilled, (state, action) => {
      state.isMembershipTypesLoading = false;
      if (action.payload.data.getMembershipTypes === null) {
        return;
      }
      const items = action.payload.data.getMembershipTypes.map(
        (e: MembershipType): typesOfMembership => {
          return {
            id: e.id || 0,
            name: e.name || ''
          };
        }
      );
      state.membershipTypes = items;
      return state;
    });

    builder.addCase(fetchMembershipTypes.pending, (state, action) => {
      state.isMembershipTypesLoading = true;
      return state;
    });
    builder.addCase(deleteMembership.fulfilled, (state, action) => {
      state.isMembershipsLoading = false;
      const membershipId = action.meta.arg.id;
      const gymChainMembership = state.membershipsForSite.find(
        (memberships) => memberships.chainId === action.meta.arg.gymChainId
      );
      if (!gymChainMembership) {
        return;
      }
      const filtered = gymChainMembership?.items.filter((membershipObj) => {
        return membershipObj.id !== membershipId;
      });

      gymChainMembership.items = filtered;
      const filteredMemberships = state.membershipsForSite.map((memberships) => {
        if (memberships.chainId === action.meta.arg.gymChainId) {
          return gymChainMembership;
        } else {
          return memberships;
        }
      });
      state.membershipsForSite = filteredMemberships;
    });
    builder.addCase(deleteMembership.pending, (state, action) => {
      state.isMembershipsLoading = true;
      return state;
    });
  }
});

export const { toggleLoading } = membershipForSiteSlice.actions;

export default membershipForSiteSlice.reducer;
