import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import DateTimePicker, {
  DateTimePickerChangeEvent
} from '@gym-atoms/DateTimePicker/DateTimePicker';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Dropdown, { DropdownChangeEvent, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import Input from '@gym-atoms/Input/Input';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import {
  activateScheduleStatus,
  addDaySchedule,
  modifyDaySchedule,
  resolveScheduleRequest
} from '@gym-graphql/mutations';
import {
  getAllScheduleStatuses,
  getAllWeekDays,
  getSiteScheduleDetailsById
} from '@gym-graphql/queries';
import {
  DaySchedule,
  DaySchedulesFormInput,
  RequestDaySchedules
} from '@gym-particles/types/DaySchedules';
import { fetchDaySchedules } from '@gym-redux/slices/daySchedulesSlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import {
  DayScheduleInput,
  getAllWeekDaysResponse,
  ScheduleStatuses,
  SiteScheduleDetails
} from '@gym-src/API';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useHistory } from 'react-router-dom';
import { GymScheduleStatus, ScheduleInternalStatus } from '@gym-particles/types/GymSchedules';

dayjs.extend(customParseFormat);

const ManageDaySchedulesTemplate = (props: ManageDaySchedulesTemplateProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [sortField, setSortField] = useState('createdDate');
  const [sortOrder, setSortOrder] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const userId = useAppSelector((state) => state.user.userId);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const [isInserted, setIsInserted] = useState(false);
  const [dayDropDownItems, setDayDropDownItems] = useState<Array<SelectItem>>([]);
  const [scheduleStatuses, setScheduleStatuses] = useState<Array<GymScheduleStatus>>([]);

  const getTranslationKey = (e: string): string => {
    return props.type === 'new' ? 'ADD_NEW_DAY_SCHEDULE.' + e : 'MODIFY_DAY_SCHEDULE.' + e;
  };

  const templateTypeChangeHandler = useCallback(
    (state) => {
      if (props.templateType) {
        props.templateType(state);
      }
    },
    [props.templateType]
  );

  const getWeekdays = async () => {
    try {
      const response = (API.graphql({
        query: getAllWeekDays
      }) as Promise<{
        data: { getAllWeekDays: [getAllWeekDaysResponse] };
      }>).then((e) => {
        const dayDropDownItems: SelectItem[] = e.data.getAllWeekDays.map((data) => {
          if (data && data.name && data.id) {
            return {
              label: data.name.charAt(0).toUpperCase() + data.name.slice(1),
              value: data.id
            };
          } else {
            return { label: '', value: 0 };
          }
        }) || [{ label: '', value: '' }];
        setDayDropDownItems(dayDropDownItems);
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  useEffect(() => {
    getWeekdays();
  }, []);

  const daySchedulesDefaultValues: DaySchedulesFormInput = {
    arxReferenceId: props.daySchedules?.arxReferenceId || '',
    name: props.daySchedules?.name || '',
    weekdayId: props.daySchedules?.weekdayId || props.requestDaySchedule?.weekdayId || -1,
    startTime: props.daySchedules?.startTime
      ? new Date(dayjs(props.daySchedules?.startTime, 'HH:mm').format('YYYY-MM-DD HH:mm'))
      : props.requestDaySchedule?.startTime
      ? new Date(dayjs(props.requestDaySchedule?.startTime).format('YYYY-MM-DD HH:mm'))
      : undefined,
    endTime: props.daySchedules?.endTime
      ? new Date(dayjs(props.daySchedules?.endTime, 'HH:mm').format('YYYY-MM-DD HH:mm'))
      : props.requestDaySchedule?.endTime
      ? new Date(dayjs(props.requestDaySchedule?.endTime).format('YYYY-MM-DD HH:mm'))
      : undefined,
    status: props.daySchedules?.status || '',
    createdBy: props.daySchedules?.createdBy || -1,
    lastModifiedBy: userId
  };

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    reset,
    getValues
  } = useForm<DaySchedulesFormInput>({
    defaultValues: daySchedulesDefaultValues
  });

  const [formValues, setFormValues] = useState(daySchedulesDefaultValues);

  useEffect(() => {
    setFormValues(daySchedulesDefaultValues);
    reset(daySchedulesDefaultValues);
  }, [props.daySchedules, props.requestDaySchedule]);

  const statusDropDownItems = [
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN_ACTIVE')),
      value: 'active'
    },
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN_INACTIVE')),
      value: 'inactive'
    }
  ];

  const textFieldChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof DaySchedulesFormInput, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  const dropdownChangeHandler = (e: DropdownChangeEvent) => {
    setValue(e.target.name as keyof DaySchedulesFormInput, e.value);
    setFormValues({ ...formValues, [e.target.name]: e.value });
  };

  const dateTimeChangeHandler = (e: DateTimePickerChangeEvent) => {
    setValue(e.target.name as keyof DaySchedulesFormInput, e.target.value);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    register('arxReferenceId', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('name', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('weekdayId', {
      validate: (value) => {
        if (value === -1) {
          return false;
        } else {
          return true;
        }
      }
    });
    register('startTime', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      validate: {
        rangeValidation: () => validateTimeRange()
      }
    });
    register('endTime', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('status', {
      validate: (value) => {
        if (value === '') {
          return false;
        } else {
          return true;
        }
      }
    });
  }, [register]);

  const validateTimeRange = () => {
    if (
      dayjs(getValues('startTime')).format('HH:mm') >= dayjs(getValues('endTime')).format('HH:mm')
    ) {
      return false;
    } else {
      return true;
    }
  };

  const addDaySchedules = async (data: DaySchedulesFormInput) => {
    const dayScheduleInput: DayScheduleInput = {
      arxReferenceId: data.arxReferenceId,
      name: data.name,
      weekdayId: data.weekdayId,
      startTime: dayjs(data.startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: dayjs(data.endTime).format('YYYY-MM-DD HH:mm:ss'),
      status: data.status,
      createdBy: userId,
      lastModifiedBy: userId
    };

    let dayScheduleId;

    try {
      const addDayScheduleResponse = await (API.graphql({
        query: addDaySchedule,
        variables: {
          input: dayScheduleInput
        }
      }) as Promise<{
        data: { addDaySchedule: DaySchedule };
      }>);
      dayScheduleId = addDayScheduleResponse.data.addDaySchedule.id;
      resetForm();
    } catch (err) {
      setFailedDialogVisible(true);
      console.log('Add Day Schedule Error: ', err);
    }

    if (props.requestDaySchedule) {
      try {
        await API.graphql({
          query: resolveScheduleRequest,
          variables: {
            id: props.requestDaySchedule.scheduleId,
            userId: userId,
            dayScheduleId: dayScheduleId
          }
        });
        await checkGymScheduleStatusProgress(props.requestDaySchedule.scheduleId);
        history.push('/scheduleRequests');
      } catch (err) {
        setFailedDialogVisible(true);
        console.log('Add Day Schedule for Requests Error: ', err);
      }
    }
  };

  useEffect(() => {
    getScheduleStatuses();
  }, []);

  const checkGymScheduleStatusProgress = async (siteScheduleDetailsId: number) => {
    try {
      const response = await (API.graphql({
        query: getSiteScheduleDetailsById,
        variables: {
          id: siteScheduleDetailsId
        }
      }) as Promise<{
        data: { getSiteScheduleDetailsById: SiteScheduleDetails[] };
      }>);
      const unavailableScheduleExists = response.data.getSiteScheduleDetailsById.some(
        (e: SiteScheduleDetails) => e.isRequested === true
      );
      if (!unavailableScheduleExists) {
        const chainId = response.data.getSiteScheduleDetailsById[0].siteId || 0;
        const siteScheduleId = response.data.getSiteScheduleDetailsById[0].siteScheduleId || 0;
        const progressionStatusId =
          scheduleStatuses.find((a) => a.name === ScheduleInternalStatus.Active)?.id || -1;
        await activateScheduleStatuses(chainId, siteScheduleId, progressionStatusId);
      }
    } catch (error) {
      setFailedDialogVisible(true);
      console.log('check GymSchedule Status Progress Error: ', error);
    }
  };

  const getScheduleStatuses = async () => {
    try {
      const response = (API.graphql({
        query: getAllScheduleStatuses
      }) as Promise<{
        data: { getAllScheduleStatuses: [ScheduleStatuses] };
      }>).then((e) => {
        const scheduleStatusItems: GymScheduleStatus[] = e.data.getAllScheduleStatuses.map(
          (data) => {
            return { name: data.name || '', id: data.id || 0 };
          }
        );
        setScheduleStatuses(scheduleStatusItems);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const activateScheduleStatuses = async (
    chainId: number,
    scheduleId: number,
    scheduleStatusId: number
  ) => {
    try {
      await (API.graphql({
        query: activateScheduleStatus,
        variables: {
          siteScheduleId: scheduleId,
          scheduleStatusId: scheduleStatusId,
          userId: userId,
          siteId: chainId
        }
      }) as Promise<{
        data: { activateScheduleStatus: number };
      }>);
    } catch (error) {
      console.log('activateScheduleStatus error: ', error);
    }
  };

  const modifyDaySchedules = async (data: DaySchedulesFormInput) => {
    const dayScheduleInput: DayScheduleInput = {
      id: props.daySchedules?.id,
      arxReferenceId: data.arxReferenceId,
      name: data.name,
      weekdayId: data.weekdayId,
      startTime: dayjs(data.startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: dayjs(data.endTime).format('YYYY-MM-DD HH:mm:ss'),
      status: data.status,
      createdBy: props.daySchedules?.createdBy || -1,
      lastModifiedBy: userId
    };

    try {
      const modifyDayScheduleResponse = await (API.graphql({
        query: modifyDaySchedule,
        variables: {
          input: dayScheduleInput
        }
      }) as Promise<{
        data: { modifyDaySchedule: DaySchedule };
      }>);
      resetForm();
    } catch (err) {
      setFailedDialogVisible(true);
      console.log('Modify Day Schedule Error: ', err);
    }
  };

  const onSubmitHandler = async (data: DaySchedulesFormInput) => {
    setIsInserted(true);
    if (props.type === 'new') {
      await addDaySchedules(data);
    } else {
      await modifyDaySchedules(data);
      templateTypeChangeHandler(false);
    }

    dispatch(
      fetchDaySchedules({
        offset: offset,
        pageSize: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        search: { searchField: '', searchText: searchTerm }
      })
    );

    setIsInserted(false);
  };

  const emptyValues: DaySchedulesFormInput = {
    arxReferenceId: '',
    name: '',
    weekdayId: -1,
    startTime: undefined,
    endTime: undefined,
    status: '',
    createdBy: userId,
    lastModifiedBy: userId
  };

  const resetForm = () => {
    setFormValues(emptyValues);
    reset(emptyValues);
  };

  const clearForm = () => {
    resetForm();
    dispatch(
      fetchDaySchedules({
        offset: offset,
        pageSize: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        search: { searchField: '', searchText: searchTerm }
      })
    );
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}</Text>}
        />
      </div>
    );
  };

  return (
    <div className="ml30 mt10">
      <div>
        <Text
          data-cy={'day-shedule-temp-form-header'}
          bold={true}
          size={TextSizes.large}
          className="text-2xl">
          {props.heading}
        </Text>
      </div>
      <div className="col-md-6">
        <form className="input-mb20 py-4" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <Input
            data-cy={'day-shedule-temp-temp-form-input-name'}
            label={t(getTranslationKey('FORM_INPUT_SCHEDULE_ID_LABEL'))}
            variant="basic"
            placeholder={t(getTranslationKey('FORM_INPUT_SCHEDULE_ID_PLACEHOLDER'))}
            onChange={textFieldChangeHandler}
            name="arxReferenceId"
            error={errors.arxReferenceId ? true : false}
            errorMessage={errors.arxReferenceId?.message}
            value={formValues.arxReferenceId}
            required={true}
          />
          <Input
            data-cy={'day-shedule-temp-temp-form-input-name'}
            label={t(getTranslationKey('FORM_INPUT_SCHEDULE_NAME_LABEL'))}
            variant="basic"
            placeholder={t(getTranslationKey('FORM_INPUT_SCHEDULE_NAME_PLACEHOLDER'))}
            onChange={textFieldChangeHandler}
            name="name"
            error={errors.name ? true : false}
            errorMessage={errors.name?.message}
            value={formValues.name}
            required={true}
          />
          <div className="mb25">
            <label className="fw-bold">
              {t(getTranslationKey('FORM_INPUT_SELECT_DAY_LABEL'))}
              <span className="p-error"> *</span>
            </label>
            <Dropdown
              name="weekdayId"
              placeholder={t(getTranslationKey('FORM_INPUT_SELECT_DAY_PLACEHOLDER'))}
              value={formValues.weekdayId}
              options={dayDropDownItems}
              onChange={dropdownChangeHandler}
              error={errors.weekdayId ? true : false}
              disabled={props.type === 'modify'}
            />
            {errors.weekdayId && (
              <p className="p-error mb15">{t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}</p>
            )}
          </div>
          <div className="row mb25">
            <div className="col-6">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_START_TIME_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <DateTimePicker
                variant="time"
                name="startTime"
                placeholder={'12:00 AM'}
                onChange={dateTimeChangeHandler}
                value={formValues.startTime}
                disabled={props.type === 'modify'}
              />
              {errors.startTime && (
                <Text
                  data-cy={'day-shedule-temp-temp-form-date-invalid-error'}
                  className="p-error ">
                  {errors.startTime?.type === 'required'
                    ? t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))
                    : t(getTranslationKey('FORM_INPUT_INVALID_TIME_VALIDAION'))}
                </Text>
              )}
            </div>
            <div className="col-6">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_END_TIME_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <DateTimePicker
                variant="time"
                name="endTime"
                placeholder={'12:00 AM'}
                onChange={dateTimeChangeHandler}
                value={formValues.endTime}
                disabled={props.type === 'modify'}
              />
              {errors.endTime && (
                <Text
                  data-cy={'day-shedule-temp-temp-form-date-invalid-error'}
                  className="p-error ">
                  {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                </Text>
              )}
            </div>
          </div>
          <div className="row mb30">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_STATUS_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                name="status"
                placeholder={t(getTranslationKey('FORM_INPUT_STATUS_PLACEHOLDER'))}
                value={formValues.status}
                options={statusDropDownItems}
                onChange={dropdownChangeHandler}
                error={errors.status ? true : false}
              />
            </div>
            {errors.status && (
              <p data-cy={'day-shedule-temp-form-dropdown-stutus-error'} className="p-error mb15">
                {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
              </p>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex btn-min-w-110">
                <div className="mr15">
                  <Button
                    data-cy={'day-shedule-temp-form-btn-save'}
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                    label={t(getTranslationKey('FORM_BTN_LABEL_SAVE'))}
                    size={ButtonSizes.medium}
                    disabled={isInserted}
                  />
                </div>
                <Button
                  data-cy={'day-shedule-temp-form-btn-cancel'}
                  label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
                  variant={ButtonVariants.textonly}
                  onClick={
                    props.requestDaySchedule
                      ? () => history.push('/scheduleRequests')
                      : (e) => {
                          clearForm();
                          e.preventDefault();
                          templateTypeChangeHandler(false);
                        }
                  }
                  disabled={isInserted}
                />
              </div>
            </div>
          </div>
          <ActionFailedDialog />
        </form>
      </div>
    </div>
  );
};
type ManageDaySchedulesTemplateType = 'new' | 'modify';

export interface ManageDaySchedulesTemplateProps {
  /** Type of the template: new or modify */
  type: ManageDaySchedulesTemplateType;
  /** Heading of the template */
  heading?: string;
  /** form values*/
  daySchedules?: DaySchedule | undefined;
  /** form values*/
  requestDaySchedule?: RequestDaySchedules;
  /** template change function */
  templateType?: (e: boolean) => void;
}

export default ManageDaySchedulesTemplate;
