import { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { AlertType } from '@gym-atoms/Alert/Alert';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import GymInfoTemplate from '@gym-templates/GymInfo/GymInfoTemplate';

const MainColumn = (props: { chainId: string }) => {
  return (
    <Card className="pt30 pl40 pr40 pb30">
      <GymInfoTemplate type="new" gymChainId={+props.chainId} />
    </Card>
  );
};

const AddNewGymPage = () => {
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { chainId } = useParams<{ chainId: string }>();
  const { t } = useTranslation();

  return (
    <TwoColTemplate
      title={t('ADD_NEW_GYM.PAGE_TITLE')}
      col1={<MainColumn chainId={chainId} />}
      gymChainId={+chainId}
      alertRef={alertRef}
      backButtonHandler={() => history.push(`/gymChains/${chainId}/gyms`)}
    />
  );
};

export default AddNewGymPage;
