import { Calendar } from 'primereact/calendar';
import 'primeflex/primeflex.css';

type DateTimeVariant = 'date' | 'time';

export enum DateTimePickerSizes {
  small = 'p-datetimepicker-sm',
  medium = 'p-datetimepicker'
}

const DateTimePicker = ({
  label,
  showIcon = true,
  value,
  placeholder,
  variant,
  size = DateTimePickerSizes.medium,
  ...props
}: DateTimePickerProps) => {
  const finalProps = {
    value: value,
    placeholder: placeholder,
    timeOnly: variant !== 'date',
    icon: variant === 'date' ? 'pi pi-calendar' : 'pi pi-clock',
    minDateDisable: false,
    className: `${size}`,
    ...props
  };

  return (
    <>
      {label && (
        <label className="fw-bold w-100" htmlFor="icon">
          {label}
        </label>
      )}
      <Calendar
        id="icon"
        dateFormat="dd/mm/yy"
        hourFormat="12"
        readOnlyInput
        showIcon={showIcon}
        minDate={
          variant === 'date' ? (props.minDateDisable ? undefined : new Date(Date.now())) : undefined
        }
        {...finalProps}
      />
    </>
  );
};

export type DateTimePickerChangeEvent = {
  originalEvent: Event;
  value: Date;
  target: {
    name: string;
    id: string;
    value: Date;
  };
};
export interface DateTimePickerProps {
  /** Name is needed when working with forms */
  name: string;
  /** Label to be displayed */
  label?: string;
  /** Icon will be displayed or not */
  showIcon?: boolean;
  /** Value submitted to form */
  value?: Date;
  /** Placeholder when the field is empty */
  placeholder?: string;
  /** onChange is needed when working with forms */
  onChange?: (e: DateTimePickerChangeEvent) => void;
  /** Select date or time picker */
  variant: DateTimeVariant;
  /** Disable date or time picker */
  disabled?: boolean;
  /**disable dates before today*/
  minDateDisable?: boolean;
  /** size of the DateTimePicker*/
  size?: DateTimePickerSizes;
}

export default DateTimePicker;
