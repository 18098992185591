import Loader from '@gym-atoms/Loader/Loader';
import { getAccessMethodsForMember } from '@gym-graphql/queries';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { MemberAccessMethodsTable, Member } from '@gym-particles/types/Member';
import { MemberAccessMethodsResponse } from '@gym-src/API';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { Card } from 'primereact/card';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const MemberModalAccessMethods = (props: { member: Member }) => {
  const [membershipSortField, setMembershipSortField] = useState('createdDate');
  const [membershipSortOrder, setMembershipSortOrder] = useState(1);
  const [membershipOffset, setMembershipOffset] = useState(0);
  const [membershipPageSize, setMembershipPageSize] = useState(10);
  const [selectedMembershipRow, setSelectedMembershipRow] = useState<MemberAccessMethodsTable>();
  const { t } = useTranslation();
  const [accessMethods, setAccessMethods] = useState<Array<any>>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { chainId, gymId, tab } = useParams<{ chainId: string; tab: string; gymId: string }>();

  const getAccessMethods = async () => {
    setIsDataLoading(true);
    try {
      const response = (API.graphql({
        query: getAccessMethodsForMember,
        variables: {
          userId: props.member.userId,
          memberId: props.member.id,
          siteLocationId: gymId
        }
      }) as Promise<{
        data: { getAccessMethodsForMember: MemberAccessMethodsResponse };
      }>).then((e) => {
        setIsDataLoading(false);
        const data = e.data.getAccessMethodsForMember.items?.map((i) => ({
          accessCardUID: i?.accessCardUID || 'N/A',
          mobileAppId: i?.mobileAppId,
          createdDate: dayjs(i?.createdDate || '').format('DD MMM YYYY'),
          createdBy: i?.createdBy,
          lastModifiedDate: i?.lastModifiedDate,
          lastModifiedBy: i?.lastModifiedBy,
          status:
            i?.type === 'mobileAppUser'
              ? i?.isMobileAppDisabled
                ? t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.INACTIVE')
                : t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.ACTIVE')
              : i?.isActive
              ? t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.ACTIVE')
              : t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.INACTIVE'),
          type:
            i?.type === 'mobileAppUser'
              ? t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.ACCESS_TYPE_MOBILE_APP')
              : i?.type === 'justTap'
              ? t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.ACCESS_TYPE_JUST_TAP')
              : t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.ACCESS_TYPE_ACCESS_CARD')
        }));
        setAccessMethods(data || []);
      });
    } catch (error) {
      //TODO: hadle error scenario
      console.log(error);
    }
  };

  useEffect(() => {
    getAccessMethods();
  }, [props.member.userId]);

  const tablePropsMembershipsAccessMethods: TableProps<MemberAccessMethodsTable> = {
    exportFileName: t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.EXPORT_EXCEL_FILE_NAME'),
    setPageSize: setMembershipPageSize,
    setOffset: setMembershipOffset,
    pageSize: membershipPageSize,
    offset: membershipOffset,
    setSortField: setMembershipSortField,
    setSortOrder: setMembershipSortOrder,
    sortField: membershipSortField,
    sortOrder: membershipSortOrder,
    totalRecords: accessMethods.length || 0,
    data: accessMethods,
    lazy: false,
    uniqueFieldName: 'accessCardUID',
    searchPlaceholderText: t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.SEARCH_FIELD_TEXT'),
    excelBtntext: t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.EXPORT_EXCEL_BUTTON_LABEL'),
    headerText: t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.TABLE_HEADING_TEXT'),
    selectedRow: selectedMembershipRow,
    setSelectedRow: setSelectedMembershipRow,
    columns: [
      {
        field: 'accessCardUID',
        header: t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.HEADER_ACCESS_ID'),
        toggable: false,
        sortable: true
      },
      {
        field: 'type',
        header: t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.HEADER_TYPE'),
        toggable: false,
        sortable: true
      },
      {
        field: 'createdDate',
        header: t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.HEADER_ISSUED_DATE'),
        toggable: true
      },
      {
        field: 'status',
        header: t('MEMBER_DETAILS_MODAL.ACCESS_METHODS_TAB.HEADER_STATUS'),
        toggable: true
      }
    ]
  };

  return (
    <Card className="d-flex flex-column h-100 detailed-modal__content-col">
      {isDataLoading ? <Loader shape="table" /> : <Table {...tablePropsMembershipsAccessMethods} />}
    </Card>
  );
};

export default MemberModalAccessMethods;
