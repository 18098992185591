import { useTranslation } from 'react-i18next';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { useCallback, useRef, useState } from 'react';
import GymScheduleTemplate from '@gym-templates/GymSchedules/GymScheduleFormTemplate';
import {
  GymSchedulesFormInput,
  GymScheduleStatus,
  ScheduleInternalStatus
} from '@gym-particles/types/GymSchedules';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useParams } from 'react-router-dom';
import { SiteSchedule, SiteScheduleInput } from '@gym-src/API';
import { addSiteSchedules, updateSiteSchedule } from '@gym-graphql/mutations';
import { useAppSelector } from '@gym-redux/store';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Button from '@gym-atoms/Button/Button';
import Text from '@gym-atoms/Text/Text';

dayjs.extend(relativeTime);

const GymSchedulesForm = (props: GymScheduleFormProps) => {
  const alertRef = useRef<AlertType>(null);
  const { t } = useTranslation();
  const { chainId } = useParams<{ chainId: string }>();
  const userId = useAppSelector((state) => state.user.userId);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);

  const stepperChangeHandler = useCallback(
    (state) => {
      if (props.setActiveIndex) {
        props.setActiveIndex(state);
      }
    },
    [props.setActiveIndex]
  );

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t('ADD_NEW_GYM_SCHEDULE.FORM_FAILURE_DIALOG_HEADER')}
          dialogFooter={
            <Button
              label={t('ADD_NEW_GYM_SCHEDULE.FORM_FAILURE_DIALOG_BUTTON')}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t('ADD_NEW_GYM_SCHEDULE.FORM_FAILURE_DIALOG_BODY')}</Text>}
        />
      </div>
    );
  };

  const addSiteSchedule = async (generalDetails: GymSchedulesFormInput) => {
    const addSiteSchedulesInput: SiteScheduleInput = {
      siteId: +chainId,
      name: generalDetails.name.replace(/'/g, "''"),
      type: generalDetails.type,
      validFrom: dayjs(generalDetails.validFromDate || '')
        .format('YYYY-MM-DD')
        .toString(),
      validTo: dayjs(generalDetails.validToDate || '')
        .format('YYYY-MM-DD')
        .toString(),
      scheduleStatusId:
        props.scheduleStatuses.find((a) => a.name === ScheduleInternalStatus.PartiallySaved)?.id ||
        -1,
      createdBy: userId,
      lastModifiedBy: userId,
      status: ScheduleInternalStatus.Inactive
    };

    try {
      const addSiteScheduleResponse = await (API.graphql({
        query: addSiteSchedules,
        variables: {
          input: addSiteSchedulesInput
        }
      }) as Promise<{
        data: { addSiteSchedules: SiteSchedule };
      }>);
      props.setScheduleId(addSiteScheduleResponse.data.addSiteSchedules.id || 0);
      props.setScheduleType(addSiteScheduleResponse.data.addSiteSchedules.type || '');
      stepperChangeHandler(props.activeIndex + 1);
      props.setScheduleStatusId(
        addSiteScheduleResponse.data.addSiteSchedules.scheduleStatusId || 0
      );
    } catch (error) {
      console.log(error);
      setFailedDialogVisible(true);
    }
  };

  const modifySiteSchedule = async (data: GymSchedulesFormInput) => {
    const modifySiteSchedulesInput: SiteScheduleInput = {
      id: props.scheduleId,
      siteId: +chainId,
      name: data.name.replace(/'/g, "''"),
      type: data.type,
      validFrom: dayjs(data.validFromDate || '')
        .format('YYYY-MM-DD')
        .toString(),
      validTo: dayjs(data.validToDate || '')
        .format('YYYY-MM-DD')
        .toString(),
      scheduleStatusId: props.scheduleStatusId,
      createdBy: userId,
      lastModifiedBy: userId,
      status: data.status
    };

    try {
      const updateSiteScheduleResponse = await (API.graphql({
        query: updateSiteSchedule,
        variables: {
          input: modifySiteSchedulesInput
        }
      }) as Promise<{
        data: { updateSiteSchedule: number };
      }>);

      stepperChangeHandler(props.activeIndex + 1);
    } catch (error) {
      console.log(error);
      setFailedDialogVisible(true);
    }
  };

  const submitForm = async (data: GymSchedulesFormInput) => {
    if (props.isBacked || props.type === 'modify') {
      await modifySiteSchedule(data);
    } else if (props.type === 'new') {
      await addSiteSchedule(data);
    }
  };

  return (
    <>
      <GymScheduleTemplate
        type={props.type}
        heading={props.heading}
        setScheduleForm={props.setScheduleForm}
        gymSchedule={props.gymSchedules}
        submitHandler={(data) => submitForm(data)}
        scheduleStatusId={props.scheduleStatusId}
        scheduleStatuses={props.scheduleStatuses}
        isBacked={props.isBacked}
      />
      <ActionFailedDialog />
    </>
  );
};

type GymScheduleFormType = 'new' | 'modify';

export interface GymScheduleFormProps {
  type: GymScheduleFormType;
  heading?: string;
  activeIndex: number;
  setActiveIndex: (e: number) => void;
  setScheduleForm?: (e: GymSchedulesFormInput) => void;
  gymSchedules?: GymSchedulesFormInput;
  setScheduleId: (e: number) => void;
  scheduleStatusId: number;
  setScheduleStatusId: (e: number) => void;
  setScheduleType: (e: string) => void;
  isBacked?: boolean;
  scheduleStatuses: GymScheduleStatus[];
  scheduleId: number;
}

export default GymSchedulesForm;
