import { RouteProps } from 'react-router-dom';
import AccountsPage from '@gym-pages/AccountsPage/AccountsPage';
import AddNewGymChainPage from '@gym-pages/AddNewGymChainPage/AddNewGymChainPage';
import AdminPage from '@gym-pages/AdminPage';
import ForgotPassword from '@gym-pages/ForgotPasswordPage';
import HomePage from '@gym-pages/HomePage';
import ResetPassword from '@gym-pages/ResetPasswordPage';
import SignIn from '@gym-pages/SignInPage';
import UserManagementPage from '@gym-pages/UserManagementPage/UserManagementPage';
import GymChainListPage from '@gym-pages/GymChainListPage/GymChainListPage';
import GymChainDashboardPage from '@gym-pages/GymChainsPage/GymChainsPage';
import ModifyGymChainPage from '@gym-pages/ModifyGymChainPage/ModifyGymChainPage';
import AddNewGymPage from '@gym-pages/AddNewGymPage/AddNewGymPage';
import AddNewMembershipPage from '@gym-pages/AddNewMembershipPage/AddNewMembershipPage';
import ModifyMembershipPage from '@gym-pages/ModifyMembershipPage/ModifyMembershipPage';
import ModifyGymPage from '@gym-pages/ModifyGymPage/ModifyGymPage';
import GymDetailsPage from '@gym-pages/GymDetailsPage/GymDetailsPage';
import ManageDaySchedulesPage from '@gym-pages/ManageDaySchedulesPage/ManageDaySchedulesPage';
import AddNewGymSchedulePage from '@gym-pages/GymSchedulesPage/GymSchedulesPage';
import ModifyGymSchedulesPage from '@gym-pages/ModifyGymSchedulesPage/ModifyGymSchedulesPage';
import AddNewUserPage from '@gym-pages/UserManagementPage/AddNewUserPage';
import EditUserPage from '@gym-pages/UserManagementPage/EditUserPage';
import ModifyGymChainSettingsPage from '@gym-pages/ModifyGymChainSettingsPage/ModifyGymChainSettingsPage';
import ScheduleRequestPage from '@gym-pages/ScheduleRequestPage/ScheduleRequestPage';
import ClassPortalPage from '@gym-pages/ClassPortalPage';
import EmployeeDashboardPage from '@gym-pages/EmployeeDashboardPage';
import AddNewDoorPage from '@gym-pages/AddNewDoorPage/AddNewDoorPage';
import DoorUnlockEventsDashboardPage from '@gym-pages/DoorUnlockEventsDashboardPage';

export const publicRoutes: RouteProps[] = [
  {
    component: SignIn,
    path: '/login',
    exact: true
  },
  {
    component: ResetPassword,
    path: '/initial-reset',
    exact: true
  },
  {
    component: ForgotPassword,
    path: '/forgot-password',
    exact: true
  }
];

export const sharedRoutes: RouteProps[] = [];

export const privateRoutes: RouteProps[] = [
  {
    component: HomePage,
    path: '/home',
    exact: true
  },
  {
    component: AdminPage,
    path: '/admin',
    exact: true
  },
  {
    component: AccountsPage,
    path: '/accounts',
    exact: true
  },
  {
    component: GymChainListPage,
    path: '/gymChains',
    exact: true
  },
  {
    component: AddNewGymChainPage,
    path: '/addGymChain',
    exact: true
  },
  {
    component: HomePage,
    path: '/home/:tab',
    exact: true
  },
  {
    component: AddNewGymPage,
    path: '/gymChains/:chainId/gyms/new',
    exact: true
  },
  {
    component: AddNewMembershipPage,
    path: '/gymChains/:chainId/memberships/new',
    exact: true
  },
  {
    component: AddNewGymSchedulePage,
    path: '/gymChains/:chainId/gymSchedules/new',
    exact: true
  },
  {
    component: ModifyGymSchedulesPage,
    path: '/gymChains/:chainId/gymSchedules/:gymScheduleId',
    exact: true
  },
  {
    component: ModifyMembershipPage,
    path: '/gymChains/:chainId/memberships/:membershipId',
    exact: true
  },
  {
    component: ModifyGymChainPage,
    path: '/gymChains/:chainId/edit',
    exact: true
  },
  {
    component: GymChainDashboardPage,
    path: '/gymChains/:chainId/:tab',
    exact: true
  },
  {
    component: ModifyGymPage,
    path: '/gymChains/:chainId/gyms/:gymId/edit',
    exact: true
  },
  {
    component: GymDetailsPage,
    path: '/gymChains/:chainId/gyms/:gymId/:tab',
    exact: true
  },
  {
    component: GymDetailsPage,
    path: '/gymChains/:chainId/gyms/:gymId/dashboard/:tab',
    exact: true
  },
  {
    component: UserManagementPage,
    path: '/users',
    exact: true
  },
  {
    component: AddNewUserPage,
    path: '/users/new',
    exact: true
  },
  {
    component: EditUserPage,
    path: '/users/:userId'
  },
  {
    component: ManageDaySchedulesPage,
    path: '/daySchedules/:scheduleId?',
    exact: true
  },
  {
    component: ScheduleRequestPage,
    path: '/scheduleRequests',
    exact: true
  },
  {
    component: ModifyGymChainSettingsPage,
    path: '/gymChains/:chainId/settings/:ruleId',
    exact: true
  },
  {
    component: ClassPortalPage,
    path: '/classPortal/:gymChainId/:siteLocationId',
    exact: true
  },
  {
    component: EmployeeDashboardPage,
    path: '/employeeDashboard/:gymChainId/:siteLocationId',
    exact: true
  },
  {
    component: AddNewDoorPage,
    path: '/employeeDashboard/:gymChainId/:siteLocationId/doors/new',
    exact: true
  },
  {
    component: DoorUnlockEventsDashboardPage,
    path: '/employeeDashboard/:gymChainId/:siteLocationId/doors/:doorId/events',
    exact: true
  }
];
