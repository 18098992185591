import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace';
import { MultiSelect } from 'primereact/multiselect';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Input from '@gym-atoms/Input/Input';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import VerticalMenu, { VerticalMenuType, MenuItem } from '@gym-atoms/VerticalMenu/VerticalMenu';
import Avatar from '@gym-atoms/Avatar/Avatar';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import useDebounce from '@gym-particles/debounce';
import EmptyState from '@gym-molecules/EmptyState/EmptyState';
import styles from './Table.module.scss';
export interface Col<T> {
  /** Field to display in the table */
  field: string;
  /** Field to display in the table */
  exportHeader?: string;
  /** Header for the column */
  header?: string;
  /** Whether this column is sortable */
  sortable?: boolean;
  /** Avatar/image field to be shown with this field's data */
  imageField?: string;
  /** Whether this column is sortable */
  toggable: boolean;
  /**  Whether to truncate text, useful for fields with no spaces (eg: Email) */
  truncateText?: boolean;
  /** parse a template body to a column value */
  bodyTemplate?: (value: T) => JSX.Element;
  /**define a column width class */
  additionalClassName?: string;
}

// Cannot use <T extends object> because it is not recommended.
// Record<string, any> emulates an object.
const Table = <T extends Record<string, any>>({
  columns,
  headerBtns,
  headerBtnAlignment = 'Left',
  searchPlaceholderText: searchText,
  isContextMenu = false,
  menuItem,
  selectedRow,
  setSelectedRow,
  excelBtntext,
  uniqueFieldName = 'id',
  data = [],
  totalRecords = 0,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  pageSize = 10,
  offset,
  setPageSize,
  setOffset,
  enableRowClick,
  exportFileName,
  onRowClick,
  lazy = true,
  headerText,
  expandable = false,
  expandableField,
  expandedCols = [],
  searchTerm,
  setSearchTerm,
  selectionMode = 'single',
  emptyStateTexts = [],
  headerDropDowns
}: TableProps<T>) => {
  const { t } = useTranslation();
  const menuRef = useRef<VerticalMenuType>(null);
  const [tableData, setTableData] = useState<Array<T>>(data);
  const [tableRowCount, setTableRowCount] = useState<number>(totalRecords);
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [active, setActive] = useState(false);
  const [globalFilter, setGlobalFilter] = useState<string | undefined>(searchTerm);
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean> | undefined>();
  const [searchTextTerm, setSearchTextTerm] = useState<string | undefined>(searchTerm);
  const debouncedSearchTerm = useDebounce(searchTextTerm, 1000);
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const reArrangingDataArr = (
    selectedCols: Array<Col<T>>,
    dataArr: Array<Record<string, string | number>>
  ) => {
    const selectedColValues = selectedCols.map((value: Col<T>) => value.field);
    const reArrangedArray = reduceArray(dataArr, selectedColValues, selectedCols);
    return reArrangedArray;
  };

  const reduceArray = (
    array: Array<Record<string, string | number>>,
    selectedColValues: string[],
    selectedCols: Array<Col<T>>
  ) =>
    array.map((obj: Record<string, any>) =>
      selectedColValues.reduce((arr: Record<string, string | number>, curr: string) => {
        const exportHeader = selectedCols.filter((v) => v.field === curr)[0].exportHeader;
        if (typeof obj[curr] === 'object') {
          let objectValues = '';
          for (const key in obj[curr]) {
            objectValues = objectValues.concat(`${key} :- ${obj[curr][key]},`);
          }
          arr[exportHeader ? exportHeader : curr] = objectValues;
        } else {
          arr[exportHeader ? exportHeader : curr] = obj[curr];
        }
        return arr;
      }, {})
    );

  const exportToExcel = (dataArr: T[], fileName: string) => {
    let dataToExport: Array<Record<string, string | number>> = reArrangingDataArr(
      selectedColumns,
      dataArr
    );

    // Flattening the nested property before exporting
    if (expandable && expandableField) {
      const flattenedData: Array<Record<string, string | number>> = [];
      dataArr.forEach((row) => {
        const newObj: Record<string, string | number> = {};

        if (!(expandableField in row)) {
          // Nested property is not there
          flattenedData.push({ ...row });
        } else {
          row[expandableField].forEach((item: Record<string, string | number>, index: number) => {
            for (const [key, val] of Object.entries(item)) {
              if (typeof val === 'object') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (val instanceof Date) {
                  newObj[`${key}_${index}`] = val;
                } else {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  newObj[`${key}_${index}`] = val.join(', ');
                }
              } else {
                newObj[`${key}_${index}`] = val;
              }
            }
          });
          const selectedColValues = selectedColumns.map((value: Col<T>) => value.field);
          const newRow = selectedColValues.reduce(
            (arr: Record<string, string | number>, curr: string) => {
              arr[curr] = row[curr];
              return arr;
            },
            {}
          );

          const modifiedObj = { ...newRow, ...newObj };
          delete modifiedObj[expandableField];
          flattenedData.push(modifiedObj);
        }
      });

      dataToExport = flattenedData;
    }

    // Exporting
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    setTableRowCount(totalRecords);
  }, [totalRecords]);

  /** Type emitted on pagination events */
  type PaginationEvent = {
    rows: number;
    first: number;
  };

  /** Type emitted on sort events */
  type SortingEvent = {
    sortField: string;
    sortOrder: number;
    multiSortMeta?: any;
  };

  /** Type emitted on filter events */
  type FilterEvent = {
    filters: {
      globalFilter?: {
        matchMode: string;
        value: string;
      };
    };
  };

  /** Type emitted on row expand events */
  type RowExpandEvent = {
    data: {
      [key: number]: boolean;
    };
  };

  /** Type of object passed to CurrentPageReport component */
  type CurrentPageReportOptions = {
    first: number;
    last: number;
    totalRecords: number;
  };

  /** Runs on page change */
  const onPage = (event: PaginationEvent) => {
    if (setPageSize && setOffset) {
      setPageSize(event.rows);
      setOffset(event.first);
    }
  };

  /**  Runs on sorting change */
  const onSort = (event: SortingEvent) => {
    if (setSortField && setSortOrder) {
      setSortField(event.sortField);
      setSortOrder(event.sortOrder);
    }
  };

  /** Runs on expandable row toggle */
  const onRowToggle = (e: RowExpandEvent) => {
    setExpandedRows(e.data);
  };

  const frontEndSearch = (event: FilterEvent) => {
    if (!event.filters.globalFilter) {
      setTableData(data);
      return;
    }
    const searchText = event.filters.globalFilter.value.toLowerCase() || '';
    const newData: Array<T> = [];
    data.forEach((row) => {
      const keys = Object.keys(row);
      let push_ok = false;
      keys.forEach((key) => {
        // Check if we should search for matches in this column because we don't search hidden cols, image URL cols, etc
        const check = columns.find((col) => col.field === key);
        if (!check) {
          return;
        }
        // Searching and adding to new filtered array
        if (typeof row[key] === 'string') {
          if (row[key].toLowerCase().includes(searchText)) {
            push_ok = true;
          }
        } else if (typeof row[key] === 'number') {
          if (String(row[key]).toLowerCase().includes(searchText)) {
            push_ok = true;
          }
        }
      });
      if (push_ok) {
        newData.push(row);
      }
    });
    setTableData(newData);
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (setSearchTerm) {
        setSearchTerm(debouncedSearchTerm);
      }
    }
  }, [debouncedSearchTerm, searchTextTerm]);

  const backEndSearch = (event: FilterEvent) => {
    if (!event.filters.globalFilter) {
      if (setSearchTerm) {
        setSearchTerm('');
      }
      return;
    }
    const searchText = event.filters.globalFilter.value.toLowerCase() || '';
    if (setSearchTextTerm) {
      setSearchTextTerm(searchText);
    }
  };

  const onFilter = (event: FilterEvent) => {
    if (lazy) {
      backEndSearch(event);
    } else {
      frontEndSearch(event);
    }
  };

  /** HOC that renders cells with Image+Content */
  const getTemplate = (colName: string, imageFieldName?: string) => {
    const userBodyTemplate = (rawData: T) => {
      const displayData = rawData[colName];
      const fullName = displayData.split(' ');
      const firstLetter = fullName[0].charAt(0).toUpperCase();
      const lastLetter =
        fullName.length > 1 ? fullName[fullName.length - 1].charAt(0).toUpperCase() : '';
      const initials = firstLetter + lastLetter;
      if (imageFieldName && rawData[imageFieldName]) {
        return (
          <div className="d-flex align-items-center">
            <Avatar image={rawData[imageFieldName]} size="default" shape="circle" />
            <span className="text-truncate">{displayData}</span>
          </div>
        );
      } else {
        return (
          <div className="d-flex align-items-center">
            <Avatar label={initials} size="default" shape="square" />
            <span className="text-truncate">{displayData}</span>
          </div>
        );
      }
    };
    return userBodyTemplate;
  };

  /** Creates the selected columns */
  const columnComponents = selectedColumns.map((col) => {
    const templateComponent = getTemplate(col.field, col.imageField);
    let coloumnBody;
    if (col.imageField) {
      coloumnBody = templateComponent;
    } else if (col.bodyTemplate) {
      coloumnBody = col.bodyTemplate;
    } else {
      coloumnBody = null;
    }
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable={col.sortable}
        body={coloumnBody}
        className={`${col.imageField ? 'p-col-w-avatar' : ''} ${
          col.truncateText ? 'text-truncate' : ''
        } ${col.additionalClassName ? col.additionalClassName : ''}`}
        headerClassName={styles.fixedWidth}
      />
    );
  });

  const [contextOpen, setcontextOpen] = useState(false);
  /** Action button template */
  const actionBodyTemplate = (rowEvent: any) => {
    const toggleContextMenu = (e: any) => {
      menuRef.current?.toggle(e);
      setcontextOpen(!contextOpen);
    };
    /** Action button handler */
    const clickHandler = (e: React.MouseEvent) => {
      setSelectedRow(rowEvent);
      e.stopPropagation();
      toggleContextMenu(e);
    };
    return (
      <React.Fragment>
        <div
          onMouseOver={(e) => {
            if (selectedRow && selectedRow.id && selectedRow.id == rowEvent.id) {
              return;
            }
            if (contextOpen && selectedRow !== rowEvent) {
              toggleContextMenu(e);
              setSelectedRow(rowEvent);
            }
            setSelectedRow(rowEvent);
          }}
          onFocus={() => {
            // Setting selected row
          }}>
          <Button
            data-cy={'context-menu-button'}
            icon="pi-ellipsis-v"
            onClick={(e) => {
              clickHandler(e);
            }}
            size={ButtonSizes.small}
            variant={ButtonVariants.textonly}
          />
        </div>
      </React.Fragment>
    );
  };

  /** Template for table header */
  const header = (
    <>
      <div
        className={`d-flex justify-content-between ${
          headerBtnAlignment === 'SearchBottom' ? 'align-items-start' : 'align-items-center '
        }`}>
        {headerText && (
          <div>
            <Text
              size={TextSizes.large}
              bold={true}
              className={`${headerBtnAlignment === 'SearchBottom' ? 'mt6' : ''} text-2xl`}>
              {headerText}
            </Text>
          </div>
        )}
        <div className="d-flex flex-row">
          {headerBtnAlignment === 'Left' &&
            headerBtns &&
            headerBtns.map((btn) => {
              return btn;
            })}
        </div>
        <div className="d-flex flex-row">
          {headerBtnAlignment === 'Right' &&
            headerBtns &&
            headerBtns.map((btn) => {
              return btn;
            })}
          {headerDropDowns &&
            headerDropDowns.map((dropDown) => {
              return dropDown;
            })}
          <div
            className={`${
              headerBtnAlignment === 'SearchBottom'
                ? 'd-flex flex-row mt10 align-items-center justify-content-between ms-auto '
                : ''
            }`}>
            {headerBtnAlignment === 'SearchBottom' &&
              headerBtns &&
              headerBtns.map((btn) => {
                return btn;
              })}
          </div>
        </div>
      </div>
      <div className={`${headerBtnAlignment === 'SearchBottom' ? '' : ''}`}>
        <div className={`p-datatable-search ${headerBtnAlignment === 'Left' ? 'w-auto' : ''}`}>
          <Inplace
            active={active || globalFilter ? true : false}
            onToggle={(e) => setActive(e.value)}>
            <InplaceDisplay>
              <div data-cy={'search-placeholder'} className="p-datatable-search-inactive">
                <i className="pi pi-search"></i>
                <span className="text-decoration-underline">{searchText}</span>
              </div>
            </InplaceDisplay>
            <InplaceContent>
              <Input
                data-cy={'table-search-input'}
                variant="icon_left"
                icon="pi-search"
                onChange={(e) => setGlobalFilter(e.currentTarget.value)}
                onBlur={() => setActive(false)}
                value={globalFilter}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
            </InplaceContent>
          </Inplace>
        </div>
      </div>
    </>
  );

  // This is PrimeReact's event object for onChange, taken from source
  // (primereact/components/multiselect/Multiselect.d.ts)
  type columnToggleEvent = {
    originalEvent: Event;
    value: any;
    target: { name: string; id: string; value: any };
  };

  /** Runs on selection/deselection of columns */
  const onColumnToggle = (event: columnToggleEvent) => {
    const selectedColumns = event.value;

    const nonToggledColumns = columns.filter((c) => c.toggable === false);

    const orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol: { field: string }) => sCol.field === col.field)
    );

    nonToggledColumns.push(...orderedSelectedColumns);

    const reOrderedColumns = columns.filter((col) =>
      nonToggledColumns.some((sCol: { field: string }) => sCol.field === col.field)
    );

    if (reOrderedColumns.length === 0) {
      setTableData([]);
    } else {
      setTableData(tableData.length === 0 ? data : tableData);
    }
    setSelectedColumns(reOrderedColumns);
  };

  /** Left side of the paginator */
  const paginatorLeft = (
    <div className="d-flex align-items-center">
      <MultiSelect
        value={selectedColumns.filter((x) => x.toggable)}
        options={columns.filter((x) => x.toggable)}
        optionLabel="header"
        dataKey="header"
        onChange={onColumnToggle}
        placeholder={t('molecules.Table.NO_FIELDS_MESSAGE')}
      />
      <Button
        data-cy={'export-to-excel-button'}
        size={ButtonSizes.medium}
        variant={ButtonVariants.basic}
        label={excelBtntext}
        icon="pi-file-excel"
        onClick={() => exportToExcel(data, exportFileName)}
      />
    </div>
  );

  /**
   * Current page report
   *
   * In non-lazy mode, the total number shows
   * the number of records that pass the search criteria.
   * In lazy mode, number of records should be passed as props.
   */
  const pageReportComponent = (options: CurrentPageReportOptions) => {
    const rowsInCurrentPage =
      options.totalRecords === 0 ? options.totalRecords : options.last - options.first + 1;
    return (
      <span className="p-paginator-current">{`Showing ${rowsInCurrentPage} out of ${
        lazy ? tableRowCount : tableData.length
      }`}</span>
    );
  };

  /** Custom paginator template to support both lazy and non-lazy modes */
  const paginatorTemplate = {
    layout:
      'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
    CurrentPageReport: pageReportComponent
  };

  /** Row click handler */
  const rowClickHandler = (e: React.MouseEvent) => {
    if (enableRowClick && onRowClick) {
      onRowClick(e);
    }
  };

  /** Template to render expanded rows in a sub-table */
  const expandedRowsTemplate = (data: T) => {
    return (
      <div className="columncontent-w-expandtable">
        <DataTable value={expandableField ? data[expandableField] : null} emptyMessage={emptyState}>
          {expandedCols.map((col: Col<T>) => {
            return (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                className={col.truncateText ? 'text-truncate' : ''}
                body={col.bodyTemplate}
              />
            );
          })}
        </DataTable>
      </div>
    );
  };

  /** Empty State molecule rendering part*/
  const emptyState = (
    <div>
      <EmptyState
        textItems={
          emptyStateTexts.length === 0 || globalFilter !== ''
            ? [t('molecules.Table.tableEmptyMessage')]
            : emptyStateTexts
        }
      />
    </div>
  );

  return (
    <div>
      <div className="datatable-scroll-demo">
        <DataTable
          scrollable
          value={tableData}
          header={header}
          globalFilter={globalFilter}
          emptyMessage={emptyState}
          paginator
          first={offset}
          totalRecords={lazy ? tableRowCount : tableData.length}
          onPage={onPage}
          onSort={onSort}
          onFilter={onFilter}
          lazy={lazy}
          sortField={sortField}
          sortOrder={sortOrder}
          paginatorTemplate={paginatorTemplate}
          rows={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          paginatorLeft={paginatorLeft}
          selection={selectedRow}
          onSelectionChange={(e) => {
            setSelectedRow(e.value);
            setcontextOpen(false);
          }}
          selectionMode={selectionMode}
          dataKey={uniqueFieldName}
          onRowClick={(e) => {
            rowClickHandler(e.data.id || e.data[uniqueFieldName]);
          }}
          rowExpansionTemplate={expandable ? expandedRowsTemplate : undefined}
          onRowToggle={onRowToggle}
          expandedRows={expandedRows}>
          {selectionMode === 'checkbox' && (
            <Column selectionMode="multiple" headerStyle={{ width: '4em' }}></Column>
          )}
          {selectionMode === 'radioBtn' && (
            <Column selectionMode="single" headerStyle={{ width: '4em' }}></Column>
          )}

          {expandable && <Column expander className="column-w-expandbtn" />}
          {isContextMenu && (
            <Column
              className="p-col-w-actions"
              body={actionBodyTemplate}
              style={{ width: '60px' }}
            />
          )}
          {columnComponents}
        </DataTable>
      </div>
      {menuItem && (
        <VerticalMenu
          className={'data-table-overlay-menu'}
          popup
          refProp={menuRef}
          items={menuItem}
        />
      )}
    </div>
  );
};

export interface TableProps<T> {
  /** column names array */
  columns: Array<Col<T>>;
  /** Buttons needed in the header */
  headerBtns?: Array<JSX.Element>;
  /** searchbox placeholder text */
  searchPlaceholderText: string;
  /** export excel button text */
  excelBtntext: string;
  /** context menu visibility */
  isContextMenu?: boolean;
  /** contextmenu menu items */
  menuItem?: Array<MenuItem>;
  /** select a user row */
  selectedRow: T | undefined;
  /** set selected user row */
  setSelectedRow: (value: React.SetStateAction<T | undefined>) => void;
  /** Name of a unique field such as `id`. Required for select row behaviour. */
  uniqueFieldName?: string;
  /** Data to be displayed in the table */
  data?: T[];
  /** The number of total records */
  totalRecords: number;
  /** Field to sort by */
  sortField?: string;
  /** Order to sort by. 1 = ascending, -1 = descending */
  sortOrder?: number;
  /** Function to modify sort field */
  setSortField?: (arg: string) => void;
  /** Function to modify sort order */
  setSortOrder?: (arg: number) => void;
  /** Number of entries in a single page */
  pageSize?: number;
  /** Offset of the first item in the page */
  offset?: number;
  /** Function to modify page size */
  setPageSize?: (arg: number) => void;
  /** Function to modify offset */
  setOffset?: (arg: number) => void;
  /** row click enable */
  enableRowClick?: boolean;
  /** Page Name */
  exportFileName: string;
  /** Function to execute on row click. Applicable only if `enableRowClick` is true */
  onRowClick?: (e: React.MouseEvent) => void;
  /** Enables lazy-loading features. This makes it necessary to provide sorting and page change handlers as props. */
  lazy?: boolean;
  /**  Shows header text */
  headerText?: string;
  /** Whether this table contains expandable rows */
  expandable?: boolean;
  /** The name of the nested data field to expand. Must be an array of objects */
  expandableField?: string;
  /** Column information for the expanded rows */
  expandedCols?: Array<Col<T>>;
  /**Backend Search term */
  searchTerm?: string;
  /**Function to set search text */
  setSearchTerm?: (arg: string) => void;
  /** Table Selection mode */
  selectionMode?: string;
  /** Header Button Alignment */
  headerBtnAlignment?: 'Left' | 'Right' | 'SearchBottom';
  /** Text items array for empty state */
  emptyStateTexts?: string[];
  /** Dropdowns needed in the header */
  headerDropDowns?: Array<JSX.Element>;
}
export default Table;
