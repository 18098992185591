import { FormEvent, useRef } from 'react';
import styles from './Upload.module.scss';
import { API } from 'aws-amplify';
import axios from 'axios';
import { uploadImageUrlResolver } from '@gym-graphql/queries';
import placeholder from '@gym-assets/images/upload_placeholder.png';

const isValidImageExtention = (fileExtension: string): boolean => {
  switch (fileExtension.toLowerCase()) {
    case 'apng':
    case 'avif':
    case 'gif':
    case 'jpeg':
    case 'jpg':
    case 'jfif':
    case 'pjpeg':
    case 'pjp':
    case 'png':
    case 'svg':
    case 'webp':
    case 'bmp':
      return true;
    default:
      return false;
  }
};

const Upload = ({
  inputAriaLabel = '',
  imageAriaLabel = '',
  imageAlt = '',
  name,
  uploadHandler,
  setUploadState,
  previewImg = placeholder
}: UploadProps) => {
  const uploadedImage = useRef<HTMLImageElement>(null);
  const imageUploader = useRef<HTMLInputElement>(null);

  /**
   * This function is fired once an image is uploaded.
   * It creates the preview and sends the image to parent.
   */
  const handleImageUpload = async (e: FormEvent) => {
    setUploadState('INPROGRESS');

    const target = e.target as HTMLInputElement;
    const file = target.files && target.files[0];

    // creating the preview
    if (file) {
      if (!isValidImageExtention(file.name.split('.').pop() || '')) {
        setUploadState('ERROR');
        return;
      }

      const reader = new FileReader();
      const current = uploadedImage.current;
      if (current !== null) {
        reader.onload = (e) => {
          current.src = e.target?.result as string;
        };
        reader.readAsDataURL(file);
      }
    }

    const fileName = file?.name.replaceAll(' ', '');

    try {
      const response = (await API.graphql({
        query: uploadImageUrlResolver,
        variables: {
          imageName: fileName
        }
      })) as { data: { uploadImageUrlResolver: { uploadUrl: string; url: any } } };

      await axios.put(response.data.uploadImageUrlResolver.uploadUrl, file, {
        headers: new Headers({
          'Content-Type': 'image/*'
        })
      });

      setUploadState('SUCCESS');

      uploadHandler(response.data.uploadImageUrlResolver.url);
    } catch (err) {
      setUploadState('ERROR');
    }
  };

  return (
    <div className={styles.uploadWrapperDiv}>
      <input
        data-cy={'atom-image-upload-input'}
        aria-label={inputAriaLabel}
        name={name}
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={imageUploader}
      />
      <div className={styles.uploadPreviewDiv}>
        <img
          data-cy={'atom-image-upload-placeholder'}
          ref={uploadedImage}
          src={previewImg}
          alt={imageAlt}
          aria-label={imageAriaLabel}
        />
      </div>
      <button
        data-cy={'atom-image-upload-btn'}
        onClick={(e) => {
          e.preventDefault();
          imageUploader.current?.click();
        }}
        className={`fw-bold ${styles.uploadButton}`}>
        Change
      </button>
    </div>
  );
};

export interface UploadProps {
  /** Name of the internal <input> element, needed for forms. */
  name: string;
  /** Adding image aria-label. */
  imageAriaLabel?: string;
  /** Adding input aria-label. */
  inputAriaLabel?: string;
  /** Adding image alt. */
  imageAlt?: string;
  /** Callback function to receive the uploaded image */
  uploadHandler: (f: File | null) => void;
  /**
   * A callback to set the upload status.
   * `s` is one of the values: 'SUCCESS', 'INPROGRESS', 'ERROR'
   */
  setUploadState: (s: string) => void;
  /**
   * Image displayed as the preview.
   * Useful for places where no upload happens, but a preview
   * should be shown anyway. (Eg: Imported Gyms from Mindbody)
   */
  previewImg?: string;
}

export default Upload;
