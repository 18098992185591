import Text from '@gym-atoms/Text/Text';
import Logo from '@gym-assets/images/empty-state.svg';
import styles from './EmptyState.module.scss';

const EmptyState = ({ alt = 'empty state', textItems, ...props }: EmptyStateProps) => {
  return (
    <div className="text-center d-flex justify-content-center mb25">
      <div className="my-5">
        <div className="input-mb20 mb25">
          <img src={Logo} alt={alt} width={200} height={200} {...props} />
        </div>
        <div>
          {textItems.map((text, index) => (
            <Text key={index} className={`${styles.emptyStateText} my-2`}>
              {text}
            </Text>
          ))}
        </div>
      </div>
    </div>
  );
};

export interface EmptyStateProps {
  /** Alt-text for the image */
  alt?: string;
  /** Text items array for empty state */
  textItems: string[];
}

export default EmptyState;
