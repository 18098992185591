import { Dialog } from 'primereact/dialog';

export enum DialogBoxVariants {
  basic = 'dialog-basic',
  long = 'dialog-long',
  xl = 'dialog-xl'
}

const DialogBox = ({
  variant,
  dialogVisible,
  dialogClosable,
  onHideCallback,
  dialogHeader,
  dialogFooter,
  dialogDismissableMask,
  content
}: DialogBoxProps) => {
  const getDialogBox = (variantType: DialogBoxVariants) => {
    switch (variantType) {
      case DialogBoxVariants.basic:
        return (
          <>
            <Dialog
              visible={dialogVisible}
              onHide={onHideCallback}
              footer={dialogFooter}
              closable={dialogClosable}
              header={dialogHeader}
              dismissableMask={dialogDismissableMask}
              className={variant}>
              {content}
            </Dialog>
          </>
        );
      case DialogBoxVariants.long:
        return (
          <>
            <Dialog
              visible={dialogVisible}
              onHide={onHideCallback}
              footer={dialogFooter}
              closable={dialogClosable}
              header={dialogHeader}
              dismissableMask={dialogDismissableMask}
              className={variant}>
              {content}
            </Dialog>
          </>
        );
      case DialogBoxVariants.xl:
        return (
          <>
            <Dialog
              visible={dialogVisible}
              onHide={onHideCallback}
              footer={dialogFooter}
              closable={dialogClosable}
              header={dialogHeader}
              dismissableMask={dialogDismissableMask}
              className={variant}>
              {content}
            </Dialog>
          </>
        );
    }
  };

  return <>{getDialogBox(variant)}</>;
};

export interface DialogBoxProps {
  /** DialogBox variants e.g. basic, long */
  variant: DialogBoxVariants;
  /** DialogBox visibility */
  dialogVisible: boolean;
  /** Whether the DialogBox contains a close button or not */
  dialogClosable: boolean;
  /** Whether the DialogBox can be dismissed by clicking outside the box area*/
  dialogDismissableMask: boolean;
  /** DialogBox onHide callback */
  onHideCallback?: any;
  /** DialogBox header elements */
  dialogHeader: JSX.Element;
  /** DialogBox footer elements */
  dialogFooter?: JSX.Element;
  /** DialogBox content elements */
  content: JSX.Element;
}

export default DialogBox;
