import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import styles from './ReceivedInformation.module.scss';
import Avatar from '@gym-atoms/Avatar/Avatar';
import { acronym } from '@gym-particles/acronym';
import Image from '@gym-atoms/Image/Image';
import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import Checkbox, { CheckboxChangeEvent } from '@gym-atoms/Checkbox/Checkbox';
import {
  CurrentInformationType,
  MatchingRecords,
  ReceivedInformationType
} from '@gym-particles/types/Account';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { API } from 'aws-amplify';
import {
  getEventByDescription,
  getLastMemberAssignmentHistory,
  getMatchingRecords,
  getReceivedInformation
} from '@gym-graphql/queries';
import {
  AssignMemberEventInput,
  GetEventByDescriptionQuery,
  GetLastMemberAssignmentHistoryQuery,
  MapMatchingRecordsResponse,
  MatchingRecordsResponse,
  memberMappingInput,
  ReceivedInformation
} from '@gym-src/API';
import { assignMemberEvent, mapMatchingRecords } from '@gym-graphql/mutations';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import Loader from '@gym-atoms/Loader/Loader';
import { Events } from '@gym-particles/types/Events';
import Alert, { AlertType, createAlert } from '@gym-atoms/Alert/Alert';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';

const ReceivedInformationModalContent = (props: {
  info: CurrentInformationType;
  selectedRow?: MatchingRecords;
  setSelectedRow: (value: React.SetStateAction<MatchingRecords | undefined>) => void;
  receivedInfo?: ReceivedInformationType;
  setReceivedInfo: (value: React.SetStateAction<ReceivedInformationType | undefined>) => void;
}) => {
  const { t } = useTranslation();

  const [membershipSortField, setMembershipSortField] = useState('id');
  const [membershipSortOrder, setMembershipSortOrder] = useState(-1);
  const [membershipOffset, setMembershipOffset] = useState(0);
  const [membershipPageSize, setMembershipPageSize] = useState(10);
  const [matchingRecordsLoading, setMatchingRecordsLoading] = useState(true);
  const [receivedInfoLoading, setReceivedInfoLoading] = useState(true);

  const [memberData, setMemberData] = useState<MatchingRecords[]>([]);

  const getRecevivedData = async () => {
    setReceivedInfoLoading(true);
    try {
      const response = (API.graphql({
        query: getReceivedInformation,
        variables: {
          userId: props.info.id
        }
      }) as Promise<{
        data: { getReceivedInformation: ReceivedInformation[] };
      }>).then((e) => {
        e.data.getReceivedInformation.length != 0 &&
          props.setReceivedInfo({
            id: e.data.getReceivedInformation[0].id || 0,
            userPhone: e.data.getReceivedInformation[0].userPhone || '',
            firstName: e.data.getReceivedInformation[0].firstName || '',
            lastName: e.data.getReceivedInformation[0].lastName || '',
            email: e.data.getReceivedInformation[0].email || '',
            phone: e.data.getReceivedInformation[0].phone || '',
            address: e.data.getReceivedInformation[0].address || '',
            birthDate: e.data.getReceivedInformation[0].birthDate || '',
            inquiryMemberId: e.data.getReceivedInformation[0].inquiryMemberId || 0,
            siteId: e.data.getReceivedInformation[0].siteId || 0,
            siteLocationId: e.data.getReceivedInformation[0].siteLocationId || 0
          });

        e.data.getReceivedInformation.length != 0 &&
          props.info.id &&
          getMatchingRecordsFn(
            e.data.getReceivedInformation[0].email || '',
            e.data.getReceivedInformation[0].phone || '',
            e.data.getReceivedInformation[0].birthDate || '',
            e.data.getReceivedInformation[0].siteId || 0
          );

        setReceivedInfoLoading(false);
      });
    } catch (error) {
      // TODO: handle error scenario
      console.log(error);
    }
  };

  useEffect(() => {
    getRecevivedData();
  }, [props.info]);

  const getMatchingRecordsFn = (
    email?: string,
    phone?: string,
    birthDate?: string,
    siteId?: number
  ) => {
    setMatchingRecordsLoading(true);
    try {
      const response = (API.graphql({
        query: getMatchingRecords,
        variables: {
          email: email,
          phoneNumber: phone,
          birthDate: birthDate,
          siteId: siteId
        }
      }) as Promise<{
        data: { getMatchingRecords: MatchingRecordsResponse };
      }>).then((e) => {
        const memberData =
          e.data.getMatchingRecords.items?.map((e) => ({
            id: e?.id || 0,
            name: `${e?.firstName} ${e?.lastName}` || '',
            email: e?.email || '',
            mobile: e?.phone || '',
            birthdate: e?.birthDate || '',
            address: e?.address || '',
            providerId: e?.providerId || 0,
            uniqueId: e?.uniqueId || 0,
            userId: e?.userId || 0
          })) || [];

        setMatchingRecordsLoading(false);
        setMemberData(memberData);
      });
    } catch (error) {
      // TODO: handle error scenario
      console.log(error);
    }
  };

  const reveivedInfoTableProps: TableProps<MatchingRecords> = {
    exportFileName: t('VIEW_RECEIVED_INFORMATION_MODAL.EXPORT_EXCEL_FILE_NAME'),
    searchPlaceholderText: t('VIEW_RECEIVED_INFORMATION_MODAL.TABLE_SEARCH_PLACEHOLDER'),
    excelBtntext: t('VIEW_RECEIVED_INFORMATION_MODAL.EXPORT_EXCEL_BUTTON_LABEL'),
    headerText: t('VIEW_RECEIVED_INFORMATION_MODAL.TABLE_HEADING'),
    selectedRow: props.selectedRow,
    setSelectedRow: props.setSelectedRow,
    totalRecords: memberData.length || 0,
    lazy: false,
    setPageSize: setMembershipPageSize,
    setOffset: setMembershipOffset,
    pageSize: membershipPageSize,
    offset: membershipOffset,
    setSortField: setMembershipSortField,
    setSortOrder: setMembershipSortOrder,
    sortField: membershipSortField,
    sortOrder: membershipSortOrder,
    uniqueFieldName: 'id',
    data: memberData || [],
    columns: [
      {
        field: 'name',
        header: t('VIEW_RECEIVED_INFORMATION_MODAL.TABLE_COLUMN_NAME'),
        toggable: false,
        sortable: true
      },
      {
        field: 'email',
        header: t('VIEW_RECEIVED_INFORMATION_MODAL.TABLE_COLUMN_EMAIL'),
        toggable: true
      },
      {
        field: 'mobile',
        header: t('VIEW_RECEIVED_INFORMATION_MODAL.TABLE_COLUMN_PHONENUMBER'),
        toggable: true
      },
      {
        field: 'birthdate',
        header: t('VIEW_RECEIVED_INFORMATION_MODAL.TABLE_COLUMN_BIRTHDATE'),
        toggable: true
      },
      {
        field: 'address',
        header: t('VIEW_RECEIVED_INFORMATION_MODAL.TABLE_COLUMN_ADDRESS'),
        toggable: true
      }
    ],
    selectionMode: 'radioBtn'
  };

  return (
    <>
      <div className="mb25">
        <Text className={styles.ReceivedInformationModalGrayText}>
          {t('VIEW_RECEIVED_INFORMATION_MODAL.DETAIL_TEXT')}
        </Text>
      </div>
      <div className="col-md-8">
        <div className="row">
          <div className="col-md-6">
            <div className="mb10">
              <Text bold size={TextSizes.t16} className={styles.ReceivedInformationModalGrayText}>
                {t('VIEW_RECEIVED_INFORMATION_MODAL.ACCOUNT_DETAILS_HEADING')}
              </Text>
            </div>
            <div className="d-flex flex-row mb35 align-items-center">
              <div className="col-md-2">
                <div className="mt10">
                  {!props.info.imageUrl ? (
                    <Avatar
                      size="custom"
                      shape="circle"
                      label={acronym(props.info.imageUrl || '')}
                      width={30}
                      height={30}
                      fontSize="3em"
                      color="#e0f4fe"
                    />
                  ) : (
                    <div className={styles.imageCircle}>
                      <Image source={props.info.imageUrl} width={30} height={30} circle />
                    </div>
                  )}
                </div>
              </div>
              <div className="col">
                <Text bold className={styles.ReceivedInformationModalGrayText} size={TextSizes.t15}>
                  {`${props.info.currentFirstName} ${props.info.currentLastName}`}
                </Text>
                <Text bold className={styles.ReceivedInformationModalGrayText}>
                  {props.info.currentEmail}
                </Text>
                <Text bold className={styles.ReceivedInformationModalGrayText}>
                  {props.info.currentPhoneNumber}
                </Text>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb10">
              <Text bold size={TextSizes.t16} className={styles.ReceivedInformationModalGrayText}>
                {t('VIEW_RECEIVED_INFORMATION_MODAL.RECEIVED_INFORMATION_HEADING')}
              </Text>
            </div>
            <div className="d-flex flex-row mb35 align-items-center">
              {receivedInfoLoading ? (
                <div className="col">
                  <div className="mb10">
                    <Loader shape="rectangle" width="quarter" />
                  </div>
                  <div className="mb10">
                    <Loader shape="rectangle" width="quarter" />
                  </div>
                  <div className="mb10">
                    <Loader shape="rectangle" width="quarter" />
                  </div>
                </div>
              ) : (
                <div className="col">
                  <Text
                    bold
                    className={styles.ReceivedInformationModalGrayText}
                    size={TextSizes.t15}>
                    {`${props.receivedInfo?.firstName} ${props.receivedInfo?.lastName}`}
                  </Text>
                  <Text bold className={styles.ReceivedInformationModalGrayText}>
                    {props.receivedInfo?.email}
                  </Text>
                  <Text bold className={styles.ReceivedInformationModalGrayText}>
                    {props.receivedInfo?.phone}
                  </Text>
                  <Text bold className={styles.ReceivedInformationModalGrayText}>
                    {props.receivedInfo?.birthDate}
                  </Text>
                  <Text bold className={styles.ReceivedInformationModalGrayText}>
                    {props.receivedInfo?.address}
                  </Text>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mb25">
        {matchingRecordsLoading ? <Loader shape="table" /> : <Table {...reveivedInfoTableProps} />}
      </div>
    </>
  );
};

const ReceivedInformationModal = (props: ReceivedInformationModalProps) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<MatchingRecords>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [receivedInfo, setReceivedInfo] = useState<ReceivedInformationType>();
  const dispatch = useAppDispatch();
  const checkBoxHandleChange = (e: CheckboxChangeEvent) => {
    if (e.checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };
  const userId = useAppSelector((state) => state.user.userId);
  const [isLinking, setIsLinking] = useState(false);
  const [isEventAssigning, setIsEventAssigning] = useState(false);
  const alertRef = useRef<AlertType>(null);

  useEffect(() => {
    fetchGymChain();
  }, []);

  const fetchGymChain = async () => {
    receivedInfo && receivedInfo?.siteId && dispatch(getGymChainById(receivedInfo.siteId));
  };
  const providerId = useAppSelector(
    (state) => state.gymChain.items.find((g) => g.id === receivedInfo?.siteId)?.providerId
  );

  const linkMemberFn = async (member?: MatchingRecords, isChecked?: boolean) => {
    setIsLinking(true);
    if (receivedInfo?.inquiryMemberId === member?.id) {
      createAlert(alertRef, {
        content: t('VIEW_RECEIVED_INFORMATION_MODAL.ALERT_MEMBER_ALREADY_LINKED'),
        severity: 'error'
      });
      setIsLinking(false);
      return;
    }
    if (!providerId) {
      await fetchGymChain();
    }
    try {
      const memberInput: memberMappingInput = {
        providerId: providerId || 0,
        oldMemberId: member?.id || 0,
        newMemberId: receivedInfo?.inquiryMemberId || 0,
        phoneNumber:
          receivedInfo?.userPhone.charAt(0) === '+'
            ? receivedInfo?.userPhone.substring(3)
            : receivedInfo?.userPhone || '',
        isChecked: isChecked || false,
        uniqueId: member?.uniqueId || 0,
        oldUserId: member?.userId || 0,
        newUserId: receivedInfo?.id || 0,
        loggedInUserId: userId || 1,
        siteLocationId: receivedInfo?.siteLocationId
      };

      const response = await (API.graphql({
        query: mapMatchingRecords,
        variables: {
          input: memberInput
        }
      }) as Promise<{
        data: {
          mapMatchingRecords: MapMatchingRecordsResponse;
        };
      }>);
      if (response && !response.data.mapMatchingRecords.error)
        createAlert(alertRef, {
          content: t('VIEW_RECEIVED_INFORMATION_MODAL.ALERT_MEMBER_LINK_SUCCESS'),
          severity: 'success'
        });
      if (response && response.data.mapMatchingRecords.error)
        if (response.data.mapMatchingRecords.errorType === 'EXTERNAL.UPDATE_EXISTS') {
          createAlert(alertRef, {
            content: t('VIEW_RECEIVED_INFORMATION_MODAL.ALERT_PHONE_ALREADY_UPDATED'),
            severity: 'error'
          });
        } else {
          throw response.data.mapMatchingRecords.errorMsg;
        }
    } catch (error) {
      console.log(error);
      createAlert(alertRef, {
        content: t('VIEW_RECEIVED_INFORMATION_MODAL.ALERT_MEMBER_LINK_FAILURE'),
        severity: 'error'
      });
    } finally {
      setIsLinking(false);
    }
  };

  const submitButtonClickHandler = async () => {
    if (isChecked) {
      await linkMemberFn(selectedRow, true);
    } else {
      await linkMemberFn(selectedRow, false);
    }
  };

  const assignRequestedInfo = async () => {
    setIsEventAssigning(true);
    try {
      const eventResposne = await (API.graphql({
        query: getEventByDescription,
        variables: {
          description: Events.REQUEST_MORE_INFO
        }
      }) as Promise<{ data: GetEventByDescriptionQuery }>);

      if (!eventResposne || !eventResposne.data.getEventByDescription) {
        throw 'Event not found';
      }

      const assignHistoryResponse = await (API.graphql({
        query: getLastMemberAssignmentHistory,
        variables: {
          userId: props.receivedInformation.id,
          eventId: eventResposne.data.getEventByDescription.eventId
        }
      }) as Promise<{ data: GetLastMemberAssignmentHistoryQuery }>);

      const lastRecord = assignHistoryResponse.data.getLastMemberAssignmentHistory;

      const input: AssignMemberEventInput = {
        memberEventId: props.memberEventId || -1,
        assignedBy: userId,
        createdBy: userId,
        assignedUserId: lastRecord?.createdBy || -1,
        assignedRoleId: -1,
        lastModifiedBy: userId,
        memberAssignmentId: props.memberAssignmentId || -1
      };

      await (API.graphql({
        query: assignMemberEvent,
        variables: { input: input }
      }) as Promise<{ data: { assignMemberEvent: number } }>);
    } catch (e) {
      console.log(e);
    }
    props.modalOnHide();
    setIsEventAssigning(false);
  };

  const dialogFooter = (
    <div>
      <Button
        variant={ButtonVariants.textonly}
        label={t('COMMON.CANCEL')}
        icon={isEventAssigning ? 'pi-spinner pi-spin' : ''}
        onClick={() => assignRequestedInfo()}
      />
      <Button
        variant={ButtonVariants.basic}
        label={t('VIEW_RECEIVED_INFORMATION_MODAL.BTN_LINK')}
        icon={isLinking ? 'pi-spinner pi-spin' : ''}
        onClick={() => submitButtonClickHandler()}
        disabled={!selectedRow}
      />
    </div>
  );

  return (
    <Dialog
      modal
      dismissableMask
      footer={dialogFooter}
      visible={props.visible}
      onHide={props.modalOnHide}
      position="top"
      style={{ width: '75vw' }}
      header={t('VIEW_RECEIVED_INFORMATION_MODAL.MODAL_HEADING')}>
      <ReceivedInformationModalContent
        info={props.receivedInformation}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        receivedInfo={receivedInfo}
        setReceivedInfo={setReceivedInfo}
      />
      <div className="mb25">
        <Checkbox
          label={t('VIEW_RECEIVED_INFORMATION_MODAL.CHECKBOX_LABEL')}
          checked={isChecked}
          onChange={checkBoxHandleChange}
          value={'checked'}></Checkbox>
      </div>
      <Alert refProp={alertRef} />
    </Dialog>
  );
};

export interface ReceivedInformationModalProps {
  /** Controls the visibility of the modal */
  visible: boolean;
  /** Runs when modal is closed */
  modalOnHide: () => void;
  /** Account data to populate the modal */
  receivedInformation: CurrentInformationType;
  /* MemberEvent record id of the submitted custom inquiry */
  memberEventId?: number;
  /* MemberAssisgnmentHistory record id of the submitted inquiry */
  memberAssignmentId?: number;
}

export default ReceivedInformationModal;
