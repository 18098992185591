import { Menu } from 'primereact/menu';
import { MenuItem as PrimeMenuItem } from 'primereact/components/menuitem/MenuItem';
import { RefObject } from 'react';

const VerticalMenu = ({
  items,
  popup = false,
  refProp,
  className,
  ...props
}: VerticalMenuProps) => (
  <Menu className={className} model={items} ref={refProp} popup={popup} {...props} />
);

/** Each item in the menu should be of this type */
export type MenuItem = PrimeMenuItem;

/** This type can be used in useRef<T> and other places */
export type VerticalMenuType = Menu;

export interface VerticalMenuProps {
  /**
   * Array of items to show in the list. Items follow
   * the [MenuModel API](https://www.primefaces.org/primereact/showcase/#/menumodel),
   * which is also the exported MenuItem type.
   */
  items: Array<MenuItem>;
  /** Whether this is a popup menu or not */
  popup?: boolean;
  /** A ref object for toggling popup menus. Use the exported `VerticalMenuType` for ref's type */
  refProp?: RefObject<Menu>;
  /** classNames to pass on to elements */
  className?: string;
}

export default VerticalMenu;
