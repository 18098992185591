import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { AccessCategoryMapping } from '@gym-particles/types/AccessCategoryMapping';
import { SiteLocationAccessCategory } from '@gym-src/API';
import { getAccessCategories } from '@gym-graphql/queries';
import { removeSiteLocationAccessCategory } from '@gym-graphql/mutations';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isAccessCategoriesLoading: boolean;
  items: Array<{
    siteLocationId: number;
    items: AccessCategoryMapping[];
    totalRecords: number;
  }>;
} = {
  isAccessCategoriesLoading: false,
  items: []
};

type accessCategoryParamType = {
  siteLocationId: number;
};

export const fetchAccessCategories = createAsyncThunk(
  '/gym/fetchAccessCategories',
  async (params: accessCategoryParamType) => {
    const response = await (API.graphql({
      query: getAccessCategories,
      variables: {
        siteLocationId: params.siteLocationId
      }
    }) as Promise<{
      data: {
        getAccessCategories: {
          items: SiteLocationAccessCategory[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const deleteAccessCategory = createAsyncThunk(
  'AccessCategory/deleteAccessCategory',
  async (id: number) => {
    const response = await (API.graphql({
      query: removeSiteLocationAccessCategory,
      variables: {
        id: id
      }
    }) as Promise<{
      data: {
        removeSiteLocationAccessCategory: number;
      };
    }>);
    return response;
  }
);

export const AccessCategoriesSlice = createSlice({
  name: 'AccessCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccessCategories.fulfilled, (state, action) => {
      state.isAccessCategoriesLoading = false;
      if (action.payload.data.getAccessCategories.items === null) {
        return;
      }
      const items = action.payload.data.getAccessCategories.items.map(
        (e: SiteLocationAccessCategory): AccessCategoryMapping => {
          return {
            id: e.id || 0,
            siteLocationId: e.siteLocationId || 0,
            name: e.name || '',
            arxCategoryId: e.arxAccessCategoryId || '',
            gender: e.gender || '',
            membershipId: e.siteLocationMembershipId || -1,
            membershipName: e.membershipName || '',
            program: e.program || '',
            scheduleType: e.scheduleType || '',
            status: e.status || '',
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            createdBy: e.createdBy || 0,
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            displayStatus: e.status ? e.status.charAt(0).toUpperCase() + e.status.slice(1) : '',
            displayGender: e.gender
              ? e.gender.toLocaleLowerCase() === 'n/a'
                ? 'N/A'
                : e.gender.charAt(0).toUpperCase() + e.gender.slice(1)
              : '',
            schedulesOnly: e.schedulesOnly ? 'true' : 'false'
          };
        }
      );

      const totalRecords = action.payload.data.getAccessCategories.totalRecords.totalRecords;

      const fetchedAccessCategoriesInfo = {
        totalRecords: totalRecords,
        items: items,
        siteLocationId: action.meta.arg.siteLocationId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedAccessCategoriesInfo);
      } else {
        const update = state.items.find(
          (accessCategoryInfo) =>
            accessCategoryInfo.siteLocationId === fetchedAccessCategoriesInfo.siteLocationId
        );
        if (update) {
          const newState = state.items.map((accessCategoryInfo) => {
            if (accessCategoryInfo.siteLocationId === fetchedAccessCategoriesInfo.siteLocationId) {
              return fetchedAccessCategoriesInfo;
            } else {
              return accessCategoryInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedAccessCategoriesInfo);
        }
      }
    });

    builder.addCase(fetchAccessCategories.pending, (state, action) => {
      state.isAccessCategoriesLoading = true;
      return state;
    });

    builder.addCase(fetchAccessCategories.rejected, (state, action) => {
      state.isAccessCategoriesLoading = false;
      console.log('getAccessCategories', action);
      return state;
    });

    builder.addCase(deleteAccessCategory.fulfilled, (state, action) => {
      state.isAccessCategoriesLoading = false;
      const accessCategoryId = action.meta.arg;
      const filtered = state.items.map((accessCategoryObj) => {
        const filteredAccessCategories = accessCategoryObj.items.filter(
          (accessCategory) => accessCategory.id !== accessCategoryId
        );
        accessCategoryObj.items = filteredAccessCategories;
        accessCategoryObj.totalRecords = accessCategoryObj.totalRecords - 1;
        return accessCategoryObj;
      });
      state.items = filtered;
    });

    builder.addCase(deleteAccessCategory.pending, (state, action) => {
      state.isAccessCategoriesLoading = true;
      return state;
    });

    builder.addCase(deleteAccessCategory.rejected, (state, action) => {
      state.isAccessCategoriesLoading = false;
      console.error('deleteAccessCategory', action);
      return state;
    });
  }
});

export default AccessCategoriesSlice.reducer;
