import Table, { TableProps } from '@gym-molecules/Table/Table';
import { deleteAccessPoint, fetchAccessPoints } from '@gym-redux/slices/accessPointsSlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { useCallback, useEffect, useState } from 'react';
import Loader from '@gym-atoms/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { AccessPoints } from '@gym-particles/types/models';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Text from '@gym-atoms/Text/Text';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { inactivateBeacons } from '@gym-graphql/mutations';
import { API } from 'aws-amplify';
import { InactivateBeaconsInput } from '@gym-src/API';
import { UserRole } from '@gym-particles/types/User';

const AccessPointsList = (props: AccessPointsListProps) => {
  const isSodvin = props.providerId === 2;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<AccessPoints>();
  const [sortField, setSortField] = useState('createdDate');
  const [sortOrder, setSortOrder] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const isAccessPointLoading = useAppSelector((state) => state.accessPoint.isAccessPointLoading);
  const accessPointList = useAppSelector(
    (state) => state.accessPoint.items.find((g) => g.siteLocationId === props.gymId)?.items
  );
  const totalAccessPointCount =
    useAppSelector(
      (state) => state.accessPoint.items.find((g) => g.siteLocationId === props.gymId)?.totalRecords
    ) || 0;
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [changeBeaconStatusDialogVisible, setChangeBeaconStatusDialogVisible] = useState(false);
  const userId = useAppSelector((state) => state.user.userId);
  const deleteParams = {
    id: selectedRow?.id,
    lastModifiedBy: userId
  };
  const userRole = useAppSelector((state) => state.user.userRole);
  const beaconStatus = accessPointList?.every((a) => a.status === 'inactive')
    ? 'active'
    : 'inactive';

  const [isSwitching, setIsSwitching] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  useEffect(() => {
    dispatch(
      fetchAccessPoints({
        siteLocationId: props.gymId
      })
    );
  }, [props.gymId, sortField, sortOrder, offset, pageSize]);

  const templateTypeChangeHandler = useCallback(
    (state) => {
      props.templateType(state);
    },
    [props.templateType]
  );

  const templateAccessPointHandler = useCallback(
    (state) => {
      props.templateZone(state);
    },
    [props.templateZone]
  );

  const deleteDialogHandler = async () => {
    setIsDeleting(true);
    selectedRow && (await dispatch(deleteAccessPoint(deleteParams)));
    setIsDeleting(false);
    setDeleteDialogVisible(false);
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        <Button
          data-cy={'access-point-dialog-cancel-btn'}
          label={t('COMMON.CANCEL')}
          variant={ButtonVariants.textonly}
          onClick={() => setDeleteDialogVisible(false)}
        />
        <Button
          data-cy={'access-point-dialog-delete-btn'}
          label={t('COMMON.DELETE')}
          variant={ButtonVariants.danger}
          onClick={deleteDialogHandler}
          icon={isDeleting ? 'pi-spinner pi-spin' : ''}
        />
      </div>
    );
  };

  const beaconStatusHandler = async () => {
    const inactivateBeaconsInput: InactivateBeaconsInput = {
      siteLocationId: props.gymId,
      status: beaconStatus
    };
    try {
      setIsSwitching(true);
      const inactivateBeaconsResponse = await (API.graphql({
        query: inactivateBeacons,
        variables: {
          input: inactivateBeaconsInput
        }
      }) as Promise<{
        data: { inactivateBeacons: number };
      }>);
      setIsSwitching(false);
      if (inactivateBeaconsResponse.data.inactivateBeacons === 1) {
        dispatch(
          fetchAccessPoints({
            siteLocationId: props.gymId
          })
        );
      }
    } catch (e) {
      console.log('Inactivate Beacon error', e);
    }
    setChangeBeaconStatusDialogVisible(false);
  };

  const ChangeBeaconStatusDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.CANCEL')}
          variant={ButtonVariants.textonly}
          onClick={() => setChangeBeaconStatusDialogVisible(false)}
        />
        <Button
          label={beaconStatus === 'active' ? t('COMMON.ACTIVATE') : t('COMMON.INACTIVATE')}
          variant={ButtonVariants.danger}
          onClick={beaconStatusHandler}
          icon={isSwitching ? 'pi-spinner pi-spin' : ''}
        />
      </div>
    );
  };

  const tableItems = [
    {
      field: 'id',
      header: t('ACCESSPOINTS.TABLE_HEADER_DOOR_ID'),
      toggable: false,
      sortable: true
    },
    {
      field: 'doorName',
      header: t('ACCESSPOINTS.TABLE_HEADER_NAME'),
      toggable: true
    },
    {
      field: 'displayType',
      header: t('ACCESSPOINTS.TABLE_HEADER_TYPE'),
      toggable: true
    },
    {
      field: 'doorCaption',
      header: t('ACCESSPOINTS.TABLE_HEADER_CAPTION'),
      toggable: true
    },
    {
      field: 'doorARXId',
      header: t('ACCESSPOINTS.TABLE_HEADER_ARX_Reference_ID'),
      toggable: true,
      truncateText: true
    },
    {
      field: 'uniqueId',
      header: t('ACCESSPOINTS.TABLE_HEADER_UNIQUE_ID'),
      toggable: true
    },
    {
      field: 'displayBatteryLevel',
      header: t('ACCESSPOINTS.TABLE_HEADER_BATTERY_LEVEL'),
      toggable: true
    },
    {
      field: 'locationZoneName',
      header: t('ACCESSPOINTS.TABLE_HEADER_ZONE'),
      toggable: true
    },
    {
      field: 'displayStatus',
      header: t('ACCESSPOINTS.TABLE_HEADER_STATUS'),
      toggable: true,
      truncateText: true
    },
    {
      field: 'createdDate',
      header: t('ACCESSPOINTS.TABLE_HEADER_CREATED_DATE'),
      toggable: true,
      truncateText: true
    }
  ];

  if (isSodvin) {
    tableItems.push(
      {
        field: 'sodvinDoorId',
        header: t('ACCESSPOINTS.TABLE_HEADER_SODVIN_DOOR_ID'),
        toggable: true
      },
      {
        field: 'sodvinDoorSecret',
        header: t('ACCESSPOINTS.TABLE_HEADER_SODVIN_DOOR_SECRET'),
        toggable: true
      }
    );
  }

  const menuItem = [
    {
      label: t('COMMON.MODIFY'),
      command: () => {
        templateTypeChangeHandler(true);
        templateAccessPointHandler(selectedRow);
      }
    },
    {
      label: t('COMMON.DELETE'),
      command: () => {
        templateTypeChangeHandler(false);
        setDeleteDialogVisible(true);
      }
    }
  ];

  const tableProps: TableProps<AccessPoints> = {
    exportFileName: t('ACCESSPOINTS.EXPORT_EXCEL_FILE_NAME'),
    setPageSize: setPageSize,
    setOffset: setOffset,
    pageSize: pageSize,
    offset: offset,
    setSortField: setSortField,
    setSortOrder: setSortOrder,
    sortField: sortField,
    sortOrder: sortOrder,
    totalRecords: totalAccessPointCount,
    data: accessPointList,
    columns: tableItems,
    searchPlaceholderText: t('ACCESSPOINTS.SEARCH_PLACEHOLDER'),
    headerText: t('ACCESSPOINTS.HEADER_TEXT'),
    excelBtntext: t('ACCESSPOINTS.EXPORT_EXCEL_BUTTON_LABEL'),
    selectedRow: selectedRow,
    setSelectedRow: setSelectedRow,
    isContextMenu:
      userRole === UserRole.SYSTEM_ADMIN
        ? true
        : userRole === UserRole.GYM_USER
        ? true
        : userRole === UserRole.GYM_CHAIN_USER
        ? true
        : false,
    menuItem:
      userRole === UserRole.SYSTEM_ADMIN
        ? menuItem
        : userRole === UserRole.GYM_USER
        ? menuItem
        : userRole === UserRole.GYM_CHAIN_USER
        ? menuItem
        : [],
    headerBtns: [
      <div className="d-flex" key={1}>
        <Button
          data-cy={'access-point-table-btn-inactive-beacons'}
          variant={ButtonVariants.outline}
          label={
            beaconStatus === 'active'
              ? t('ACCESSPOINTS.HEADER_BUTTON_ACTIVATE_BEACONS')
              : t('ACCESSPOINTS.HEADER_BUTTON_INACTIVATE_BEACONS')
          }
          size={ButtonSizes.small}
          onClick={() => setChangeBeaconStatusDialogVisible(true)}
          disabled={accessPointList?.length === 0}
        />
      </div>
    ],
    headerBtnAlignment: 'Right',
    lazy: false,
    emptyStateTexts: [t('ACCESSPOINTS.EMPTY_STATE_TEXT')]
  };

  return (
    <div>
      <DialogBox
        dialogVisible={deleteDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setDeleteDialogVisible(false)}
        dialogFooter={<DeleteDialogFooter />}
        dialogClosable={false}
        dialogHeader={t('COMMON.DELETE')}
        content={
          <Text data-cy={'access-point-dialog-content'}>
            {t('ACCESSPOINTS.DELETE_MODAL_CONFIRM')}{' '}
            <span className="fw-bold">{selectedRow?.doorName}</span> ?
          </Text>
        }
      />
      <DialogBox
        dialogVisible={changeBeaconStatusDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setChangeBeaconStatusDialogVisible(false)}
        dialogFooter={<ChangeBeaconStatusDialogFooter />}
        dialogClosable={false}
        dialogHeader={beaconStatus === 'active' ? t('COMMON.ACTIVATE') : t('COMMON.INACTIVATE')}
        content={
          <Text>
            {beaconStatus === 'active'
              ? t('ACCESSPOINTS.ACTIVATE_MODAL_CONFIRM')
              : t('ACCESSPOINTS.INACTIVATE_MODAL_CONFIRM')}
          </Text>
        }
      />
      {isAccessPointLoading ? (
        <Loader shape="table" />
      ) : (
        accessPointList && <Table {...tableProps} />
      )}
    </div>
  );
};

export default AccessPointsList;

interface AccessPointsListProps {
  gymId: number;
  providerId: number | null | undefined;
  templateType: (e: boolean) => void;
  templateZone: (e: AccessPoints) => void;
}
