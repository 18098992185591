import {
  Calendar,
  Culture,
  DateLocalizer,
  Formats,
  momentLocalizer,
  stringOrDate
} from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Button from '@gym-atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import Text from '@gym-atoms/Text/Text';
import { useEffect, useState } from 'react';
import { ScheduleEvent } from '@gym-particles/types/GymSchedules';

export type scheduleType = 'available' | 'unavailable' | 'draft';
type eventType = {
  title: string;
  start: Date;
  end: Date;
  isAvailable?: scheduleType | undefined;
};
const Scheduler = ({
  minHour = 8,
  maxHour = 17,
  step = 15,
  minMinutes = 0,
  maxMinutes = 0,
  ...props
}: SchedulerProps) => {
  const localizer = momentLocalizer(moment);
  const { t } = useTranslation();
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [eventId, setEventId] = useState(0);

  const formats: Formats | undefined = {
    dayFormat: (
      date: Date,
      culture: Culture | undefined,
      localizer: DateLocalizer | undefined
    ): string => (localizer ? localizer.format(date, 'dddd', culture || '') : ''),
    timeGutterFormat: (
      date: Date,
      culture: Culture | undefined,
      localizer: DateLocalizer | undefined
    ): string => (localizer ? localizer.format(date, 'HH:mm', culture || '') : ''),
    eventTimeRangeFormat: (
      range: { start: Date; end: Date },
      culture: Culture | undefined,
      localizer: DateLocalizer | undefined
    ): string =>
      localizer
        ? `${localizer.format(range.start, 'HH:mm', culture || '')}-${localizer.format(
            range.end,
            'HH:mm',
            culture || ''
          )}`
        : ''
  };

  useEffect(() => {
    const eventsList: ScheduleEvent[] = props.events.map((val) => ({
      title: val.title,
      start: val.start,
      end: val.end,
      isAvailable: val.isAvailable
    }));
  }, []);

  const minTime = new Date();
  minTime.setHours(minHour, minMinutes);
  const maxTime = new Date();
  maxTime.setHours(maxHour, maxMinutes);

  const validateOverlap = (startTime: stringOrDate, endTime: stringOrDate): boolean => {
    return props.events.some((x) => {
      return (
        moment(startTime).isBetween(x.start, x.end) ||
        moment(endTime).isBetween(x.start, x.end) ||
        moment(x.start).isBetween(startTime, endTime) ||
        moment(x.end).isBetween(startTime, endTime) ||
        moment(startTime).isSame(x.start)
      );
    });
  };

  const eventMapping = (startTime: stringOrDate, endTime: stringOrDate) => {
    const newEvent = {
      title: '',
      start: new Date(startTime),
      end: new Date(endTime)
    };

    validateOverlap(startTime, endTime) ? undefined : props.setEvents([...props.events, newEvent]);
  };

  const colorMapping = (eventType: scheduleType | undefined) => {
    if (eventType === 'available') {
      return '#2ECC71';
    } else if (eventType === 'unavailable') {
      return '#ED8936';
    } else if (eventType === 'draft') {
      return '#58C0F9';
    }
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={confirmDialogVisible}
          onHideCallback={() => setConfirmDialogVisible(false)}
          dialogHeader={t('SCHEDULAR_ATOM.DIALOG_HEADER')}
          dialogFooter={
            <>
              <Button label={t('COMMON.OK')} onClick={deleteEvent} />
              <Button
                label={t('COMMON.CANCEL')}
                onClick={() => {
                  setConfirmDialogVisible(false);
                }}
              />
            </>
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t('SCHEDULAR_ATOM.DIALOG_BODY')}</Text>}
        />
      </div>
    );
  };

  const deleteEvent = () => {
    props.events.splice(eventId, 1);
    setConfirmDialogVisible(false);
    const eventsList: ScheduleEvent[] = props.events.map((val) => ({
      title: val.title,
      start: val.start,
      end: val.end,
      isAvailable: val.isAvailable
    }));
    props.setEvents(eventsList);
  };

  const removeSchedule = (event: number) => {
    setConfirmDialogVisible(true);
    setEventId(event);
  };

  /* handle the scroll which occurs when dragging the slot to select the time */
  const mouseMoveHandler = (e: any) => {
    const height = window.innerHeight;
    const pos = e.clientY;

    if (document.getElementsByClassName('rbc-slot-selecting').length !== 0) {
      if (pos > height - 30) {
        window.scrollBy(0, 20);
      }
      if (pos < 30) {
        window.scrollBy(0, -20);
      }
    }
  };

  return (
    <div onMouseMove={(e) => mouseMoveHandler(e)}>
      <Calendar
        toolbar={false}
        localizer={localizer}
        events={props.events}
        startAccessor="start"
        endAccessor="end"
        views={['week']}
        defaultView={'week'}
        formats={formats}
        showMultiDayTimes={true}
        min={minTime}
        max={maxTime}
        selectable={true}
        defaultDate={moment().toDate()}
        onSelectSlot={(slotInfo) => eventMapping(slotInfo.start, slotInfo.end)}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: colorMapping(event.isAvailable)
          }
        })}
        step={step}
        dayLayoutAlgorithm="no-overlap"
        onSelectEvent={(event) => removeSchedule(props.events.indexOf(event))}
      />
      <ActionFailedDialog />
    </div>
  );
};

export interface SchedulerProps {
  /** selected event */
  events: eventType[];
  /** function to set events */
  setEvents: (e: eventType[]) => void;
  /** scheduler starting time hour */
  minHour?: number;
  /** scheduler ending time hour */
  maxHour?: number;
  /** minimum slot range */
  step?: number;
  /* scheduler starting time minutes  */
  minMinutes?: number;
  /* scheduler ending time minutes */
  maxMinutes?: number;
}

export default Scheduler;
