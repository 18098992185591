import { useTranslation } from 'react-i18next';
import { DaySchedule, RequestDaySchedules } from '@gym-particles/types/DaySchedules';
import ManageDaySchedulesTemplate from '@gym-templates/ManageDaySchedules/ManageDaySchedulesTemplate';

const ManageDaySchedulesForm = (props: ManageDaySchedulesInfoTemplateProps) => {
  const { t } = useTranslation();
  const { type, dayScheduleData, requestDaySchedule, templateType } = props;

  return (
    <ManageDaySchedulesTemplate
      type={type}
      heading={t(`${type === 'new' ? 'ADD_NEW' : 'MODIFY'}_DAY_SCHEDULE.PAGE_TITLE`)}
      daySchedules={dayScheduleData}
      requestDaySchedule={requestDaySchedule}
      templateType={templateType}
    />
  );
};

export default ManageDaySchedulesForm;

type ManageDaySchedulesInfoTemplateType = 'new' | 'modify';

export interface ManageDaySchedulesInfoTemplateProps {
  type: ManageDaySchedulesInfoTemplateType;
  dayScheduleData?: DaySchedule;
  requestDaySchedule?: RequestDaySchedules;
  templateType?: (e: boolean) => void;
}
