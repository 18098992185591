import Text, { TextSizes } from '@gym-atoms/Text/Text';
import Avatar from '@gym-atoms/Avatar/Avatar';
import { Dialog } from 'primereact/dialog';

import styles from './AccessCardIssueModal.module.scss';
import Dropdown, { DropdownChangeEvent, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Input from '@gym-atoms/Input/Input';
import { MemberAccessCard } from '@gym-particles/types/Member';
import {
  addNewAccessCardHistory,
  deactivateAccessCardForSiteLocation,
  updateAccessCardHistory,
  updateAccessCardOfPerson,
  updateMemberFlag
} from '@gym-graphql/mutations';
import {
  AddNewAccessCardHistoryMutation,
  AddNewAccessCardHistoryMutationVariables,
  DeactivateAccessCardForSiteLocationMutation,
  DeactivateAccessCardForSiteLocationMutationVariables,
  GetAccessCardHistoryQuery,
  GetAccessCardHistoryQueryVariables,
  GetAccessCardQueryVariables,
  UpdateAccessCardHistoryMutation,
  UpdateAccessCardHistoryMutationVariables,
  UpdateAccessCardOfPersonMutationVariables
} from '@gym-src/API';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AccessPoints } from '@gym-particles/types/AccessPoints';
import { getAccessCard, getAccessCardHistory } from '@gym-graphql/queries';
import { useAppSelector } from '@gym-redux/store';
import { useParams } from 'react-router';

dayjs.extend(utc);

const AccessCardIssueModal = ({
  visible,
  accessPoints,
  onHideCallBack,
  member
}: AccessCardIssueModalProps) => {
  const { t } = useTranslation();
  const { gymId } = useParams<{ chainId: string; gymId: string }>();
  const accesspointsDropdown: SelectItem[] = [];

  accessPoints &&
    accessPoints.map((accesspoint) =>
      accesspointsDropdown.push({
        label: accesspoint.doorName,
        value: accesspoint.id
      })
    );

  const cardReadTime = 30;
  const initialSelected = accesspointsDropdown.length !== 0 ? accesspointsDropdown[0].value : '';
  const [selected, setselected] = useState(initialSelected);
  const [isScanning, setIsScanning] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [card, setCard] = useState<{ cardId: string; cardType: string } | undefined>();
  const [currentDate, setCurrentDate] = useState<Date>();
  const [dropdownError, setDropdownError] = useState<boolean>(false);
  const currentUser = useAppSelector((state) => state.user.userId);
  const [errorMessage, seterrorMessage] = useState<string>('');
  const [otherError, setOtherError] = useState<boolean>(false);
  const [isIssuingCard, setIsIssuingCard] = useState<boolean>(false);
  const [existingUserName, setExistingUserName] = useState<string>('');

  const CardHeader = (
    <>
      <Text size={TextSizes.large} bold className="text-2xl">
        {t('ACCESS_CARD_ISSUE.MODAL_TITLE')}
      </Text>
      <div className="d-flex  mt5 w-100">
        <div className="mr15">
          <Avatar image={member.imageUrl} size={'default'} shape={'circle'} />
        </div>
        <div className="d-flex mt7">
          <Text size={TextSizes.t14} bold>
            {`${member.firstName} ${member.lastName}`}
          </Text>
          <p className="ml5 mr5">-</p>
          <Text size={TextSizes.t14}>
            {member.hasAccessCard
              ? t('ACCESS_CARD_ISSUE.STATUS_HAVE_ACCESS_CARD')
              : t('ACCESS_CARD_ISSUE.STATUS_NO_PREVIOUS_ACCESS_CARD')}
          </Text>
        </div>
      </div>
    </>
  );

  const accessPointChangeHandler = (e: DropdownChangeEvent) => {
    if (!e.value) {
      setselected(initialSelected);
      return;
    }
    setselected(e.value);
  };

  const scanHandler = async () => {
    setOtherError(false);
    setIsScanning(true);
    setInputValue('');
    seterrorMessage('');
    setExistingUserName('');

    if (!selected) {
      setDropdownError(true);
      setIsScanning(false);
      return;
    } else {
      setDropdownError(false);
    }

    const date = new Date();
    setCurrentDate(date);
    const endTime = dayjs(date).utc().valueOf();
    const startTime = dayjs(date).subtract(cardReadTime, 'seconds').utc().valueOf();

    if (accessPoints && selected) {
      const doorArxId =
        accessPoints.find((accessPoint) => accessPoint.id === +selected)?.doorARXId || '';

      const data: GetAccessCardQueryVariables = {
        doorId: doorArxId,
        endTime: endTime.toString(),
        startTime: startTime.toString(),
        siteLocationId: +gymId
      };
      await readAccessCard(data);
    }

    setIsScanning(false);
  };

  const readAccessCard = async (input: GetAccessCardQueryVariables) => {
    try {
      const result = await (API.graphql({
        query: getAccessCard,
        variables: input
      }) as Promise<{
        data: {
          getAccessCard: {
            error?: boolean | null;
            errorType?: string | null;
            errorMsg?: string | null;
            cardId?: number | null;
            cardType?: string | null;
          };
        };
      }>);
      const resultData = result.data.getAccessCard;
      console.log({ resultData });
      console.log(!resultData.error);
      if (!resultData.error) {
        setCard({
          cardId: resultData.cardId ? resultData.cardId.toString() : '',
          cardType: resultData.cardType ? resultData.cardType : ''
        });
        setSubmitDisabled(false);
        setInputValue(resultData.cardId ? resultData.cardId.toString() : '');
      } else {
        if (resultData.errorType === 'sameGymMember') {
          setExistingUserName(
            `${t('ACCESS_CARD_ISSUE.PREVIOUS_ASSIGNED_USER')} ${resultData.errorMsg}. ${t(
              'ACCESS_CARD_ISSUE.OTHER_ERROR'
            )}`
          );
        } else if (resultData.errorType === 'differentGymMember') {
          setExistingUserName(
            `${t('ACCESS_CARD_ISSUE.PREVIOUS_ASSIGNED_USER_IN_ANOTHER_GYM')} ${t(
              'ACCESS_CARD_ISSUE.OTHER_ERROR'
            )}`
          );
        } else {
          seterrorMessage(t('ACCESS_CARD_ISSUE.SCAN_ERROR_MESSAGE'));
          setExistingUserName('');
        }

        setSubmitDisabled(true);
      }
    } catch (e) {
      // console.log(e)
    }
  };

  const submitHandler = async () => {
    setOtherError(false);
    setIsIssuingCard(true);
    try {
      if (card) {
        const existingCards = await getExistingCards();
        if (member.arxReferenceId === undefined) {
          seterrorMessage(t('ACCESS_CARD_ISSUE.MEMBER_NOT_SYNCED_TO_ARX_ERROR_MESSAGE'));
          throw 'Member is not synced to card access server';
        } else if (existingCards && existingCards?.length > 0) {
          /* If we have existing cards for the member for the site location,
        we remove them from both ARX and GymAxess */
          await deactivateCardsFromArx(existingCards);
          await deactivateExistingCards();
        }

        if (await addNewCardTODatabase()) {
          issueCard(member.arxReferenceId || '', card.cardType, card.cardId);
        } else {
          throw 'Unable to issue the new card';
        }
      } else {
        throw 'No cards';
      }
    } catch (e) {
      console.error(e);
      setOtherError(true);
    } finally {
      setIsIssuingCard(false);
    }
  };

  const deactivateExistingCards = async () => {
    const requestData: DeactivateAccessCardForSiteLocationMutationVariables = {
      memberId: member.memberId,
      siteLocationId: +gymId,
      lastModifiedBy: +currentUser,
      deactivatedDateTime: dayjs(new Date()).utc().format('YYYY-MM-DD HH:mm:ss')
    };
    try {
      await (API.graphql({
        query: deactivateAccessCardForSiteLocation,
        variables: requestData
      }) as Promise<{
        data: DeactivateAccessCardForSiteLocationMutation;
      }>);
    } catch (e) {
      // console.log(e);
    }
  };

  /* Fetching existing cards for the member for the site location. */
  const getExistingCards = async () => {
    const requestData: GetAccessCardHistoryQueryVariables = {
      memberId: member.memberId,
      siteLocationId: +gymId
    };
    try {
      const response = await (API.graphql({
        query: getAccessCardHistory,
        variables: requestData
      }) as Promise<{
        data: GetAccessCardHistoryQuery;
      }>);
      const cardHistory = response.data.getAccessCardHistory;
      return cardHistory?.map((c) => {
        return {
          cardId: c?.accessCardUID || '',
          cardFormat: c?.accessCardFormatName || ''
        };
      });
    } catch (e) {
      console.error(e);
    }
  };

  /* Deactivating cards from ARX and then deactivate from GymAxess database */
  const deactivateCardsFromArx = async (cards: { cardId: string; cardFormat: string }[]) => {
    const promises: Promise<{
      data: {
        error?: boolean | null;
        errorType?: string | null;
        errorMsg?: string | null;
        statusCode?: string | null;
        statusMessage?: string | null;
      };
    }>[] = [];
    cards.forEach((card) => {
      const data: UpdateAccessCardOfPersonMutationVariables = {
        input: {
          arxPersonId: member.arxReferenceId || '',
          cardFormat: card.cardFormat,
          cardNumber: card.cardId,
          isDeleted: true
        }
      };
      promises.push(
        API.graphql({
          query: updateAccessCardOfPerson,
          variables: data
        }) as Promise<{
          data: {
            error?: boolean | null;
            errorType?: string | null;
            errorMsg?: string | null;
            statusCode?: string | null;
            statusMessage?: string | null;
          };
        }>
      );
    });
    await Promise.all(promises);
  };

  const addNewCardTODatabase = async () => {
    const data: AddNewAccessCardHistoryMutationVariables = {
      input: {
        accessCardUID: card?.cardId || '',
        userId: member.userId || -1,
        accessCardFormatName: card?.cardType || '',
        memberId: member.memberId,
        integrationCardId: '',
        createdBy: currentUser,
        siteLocationId: +gymId
      }
    };

    try {
      const result = await (API.graphql({
        query: addNewAccessCardHistory,
        variables: data
      }) as Promise<{
        data: AddNewAccessCardHistoryMutation;
      }>);

      if (result.data.addNewAccessCardHistory?.existingCardsCount === 0) {
        setOtherError(false);
        return true;
      } else {
        setOtherError(true);
      }
    } catch (e) {
      setOtherError(true);
    }
    return false;
  };

  const issueCard = async (personArxId: string, cardFormat: string, cardNumber: string) => {
    const data: UpdateAccessCardOfPersonMutationVariables = {
      input: {
        arxPersonId: personArxId,
        cardFormat: cardFormat,
        cardNumber: cardNumber,
        isDeleted: false
      }
    };

    try {
      const result = await (API.graphql({
        query: updateAccessCardOfPerson,
        variables: data
      }) as Promise<{
        data: {
          updateAccessCardOfPerson: {
            error?: boolean | null;
            errorType?: string | null;
            errorMsg?: string | null;
            statusCode?: string | null;
            statusMessage?: string | null;
          };
        };
      }>);

      const response = result.data.updateAccessCardOfPerson;

      if (response && response.error) {
        setOtherError(true);
        deactivateCardFromDatabase(cardNumber);
      } else {
        setOtherError(false);
        hideModal();
        updateMemberFlagYellowBox();
      }
    } catch (e) {
      console.log(e);
      deactivateCardFromDatabase(cardNumber);
      setOtherError(true);
    }
  };

  const updateMemberFlagYellowBox = async () => {
    await (API.graphql({
      query: updateMemberFlag,
      variables: {
        memberId: member.memberId,
        flag: 1
      }
    }) as Promise<{
      data: { updateMemberFlag: number };
    }>);
  };

  /* since card is added to database prior to issuing, 
    if issuing fails it should be deactivated from database */
  const deactivateCardFromDatabase = async (cardId: string) => {
    const data: UpdateAccessCardHistoryMutationVariables = {
      cardId: cardId,
      lastModifiedBy: currentUser,
      deactivatedDateTime: dayjs(new Date()).utc().format('YYYY-MM-DD HH:mm:ss')
    };

    try {
      await (API.graphql({
        query: updateAccessCardHistory,
        variables: data
      }) as Promise<{
        data: UpdateAccessCardHistoryMutation;
      }>);
    } catch (e) {
      console.log(e);
    }
  };

  const cardContent = (
    <>
      {existingUserName && (
        <div className="mb25 p-error">
          <Text size={TextSizes.t15}>{`${existingUserName}`}</Text>
        </div>
      )}

      <>
        <label className="fw-bold">{t('ACCESS_CARD_ISSUE.DROPDOWN_LABEL')}</label>

        <Dropdown
          onChange={accessPointChangeHandler}
          value={selected}
          options={accesspointsDropdown}
          error={dropdownError}
          placeholder={t('ACCESS_CARD_ISSUE.DROPDOWN_LABEL')}
        />
        <div className="mt10">
          {dropdownError && (
            <p className="p-error mb15">{t('ACCESS_CARD_ISSUE.DROPDOWN_ERROR_TEXT')}</p>
          )}
        </div>
        <div className="d-flex justify-content-between  mt15">
          <div className="w-75">
            <Input
              label={t('ACCESS_CARD_ISSUE.SCAN_INPUT_LABEL')}
              value={inputValue}
              variant={'basic'}
              disabled={true}
              error={errorMessage.length !== 0}
              errorMessage={
                errorMessage == t('ACCESS_CARD_ISSUE.MEMBER_NOT_SYNCED_TO_ARX_ERROR_MESSAGE')
                  ? ''
                  : errorMessage
              }
            />
          </div>
          <div className="ml10 mt30">
            <Button
              label={t('ACCESS_CARD_ISSUE.SCAN_BUTTON_LABEL')}
              icon={isScanning ? 'pi pi-spin pi-spinner' : 'pi-search'}
              onClick={() => scanHandler()}
              variant={ButtonVariants.basic}
              size={ButtonSizes.large}
              disabled={isScanning}
            />
          </div>
        </div>
        <Text size={TextSizes.t15}>{t('ACCESS_CARD_ISSUE.DESCRIPTION')}</Text>
        {otherError && (
          <Text className="p-error">
            {errorMessage == t('ACCESS_CARD_ISSUE.MEMBER_NOT_SYNCED_TO_ARX_ERROR_MESSAGE')
              ? t('ACCESS_CARD_ISSUE.MEMBER_NOT_SYNCED_TO_ARX_ERROR_MESSAGE')
              : t('ACCESS_CARD_ISSUE.OTHER_ERROR')}
          </Text>
        )}
      </>
    </>
  );
  const footer = (
    <>
      <div>
        <Button
          label={t('ACCESS_CARD_ISSUE.CANCEL_BUTTON_LABEL')}
          onClick={() => hideModal()}
          variant={ButtonVariants.textonly}
          size={ButtonSizes.large}
        />

        <Button
          label={t('ACCESS_CARD_ISSUE.ADD_NEW_CARD_BUTTON_LABEL')}
          icon={isIssuingCard ? 'pi pi-spin pi-spinner' : ''}
          onClick={() => submitHandler()}
          variant={ButtonVariants.basic}
          size={ButtonSizes.large}
          disabled={submitDisabled}
        />
      </div>
    </>
  );

  const hideModal = () => {
    setSubmitDisabled(true);
    setInputValue('');
    setCard(undefined);
    seterrorMessage('');
    setIsScanning(false);
    setOtherError(false);
    setIsIssuingCard(false);
    setExistingUserName('');
    onHideCallBack();
  };

  return (
    <Dialog
      onHide={() => hideModal()}
      visible={visible}
      closable={true}
      dismissableMask={false}
      header={CardHeader}
      className={` ${styles.dialogBoxSize}`}
      footer={footer}>
      {cardContent}
    </Dialog>
  );
};
export default AccessCardIssueModal;

export interface AccessCardIssueModalProps {
  /* component visibility */
  visible: boolean;
  /* dropdown menu options */
  accessPoints?: AccessPoints[];
  /* action to perform on clicking close button & cancel button */
  onHideCallBack: () => void;
  /* the member to issue the access card */
  member: MemberAccessCard;
}
