import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { LocationOpeningHourWithDay } from '@gym-src/API';
import { Holiday, PaginatedQueryParams } from '@gym-particles/types/models';
import { getAllAvailableHolidays, getAvailableHolidays } from '@gym-graphql/queries';
import { deleteLocationOpeningHours as deleteLocationOpeningHoursMutation } from '@gym-graphql/mutations';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isHolidaysLoading: boolean;
  items: Array<{
    locationId: number;
    items: Holiday[];
    totalRecords: number;
  }>;
} = {
  isHolidaysLoading: false,
  items: []
};

type paramType = {
  locationId: number;
  pagination: PaginatedQueryParams;
};

type allHolidaysParamType = {
  locationId: number;
};

export const fetchHolidays = createAsyncThunk('/gym/fetchHolidays', async (params: paramType) => {
  const response = await (API.graphql({
    query: getAvailableHolidays,
    variables: {
      locationId: params.locationId,
      offset: params.pagination.offset,
      pageSize: params.pagination.pageSize,
      sortField: params.pagination.sortField,
      sortOrder: params.pagination.sortOrder === 1 ? 'ASC' : 'DESC'
    }
  }) as Promise<{
    data: {
      getAvailableHolidays: {
        items: LocationOpeningHourWithDay[];
        totalRecords: { totalRecords: number };
      };
    };
  }>);
  return response;
});

export const fetchAllHolidays = createAsyncThunk(
  '/gym/fetchAllHolidays',
  async (params: allHolidaysParamType) => {
    const response = await (API.graphql({
      query: getAllAvailableHolidays,
      variables: {
        locationId: params.locationId
      }
    }) as Promise<{
      data: {
        getAllAvailableHolidays: {
          items: LocationOpeningHourWithDay[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const deleteOpeningHour = createAsyncThunk(
  'LocationOpeningHour/deleteOpeningHour',
  async (id: number) => {
    const response = await (API.graphql({
      query: deleteLocationOpeningHoursMutation,
      variables: {
        id: id
      }
    }) as Promise<{
      data: { deleteOpeningHour: LocationOpeningHourWithDay };
    }>);
    return response;
  }
);

export const HolidaysSlice = createSlice({
  name: 'Holiday',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHolidays.fulfilled, (state, action) => {
      state.isHolidaysLoading = false;
      if (!action.payload.data.getAvailableHolidays.items) {
        return;
      }

      const items = action.payload.data.getAvailableHolidays.items.map(
        (e: LocationOpeningHourWithDay): Holiday => {
          return {
            id: e.id || 0,
            locationId: e.locationId || 0,
            name: e.name || '',
            isHoliday: e.isHoliday || false,
            isGeneral: e.isGeneral || false,
            validFromDate: e.validFromDate
              ? dayjs(e.validFromDate || '').format('DD MMM YYYY')
              : '',
            validToDate: e.validToDate ? dayjs(e.validToDate || '').format('DD MMM YYYY') : '',
            validFromDateTime: e.validFromDateTime
              ? dayjs(e.validFromDateTime || '').format('DD MMM YYYY HH:mm:ss')
              : '',
            validToDateTime: e.validToDateTime
              ? dayjs(e.validToDateTime || '').format('DD MMM YYYY HH:mm:ss')
              : '',
            createdBy: e.createdBy || 0,
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            status: e.status || '',
            dayOfWeek: e.dayOfWeek || '',
            displayStatus: e.status ? e.status.charAt(0).toUpperCase() + e.status.slice(1) : ''
          };
        }
      );
      const totalRecords = action.payload.data.getAvailableHolidays.totalRecords.totalRecords;

      const fetchedHolidaysInfo = {
        totalRecords: totalRecords,
        items: items,
        locationId: action.meta.arg.locationId
      };
      if (state.items.length === 0) {
        state.items.push(fetchedHolidaysInfo);
      } else {
        // Check if we are updating an already fetched or fetching new one
        const update = state.items.find(
          (HolidaysInfo) => HolidaysInfo.locationId === fetchedHolidaysInfo.locationId
        );
        if (update) {
          const newState = state.items.map((HolidaysInfo) => {
            if (HolidaysInfo.locationId === fetchedHolidaysInfo.locationId) {
              return fetchedHolidaysInfo;
            } else {
              return HolidaysInfo;
            }
          });
          Object.assign(state.items, newState);
        } else {
          state.items.push(fetchedHolidaysInfo);
        }
      }
    });

    builder.addCase(fetchHolidays.pending, (state, action) => {
      state.isHolidaysLoading = true;
      return state;
    });
    builder.addCase(deleteOpeningHour.fulfilled, (state, action) => {
      state.isHolidaysLoading = false;
      const holidayId = action.meta.arg;
      const filtered = state.items.map((holidayObj) => {
        const filteredGym = holidayObj.items.filter((holiday) => holiday.id !== holidayId);
        holidayObj.items = filteredGym;
        holidayObj.totalRecords = holidayObj.totalRecords - 1;
        return holidayObj;
      });
      state.items = filtered;
    });
    builder.addCase(deleteOpeningHour.pending, (state, action) => {
      state.isHolidaysLoading = true;
      return state;
    });
    builder.addCase(fetchAllHolidays.fulfilled, (state, action) => {
      state.isHolidaysLoading = false;
      if (!action.payload.data.getAllAvailableHolidays.items) {
        return;
      }

      const items = action.payload.data.getAllAvailableHolidays.items.map(
        (e: LocationOpeningHourWithDay): Holiday => {
          return {
            id: e.id || 0,
            locationId: e.locationId || 0,
            name: e.name || '',
            isHoliday: e.isHoliday || false,
            isGeneral: e.isGeneral || false,
            validFromDate: e.validFromDate
              ? dayjs(e.validFromDate || '').format('DD MMM YYYY')
              : '',
            validToDate: e.validToDate ? dayjs(e.validToDate || '').format('DD MMM YYYY') : '',
            validFromDateTime: e.validFromDateTime
              ? dayjs(e.validFromDateTime || '').format('DD MMM YYYY HH:mm:ss')
              : '',
            validToDateTime: e.validToDateTime
              ? dayjs(e.validToDateTime || '').format('DD MMM YYYY HH:mm:ss')
              : '',
            createdBy: e.createdBy || 0,
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            status: e.status || '',
            dayOfWeek: e.dayOfWeek || '',
            displayStatus: e.status ? e.status.charAt(0).toUpperCase() + e.status.slice(1) : ''
          };
        }
      );
      const totalRecords = action.payload.data.getAllAvailableHolidays.totalRecords.totalRecords;

      const fetchedAllHolidaysInfo = {
        totalRecords: totalRecords,
        items: items,
        locationId: action.meta.arg.locationId
      };
      if (state.items.length === 0) {
        state.items.push(fetchedAllHolidaysInfo);
      } else {
        // Check if we are updating an already fetched or fetching new one
        const update = state.items.find(
          (HolidaysInfo) => HolidaysInfo.locationId === fetchedAllHolidaysInfo.locationId
        );
        if (update) {
          const newState = state.items.map((HolidaysInfo) => {
            if (HolidaysInfo.locationId === fetchedAllHolidaysInfo.locationId) {
              return fetchedAllHolidaysInfo;
            } else {
              return HolidaysInfo;
            }
          });
          Object.assign(state.items, newState);
        } else {
          state.items.push(fetchedAllHolidaysInfo);
        }
      }
    });
    builder.addCase(fetchAllHolidays.pending, (state, action) => {
      state.isHolidaysLoading = true;
      return state;
    });
    // TODO: It has to be done rejected case to a handle the Error scenario.
  }
});

export default HolidaysSlice.reducer;
