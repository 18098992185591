import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '@gym-atoms/Input/Input';
import Button, { ButtonSizes } from '@gym-atoms/Button/Button';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { useTranslation } from 'react-i18next';

import Text from '@gym-atoms/Text/Text';

export interface ResetPasswordFormValues {
  password: string;
  oldPassword: string;
}

const ResetPassword = ({ visible, onHide, onSubmit }: ResetPasswordProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    errors,
    clearErrors,
    trigger
  } = useForm<ResetPasswordFormValues>();

  const [formGeneralError, setFormGeneralError] = useState(false);
  const [formGeneralErrorMessage, setFormGeneralErrorMessage] = useState<string>();
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState<string>();
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>();
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [isInserted, setIsInserted] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // Validation rules from react-hook-form
    register('password', {
      required: true,
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      validate: (value) => {
        if (getValues('oldPassword') !== undefined) {
          return value !== getValues('oldPassword');
        } else {
          return false;
        }
      }
    });
    register('oldPassword', {
      required: true
    });
  }, [register]);

  // Update error messages as they change
  useEffect(() => {
    if (errors.password?.type === 'required') {
      setPasswordError(true);
      setSubmitButtonDisabled(true);
    } else if (errors.password?.type === 'minLength') {
      setPasswordError(true);
      setPasswordErrorMessage(t('RESET_PASSWORD.LENGTH_ERROR'));
      setSubmitButtonDisabled(true);
    } else if (errors.password?.type === 'pattern') {
      setPasswordError(true);
      setPasswordErrorMessage(t('RESET_PASSWORD.PATTERN_ERROR'));
      setSubmitButtonDisabled(true);
    } else if (errors.password?.type === 'validate') {
      setPasswordError(true);
      setPasswordErrorMessage(t('RESET_PASSWORD.OLD_NEW_PASSWORD_ERROR'));
      setSubmitButtonDisabled(true);
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }
  }, [errors]);

  const oldPasswordHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue('oldPassword', e.currentTarget.value.trim());
  };
  const passwordChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value.trim();
    setValue('password', newValue, { shouldValidate: true });
    trigger(); // triggers validation
  };

  const confirmPasswordChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    const isInvalid = e.currentTarget.value.trim() !== getValues('password');
    setConfirmPasswordError(isInvalid);
    setSubmitButtonDisabled(isInvalid);
  };

  const onHideState = () => {
    setOldPasswordError(false);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setFormGeneralError(false);
    onHide();
  };

  const submitCallback = (error: boolean, errorCode?: string) => {
    if (error) {
      if (errorCode === 'NotAuthorizedException') {
        setOldPasswordError(true);
        setOldPasswordErrorMessage(t('RESET_PASSWORD.INCORRECT_PASSWORD_ERROR'));
      } else {
        setFormGeneralError(true);
        setFormGeneralErrorMessage(
          errorCode === 'LimitExceededException'
            ? t('RESET_PASSWORD.LIMIT_EXCEEDED_ERROR')
            : t('RESET_PASSWORD.DEFAULT_ERROR')
        );
      }
    } else {
      setValue('password', null);
      setSubmitButtonDisabled(true);
      clearErrors();
      onHideState();
    }

    setIsInserted(false);
  };

  const onSubmitHandler = (
    data: ResetPasswordFormValues,
    callback: (error: boolean, errorCode?: string) => void
  ) => {
    setIsInserted(true);
    onSubmit(data, callback);
  };

  const resetFormContent = (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmitHandler(data, submitCallback);
      })}>
      <div className="form-group mb25">
        <Input
          label={t('RESET_PASSWORD.OLD_PASSWORD_PLACEHOLDER')}
          variant="password"
          name="oldPassword"
          onChange={oldPasswordHandler}
          error={oldPasswordError}
          errorMessage={oldPasswordErrorMessage}
        />
      </div>
      <div className="form-group mb25">
        <Input
          label={t('RESET_PASSWORD.NEW_PASSWORD_PLACEHOLDER')}
          variant="password"
          name="password"
          onChange={passwordChangeHandler}
          error={passwordError}
          errorMessage={passwordErrorMessage}
        />
      </div>

      <div className="form-group mb40">
        <Input
          label={t('RESET_PASSWORD.REPEAT_PASSWORD_PLACEHOLDER')}
          variant="password"
          name="confirmPassword"
          error={confirmPasswordError}
          errorMessage={t('RESET_PASSWORD.PASSWORD_DO_NOT_MATCH')}
          onChange={confirmPasswordChangeHandler}
        />
      </div>

      {formGeneralError && (
        <div className="row mb18 p-error">
          <Text>{formGeneralErrorMessage}</Text>
        </div>
      )}
      <Button
        label="Reset Password"
        block={true}
        disabled={submitButtonDisabled || isInserted}
        icon={isInserted ? 'pi-spinner pi-spin' : ''}
        size={ButtonSizes.large}
      />
    </form>
  );

  return (
    <>
      <DialogBox
        variant={DialogBoxVariants.basic}
        dialogVisible={visible}
        onHideCallback={onHideState}
        dialogHeader={<>{t('RESET_PASSWORD.MODAL_HEADER')}</>}
        dialogDismissableMask={true}
        dialogClosable={true}
        content={resetFormContent}
      />
    </>
  );
};

export interface ResetPasswordProps {
  /** Controls the visibility of the ResetPassword modal */
  visible: boolean;
  /** Function to execute on modal close */
  onHide: () => void;
  /** Callback to pass the new password. The results will be in `ResetPasswordFormValues` type */
  onSubmit: (
    data: ResetPasswordFormValues,
    callback: (error: boolean, errorCode?: string) => void
  ) => void;
}

export default ResetPassword;
