/**
 * Custom react hooks
 */

import { useEffect, useRef } from 'react';

/**
 * A custom hook to store previously used value
 * @param value - Previous value that needs to be kept
 * @returns - Previously used value
 */
export const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
