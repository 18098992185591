import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { getMembersForKisiEvent } from '@gym-graphql/queries';
import {
  KisiEventMembers,
  KisiEventMembers as typeOfKisiEventMembers
} from '@gym-particles/types/KisiEventMembers';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isKisiEventMembersLoading: boolean;
  items: Array<{
    memberEmail: string;
    items: typeOfKisiEventMembers[];
    totalRecords: number;
  }>;
} = {
  isKisiEventMembersLoading: false,
  items: []
};

type kisiEventParamType = {
  memberEmail: string;
};

export const fetchKisiEventMembers = createAsyncThunk(
  '/gym/fetchKisiEventMembers',
  async (params: kisiEventParamType) => {
    const response = await (API.graphql({
      query: getMembersForKisiEvent,
      variables: {
        memberEmail: params.memberEmail
      }
    }) as Promise<{
      data: {
        getMembersForKisiEvent: {
          items: KisiEventMembers[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const KisiEventsSlice = createSlice({
  name: 'KisiEvents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchKisiEventMembers.fulfilled, (state, action) => {
      state.isKisiEventMembersLoading = false;
      if (action.payload.data.getMembersForKisiEvent.items === null) {
        return;
      }
      const items = action.payload.data.getMembersForKisiEvent.items.map(
        (e: KisiEventMembers): typeOfKisiEventMembers => {
          return {
            id: e.id || 0,
            uniqueId: e.uniqueId || '',
            clientId: e.clientId || '',
            memberStatusId: e.memberStatusId || 0,
            userId: e.userId || 0,
            username: e.username || '',
            name: e.name || '',
            address: e.address || '',
            phone: e.phone || '',
            email: e.email || '',
            createdBy: e.createdBy || 0,
            createdDate: e.createdDate || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            note: e.note || '',
            accountBalance: e.accountBalance || 0,
            accountBalanceChangeDate: e.accountBalanceChangeDate || ''
          };
        }
      );
      console.log(items);

      const totalRecords = action.payload.data.getMembersForKisiEvent.totalRecords.totalRecords;

      const fetchedKisiEventMembersInfo = {
        totalRecords: totalRecords,
        items: items,
        memberEmail: action.meta.arg.memberEmail
      };

      if (state.items.length === 0) {
        state.items.push(fetchedKisiEventMembersInfo);
      } else {
        const update = state.items.find(
          (kisiEventInfo) => kisiEventInfo.memberEmail === fetchedKisiEventMembersInfo.memberEmail
        );
        if (update) {
          const newState = state.items.map((kisiEventInfo) => {
            if (kisiEventInfo.memberEmail === fetchedKisiEventMembersInfo.memberEmail) {
              return fetchedKisiEventMembersInfo;
            } else {
              return kisiEventInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedKisiEventMembersInfo);
        }
      }
    });

    builder.addCase(fetchKisiEventMembers.pending, (state, action) => {
      state.isKisiEventMembersLoading = true;
      return state;
    });

    builder.addCase(fetchKisiEventMembers.rejected, (state, action) => {
      state.isKisiEventMembersLoading = false;
      console.log('getKisiEvents', action);
      return state;
    });
  }
});

export default KisiEventsSlice.reducer;
