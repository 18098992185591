import { useState, useRef, useEffect, FormEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from 'aws-amplify';
import { useHistory, useParams } from 'react-router-dom';
import {
  MemberDetails,
  Member,
  MemberChangePhoneNumberFormInput,
  MemberBookingDetails
} from '@gym-particles/types/Member';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import Image from '@gym-atoms/Image/Image';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { CheckboxChangeEvent } from '@gym-atoms/Checkbox/Checkbox';
import Alert, { AlertType, createAlert } from '@gym-atoms/Alert/Alert';
import {
  changeMemberLocationIsLocked,
  changePhoneNumber,
  linkMember,
  resyncMemberData,
  sendInvitationEmail,
  unlinkMember,
  updateMemberFlag
} from '@gym-graphql/mutations';
import styles from './MemberDetails.module.scss';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import Avatar from '@gym-atoms/Avatar/Avatar';
import { acronym } from '@gym-particles/acronym';
import { useAppSelector } from '@gym-redux/store';
import {
  ChangePhoneNumberMutationVariables,
  ChangePhoneNumberResponse,
  getMemberUserLinkResponse,
  isMemberLinkedResponse,
  ResyncMemberResponse,
  SiteLocationMemberDetails
} from '@gym-src/API';
import {
  getMemberBookingDetails,
  getMemberDetails,
  getMemberUserLink,
  isMemberLinked
} from '@gym-graphql/queries';
import Loader from '@gym-atoms/Loader/Loader';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { useForm } from 'react-hook-form';
import Input from '@gym-atoms/Input/Input';
import * as Validator from '@gym-particles/validations/Validator';
import Dropdown, { DropdownChangeEvent } from '@gym-atoms/Dropdown/Dropdown';
import countryCodes from 'country-codes-list';
import { MemberDetailsModalProps } from '@gym-organisms/MemberDetailsModal/MemberDetailsModal';
dayjs.extend(isBetween);
dayjs.extend(utc);

const MemberDetailsGeneral = ({
  member,
  setForceResync
}: {
  member: Member;
  setForceResync: MemberDetailsModalProps['setForceResync'];
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [subscriptionSortField, setSubscriptionSortField] = useState('createdDate');
  const [subscriptionSortOrder, setSubscriptionSortOrder] = useState(-1);
  const [subscriptionOffset, setSubscriptionOffset] = useState(0);
  const [subscriptionPageSize, setSubscriptionPageSize] = useState(10);
  const [selectedSubscriptionRow, setSelectedsubScriptionRow] = useState<MemberDetails>();
  const [membershipSortField, setMembershipSortField] = useState('createdDate');
  const [membershipSortOrder, setMembershipSortOrder] = useState(-1);
  const [membershipOffset, setMembershipOffset] = useState(0);
  const [membershipPageSize, setMembershipPageSize] = useState(10);
  const [selectedMembershipRow, setSelectedMembershipRow] = useState<MemberDetails>();
  const [memberBookingSortField, setMemberBookingSortField] = useState('createdDate');
  const [memberBookingSortOrder, setMemberBookingSortOrder] = useState(-1);
  const [memberBookingOffset, setMemberBookingOffset] = useState(0);
  const [memberBookingPageSize, setMemberBookingPageSize] = useState(10);
  const [selectedMemberBookingRow, setSelectedMemberBookingRow] = useState<MemberBookingDetails>();
  const [resyncInProgress, setResyncInProgress] = useState(false);
  const [inviteInProgress, setInviteInProgress] = useState(false);
  const [linkingInProgress, setLinkingInProgress] = useState(false);
  const [phoneNumberChangeInProgress, setPhoneNumberChangeInProgress] = useState(false);
  const [isLocked, setIsLocked] = useState<boolean>(member.isBlocked);
  const [resetMemberFlagYelloBoxInProgress, setResetMemberFlagYelloBoxInProgress] = useState(false);
  const [resetMemberFlagArxInProgress, setResetMemberFlagArxInProgress] = useState(false);
  const userId = useAppSelector((state) => state.user.userId);
  const userRole = useAppSelector((state) => state.user.userRole);
  const { gymId, chainId } = useParams<{ gymId: string; chainId: string }>();
  const alertRef = useRef<AlertType>(null);
  const gymInfo = useAppSelector((state) =>
    state.gyms.items
      .find((gymSet) => gymSet.chainId === +chainId)
      ?.items.find((gym) => gym.gymId === +gymId)
  );
  const [memberDetails, setMemberDetails] = useState<Array<MemberDetails>>([]);
  const [memberBookingDetails, setMemberBookingDetails] = useState<Array<MemberBookingDetails>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [changePhoneNumberDialogVisible, setChangePhoneNumberDialogVisible] = useState(false);
  const [memberLinked, setMemberLinked] = useState(false);

  const { id: siteId, customerSiteId } = useAppSelector((state) => state.gymChain.currentGymChain);

  useEffect(() => {
    setForceResync(Math.random());
  }, [memberLinked]);

  const fetchMemberDetails = async () => {
    setIsFetching(true);
    const response = await (API.graphql({
      query: getMemberDetails,
      variables: {
        memberId: member.id
      }
    }) as Promise<{
      data: { getMemberDetails: [SiteLocationMemberDetails] };
    }>);

    const details = response.data.getMemberDetails.map((d) => {
      return {
        membership: d.membership,
        memberLocationMembershipId: d.memberLocationMembershipId,
        subscriptionDeleted: d.subscriptionDeleted,
        validFrom: d.validFrom ? dayjs(d.validFrom || '').format('DD MMM YYYY') : '',
        validTo: d.validTo ? dayjs(d.validTo || '').format('DD MMM YYYY') : '',
        subscriptionCreatedDate: d.subscriptionCreatedDate,
        subscriptionModifiedDate: d.subscriptionModifiedDate,
        subscriptionIsSelected: d.subscriptionIsSelected && !d.subscriptionDeleted ? 'Yes' : 'No',
        subscriptionStatus: d.subscriptionStatus
          ? d.subscriptionStatus.charAt(0).toUpperCase() + d.subscriptionStatus.slice(1)
          : '',
        scheduleType: d.scheduleType || '',
        remainingSessions: d.remainingSessions || 0,
        siteLocationMembershipStatus: d.siteLocationMembershipStatus
          ? d.siteLocationMembershipStatus.charAt(0).toUpperCase() +
            d.siteLocationMembershipStatus.slice(1)
          : ''
      };
    }) as MemberDetails[];
    setMemberDetails(details);
    setIsFetching(false);
  };
  useEffect(() => {
    fetchMemberDetails();
  }, [member.id]);

  const fetchBookingDetails = async () => {
    setIsFetching(true);
    const response = await (API.graphql({
      query: getMemberBookingDetails,
      variables: {
        memberId: member.id
      }
    }) as Promise<{
      data: { getMemberBookingDetails: [MemberBookingDetails] };
    }>);
    let details = [];
    if (response.data.getMemberBookingDetails !== null) {
      details = response.data.getMemberBookingDetails.map((d) => {
        return {
          booking: d.booking,
          appointmentId: d.appointmentId,
          importedId: d.importedId,
          isVisited: d.isVisited ? 'True' : 'False',
          startDateTime: d.startDateTime
            ? dayjs(d.startDateTime || '').format('DD MMM YYYY HH:mm:ss')
            : '',
          endDateTime: d.endDateTime
            ? dayjs(d.endDateTime || '').format('DD MMM YYYY HH:mm:ss')
            : '',
          createdDate: d.createdDate,
          lastModifiedDate: d.lastModifiedDate,
          membershipName: d.membershipName,
          siteLocation: d.siteLocation
        };
      }) as MemberBookingDetails[];
    }
    setMemberBookingDetails(details);
    setIsFetching(false);
  };
  useEffect(() => {
    fetchBookingDetails();
  }, [member.id]);

  const onIsLockedChange = (event: CheckboxChangeEvent) => {
    API.graphql({
      query: changeMemberLocationIsLocked,
      variables: {
        gymId: +gymId,
        memberId: member.id,
        isLocked: event.checked
      }
    });
    setIsLocked(event.checked);
  };

  const resyncButtonClickHandler = async () => {
    setResyncInProgress(true);
    const result = await (API.graphql({
      query: resyncMemberData,
      variables: {
        params: {
          memberId: member.id,
          provider: 'mindbody',
          siteId: parseInt(chainId),
          siteLocationId: parseInt(gymId),
          userId: userId
        }
      }
    }) as Promise<{
      data: { resyncMemberData: ResyncMemberResponse };
    }>);

    if (result.data.resyncMemberData.error) {
      console.error('Error resyncing!', result);
      // Show alert - when alerts are implemented
    } else {
      console.log('Resyncing successful!', result.data.resyncMemberData);
      createAlert(alertRef, {
        content: t('MEMBER_DETAILS_MODAL.ALERT_RESYNC_SUCCESS'),
        severity: 'success'
      });
      setTimeout(() => {
        history.push(`/gymChains/${chainId}/gyms/${gymId}/dashboard/visits`);
        history.push(`/gymChains/${chainId}/gyms/${gymId}/members`);
      }, 5000);
      // Show alert - when alerts are implemented
    }
    setResyncInProgress(false);
  };

  const resetMemberFlagYellowBox = async () => {
    setResetMemberFlagYelloBoxInProgress(true);
    await (API.graphql({
      query: updateMemberFlag,
      variables: {
        memberId: member.id,
        flag: 1
      }
    }) as Promise<{
      data: { updateMemberFlag: number };
    }>);
    setResetMemberFlagYelloBoxInProgress(false);
  };

  const resetMemberFlagArx = async () => {
    setResetMemberFlagArxInProgress(true);
    await (API.graphql({
      query: updateMemberFlag,
      variables: {
        memberId: member.id,
        flag: 2
      }
    }) as Promise<{
      data: { updateMemberFlag: number };
    }>);
    setResetMemberFlagArxInProgress(false);
  };
  const inviteButtonHandler = async () => {
    setInviteInProgress(true);
    const gymName = gymInfo?.gymName;
    const gymUrl = gymInfo?.signUpUrl || gymInfo?.websiteLink;

    // validation
    if (member.email === undefined) {
      setInviteInProgress(false);
      createAlert(
        alertRef,
        {
          content: `${t('MEMBER_DETAILS_MODAL.INVITATION_MISSING_PREFIX')} - ${t(
            'MEMBER_DETAILS_MODAL.INVITATION_MISSING_EMAIL'
          )}`,
          severity: 'error',
          sticky: true
        },
        'replace'
      );
      return;
    }
    if (gymUrl === undefined) {
      setInviteInProgress(false);
      createAlert(
        alertRef,
        {
          content: `${t('MEMBER_DETAILS_MODAL.INVITATION_MISSING_PREFIX')} - ${t(
            'MEMBER_DETAILS_MODAL.INVITATION_MISSING_URL'
          )}`,
          severity: 'error',
          sticky: true
        },
        'replace'
      );
      return;
    }

    const result = await (API.graphql({
      query: sendInvitationEmail,
      variables: {
        input: {
          userName: member.memberFirstName,
          userEmail: member.email,
          gymName: gymName,
          gymUrl: gymUrl,
          language: member.language || 'en'
        }
      }
    }) as Promise<{
      data: {
        sendInvitationEmail: {
          error: boolean;
          errorMessage: string | null;
        };
      };
    }>);

    if (result.data.sendInvitationEmail.error) {
      console.error('Failed to send invitation:', result.data.sendInvitationEmail.errorMessage);
      createAlert(
        alertRef,
        {
          content: t('MEMBER_DETAILS_MODAL.INVITATION_FAILURE'),
          severity: 'error',
          sticky: true
        },
        'replace'
      );
    } else {
      createAlert(
        alertRef,
        {
          content: t('MEMBER_DETAILS_MODAL.INVITATION_SUCCESS'),
          severity: 'success',
          sticky: true
        },
        'replace'
      );
    }

    setInviteInProgress(false);
  };

  const membershipData = memberDetails
    ?.filter((v, i, a) => a.findIndex((v2) => v2.membership === v.membership) === i)
    .sort((a, b) => a.membership.localeCompare(b.membership));

  const tablePropsSiteLocationMemberships: TableProps<MemberDetails> = {
    exportFileName: t('MEMBER_DETAILS_MODAL.EXPORT_EXCEL_FILE_NAME'),
    setPageSize: setMembershipPageSize,
    setOffset: setMembershipOffset,
    pageSize: membershipPageSize,
    offset: membershipOffset,
    setSortField: setMembershipSortField,
    setSortOrder: setMembershipSortOrder,
    sortField: membershipSortField,
    sortOrder: membershipSortOrder,
    totalRecords: membershipData.length,
    data: membershipData,
    lazy: false,
    uniqueFieldName: 'membership',
    columns: [
      {
        field: 'membership',
        header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_NAME'),
        toggable: false,
        sortable: true
      },
      {
        field: 'siteLocationMembershipStatus',
        header: t('MEMBER_DETAILS_MODAL.LABEL_STATUS'),
        toggable: true,
        truncateText: true
      }
    ],
    searchPlaceholderText: t('MEMBER_DETAILS_MODAL.SEARCH_FIELD_TEXT_MEMBERSHIP'),
    excelBtntext: t('MEMBER_DETAILS_MODAL.EXPORT_EXCEL_BUTTON_LABEL'),
    headerText: t('MEMBER_DETAILS_MODAL.LABEL_MEMBERSHIPS'),
    selectedRow: selectedMembershipRow,
    setSelectedRow: setSelectedMembershipRow
  };

  const isMemberLinkedToUser = async () => {
    try {
      const response = await (API.graphql({
        query: isMemberLinked,
        variables: {
          memberId: member.id || 0
        }
      }) as Promise<{
        data: { isMemberLinked: isMemberLinkedResponse };
      }>);
      if (
        response.data.isMemberLinked[0].rowCount !== undefined &&
        response.data.isMemberLinked[0].rowCount !== null
      ) {
        response.data.isMemberLinked[0].rowCount > 0
          ? setMemberLinked(true)
          : setMemberLinked(false);
      } else {
        console.log('Error isMemberLinked Query');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    isMemberLinkedToUser();
  }, [memberLinked]);

  const linkMemberToUser = async (id: number) => {
    setLinkingInProgress(true);
    try {
      const checkLink = await (API.graphql({
        query: getMemberUserLink,
        variables: {
          memberId: id || 0
        }
      }) as Promise<{
        data: {
          getMemberUserLink: getMemberUserLinkResponse;
        };
      }>);
      if (checkLink && checkLink.data.getMemberUserLink.totalRecords === 0) {
        createAlert(alertRef, {
          content: t('MEMBER_DETAILS_MODAL.ALERT_LINK_MEMBER_UNLINKABLE'),
          severity: 'error'
        });
        setLinkingInProgress(false);
      } else {
        const response = await (API.graphql({
          query: linkMember,
          variables: {
            memberId: id || 0
          }
        }) as Promise<{
          data: {
            linkMember: { linkMember: number };
          };
        }>);
        if (response && response.data.linkMember) {
          setMemberLinked(true);
          createAlert(alertRef, {
            content: t('MEMBER_DETAILS_MODAL.ALERT_LINK_MEMBER_SUCCESS'),
            severity: 'success'
          });
          setLinkingInProgress(false);
        }
      }
    } catch (error) {
      setLinkingInProgress(false);
      console.log(error);
      createAlert(alertRef, {
        content: t('MEMBER_DETAILS_MODAL.ALERT_LINK_MEMBER_FAILURE'),
        severity: 'error'
      });
    }
  };

  const unlinkMemberFromUser = async (id: number) => {
    setLinkingInProgress(true);
    try {
      const response = await (API.graphql({
        query: unlinkMember,
        variables: {
          memberId: id || 0
        }
      }) as Promise<{
        data: {
          unlinkMember: { unlinkMember: number };
        };
      }>);
      if (response && response.data.unlinkMember) {
        setMemberLinked(false);
        createAlert(alertRef, {
          content: t('MEMBER_DETAILS_MODAL.ALERT_UNLINK_MEMBER_SUCCESS'),
          severity: 'success'
        });
        setLinkingInProgress(false);
      }
    } catch (error) {
      console.log(error);
      createAlert(alertRef, {
        content: t('MEMBER_DETAILS_MODAL.ALERT_UNLINK_MEMBER_FAILURE'),
        severity: 'error'
      });
      setLinkingInProgress(false);
    }
  };

  const changePhonenumber = async (data) => {
    setChangePhoneNumberDialogVisible(false);
    setPhoneNumberChangeInProgress(true);
    try {
      const response = await (API.graphql({
        query: changePhoneNumber,
        variables: {
          phoneNumber:
            data.countryCode.substring(1) +
              data.phoneNumber.replace(/[()-\s]/g, '').replace(/^0/, '') || '',
          uniqueId: member?.uniqueId || 0,
          siteId,
          customerSiteId
        } as ChangePhoneNumberMutationVariables
      }) as Promise<{
        data: {
          changePhoneNumber: ChangePhoneNumberResponse;
        };
      }>);
      if (response && !response.data.changePhoneNumber.error) {
        createAlert(alertRef, {
          content: t('MEMBER_DETAILS_MODAL.ALERT_PHONE_UPDATE_SUCCESS'),
          severity: 'success'
        });
        setPhoneNumberChangeInProgress(false);
        setTimeout(() => {
          history.push(`/gymChains/${chainId}/gyms/${gymId}/dashboard/visits`);
          history.push(`/gymChains/${chainId}/gyms/${gymId}/members`);
        }, 1500);
      } else {
        console.log(response.data.changePhoneNumber.error);
        createAlert(alertRef, {
          content: t('MEMBER_DETAILS_MODAL.ALERT_PHONE_NUMBER_UPDATE_FAILURE'),
          severity: 'error'
        });
        setPhoneNumberChangeInProgress(false);
      }
    } catch (error) {
      console.log(error);
      createAlert(alertRef, {
        content: t('MEMBER_DETAILS_MODAL.ALERT_PHONE_NUMBER_UPDATE_FAILURE'),
        severity: 'error'
      });
      setPhoneNumberChangeInProgress(false);
    }
  };

  const tablePropsSubscriptions: TableProps<MemberDetails> = {
    exportFileName: t('MEMBER_DETAILS_MODAL.EXPORT_EXCEL_FILE_NAME'),
    setPageSize: setSubscriptionPageSize,
    setOffset: setSubscriptionOffset,
    pageSize: subscriptionPageSize,
    offset: subscriptionOffset,
    setSortField: setSubscriptionSortField,
    setSortOrder: setSubscriptionSortOrder,
    sortField: subscriptionSortField,
    sortOrder: subscriptionSortOrder,
    totalRecords: memberDetails.length,
    data: memberDetails,
    lazy: false,
    uniqueFieldName: 'membership',
    columns: [
      {
        field: 'membership',
        header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_NAME'),
        toggable: false,
        sortable: true
      },
      {
        field: 'validFrom',
        header: t('MEMBER_DETAILS_MODAL.LABEL_VALID_FROM'),
        toggable: true
      },
      {
        field: 'validTo',
        header: t('MEMBER_DETAILS_MODAL.LABEL_VALID_TO'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'scheduleType',
        header: t('MEMBER_DETAILS_MODAL.LABEL_SCHEDULE_TYPE'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'remainingSessions',
        header: t('MEMBER_DETAILS_MODAL.LABEL_REMAINING_SESSIONS'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'subscriptionStatus',
        header: t('MEMBER_DETAILS_MODAL.LABEL_STATUS'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'subscriptionCreatedDate',
        header: t('MEMBER_DETAILS_MODAL.LABEL_SUBCRIPTION_CREATED_DATE'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'subscriptionModifiedDate',
        header: t('MEMBER_DETAILS_MODAL.LABEL_SUBCRIPTION_MODIFIED_DATE'),
        toggable: true,
        truncateText: true
      }
    ],
    searchPlaceholderText: t('MEMBER_DETAILS_MODAL.SEARCH_FIELD_TEXT'),
    excelBtntext: t('MEMBER_DETAILS_MODAL.EXPORT_EXCEL_BUTTON_LABEL'),
    headerText: t('MEMBER_DETAILS_MODAL.LABEL_SUBSCRIPTIONS'),
    selectedRow: selectedSubscriptionRow,
    setSelectedRow: setSelectedsubScriptionRow
  };

  const tablePropsBookings: TableProps<MemberBookingDetails> = {
    exportFileName: t('MEMBER_DETAILS_MODAL.EXPORT_EXCEL_BOOKING_FILE_NAME'),
    setPageSize: setMemberBookingPageSize,
    setOffset: setMemberBookingOffset,
    pageSize: memberBookingPageSize,
    offset: memberBookingOffset,
    setSortField: setMemberBookingSortField,
    setSortOrder: setMemberBookingSortOrder,
    sortField: memberBookingSortField,
    sortOrder: memberBookingSortOrder,
    totalRecords: memberBookingDetails.length,
    data: memberBookingDetails,
    lazy: false,
    uniqueFieldName: 'booking',
    columns: [
      {
        field: 'booking',
        header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_NAME'),
        toggable: false,
        sortable: true
      },
      {
        field: 'importedId',
        header: t('MEMBER_DETAILS_MODAL.LABEL_IMPORTED_ID'),
        toggable: true
      },
      {
        field: 'startDateTime',
        header: t('MEMBER_DETAILS_MODAL.LABEL_START_DATE_TIME'),
        toggable: true
      },
      {
        field: 'endDateTime',
        header: t('MEMBER_DETAILS_MODAL.LABEL_END_DATE_TIME'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'isVisited',
        header: t('MEMBER_DETAILS_MODAL.LABEL_IS_VISITED'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'appointmentId',
        header: t('MEMBER_DETAILS_MODAL.LABEL_APPOINTMENT_ID'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'membershipName',
        header: t('MEMBER_DETAILS_MODAL.LABEL_MEMBERSHIP_NAME'),
        toggable: true,
        truncateText: false
      },
      {
        field: 'siteLocation',
        header: t('MEMBER_DETAILS_MODAL.LABEL_SITE_LOCATION_NAME'),
        toggable: true,
        truncateText: false
      },
      {
        field: 'createdDate',
        header: t('MEMBER_DETAILS_MODAL.LABEL_CREATED_DATE'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'lastModifiedDate',
        header: t('MEMBER_DETAILS_MODAL.LABEL_LAST_MODIFIED_DATE'),
        toggable: true,
        truncateText: true
      }
    ],
    searchPlaceholderText: t('MEMBER_DETAILS_MODAL.BOOKING_SEARCH_FIELD_TEXT'),
    excelBtntext: t('MEMBER_DETAILS_MODAL.EXPORT_EXCEL_BUTTON_LABEL'),
    headerText: t('MEMBER_DETAILS_MODAL.LABEL_BOOKINGS'),
    selectedRow: selectedMemberBookingRow,
    setSelectedRow: setSelectedMemberBookingRow
  };

  const ChangePhoneNumberDialog = () => {
    const countryCodesList = useMemo(
      () =>
        countryCodes
          .all()
          .sort((a, b) => a.countryCode.localeCompare(b.countryCode))
          .map((val) => ({
            value: '+' + val.countryCallingCode,
            label: `${`(+${val.countryCallingCode})`.padEnd(8)} ${val.countryCode}`
          })),
      []
    );

    const defaultValues: MemberChangePhoneNumberFormInput = {
      countryCode: countryCodesList[0].value,
      phoneNumber: ''
    };

    const { register, handleSubmit, errors, setValue } = useForm<MemberChangePhoneNumberFormInput>({
      defaultValues: defaultValues
    });
    const [formValues, setFormValues] = useState(defaultValues);

    useEffect(() => {
      register('phoneNumber', {
        required: t('MEMBER_DETAILS_MODAL.FORM_REQUIRED_FIELD_ERROR') as string,
        pattern: {
          value: Validator.validPhoneNumberRegEx,
          message: t('MEMBER_DETAILS_MODAL.FORM_PHONE_INVALID_ERROR') as string
        },
        maxLength: {
          value: 20,
          message: t('MEMBER_DETAILS_MODAL.FORM_PHONE_NUMBER_LENGTH_ERROR') as string
        }
      });
      register('countryCode', {
        validate: (value) => {
          if (value) {
            return true;
          } else {
            return t('MEMBER_DETAILS_MODAL.FORM_REQUIRED_FIELD_ERROR') as string;
          }
        }
      });
    }, [register]);

    const textFieldChangeHandler = (e: FormEvent<HTMLInputElement>) => {
      setValue(
        e.currentTarget.name as keyof MemberChangePhoneNumberFormInput,
        e.currentTarget.value
      );
      setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
    };

    const dropdownChangeHandler = (e: DropdownChangeEvent) => {
      setValue(e.target.name as keyof MemberChangePhoneNumberFormInput, e.target.value);
      setFormValues({ ...formValues, [e.target.name]: e.value });
    };

    const onSubmitHandler = async (data: MemberChangePhoneNumberFormInput) => {
      changePhonenumber(data);
    };

    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={changePhoneNumberDialogVisible}
          onHideCallback={() => {
            setChangePhoneNumberDialogVisible(false);
          }}
          dialogHeader={t('MEMBER_DETAILS_MODAL.FORM_CHANGE_PHONE_DIALOG_HEADER')}
          dialogDismissableMask={true}
          dialogClosable={false}
          content={
            <form className="input-mb20 py-4" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
              <div className="mb25">
                <div className="row">
                  <label className="fw-bold">
                    {t('MEMBER_DETAILS_MODAL.FORM_PHONE')}
                    <span className="p-error"> *</span>
                  </label>

                  <div className="col-md-5">
                    <div className="no-label">
                      <Dropdown
                        name="countryCode"
                        options={countryCodesList}
                        value={formValues.countryCode}
                        onChange={dropdownChangeHandler}
                        error={errors.countryCode ? true : false}
                      />
                    </div>
                    {errors.countryCode && (
                      <Text className="p-error mb15">
                        {t('MEMBER_DETAILS_MODAL.FORM_REQUIRED_FIELD_ERROR')}
                      </Text>
                    )}
                  </div>
                  <div className="col-md-7">
                    <div className="no-label">
                      <Input
                        label={t('MEMBER_DETAILS_MODAL.FORM_PHONE')}
                        variant="basic"
                        onChange={textFieldChangeHandler}
                        name="phoneNumber"
                        error={errors.phoneNumber ? true : false}
                        errorMessage={errors.phoneNumber?.message}
                        value={formValues.phoneNumber}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row mt35 btn-min-w-110">
                <div className="mr15">
                  <Button
                    label={t('MEMBER_DETAILS_MODAL.FORM_CHANGE_PHONE_DIALOG_HEADER')}
                    size={ButtonSizes.medium}
                  />
                </div>
                <Button
                  label={t('MEMBER_DETAILS_MODAL.FORM_CANCEL_DIALOG_BUTTON')}
                  variant={ButtonVariants.textonly}
                  onClick={(e) => {
                    e.preventDefault();
                    setChangePhoneNumberDialogVisible(false);
                  }}
                />
              </div>
            </form>
          }
        />
      </div>
    );
  };

  return (
    <div className="row px-3 pt-5 h-100">
      <ChangePhoneNumberDialog />
      <div className="col-md-3 text-center">
        {member.imageUrl === '' ? (
          <Avatar
            size="custom"
            shape="circle"
            label={acronym(member.memberFullName || '')}
            width={150}
            height={150}
            fontSize="3em"
            color="#e0f4fe"
          />
        ) : (
          <Image
            data-cy={'gyms-members-memberdetails-modal-image'}
            source={member.imageUrl}
            width={150}
            height={150}
            circle
          />
        )}
        <div className="mt25">
          <Text
            data-cy={'gyms-members-memberdetails-modal-text-name'}
            bold
            className={styles.memberModalBlackText1}
            size={TextSizes.large}>
            {member.memberFullName}
          </Text>
        </div>
        <Text
          size={TextSizes.t14}
          data-cy={'gyms-members-memberdetails-modal-text-last-accessed'}>{`${t(
          'MEMBER_DETAILS_MODAL.LAST_ACCESSED'
        )} ${dayjs(member.lastModifiedDate).format('DD MMM YYYY')}`}</Text>
        <Text
          size={TextSizes.t14}
          data-cy={'gyms-members-memberdetails-modal-text-last-sync'}>{`${t(
          'MEMBER_DETAILS_MODAL.LAST_SYNC'
        )} ${dayjs.utc(member.lastSyncDate).local().format('hh.mm A')} on ${dayjs(
          member.lastSyncDate
        ).format('DD MMM YYYY')}`}</Text>
        <div className="mt-3 d-flex justify-content-center">
          <div className="w-75">
            <Button
              data-cy={'gyms-members-memberdetails-modal-btn-resync'}
              label={t(
                resyncInProgress
                  ? 'MEMBER_DETAILS_MODAL.RESYNC_BUTTON_INPROGRESS'
                  : 'MEMBER_DETAILS_MODAL.RESYNC_BUTTON'
              )}
              onClick={resyncButtonClickHandler}
              icon={resyncInProgress ? 'pi-spinner pi-spin' : ''}
              block
            />
          </div>
        </div>
      </div>
      <div className="col-md-9 d-flex flex-column h-100 detailed-modal__content-col">
        <div className="d-flex flex-row">
          <div className="col">
            <Alert refProp={alertRef} />
          </div>
        </div>

        <div className="d-flex flex-row  mb25">
          <div className="col-md-3">
            <Text bold className={styles.memberModalLabel}>
              {t('MEMBER_DETAILS_MODAL.LABEL_MEMBER_ID')}
            </Text>
          </div>
          <div>
            <Text>{member.id}</Text>
          </div>
        </div>
        {member.uniqueId && (
          <div className="d-flex flex-row  mb25">
            <div className="col-md-3">
              <Text bold className={styles.memberModalLabel}>
                {t('MEMBER_DETAILS_MODAL.LABEL_UNIQUE_ID')}
              </Text>
            </div>
            <div>
              <Text>{member.uniqueId}</Text>
            </div>
          </div>
        )}

        <div className="d-flex flex-row  mb25">
          <div className="col-md-3">
            <Text bold className={styles.memberModalLabel}>
              {t('MEMBER_DETAILS_MODAL.LABEL_CLIENT_ID')}
            </Text>
          </div>
          <div>
            <Text>{member.clientId}</Text>
          </div>
        </div>

        <div className="d-flex flex-row  mb25">
          <div className="col-md-3">
            <Text bold className={styles.memberModalLabel}>
              {t('MEMBER_DETAILS_MODAL.LABEL_ACCOUNT_BALANCE')}
            </Text>
          </div>
          <div>
            <Text>{member.accountBalance}</Text>
          </div>
        </div>
        <div className="d-flex flex-row  mb25">
          <div className="col-md-3">
            <Text
              data-cy={'gyms-members-memberdetails-modal-text-label-email'}
              bold
              className={styles.memberModalLabel}>
              {t('MEMBER_DETAILS_MODAL.LABEL_EMAIL')}
            </Text>
          </div>
          <div>
            <Text data-cy={'gyms-members-memberdetails-modal-text-email'}>{member.email}</Text>
          </div>
        </div>
        {member.userId === 0 ? (
          <div className="d-flex flex-row  mb25">
            <div className="col-md-3">
              <Text
                data-cy={'gyms-members-memberdetails-modal-text-label-type-access'}
                bold
                className={styles.memberModalLabel}>
                {t('MEMBER_DETAILS_MODAL.LABEL_TYPE_OF_ACCESS')}
              </Text>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row  mb25">
            <div className="col-md-3">
              <Text
                data-cy={'gyms-members-memberdetails-modal-text-label-type-access'}
                bold
                className={styles.memberModalLabel}>
                {t('MEMBER_DETAILS_MODAL.LABEL_TYPE_OF_ACCESS')}
              </Text>
            </div>
            <div className="d-flex flex-row">
              {!member.accessTypes.isMobileAppDisabled && (
                <div className="d-flex align-items-center me-4">
                  <span className="modal-icon icon-mobile"></span>
                  <Text
                    data-cy={'gyms-members-memberdetails-modal-text-type-access-phone'}
                    className={styles.memberModalBlackText}>
                    {t('MEMBER_DETAILS_MODAL.TYPE_OF_ACCESS_PHONE_APP')}
                  </Text>
                </div>
              )}
              {member.hasAccessCard && (
                <div className="d-flex align-items-center me-4">
                  <span className="modal-icon icon-signal me-2"></span>
                  <Text
                    data-cy={'gyms-members-memberdetails-modal-text-type-access-card'}
                    className={styles.memberModalBlackText}>
                    {t('MEMBER_DETAILS_MODAL.TYPE_OF_ACCESS_ACCESS_CARD')}
                  </Text>
                </div>
              )}
              {/* Will enable below icon later if necessary  */}
              {/* {!member.accessTypes.isJustTapRevoked && (
              <div className="d-flex align-items-center">
                <span className="modal-icon icon-credentials me-2"></span>
                <Text
                  data-cy={'gyms-members-memberdetails-modal-text-type-access-just-tap'}
                  className={styles.memberModalBlackText}>
                  {t('MEMBER_DETAILS_MODAL.TYPE_OF_ACCESS_JUST_TAP')}
                </Text>
              </div>
            )} */}
            </div>
          </div>
        )}

        {isFetching ? <Loader shape="table" /> : <Table {...tablePropsSiteLocationMemberships} />}
        <br />
        {isFetching ? <Loader shape="table" /> : <Table {...tablePropsSubscriptions} />}
        <br />
        {isFetching ? <Loader shape="table" /> : <Table {...tablePropsBookings} />}
      </div>
    </div>
  );
};

export default MemberDetailsGeneral;
