import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import TabView from '@gym-atoms/TabView/TabView';
import { Member } from '@gym-particles/types/Member';
import MemberDetailsGeneral from './MemberDetailsGeneral';
import MemberDetailsAccessMethods from './MemberDetailsAccessMethods';
import { useParams } from 'react-router';

const MemberDetailsModalContent = (props: {
  member: Member;
  setForceResync: MemberDetailsModalProps['setForceResync'];
}) => {
  const { t } = useTranslation();
  const { gymId } = useParams<{ gymId: string }>();

  const tabViewItems = [
    {
      header: t('MEMBER_DETAILS_MODAL.TAB_HEADER_GENERAL'),
      content: <MemberDetailsGeneral member={props.member} setForceResync={props.setForceResync} />
    }
  ];

  const conditionalTabItems = [
    {
      header: t('MEMBER_DETAILS_MODAL.TAB_HEADER_ACCESS_METHODS'),
      content: <MemberDetailsAccessMethods member={props.member} />
    }
  ];

  return <TabView items={[...tabViewItems, ...conditionalTabItems]} />;
};

const MemberDetailsModal = (props: MemberDetailsModalProps) => {
  return (
    <Dialog
      className={'detailed-modal'}
      modal
      draggable={false}
      dismissableMask
      visible={props.visible}
      onHide={props.modalOnHide}
      position={'top'}
      style={{ width: '75vw' }}
      header={
        <MemberDetailsModalContent member={props.member} setForceResync={props.setForceResync} />
      }></Dialog>
  );
};

export interface MemberDetailsModalProps {
  /** Controls the visibility of the modal */
  visible: boolean;
  /** Runs when modal is closed */
  modalOnHide: () => void;
  /** Member data to populate the modal */
  member: Member;
  setForceResync: (value: number) => void;
}

export default MemberDetailsModal;
