import { Skeleton } from 'primereact/skeleton';

export const getActivityLoader = () => {
  return (
    <>
      <div className="all-activity">
        <div className="pb10">
          <div className="activity-date-wrapper mb10 p-col-6">
            <Skeleton width={'25%'} borderRadius="10px"></Skeleton>
          </div>
          <div className="activity-list-wrapper">
            <ul className="activity-list">
              <li className="pb10">
                <div className="d-flex align-items-center">
                  <div className="p-col-fixed" style={{ width: '50px' }}>
                    <Skeleton size={'30px'} shape="circle"></Skeleton>
                  </div>
                  <div className="p-col-1">
                    <Skeleton width={'100%'} borderRadius="10px"></Skeleton>
                  </div>
                  <div className="p-col-6">
                    <Skeleton width={'50%'} borderRadius="10px"></Skeleton>
                  </div>
                </div>
              </li>
              <li className="pb10">
                <div className="d-flex align-items-center">
                  <div className="p-col-fixed" style={{ width: '50px' }}>
                    <Skeleton size={'30px'} shape="circle"></Skeleton>
                  </div>
                  <div className="p-col-1">
                    <Skeleton width={'100%'} borderRadius="10px"></Skeleton>
                  </div>
                  <div className="p-col-6">
                    <Skeleton width={'100%'} borderRadius="10px"></Skeleton>
                  </div>
                </div>
              </li>
              <li className="pb10">
                <div className="d-flex align-items-center">
                  <div className="p-col-fixed" style={{ width: '50px' }}>
                    <Skeleton size={'30px'} shape="circle"></Skeleton>
                  </div>
                  <div className="p-col-1">
                    <Skeleton width={'100%'} borderRadius="10px"></Skeleton>
                  </div>
                  <div className="p-col-6">
                    <Skeleton width={'25%'} borderRadius="10px"></Skeleton>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
