import { ReactNode } from 'react';
import styles from './Text.module.scss';

const Text = ({
  size = TextSizes.t14,
  children,
  color,
  bold = false,
  className,
  ...props
}: TextProps) => {
  const colorStyle = { color: color };
  const boldStyle = bold ? styles.textBold : styles.textNormal;

  const finalProps = {
    className: `${boldStyle} ${className}`,
    style: colorStyle,
    ...props
  };

  switch (size) {
    case 'extra':
      return <h1 {...finalProps}>{children}</h1>;
    case 'large':
      return <h2 {...finalProps}>{children}</h2>;
    case '16':
      return <h5 {...finalProps}>{children}</h5>;
    case '15':
      return <h6 {...finalProps}>{children}</h6>;
    case '14':
    default:
      return <p {...finalProps}>{children}</p>;
  }
};

export enum TextSizes {
  t14 = '14',
  t15 = '15',
  t16 = '16',
  large = 'large',
  extra = 'extra'
}

export interface TextProps {
  /**
   * Size of the text. Use the exported `TextSizes` enum to specify size.
   * One of `TextSizes.t14`, `TextSizes.t15`, `TextSizes.t16`, `TextSizes.large`, `TextSizes.extra`
   */
  size?: TextSizes;
  /** Text to be displayed */
  children: ReactNode;
  /** Color as a string (`"green"`, `"#a9b9c9"`) */
  color?: string;
  /** Bold text or normal text */
  bold?: boolean;
  /** classNames to pass on to elements */
  className?: string;
}

export default Text;
