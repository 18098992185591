export const getPartialGymsOfGymChain = /* GraphQL */ `
  query GetGymsOfGymChain(
    $id: Int!
    $offset: Int!
    $pageSize: Int!
    $sortField: String!
    $sortOrder: String!
    $userId: Int!
  ) {
    getGymsOfGymChain(
      id: $id
      offset: $offset
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
      userId: $userId
    ) {
      items {
        gymId
        gymName
        status
      }
    }
  }
`;
