import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Loader from '@gym-atoms/Loader/Loader';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { Zone, ZoneTable } from '@gym-particles/types/models';
import { UserRole } from '@gym-particles/types/User';
import { fetchZones, deleteZone } from '@gym-redux/slices/zoneSlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@gym-atoms/Text/Text';
import { API } from 'aws-amplify';
import { isZoneLinked } from '@gym-graphql/queries';
import { CountResponse } from '@gym-src/API';

const ZoneList = (props: ZoneListProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [selectedZonesRow, setSelectedZonesRow] = useState<ZoneTable>();
  const [zoneSortField, setZoneSortField] = useState('id');
  const [zoneSortOrder, setZoneSortOrder] = useState(-1);
  const [zoneOffset, setZoneOffset] = useState(0);
  const [zonePageSize, setZonePageSize] = useState(10);
  const isZonesLoading = useAppSelector((state) => state.zone.isZonesLoading);
  const [zoneSearchTerm, setZoneSearchTerm] = useState('');
  const [zoneDeletableCheckLoading, setZoneDeletableCheckLoading] = useState(false);
  const [zoneDeletableStatus, setZoneDeletableStatus] = useState(false);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const userId = useAppSelector((state) => state.user.userId);
  const userRole = useAppSelector((state) => state.user.userRole);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteParams = {
    id: selectedZonesRow?.id,
    userId: userId
  };
  const totalZonesCount =
    useAppSelector(
      (state) => state.zone.items.find((g) => g.siteLocationId === props.gymId)?.totalRecords
    ) || 0;

  useEffect(() => {
    dispatch(
      fetchZones({
        siteLocationId: props.gymId
      })
    );
  }, []);

  const templateTypeChangeHandler = useCallback(
    (state) => {
      props.templateType(state);
    },
    [props.templateType]
  );

  const templateZoneHandler = useCallback(
    (state) => {
      props.templateZone(state);
    },
    [props.templateZone]
  );

  const mapZones = (zones?: Zone[]) => {
    if (!zones) {
      return;
    }

    const mappedZones: ZoneTable[] = [];

    zones.forEach((zone) => {
      const index = mappedZones.findIndex((mappedZone) => {
        return mappedZone.id === zone.id;
      });

      if (index > -1) {
        mappedZones[index].zoneDetails.push({
          accessCategoryZoneId: zone.locationAccessCategoryZoneId,
          accessCategoryId: zone.locationAccessCategoryId,
          accessCategoryName: zone.locationAccessCategoryName,
          gender: zone.locationAccessCategoryGender,
          accessCategoryArxRefId: zone.locationAccessCategoryArxRefId
        });
      } else {
        mappedZones.push({
          id: zone.id,
          siteLocationId: zone.siteLocationId,
          zoneName: zone.zoneName,
          zoneDescription: zone.zoneDescription,
          arxReferenceId: zone.arxReferenceId,
          status: zone.status,
          createdBy: zone.createdBy,
          createdDate: zone.createdDate,
          lastModifiedBy: zone.lastModifiedBy,
          lastModifiedDate: zone.lastModifiedDate,
          displayStatus: zone.displayStatus,
          zoneDetails: [
            {
              accessCategoryZoneId: zone.locationAccessCategoryZoneId,
              accessCategoryId: zone.locationAccessCategoryId,
              accessCategoryName: zone.locationAccessCategoryName,
              gender: zone.locationAccessCategoryGender,
              accessCategoryArxRefId: zone.locationAccessCategoryArxRefId
            }
          ]
        });
      }
    });

    return mappedZones;
  };

  const zones = useAppSelector((state) =>
    mapZones(state.zone.items.find((e) => e.siteLocationId === props.gymId)?.items)
  );

  const menuItem = [
    {
      label: t('COMMON.MODIFY'),
      command: () => {
        templateTypeChangeHandler(true);
        templateZoneHandler(selectedZonesRow);
      }
    },
    {
      label: t('COMMON.DELETE'),
      command: () => {
        templateTypeChangeHandler(false);
        checkZoneDeletable();
      }
    }
  ];

  const checkZoneDeletable = async () => {
    setIsDeleteDialogVisible(true);
    setZoneDeletableCheckLoading(true);
    try {
      const response = await (API.graphql({
        query: isZoneLinked,
        variables: {
          locationZoneId: selectedZonesRow?.id
        }
      }) as Promise<{
        data: { isZoneLinked: CountResponse };
      }>);
      if (
        response.data.isZoneLinked.count !== undefined &&
        response.data.isZoneLinked.count !== null
      ) {
        response.data.isZoneLinked.count > 0
          ? setZoneDeletableStatus(false)
          : setZoneDeletableStatus(true);
      } else {
        console.log('Error Delete Validation Query');
        setErrorDialogVisible(true);
      }
      setZoneDeletableCheckLoading(false);
    } catch (error) {
      setErrorDialogVisible(true);
      setIsDeleteDialogVisible(false);
      console.log('Catch: ', error);
      setZoneDeletableStatus(false);
      setZoneDeletableCheckLoading(false);
    }
  };

  const tablePropsZones: TableProps<ZoneTable> = {
    exportFileName: t('ZONES.EXPORT_EXCEL_FILE_NAME'),
    setPageSize: setZonePageSize,
    setOffset: setZoneOffset,
    pageSize: zonePageSize,
    offset: zoneOffset,
    setSortField: setZoneSortField,
    setSortOrder: setZoneSortOrder,
    sortField: zoneSortField,
    sortOrder: zoneSortOrder,
    totalRecords: totalZonesCount,
    uniqueFieldName: 'id',
    data: zones || undefined,
    headerText: props.heading,
    searchTerm: zoneSearchTerm,
    setSearchTerm: setZoneSearchTerm,
    columns: [
      {
        field: 'zoneName',
        header: t('ZONES.TABLE.HEADER_ZONES_NAME'),
        toggable: false,
        truncateText: true,
        sortable: true
      },
      {
        field: 'zoneDescription',
        header: t('ZONES.TABLE.HEADER_ZONES_DESCRIPTION'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'arxReferenceId',
        header: t('ZONES.TABLE.HEADER_ARX_REF_ID'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'displayStatus',
        header: t('ZONES.TABLE.HEADER_ZONES_STATUS'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'createdDate',
        header: t('ZONES.TABLE.HEADER_ZONES_CREATED_DATE'),
        toggable: true,
        truncateText: true
      }
    ],
    searchPlaceholderText: t('ZONES.SEARCH_FIELD_TEXT'),
    excelBtntext: t('ZONES.EXPORT_EXCEL_BUTTON_LABEL'),
    selectedRow: selectedZonesRow,
    setSelectedRow: setSelectedZonesRow,
    isContextMenu:
      userRole === UserRole.SYSTEM_ADMIN
        ? true
        : userRole === UserRole.GYM_USER
        ? true
        : userRole === UserRole.GYM_CHAIN_USER
        ? true
        : false,
    menuItem:
      userRole === UserRole.SYSTEM_ADMIN
        ? menuItem
        : userRole === UserRole.GYM_USER
        ? menuItem
        : userRole === UserRole.GYM_CHAIN_USER
        ? menuItem
        : [],
    lazy: false,
    expandable: true,
    expandableField: 'zoneDetails',
    emptyStateTexts: [t('ZONES.EMPTY_STATE_TEXT')],
    expandedCols: [
      {
        field: 'accessCategoryName',
        header: t('ZONES.TABLE.HEADER_ACCESS_CATEGORY_NAME'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'gender',
        header: t('ZONES.TABLE.HEADER_GENDER'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'accessCategoryArxRefId',
        header: t('ZONES.TABLE.HEADER_ARX_REF_ID'),
        toggable: true,
        truncateText: true
      }
    ]
  };

  const deleteDialogHandler = async () => {
    setIsDeleting(true);
    selectedZonesRow && (await dispatch(deleteZone(deleteParams)));
    setIsDeleting(false);
    setIsDeleteDialogVisible(false);
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        {zoneDeletableCheckLoading ? (
          <></>
        ) : !zoneDeletableStatus ? (
          <Button
            label={t('COMMON.OK')}
            variant={ButtonVariants.basic}
            onClick={() => setIsDeleteDialogVisible(false)}
          />
        ) : (
          <>
            <Button
              data-cy={'zone-dialog-cancel-btn'}
              label={t('COMMON.CANCEL')}
              variant={ButtonVariants.textonly}
              onClick={() => setIsDeleteDialogVisible(false)}
            />
            <Button
              data-cy={'zone-dialog-delete-btn'}
              label={t('COMMON.DELETE')}
              variant={ButtonVariants.danger}
              onClick={deleteDialogHandler}
              icon={isDeleting ? 'pi-spinner pi-spin' : ''}
            />
          </>
        )}
      </div>
    );
  };

  const ErrorDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.OK')}
          variant={ButtonVariants.textonly}
          onClick={() => setErrorDialogVisible(false)}
        />
      </div>
    );
  };

  return (
    <div>
      <DialogBox
        dialogVisible={isDeleteDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setIsDeleteDialogVisible(false)}
        dialogFooter={<DeleteDialogFooter />}
        dialogClosable={false}
        dialogHeader={zoneDeletableCheckLoading ? <></> : t('COMMON.DELETE')}
        content={
          zoneDeletableCheckLoading ? (
            <>
              <div className="mb-3">
                <Loader shape="rectangle" width="full" />
              </div>
              <div className="mb-3">
                <Loader shape="rectangle" width="full" />
              </div>
              <div className="mb-3">
                <Loader shape="rectangle" width="half" />
              </div>
            </>
          ) : (
            <Text data-cy={'zone-dialog-content'}>
              {zoneDeletableStatus
                ? `${t('ZONES.DELETE_MODAL_CONFIRM')} ${selectedZonesRow?.zoneName}?`
                : t('ZONES.DELETE_MODAL_CANNOT')}
            </Text>
          )
        }
      />
      <DialogBox
        dialogVisible={errorDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setErrorDialogVisible(false)}
        dialogFooter={<ErrorDialogFooter />}
        dialogClosable={false}
        dialogHeader={t('ZONES.ERROR_HEADER')}
        content={<Text>{t('ZONES.ERROR_MESSAGE')}</Text>}
      />
      {isZonesLoading ? <Loader shape="table" /> : <Table {...tablePropsZones} />}
    </div>
  );
};

export default ZoneList;

interface ZoneListProps {
  gymId: number;
  templateType: (e: boolean) => void;
  templateZone: (e: ZoneTable) => void;
  heading?: string;
}
