import { ListBox as PrimeListBox } from 'primereact/listbox';
import { useEffect, useState } from 'react';

const ListBox = <T extends Record<string, any>>({
  multiSelect = false,
  ...props
}: ListBoxProps<T>) => {
  const [checked, setChecked] = useState<boolean>(false);

  // const selectAllFn = (e: CheckboxChangeEvent) => {
  //   setChecked(e.checked);

  //   if (props.optionGroupChildren) {
  //     const allSelected = props.options
  //       .filter((e) => e[props.optionGroupChildren || ''])
  //       .map((e) => e.items.map((i: T) => i[props.optionGroupLabel || '']))
  //       .reduce((arr1, arr2) => arr1.concat(arr2), []);

  //     e.checked ? props.setSelected(allSelected) : props.setSelected([]);
  //   } else {
  //     e.checked ? props.setSelected(props.options) : props.setSelected([]);
  //   }
  // };

  useEffect(() => {
    if (props.options.length === 0) {
      setChecked(false);
      return;
    }
    if (props.optionGroupChildren) {
      const allSelectedValues = props.options
        .filter((e) => {
          return e[props.optionGroupChildren || ''];
        })
        .map((e) => {
          return e[props.optionGroupChildren || ''].map((i: T) => i[props.optionGroupLabel || '']);
        })
        .reduce((arr1, arr2) => arr1.concat(arr2), []);

      props.value.length === allSelectedValues.length ? setChecked(true) : setChecked(false);
    } else {
      props.value.length === props.options.length ? setChecked(true) : setChecked(false);
    }
  }, [props.value]);

  return (
    <>
      <PrimeListBox
        {...props}
        value={props.value}
        onChange={(e) => props.setSelected(e.value)}
        filter
        multiple={multiSelect}
        className={props.options.length !== 0 ? '' : 'p-empty-state'}></PrimeListBox>
      {/* <Checkbox
        label={props.checkBoxLabel || ''}
        onChange={(e) => selectAllFn(e)}
        checked={checked}
      /> */}
    </>
  );
};

/**
 * This is PrimeReact's event object for onChange, taken from source
 * (primereact/components/listbox/ListBox.d.ts)
 */
export type ListBoxChangeEvent = {
  originalEvent: Event;
  value: any;
  target: { name: string; id: string; value: any };
};

export interface ListBoxProps<T> {
  /** value of selected items */
  value: Array<T>;
  /**An array of objects to display as the available options. */
  options: Array<T>;
  /** Name of the label field of an option */
  optionLabel: string;
  /** template for a item */
  itemTemplate?: (option: T) => JSX.Element;
  /** label when grouping */
  optionGroupLabel?: string;
  /** children when grouping */
  optionGroupChildren?: string;
  /** template when grouping */
  optionGroupTemplate?: (option: T) => JSX.Element;
  /** enable multiple selection */
  multiSelect?: boolean;
  /** select all checkbox label */
  checkBoxLabel?: string;
  /** function to set selected items */
  setSelected: (e: T[]) => void;
}

export default ListBox;
