import { useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';

import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { AlertType } from '@gym-atoms/Alert/Alert';
import GymChainInfoTemplate from '@gym-templates/GymChainInfo/GymChainInfoTemplate';
import { useAppSelector, useAppDispatch } from '@gym-redux/store';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';
import Loader from '@gym-atoms/Loader/Loader';

const MainColumn = () => {
  const { chainId } = useParams<{ chainId: string }>();
  const gymChain = useAppSelector((state) => state.gymChain.items.find((g) => g.id === +chainId));
  const fetchFailed = useAppSelector((state) => state.gymChain.fetchGymChainByIdFailed);
  const dispatch = useAppDispatch();
  const history = useHistory();

  /** Fetches the gym chain for this page */
  useEffect(() => {
    dispatch(getGymChainById(+chainId));
  }, []);

  /** When an invalid gym chain ID is entered, redirect to gymChains list */
  useEffect(() => {
    if (fetchFailed) {
      history.push('/gymChains');
    }
  }, [fetchFailed]);

  if (gymChain) {
    return (
      <Card className="pt30 pl40 pr40 pb30">
        <GymChainInfoTemplate type="modify" gymChain={gymChain} />
      </Card>
    );
  } else {
    return (
      <Card className="pt30 pl40 pr40 pb30">
        <div className="mr15 mb18">
          <Loader shape="rectangle" />
        </div>
        <div className="mr15 mb18">
          <Loader shape="rectangle" />
        </div>
        <div className="mr15 mb18">
          <Loader shape="rectangle" />
        </div>
      </Card>
    );
  }
};

const ModifyGymChainPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  return (
    <TwoColTemplate
      title={t('MODIFY_GYM_CHAIN.PAGE_TITLE')}
      col1={<MainColumn />}
      alertRef={alertRef}
      backButtonHandler={() => history.push('/gymChains')}
    />
  );
};

export default ModifyGymChainPage;
