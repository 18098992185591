import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { Member, MemberAccessCard } from '@gym-particles/types/Member';
import { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import Loader from '@gym-atoms/Loader/Loader';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { fetchMembers } from '@gym-redux/slices/memberSlice';
import { useLocation, useParams, useHistory } from 'react-router';
import MemberDetailsModal from '@gym-organisms/MemberDetailsModal/MemberDetailsModal';
import AccessCardIssueModal from '@gym-organisms/AccessCards/AccessCardIssueModal';
import AccessCardDeactivateModal from '@gym-organisms/AccessCards/AccessCardDeactivateModal';
import { fetchAccessPoints } from '@gym-redux/slices/accessPointsSlice';
import { AccessPoints } from '@gym-particles/types/AccessPoints';
import TemporaryAccessModal from '@gym-organisms/TemporaryAccessModal/TemporaryAccessModal';
import { UserRole } from '@gym-particles/types/User';
import Button, { ButtonSizes } from '@gym-atoms/Button/Button';
import Input from '@gym-atoms/Input/Input';
import { Toast } from 'primereact/toast';
import { manualMemberImport } from '@gym-graphql/mutations';
import { API } from 'aws-amplify';
import { LambdaResponse, ManualMemberImportParams } from '@gym-src/API';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';
import { getGymById } from '@gym-redux/slices/gymsSlice';
import Text from '@gym-atoms/Text/Text';

const MembersPage = () => {
  const { t } = useTranslation();
  const isMembersLoading = useAppSelector((state) => state.member.isMembersLoading);
  const dispatch = useAppDispatch();
  const { chainId, gymId } = useParams<{ chainId: string; gymId: string }>();
  const history = useHistory();
  const userRole = useAppSelector((state) => state.user.userRole);
  const queryString = new URLSearchParams(useLocation().search);
  const queryValue = queryString.get('modal');

  const gymName = useAppSelector(
    (state) =>
      state.gyms.items.find((g) => g.chainId === +chainId)?.items.find((a) => a.gymId === +gymId)
        ?.gymName
  );
  const totalMemberCount =
    useAppSelector(
      (state) => state.member.items.find((g) => g.locationId === +gymId)?.totalRecords
    ) || 0;

  const [membersSortField, setMembersSortField] = useState('createdDate');
  const [membersSortOrder, setMembersSortOrder] = useState(-1);
  const [membersOffset, setMembersOffset] = useState(0);
  const [membersPageSize, setMembersPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMemberRow, setSelectedMemberRow] = useState<Member>();
  const [memberDetailsModalVisible, setMemberDetailsModalVisible] = useState(false);
  const [memberDetailsModalData, setMemberDetailsModalData] = useState<Member>();
  const [accessCardIssueModalVisible, setaccessCardIssueModalVisible] = useState(false);
  const [accessCardDeactivateModalVisible, setAccessCardDeactivateModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [manualImportingInProgress, setManualImportingInProgress] = useState(false);
  const [manualImportValue, setManualImportValue] = useState<string>('');
  const [manualImportError, setManualImportError] = useState<string>('');
  const manualImportToast = useRef<any>();
  const gymChain = useAppSelector((state) => state.gymChain.items.find((c) => c.id === +chainId));
  const currentUser = useAppSelector((state) => state.user);
  const modalToggle = () => setModalVisible(!modalVisible);
  const [forceResync, setForceResync] = useState(0);

  useEffect(() => {
    dispatch(
      fetchMembers({
        locationId: +gymId,
        pagination: {
          offset: membersOffset,
          pageSize: membersPageSize,
          sortField:
            {
              signedupDate: 'DATE(signedupDate)',
              displayIsImported: 'uniqueId',
              displayHasForcedAccess: 'hasForcedAccess',
              displayIsFreepassMember: 'isFreepassMember'
            }[membersSortField] || membersSortField,
          sortOrder: membersSortOrder,
          search: { searchField: '', searchText: searchTerm }
        }
      })
    );
  }, [
    membersSortOrder,
    membersSortField,
    membersPageSize,
    membersOffset,
    searchTerm,
    gymId,
    accessCardDeactivateModalVisible,
    accessCardIssueModalVisible,
    forceResync
  ]);

  useEffect(() => {
    if (searchTerm === '') {
      setMembersOffset(0);
    }
  }, [searchTerm]);

  /* When the page is loaded directly from the url, 
  the chain data and gym data is not loading
  therefore, adding the dispatch calls.
  */
  useEffect(() => {
    dispatch(getGymChainById(+chainId));
    dispatch(getGymById({ gymId: +gymId, gymChainId: +chainId }));
  }, [chainId, gymId]);

  useEffect(() => {
    if (!modalVisible) {
      dispatch(
        fetchMembers({
          locationId: +gymId,
          pagination: {
            offset: searchTerm !== '' ? 0 : membersOffset,
            pageSize: membersPageSize,
            sortField: membersSortField,
            sortOrder: membersSortOrder,
            search: { searchField: '', searchText: searchTerm }
          }
        })
      );
    }
  }, [modalVisible]);

  useEffect(() => {
    dispatch(
      fetchAccessPoints({
        siteLocationId: +gymId
      })
    );
  }, [gymId]);

  const detailsModalOnHide = () => {
    setMemberDetailsModalVisible(!memberDetailsModalVisible);

    // If modal was opened automatically, need to reset the URL
    if (queryValue) {
      history.push(`/accounts`);
    }
  };

  const accessTypeBodyTemplate = (row: Member) => {
    return (
      <div className="d-flex">
        {!row.accessTypes.isMobileAppDisabled && (
          <span
            title={t('MEMBER_LIST.TABLE.MOBILE_ACCESS_TOOLTIP_TEXT')}
            className="modal-icon icon-mobile me-2"></span>
        )}
        {row.hasAccessCard && <span className="modal-icon icon-signal me-2"></span>}
        {/* Will enable below icon later if necessary  */}
        {/* {!row.accessTypes.isJustTapRevoked && (
          <span
            title={t('MEMBER_LIST.TABLE.JUST_TAP_ACCESS_TOOLTIP_TEXT')}
            className="modal-icon icon-credentials"></span>
        )} */}
      </div>
    );
  };

  const isBlockedBodyTemplate = (row: Member) => {
    return (
      <span>
        {row.isBlocked && <span className="modal-icon icon-padlock icon-h-100 ms-5"></span>}
      </span>
    );
  };

  const members = useAppSelector(
    (state) => state.member.items.find((g) => g.locationId === +gymId)?.items
  );

  useEffect(() => {
    if (memberDetailsModalVisible) {
      setMemberDetailsModalData(selectedMemberRow);
    }
  }, [memberDetailsModalVisible]);

  /**
   * Updates the modal if table refreshes while the modal is open.
   * This could happen when 'resync' button is clicked.
   */
  useEffect(() => {
    if (memberDetailsModalVisible) {
      const updated = members?.find((memb) => memb.id === selectedMemberRow?.id);
      setMemberDetailsModalData(updated);
    }

    // Handling when url has a query param to open the modal automatically
    if (queryValue) {
      const modalData = members?.find((member) => member.id === +queryValue);
      setMemberDetailsModalData(modalData);
      setMemberDetailsModalVisible(true);
    }
  }, [isMembersLoading]);

  const showForcedAccess = () => {
    setModalVisible(true);
  };

  const manualImportInputChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.trim();
    if (value.length === 0) {
      setManualImportError('');
    } else {
      setManualImportError('');
    }
    setManualImportValue(value);
  };

  useEffect(() => {
    if (manualImportingInProgress) {
      manualImportHandler();
    }
  }, [manualImportingInProgress]);

  const manualImportHandler = async () => {
    try {
      const params: ManualMemberImportParams = {
        clientId: manualImportValue,
        customerSiteId: gymChain?.customerSiteId || 0,
        siteId: gymChain?.id || 0,
        userId: currentUser.userId
      };
      const response = await (API.graphql({
        query: manualMemberImport,
        variables: { input: params }
      }) as Promise<{
        data: { manualMemberImport: LambdaResponse };
      }>);
      let toastMsg = '';
      let toastType = '';
      if (response.data.manualMemberImport && !response.data.manualMemberImport.error) {
        toastType = 'success';
        toastMsg = t('MEMBER_LIST.MANUAL_IMPORT_SUCCESS_MESSAGE');
        setManualImportValue('');
      } else {
        const errorType = response.data.manualMemberImport.errorType;
        if (errorType === 'CLIENT_EXISTS') {
          toastType = 'error';
          toastMsg = `${t(
            'MEMBER_LIST.MANUAL_IMPORT_CLIENT_EXISTS_MESSAGE_1'
          )} ${manualImportValue} ${t('MEMBER_LIST.MANUAL_IMPORT_CLIENT_EXISTS_MESSAGE_2')}`;
        } else if (errorType === 'CLIENT_NOT_FOUND') {
          toastType = 'error';
          toastMsg = `${t(
            'MEMBER_LIST.MANUAL_IMPORT_INVALID_CLIENT_MESSAGE_1'
          )} ${manualImportValue} ${t('MEMBER_LIST.MANUAL_IMPORT_INVALID_CLIENT_MESSAGE_2')}`;
        } else {
          throw response;
        }
      }
      manualImportToast.current.show({
        severity: toastType,
        summary: toastMsg,
        life: 3000
      });
      setTimeout(() => {
        history.push(`/gymChains/${chainId}/gyms/${gymId}/dashboard/visits`);
        history.push(`/gymChains/${chainId}/gyms/${gymId}/members`);
      }, 5000);
    } catch (error) {
      console.error('Manual import error', error);
      manualImportToast.current.show({
        severity: 'error',
        summary: t('MEMBER_LIST.MANUAL_IMPORT_UNKNOWN_ERROR'),
        life: 3000
      });
    } finally {
      setManualImportingInProgress(false);
    }
  };

  const tablePropsMembers = useMemo<TableProps<Member>>(
    () => ({
      onRowClick: () => setMemberDetailsModalVisible(true),
      headerBtns: [
        gymChain?.providerId === 1 ? (
          <div className="d-flex flex-column fs-6">
            <div className="d-flex justify-content-between align-items-center">
              <Input
                key={1}
                name="clientId"
                label={t('MEMBER_LIST.MANUAL_IMPORT_LABEL')}
                onChange={manualImportInputChangeHandler}
                noMarginBottom={true}
                error={manualImportError.length > 0 ? true : false}
                errorMessage={manualImportError}
                variant="basic"
              />
              <div className={`mt15 ml10 ${manualImportError.length > 0 ? 'mb20' : ''}`}>
                <Button
                  key={2}
                  icon={manualImportingInProgress ? 'pi-spinner pi-spin' : null}
                  label={t('MEMBER_LIST.MANUAL_IMPORT_BUTTON')}
                  size={ButtonSizes.medium}
                  onClick={() => setManualImportingInProgress(true)}
                  disabled={manualImportingInProgress}
                />
              </div>
            </div>
            <Toast ref={manualImportToast} />
          </div>
        ) : (
          <></>
        )
      ],
      headerBtnAlignment: 'SearchBottom',
      enableRowClick: true,
      exportFileName: `${gymName}_${t('MEMBER_LIST.EXPORT_EXCEL_FILE_NAME')}`,
      data: members || undefined,
      headerText: t('MEMBER_LIST.TABLE_HEADING_MEMBERS'),
      searchPlaceholderText: t('MEMBER_LIST.SEARCH_FIELD_TEXT'),
      excelBtntext: t('MEMBER_LIST.EXPORT_EXCEL_BUTTON_LABEL'),
      totalRecords: totalMemberCount,
      selectedRow: selectedMemberRow,
      setSelectedRow: setSelectedMemberRow,
      setPageSize: setMembersPageSize,
      setOffset: setMembersOffset,
      pageSize: membersPageSize,
      offset: membersOffset,
      setSortField: setMembersSortField,
      setSortOrder: setMembersSortOrder,
      sortField: membersSortField,
      sortOrder: membersSortOrder,
      searchTerm: searchTerm,
      setSearchTerm: setSearchTerm,
      emptyStateTexts: [t('MEMBER_LIST.EMPTY_STATE_TEXT')],
      columns: [
        {
          field: 'memberFullName',
          header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_NAME'),
          toggable: false,
          truncateText: false,
          sortable: true,
          imageField: 'imageUrl'
        },
        {
          field: 'uniqueId',
          header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_UNIQUE_ID'),
          toggable: false,
          truncateText: true
        },
        {
          field: 'accessTypes',
          header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_TYPE_OF_ACCESS'),
          toggable: true,
          truncateText: true,
          bodyTemplate: (data) => accessTypeBodyTemplate(data)
        },
        {
          field: 'signedupDate',
          header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_SIGNED_UP_ON_GYMXS'),
          toggable: true,
          sortable: true,
          truncateText: true
        },
        {
          field: 'email',
          header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_EMAIL'),
          toggable: true,
          truncateText: false,
          sortable: false
        },
        {
          field: 'displayIsImported',
          header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_IS_IMPORTED'),
          toggable: true,
          truncateText: true,
          sortable: true
        },
        {
          field: 'displayHasForcedAccess',
          header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_HAS_FORCED_ACCESS'),
          toggable: true,
          truncateText: true,
          sortable: true
        },
        {
          field: 'displayIsFreepassMember',
          header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_IS_FREEPASS_MEMBER'),
          toggable: true,
          truncateText: true,
          sortable: true
        },
        {
          field: 'isBlocked',
          header: '',
          bodyTemplate: (data) => isBlockedBodyTemplate(data),
          toggable: false,
          truncateText: true,
          additionalClassName: 'p-0'
        }
      ],
      isContextMenu: true,
      menuItem:
        userRole === UserRole.GYM_USER
          ? [
              {
                label: t('MEMBER_LIST.CONTEXT_MENU.ISSUE_ACCESS_CARD'),
                command: () => setaccessCardIssueModalVisible(true)
              },
              {
                label: t('MEMBER_LIST.CONTEXT_MENU.DEACTIVATE_ACCESS_CARD'),
                command: () => setAccessCardDeactivateModalVisible(true)
              },
              {
                label: t('MEMBER_LIST.CONTEXT_MENU.GIVE_FORCED_ACCESS'),
                command: () => showForcedAccess()
              }
            ]
          : [
              {
                label: t('MEMBER_LIST.CONTEXT_MENU.GIVE_FORCED_ACCESS'),
                command: () => showForcedAccess()
              }
            ]
    }),
    [members, manualImportingInProgress]
  );

  const accesspoints = useAppSelector(
    (state) => state.accessPoint.items.find((gym) => gym.siteLocationId === +gymId)?.items
  );

  const doors: AccessPoints[] = [];

  accesspoints?.forEach((point) => {
    if (point.accessPointType === 'door') {
      doors.push(point);
    }
  });

  const selectedMemberForAccessCard: MemberAccessCard = {
    memberId: selectedMemberRow?.id || 0,
    userId: selectedMemberRow?.userId || 0,
    arxReferenceId: selectedMemberRow?.arxReferenceId,
    firstName: selectedMemberRow?.memberFirstName || '',
    lastName: selectedMemberRow?.memberLastName || '',
    hasAccessCard: selectedMemberRow?.hasAccessCard || false,
    imageUrl: selectedMemberRow?.imageUrl || ''
  };

  return (
    <>
      <Card className="p-card-w-table">
        {isMembersLoading ? <Loader shape="table" /> : <Table {...tablePropsMembers} />}
        {memberDetailsModalData && (
          <MemberDetailsModal
            visible={memberDetailsModalVisible}
            modalOnHide={detailsModalOnHide}
            member={memberDetailsModalData}
            setForceResync={setForceResync}
          />
        )}
        {
          <AccessCardIssueModal
            visible={accessCardIssueModalVisible}
            accessPoints={doors}
            onHideCallBack={() => setaccessCardIssueModalVisible(false)}
            member={selectedMemberForAccessCard}
          />
        }

        {
          <AccessCardDeactivateModal
            visible={accessCardDeactivateModalVisible}
            onHideCallBack={() => setAccessCardDeactivateModalVisible(false)}
            member={selectedMemberForAccessCard}
          />
        }
        {selectedMemberRow && modalVisible && (
          <TemporaryAccessModal
            visible={modalVisible}
            onHideCallback={modalToggle}
            gymId={+gymId}
            memberId={selectedMemberRow?.id || 0}
            memberLocationId={selectedMemberRow.memberLocationId}
          />
        )}
      </Card>
    </>
  );
};

export default MembersPage;
