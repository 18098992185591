import DaySchedulesList from '@gym-organisms/DaySchedules/DaySchedulesList';
import ManageDaySchedulesForm from '@gym-organisms/ManageDaySchedules/ManageDaySchedulesForm';
import { Card } from 'primereact/card';
import { useEffect, useRef, useState } from 'react';
import { DaySchedule, RequestDaySchedules } from '@gym-particles/types/DaySchedules';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@gym-redux/store';
import { UserRole } from '@gym-particles/types/User';

const ManageDaySchedulesPage = () => {
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { t } = useTranslation();
  const [isModifyTemplate, setIsModifyTemplate] = useState(false);
  const [daySchedules, setDaySchedules] = useState<DaySchedule>();
  const userRole = useAppSelector((state) => state.user.userRole);
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const scheduleRequest = useAppSelector((state) =>
    state.scheduleRequests.items.find((g) => g.scheduleId === +scheduleId)
  );

  const [requestDaySchedule, setRequestDaySchedule] = useState<RequestDaySchedules>();

  const createDaySchedule = async () => {
    const items = {
      scheduleId: scheduleRequest?.scheduleId || 0,
      weekdayId: scheduleRequest?.weekdayId || 0,
      startTime: scheduleRequest?.startTime || '',
      endTime: scheduleRequest?.endTime || ''
    };

    setRequestDaySchedule(items);
  };

  useEffect(() => {
    createDaySchedule();
  }, []);

  return (
    <>
      <TwoColTemplate
        backButtonHandler={
          scheduleId ? () => history.push('/scheduleRequests') : () => history.push('/dashboard')
        }
        col1={
          <div>
            {userRole === UserRole.SYSTEM_ADMIN && (
              <Card className="p-card-w-table">
                <ManageDaySchedulesForm
                  type={isModifyTemplate ? 'modify' : 'new'}
                  dayScheduleData={isModifyTemplate ? daySchedules : undefined}
                  requestDaySchedule={
                    isModifyTemplate === false && scheduleId ? requestDaySchedule : undefined
                  }
                  templateType={setIsModifyTemplate}
                />
              </Card>
            )}
            <Card className="p-card-w-table">
              <DaySchedulesList templateType={setIsModifyTemplate} templateDay={setDaySchedules} />
            </Card>
          </div>
        }
        title={t('DAYSCHEDULES.PAGE_TITLE')}
        alertRef={alertRef}
      />
    </>
  );
};

export default ManageDaySchedulesPage;
