import { API, Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RESET_STAGE_CODE, RESET_STAGE_USERNAME } from '@gym-particles/passwordResetStages';
import { login } from '@gym-redux/slices/userSlice';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Input from '@gym-atoms/Input/Input';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import AuthTemplate from '@gym-templates/Authentication/AuthTemplate';
import { LoggedInUser } from '@gym-src/API';
import { getLoggedInUserWeb } from '@gym-graphql/queries';
import { UserRole } from '@gym-particles/types/User';

const SignIn: React.FC = (props) => {
  const emptyError = {
    username: {
      error: false,
      message: ''
    },
    password: {
      error: false,
      message: ''
    },
    global: {
      error: false,
      message: ''
    }
  };

  const { t, i18n } = useTranslation();

  const helperText = t('AUTH_SIGNIN.SIGNIN_HELPER_TEXT_ONE');
  const helperTextMiddle = t('AUTH_SIGNIN.SIGNIN_HELPER_TEXT_TWO');
  const helperTextBack = t('AUTH_SIGNIN.SIGNIN_HELPER_TEXT_THREE');

  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [formDisabled, setFormDisabled] = useState(true);
  const [error, setError] = useState(emptyError);
  const [loading, setLoading] = useState(false);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    setFormData({ ...formData, [element.name]: element.value.trim() });
  };

  useEffect(() => {
    formDisableCheck();
  }, [formData]);

  const formDisableCheck = () => {
    if (formData.username && formData.password) {
      setFormDisabled(false);
    } else {
      setFormDisabled(true);
    }
  };

  const validateSignIn = (username: string, password: string) => {
    const errors = {
      username: {
        error: false,
        message: ''
      },
      password: {
        error: false,
        message: ''
      },
      global: {
        error: false,
        message: ''
      }
    };

    if (!username || username.length === 0) {
      errors.username.error = true;
      errors.username.message = t('AUTH_COMMON.EMAIL_EMPTY_ERROR');
    }

    if (!password || password.length === 0) {
      errors.password.error = true;
      errors.password.message = t('AUTH_COMMON.PASSWORD_EMPTY_ERROR');
    }

    return errors;
  };

  const handleSignIn = (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    setError({ ...emptyError });

    const formErrors = validateSignIn(formData.username, formData.password);

    if (formErrors.username.error || formErrors.password.error || formErrors.global.error) {
      setError(formErrors);
      return;
    }

    setLoading(true);

    Auth.signIn(formData.username, formData.password)
      .then(async (user) => {
        setLoading(false);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch(
            login({
              userName: formData.username,
              isLogged: false,
              cognitoUser: user,
              userFirstName: '',
              userLastName: '',
              // Query error is handled through VTL and it'll go to the catch block
              userId: -1,
              roleId: -1,
              userRole: '',
              language: 'en',
              siteCount: -1,
              locationCount: -1
            })
          );
          history.push('/initial-reset');
        } else {
          const result = await (API.graphql({
            query: getLoggedInUserWeb,
            variables: {
              email: user.attributes.email
            }
          }) as Promise<{
            data: { getLoggedInUserWeb: LoggedInUser };
          }>);

          dispatch(
            login({
              userName: formData.username,
              isLogged: true,
              cognitoUser: user,
              // Query error is handled through VTL and it'll go to the catch block
              userId: result.data.getLoggedInUserWeb.userId || -1,
              roleId: result.data.getLoggedInUserWeb.roleId || -1,
              userRole:
                result.data.getLoggedInUserWeb.roleName == 'system admin'
                  ? UserRole.SYSTEM_ADMIN
                  : result.data.getLoggedInUserWeb.roleName == 'gym chain admin'
                  ? UserRole.GYM_CHAIN_USER
                  : result.data.getLoggedInUserWeb.roleName == 'gym admin'
                  ? UserRole.GYM_USER
                  : '',
              userFirstName: result.data.getLoggedInUserWeb.firstName || '',
              userLastName: result.data.getLoggedInUserWeb.lastName || '',
              language: result.data.getLoggedInUserWeb.language || '',
              siteCount: result.data.getLoggedInUserWeb.siteCount || -1,
              locationCount: result.data.getLoggedInUserWeb.locationCount || -1
            })
          );
          i18n.changeLanguage(result.data.getLoggedInUserWeb.language?.toLowerCase());
          const gymChainId = result.data.getLoggedInUserWeb.siteCount;
          const gymLocationId = result.data.getLoggedInUserWeb.locationCount;

          if (result.data.getLoggedInUserWeb.roleName === 'gym admin') {
            if (gymChainId != null && gymLocationId != null) {
              history.push(`/gymChains/${gymChainId}/gyms/${gymLocationId}/dashboard`);
            } else if (gymChainId != null && gymLocationId === null) {
              history.push(`/gymChains/${gymChainId}/gyms`);
            } else {
              history.push(`/gymChains`);
            }
          }
        }
      })
      .catch((err) => {
        console.log('Sign In Error: ', err);

        const error = { ...emptyError };
        error.global.error = true;

        if (err.code === 'PasswordResetRequiredException') {
          error.global.message = t('AUTH_SIGNIN.PASSWORD_NEEDS_RESETTING_ERROR');
          setError(error);

          setTimeout(() => {
            setLoading(false);
            history.push('/forgot-password', {
              username: formData.username,
              stage: RESET_STAGE_CODE.STAGE,
              helperText: t(RESET_STAGE_CODE.HELPER_TEXT)
            });
          }, 3000);
        } else {
          setLoading(false);
          error.global.message = err.message;
          setError(error);
        }
      });
  };
  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSignIn(event);
    }
  };

  const handleForgotPassword = () => {
    history.push('/forgot-password', {
      stage: RESET_STAGE_USERNAME.STAGE,
      helperText: t(RESET_STAGE_USERNAME.HELPER_TEXT)
    });
  };

  return (
    <div>
      <AuthTemplate
        helperText={helperText}
        helperTextMiddle={helperTextMiddle}
        helperTextBack={helperTextBack}>
        <Input
          label={t('AUTH_COMMON.EMAIL')}
          onChange={handleChange}
          name="username"
          placeholder={t('AUTH_COMMON.EMAIL')}
          variant="basic"
          error={error.username.error}
          errorMessage={error.username.message}
          onKeyPress={onKeyPress}
        />
        <Input
          label={t('AUTH_COMMON.PASSWORD')}
          onChange={handleChange}
          name="password"
          placeholder={t('AUTH_COMMON.PASSWORD')}
          variant="password"
          error={error.password.error}
          errorMessage={error.password.message}
          onKeyPress={onKeyPress}
        />
        <Button
          block
          label={t('AUTH_SIGNIN.SIGN_IN')}
          onClick={handleSignIn}
          size={ButtonSizes.large}
          variant={ButtonVariants.basic}
          disabled={loading || formDisabled}
        />

        {error.global.error && (
          <div className="form-errors">
            <Text color="#db1e1e" size={TextSizes.t14}>
              {error.global.message}
            </Text>
          </div>
        )}

        <div className="footer-link-wrapper">
          <Text className="footer-link-text" size={TextSizes.t14}>
            {t('AUTH_SIGNIN.FORGOT_PASSWORD_CLICK')}
          </Text>

          <button className="text-only-btn" onClick={handleForgotPassword} disabled={loading}>
            {t('AUTH_COMMON.TEXT_HERE')}
          </button>
        </div>
      </AuthTemplate>
    </div>
  );
};

export default SignIn;

SignIn.propTypes = {
  history: PropTypes.any
};
