// This regex checks if the value is between -180.0 and +180.0 - Only numbers and +,- signs are allowed.
export const validLongitudeRegEx = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
// This regex checks if the value is between -90.0 and +90.0 - Only numbers and +,- signs are allowed.
export const validLatitudeRegEx = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
// This regex allows numbers, spaces, parentheses, and +- signs
export const validPhoneNumberRegEx = /^[\d+\s*+\-()]*$/;
// This regex checks if the value matches an email address. - No others checks (length, valid domain or not) are performed with this.
export const validEmailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
// This regex checks if the value contains atleast one lowercase, one uppercase, one numeric value, one special character. String must be atleast 8 characters long.
export const validPasswordRegEx = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><\':;|_~`^\\]\\[\\)\\(]).{8,}'
);
