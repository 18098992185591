import { UserType } from '@gym-particles/types/User';
import UserManagementTemplate from '@gym-templates/UserManagement/UserManagementTemplate';
import { Card } from 'primereact/card';
import Loader from '@gym-atoms/Loader/Loader';
import { useHistory, useParams } from 'react-router-dom';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { useTranslation } from 'react-i18next';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { useRef } from 'react';
import { useAppSelector } from '@gym-redux/store';

const MainColumn = (props: { user?: UserType }) => {
  if (props.user) {
    return (
      <>
        <Card className="pt30 pl40 pr40 pb30">
          <UserManagementTemplate type="modify" user={props.user} />
        </Card>
      </>
    );
  } else {
    return (
      <Card className="pt30 pl40 pr40 pb30">
        <div className="mr15 mb18">
          <Loader shape="rectangle" />
        </div>
        <div className="mr15 mb18">
          <Loader shape="rectangle" />
        </div>
        <div className="mr15 mb18">
          <Loader shape="rectangle" />
        </div>
      </Card>
    );
  }
};

const EditUserPage = () => {
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const { t } = useTranslation();

  const userInfo = useAppSelector((state) =>
    state.userManagement.items.find((i) => i.userId === +userId)
  );

  return (
    <TwoColTemplate
      title={t('MODIFY_USER.PAGE_TITLE')}
      col1={<MainColumn user={userInfo} />}
      alertRef={alertRef}
      backButtonHandler={() => history.push('/users')}
    />
  );
};

export default EditUserPage;
