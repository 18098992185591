import { createSlice } from '@reduxjs/toolkit';

const initialState = { show: false };

export const sidebarPropsSlice = createSlice({
  name: 'sidebarProps',
  initialState,
  reducers: {
    show: (state) => {
      state.show = true;
      return state;
    },
    hide: (state) => {
      state.show = false;
      return state;
    }
  }
});

export default sidebarPropsSlice.reducer;
