import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';

import { logout } from '@gym-redux/slices/userSlice';
import AuthTemplate from '@gym-templates/Authentication/AuthTemplate';
import Input from '@gym-atoms/Input/Input';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';

const ResetPassword: React.FC = (props) => {
  const emptyError = {
    newPassword: {
      error: false,
      message: ''
    },
    confirmPassword: {
      error: false,
      message: ''
    },
    global: {
      error: false,
      message: ''
    }
  };

  const { t, i18n } = useTranslation();
  const helperText = t('AUTH_INITIAL_RESET.RESET_HELPER_TEXT');
  const dispatch = useDispatch();
  const { cognitoUser } = useSelector((state: any) => state.user);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ newPassword: '', confirmPassword: '' });
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [error, setError] = useState(emptyError);

  const [resetFormDisabled, setResetFormDisabled] = useState(true);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    setFormData({ ...formData, [element.name]: element.value });
  };

  useEffect(() => {
    formDisableCheck();
  }, [formData]);

  const formDisableCheck = () => {
    if (formData.newPassword && formData.confirmPassword) {
      setResetFormDisabled(false);
    } else {
      setResetFormDisabled(true);
    }
  };

  const validateReset = (newPassword: string, confirmPassword: string) => {
    const errors = {
      newPassword: {
        error: false,
        message: ''
      },
      confirmPassword: {
        error: false,
        message: ''
      },
      global: {
        error: false,
        message: ''
      }
    };

    if (!cognitoUser) {
      errors.global.error = true;
      errors.global.message = t('AUTH_INITIAL_RESET.RESET_COGNITO_USER_NULL');
    }

    if (!newPassword || newPassword.length === 0) {
      errors.newPassword.error = true;
      errors.newPassword.message = t('AUTH_COMMON.NEW_PASSWORD_EMPTY_ERROR');
    }

    if (!confirmPassword || confirmPassword.length === 0) {
      errors.confirmPassword.error = true;
      errors.confirmPassword.message = t('AUTH_COMMON.CONFIRM_PASSWORD_EMPTY_ERROR');
    }

    if (newPassword !== confirmPassword) {
      errors.global.error = true;
      errors.global.message = t('AUTH_COMMON.PASSWORDS_NOT_MATCHING_ERROR');
    }

    return errors;
  };

  const handleReset = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();

    setError(emptyError);

    const formErrors = validateReset(formData.newPassword, formData.confirmPassword);

    if (
      formErrors.newPassword.error ||
      formErrors.confirmPassword.error ||
      formErrors.global.error
    ) {
      setError(formErrors);
      return;
    }

    setLoading(true);

    Auth.completeNewPassword(cognitoUser, formData.newPassword)
      .then((data) => {
        setPasswordStatus(true);
        setTimeout(() => {
          dispatch(logout());
          setLoading(false);
          history.push('/login');
        }, 3000);
      })
      .catch((err) => {
        console.log('Initial Password Reset Err: ', err);

        setLoading(false);
        const error = { ...emptyError };
        error.global.error = true;
        error.global.message = err.message;
        setError(error);
      });
  };

  const handleLoginDifferentAccount = () => {
    history.push('/login');
  };

  return (
    <div>
      <AuthTemplate helperText={helperText}>
        <Input
          label={t('AUTH_COMMON.NEW_PASSWORD')}
          onChange={handleChange}
          name="newPassword"
          placeholder={t('AUTH_COMMON.NEW_PASSWORD')}
          variant="password"
          error={error.newPassword.error}
          errorMessage={error.newPassword.message}
        />
        <Input
          label={t('AUTH_COMMON.CONFIRM_PASSWORD')}
          onChange={handleChange}
          name="confirmPassword"
          placeholder={t('AUTH_COMMON.CONFIRM_PASSWORD')}
          variant="password"
          error={error.confirmPassword.error}
          errorMessage={error.confirmPassword.message}
        />
        <Button
          block
          label={t('AUTH_COMMON.RESET_PASSWORD')}
          onClick={handleReset}
          size={ButtonSizes.large}
          variant={ButtonVariants.basic}
          disabled={loading || resetFormDisabled}
        />

        {passwordStatus && (
          <div className="form-success">
            <Text color="#159b10" size={TextSizes.t14}>
              {t('AUTH_COMMON.INITIAL_PASSWORD_RESET_SUCCESS')}
            </Text>
          </div>
        )}

        {error.global.error && (
          <div className="form-errors">
            <Text color="#db1e1e" size={TextSizes.t14}>
              {error.global.message}
            </Text>
          </div>
        )}

        <div className="footer-link-wrapper">
          <Text className="footer-link-text" size={TextSizes.t14}>
            {t('AUTH_COMMON.LOGIN_DIFFERENT_ACCOUNT')}
          </Text>

          <button
            className="text-only-btn"
            onClick={handleLoginDifferentAccount}
            disabled={loading}>
            {t('AUTH_COMMON.TEXT_HERE')}
          </button>
        </div>
      </AuthTemplate>
    </div>
  );
};

export default ResetPassword;
