import React, { FormEvent, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AuthTemplate from '@gym-templates/Authentication/AuthTemplate';
import Input from '@gym-atoms/Input/Input';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import {
  RESET_STAGE_CODE,
  RESET_STAGE_NEW_PASSWORD,
  RESET_STAGE_USERNAME
} from '@gym-particles/passwordResetStages';

interface IForgotPassword {
  location?: any;
}

const ForgotPassword: React.FC<IForgotPassword> = (props) => {
  const emptyError = {
    username: {
      error: false,
      message: ''
    },
    resetCode: {
      error: false,
      message: ''
    },
    newPassword: {
      error: false,
      message: ''
    },
    confirmPassword: {
      error: false,
      message: ''
    },
    global: {
      error: false,
      message: ''
    }
  };

  const stagePropValidation = () => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.stage &&
      props.location &&
      props.location.state &&
      props.location.state.helperText
    ) {
      return {
        stage: props.location.state.stage,
        helperText: props.location.state.helperText
      };
    }

    return {
      stage: RESET_STAGE_USERNAME.STAGE,
      helperText: t(RESET_STAGE_USERNAME.HELPER_TEXT)
    };
  };

  const formPropValidation = () => {
    if (props.location && props.location.state && props.location.state.username) {
      return {
        username: props.location.state.username,
        resetCode: '',
        newPassword: '',
        confirmPassword: ''
      };
    }

    return {
      username: '',
      resetCode: '',
      newPassword: '',
      confirmPassword: ''
    };
  };

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [state, setState] = useState(stagePropValidation);
  const [loading, setLoading] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [error, setError] = useState(emptyError);
  const [formData, setFormData] = useState(formPropValidation);

  const [usernameFormDisabled, setUsernameFormDisabled] = useState(true);

  const [codeFormDisabled, setCodeFormDisabled] = useState(true);

  const [passwordFormDisabled, setPasswordFormDisabled] = useState(true);

  const validateUsername = (username: string) => {
    const errors = {
      username: {
        error: false,
        message: ''
      },
      resetCode: {
        error: false,
        message: ''
      },
      newPassword: {
        error: false,
        message: ''
      },
      confirmPassword: {
        error: false,
        message: ''
      },
      global: {
        error: false,
        message: ''
      }
    };

    if (!username || username.length === 0) {
      errors.username.error = true;
      errors.username.message = t('AUTH_COMMON.EMAIL_EMPTY_ERROR');
    }

    return errors;
  };

  const validateResetCode = (resetCode: string) => {
    const errors = {
      username: {
        error: false,
        message: ''
      },
      resetCode: {
        error: false,
        message: ''
      },
      newPassword: {
        error: false,
        message: ''
      },
      confirmPassword: {
        error: false,
        message: ''
      },
      global: {
        error: false,
        message: ''
      }
    };

    if (!resetCode || resetCode.length === 0) {
      errors.resetCode.error = true;
      errors.resetCode.message = t('AUTH_COMMON.RESET_CODE_EMPTY_ERROR');
    }

    return errors;
  };

  const validatePasswords = (newPassword: string, confirmPassword: string) => {
    const errors = {
      username: {
        error: false,
        message: ''
      },
      resetCode: {
        error: false,
        message: ''
      },
      newPassword: {
        error: false,
        message: ''
      },
      confirmPassword: {
        error: false,
        message: ''
      },
      global: {
        error: false,
        message: ''
      }
    };

    if (!newPassword || newPassword.length === 0) {
      errors.newPassword.error = true;
      errors.newPassword.message = t('AUTH_COMMON.NEW_PASSWORD_EMPTY_ERROR');
    }

    if (!confirmPassword || confirmPassword.length === 0) {
      errors.confirmPassword.error = true;
      errors.confirmPassword.message = t('AUTH_COMMON.CONFIRM_PASSWORD_EMPTY_ERROR');
    }

    if (newPassword !== confirmPassword) {
      errors.global.error = true;
      errors.global.message = t('AUTH_COMMON.PASSWORDS_NOT_MATCHING_ERROR');
    }

    return errors;
  };

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    setFormData({ ...formData, [element.name]: element.value.trim() });
  };

  useEffect(() => {
    formDisableCheck();
  }, [formData]);

  const formDisableCheck = () => {
    if (formData.username) {
      setUsernameFormDisabled(false);
    } else {
      setUsernameFormDisabled(true);
    }

    if (formData.resetCode) {
      setCodeFormDisabled(false);
    } else {
      setCodeFormDisabled(true);
    }

    if (formData.newPassword && formData.confirmPassword) {
      setPasswordFormDisabled(false);
    } else {
      setPasswordFormDisabled(true);
    }
  };

  const handleUsername = async (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();

    setError(emptyError);

    const formErrors = validateUsername(formData.username);

    if (formErrors.username.error || formErrors.global.error) {
      setError(formErrors);
      return;
    }

    setLoading(true);

    try {
      await Auth.forgotPassword(formData.username);

      setState({
        stage: RESET_STAGE_CODE.STAGE,
        helperText: t(RESET_STAGE_CODE.HELPER_TEXT)
      });
    } catch (err: any) {
      console.log('forgot password err: ', err);

      let errMessage = 'Failed to complete action. Please try again or contact an Admin.';

      if (err.code === 'NotAuthorizedException') {
        errMessage = err.message;
      } else if (err.code === 'UserNotFoundException') {
        errMessage = 'User does not exist. Please try again with a valid email.';
      }

      setError({
        username: {
          error: false,
          message: ''
        },
        resetCode: {
          error: false,
          message: ''
        },
        newPassword: {
          error: false,
          message: ''
        },
        confirmPassword: {
          error: false,
          message: ''
        },
        global: {
          error: true,
          message: errMessage
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResetCode = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();

    setError(emptyError);

    const formErrors = validateResetCode(formData.resetCode);

    if (formErrors.resetCode.error || formErrors.global.error) {
      setError(formErrors);
      return;
    }

    setState({
      stage: RESET_STAGE_NEW_PASSWORD.STAGE,
      helperText: t(RESET_STAGE_NEW_PASSWORD.HELPER_TEXT)
    });
  };

  const handlePasswordChange = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();

    setError(emptyError);

    const formErrors = validatePasswords(formData.newPassword, formData.confirmPassword);

    if (
      formErrors.newPassword.error ||
      formErrors.confirmPassword.error ||
      formErrors.global.error
    ) {
      setError(formErrors);
      return;
    }

    setLoading(true);

    Auth.forgotPasswordSubmit(formData.username, formData.resetCode, formData.newPassword)
      .then(() => {
        setPasswordStatus(true);

        setTimeout(() => {
          setLoading(false);
          history.push('/login');
        }, 3000);
      })
      .catch((err) => {
        console.log(err);

        setLoading(false);
        const error = { ...emptyError };

        if (err.code === 'CodeMismatchException') {
          setState({
            stage: RESET_STAGE_CODE.STAGE,
            helperText: t(RESET_STAGE_CODE.HELPER_TEXT)
          });

          error.resetCode.error = true;
        }

        error.global.error = true;
        error.global.message = err.message;
        setError(error);
      });
  };

  const handleLoginDifferentAccount = () => {
    history.push('/login');
  };

  return (
    <div>
      <AuthTemplate helperText={state.helperText}>
        {state.stage === RESET_STAGE_USERNAME.STAGE && (
          <>
            <Input
              label={t('AUTH_COMMON.EMAIL')}
              onChange={handleChange}
              name="username"
              placeholder={t('AUTH_COMMON.EMAIL')}
              variant="basic"
              error={error.username.error}
              errorMessage={error.username.message}
            />
            <div className="mt50">
              <Button
                block
                label={t('AUTH_FORGOT_PASSWORD.GET_PASSWORD_RESET_CODE')}
                onClick={handleUsername}
                size={ButtonSizes.large}
                variant={ButtonVariants.basic}
                disabled={loading || usernameFormDisabled}
              />
            </div>
          </>
        )}

        {state.stage === RESET_STAGE_CODE.STAGE && (
          <>
            <Input
              label={t('AUTH_FORGOT_PASSWORD.RESET_CODE')}
              onChange={handleChange}
              name="resetCode"
              placeholder={t('AUTH_FORGOT_PASSWORD.RESET_CODE')}
              variant="basic"
              error={error.resetCode.error}
              errorMessage={error.resetCode.message}
            />
            <Button
              block
              label={t('AUTH_FORGOT_PASSWORD.SUBMIT')}
              onClick={handleResetCode}
              size={ButtonSizes.medium}
              variant={ButtonVariants.basic}
              disabled={codeFormDisabled}
            />
            <div className="form-success">
              <Text color="#159b10" size={TextSizes.t14}>
                {t('AUTH_FORGOT_PASSWORD.PASSWORD_CODE_SENT')}
              </Text>
            </div>
          </>
        )}

        {state.stage === RESET_STAGE_NEW_PASSWORD.STAGE && (
          <>
            <Input
              label={t('AUTH_COMMON.NEW_PASSWORD')}
              onChange={handleChange}
              name="newPassword"
              placeholder={t('AUTH_COMMON.NEW_PASSWORD')}
              variant="password"
              error={error.newPassword.error}
              errorMessage={error.newPassword.message}
            />
            <Input
              label={t('AUTH_COMMON.CONFIRM_PASSWORD')}
              onChange={handleChange}
              name="confirmPassword"
              placeholder={t('AUTH_COMMON.CONFIRM_PASSWORD')}
              variant="password"
              error={error.confirmPassword.error}
              errorMessage={error.confirmPassword.message}
            />
            <Button
              block
              label={t('AUTH_COMMON.RESET_PASSWORD')}
              onClick={handlePasswordChange}
              size={ButtonSizes.large}
              variant={ButtonVariants.basic}
              disabled={loading || passwordFormDisabled}
            />
          </>
        )}

        {passwordStatus && (
          <div className="form-success">
            <Text color="#159b10" size={TextSizes.t14}>
              {t('AUTH_COMMON.INITIAL_PASSWORD_RESET_SUCCESS')}
            </Text>
          </div>
        )}

        {error.global.error && (
          <div className="form-errors">
            <Text color="#db1e1e" size={TextSizes.t14}>
              {error.global.message}
            </Text>
          </div>
        )}

        <div className="footer-link-wrapper">
          <Text className="footer-link-text" size={TextSizes.t14}>
            {t('AUTH_COMMON.LOGIN_DIFFERENT_ACCOUNT')}
          </Text>

          <button
            className="text-only-btn"
            onClick={handleLoginDifferentAccount}
            disabled={loading}>
            {t('AUTH_COMMON.TEXT_HERE')}
          </button>
        </div>
      </AuthTemplate>
    </div>
  );
};

export default ForgotPassword;

ForgotPassword.propTypes = {
  location: PropTypes.any
};
