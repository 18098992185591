import TabView, { OnTabChangeEvent } from '@gym-atoms/TabView/TabView';
import {
  HomeDetailsHeaderRoutes,
  HomeDetailsHeaderTabNames
} from '@gym-particles/types/HomeDetails';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HomeDashboardTasks from '@gym-organisms/HomeDashboardTasks/HomeDashboardTasks';
import HomeDashboardActivity from '@gym-organisms/HomeDashboardActivity/HomeDashboardActivity';
import { useEffect } from 'react';
import { fetchMyTaskCountForUser } from '@gym-redux/slices/homeActivitySlice';
import { UserRole } from '@gym-particles/types/User';

const HomeHeader = ({ currentTab }: HomeHeaderProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.userId);
  const userRoleId = useAppSelector((state) => state.user.roleId);
  const [activeTab, setActiveTab] = useState(HomeDetailsHeaderRoutes[currentTab]);
  const userRole = useAppSelector((state) => state.user.userRole);
  const routes = [`/home/activity`, `/home/inquiries`];
  const [isCountChanged, setIsCountChanged] = useState(false);

  useEffect(() => {
    dispatch(
      fetchMyTaskCountForUser({
        userId: userId,
        roleId: userRoleId,
        platformAdmin: userRole === UserRole.SYSTEM_ADMIN
      })
    );
  }, [userId, isCountChanged]);

  const myTasksCount = useAppSelector((state) => state.homeActivity.myTaskCount);

  const tabViewItems = [
    {
      header: t('HOME_PAGE.HOME_HEADER.ACTIVITY'),
      content: (
        <HomeDashboardActivity countChange={setIsCountChanged} isCountChanged={isCountChanged} />
      )
    },
    {
      header: t('HOME_PAGE.HOME_HEADER.INQUIRIES'),
      content: (
        <HomeDashboardTasks countChange={setIsCountChanged} isCountChanged={isCountChanged} />
      ),
      count: myTasksCount || undefined
    }
  ];

  const onTabChangeHandler = (e: OnTabChangeEvent) => {
    setActiveTab(e.index);
    history.push(routes[e.index]);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="tab-view-align-custom">
            <TabView
              headerClassName="dashboard-header-tabs"
              items={tabViewItems}
              activeTab={activeTab}
              onTabChange={onTabChangeHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export interface HomeHeaderProps {
  currentTab: HomeDetailsHeaderTabNames;
}

export default HomeHeader;
