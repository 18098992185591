import GymZoneTemplate from '@gym-templates/GymZone/GymZoneTemplate';
import { useTranslation } from 'react-i18next';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { useRef } from 'react';
import { ZoneTable } from '@gym-particles/types/Zone';

const ZoneForm = (props: ZoneTemplateProps) => {
  const alertRef = useRef<AlertType>(null);
  const { t } = useTranslation();

  return (
    <GymZoneTemplate
      type={props.type}
      heading={t(`${props.type === 'new' ? 'ADD_NEW' : 'MODIFY'}_ZONE.PAGE_TITLE`)}
      zone={props.zoneData}
      templateType={props.templateType}
    />
  );
};

export default ZoneForm;

type ZoneInfoTemplateType = 'new' | 'modify';

export interface ZoneTemplateProps {
  type: ZoneInfoTemplateType;
  zoneData?: ZoneTable;
  templateType?: (e: boolean) => void;
}
