import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Text from '@gym-atoms/Text/Text';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import Loader from '@gym-atoms/Loader/Loader';
import { KisiDoor } from '@gym-src/API';
import { deleteKisiDoor, fetchKisiDoors } from '@gym-redux/slices/kisiDoorSlice';

const EmployeeDashboardPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { siteLocationId, gymChainId } = useParams<{
    siteLocationId: string;
    gymChainId: string;
  }>();
  const { t } = useTranslation();

  const kisiDoorList = useAppSelector(
    (state) => state.kisiDoor.items.find((g) => g.siteLocationId === +siteLocationId)?.items
  );
  const totalDoorCount =
    useAppSelector(
      (state) =>
        state.kisiDoor.items.find((g) => g.siteLocationId === +siteLocationId)?.totalRecords
    ) || 0;
  const [isDeleting, setIsDeleting] = useState(false);
  const [doorsSortField, setDoorsSortField] = useState('createdDate');
  const [doorsSortOrder, setDoorsSortOrder] = useState(-1);
  const [doorsOffset, setDoorsOffset] = useState(0);
  const [doorsPageSize, setDoorsPageSize] = useState(10);
  const [selectedDoorRow, setSelectedDoorRow] = useState<KisiDoor>();
  const [dialogVisible, setDialogVisible] = useState(false);
  const isDoorsLoading = useAppSelector((state) => state.kisiDoor.isKisiDoorLoading);
  const [searchTerm, setSearchTerm] = useState('');
  const userRole = useAppSelector((state) => state.user.userRole);
  const userId = useAppSelector((state) => state.user.userId);

  useEffect(() => {
    dispatch(
      fetchKisiDoors({
        siteLocationId: +siteLocationId
      })
    );
  }, [siteLocationId]);

  const menuItem = [
    {
      label: t('EMPLOYEE_DASHBOARD_PAGE.KISI_DOORS_TABLE.BUTTON_DELETE_LABEL'),
      command: () => setDialogVisible(true)
    }
  ];

  const TABLE_HEAD = [
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_DOORS_TABLE.KISI_DOOR_ID_HEADER'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_DOORS_TABLE.KISI_DOOR_NAME_HEADER'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_DOORS_TABLE.KISI_PLACE_ID_HEADER'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_DOORS_TABLE.KISI_PLACE_NAME_HEADER'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_EVENTS_TABLE.CREATED_DATE_HEADER'),
    t('EMPLOYEE_DASHBOARD_PAGE.KISI_EVENTS_TABLE.LAST_MODIFIED_DATE_HEADER'),
    ''
  ];

  const deleteDialogHandler = async () => {
    setIsDeleting(true);
    selectedDoorRow &&
      (await dispatch(deleteKisiDoor({ id: selectedDoorRow.id, lastModifiedBy: userId })));
    setIsDeleting(false);
    setDialogVisible(false);
    history.push(`/employeeDashboard/${gymChainId}/${siteLocationId}`);
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        <Button
          data-cy={'doors-dialog-cancel-btn'}
          label={t('EMPLOYEE_DASHBOARD_PAGE.DELETE_MODAL_CANCEL')}
          variant={ButtonVariants.textonly}
          onClick={() => setDialogVisible(false)}
        />
        <Button
          data-cy={'doors-dialog-delete-btn'}
          label={t('EMPLOYEE_DASHBOARD_PAGE.DELETE_MODAL_DELETE')}
          variant={ButtonVariants.danger}
          onClick={deleteDialogHandler}
          icon={isDeleting ? 'pi-spinner pi-spin' : ''}
        />
      </div>
    );
  };

  return (
    <div>
      <DialogBox
        dialogVisible={dialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setDialogVisible(false)}
        dialogFooter={<DeleteDialogFooter />}
        dialogClosable={false}
        dialogHeader={t('EMPLOYEE_DASHBOARD_PAGE.DELETE_MODAL_HEADER')}
        content={
          <Text data-cy={'doors-dialog-content'}>
            {t('EMPLOYEE_DASHBOARD_PAGE.DELETE_MODAL_CONFIRM')}
          </Text>
        }
      />
      <div className={'d-flex justify-content-between align-items-start mt-3 w-full'}>
        <div>
          <p className="text-3xl mt6 text-2xl font-semibold">
            {t('EMPLOYEE_DASHBOARD_PAGE.KISI_DOORS_TABLE.KISI_DOOR_TABLE_HEADER')}
          </p>
        </div>
        <button
          className="flex float-right select-none items-center gap-3 rounded-lg bg-gray-900 py-2 px-7 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          onClick={() =>
            history.push(`/employeeDashboard/${gymChainId}/${siteLocationId}/doors/new`)
          }>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>

          {t('EMPLOYEE_DASHBOARD_PAGE.KISI_DOORS_TABLE.BUTTON_ADD_LABEL')}
        </button>
      </div>
      {isDoorsLoading ? (
        <Loader shape="table" />
      ) : kisiDoorList ? (
        <div className="h-full w-full">
          <table className="w-full table-auto text-center">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th key={head} className="border-b border-blue-gray-100 bg-blue-gray-100 p-4">
                    <p color="blue-gray" className="font-normal leading-none opacity-70">
                      {head}
                    </p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {kisiDoorList.map(
                (
                  {
                    id,
                    kisiDoorId,
                    kisiDoorName,
                    kisiPlaceId,
                    kisiPlaceName,
                    createdBy,
                    createdDate,
                    lastModifiedBy,
                    lastModifiedDate
                  },
                  index
                ) => {
                  const isLast = index === kisiDoorList.length - 1;
                  const events = isLast
                    ? 'p-2.5 border-b border-blue-gray-50'
                    : 'p-2.5 border-b border-blue-gray-50';

                  return (
                    <tr key={id}>
                      <td className={events}>
                        <button
                          className="p-2.5 font-normal text-center text-blue-gray uppercase align-middle transition-all rounded-lg select-none hover:text-blue-700 active:text-blue-950"
                          type="button"
                          onClick={() =>
                            history.push(
                              `/employeeDashboard/${gymChainId}/${siteLocationId}/doors/${id}/events`
                            )
                          }>
                          {kisiDoorId}
                        </button>
                      </td>
                      <td className={`${events} bg-blue-gray-50/50`}>
                        <p color="blue-gray" className="font-normal">
                          {kisiDoorName}
                        </p>
                      </td>
                      <td className={events}>
                        <p color="blue-gray" className="font-normal">
                          {kisiPlaceId}
                        </p>
                      </td>
                      <td className={events}>
                        <p color="blue-gray" className="font-normal">
                          {kisiPlaceName}
                        </p>
                      </td>
                      <td className={events}>
                        <p color="blue-gray" className="font-normal">
                          {createdDate}
                        </p>
                      </td>
                      <td className={events}>
                        <p color="blue-gray" className="font-normal">
                          {lastModifiedDate}
                        </p>
                      </td>
                      <td className={`${events} bg-blue-gray-50/50`}>
                        <button
                          className="flex select-none items-center gap-2 rounded-lg bg-gray-900 py-2 px-5 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                          type="button"
                          onClick={() => setDialogVisible(true)}>
                          {' '}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-6">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                          {t('EMPLOYEE_DASHBOARD_PAGE.KISI_DOORS_TABLE.BUTTON_DELETE_LABEL')}
                        </button>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EmployeeDashboardPage;
