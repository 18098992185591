import { useState, useEffect, FormEvent, RefObject } from 'react';
import Input from '@gym-atoms/Input/Input';
import { useTranslation } from 'react-i18next';
import Dropdown, { DropdownChangeEvent } from '@gym-atoms/Dropdown/Dropdown';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { SettingsElement } from '@gym-particles/types/GymChainSettings';
import { useForm } from 'react-hook-form';
import { fetchGymChainSettings } from '@gym-redux/slices/gymChainSettingsSlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { AccessRule, AccessRuleInput } from '@gym-src/API';
import { API } from 'aws-amplify';
import { modifyAccessRule } from '@gym-graphql/mutations';
import { useHistory, useParams } from 'react-router-dom';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Text from '@gym-atoms/Text/Text';
import { AlertType } from '@gym-atoms/Alert/Alert';
import Loader from '@gym-atoms/Loader/Loader';

const SettingsInfoTemplate = (props: SettingsInfoTemplateProps) => {
  const history = useHistory();
  const { chainId } = useParams<{ chainId: string }>();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.userId);
  const [isInserted, setIsInserted] = useState(false);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const isSettingsLoading = useAppSelector((state) => state.gymChainSettings.isSettingsLoading);
  const getTranslationKey = (e: string): string => {
    return props.type === 'new' ? null + e : 'MODIFY_SETTING_RULE.' + e;
  };

  type FormInputs = {
    maxLimit?: number;
    gracePeriod?: string;
    allowAccess?: string;
    graceAccessCount?: number;
    noOfDays?: number;
  };

  const defaultValues: FormInputs = {
    maxLimit: props.rule?.maxLimit || -1,
    noOfDays: props.rule?.noOfDays || -1,
    allowAccess: props.rule?.allowAccess || '',
    gracePeriod: props.rule?.gracePeriod || '',
    graceAccessCount: props.rule?.graceAccessCount || -1
  };

  const [formValues, setFormValues] = useState(defaultValues);

  const { register, handleSubmit, setValue, errors } = useForm<FormInputs>({
    defaultValues: defaultValues
  });

  const modifyAccessRuleFunction = async (data: FormInputs) => {
    console.log(data);
    const modifyAccessRuleInputs: AccessRuleInput = {
      id: props.rule?.id,
      maxLimit: data.maxLimit || -1,
      allowAccess: data.allowAccess || '',
      gracePeriod: data.allowAccess === 'With grace' ? data.gracePeriod : '',
      graceAccessCount: 1,
      noOfDays: data.noOfDays || -1,
      lastModifiedBy: userId
    };

    try {
      await (API.graphql({
        query: modifyAccessRule,
        variables: {
          input: modifyAccessRuleInputs
        }
      }) as Promise<{
        data: { modifyAccessRule: AccessRule };
      }>);
      history.push(`/gymChains/${chainId}/settings`);
    } catch (e) {
      setFailedDialogVisible(true);
      console.log(e);
    }
  };

  //Form submission handler
  const onSubmitHandler = async (data: FormInputs) => {
    setTimeout(() => {
      setIsInserted(true);
    }, 50);
    if (props.type === 'modify' && props.rule) {
      modifyAccessRuleFunction(data);
    }
    setIsInserted(false);
  };

  useEffect(() => {
    register('maxLimit', {
      valueAsNumber: true,
      validate: (value) => {
        if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
          return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
        }
      }
    });
    register('allowAccess');
    register('gracePeriod', {
      max: {
        value: 365,
        message: t(getTranslationKey('FORM_FIELD_MAX_GRACE_PERIOD_IN_DAYS_ERROR')) as string
      },
      validate: (value) =>
        !value ||
        (parseInt(value) > 0 && Number.isInteger(Number(value))) ||
        (t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string)
    });
    register('graceAccessCount', {
      valueAsNumber: true,
      validate: (value) => {
        if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
          return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
        }
      }
    });
    register('noOfDays', {
      valueAsNumber: true,
      validate: (value) => {
        if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
          return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
        }
      }
    });
  }, [register]);

  const { t } = useTranslation();

  const allowAccessDropDownItems = [
    {
      label: t(getTranslationKey('FORM_ALLOW_ACCESS_DROPDOWN_YES')),
      value: 'Yes'
    },
    {
      label: t(getTranslationKey('FORM_ALLOW_ACCESS_DROPDOWN_NO')),
      value: 'No'
    },
    {
      label: t(getTranslationKey('FORM_ALLOW_ACCESS_DROPDOWN_With_GRACE')),
      value: 'With grace'
    }
  ];

  const AllowAccessDropdownHandler = (e: DropdownChangeEvent) => {
    setValue('allowAccess', e.value);
    setFormValues({ ...formValues, allowAccess: e.value });
  };

  const gracePeriodDropDownItems = [
    {
      label: t(getTranslationKey('FORM_ALLOW_GRACE_PERIOD_DROPDOWN_TOTAL')),
      value: 'Total'
    },
    {
      label: t(getTranslationKey('FORM_ALLOW_GRACE_PERIOD_DROPDOWN_DAILY')),
      value: 'Daily'
    },
    {
      label: t(getTranslationKey('FORM_ALLOW_GRACE_PERIOD_DROPDOWN_With_MONTHLY')),
      value: 'Monthly'
    }
  ];

  useEffect(() => {
    dispatch(fetchGymChainSettings({ siteId: props.gymChainId }));
  }, []);

  useEffect(() => {
    setFormValues({
      ...formValues,
      maxLimit: props.rule?.maxLimit || -1,
      noOfDays: props.rule?.noOfDays || -1,
      allowAccess: props.rule?.allowAccess || '',
      gracePeriod: props.rule?.gracePeriod || '',
      graceAccessCount: props.rule?.graceAccessCount || -1
    });
    setValue('maxLimit', props.rule?.maxLimit);
    setValue('noOfDays', props.rule?.noOfDays);
    setValue('allowAccess', props.rule?.allowAccess);
    setValue('gracePeriod', props.rule?.gracePeriod);
    setValue('graceAccessCount', props.rule?.graceAccessCount);
  }, [props]);

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}</Text>}
        />
      </div>
    );
  };

  const changeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof FormInputs, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  const loderElement = () => {
    return (
      <div className="loaderHeader">
        <div className="loaderContent">
          <Loader shape="rectangle" width="quarter" />
        </div>
        <div>
          <Loader shape="rectangle" width="full" />
        </div>
      </div>
    );
  };

  return (
    <div className="col-md-6">
      {isSettingsLoading ? (
        [...Array(5)].map((element, index) => {
          return <div key={index}> {loderElement()} </div>;
        })
      ) : (
        <form className="input-mb20" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="mb25">
            <label className="fw-bold">{t(getTranslationKey('FORM_INPUT_LABEL_LEVEL'))}</label>
            <Text>{props.rule?.level}</Text>
          </div>
          <div className="mb25">
            <label className="fw-bold">{t(getTranslationKey('FORM_INPUT_LABEL_TYPE'))}</label>
            <Text>{props.rule?.type}</Text>
          </div>
          <div className="mb25">
            <label className="fw-bold">
              {props.rule?.serviceResponse === 'Access count is exceeded'
                ? t(getTranslationKey('FORM_INPUT_LABEL_RESPONSE_EXCEED'))
                : t(getTranslationKey('FORM_INPUT_LABEL_RESPONSE'))}
            </label>
            <Text>{props.rule?.serviceResponse}</Text>
          </div>
          {props.rule?.type === 'Minus credit balance' ? (
            <Input
              label={t(getTranslationKey('FORM_INPUT_LABEL_MAX_LIMIT'))}
              placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_MAX_LIMIT'))}
              onChange={changeHandler}
              variant="basic"
              disabled={false}
              value={formValues.maxLimit === -1 ? '' : formValues.maxLimit?.toString()}
              name="maxLimit"
              error={errors.maxLimit ? true : false}
              errorMessage={errors.maxLimit?.message}
            />
          ) : null}
          {props.rule?.type === 'Minus credit balance' ? (
            <Input
              label={t(getTranslationKey('FORM_INPUT_LABEL_MAX_NO_OF_DAYS'))}
              placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_MAX_NO_OF_DAYS'))}
              onChange={changeHandler}
              variant="basic"
              disabled={false}
              value={formValues.noOfDays === -1 ? '' : formValues.noOfDays?.toString()}
              name="noOfDays"
              error={errors.noOfDays ? true : false}
              errorMessage={errors.noOfDays?.message}
            />
          ) : null}
          <div className="mb25">
            <label className="fw-bold">
              {t(getTranslationKey('FORM_INPUT_LABEL_ALLOW_ACCESS'))}
            </label>
            <Dropdown
              name="allowAccess"
              options={allowAccessDropDownItems}
              value={formValues.allowAccess}
              placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_ALLOW_ACCESS'))}
              onChange={AllowAccessDropdownHandler}
              disabled={false}
            />
          </div>
          {formValues.allowAccess === 'With grace' ? (
            <div className="mb25">
              <Input
                label={t(getTranslationKey('FORM_INPUT_LABEL_GRACE_PERIOD_IN_DAYS'))}
                placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_GRACE_PERIOD_IN_DAYS'))}
                onChange={changeHandler}
                variant="basic"
                disabled={false}
                value={formValues.gracePeriod ? formValues.gracePeriod : ''}
                name="gracePeriod"
                error={errors.gracePeriod ? true : false}
                errorMessage={errors.gracePeriod?.message}
              />
            </div>
          ) : null}
          <div className="d-flex flex-row mt35 btn-min-w-110">
            <div className="mr15">
              <Button
                icon={isInserted ? 'pi-spinner pi-spin' : ''}
                label={t(getTranslationKey('FORM_BTN_LABEL_SAVE'))}
                size={ButtonSizes.medium}
                disabled={isInserted}
              />
            </div>
            <Button
              label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
              variant={ButtonVariants.textonly}
              onClick={() => history.push(`/gymChains/${props.gymChainId}/settings`)}
              disabled={isInserted}
            />
            <ActionFailedDialog />
          </div>
        </form>
      )}
    </div>
  );
};

export default SettingsInfoTemplate;

type SettingsInfoTemplateType = 'new' | 'modify';

export interface SettingsInfoTemplateProps {
  type: SettingsInfoTemplateType;
  rule?: SettingsElement;
  gymChainId: number;
  alertRef: RefObject<AlertType>;
  alertMessage: string;
}
