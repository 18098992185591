import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { GymScheduleTable, GymScheduleType } from '@gym-particles/types/GymSchedules';
import { useEffect, useState } from 'react';
import Loader from '@gym-atoms/Loader/Loader';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { deleteGymSchedule, fetchGymSchedules } from '@gym-redux/slices/gymSchedulesSlice';
import Styles from './GymChainScheduleListStyle.module.scss';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Text from '@gym-atoms/Text/Text';
import { API } from 'aws-amplify';
import {
  removeSiteLocationSchedules,
  removeSiteSchedulesDetails,
  updateScheduleStatus
} from '@gym-graphql/mutations';
import { UserRole } from '@gym-particles/types/User';
import { fetchGyms } from '@gym-redux/slices/gymsSlice';
import {
  UpdateScheduleStatusIdMutation,
  UpdateScheduleStatusMutationVariables
} from '@gym-src/API';
import { fetchScheduleRequests } from '@gym-redux/slices/scheduleRequestsSlice';

export const GymChainGymSchedulesList = (props: GymSchedulesListTableProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [selectedRow, setSelectedRow] = useState<GymScheduleTable>();
  const gymChainId = props.gymChainId;
  const { t } = useTranslation();
  const [gymScheduleSortField, setGymScheduleSortField] = useState('id');
  const [gymScheduleSortOrder, setGymScheduleSortOrder] = useState(-1);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const isGymScheduleLoading = useAppSelector((state) => state.gymSchedules.isGymSchedulesLoading);
  const userId = useAppSelector((state) => state.user.userId);
  const userRole = useAppSelector((state) => state.user.userRole);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [inactiveScheduleModalVisible, setInactiveScheduleModalVisible] = useState(false);
  const [refreshSchedules, setRefreshSchedules] = useState(false);
  const [inactivateStatus, setInactivateStatus] = useState<'success' | 'fail'>();
  const totalRecords =
    useAppSelector(
      (state) => state.gymSchedules.items.find((g) => g.siteId === gymChainId)?.totalRecords
    ) || 0;

  const gymChainName = useAppSelector(
    (state) => state.gymChain.items.find((g) => g.id === +gymChainId)?.name
  );
  const currentUser = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(
      fetchGymSchedules({
        siteId: props.gymChainId
      })
    );

    dispatch(
      fetchGyms({
        id: +gymChainId,
        pagination: {
          offset: 0,
          pageSize: 0,
          sortField: 'siteId',
          sortOrder: 1,
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        }
      })
    );
    /* When gym schedules modifed, the sidebar schedule request count should update. */
    userRole === UserRole.SYSTEM_ADMIN && dispatch(fetchScheduleRequests());
  }, []);

  useEffect(() => {
    dispatch(
      fetchGymSchedules({
        siteId: props.gymChainId
      })
    );
    /* When gym schedules modifed, the sidebar schedule request count should update. */
    userRole === UserRole.SYSTEM_ADMIN && dispatch(fetchScheduleRequests());
  }, [refreshSchedules]);

  const inactivateSchedule = async (id?: number, siteId?: number) => {
    try {
      const params: UpdateScheduleStatusMutationVariables = {
        siteScheduleId: id,
        status: 'inactive',
        userId: currentUser.userId,
        siteId: siteId
      };
      const res = await (API.graphql({
        query: updateScheduleStatus,
        variables: params
      }) as Promise<{
        data: { updateScheduleStatus: UpdateScheduleStatusIdMutation };
      }>);
      if (res.data.updateScheduleStatus === 1) {
        setInactivateStatus('success');
      } else {
        setInactivateStatus('fail');
      }
    } catch (e) {
      console.error(e);
      setInactivateStatus('fail');
    } finally {
      setInactiveScheduleModalVisible(true);
    }
  };

  const mapSchedules = (schedules?: GymScheduleType[]) => {
    if (!schedules) {
      return;
    }
    const mappedSchedules: GymScheduleTable[] = [];

    schedules.forEach((schedule) => {
      const index = mappedSchedules.findIndex((mappedSchedule) => {
        return mappedSchedule.id == schedule.siteScheduleId;
      });

      if (index > -1) {
        const subRow = mappedSchedules[index].scheduleDetails.findIndex((gym) => {
          return gym.gymId === schedule.siteLocationId && gym.zoneId === schedule.zoneId;
        });
        if (subRow === -1) {
          schedule.zoneName === ''
            ? ''
            : mappedSchedules[index].scheduleDetails.push({
                gymId: schedule.siteLocationId,
                gymName: schedule.siteLocationName,
                zoneId: schedule.zoneId,
                zone: schedule.zoneName,
                accessCategory: [schedule.accessCategoryName],
                accessPoint: [schedule.locationAccessPointName]
              });
        } else {
          const accessCatIndex = mappedSchedules[index].scheduleDetails[
            subRow
          ].accessCategory.findIndex((accessCatName) => {
            return accessCatName === schedule.accessCategoryName;
          });

          const accessPointIndex = mappedSchedules[index].scheduleDetails[
            subRow
          ].accessPoint.findIndex((accessPointName) => {
            return accessPointName === schedule.locationAccessPointName;
          });

          if (accessCatIndex === -1) {
            schedule.zoneName === ''
              ? ''
              : mappedSchedules[index].scheduleDetails[subRow].accessCategory.push(
                  schedule.accessCategoryName
                );
          }

          if (accessPointIndex === -1) {
            schedule.zoneName === ''
              ? ''
              : mappedSchedules[index].scheduleDetails[subRow].accessPoint.push(
                  schedule.locationAccessPointName
                );
          }
        }
      } else {
        schedule.zoneName === ''
          ? ''
          : mappedSchedules.push({
              id: schedule.siteScheduleId,
              gymChainId: schedule.siteId,
              gymChainName: schedule.siteName,
              gymScheduleName: schedule.siteScheduleName,
              gymScheduleType: schedule.siteScheduleType,
              startingDate: schedule.validFrom,
              endingDate: schedule.validTo,
              scheduleProgressionStatus: schedule.scheduleStatusName,
              siteScheduleStatus: schedule.siteScheduleStatus,
              createdDate: schedule.siteScheduleCreatedDate,
              scheduleDetails: [
                {
                  gymId: schedule.siteLocationId,
                  gymName: schedule.siteLocationName,
                  zoneId: schedule.zoneId,
                  zone: schedule.zoneName,
                  accessCategory: [schedule.accessCategoryName],
                  accessPoint: [schedule.locationAccessPointName]
                }
              ]
            });
      }
    });

    return mappedSchedules;
  };

  const gymSchedules = useAppSelector((state) =>
    mapSchedules(state.gymSchedules.items.find((e) => e.siteId === props.gymChainId)?.items)
  );

  const deleteSiteLocationSchedules = async () => {
    try {
      const response = await API.graphql({
        query: removeSiteLocationSchedules,
        variables: {
          siteScheduleId: selectedRow?.id,
          userId: userId
        }
      });
    } catch (error) {
      console.log('Delete SiteLocation Schedules Error: ', error);
    }
  };
  const deleteSiteSchedulesDetails = async () => {
    try {
      const response = await API.graphql({
        query: removeSiteSchedulesDetails,
        variables: {
          siteScheduleId: selectedRow?.id,
          userId: userId
        }
      });
    } catch (error) {
      console.log('Delete Siteschedules Details Error: ', error);
    }
  };

  const deleteDialogHandler = () => {
    selectedRow &&
      dispatch(deleteGymSchedule({ id: selectedRow.id, siteId: props.gymChainId, userId: userId }));
    selectedRow && deleteSiteLocationSchedules();
    selectedRow && deleteSiteSchedulesDetails();
    setDeleteDialogVisible(false);
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.CANCEL')}
          variant={ButtonVariants.textonly}
          onClick={() => setDeleteDialogVisible(false)}
        />
        <Button
          label={t('COMMON.DELETE')}
          variant={ButtonVariants.danger}
          onClick={deleteDialogHandler}
        />
      </div>
    );
  };

  const accessCategoryBodyTemplate = (row: any) => {
    return (
      <div className={Styles.bodyTemplate}>
        <ul>
          {row.accessCategory.map((accessCategory: string, index: number) => (
            <li key={index}>{accessCategory}</li>
          ))}
        </ul>
      </div>
    );
  };

  const accessPointBodyTemplate = (row: any) => {
    return (
      <div className={Styles.bodyTemplate}>
        <ul>
          {row.accessPoint.map((accessPoint: string, index: number) => (
            <li key={index}>{accessPoint}</li>
          ))}
        </ul>
      </div>
    );
  };

  const tablePropsGymSchedule: TableProps<GymScheduleTable> = {
    exportFileName: `GymSchedules-${gymChainName}`,
    data: gymSchedules || undefined,
    searchPlaceholderText: t('GYM_SCHEDULE_LIST.SEARCH_PLACEHOLDER'),
    excelBtntext: t('GYM_SCHEDULE_LIST.EXPORT_EXCEL_BUTTON_LABEL'),
    totalRecords: totalRecords,
    selectedRow: selectedRow,
    pageSize: pageSize,
    offset: offset,
    setPageSize: setPageSize,
    setOffset: setOffset,
    setSelectedRow: setSelectedRow,
    setSortField: setGymScheduleSortField,
    setSortOrder: setGymScheduleSortOrder,
    sortField: gymScheduleSortField,
    sortOrder: gymScheduleSortOrder,
    emptyStateTexts: [t('GYM_SCHEDULE_LIST.EMPTY_STATE_TEXT')],
    columns: [
      {
        field: 'gymScheduleName',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_NAME'),
        toggable: false,
        sortable: true,
        truncateText: true
      },
      {
        field: 'gymScheduleType',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_TYPE'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'startingDate',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_STARTING_DATE'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'endingDate',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_ENDING_DATE'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'scheduleProgressionStatus',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_PROGRESSION_STATUS'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'siteScheduleStatus',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_SITE_SCHEDULE_STATUS'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'createdDate',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_CREATED_DATE'),
        toggable: true,
        truncateText: true
      }
    ],
    isContextMenu: true,
    menuItem: [
      {
        label: t('COMMON.MODIFY'),
        command: () => history.push(`/gymChains/${gymChainId}/gymSchedules/${selectedRow?.id}`)
      },
      {
        label: t('COMMON.DELETE'),
        command: () => setDeleteDialogVisible(true)
      },
      {
        label: t('COMMON.INACTIVATE'),
        command: () => inactivateSchedule(selectedRow?.id, selectedRow?.gymChainId)
      }
    ],
    lazy: false,
    expandable: true,
    expandableField: 'scheduleDetails',
    expandedCols: [
      {
        field: 'gymName',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_GYM'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'zone',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_ZONES'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'accessCategory',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_ACCESS_CATEGORIES'),
        toggable: true,
        truncateText: true,
        bodyTemplate: (e) => accessCategoryBodyTemplate(e)
      },
      {
        field: 'accessPoint',
        header: t('GYM_SCHEDULE_LIST.TABLE.HEADER_GYM_SCHEDULE_ACCESS_POINTS'),
        toggable: true,
        truncateText: true,
        bodyTemplate: (e) => accessPointBodyTemplate(e)
      }
    ],
    headerBtns: [
      <div className="d-flex" key={1}>
        <Button
          label={t('GYM_SCHEDULE_LIST.HEADER_BUTTON_LABEL')}
          size={ButtonSizes.small}
          onClick={() => history.push(`/gymChains/${gymChainId}/gymSchedules/new`)}
        />
      </div>
    ]
  };

  return (
    <div>
      <DialogBox
        dialogVisible={deleteDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setDeleteDialogVisible(false)}
        dialogFooter={<DeleteDialogFooter />}
        dialogClosable={false}
        dialogHeader={t('COMMON.DELETE')}
        content={
          <Text>
            {t('GYM_SCHEDULE_LIST.DELETE_MODAL_CONFIRM')}{' '}
            <span className="fw-bold">{selectedRow?.gymScheduleName}</span> ?
          </Text>
        }
      />
      <DialogBox
        dialogVisible={inactiveScheduleModalVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => {
          setInactiveScheduleModalVisible(false);
          setRefreshSchedules(!refreshSchedules);
        }}
        dialogFooter={
          <Button
            label={t('COMMON.OK')}
            variant={ButtonVariants.basic}
            onClick={() => {
              setInactiveScheduleModalVisible(false);
              setRefreshSchedules(!refreshSchedules);
            }}
          />
        }
        dialogClosable={false}
        dialogHeader={t('COMMON.INACTIVATE')}
        content={
          <Text>
            {inactivateStatus === 'success'
              ? t('GYM_SCHEDULE_LIST.INACTIVE_SUCCESS')
              : t('GYM_SCHEDULE_LIST.INACTIVE_FAILED')}
          </Text>
        }
      />
      {isGymScheduleLoading ? <Loader shape="table" /> : <Table {...tablePropsGymSchedule} />}
    </div>
  );
};

interface GymSchedulesListTableProps {
  gymChainId: number;
}
