import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { Activity } from '@gym-particles/types/models';
import { GymActivity } from '@gym-src/API';
import {
  getActivityForSite,
  getActivityMyTasksCountForSite,
  getActivityMyTasksForSite
} from '@gym-graphql/queries';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  items: Array<{
    userId: number;
    siteId: number;
    activityItems: { items: Activity[]; totalRecords: number };
    myTaskItems: { items: Activity[]; totalRecords: number };
  }>;
  isActivityLoading: boolean;
  myTaskCount?: number;
} = {
  items: [],
  isActivityLoading: false
};

type paramType = {
  userId: number;
  siteId: number;
  offset: number;
  pageSize: number;
  dateFrom: string;
  dateTo: string;
  stageId: number;
  eventType: string;
  platformAdmin: boolean;
};

type paramTypeForMyTasks = {
  userId: number;
  roleId: number;
  siteId: number;
  offset: number;
  pageSize: number;
  closedInquiries: boolean;
  platformAdmin: boolean;
};

type countParamType = {
  userId: number;
  roleId: number;
  siteId: number;
  platformAdmin: boolean;
};

export const fetchActivityForSite = createAsyncThunk(
  '/gymChain/fetchActivityForSite',
  async (params: paramType) => {
    const response = await (API.graphql({
      query: getActivityForSite,
      variables: {
        userId: params.userId,
        siteId: params.siteId,
        offset: params.offset,
        pageSize: params.pageSize,
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        stageId: params.stageId,
        eventType: params.eventType,
        platformAdmin: params.platformAdmin
      }
    }) as Promise<{
      data: {
        getActivityForSite: {
          items: GymActivity[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const fetchActivityMyTasksForSite = createAsyncThunk(
  '/home/fetchActivityMyTasksForSite',
  async (params: paramTypeForMyTasks) => {
    const response = await (API.graphql({
      query: getActivityMyTasksForSite,
      variables: {
        userId: params.userId,
        roleId: params.roleId,
        siteId: params.siteId,
        offset: params.offset,
        pageSize: params.pageSize,
        closedInquiries: params.closedInquiries,
        platformAdmin: params.platformAdmin
      }
    }) as Promise<{
      data: {
        getActivityMyTasksForSite: {
          items: GymActivity[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const fetchMyTaskCountForSite = createAsyncThunk(
  'gym/fetchMyTaskCountForSite',
  async (params: countParamType) => {
    const response = await (API.graphql({
      query: getActivityMyTasksCountForSite,
      variables: {
        userId: params.userId,
        roleId: params.roleId,
        siteId: params.siteId,
        platformAdmin: params.platformAdmin
      }
    }) as Promise<{
      data: { getActivityMyTasksCountForSite: { count: number } };
    }>);
    return response;
  }
);

export const gymChainActivitySlice = createSlice({
  name: 'gymChainActivity',
  initialState,
  reducers: {
    incrementMyTaskCount: (state) => {
      if (state.myTaskCount) state.myTaskCount += 1;
      return state;
    },
    decrementMyTaskCount: (state) => {
      if (state.myTaskCount) state.myTaskCount -= 1;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActivityForSite.fulfilled, (state, action) => {
      state.isActivityLoading = false;
      if (action.payload.data.getActivityForSite.items === null) {
        return;
      }
      const items = action.payload.data.getActivityForSite.items.map(
        (e: GymActivity): Activity => {
          return {
            memberEventId: e.memberEventId || 0,
            memberId: e.memberId || 0,
            memberEmail: e.memberEmail || '',
            eventId: e.eventId || 0,
            accessCardUID: e.accessCardUID || undefined,
            eventDescription: e.eventDescription || '',
            eventType: e.eventType || '',
            stageId: e.stageId || 0,
            stage: e.stage || '',
            createdTime: e.createdTime
              ? dayjs
                  .utc(e.createdTime || '')
                  .local()
                  .format('hh:mm A')
                  .toString()
              : '',
            isAutoAssigned: e.isAutoAssigned || false,
            memberEventStatusTypeId: e.memberEventStatusTypeId || 0,
            memberEventStatusType: e.memberEventStatusType || '',
            firstName: e.firstName || '',
            lastName: e.lastName || '',
            siteLocationId: e.siteLocationId || 0,
            locationName: e.locationName || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            activityDate: e?.createdTime
              ? dayjs
                  .utc(e?.createdTime || '')
                  .local()
                  .format('MM DD YYYY')
                  .toString()
              : '',
            assignedUserId: e.assignedUserId || 0,
            assignmentHistoryId: e.assignmentHistoryId || undefined,
            inquiryDescription: e.inquiryDescription || '',
            memberLocationId: e.memberLocationId || 0,
            informationReceivedEvent: e.informationReceivedEvent || false,
            language: e.language || 'en',
            userId: e.userId || 0,
            en: e.en || '',
            nor: e.nor || '',
            nl: e.nl || ''
          };
        }
      );

      const totalRecords = action.payload.data.getActivityForSite.totalRecords.totalRecords;

      const fetchedActivityInfo = {
        activityItems: { items: items, totalRecords: totalRecords },
        myTaskItems: {
          items:
            state.items.find(
              (i) => i.userId === action.meta.arg.userId && i.siteId === action.meta.arg.siteId
            )?.myTaskItems.items || [],
          totalRecords:
            state.items.find(
              (i) => i.userId === action.meta.arg.userId && i.siteId === action.meta.arg.siteId
            )?.myTaskItems.totalRecords || 0
        },
        userId: action.meta.arg.userId,
        siteId: action.meta.arg.siteId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedActivityInfo);
      } else {
        const update = state.items.find(
          (activityInfo) =>
            activityInfo.userId === fetchedActivityInfo.userId &&
            activityInfo.siteId === fetchedActivityInfo.siteId
        );
        if (update) {
          const newState = state.items.map((activityInfo) => {
            if (
              activityInfo.userId === fetchedActivityInfo.userId &&
              activityInfo.siteId === fetchedActivityInfo.siteId
            ) {
              return fetchedActivityInfo;
            } else {
              return activityInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedActivityInfo);
        }
      }
    });

    builder.addCase(fetchActivityForSite.pending, (state, action) => {
      state.isActivityLoading = true;
      return state;
    });

    builder.addCase(fetchActivityForSite.rejected, (state, action) => {
      state.isActivityLoading = false;
      console.log('Get gym chain activity Error:', action);
      return state;
    });

    builder.addCase(fetchActivityMyTasksForSite.fulfilled, (state, action) => {
      state.isActivityLoading = false;
      if (action.payload.data.getActivityMyTasksForSite.items === null) {
        return;
      }
      const items = action.payload.data.getActivityMyTasksForSite.items.map(
        (e: GymActivity): Activity => {
          return {
            memberEventId: e.memberEventId || 0,
            memberId: e.memberId || 0,
            memberEmail: e.memberEmail || '',
            eventId: e.eventId || 0,
            accessCardUID: e.accessCardUID || undefined,
            eventDescription: e.eventDescription || '',
            eventType: e.eventType || '',
            stageId: e.stageId || 0,
            stage: e.stage || '',
            createdTime: e.createdTime
              ? dayjs
                  .utc(e.createdTime || '')
                  .local()
                  .format('hh:mm A')
                  .toString()
              : '',
            isAutoAssigned: e.isAutoAssigned || false,
            memberEventStatusTypeId: e.memberEventStatusTypeId || 0,
            memberEventStatusType: e.memberEventStatusType || '',
            firstName: e.firstName || '',
            lastName: e.lastName || '',
            siteLocationId: e.siteLocationId || 0,
            locationName: e.locationName || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            activityDate: e?.createdTime
              ? dayjs
                  .utc(e?.createdTime || '')
                  .local()
                  .format('MM DD YYYY')
                  .toString()
              : '',
            assignedUserId: e.assignedUserId || 0,
            assignmentHistoryId: e.assignmentHistoryId || undefined,
            inquiryDescription: e.inquiryDescription || '',
            memberLocationId: e.memberLocationId || 0,
            informationReceivedEvent: e.informationReceivedEvent || false,
            language: e.language || 'en',
            en: e.en || '',
            nor: e.nor || '',
            nl: e.nl || ''
          };
        }
      );

      const totalRecords = action.payload.data.getActivityMyTasksForSite.totalRecords.totalRecords;

      const fetchedActivityInfo = {
        activityItems: {
          items:
            state.items.find(
              (i) => i.userId === action.meta.arg.userId && i.siteId === action.meta.arg.siteId
            )?.activityItems.items || [],
          totalRecords:
            state.items.find(
              (i) => i.userId === action.meta.arg.userId && i.siteId === action.meta.arg.siteId
            )?.activityItems.totalRecords || 0
        },
        myTaskItems: {
          items: items,
          totalRecords: totalRecords
        },
        userId: action.meta.arg.userId,
        siteId: action.meta.arg.siteId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedActivityInfo);
      } else {
        const update = state.items.find(
          (activityInfo) =>
            activityInfo.userId === fetchedActivityInfo.userId &&
            activityInfo.siteId === fetchedActivityInfo.siteId
        );
        if (update) {
          const newState = state.items.map((activityInfo) => {
            if (
              activityInfo.userId === fetchedActivityInfo.userId &&
              activityInfo.siteId === fetchedActivityInfo.siteId
            ) {
              return fetchedActivityInfo;
            } else {
              return activityInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedActivityInfo);
        }
      }
    });

    builder.addCase(fetchActivityMyTasksForSite.pending, (state, action) => {
      state.isActivityLoading = true;
      return state;
    });

    builder.addCase(fetchActivityMyTasksForSite.rejected, (state, action) => {
      state.isActivityLoading = false;
      console.log('Get home activity my tasks Error:', action);
      return state;
    });

    builder.addCase(fetchMyTaskCountForSite.fulfilled, (state, action) => {
      const count = action.payload.data.getActivityMyTasksCountForSite.count;
      state.myTaskCount = count;
      return state;
    });

    builder.addCase(fetchMyTaskCountForSite.rejected, (state, action) => {
      console.error('Get my task count error:', action);
      return state;
    });
  }
});

export const { incrementMyTaskCount, decrementMyTaskCount } = gymChainActivitySlice.actions;

export default gymChainActivitySlice.reducer;
