import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { AccessPoints as typeOfAccessPoint } from '@gym-particles/types/models';
import { getAccessPointsForSiteLocation } from '@gym-graphql/queries';
import { LocationAccessPoint } from '@gym-src/API';
import { removeLocationAccessPoint } from '@gym-graphql/mutations';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isAccessPointLoading: boolean;
  items: Array<{
    siteLocationId: number;
    items: typeOfAccessPoint[];
    totalRecords: number;
  }>;
} = {
  isAccessPointLoading: false,
  items: []
};

type accessPointParamType = {
  siteLocationId: number;
};

export type deleteParamType = {
  id?: number;
  lastModifiedBy: number;
};

export const fetchAccessPoints = createAsyncThunk(
  '/gym/fetchAccessPoints',
  async (params: accessPointParamType) => {
    const response = await (API.graphql({
      query: getAccessPointsForSiteLocation,
      variables: {
        siteLocationId: params.siteLocationId
      }
    }) as Promise<{
      data: {
        getAccessPointsForSiteLocation: {
          items: LocationAccessPoint[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const deleteAccessPoint = createAsyncThunk(
  'AccessPoint/deleteAccessPoint',
  async (params: deleteParamType) => {
    const response = await (API.graphql({
      query: removeLocationAccessPoint,
      variables: {
        id: params.id,
        lastModifiedBy: params.lastModifiedBy
      }
    }) as Promise<{
      data: {
        removeLocationAccessPoint: number;
      };
    }>);
    return response;
  }
);

export const AccessPointsSlice = createSlice({
  name: 'AccessPoints',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccessPoints.fulfilled, (state, action) => {
      state.isAccessPointLoading = false;
      if (action.payload.data.getAccessPointsForSiteLocation.items === null) {
        return;
      }
      const items = action.payload.data.getAccessPointsForSiteLocation.items.map(
        (e: LocationAccessPoint): typeOfAccessPoint => {
          return {
            id: e.id || 0,
            siteLocationId: e.siteLocationId || 0,
            locationZoneId: e.locationZoneId || 0,
            locationZoneName: e.locationZoneName || '',
            zoneAccessPointId: e.zoneAccessPointId || 0,
            accessPointType: e.accessPointType || '',
            uniqueId: e.uniqueId || '',
            beaconUUID: e.beaconUUID || '',
            beaconMajor: e.beaconMajor || '',
            beaconMinor: e.beaconMinor || '',
            doorARXId: e.doorARXId || '',
            doorName: e.doorName || '',
            doorDescription: e.doorDescription || '',
            handbackTime: e.handbackTime || 0,
            sodvinDoorId: e.sodvinDoorId || '',
            sodvinDoorSecret: e.sodvinDoorSecret || '',
            doorCaption: e.doorCaption || '',
            priority: e.priority || '',
            status: e.status || '',
            batteryLevel: e.batteryLevel || 0,
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            createdBy: e.createdBy || 0,
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            displayBatteryLevel: e.batteryLevel ? e.batteryLevel + '%' : '0%',
            displayStatus: e.status ? e.status.charAt(0).toUpperCase() + e.status.slice(1) : '',
            displayType: e.accessPointType
              ? e.accessPointType.charAt(0).toUpperCase() + e.accessPointType.slice(1)
              : ''
          };
        }
      );

      const totalRecords =
        action.payload.data.getAccessPointsForSiteLocation.totalRecords.totalRecords;

      const fetchedAccessPointsInfo = {
        totalRecords: totalRecords,
        items: items,
        siteLocationId: action.meta.arg.siteLocationId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedAccessPointsInfo);
      } else {
        const update = state.items.find(
          (accessPointInfo) =>
            accessPointInfo.siteLocationId === fetchedAccessPointsInfo.siteLocationId
        );
        if (update) {
          const newState = state.items.map((accessPointInfo) => {
            if (accessPointInfo.siteLocationId === fetchedAccessPointsInfo.siteLocationId) {
              return fetchedAccessPointsInfo;
            } else {
              return accessPointInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedAccessPointsInfo);
        }
      }
    });

    builder.addCase(fetchAccessPoints.pending, (state, action) => {
      state.isAccessPointLoading = true;
      return state;
    });

    builder.addCase(fetchAccessPoints.rejected, (state, action) => {
      state.isAccessPointLoading = false;
      console.log('getAccessPoints', action);
      return state;
    });

    builder.addCase(deleteAccessPoint.fulfilled, (state, action) => {
      state.isAccessPointLoading = false;
      const accessPointId = action.meta.arg;
      const filtered = state.items.map((accessPointObj) => {
        const filteredAccessPoints = accessPointObj.items.filter(
          (accessPoint) => accessPoint.id !== accessPointId.id
        );
        accessPointObj.items = filteredAccessPoints;
        accessPointObj.totalRecords = accessPointObj.totalRecords - 1;
        return accessPointObj;
      });
      state.items = filtered;
    });

    builder.addCase(deleteAccessPoint.pending, (state, action) => {
      state.isAccessPointLoading = true;
      return state;
    });

    builder.addCase(deleteAccessPoint.rejected, (state, action) => {
      state.isAccessPointLoading = false;
      console.error('deleteAccessPoint', action);
      return state;
    });
  }
});

export default AccessPointsSlice.reducer;
