import { AlertType } from '@gym-atoms/Alert/Alert';
import AccessCategoryMappingForm from '@gym-organisms/AccessCategoryMapping/AccessCategoryMappingForm';
import AccessCategoryMappingList from '@gym-organisms/AccessCategoryMapping/AccessCategoryMappingList';
import { AccessCategoryMapping } from '@gym-particles/types/AccessCategoryMapping';
import { useAppSelector } from '@gym-redux/store';
import { Card } from 'primereact/card';
import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const GymAccessCategoryMappingPage = (props: GymAccessCategoryMappingProps) => {
  const [isModifyTemplate, setIsModifyTemplate] = useState(false);
  const { t } = useTranslation();
  const { gymId } = useParams<{ gymId: string }>();
  const [accessCategoryMapping, setAccessCategoryMapping] = useState<AccessCategoryMapping>();
  const userRole = useAppSelector((state) => state.user.userRole);

  return (
    <>
      <Card className="p-card-w-table">
        <AccessCategoryMappingForm
          type={isModifyTemplate ? 'modify' : 'new'}
          accessCategoryData={isModifyTemplate ? accessCategoryMapping : undefined}
          templateType={setIsModifyTemplate}
        />
      </Card>
      <Card className="p-card-w-table">
        <AccessCategoryMappingList
          templateType={setIsModifyTemplate}
          gymId={+gymId}
          heading={t('ACCESS_CATEGORY.HEADER_TEXT')}
          templateAccessCategory={setAccessCategoryMapping}
        />
      </Card>
    </>
  );
};

export interface GymAccessCategoryMappingProps {
  alertRef: RefObject<AlertType>;
}

export default GymAccessCategoryMappingPage;
