import { GymxsFeatures } from '@gym-particles/types/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ConfigurationSlice = {
  features: GymxsFeatures[];
};

const initialState: ConfigurationSlice = {
  features: ['sodvin']
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setFeatures: (state, action: PayloadAction<GymxsFeatures[]>) => {
      state.features = action.payload;
    }
  }
});

export const { setFeatures } = configurationSlice.actions;

export default configurationSlice.reducer;
