import { AlertType } from '@gym-atoms/Alert/Alert';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { Card } from 'primereact/card';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DashboardCounts from '@gym-molecules/DashboardCounts/DashboardCounts';
import HomeHeader from '@gym-organisms/HomeHeader/HomeHeader';
import { HomeDetailsHeaderTabNames } from '@gym-particles/types/HomeDetails';

const MainColumn = () => {
  const { tab } = useParams<{ tab: string }>();
  return (
    <Card>
      <HomeHeader currentTab={tab as HomeDetailsHeaderTabNames} />
    </Card>
  );
};

const SecondColumn = () => {
  const todayUserCount = 200;
  const activeUserCount = 300532;
  const errorCount = 30;

  return (
    <div>
      <DashboardCounts
        todayUserCount={todayUserCount}
        activeUserCount={activeUserCount}
        errorCount={errorCount}
      />
    </div>
  );
};

const HomePage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);

  return (
    <TwoColTemplate
      title={t('HOME_PAGE.PAGE_TITLE')}
      col1={<MainColumn />}
      //--Sodvin Release--
      // col2={<SecondColumn />}
      alertRef={alertRef}
      backButtonHandler={() => ''}
      showBackButton={false}
    />
  );
};

export default HomePage;
