import { Dialog } from 'primereact/dialog';
import { SelectItem, DropdownChangeEvent } from '@gym-atoms/Dropdown/Dropdown';
import Input from '@gym-atoms/Input/Input';
import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import { useState, FormEvent } from 'react';
import { ActivityLogGroupEntry } from '@gym-particles/types/models';

export enum IssueModalVariants {
  close = 'issuing-modal-close'
}

const IssueModal = ({
  variant,
  onHideCallback,
  errorMessage,
  dropdownOptions,
  visible,
  onSubmitHander,
  selectedActivity
}: IssueModalProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selected, setSelected] = useState<string | null>(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();

  const searchUserOnChangeHandler = (e: DropdownChangeEvent) => {
    if (e.value === undefined) {
      setSelected(null);
      return;
    }
    setSelected(e.value);
    setButtonDisabled(false);
  };

  const getModal = (variantType: IssueModalVariants) => {
    const submitButtonClickHandler = () => {
      inputValue != '' ? onSubmitHander(inputValue) : setHasError(true);

      // reset form values
      setSelected(null);
      setInputValue('');
    };

    const dialogFooter = (
      <div>
        <Button
          data-cy={'issue-modal-cancel-btn'}
          variant={ButtonVariants.textonly}
          label={t('COMMON.CANCEL')}
          onClick={() => {
            onHideCallback();
            setInputValue('');
            setSelected(null);
          }}
        />
        <Button
          data-cy={'issue-modal-cancel-btn'}
          variant={ButtonVariants.basic}
          label={t('ISSUE_MODALS.CLOSED_BUTTON')}
          onClick={submitButtonClickHandler}
          disabled={buttonDisabled}
        />
      </div>
    );

    switch (variantType) {
      case IssueModalVariants.close: {
        const content = (
          <div>
            <Input
              label={t('ISSUE_MODALS.CLOSE_DESCRIPTION_LABEL')}
              onChangeTA={(e: FormEvent<HTMLTextAreaElement>) => {
                setInputValue(e.currentTarget.value);
                setButtonDisabled(false);
                setHasError(false);
              }}
              value={inputValue}
              variant={'textarea'}
              error={hasError}
              errorMessage={t('ISSUE_MODALS.REQUIRED_FIELD_ERROR')}
              data-cy={'close-modal-text-area-input'}
            />
          </div>
        );
        return (
          <>
            <Dialog
              visible={visible}
              onHide={() => {
                onHideCallback();
                setInputValue('');
              }}
              footer={dialogFooter}
              closable={true}
              header={t('ISSUE_MODALS.DIALOG_HEADER_CLOSE')}
              dismissableMask={false}
              className={'dialog-basic'}>
              {content}
            </Dialog>
          </>
        );
      }
    }
  };
  return <>{getModal(variant)}</>;
};

export interface IssueModalProps {
  /** Modal variants e.g. assign, close */
  variant: IssueModalVariants;
  /** Controls the visibility of the modal */
  visible: boolean;
  /** Modal onHideCallback */
  onHideCallback: () => void;
  /** Assign modal message */
  errorMessage?: string;
  /** Assign modal select user dropdown options */
  dropdownOptions?: Array<Partial<SelectItem>> | Array<string>;
  /** Modal data submission handler */
  onSubmitHander: (text: string) => void;
  /** Selected activity */
  selectedActivity?: ActivityLogGroupEntry;
}

export default IssueModal;
