import Pill, { PillSizes, PillVariants } from '@gym-atoms/Pill/Pill';
import i18n from '@gym-particles/i18next';

const InquiriesFilters = (props: InquiriesFilterProps) => {
  return (
    <div className="row">
      <div className="col-auto pl0 pr0">
        <Pill
          label={i18n.t('HOME_PAGE.INQUIRY_TYPES.OPEN')}
          variant={PillVariants.primary}
          checked={props.openInquiries}
          onChange={props.openInquiriesOnChange}
          size={PillSizes.small}
        />
      </div>
      <div className="col-auto pl0 pr0">
        <Pill
          label={i18n.t('HOME_PAGE.INQUIRY_TYPES.CLOSED')}
          variant={PillVariants.success}
          checked={props.closedInquiries}
          onChange={props.closedInquiriesOnChange}
          size={PillSizes.small}
        />
      </div>
    </div>
  );
};

export interface InquiriesFilterProps {
  openInquiries: boolean;
  openInquiriesOnChange?: () => void;
  closedInquiries: boolean;
  closedInquiriesOnChange?: () => void;
}

export default InquiriesFilters;
