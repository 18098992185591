import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import Loader from '@gym-atoms/Loader/Loader';
import { onPushGymVisitEntry } from '@gym-graphql/subscriptions';
import ActivityFeed from '@gym-molecules/ActivityFeed/ActivityFeed';
import EmptyState from '@gym-molecules/EmptyState/EmptyState';
import { Activity, ActivityEventTypes } from '@gym-particles/types/ActivityFeed';
import { fetchActivityVisits } from '@gym-redux/slices/activitySlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import {
  OnPushGymVisitEntrySubscription,
  OnPushGymVisitEntrySubscriptionVariables
} from '@gym-src/API';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Observable } from 'zen-observable-ts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { UserRole } from '@gym-particles/types/User';
dayjs.extend(utc);

const GymDashboardVisits = (props: GymDashboardActivityProps) => {
  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const { t } = useTranslation();
  const userId = useAppSelector((state) => state.user.userId);
  const isActivityLoading = useAppSelector((state) => state.activity.isActivityLoading);
  const [activitiesList, setactivitiesList] = useState<Activity[]>();
  const [visitSubscriptiondata, setvisitSubscriptiondata] = useState<any>();
  const [refresh, setRefresh] = useState(false);
  const userRole = useAppSelector((state) => state.user.userRole);
  const totalActivityCount =
    useAppSelector(
      (state) =>
        state.activity.items.find((g) => g.siteLocationId === props.gymId)?.visitItems.totalRecords
    ) || 0;

  useEffect(() => {
    dispatch(
      fetchActivityVisits({
        userId: userId,
        siteLocationId: props.gymId,
        pageSize: pageSize,
        offset: offset,
        platformAdmin: userRole === UserRole.SYSTEM_ADMIN
      })
    );
  }, [props.gymId, userId, offset, pageSize, refresh]);

  const mapVisits = (activities?: Activity[]) => {
    if (!activities) {
      return;
    }

    return activities.filter((activity) => {
      return activity.eventType === ActivityEventTypes.VISITS;
    });
  };

  const activities = useAppSelector((state) =>
    mapVisits(state.activity.items.find((e) => e.siteLocationId === props.gymId)?.visitItems.items)
  );

  useEffect(() => {
    setactivitiesList(activities);
  }, [isActivityLoading]);

  useEffect(() => {
    setactivitiesList(activities || []);
    subscribeVisits();
    return () => unsubscribeVisits();
  }, []);

  let visitsSubscription: any;

  const unsubscribeVisits = () => {
    if (visitsSubscription) {
      visitsSubscription.unsubscribe();
    }
  };

  const subscribeVisits = () => {
    const variables: OnPushGymVisitEntrySubscriptionVariables = {
      siteLocationId: props.gymId
    };

    const subscription = (API.graphql({
      query: onPushGymVisitEntry,
      variables: variables
    }) as Observable<any>).subscribe({
      next: ({ provider, value }) => {
        console.log(provider);
        setvisitSubscriptiondata(value.data);
      },
      error: (error) => console.warn(error)
    });

    visitsSubscription = subscription;
  };

  useEffect(() => {
    if (visitSubscriptiondata) {
      updateActivities(visitSubscriptiondata as OnPushGymVisitEntrySubscription);
    }
  }, [setvisitSubscriptiondata, visitSubscriptiondata]);

  const updateActivities = (visit: OnPushGymVisitEntrySubscription) => {
    const newVisit = visit.onPushGymVisitEntry;
    const activity: Activity = {
      memberEventId: newVisit?.memberEventId || 0,
      memberId: newVisit?.memberId || 0,
      memberEmail: newVisit?.memberEmail || '',
      eventId: newVisit?.eventId || 0,
      eventDescription: newVisit?.eventDescription || '',
      eventType: newVisit?.eventType || '',
      stageId: newVisit?.stageId || 0,
      stage: newVisit?.stage || '',
      createdTime: dayjs
        .utc(newVisit?.createdTime || '')
        .local()
        .format('HH:mm A'),
      isAutoAssigned: newVisit?.isAutoAssigned || false,
      memberEventStatusTypeId: newVisit?.memberEventStatusTypeId || 0,
      memberEventStatusType: newVisit?.memberEventStatusType || '',
      firstName: newVisit?.firstName || '',
      lastName: newVisit?.lastName || '',
      siteLocationId: newVisit?.siteLocationId || 0,
      locationName: newVisit?.locationName || '',
      lastModifiedBy: newVisit?.lastModifiedBy || 0,
      lastModifiedDate: newVisit?.lastModifiedDate || '',
      activityDate: dayjs
        .utc(newVisit?.createdTime || '')
        .local()
        .format('MM DD YYYY'),
      assignedUserId: newVisit?.assignedUserId || 0,
      assignmentHistoryId: newVisit?.assignmentHistoryId || 0,
      inquiryDescription: newVisit?.inquiryDescription || '',
      memberLocationId: newVisit?.memberLocationId || 0,
      informationReceivedEvent: newVisit?.informationReceivedEvent || false
    };
    setactivitiesList(activitiesList ? [activity, ...activitiesList] : [activity]);
  };

  return (
    <>
      {isActivityLoading ? (
        <Loader shape="table" />
      ) : (
        <>
          <div className="activty-feed">
            {/* TO-DO: Look into passing of an empty array */}
            <ActivityFeed
              activities={activitiesList || []}
              stage={'siteLocation'}
              refreshCallBack={() => setRefresh(!refresh)}></ActivityFeed>
          </div>
          <div className="text-center">
            {activitiesList?.length === 0 ? (
              <EmptyState textItems={[t('GYM_DASHBOARD_PAGE.EMPTY_STATE_TEXT')]} />
            ) : (
              <div className="button-view-all">
                <Button
                  data-cy={'dashboard-visits-previous-btn'}
                  variant={ButtonVariants.textonlyslim}
                  label={t('GYM_DASHBOARD.VIEW_PREVIOUS_ACTIVITY')}
                  icon="pi-angle-left"
                  iconPos="left"
                  disabled={offset === 0}
                  onClick={() => setOffset(offset - pageSize)}></Button>
                <Button
                  data-cy={'dashboard-visits-next-btn'}
                  variant={ButtonVariants.textonlyslim}
                  label={t('GYM_DASHBOARD.VIEW_NEXT_ACTIVITY')}
                  icon="pi-angle-right"
                  iconPos="right"
                  disabled={offset + pageSize >= totalActivityCount}
                  onClick={() => setOffset(offset + pageSize)}></Button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export interface GymDashboardActivityProps {
  gymId: number;
}

export default GymDashboardVisits;
