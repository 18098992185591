import { RefObject, useRef, useState, useEffect } from 'react';
import SettingsInfoTemplate from '@gym-templates/SettingsInfoTemplate/SettingsInfoTemplate';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { useAppSelector } from '@gym-redux/store';
import { SettingsElement } from '@gym-particles/types/GymChainSettings';

const MainColumn = (props: { alertRef: RefObject<AlertType> }) => {
  const { ruleId, chainId } = useParams<{ ruleId: string; chainId: string }>();
  const { t } = useTranslation();

  const rule = useAppSelector((state) =>
    state.gymChainSettings.items.find((g) => g.id === +ruleId)
  );

  const [ruleState, setRuleState] = useState<SettingsElement>();

  const createRule = async () => {
    const items = {
      id: rule?.id || 0,
      level: rule?.level || '',
      type: rule?.type || '',
      defaultRef: rule?.defaultRef || 0,
      serviceResponse: rule?.serviceResponse || '',
      accessStatus: rule?.accessStatus || '',
      maxLimit: rule?.maxLimit || 0,
      allowAccess: rule?.allowAccess || '',
      gracePeriod: rule?.gracePeriod || '',
      graceAccessCount: rule?.graceAccessCount || 0,
      isCustom: rule?.isCustom || false,
      noOfDays: rule?.noOfDays || 0,
      createdBy: rule?.createdBy || 0,
      createdDate: rule?.createdDate || '',
      lastModifiedBy: rule?.lastModifiedBy || 0,
      lastModifiedDate: rule?.lastModifiedDate || ''
    };

    setRuleState(items);
  };

  useEffect(() => {
    createRule();
  }, []);

  return (
    <Card className="pt30 pl40 pr40 pb30">
      <SettingsInfoTemplate
        type="modify"
        rule={ruleState}
        gymChainId={+chainId}
        alertRef={props.alertRef}
        alertMessage={t('MODIFY_SETTING_RULE.ALERT_MESSAGE_SUCCESS')}
      />
    </Card>
  );
};

const ModifyGymChainSettingsPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { chainId } = useParams<{ chainId: string }>();

  return (
    <TwoColTemplate
      title={t('MODIFY_SETTING_RULE.PAGE_TITLE')}
      gymChainId={+chainId}
      col1={<MainColumn alertRef={alertRef} />}
      alertRef={alertRef}
      backButtonHandler={() => history.push(`/gymChains/${chainId}/settings`)}
    />
  );
};

export default ModifyGymChainSettingsPage;
