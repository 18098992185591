import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { getActivityLoader } from './ActivityTemplate';

export type LoaderShape = 'circle' | 'rectangle' | 'activity' | 'table';
export type LoaderWidth = 'full' | 'half' | 'quarter';

const getRectangleSize = (width: LoaderWidth): string => {
  switch (width) {
    case 'full':
      return '100%';
    case 'half':
      return '50%';
    case 'quarter':
      return '25%';
  }
};

const getEmptyRows = () => new Array(3);
const getCellTemplate = (width = '50%') => <Skeleton width={width} borderRadius="10px"></Skeleton>;

const getDataTable = () => {
  return (
    <DataTable value={getEmptyRows()} className="p-datatable-striped">
      <Column field="code" header={getCellTemplate('70%')} body={getCellTemplate}></Column>
      <Column field="name" header={getCellTemplate('70%')} body={getCellTemplate}></Column>
      <Column field="category" header={getCellTemplate('70%')} body={getCellTemplate}></Column>
      <Column field="quantity" header={getCellTemplate('70%')} body={getCellTemplate}></Column>
    </DataTable>
  );
};

const Loader = ({
  shape = 'rectangle',
  width = 'full',
  radius = '50px',
  ...props
}: LoaderProps) => {
  switch (shape) {
    case 'rectangle':
      return <Skeleton width={getRectangleSize(width)} borderRadius="10px" {...props}></Skeleton>;
    case 'circle':
      return <Skeleton size={radius} shape="circle" {...props}></Skeleton>;
    case 'activity':
      return getActivityLoader();
    case 'table':
      return getDataTable();
  }
};

export interface LoaderProps {
  /** Shape of the Loader */
  shape: LoaderShape;
  /** Width of the Loader. Applicable only for rectangle loader */
  width?: LoaderWidth;
  /** radius of the Loader in pixels. Applicable only for circle loader */
  radius?: string;
}

export default Loader;
