import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { useTranslation } from 'react-i18next';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { useEffect, useState } from 'react';
import Loader from '@gym-atoms/Loader/Loader';
import { fetchGymChainSettings } from '@gym-redux/slices/gymChainSettingsSlice';
import { SettingsElement } from '@gym-particles/types/GymChainSettings';
import { useHistory } from 'react-router';
import { UserRole } from '@gym-particles/types/User';
import { API } from 'aws-amplify';
import { revertAccessRule } from '@gym-graphql/mutations';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Button from '@gym-atoms/Button/Button';
import Text from '@gym-atoms/Text/Text';

export const Settings = (props: GymChainSettingsTableProps) => {
  const dispatch = useAppDispatch();
  const chainId = props.gymChainId;
  const [selectedRow, setSelectedRow] = useState<SettingsElement>();
  const gymChainId = props.gymChainId;
  const { t } = useTranslation();
  const history = useHistory();
  const [gymChainSettingsSortField, setGymChainSettingsSortField] = useState('id');
  const [gymChainSettingsSortOrder, setGymChainSettingsSortOrder] = useState(-1);
  const isSettingsLoading = useAppSelector((state) => state.gymChainSettings.isSettingsLoading);
  const userRole = useAppSelector((state) => state.user.userRole);
  const userId = useAppSelector((state) => state.user.userId);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);

  const gymChainName = useAppSelector(
    (state) => state.gymChain.items.find((g) => g.id === +gymChainId)?.name
  );

  useEffect(() => {
    dispatch(fetchGymChainSettings({ siteId: +gymChainId }));
  }, []);

  const revertAccessRuleFunction = async (id: number | undefined, userId: number) => {
    try {
      await API.graphql({
        query: revertAccessRule,
        variables: {
          ruleId: id,
          userId: userId
        }
      });
      setTimeout(() => {
        dispatch(fetchGymChainSettings({ siteId: +gymChainId }));
      }, 200);
    } catch (e) {
      setFailedDialogVisible(true);
      console.log(e);
    }
  };

  const menuItem = [
    {
      label: t('COMMON.MODIFY'),
      command: () => {
        history.push(`/gymChains/${chainId}/settings/${selectedRow?.id}`);
      }
    },
    {
      label: t('COMMON.DEFAULT'),
      command: () => revertAccessRuleFunction(selectedRow?.id, userId)
    }
  ];

  const settings = useAppSelector((state) => state.gymChainSettings.items);

  const tablePropsGymChainSettings: TableProps<SettingsElement> = {
    exportFileName: `GymChainSettings-${gymChainName}`,
    data: settings || undefined,
    searchPlaceholderText: t('GYM_CHAIN_SETTINGS.SEARCH_PLACEHOLDER'),
    excelBtntext: t('GYM_CHAIN_SETTINGS.EXPORT_EXCEL_BUTTON_LABEL'),
    totalRecords: settings.length,
    selectedRow: selectedRow,
    setSelectedRow: setSelectedRow,
    setSortField: setGymChainSettingsSortField,
    setSortOrder: setGymChainSettingsSortOrder,
    sortField: gymChainSettingsSortField,
    sortOrder: gymChainSettingsSortOrder,
    emptyStateTexts: [t('GYM_CHAIN_SETTINGS.EMPTY_STATE_TEXT')],
    columns: [
      {
        field: 'level',
        header: t('GYM_CHAIN_SETTINGS.TABLE.HEADER_LEVEL'),
        toggable: false,
        sortable: true
      },
      {
        field: 'type',
        header: t('GYM_CHAIN_SETTINGS.TABLE.HEADER_TYPE'),
        toggable: false,
        sortable: true
      },
      {
        field: 'serviceResponse',
        header: t('GYM_CHAIN_SETTINGS.TABLE.HEADER_RESPONSE'),
        toggable: true
      },
      {
        field: 'accessStatus',
        header: t('GYM_CHAIN_SETTINGS.TABLE.HEADER_GYMAXESS_STATUS'),
        toggable: true
      },
      {
        field: 'allowAccess',
        header: t('GYM_CHAIN_SETTINGS.TABLE.HEADER_ALLOW_ACCESS'),
        toggable: true
      },
      {
        field: 'gracePeriod',
        header: t('GYM_CHAIN_SETTINGS.TABLE.HEADER_GRACE_PERIOD'),
        toggable: true
      },
      {
        field: 'graceAccessCount',
        header: t('GYM_CHAIN_SETTINGS.TABLE.HEADER_GRACE_COUNT'),
        toggable: true
      }
    ],
    isContextMenu:
      userRole == UserRole.SYSTEM_ADMIN || userRole == UserRole.GYM_CHAIN_USER ? true : false,
    menuItem:
      userRole === UserRole.SYSTEM_ADMIN || userRole == UserRole.GYM_CHAIN_USER ? menuItem : []
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t('GYM_CHAIN_SETTINGS.FORM_FAILURE_DIALOG_HEADER')}
          dialogFooter={
            <Button
              label={t('GYM_CHAIN_SETTINGS.FORM_FAILURE_DIALOG_BUTTON')}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t('GYM_CHAIN_SETTINGS.FORM_FAILURE_DIALOG_BODY')}</Text>}
        />
      </div>
    );
  };

  return (
    <div>
      {isSettingsLoading ? <Loader shape="table" /> : <Table {...tablePropsGymChainSettings} />}
      <ActionFailedDialog />
    </div>
  );
};

interface GymChainSettingsTableProps {
  gymChainId: number;
}
