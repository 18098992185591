import React from 'react';
import PropTypes from 'prop-types';
import logo from '@gym-assets/images/logo.png';
import Text, { TextSizes } from '@gym-atoms/Text/Text';

interface IAuthTemplateProps {
  children?: React.ReactNode;
  helperText?: string;
  helperTextMiddle?: string;
  helperTextBack?: string;
}

const AuthTemplate: React.FC<IAuthTemplateProps> = (props) => {
  return (
    <div className="auth-template-bg">
      <div className="auth-template-card">
        <div className="auth-template-card-header">
          <div className="auth-template-logo-wrapper">
            <img className="auth-template-logo-img" src={logo} alt="logo" />
          </div>
          <div className="auth-template-helper-text-wrapper">
            <Text className="auth-template-helper-text" size={TextSizes.t14}>
              {props.helperText} <span className="fw-bold">{props.helperTextMiddle}</span>{' '}
              {props.helperTextBack}
            </Text>
          </div>
        </div>
        <div className="auth-template-card-body pt40">{props.children}</div>
      </div>
    </div>
  );
};

export default AuthTemplate;

AuthTemplate.propTypes = {
  children: PropTypes.any,
  helperText: PropTypes.string,
  helperTextMiddle: PropTypes.string,
  helperTextBack: PropTypes.string
};
