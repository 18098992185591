import { FormEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Dropdown, { DropdownChangeEvent, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import Input from '@gym-atoms/Input/Input';
import Text from '@gym-atoms/Text/Text';
import Upload from '@gym-atoms/Upload/Upload';
import {
  GymType as Gym,
  GymFormInputs as FormInputs,
  gymDefaultValues
} from '@gym-particles/types/models.d';
import { UserRole } from '@gym-particles/types/User';
import {
  MindbodyImportedLocation,
  Gym as GymResponse,
  GymInput,
  SodvinImportedLocation,
  ImportSodvinDepartmentQuery,
  GetAllAccessCardTypesResponse,
  SiteLocationUserRecordInput
} from '@gym-src/API';
import {
  getAllAccessCardTypes,
  getGymByApiId,
  getGymByCompanyId,
  importLocation,
  importSodvinDepartment,
  importSodvinLocation,
  uploadImageUrlResolver
} from '@gym-graphql/queries';
import { addGym, addSiteLocationUserRecord, modifyGym } from '@gym-graphql/mutations';
import { fetchGyms } from '@gym-redux/slices/gymsSlice';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { fetchPlatformSettings } from '@gym-redux/slices/platformSettingSlice';
import * as Validator from '@gym-particles/validations/Validator';
import { fetchMembershipsForSiteLocation } from '@gym-redux/slices/membershipSlice';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';
import axios from 'axios';
import base64toFile from '@gym-particles/base64ToFile';
import { fetchAccessPoints } from '@gym-redux/slices/accessPointsSlice';
import Checkbox from '@gym-atoms/Checkbox/Checkbox';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const GymInfoTemplate = (props: GymInfoTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [imgUploadStatus, setImgUploadStatus] = useState('NONE');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [importErrorMessage, setImportErrorMessage] = useState<string | null>(null);
  const [dialogErrorMessage, setDialogErrorMessage] = useState<string | null>(null);
  const [importing, setImporting] = useState(false);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const userRole = useAppSelector((state) => state.user.userRole);
  const userId = useAppSelector((state) => state.user.userId);
  const [isInserted, setIsInserted] = useState(false);
  const [syncVisitsChecked, setSyncVisitsChecked] = useState<boolean | undefined>(
    props.gym?.syncAllVisitsWithGenericSubscription || false
  );
  const [
    allowConvertingCardNumbersToArxFormat,
    setAllowConvertingCardNumbersToArxFormat
  ] = useState<boolean | undefined>(false);
  const { chainId } = useParams<{ chainId: string }>();

  const providerId = useAppSelector((state) => state.gymChain.currentGymChain?.providerId);
  const currentGymChain = useAppSelector((state) => state.gymChain.currentGymChain);
  const [imported, setImported] = useState(false);
  const reduxGyms = useAppSelector((state) =>
    state.gyms.items.find((gymChainInfo) => gymChainInfo.chainId === props.gymChainId)
  )?.items;
  const membershipData = useAppSelector((state) =>
    state.membership.membershipSiteLocation
      .find((g) => g.siteLocationId === props.gym?.gymId)
      ?.items.filter((item) => item.validityPeriod)
  );

  const dropdownItems = membershipData?.map((membership) => {
    return { label: membership.membershipName, value: membership.SiteLocationMembershipId };
  });

  const accessPointList = useAppSelector(
    (state) => state.accessPoint.items.find((g) => g.siteLocationId === props.gym?.gymId)?.items
  );
  const isAllaccessPointsInactive = accessPointList?.every((a) => a.status === 'inactive')
    ? true
    : false;

  const handbackTime = useAppSelector(
    (state) =>
      state.platformSettings.find(
        (handBackTimeSetting) => handBackTimeSetting.setting === 'handBackTime'
      )?.value
  );
  const gymChain = useAppSelector((state) => state.gymChain.currentGymChain);
  const defaultValues: FormInputs = {
    ...gymDefaultValues,
    ...props.gym
  };

  useEffect(() => {
    dispatch(getGymChainById(+chainId));
    console.log(props.gym);
    props.gym && setAllowConvertingCardNumbersToArxFormat(props.gym?.allowConvertingAccessCards);
    props.gym && setSyncVisitsChecked(props.gym?.syncAllVisitsWithGenericSubscription);
    getAllAccessCards();
    console.log(syncVisitsChecked);
  }, [chainId]);

  useEffect(() => {
    dispatch(fetchPlatformSettings());
    dispatch(
      fetchMembershipsForSiteLocation({
        siteLocationId: props.gym?.gymId || 0,
        pagination: {
          offset: 0,
          pageSize: 100,
          sortField: 'membershipName',
          sortOrder: 1
        }
      })
    );
    dispatch(
      fetchAccessPoints({
        siteLocationId: props.gym?.gymId || 0
      })
    );
  }, []);

  const [formValues, setFormValues] = useState(defaultValues);

  /** Default values used for importing from 3rd party APIs */
  const [importParams, setImportParams] = useState<{
    provider: string;
    siteId: number;
    locationId: string;
  }>({
    provider: '',
    siteId: props.gym?.gymChainId || -1,
    locationId: props.gym?.gymIdApi ? String(props.gym?.gymIdApi) : ''
  });

  const [importSodvinParams, setImportSodvinParams] = useState<{
    provider: string;
    siteId: number;
    locationId: string;
  }>({
    provider: '',
    siteId: props.gym?.gymChainId || -1,
    locationId: props.gym?.sodvinCompanyId ? String(props.gym?.sodvinCompanyId) : ''
  });

  const { register, handleSubmit, setValue, errors, reset } = useForm<FormInputs>({
    defaultValues: defaultValues
  });

  /** Sets the import error message to be displayed on screen */
  const setImportError = (errorType: ImportErrorType) => {
    if (errorType === 'duplicateError') {
      setImportErrorMessage(t(getTranslationKey('FORM_IMPORT_EXISTS_ERROR')));
    } else if (errorType === 'importError') {
      setImportErrorMessage(t(getTranslationKey('FORM_IMPORT_ERROR')));
    } else if (errorType === 'duplicateChain') {
      setImportErrorMessage(t(getTranslationKey('FORM_ALREADY_IMPORTED_ERROR')));
    } else if (errorType === null) {
      setImportErrorMessage(null);
    }
  };

  const getTranslationKey = (e: string): string => {
    return props.type === 'new' ? 'ADD_NEW_GYM.' + e : 'MODIFY_GYM.' + e;
  };

  const uploadHandler = (f: File | null) => {
    setValue('imageUrl', f?.toString());
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={
            <Text>
              {dialogErrorMessage
                ? dialogErrorMessage
                : t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}
            </Text>
          }
        />
      </div>
    );
  };

  /** Returns true if successful, false otherwise */
  const createGymApiCall = async (data: FormInputs) => {
    const { ...newGym } = {
      ...data,
      gymName: data.gymName.replace(/'/g, "''"),
      arxReferenceId: data.arxReferenceId?.replace(/'/g, "''"),
      contactPerson: data.contactPerson.replace(/'/g, "''"),
      city: data.city?.replace(/'/g, "''"),
      facebookLink: data.facebookLink?.replace(/'/g, "''"),
      twitterLink: data.twitterLink?.replace(/'/g, "''"),
      instagramLink: data.instagramLink?.replace(/'/g, "''"),
      websiteLink: data.websiteLink?.replace(/'/g, "''"),
      externalBookingsLink: data.externalBookingsLink?.replace(/'/g, "''"),
      signUpUrl: data.signUpUrl?.replace(/'/g, "''"),
      address: data.address.replace(/\n/g, ',').replace(/'/g, "''"),
      latitude: data.latitude || -1,
      longitude: data.longitude || -1,
      gymChainId: props.gymChainId,
      createdBy: userId,
      lastModifiedBy: userId,
      allowConvertingAccessCards: allowConvertingCardNumbersToArxFormat
    };

    try {
      if (
        imported &&
        importSodvinParams.locationId &&
        providerId === 2 &&
        newGym.sodvinCompanyId != importSodvinParams.locationId
      ) {
        setDialogErrorMessage(t(getTranslationKey('FORM_FAILURE_DIALOG_BODY_IMPORT_ID_CHANGE')));
        return false;
      }
      const response = await (API.graphql({
        query: addGym,
        variables: {
          input: newGym
        }
      }) as Promise<{
        data: { addGym: Gym };
      }>);

      const SiteLocationUserInput: SiteLocationUserRecordInput = {
        siteId: response.data.addGym.gymChainId,
        siteLocationId: response.data.addGym.gymId,
        userId: userId
      };

      await API.graphql({
        query: addSiteLocationUserRecord,
        variables: {
          input: SiteLocationUserInput
        }
      });

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  /** Returns true if successful, false otherwise */
  const modifyGymApiCall = async (data: FormInputs) => {
    /**
     * Gym prop has many fields that we do not want for the modify API call.
     * This type is used to destructure and remove those fields.
     */
    type FieldsToExclude = {
      gymChainName: string;
      gymId: number;
      createdDate: string;
      lastModifiedDate: string;
      freePassId: string;
      staffAccessMembershipId: string;
      displayStatus: string;
    };

    const {
      gymChainName,
      gymId,
      createdDate,
      lastModifiedDate,
      freePassId,
      staffAccessMembershipId,
      displayStatus,
      ...modifiedGym
    }: Partial<FieldsToExclude> & Partial<GymInput> = {
      ...data,
      gymName: data.gymName.replace(/'/g, "''"),
      arxReferenceId: data.arxReferenceId?.replace(/'/g, "''"),
      contactPerson: data.contactPerson.replace(/'/g, "''"),
      city: data.city?.replace(/'/g, "''"),
      facebookLink: data.facebookLink?.replace(/'/g, "''"),
      twitterLink: data.twitterLink?.replace(/'/g, "''"),
      instagramLink: data.instagramLink?.replace(/'/g, "''"),
      websiteLink: data.websiteLink?.replace(/'/g, "''"),
      externalBookingsLink: data.externalBookingsLink?.replace(/'/g, "''"),
      signUpUrl: data.signUpUrl?.replace(/'/g, "''"),
      address: data.address.replace(/\n/g, ',').replace(/'/g, "''"),
      id: props.gym?.gymId,
      latitude: data.latitude || -1,
      longitude: data.longitude || -1,
      gymChainId: props.gymChainId,
      lastModifiedBy: userId,
      gracePeriodWhenDeclined: isNaN(Number(data.gracePeriodWhenDeclined))
        ? 0
        : data.gracePeriodWhenDeclined,
      gracePeriodWhenExpired: isNaN(Number(data.gracePeriodWhenExpired))
        ? 0
        : data.gracePeriodWhenExpired,
      gracePeriodWhenSuspended: isNaN(Number(data.gracePeriodWhenSuspended))
        ? 0
        : data.gracePeriodWhenSuspended,
      accessPerDayWhenDeclined: isNaN(Number(data.accessPerDayWhenDeclined))
        ? 0
        : data.accessPerDayWhenDeclined,
      accessPerDayWhenExpired: isNaN(Number(data.accessPerDayWhenExpired))
        ? 0
        : data.accessPerDayWhenExpired,
      accessPerDayWhenSuspended: isNaN(Number(data.accessPerDayWhenSuspended))
        ? 0
        : data.accessPerDayWhenSuspended,
      displayStatus: undefined,
      allowConvertingAccessCards: allowConvertingCardNumbersToArxFormat,
      sessionCheckTimeLimit: data.sessionCheckTimeLimit || 0,
      sessionCheckTimeLimitUpper: data.sessionCheckTimeLimitUpper || 0,
      syncAllVisitsWithGenericSubscription: syncVisitsChecked || false,
      markArrivalsWhenThereAreVisits: data.markArrivalsWhenThereAreVisits || false
    };

    try {
      await API.graphql({
        query: modifyGym,
        variables: {
          input: modifiedGym
        }
      });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  /** Runs on form submit, if validation has passed */
  const submitHandler = async (data: FormInputs) => {
    setIsInserted(true);
    let actionSuccess = false;
    if (props.type === 'modify' && props.gym) {
      if (data.defaultLocationDetectionMethod === 'bluetooth' && isAllaccessPointsInactive) {
        setDialogErrorMessage(t(getTranslationKey('FORM_FAILURE_DIALOG_BODY_BLUETOOTH')));
        setFailedDialogVisible(true);
      } else {
        actionSuccess = await modifyGymApiCall(data);
      }
    } else if (props.type === 'new') {
      if (data.defaultLocationDetectionMethod === 'bluetooth') {
        setDialogErrorMessage(t(getTranslationKey('FORM_FAILURE_DIALOG_BODY_BLUETOOTH')));
        setFailedDialogVisible(true);
      } else {
        actionSuccess = await createGymApiCall(data);
      }
    }

    if (actionSuccess === false) {
      setFailedDialogVisible(true);
    } else {
      await dispatch(
        fetchGyms({
          id: props.gymChainId,
          pagination: {
            offset: 0,
            pageSize: 10,
            sortField: 'siteId',
            sortOrder: 1,
            userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
          }
        })
      );
      history.push(`/gymChains/${props.gymChainId}/gyms`);
    }
    setIsInserted(false);
  };

  const changeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof FormInputs, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  const changeHandlerTA = (e: FormEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.name as keyof FormInputs, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  const dropdownChangeHandler = (e: DropdownChangeEvent) => {
    if (e.target.name === 'isMobileCredentialAllowed') {
      const newVal = e.value === 'true' ? true : false;
      setValue('isMobileCredentialAllowed', newVal);
      setFormValues({ ...formValues, isMobileCredentialAllowed: newVal });
      return;
    }
    setValue(e.target.name as keyof FormInputs, e.value);
    setFormValues({ ...formValues, [e.target.name]: e.value });
  };

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      switch (key) {
        case 'gymName':
          register(key as keyof FormInputs, {
            required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
            maxLength: {
              value: 255,
              message: t(getTranslationKey('FORM_FIELD_LENGTH_ERROR_255')) as string
            }
          });
          break;
        case 'arxReferenceId':
        case 'defaultLocationDetectionMethod':
          register(key as keyof FormInputs, {
            required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
          });
          break;
        case 'timeZone':
          register(key as keyof FormInputs, {
            required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
          });
          break;
        case 'longitude':
          register(key as keyof FormInputs, {
            required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
            valueAsNumber: true,
            validate: (value) => {
              if (!(isFinite(value) && Math.abs(value) <= 180)) {
                return t(getTranslationKey('FORM_LONGITUDE_INVALID_ERROR')) as string;
              }
            }
          });
          break;
        case 'latitude':
          register(key as keyof FormInputs, {
            required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
            valueAsNumber: true,
            validate: (value) => {
              if (!(isFinite(value) && Math.abs(value) <= 90)) {
                return t(getTranslationKey('FORM_LATITUDE_INVALID_ERROR')) as string;
              }
            }
          });
          break;
        case 'membershipForVisits':
          register(key as keyof FormInputs, {
            required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
          });
          break;
        case 'contactPerson':
          register(key as keyof FormInputs, {
            maxLength: {
              value: 255,
              message: t(getTranslationKey('FORM_FIELD_LENGTH_ERROR_255')) as string
            }
          });
          break;
        case 'phoneNumber':
          register(key as keyof FormInputs, {
            pattern: {
              value: Validator.validPhoneNumberRegEx,
              message: t(getTranslationKey('FORM_PHONE_INVALID_ERROR')) as string
            },
            maxLength: {
              value: 20,
              message: t(getTranslationKey('FORM_FIELD_LENGTH_ERROR_20')) as string
            }
          });
          break;
        case 'email':
          register(key as keyof FormInputs, {
            pattern: {
              value: Validator.validEmailRegEx,
              message: t(getTranslationKey('FORM_EMAIL_INVALID_ERROR')) as string
            },
            maxLength: {
              value: 255,
              message: t(getTranslationKey('FORM_FIELD_LENGTH_ERROR_255')) as string
            }
          });
          break;
        case 'address':
          register(key as keyof FormInputs, {
            maxLength: {
              value: 1024,
              message: t(getTranslationKey('FORM_FIELD_LENGTH_ERROR_1024')) as string
            }
          });
          break;
        case 'externalBookingsLink':
        case 'signUpUrl':
        case 'facebookLink':
        case 'instagramLink':
        case 'twitterLink':
        case 'websiteLink':
          register(key as keyof FormInputs, {
            maxLength: {
              value: 2000,
              message: t(getTranslationKey('FORM_FIELD_LENGTH_ERROR_2000')) as string
            }
          });
          break;
        case 'handbackTime':
          register(key as keyof FormInputs, {
            required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
          });
          break;
        case 'gracePeriodWhenDeclined':
          register(key as keyof FormInputs, {
            valueAsNumber: true,
            validate: (value) => {
              if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
                return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
              }
            }
          });
          break;
        case 'accessPerDayWhenDeclined':
          register(key as keyof FormInputs, {
            valueAsNumber: true,
            validate: (value) => {
              if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
                return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
              }
            }
          });
          break;
        case 'gracePeriodWhenExpired':
          register(key as keyof FormInputs, {
            valueAsNumber: true,
            validate: (value) => {
              if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
                return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
              }
            }
          });
          break;
        case 'accessPerDayWhenExpired':
          register(key as keyof FormInputs, {
            valueAsNumber: true,
            validate: (value) => {
              if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
                return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
              }
            }
          });
          break;
        case 'gracePeriodWhenSuspended':
          register(key as keyof FormInputs, {
            valueAsNumber: true,
            validate: (value) => {
              if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
                return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
              }
            }
          });
          break;
        case 'accessPerDayWhenSuspended':
          register(key as keyof FormInputs, {
            valueAsNumber: true,
            validate: (value) => {
              if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
                return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
              }
            }
          });
          break;
        case 'blockTime':
          register(key as keyof FormInputs, {
            valueAsNumber: true,
            validate: (value) => {
              if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
                return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
              }
              if (value > 1440) {
                return t(getTranslationKey('FORM_BLOCK_TIME_INVALID_ERROR')) as string;
              }
            }
          });
          break;
        case 'sessionCheckTimeLimit':
          register(key as keyof FormInputs, {
            valueAsNumber: true,
            validate: (value) => {
              if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
                return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
              }
            }
          });
          break;
        case 'sessionCheckTimeLimitUpper':
          register(key as keyof FormInputs, {
            valueAsNumber: true,
            validate: (value) => {
              if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
                return t(getTranslationKey('FORM_FIELD_POSITIVE_INTEGER_ERROR')) as string;
              }
            }
          });
          break;
        case 'cardType':
        case 'addArrivalAuto':
        case 'addVisitAuto':
        case 'prioratizeClasses':
        case 'allowConvertingCardNumbersToArx':
        case 'markArrivalFirst':
        case 'openWithoutArrivalMarked':
        case 'markMultipleAttendanceAuto':
        case 'markArrivalsWhenThereAreVisits':
        default:
          register(key as keyof FormInputs);
      }
    });
  }, [register]);

  useEffect(() => {
    if (imgUploadStatus === 'INPROGRESS') {
      setSubmitDisabled(true);
    } else if (imgUploadStatus === 'SUCCESS') {
      setSubmitDisabled(false);
    } else if (imgUploadStatus === 'NONE') {
      setSubmitDisabled(false);
    }
  }, [imgUploadStatus]);

  const dropdownDefaultLocationDetectionMethodItems = [
    { label: t(getTranslationKey('FORM_DROPDOWN_DEFAULT_LOCATION_DETECTION.GPS')), value: 'gps' },
    {
      label: t(getTranslationKey('FORM_DROPDOWN_DEFAULT_LOCATION_DETECTION.BLUETOOTH')),
      value: 'bluetooth'
    }
  ];

  const dropdownPrimaryAccessMethodItems = [
    {
      label: t(getTranslationKey('FORM_DROPDOWN_PRIMARY_ACCESS.MOBILE_CREDENTIAL')),
      value: 'mobileCredential'
    },
    { label: t(getTranslationKey('FORM_DROPDOWN_PRIMARY_ACCESS.ARX')), value: 'arx' }
  ];

  const dropdownStatusItems = [
    { label: t(getTranslationKey('FORM_DROPDOWN_STATUS.ACTIVE')), value: 'active' },
    { label: t(getTranslationKey('FORM_DROPDOWN_STATUS.INACTIVE')), value: 'inactive' }
  ];

  const timeZoneItems = [
    'Africa/Abidjan',
    'Africa/Accra',
    'Africa/Addis_Ababa',
    'Africa/Algiers',
    'Africa/Asmera',
    'Africa/Bamako',
    'Africa/Bangui',
    'Africa/Banjul',
    'Africa/Bissau',
    'Africa/Blantyre',
    'Africa/Brazzaville',
    'Africa/Bujumbura',
    'Africa/Cairo',
    'Africa/Casablanca',
    'Africa/Ceuta',
    'Africa/Conakry',
    'Africa/Dakar',
    'Africa/Dar_es_Salaam',
    'Africa/Djibouti',
    'Africa/Douala',
    'Africa/El_Aaiun',
    'Africa/Freetown',
    'Africa/Gaborone',
    'Africa/Harare',
    'Africa/Johannesburg',
    'Africa/Juba',
    'Africa/Kampala',
    'Africa/Khartoum',
    'Africa/Kigali',
    'Africa/Kinshasa',
    'Africa/Lagos',
    'Africa/Libreville',
    'Africa/Lome',
    'Africa/Luanda',
    'Africa/Lubumbashi',
    'Africa/Lusaka',
    'Africa/Malabo',
    'Africa/Maputo',
    'Africa/Maseru',
    'Africa/Mbabane',
    'Africa/Mogadishu',
    'Africa/Monrovia',
    'Africa/Nairobi',
    'Africa/Ndjamena',
    'Africa/Niamey',
    'Africa/Nouakchott',
    'Africa/Ouagadougou',
    'Africa/Porto-Novo',
    'Africa/Sao_Tome',
    'Africa/Tripoli',
    'Africa/Tunis',
    'Africa/Windhoek',
    'America/Adak',
    'America/Anchorage',
    'America/Anguilla',
    'America/Antigua',
    'America/Araguaina',
    'America/Argentina/La_Rioja',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Salta',
    'America/Argentina/San_Juan',
    'America/Argentina/San_Luis',
    'America/Argentina/Tucuman',
    'America/Argentina/Ushuaia',
    'America/Aruba',
    'America/Asuncion',
    'America/Bahia',
    'America/Bahia_Banderas',
    'America/Barbados',
    'America/Belem',
    'America/Belize',
    'America/Blanc-Sablon',
    'America/Boa_Vista',
    'America/Bogota',
    'America/Boise',
    'America/Buenos_Aires',
    'America/Cambridge_Bay',
    'America/Campo_Grande',
    'America/Cancun',
    'America/Caracas',
    'America/Catamarca',
    'America/Cayenne',
    'America/Cayman',
    'America/Chicago',
    'America/Chihuahua',
    'America/Ciudad_Juarez',
    'America/Coral_Harbour',
    'America/Cordoba',
    'America/Costa_Rica',
    'America/Creston',
    'America/Cuiaba',
    'America/Curacao',
    'America/Danmarkshavn',
    'America/Dawson',
    'America/Dawson_Creek',
    'America/Denver',
    'America/Detroit',
    'America/Dominica',
    'America/Edmonton',
    'America/Eirunepe',
    'America/El_Salvador',
    'America/Fort_Nelson',
    'America/Fortaleza',
    'America/Glace_Bay',
    'America/Godthab',
    'America/Goose_Bay',
    'America/Grand_Turk',
    'America/Grenada',
    'America/Guadeloupe',
    'America/Guatemala',
    'America/Guayaquil',
    'America/Guyana',
    'America/Halifax',
    'America/Havana',
    'America/Hermosillo',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indianapolis',
    'America/Inuvik',
    'America/Iqaluit',
    'America/Jamaica',
    'America/Jujuy',
    'America/Juneau',
    'America/Kentucky/Monticello',
    'America/Kralendijk',
    'America/La_Paz',
    'America/Lima',
    'America/Los_Angeles',
    'America/Louisville',
    'America/Lower_Princes',
    'America/Maceio',
    'America/Managua',
    'America/Manaus',
    'America/Marigot',
    'America/Martinique',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Mendoza',
    'America/Menominee',
    'America/Merida',
    'America/Metlakatla',
    'America/Mexico_City',
    'America/Miquelon',
    'America/Moncton',
    'America/Monterrey',
    'America/Montevideo',
    'America/Montserrat',
    'America/Nassau',
    'America/New_York',
    'America/Nipigon',
    'America/Nome',
    'America/Noronha',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Ojinaga',
    'America/Panama',
    'America/Pangnirtung',
    'America/Paramaribo',
    'America/Phoenix',
    'America/Port-au-Prince',
    'America/Port_of_Spain',
    'America/Porto_Velho',
    'America/Puerto_Rico',
    'America/Punta_Arenas',
    'America/Rainy_River',
    'America/Rankin_Inlet',
    'America/Recife',
    'America/Regina',
    'America/Resolute',
    'America/Rio_Branco',
    'America/Santa_Isabel',
    'America/Santarem',
    'America/Santiago',
    'America/Santo_Domingo',
    'America/Sao_Paulo',
    'America/Scoresbysund',
    'America/Sitka',
    'America/St_Barthelemy',
    'America/St_Johns',
    'America/St_Kitts',
    'America/St_Lucia',
    'America/St_Thomas',
    'America/St_Vincent',
    'America/Swift_Current',
    'America/Tegucigalpa',
    'America/Thule',
    'America/Thunder_Bay',
    'America/Tijuana',
    'America/Toronto',
    'America/Tortola',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Winnipeg',
    'America/Yakutat',
    'America/Yellowknife',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Macquarie',
    'Antarctica/Mawson',
    'Antarctica/McMurdo',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Arctic/Longyearbyen',
    'Asia/Aden',
    'Asia/Almaty',
    'Asia/Amman',
    'Asia/Anadyr',
    'Asia/Aqtau',
    'Asia/Aqtobe',
    'Asia/Ashgabat',
    'Asia/Atyrau',
    'Asia/Baghdad',
    'Asia/Bahrain',
    'Asia/Baku',
    'Asia/Bangkok',
    'Asia/Barnaul',
    'Asia/Beirut',
    'Asia/Bishkek',
    'Asia/Brunei',
    'Asia/Calcutta',
    'Asia/Chita',
    'Asia/Choibalsan',
    'Asia/Colombo',
    'Asia/Damascus',
    'Asia/Dhaka',
    'Asia/Dili',
    'Asia/Dubai',
    'Asia/Dushanbe',
    'Asia/Famagusta',
    'Asia/Gaza',
    'Asia/Hebron',
    'Asia/Hong_Kong',
    'Asia/Hovd',
    'Asia/Irkutsk',
    'Asia/Jakarta',
    'Asia/Jayapura',
    'Asia/Jerusalem',
    'Asia/Kabul',
    'Asia/Kamchatka',
    'Asia/Karachi',
    'Asia/Katmandu',
    'Asia/Khandyga',
    'Asia/Krasnoyarsk',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Asia/Kuwait',
    'Asia/Macau',
    'Asia/Magadan',
    'Asia/Makassar',
    'Asia/Manila',
    'Asia/Muscat',
    'Asia/Nicosia',
    'Asia/Novokuznetsk',
    'Asia/Novosibirsk',
    'Asia/Omsk',
    'Asia/Oral',
    'Asia/Phnom_Penh',
    'Asia/Pontianak',
    'Asia/Pyongyang',
    'Asia/Qatar',
    'Asia/Qostanay',
    'Asia/Qyzylorda',
    'Asia/Rangoon',
    'Asia/Riyadh',
    'Asia/Saigon',
    'Asia/Sakhalin',
    'Asia/Samarkand',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Srednekolymsk',
    'Asia/Taipei',
    'Asia/Tashkent',
    'Asia/Tbilisi',
    'Asia/Tehran',
    'Asia/Thimphu',
    'Asia/Tokyo',
    'Asia/Tomsk',
    'Asia/Ulaanbaatar',
    'Asia/Urumqi',
    'Asia/Ust-Nera',
    'Asia/Vientiane',
    'Asia/Vladivostok',
    'Asia/Yakutsk',
    'Asia/Yekaterinburg',
    'Asia/Yerevan',
    'Atlantic/Azores',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faeroe',
    'Atlantic/Madeira',
    'Atlantic/Reykjavik',
    'Atlantic/South_Georgia',
    'Atlantic/St_Helena',
    'Atlantic/Stanley',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Broken_Hill',
    'Australia/Currie',
    'Australia/Darwin',
    'Australia/Eucla',
    'Australia/Hobart',
    'Australia/Lindeman',
    'Australia/Lord_Howe',
    'Australia/Melbourne',
    'Australia/Perth',
    'Australia/Sydney',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Astrakhan',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Busingen',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Guernsey',
    'Europe/Helsinki',
    'Europe/Isle_of_Man',
    'Europe/Istanbul',
    'Europe/Jersey',
    'Europe/Kaliningrad',
    'Europe/Kiev',
    'Europe/Kirov',
    'Europe/Lisbon',
    'Europe/Ljubljana',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Mariehamn',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Podgorica',
    'Europe/Prague',
    'Europe/Riga',
    'Europe/Rome',
    'Europe/Samara',
    'Europe/San_Marino',
    'Europe/Sarajevo',
    'Europe/Saratov',
    'Europe/Simferopol',
    'Europe/Skopje',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Tallinn',
    'Europe/Tirane',
    'Europe/Ulyanovsk',
    'Europe/Uzhgorod',
    'Europe/Vaduz',
    'Europe/Vatican',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Volgograd',
    'Europe/Warsaw',
    'Europe/Zagreb',
    'Europe/Zaporozhye',
    'Europe/Zurich',
    'Indian/Antananarivo',
    'Indian/Chagos',
    'Indian/Christmas',
    'Indian/Cocos',
    'Indian/Comoro',
    'Indian/Kerguelen',
    'Indian/Mahe',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Mayotte',
    'Indian/Reunion',
    'Pacific/Apia',
    'Pacific/Auckland',
    'Pacific/Bougainville',
    'Pacific/Chatham',
    'Pacific/Easter',
    'Pacific/Efate',
    'Pacific/Enderbury',
    'Pacific/Fakaofo',
    'Pacific/Fiji',
    'Pacific/Funafuti',
    'Pacific/Galapagos',
    'Pacific/Gambier',
    'Pacific/Guadalcanal',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Johnston',
    'Pacific/Kiritimati',
    'Pacific/Kosrae',
    'Pacific/Kwajalein',
    'Pacific/Majuro',
    'Pacific/Marquesas',
    'Pacific/Midway',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Noumea',
    'Pacific/Pago_Pago',
    'Pacific/Palau',
    'Pacific/Pitcairn',
    'Pacific/Ponape',
    'Pacific/Port_Moresby',
    'Pacific/Rarotonga',
    'Pacific/Saipan',
    'Pacific/Tahiti',
    'Pacific/Tarawa',
    'Pacific/Tongatapu',
    'Pacific/Truk',
    'Pacific/Wake',
    'Pacific/Wallis'
  ].map((tz) => ({
    label: `${tz} (GMT ${dayjs.tz(new Date(), tz).format().slice(19)})`,
    value: tz
  }));

  const mobileCredentialAllowedItems = [
    { label: t(getTranslationKey('FORM_DROPDOWN_MOBILE_CREDENTIAL_ALLOWED.TRUE')), value: 'true' },
    { label: t(getTranslationKey('FORM_DROPDOWN_MOBILE_CREDENTIAL_ALLOWED.FALSE')), value: 'false' }
  ];

  const trueFalseDropDownItems = [
    { label: t(getTranslationKey('TRUE_FALSE_DROP_DOWN_ITEMS.TRUE')), value: 'true' },
    { label: t(getTranslationKey('TRUE_FALSE_DROP_DOWN_ITEMS.FALSE')), value: 'false' }
  ];

  const [previouslyUsedCardTypeDropDownItems, setPreviouslyUsedCardTypeDropDownItems] = useState<
    Array<SelectItem>
  >([]);
  /** Updates the state variable containing access cards dropdown items */
  const getAllAccessCards = async () => {
    try {
      const response = (API.graphql({
        query: getAllAccessCardTypes
      }) as Promise<{
        data: { getAllAccessCardTypes: GetAllAccessCardTypesResponse[] };
      }>).then((e) => {
        const accessCardsDropDownItems: SelectItem[] =
          e.data.getAllAccessCardTypes.map((data) => {
            return {
              label: data.accessCard || '',
              value: data.value || ''
            };
          }) || [];
        setPreviouslyUsedCardTypeDropDownItems(accessCardsDropDownItems);

        // props.type === 'modify' &&
        //   setDefaultSelectedMembershipId(props.accessCategoryMapping?.membershipId || -1);
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const resetForm = () => {
    defaultValues.handbackTime = handbackTime ? +handbackTime : 0;
    setFormValues(defaultValues);
    reset(defaultValues);
    setImported(false);
  };

  /**
   * Checks if this gym has already been imported to this gymchain.
   * If already imported, returns true, and importing should stop.
   */
  const checkGymAlreadyImported = async (locationId: number): Promise<boolean> => {
    // First checking the store
    const reduxResult = reduxGyms?.find((gym) => gym.gymIdApi === locationId);
    if (reduxResult) {
      return true;
    }

    try {
      const apiResponse = await (API.graphql({
        query: getGymByApiId,
        variables: {
          gymChainId: props.gymChainId,
          gymApiId: locationId
        }
      }) as Promise<{
        data: { getGymByApiId: GymResponse[] };
      }>);

      if (apiResponse.data.getGymByApiId.length === 0) {
        return false;
      }
      const searchResult = apiResponse.data.getGymByApiId.find(
        (gym) => gym.gymIdApi === locationId
      );
      if (searchResult) {
        return true;
      }
    } catch (e) {
      setImportError('importError');
      setImporting(false);
      resetForm();
      console.log('checkGymAlreadyImported failed', e);
    }

    // Allow to import this since we don't already have it
    return false;
  };

  const checkSodvinGymAlreadyImported = async (locationId: string): Promise<boolean> => {
    const reduxResult = reduxGyms?.find((gym) => gym.sodvinCompanyId === locationId);
    if (reduxResult) {
      return true;
    }

    try {
      const apiResponse = await (API.graphql({
        query: getGymByCompanyId,
        variables: {
          gymChainId: props.gymChainId,
          gymCompanyId: locationId
        }
      }) as Promise<{
        data: { getGymByCompanyId: GymResponse[] };
      }>);

      if (apiResponse.data.getGymByCompanyId.length === 0) {
        return false;
      }
      const searchResult = apiResponse.data.getGymByCompanyId.find(
        (gym) => gym.sodvinCompanyId === locationId
      );
      if (searchResult) {
        return true;
      }
    } catch (error) {
      setImportError('importError');
      setImporting(false);
      resetForm();
      console.log('checkSodvinGymAlreadyImported failed', error);
    }
    return false;
  };

  /** Parses data imported from 3rd party APIs */
  const parseImportedData = (importedLocation: MindbodyImportedLocation) => {
    const updatedFormVals = formValues;

    setValue('gymIdApi', importedLocation.Id);
    setValue('lastImportedDate', new Date().toLocaleString());

    if (importedLocation.Name) {
      setValue('gymName', importedLocation.Name);
      updatedFormVals.gymName = importedLocation.Name;
    }

    if (importedLocation.Address) {
      setValue('address', importedLocation.Address + ' ' + importedLocation.Address2);
      updatedFormVals.address = importedLocation.Address + ' ' + importedLocation.Address2;
    }

    if (importedLocation.Latitude) {
      setValue('latitude', importedLocation.Latitude);
      updatedFormVals.latitude = importedLocation.Latitude;
    }

    if (importedLocation.Longitude) {
      setValue('longitude', importedLocation.Longitude);
      updatedFormVals.longitude = importedLocation.Longitude;
    }

    if (importedLocation.Phone) {
      setValue('phoneNumber', importedLocation.Phone);
      updatedFormVals.phoneNumber = importedLocation.Phone;
    }

    if (importedLocation.City) {
      setValue('city', importedLocation.City);
      updatedFormVals.city = importedLocation.City;
    }

    if (importedLocation.AdditionalImageURLs) {
      const url = importedLocation.AdditionalImageURLs[0];
      if (url) {
        setValue('imageUrl', url);
        updatedFormVals.imageUrl = url;
      }
    }

    setFormValues(updatedFormVals);
  };

  const getImageUrl = async (file: File) => {
    try {
      const response = (await API.graphql({
        query: uploadImageUrlResolver,
        variables: {
          imageName: file.name
        }
      })) as { data: { uploadImageUrlResolver: { uploadUrl: string; url: any } } };

      await axios.put(response.data.uploadImageUrlResolver.uploadUrl, file, {
        headers: new Headers({
          'Content-Type': 'image/*'
        })
      });

      setImgUploadStatus('SUCCESS');

      uploadHandler(response.data.uploadImageUrlResolver.url);
    } catch (err) {
      setImgUploadStatus('ERROR');
    }
  };

  const parseSodvinImportedData = (importedLocation: any) => {
    const updatedFormVals = formValues;

    setValue('sodvinCompanyId', importedLocation.organizationNumber);
    setValue('lastImportedDate', new Date().toLocaleString());

    if (importedLocation.name) {
      setValue('gymName', importedLocation.name);
      updatedFormVals.gymName = importedLocation.name;
    }

    if (importedLocation.address1) {
      setValue(
        'address',
        importedLocation.address1 +
          ' ' +
          importedLocation.address2 +
          ' ' +
          importedLocation.zip +
          ' ' +
          importedLocation.postOffice
      );
      updatedFormVals.address =
        importedLocation.address1 +
        ' ' +
        importedLocation.address2 +
        ' ' +
        importedLocation.zip +
        ' ' +
        importedLocation.postOffice;
    }

    if (importedLocation.telephone) {
      setValue('phoneNumber', importedLocation.telephone);
      updatedFormVals.phoneNumber = importedLocation.telephone;
    }

    if (importedLocation.email) {
      setValue('email', importedLocation.email);
      updatedFormVals.email = importedLocation.email;
    }

    if (importedLocation.webAddress) {
      setValue('websiteLink', importedLocation.webAddress);
      updatedFormVals.websiteLink = importedLocation.webAddress;
    }

    if (importedLocation.logo) {
      const imageFile = base64toFile(
        importedLocation.logo,
        `sodvinLocationImage-${importedLocation.organizationNumber}`
      );

      getImageUrl(imageFile);

      updatedFormVals.imageUrl = importedLocation.logo;
    }

    setFormValues(updatedFormVals);
  };

  const mindbodyImportHandler = async () => {
    setImportError(null);
    setImporting(true);

    const importLocationId = parseInt(importParams.locationId);
    if (isNaN(importLocationId)) {
      setImportError('importError');
      setImporting(false);
      resetForm();
      return;
    }

    const alreadyImported = await checkGymAlreadyImported(importLocationId);
    if (alreadyImported) {
      setImportError('duplicateError');
      setImporting(false);
      resetForm();
      return;
    }

    const provider = providerId === 1 ? 'mindbody' : providerId === 2 ? 'sodvin' : '';
    setImportParams({ ...importParams, provider: provider });

    try {
      const res = await (API.graphql({
        query: importLocation,
        variables: {
          provider: provider,
          siteId: gymChain?.customerSiteId,
          locationId: importLocationId
        }
      }) as Promise<{ data: { importLocation: MindbodyImportedLocation } }>);

      if (res.data.importLocation === null) {
        console.log('Import error', res);
        setImportError('importError');
        setImporting(false);
        resetForm();
        return;
      }

      const importedLocation = res.data.importLocation;
      if (importedLocation === null || importedLocation === undefined) {
        console.log('Import error', res);
        setImportError('importError');
        resetForm();
      } else {
        parseImportedData(importedLocation);
      }
      setImporting(false);
    } catch (e) {
      setImportError('importError');
      setImporting(false);
      resetForm();
      console.log('Import error', e);
    }
  };

  const sodvinImportHandler = async () => {
    setImportError(null);
    setImporting(true);

    const importLocationId = importSodvinParams.locationId;

    if (importLocationId === '') {
      setImportError('importError');
      setImporting(false);
      resetForm();
      return;
    }

    const alreadyImported = await checkSodvinGymAlreadyImported(importLocationId);
    if (alreadyImported) {
      setImportError('duplicateError');
      setImporting(false);
      resetForm();
      return;
    }

    const provider = providerId === 1 ? 'mindbody' : providerId === 2 ? 'sodvin' : '';
    setImportParams({ ...importParams, provider: provider });

    if (currentGymChain?.isSodvinCompany) {
      try {
        const res = await (API.graphql({
          query: importSodvinDepartment,
          variables: {
            sodvinCompanyId: currentGymChain.customerSiteId,
            sodvinDepartmentId: importLocationId
          }
        }) as Promise<{ data: { importSodvinDepartment: ImportSodvinDepartmentQuery } }>);
        const response: any = res.data.importSodvinDepartment;
        if (!response.organizationNumber) {
          console.log('Import error', res);
          setImportError('importError');
          setImporting(false);
          resetForm();
          return;
        }
        const importedDepartment = res.data.importSodvinDepartment;
        parseSodvinImportedData(importedDepartment);
        setImporting(false);
        setImported(true);
      } catch (error) {
        setImportError('importError');
        setImporting(false);
        resetForm();
        console.log('Import error', error);
      }
    } else {
      try {
        const res = await (API.graphql({
          query: importSodvinLocation,
          variables: {
            sodvinCompanyId: importLocationId
          }
        }) as Promise<{ data: { importSodvinLocation: SodvinImportedLocation } }>);

        if (
          res.data.importSodvinLocation.error &&
          res.data.importSodvinLocation.errorMsg === 'SITE_ALREADY_EXISTS'
        ) {
          setImportError('duplicateChain');
          resetForm();
          return;
        }

        if (
          res.data.importSodvinLocation.error ||
          !res.data.importSodvinLocation.organizationNumber
        ) {
          console.log('Import error', res);
          setImportError('importError');
          resetForm();
          return;
        }
        const importedLocation = res.data.importSodvinLocation;
        parseSodvinImportedData(importedLocation);
        setImporting(false);
        setImported(true);
      } catch (error) {
        setImportError('importError');
        resetForm();
        console.log('Import error', error);
      } finally {
        setImporting(false);
      }
    }
  };

  useEffect(() => {
    if (formValues.handbackTime === -1 && handbackTime) {
      setFormValues({
        ...formValues,
        handbackTime: +handbackTime
      });
      setValue('handbackTime', +handbackTime);
    }
  }, [handbackTime]);

  return (
    <div className="row">
      <div className="col-md-7">
        {providerId === 1 && (
          <div className="d-flex flex-xxl-row flex-column align-items-xxl-end">
            <div className="mr15 mb18 flex-grow-1 input-mb0">
              <Input
                data-cy={'gym-info-temp-form-input-location-id'}
                label={t(getTranslationKey('FORM_LOCATION_ID'))}
                placeholder={t(getTranslationKey('FORM_LOCATION_ID_PLACEHOLDER'))}
                onChange={(e) =>
                  setImportParams({ ...importParams, locationId: e.currentTarget.value })
                }
                value={importParams.locationId}
                disabled={props.type === 'modify'}
              />
            </div>
            <div>
              <div className="mb18">
                <Button
                  data-cy={'gym-info-temp-form-btn-import'}
                  label={t(getTranslationKey('FORM_BTN_IMPORT'))}
                  size={ButtonSizes.large}
                  icon={importing ? 'pi-spinner pi-spin' : ''}
                  onClick={mindbodyImportHandler}
                  disabled={props.type === 'modify'}
                />
              </div>
            </div>
          </div>
        )}
        {providerId === 2 && (
          <div className="d-flex flex-xxl-row flex-column align-items-xxl-end">
            <div className="mr15 mb18 flex-grow-1 input-mb0">
              <Input
                data-cy={'gym-info-temp-form-input-company-id'}
                label={t(getTranslationKey('FORM_SODIV_COMPANY_ID'))}
                placeholder={t(getTranslationKey('FORM_SODIV_COMPANY_ID_PLACEHOLDER'))}
                onChange={(e) =>
                  setImportSodvinParams({
                    ...importSodvinParams,
                    locationId: e.currentTarget.value
                  })
                }
                value={importSodvinParams.locationId}
                disabled={props.type === 'modify'}
              />
            </div>
            <div>
              <div className="mb18">
                <Button
                  data-cy={'gym-info-temp-form-sodvin-btn-import'}
                  label={t(getTranslationKey('FORM_BTN_IMPORT'))}
                  size={ButtonSizes.large}
                  icon={importing ? 'pi-spinner pi-spin' : ''}
                  onClick={sodvinImportHandler}
                  disabled={props.type === 'modify'}
                />
              </div>
            </div>
          </div>
        )}
        <div className="row mb18">
          {importErrorMessage && (
            <p data-cy={'gym-info-temp-form-btn-import-error'} className="p-error mb-15">
              {importErrorMessage}
            </p>
          )}
        </div>
        <div className="row">
          <div className="col">
            <form onSubmit={handleSubmit(submitHandler)} noValidate>
              <Input
                data-cy={'gym-info-temp-form-input-arxReferenceId'}
                name="arxReferenceId"
                label={t(getTranslationKey('FORM_ARX_GYM_REFERENCE_ID'))}
                value={formValues.arxReferenceId}
                onChange={changeHandler}
                required={true}
                error={errors.arxReferenceId ? true : false}
                errorMessage={errors.arxReferenceId?.message}
              />
              <Input
                data-cy={'gym-info-temp-form-input-name'}
                name="gymName"
                label={t(getTranslationKey('FORM_NAME'))}
                required={true}
                value={formValues.gymName}
                onChange={changeHandler}
                error={errors.gymName ? true : false}
                errorMessage={errors.gymName?.message}
              />
              <Input
                data-cy={'gym-info-temp-form-input-contact-person'}
                name="contactPerson"
                label={t(getTranslationKey('FORM_CONTACT_PERSON'))}
                value={formValues.contactPerson}
                onChange={changeHandler}
                error={errors.contactPerson ? true : false}
                errorMessage={errors.contactPerson?.message}
              />
              <Input
                data-cy={'gym-info-temp-form-input-address'}
                name="address"
                label={t(getTranslationKey('FORM_ADDRESS'))}
                variant="textarea"
                value={formValues.address}
                onChangeTA={changeHandlerTA}
                error={errors.address ? true : false}
                errorMessage={errors.address?.message}
              />
              <Input
                data-cy={'gym-info-temp-form-input-city'}
                name="city"
                label={t(getTranslationKey('FORM_CITY'))}
                value={formValues.city}
                onChange={changeHandler}
                error={errors.city ? true : false}
                errorMessage={errors.city?.message}
              />
              <div className="row">
                <div className="col">
                  <Input
                    data-cy={'gym-info-temp-form-input-email'}
                    name="email"
                    label={t(getTranslationKey('FORM_EMAIL'))}
                    value={formValues.email}
                    onChange={changeHandler}
                    error={errors.email ? true : false}
                    errorMessage={errors.email?.message}
                  />
                </div>
                <div className="col">
                  <Input
                    data-cy={'gym-info-temp-form-input-phone-number'}
                    name="phoneNumber"
                    label={t(getTranslationKey('FORM_PHONE_NUMBER'))}
                    value={formValues.phoneNumber}
                    onChange={changeHandler}
                    error={errors.phoneNumber ? true : false}
                    errorMessage={errors.phoneNumber?.message}
                  />
                </div>
              </div>
              <div className="row">
                <label className="fw-bold">
                  {t(getTranslationKey('FORM_GEO_COORDINATES'))}
                  <span className="p-error"> *</span>
                </label>
                <div className="col">
                  <div className="no-label">
                    <Input
                      data-cy={'gym-info-temp-form-input-latitude'}
                      name="latitude"
                      placeholder={t(getTranslationKey('FORM_LATITUDES'))}
                      value={formValues.latitude ? String(formValues.latitude) : ''}
                      onChange={changeHandler}
                      error={errors.latitude ? true : false}
                      errorMessage={errors.latitude?.message}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="no-label">
                    <Input
                      data-cy={'gym-info-temp-form-input-longitude'}
                      name="longitude"
                      placeholder={t(getTranslationKey('FORM_LONGITUDES'))}
                      value={formValues.longitude ? String(formValues.longitude) : ''}
                      onChange={changeHandler}
                      error={errors.longitude ? true : false}
                      errorMessage={errors.longitude?.message}
                    />
                  </div>
                </div>
              </div>
              <Input
                data-cy={'gym-info-temp-form-input-signup-url'}
                name="signUpUrl"
                label={t(getTranslationKey('FORM_SIGNUP_URL'))}
                value={formValues.signUpUrl}
                onChange={changeHandler}
                error={errors.signUpUrl ? true : false}
                errorMessage={errors.signUpUrl?.message}
              />
              <div className="row mb25">
                {props.type === 'modify' && (
                  <div className="col">
                    <Dropdown
                      name="membershipOnSignup"
                      label={t(getTranslationKey('FORM_MEMBERSHIP_ON_SIGNUP'))}
                      options={dropdownItems ? dropdownItems : []}
                      onChange={dropdownChangeHandler}
                      value={formValues.membershipOnSignup}
                      showClear={true}
                    />
                  </div>
                )}
              </div>
              <div className="row mb25">
                <div className="col">
                  <Dropdown
                    name="membershipForVisits"
                    label={t(getTranslationKey('FORM_MEMBERSHIP_FOR_VISITS'))}
                    options={dropdownItems ? dropdownItems : []}
                    onChange={dropdownChangeHandler}
                    value={formValues.membershipForVisits}
                    showClear={true}
                  />
                  {errors.membershipForVisits && (
                    <div className="row mb18 p-error">
                      <Text>{errors.membershipForVisits.message}</Text>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb25">
                {props.type === 'modify' && (
                  <div className="col">
                    <Checkbox
                      name="syncAllVisitsWithGenericSubscription"
                      label={t(getTranslationKey('FORM_SYNC_VISITS_MEMBERSHIP'))}
                      checked={syncVisitsChecked}
                      onChange={() => {
                        setSyncVisitsChecked(!syncVisitsChecked);
                      }}
                      value={'checked'}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col">
                  <Input
                    data-cy={'gym-info-temp-form-input-handback-time'}
                    name="handbackTime"
                    label={t(getTranslationKey('FORM_HANDBACK_TIME'))}
                    value={String(formValues.handbackTime)}
                    onChange={changeHandler}
                    required={true}
                    error={errors.handbackTime ? true : false}
                    errorMessage={errors.handbackTime?.message}
                  />
                </div>
              </div>
              {providerId === 1 && (
                <div className="row">
                  <div className="col">
                    <Input
                      name="blockTime"
                      label={t(getTranslationKey('FORM_BLOCK_TIME'))}
                      value={formValues.blockTime ? String(formValues.blockTime) : ''}
                      onChange={changeHandler}
                      error={errors.blockTime ? true : false}
                      errorMessage={errors.blockTime?.message}
                    />
                  </div>
                </div>
              )}
              {/* <div className="row">
                <div className="col">
                  <Input
                    data-cy={'gym-info-temp-form-input-grace-period-when-declined'}
                    name="gracePeriodWhenDeclined"
                    label={t(getTranslationKey('FORM_GRACE_PERIOD_DECLINED'))}
                    value={
                      formValues.gracePeriodWhenDeclined
                        ? String(formValues.gracePeriodWhenDeclined)
                        : ''
                    }
                    onChange={changeHandler}
                    error={errors.gracePeriodWhenDeclined ? true : false}
                    errorMessage={errors.gracePeriodWhenDeclined?.message}
                  />
                </div>
                <div className="col">
                  <Input
                    data-cy={'gym-info-temp-form-input-access-per-day-when-declined'}
                    name="accessPerDayWhenDeclined"
                    label={t(getTranslationKey('FORM_ACCESS_PER_DAY_DECLINED'))}
                    value={
                      formValues.accessPerDayWhenDeclined
                        ? String(formValues.accessPerDayWhenDeclined)
                        : ''
                    }
                    onChange={changeHandler}
                    error={errors.accessPerDayWhenDeclined ? true : false}
                    errorMessage={errors.accessPerDayWhenDeclined?.message}
                  />
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col">
                  <Input
                    data-cy={'gym-info-temp-form-input-grace-period-when-expired'}
                    name="gracePeriodWhenExpired"
                    label={t(getTranslationKey('FORM_GRACE_PERIOD_EXPIRED'))}
                    value={
                      formValues.gracePeriodWhenExpired
                        ? String(formValues.gracePeriodWhenExpired)
                        : ''
                    }
                    onChange={changeHandler}
                    error={errors.gracePeriodWhenExpired ? true : false}
                    errorMessage={errors.gracePeriodWhenExpired?.message}
                  />
                </div>
                <div className="col">
                  <Input
                    data-cy={'gym-info-temp-form-input-access-per-day-when-expired'}
                    name="accessPerDayWhenExpired"
                    label={t(getTranslationKey('FORM_ACCESS_PER_DAY_EXPIRED'))}
                    value={
                      formValues.accessPerDayWhenExpired
                        ? String(formValues.accessPerDayWhenExpired)
                        : ''
                    }
                    onChange={changeHandler}
                    error={errors.accessPerDayWhenExpired ? true : false}
                    errorMessage={errors.accessPerDayWhenExpired?.message}
                  />
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col">
                  <Input
                    data-cy={'gym-info-temp-form-input-grace-period-when-suspended'}
                    name="gracePeriodWhenSuspended"
                    label={t(getTranslationKey('FORM_GRACE_PERIOD_SUSPENDED'))}
                    value={
                      formValues.gracePeriodWhenSuspended
                        ? String(formValues.gracePeriodWhenSuspended)
                        : ''
                    }
                    onChange={changeHandler}
                    error={errors.gracePeriodWhenSuspended ? true : false}
                    errorMessage={errors.gracePeriodWhenSuspended?.message}
                  />
                </div>
                <div className="col">
                  <Input
                    data-cy={'gym-info-temp-form-input-access-per-day-when-suspended'}
                    name="accessPerDayWhenSuspended"
                    label={t(getTranslationKey('FORM_ACCESS_PER_DAY_SUSPENDED'))}
                    value={
                      formValues.accessPerDayWhenSuspended
                        ? String(formValues.accessPerDayWhenSuspended)
                        : ''
                    }
                    onChange={changeHandler}
                    error={errors.accessPerDayWhenSuspended ? true : false}
                    errorMessage={errors.accessPerDayWhenSuspended?.message}
                  />
                </div>
              </div> */}
              <div className="mb10">
                <label className="fw-bold">
                  {t(getTranslationKey('FORM_DEFAULT_LOCATION_DETECTION_METHOD'))}
                  <span className="p-error"> *</span>
                </label>
                <Dropdown
                  name="defaultLocationDetectionMethod"
                  options={dropdownDefaultLocationDetectionMethodItems}
                  onChange={dropdownChangeHandler}
                  value={formValues.defaultLocationDetectionMethod}
                  error={errors.defaultLocationDetectionMethod ? true : false}
                />
              </div>
              {errors.defaultLocationDetectionMethod && (
                <div className="row mb18 p-error">
                  <Text>{errors.defaultLocationDetectionMethod.message}</Text>
                </div>
              )}
              <div className="mt25">
                <Dropdown
                  label={t(getTranslationKey('FORM_MOBILE_CREDENTIALS_ALLOWED'))}
                  name="isMobileCredentialAllowed"
                  options={mobileCredentialAllowedItems}
                  onChange={dropdownChangeHandler}
                  value={formValues.isMobileCredentialAllowed ? 'true' : 'false'}
                />
              </div>
              {providerId == 1 && (
                <div>
                  <div className="mt25">
                    <Dropdown
                      label={t(getTranslationKey('PREVIOUSLY_USED_CARD_TYPE'))}
                      name="cardType"
                      options={previouslyUsedCardTypeDropDownItems}
                      onChange={dropdownChangeHandler}
                      value={formValues.cardType}
                      error={errors.cardType ? true : false}
                    />
                  </div>
                  <div className="mt25">
                    <Dropdown
                      label={t(getTranslationKey('AUTOMATICALLY_ADD_ARRIVAL_FOR_A_SESSION'))}
                      name="addArrivalAuto"
                      options={trueFalseDropDownItems}
                      onChange={dropdownChangeHandler}
                      value={String(formValues.addArrivalAuto)}
                      error={errors.addArrivalAuto ? true : false}
                    />
                  </div>
                  <div className="mt25">
                    <Dropdown
                      label={t(getTranslationKey('VISIT_SIGNEDIN_STATUS'))}
                      name="addVisitAuto"
                      options={trueFalseDropDownItems}
                      onChange={dropdownChangeHandler}
                      value={String(formValues.addVisitAuto)}
                      error={errors.addVisitAuto ? true : false}
                    />
                  </div>
                  <div className="mt25">
                    <Dropdown
                      label={t(getTranslationKey('MARK_ARRIVALS_WHEN_VISITS'))}
                      name="markArrivalsWhenThereAreVisits"
                      options={trueFalseDropDownItems}
                      onChange={dropdownChangeHandler}
                      value={String(formValues.markArrivalsWhenThereAreVisits)}
                      error={errors.markArrivalsWhenThereAreVisits ? true : false}
                    />
                  </div>
                  {/* <div className="mt25">
                    <Dropdown
                      label={t(getTranslationKey('ENABLE_ARRIVAL_MARKING_FIRST'))}
                      name="markArrivalFirst"
                      options={trueFalseDropDownItems}
                      onChange={dropdownChangeHandler}
                      value={String(formValues.markArrivalFirst)}
                      error={errors.markArrivalFirst ? true : false}
                    />
                  </div>
                  <div className="mt25">
                    <Dropdown
                      label={t(getTranslationKey('OPEN_DOOR_WITHOUT_ARRIVAL_MARKING'))}
                      name="openWithoutArrivalMarked"
                      options={trueFalseDropDownItems}
                      onChange={dropdownChangeHandler}
                      value={String(formValues.openWithoutArrivalMarked)}
                      error={errors.openWithoutArrivalMarked ? true : false}
                    />
                  </div>
                  <div className="mt25">
                    <Dropdown
                      label={t(getTranslationKey('ENABLE_MARKING_MULTIPLE_ARRIVALS_AUTOMATICALLY'))}
                      name="markMultipleAttendanceAuto"
                      options={trueFalseDropDownItems}
                      onChange={dropdownChangeHandler}
                      value={String(formValues.markMultipleAttendanceAuto)}
                      error={errors.markMultipleAttendanceAuto ? true : false}
                    />
                  </div> */}
                  <div className="mt25">
                    <Dropdown
                      label={t(getTranslationKey('PRIORATIZE_CLASS_ATTENDANCE'))}
                      name="prioratizeClasses"
                      options={trueFalseDropDownItems}
                      onChange={dropdownChangeHandler}
                      value={String(formValues.prioratizeClasses)}
                      error={errors.prioratizeClasses ? true : false}
                    />
                  </div>
                  <div className="mt25">
                    <Input
                      name="sessionCheckTimeLimit"
                      label={t(
                        getTranslationKey(
                          'TIME_TO_CHECK_FOR_SESSIONS_FOR_A_DAY_WHILE_OPENING_A_DOOR'
                        )
                      )}
                      value={
                        formValues.sessionCheckTimeLimit
                          ? String(formValues.sessionCheckTimeLimit)
                          : ''
                      }
                      onChange={changeHandler}
                      error={errors.sessionCheckTimeLimit ? true : false}
                      errorMessage={errors.sessionCheckTimeLimit?.message}
                    />
                  </div>
                  <div className="mt25">
                    <Input
                      name="sessionCheckTimeLimitUpper"
                      label={t(
                        getTranslationKey('TIME_UNTIL_DOOR_OPENING_ALLOWED_AFTER_SESSION_ENDED')
                      )}
                      value={
                        formValues.sessionCheckTimeLimitUpper
                          ? String(formValues.sessionCheckTimeLimitUpper)
                          : ''
                      }
                      onChange={changeHandler}
                      error={errors.sessionCheckTimeLimitUpper ? true : false}
                      errorMessage={errors.sessionCheckTimeLimitUpper?.message}
                    />
                  </div>
                </div>
              )}
              <div className="mt25 mb10">
                <label className="fw-bold">
                  {t(getTranslationKey('FORM_TIMEZONE'))}
                  <span className="p-error"> *</span>
                </label>
                <Dropdown
                  name="timeZone"
                  options={timeZoneItems}
                  onChange={dropdownChangeHandler}
                  value={formValues.timeZone}
                  error={errors.timeZone ? true : false}
                />
              </div>
              {errors.timeZone && (
                <div className="row mb18 p-error">
                  <Text>{errors.timeZone.message}</Text>
                </div>
              )}
              <div className="mt25">
                <Dropdown
                  label={t(getTranslationKey('FORM_STATUS'))}
                  name="status"
                  options={dropdownStatusItems}
                  onChange={dropdownChangeHandler}
                  value={formValues.status}
                />
              </div>
              <div className="d-flex flex-row mt35 btn-min-w-110">
                <Text bold={true}>
                  {submitDisabled
                    ? imgUploadStatus === 'INPROGRESS'
                      ? t(getTranslationKey('FORM_UPLOAD_INPROGRESS'))
                      : imgUploadStatus === 'ERROR'
                      ? t(getTranslationKey('FORM_UPLOAD_ERROR'))
                      : t(getTranslationKey('FORM_UPLOAD_SUCCESS'))
                    : imgUploadStatus === 'SUCCESS'
                    ? t(getTranslationKey('FORM_UPLOAD_SUCCESS'))
                    : ''}
                </Text>
              </div>

              <div className="d-flex flex-row mt35 btn-min-w-110">
                <div className="mr15">
                  <Button
                    data-cy={'gym-info-temp-form-btn-save'}
                    label={t(getTranslationKey('FORM_BTN_SAVE'))}
                    size={ButtonSizes.medium}
                    disabled={submitDisabled || isInserted}
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                  />
                </div>
                <Button
                  data-cy={'gym-info-temp-form-btn-cancel'}
                  label={t(getTranslationKey('FORM_BTN_CANCEL'))}
                  variant={ButtonVariants.textonly}
                  onClick={() => history.push(`/gymChains/${props.gymChainId}/gyms`)}
                  disabled={isInserted}
                />
                <ActionFailedDialog />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="d-flex justify-content-center">
          <Upload
            name="upload"
            previewImg={formValues.imageUrl === '' ? undefined : formValues.imageUrl}
            uploadHandler={uploadHandler}
            setUploadState={setImgUploadStatus}
          />
        </div>
      </div>
    </div>
  );
};

type GymInfoTemplateType = 'new' | 'modify';

type ImportErrorType = 'importError' | 'duplicateError' | 'duplicateChain' | null;

export interface GymInfoTemplateProps {
  /** Type of the template: new or modify */
  type: GymInfoTemplateType;
  /** ID of the selected gym chain */
  gymChainId: number;
  /** Gym to modify, only for the modify scenario */
  gym?: Gym;
}

export default GymInfoTemplate;
