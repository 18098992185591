import { Messages } from 'primereact/messages';
import { ReactNode, RefObject } from 'react';

const Alert = ({ refProp, ...props }: AlertProps) => {
  return <Messages ref={refProp} {...props}></Messages>;
};

type AlertSeverity = 'success' | 'info' | 'warn' | 'error' | undefined;

type AlertParamsType = {
  severity: AlertSeverity;
  content: ReactNode;
  closable: boolean;
  sticky: boolean;
  life: number;
};

// needed for the declaration of ref type
export type AlertType = Messages;

export const alertDefaultParams: AlertParamsType = {
  severity: 'success',
  content: '',
  closable: true,
  sticky: false,
  life: 3000
};

/**
 * Triggers showing of alerts
 *
 * @param alertRef Ref object passed to the Alert component
 * @param args arguments to customize the alert
 * @param mode whether to show new alert or replace existing
 */
export const createAlert = (
  alertRef: RefObject<AlertType>,
  args: Partial<AlertParamsType>,
  mode: 'show' | 'replace' = 'show'
): void => {
  if (mode === 'show') alertRef.current?.show({ ...alertDefaultParams, ...args });
  else alertRef.current?.replace({ ...alertDefaultParams, ...args });
};

/**
 * Clears currently displayed alerts
 *
 * @param alertRef Ref object passed to the Alert component
 */
export const clearAlert = (alertRef: RefObject<AlertType>): void => {
  alertRef.current?.clear();
};

export interface AlertProps {
  /** Ref object to keep track of the alert component */
  refProp: RefObject<AlertType>;
}

export default Alert;
