import { getAllPrograms } from '@gym-graphql/queries';
import {
  PricingRelationships,
  PricingRelationshipTable
} from '@gym-particles/types/PricingRelationships';
import { RelatedProgram } from '@gym-src/API';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';

const initialState: {
  isPricingRelationshipLoading: boolean;
  items: Array<PricingRelationshipTable>;
} = { isPricingRelationshipLoading: false, items: [] };

export const fetchPricingRelationships = createAsyncThunk(
  '/gymChain/fetchPricingRelationships',
  async (siteId: number) => {
    const response = await (API.graphql({
      query: getAllPrograms,
      variables: {
        siteId: siteId
      }
    }) as Promise<{
      data: { getAllPrograms: RelatedProgram[] };
    }>);
    return response;
  }
);

export const pricingRelationshipSlice = createSlice({
  name: 'pricingRelationship',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPricingRelationships.fulfilled, (state, action) => {
      state.isPricingRelationshipLoading = false;
      if (action.payload.data.getAllPrograms === null) {
        return;
      }
      const items = action.payload.data.getAllPrograms.map(
        (e: RelatedProgram): PricingRelationships => {
          return {
            id: e.id || 0,
            siteId: e.siteId || 0,
            importedId: e.importedId || 0,
            programName: e.programName || '',
            scheduleType: e.scheduleType || '',
            createdBy: e.createdBy || 0,
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate
              ? dayjs(e.lastModifiedDate || '').format('DD MMM YYYY')
              : '',
            linkedProgramId: e.linkedProgramId || 0,
            linkCreatedBy: e.linkCreatedBy || 0,
            linkCreatedDate: e.linkCreatedDate
              ? dayjs(e.linkCreatedDate || '').format('YYYY-MM-DD HH:mm:ss')
              : '',
            linkLastModifiedBy: e.linkLastModifiedBy || 0,
            linkLastModifiedDate: e.linkLastModifiedDate
              ? dayjs(e.linkLastModifiedDate || '').format('DD MMM YYYY')
              : ''
          };
        }
      );
      state.items = mapProgramRelationships(items) || [];
      return state;
    });
    builder.addCase(fetchPricingRelationships.pending, (state, action) => {
      state.isPricingRelationshipLoading = true;
      return state;
    });
    builder.addCase(fetchPricingRelationships.rejected, (state, action) => {
      state.isPricingRelationshipLoading = false;
      console.error('Fetch PricingRelationships Error');
      return state;
    });
  }
});

const mapProgramRelationships = (programs?: PricingRelationships[]) => {
  if (!programs) {
    return;
  }
  const mappedPrograms: PricingRelationshipTable[] = [];
  programs
    .map((program) => program.id)
    .filter((programId, index, arr) => arr.indexOf(programId) === index)
    .forEach((programId) => {
      const relatedPrograms = programs.filter((program) => program.id === programId);
      mappedPrograms.push({
        id: relatedPrograms[0].id,
        siteId: relatedPrograms[0].siteId,
        importedId: relatedPrograms[0].importedId,
        programName: relatedPrograms[0].programName,
        scheduleType: relatedPrograms[0].scheduleType,
        createdBy: relatedPrograms[0].createdBy,
        createdDate: relatedPrograms[0].createdDate,
        linkedCreatedDate: relatedPrograms[0].linkCreatedDate,
        lastModifiedBy: relatedPrograms[0].lastModifiedBy,
        lastModifiedDate: relatedPrograms[0].lastModifiedDate,
        relatedPrograms: relatedPrograms
          .filter((p) => p.linkedProgramId)
          .map((r) => {
            const linked = programs.filter((x) => x.id === r.linkedProgramId);
            return {
              id: linked[0].id,
              importedId: linked[0].importedId,
              programName: linked[0].programName,
              scheduleType: linked[0].scheduleType,
              createdBy: linked[0].createdBy,
              createdDate: linked[0].createdDate,
              lastModifiedBy: linked[0].lastModifiedBy,
              lastModifiedDate: linked[0].lastModifiedDate
            };
          })
      });
    });
  return mappedPrograms;
};

export default pricingRelationshipSlice.reducer;
