import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import gymChainReducer from './slices/gymChainSlice';
import userManagementReducer from './slices/userManagementSlice';
import gymReducer from './slices/gymsSlice';
import membershipReducer from './slices/membershipSlice';
import platformSettingReducer from './slices/platformSettingSlice';
import holidayReducer from './slices/holidaysSlice';
import openingHoursReducer from './slices/openingHoursSlice';
import memberReducer from './slices/memberSlice';
import zoneReducer from './slices/zoneSlice';
import accountReducer from './slices/accountsSlice';
import accessPointReducer from './slices/accessPointsSlice';
import kisiDoorReducer from './slices/kisiDoorSlice';
import kisiEventReducer from './slices/kisiEventSlice';
import activityReducer from './slices/activitySlice';
import accessCategoryReducer from './slices/accessCategorySlice';
import daySchedulesReducer from './slices/daySchedulesSlice';
import gymSchedulesSlice from './slices/gymSchedulesSlice';
import homeActivitySlice from './slices/homeActivitySlice';
import gymChainActivitySlice from './slices/gymChainActivitySlice';
import gymChainSettingsSlice from './slices/gymChainSettingsSlice';
import userRolesSlice from './slices/userRolesSlice';
import configurationSlice from '@gym-redux/slices/configurationSlice';
import sidebarPropsSlice from './slices/sidebarPropsSlice';
import ScheduleRequestsSlice from './slices/scheduleRequestsSlice';
import pricingRelationshipSlice from './slices/pricingRelationshipSlice';
import kisiEventMembersSlice from './slices/kisiEventMembersSlice';

const rootReducer = combineReducers({
  user: userReducer,
  gymChain: gymChainReducer,
  userManagement: userManagementReducer,
  gyms: gymReducer,
  membership: membershipReducer,
  platformSettings: platformSettingReducer,
  holiday: holidayReducer,
  openingHour: openingHoursReducer,
  member: memberReducer,
  zone: zoneReducer,
  account: accountReducer,
  accessPoint: accessPointReducer,
  kisiDoor: kisiDoorReducer,
  kisiEvent: kisiEventReducer,
  kisiEventMember: kisiEventMembersSlice,
  activity: activityReducer,
  accessCategory: accessCategoryReducer,
  daySchedules: daySchedulesReducer,
  gymSchedules: gymSchedulesSlice,
  homeActivity: homeActivitySlice,
  gymChainActivity: gymChainActivitySlice,
  gymChainSettings: gymChainSettingsSlice,
  userRoles: userRolesSlice,
  configuration: configurationSlice,
  sidebarProps: sidebarPropsSlice,
  scheduleRequests: ScheduleRequestsSlice,
  pricingRelationships: pricingRelationshipSlice
});

export default rootReducer;
