import Text, { TextSizes } from '@gym-atoms/Text/Text';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '@gym-atoms/Input/Input';
import { useHistory, useParams } from 'react-router-dom';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import Dropdown, { DropdownChangeEvent, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import { ZoneFormInput, ZoneTable } from '@gym-particles/types/Zone';
import { getAccessCategories } from '@gym-graphql/queries';
import { API } from 'aws-amplify';
import {
  LocationAccessCategoryZoneInput,
  LocationZone,
  LocationZoneInput,
  SiteLocationAccessCategoryResponse,
  LambdaResponse
} from '@gym-src/API';
import {
  addLocationAccessCategoryZone,
  addLocationZone,
  modifyLocationZone as modifyLocationZoneMutation,
  removeLocationAccessCategoryZone
} from '@gym-graphql/mutations';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { fetchZones } from '@gym-redux/slices/zoneSlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';

const GymZoneTemplate = (props: GymZoneTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isInserted, setIsInserted] = useState(false);
  const { chainId, gymId } = useParams<{ chainId: string; gymId: string }>();
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const [defaultSelectedAccessCategories, setDefaultSelectedAccessCategories] = useState<
    Array<string>
  >([]);
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.userId);

  const templateTypeChangeHandler = useCallback(
    (state) => {
      if (props.templateType) {
        props.templateType(state);
      }
    },
    [props.templateType]
  );

  const zoneDefaultValues: ZoneFormInput = {
    siteLocationId: props.zone?.siteLocationId || +gymId,
    zoneName: props.zone?.zoneName || '',
    description: props.zone?.zoneDescription || '',
    accessCategories:
      props.zone?.zoneDetails.map((zone) => {
        return zone.accessCategoryId.toString();
      }) || [],
    status: props.zone?.status || '',
    arxReferenceId: props.zone?.arxReferenceId || ''
  };
  const [accessCategoryDropDownItems, setAccessCategoryDropDownItems] = useState<Array<SelectItem>>(
    []
  );
  const [formValues, setFormValues] = useState(zoneDefaultValues);
  const { register, handleSubmit, setValue, errors, reset } = useForm<ZoneFormInput>({
    defaultValues: zoneDefaultValues
  });

  const getTranslationKey = (e: string): string => {
    return props.type === 'new' ? 'ADD_NEW_ZONE.' + e : 'MODIFY_ZONE.' + e;
  };
  const emptyValues: ZoneFormInput = {
    siteLocationId: +gymId,
    zoneName: '',
    description: '',
    arxReferenceId: '',
    accessCategories: [],
    status: ''
  };
  const statusDropDownItems = [
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN_ACTIVE')),
      value: 'active'
    },
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN_INACTIVE')),
      value: 'inactive'
    }
  ];

  const fetchAccessCategories = async () => {
    try {
      const response = (API.graphql({
        query: getAccessCategories,
        variables: {
          siteLocationId: gymId
        }
      }) as Promise<{
        data: { getAccessCategories: SiteLocationAccessCategoryResponse };
      }>).then((e) => {
        if (e.data.getAccessCategories.items) {
          const accessCategoryDropDownItems: SelectItem[] = e.data.getAccessCategories.items.map(
            (data) => {
              return { label: data?.name || '', value: String(data?.id) || '' };
            }
          );
          setAccessCategoryDropDownItems(accessCategoryDropDownItems);

          props.type === 'modify' &&
            setDefaultSelectedAccessCategories(
              props.zone?.zoneDetails.map((zone) => {
                return zone.accessCategoryId.toString();
              }) || []
            );
        }
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  // fetch all the memberships relavent to selected gym
  useEffect(() => {
    fetchAccessCategories();
  }, []);

  // setting up default values
  useEffect(() => {
    setFormValues(zoneDefaultValues);
    reset(zoneDefaultValues);
    props.type === 'modify' &&
      setDefaultSelectedAccessCategories(
        props.zone?.zoneDetails.map((zone) => {
          return zone.accessCategoryId.toString();
        }) || []
      );
  }, [props]);

  // setting up selected memberships when modifying
  useEffect(() => {
    setFormValues({
      ...formValues,
      accessCategories: defaultSelectedAccessCategories
    });
    setValue('accessCategories', defaultSelectedAccessCategories);
  }, [defaultSelectedAccessCategories]);

  const resetForm = () => {
    setFormValues(emptyValues);
    reset(emptyValues);
  };
  const dropdownChangeHandler = (e: DropdownChangeEvent) => {
    setValue(e.target.name as keyof ZoneFormInput, e.value);
    setFormValues({ ...formValues, [e.target.name]: e.value });
  };

  const inputFieldChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof ZoneFormInput, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  const textAreaChangeHandler = (e: FormEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.name as keyof ZoneFormInput, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  useEffect(() => {
    register('zoneName', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('description', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('accessCategories', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      validate: (value) => value.length > 0 || ''
    });
    register('status', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('arxReferenceId', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
  }, [register]);

  const addLocationAccessCategoryZoneRecord = async (
    accessCategoryId: number,
    locationZone: LocationZone
  ) => {
    const addLocationAccessCategoryZoneInput: LocationAccessCategoryZoneInput = {
      locationZoneId: locationZone.id || -1,
      locationAccessCategoryId: accessCategoryId,
      createdBy: locationZone.createdBy || -1,
      lastModifiedBy: locationZone.lastModifiedBy || -1
    };

    return API.graphql({
      query: addLocationAccessCategoryZone,
      variables: {
        input: addLocationAccessCategoryZoneInput
      }
    });
  };

  const addLocationZoneRecord = async (data: ZoneFormInput) => {
    const addLocationZoneInput: LocationZoneInput = {
      siteLocationId: +gymId,
      zoneName: data.zoneName.replace(/'/g, "''"),
      zoneDescription: data.description.replace(/'/g, "''"),
      arxReferenceId: data.arxReferenceId.replace(/'/g, "''"),
      status: data.status,
      createdBy: userId,
      lastModifiedBy: userId
    };
    const accessCategoryIds = data.accessCategories?.map((accessCategory) => +accessCategory);

    try {
      const addLocationZoneResponse = await (API.graphql({
        query: addLocationZone,
        variables: {
          input: addLocationZoneInput,
          accessCategories: accessCategoryIds
        }
      }) as Promise<{
        data: { addLocationZone: LambdaResponse };
      }>);

      if (addLocationZoneResponse.data.addLocationZone.error) {
        throw 'Unable to add Gym Zone';
      } else {
        resetForm();
      }
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const removeLocationAccessCategoryZoneRecord = async (
    locationZoneId?: number,
    locationAccessCategoryId?: number,
    userId?: number
  ) => {
    return API.graphql({
      query: removeLocationAccessCategoryZone,
      variables: {
        locationZoneId: locationZoneId,
        locationAccessCategoryId: locationAccessCategoryId,
        userId: userId
      }
    });
  };

  const modifyLocationZone = async (data: ZoneFormInput) => {
    const modifyLocationZoneInput: LocationZoneInput = {
      id: props.zone?.id || -1,
      siteLocationId: +gymId,
      zoneName: data.zoneName.replace(/'/g, "''"),
      zoneDescription: data.description.replace(/'/g, "''"),
      arxReferenceId: data.arxReferenceId.replace(/'/g, "''"),
      status: data.status,
      createdBy: props.zone?.createdBy || -1,
      lastModifiedBy: userId
    };

    const addedAccessCategories = data.accessCategories.filter(
      (x) => !defaultSelectedAccessCategories.includes(x)
    );

    const removedAccessCategories = defaultSelectedAccessCategories.filter(
      (x) => !data.accessCategories?.includes(x)
    );

    try {
      const modifyLocationZoneResponse = await (API.graphql({
        query: modifyLocationZoneMutation,
        variables: {
          input: modifyLocationZoneInput
        }
      }) as Promise<{
        data: { modifyLocationZone: LocationZone };
      }>);

      if (addedAccessCategories) {
        const addedAccessCategoriesPromises: Promise<any>[] = [];

        addedAccessCategories.forEach((accessCategoryId) => {
          addedAccessCategoriesPromises.push(
            addLocationAccessCategoryZoneRecord(
              +accessCategoryId,
              modifyLocationZoneResponse.data.modifyLocationZone
            )
          );
        });

        await Promise.all(addedAccessCategoriesPromises);
      }

      if (removedAccessCategories) {
        const removedAccessCategoriesPromises: Promise<any>[] = [];

        removedAccessCategories.forEach((accessCategoryId) => {
          removedAccessCategoriesPromises.push(
            removeLocationAccessCategoryZoneRecord(props.zone?.id, +accessCategoryId, userId)
          );
        });

        await Promise.all(removedAccessCategoriesPromises);
      }
      resetForm();
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const onSubmitHandler = async (data: ZoneFormInput) => {
    setIsInserted(true);
    if (props.type === 'new') {
      await addLocationZoneRecord(data);
    } else {
      await modifyLocationZone(data);
      templateTypeChangeHandler(false);
    }
    dispatch(
      fetchZones({
        siteLocationId: +gymId
      })
    );
    setIsInserted(false);
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}</Text>}
        />
      </div>
    );
  };
  return (
    <div className="ml30 mt10">
      <div>
        <Text
          data-cy={'gym-zones-form-hedding'}
          bold={true}
          size={TextSizes.large}
          className="text-2xl">
          {props.heading}
        </Text>
      </div>
      <div className="col-md-6">
        <form className="input-mb20 py-4" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <div className="row">
            <div className="col-12">
              <Input
                data-cy={'gym-zones-form-input-name'}
                label={t(getTranslationKey('FORM_INPUT_ZONE_NAME_LABEL'))}
                variant="basic"
                name="zoneName"
                value={formValues.zoneName}
                onChange={inputFieldChangeHandler}
                error={errors.zoneName ? true : false}
                errorMessage={errors.zoneName?.message}
                placeholder={t(getTranslationKey('FORM_INPUT_ZONE_NAME_PLACEHOLDER'))}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Input
                // TO-DO: Adjust cypress test cases
                // data-cy={'gym-zones-form-input-arxrefid'}
                label={t(getTranslationKey('FORM_INPUT_ZONE_ARX_ID_LABEL'))}
                variant="basic"
                name="arxReferenceId"
                value={formValues.arxReferenceId}
                onChange={inputFieldChangeHandler}
                error={errors.arxReferenceId ? true : false}
                errorMessage={errors.arxReferenceId?.message}
                placeholder={t(getTranslationKey('FORM_INPUT_ZONE_ARX_ID_PLACEHOLDER'))}
                required={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Input
                data-cy={'gym-zones-form-input-description'}
                label={t(getTranslationKey('FORM_INPUT_ZONE_DESCRIPTION_LABEL'))}
                variant="textarea"
                name="description"
                value={formValues.description}
                onChangeTA={textAreaChangeHandler}
                error={errors.description ? true : false}
                errorMessage={errors.description?.message}
                placeholder={t(getTranslationKey('FORM_INPUT_ZONE_DESCRIPTION_PLACEHOLDER'))}
                required={true}
              />
            </div>
          </div>
          <div className="row mb25">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_ZONE_ACCESS_CATEGORIES_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                chips={true}
                name="accessCategories"
                options={accessCategoryDropDownItems}
                onChange={dropdownChangeHandler}
                value={formValues.accessCategories}
                placeholder={t(getTranslationKey('FORM_INPUT_ZONE_ACCESS_CATEGORIES_PLACEHOLDER'))}
                error={errors.accessCategories ? true : false}
              />
              {errors.accessCategories && (
                <Text
                  // TO-DO: Adjust cypress test cases
                  // data-cy={'gym-zones-form-accessCategories-required-error'}
                  className="p-error mt10">
                  {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                </Text>
              )}
            </div>
          </div>
          <div className="row mb40">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_ZONE_STATUS_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                name="status"
                options={statusDropDownItems}
                value={formValues.status}
                onChange={dropdownChangeHandler}
                placeholder={t(getTranslationKey('FORM_INPUT_ZONE_STATUS_PLACEHOLDER'))}
                error={errors.status ? true : false}
              />
            </div>
            {errors.status && (
              <Text data-cy={'gym-zones-form-stutus-required-error'} className="p-error mt10">
                {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
              </Text>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex btn-min-w-110">
                <div className="mr15">
                  <Button
                    data-cy={'gym-zones-form-btn-save'}
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                    label={t(getTranslationKey('FORM_BTN_LABEL_SAVE'))}
                    size={ButtonSizes.medium}
                    disabled={isInserted}
                  />
                </div>
                <Button
                  data-cy={'gym-zones-form-btn-cancel'}
                  label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
                  variant={ButtonVariants.textonly}
                  onClick={(e) => {
                    e.preventDefault();
                    resetForm();
                    templateTypeChangeHandler(false);
                  }}
                  disabled={isInserted}
                />
              </div>
              <ActionFailedDialog />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
type GymZoneTemplateType = 'new' | 'modify';

export interface GymZoneTemplateProps {
  /** Type of the template: new or modify */
  type: GymZoneTemplateType;
  /** Heading of the template */
  heading?: string;
  /** form values*/
  zone?: ZoneTable | undefined;
  /** template change function */
  templateType?: (e: boolean) => void;
}

export default GymZoneTemplate;
