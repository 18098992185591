import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Dropdown, { DropdownChangeEvent, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import Loader from '@gym-atoms/Loader/Loader';
import {
  updateAccessCardHistory,
  updateAccessCardOfPerson,
  updateMemberFlag
} from '@gym-graphql/mutations';
import { getAccessCardHistory } from '@gym-graphql/queries';
import { MemberAccessCard } from '@gym-particles/types/Member';
import { useAppSelector } from '@gym-redux/store';
import {
  AccessCardHistoryResponse,
  GetAccessCardHistoryQueryVariables,
  UpdateAccessCardHistoryMutation,
  UpdateAccessCardHistoryMutationVariables,
  UpdateAccessCardOfPersonMutationVariables
} from '@gym-src/API';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const AccessCardDeactivateModal = ({
  visible,
  onHideCallBack,
  member
}: AccessCardDeactivateModalProps) => {
  const { t } = useTranslation();
  const { gymId } = useParams<{ chainId: string; gymId: string }>();
  const currentUser = useAppSelector((state) => state.user.userId);

  const [dropdownError, setDropdownError] = useState<boolean>(false);
  const [selected, setselected] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [noData, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [selectedCard, setSelectedCard] = useState<AccessCardHistoryResponse>();
  const [cardsDropdown, setCardsDropdown] = useState<SelectItem[]>();
  const [activeCards, setActiveCards] = useState<AccessCardHistoryResponse[]>();

  const getCards = async () => {
    setIsLoading(true);
    const data: GetAccessCardHistoryQueryVariables = {
      memberId: member.memberId,
      siteLocationId: +gymId
    };

    try {
      const result = await (API.graphql({
        query: getAccessCardHistory,
        variables: data
      }) as Promise<{
        data: { getAccessCardHistory: AccessCardHistoryResponse[] };
      }>);

      const cards: AccessCardHistoryResponse[] = [];
      const dropdownCards: SelectItem[] = [];

      const allCards = result.data.getAccessCardHistory;

      allCards.forEach((card) => {
        if (card.isActive === true) {
          cards.push(card);
          dropdownCards.push({
            label: card.accessCardUID?.toString() || '',
            value: card.accessCardUID?.toString() || ''
          });
        }
      });

      setActiveCards(cards);
      setCardsDropdown(dropdownCards);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCards();
  }, [visible]);

  useEffect(() => {
    if (cardsDropdown?.length === 0) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [cardsDropdown]);

  const onDeactivateAccessCard = () => {
    if (!selectedCard) {
      setDropdownError(true);
      return;
    } else {
      setIsScanning(true);
      setDropdownError(false);
      deactivateAccessCard();
      updateMemberFlagYellowBox();
      setIsScanning(false);
    }
  };

  const deactivateAccessCard = async () => {
    const card = selectedCard;
    const data: UpdateAccessCardOfPersonMutationVariables = {
      input: {
        arxPersonId: member.arxReferenceId || '',
        cardFormat: card?.accessCardFormatName || '',
        cardNumber: card?.accessCardUID || '',
        isDeleted: true
      }
    };

    try {
      const result = await (API.graphql({
        query: updateAccessCardOfPerson,
        variables: data
      }) as Promise<{
        data: {
          error?: boolean | null;
          errorType?: string | null;
          errorMsg?: string | null;
          statusCode?: string | null;
          statusMessage?: string | null;
        };
      }>);

      if (!result.data.error) {
        await deactivateCardFromDatabase();
        setErrorMsg(false);
      }
    } catch (e) {
      setErrorMsg(true);
    }
  };

  const updateMemberFlagYellowBox = async () => {
    await (API.graphql({
      query: updateMemberFlag,
      variables: {
        memberId: member.memberId,
        flag: 1
      }
    }) as Promise<{
      data: { updateMemberFlag: number };
    }>);
  };

  const deactivateCardFromDatabase = async () => {
    const data: UpdateAccessCardHistoryMutationVariables = {
      cardId: selectedCard?.accessCardUID || '',
      lastModifiedBy: currentUser,
      deactivatedDateTime: dayjs(new Date()).format('YYYY-MM-DD HH:MM:ss')
    };

    try {
      const result = await (API.graphql({
        query: updateAccessCardHistory,
        variables: data
      }) as Promise<{
        data: UpdateAccessCardHistoryMutation;
      }>);

      if (result.data.updateAccessCardHistory === 1) {
        getCards();
        setErrorMsg(false);
      }
    } catch (e) {
      setErrorMsg(true);
    }
  };

  const cardChangeHandler = (e: DropdownChangeEvent) => {
    setselected(e.value);
  };

  useEffect(() => {
    setSelectedCard(activeCards?.find((card) => card.accessCardUID === selected));
  }, [activeCards, selected]);

  const dialogBoxContent = (
    <>
      {!isLoading && (
        <div>
          <label className="fw-bold">{t('DEACTIVATE_ACCESS_CARD.DROPDOWN_LABEL')}</label>

          <Dropdown
            onChange={cardChangeHandler}
            value={selected}
            options={cardsDropdown || []}
            error={dropdownError}
            placeholder={t('DEACTIVATE_ACCESS_CARD.DROPDOWN_PLACEHOLDER')}
          />
          <div className="mt15">
            {dropdownError && (
              <p className="p-error mb15">{t('DEACTIVATE_ACCESS_CARD.DROPDOWN_ERROR_TEXT')}</p>
            )}
          </div>
          {selected && selectedCard && (
            <p className="mt10">{`${t('DEACTIVATE_ACCESS_CARD.DEACTIVATE_MESSAGE')} ${
              selectedCard?.createdDate
            }`}</p>
          )}
          {errorMsg && (
            <p className="p-error mb15">{t('DEACTIVATE_ACCESS_CARD.DEACTIVATION_ERROR')}</p>
          )}
        </div>
      )}
      {isLoading && (
        <div>
          <div className="mb10">
            <Loader shape={'rectangle'} width={'quarter'} />
          </div>
          <div className="mb10">
            <Loader shape={'rectangle'} width={'full'} />
          </div>
          <div className="mb10">
            <Loader shape={'rectangle'} width={'full'} />
          </div>
          <div className="mb10">
            <Loader shape={'rectangle'} width={'full'} />
          </div>
        </div>
      )}
    </>
  );

  const hideModal = () => {
    setselected('');
    setDropdownError(false);
    setNoData(false);
    setIsScanning(false);
    setIsLoading(false);
    setSelectedCard(undefined);
    onHideCallBack();
  };

  return (
    <DialogBox
      variant={DialogBoxVariants.long}
      dialogVisible={visible}
      onHideCallback={() => hideModal()}
      dialogHeader={t('DEACTIVATE_ACCESS_CARD.TITLE')}
      dialogFooter={
        visible && !isLoading && !noData ? (
          <div>
            <Button
              label={t('DEACTIVATE_ACCESS_CARD.CANCEL_BUTTON')}
              variant={ButtonVariants.textonly}
              onClick={() => hideModal()}
            />
            <Button
              label={t('DEACTIVATE_ACCESS_CARD.DEACTIVATE_BUTTON')}
              icon={isScanning ? 'pi pi-spin pi-spinner' : ''}
              onClick={onDeactivateAccessCard}
              variant={ButtonVariants.danger}
            />
          </div>
        ) : visible && !isLoading && noData ? (
          <div>
            <Button
              label={t('DEACTIVATE_ACCESS_CARD.NO_CARD_OK_BUTTON')}
              onClick={() => hideModal()}
            />
          </div>
        ) : (
          <></>
        )
      }
      dialogDismissableMask={false}
      dialogClosable={true}
      content={
        visible && !noData ? (
          dialogBoxContent
        ) : visible && noData ? (
          <p>{t('DEACTIVATE_ACCESS_CARD.NO_CARD_ERROR_MESSAGE')}</p>
        ) : (
          <></>
        )
      }
    />
  );
};

export interface AccessCardDeactivateModalProps {
  visible: boolean;
  onHideCallBack: () => void;
  member: MemberAccessCard;
}

export default AccessCardDeactivateModal;
