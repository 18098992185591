import { Checkbox as PrimeCheckbox } from 'primereact/checkbox';
import styles from './Checkbox.module.scss';

const Checkbox = ({ label, checked = false, disabled = false, ...props }: CheckboxProps) => {
  const finalProps = {
    checked: checked,
    disabled: disabled,
    ...props
  };

  return (
    <>
      <PrimeCheckbox {...finalProps} />
      <label className={`${props.smallText ? styles.smallText : ''}`}>{label}</label>
    </>
  );
};

// This is PrimeReact's event object for onChange, taken from source
// (primereact/components/checkbox/Checkbox.d.ts)
export type CheckboxChangeEvent = {
  originalEvent: Event;
  value: any;
  checked: boolean;
  target: { type: string; name: string; id: string; value: any; checked: boolean };
};

export interface CheckboxProps {
  /** Label to be displayed */
  label: string;
  /** Value submitted to form when this checkbox is checked */
  value?: string;
  /**
   * Whether the checkbox is checked or not.
   * Clicking on this might not work unless onChange handler is given.
   * See `Checkbox In Form Example` for a demo.
   **/
  checked?: boolean;
  /** Disable checking/unchecking the checkbox */
  disabled?: boolean;
  /** Name is needed when working with forms */
  name?: string;
  /** onChange is needed when working with forms */
  onChange?: (e: CheckboxChangeEvent) => void;

  /** smallText style for checkbox label */
  smallText?: boolean;
}

export default Checkbox;
