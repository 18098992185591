import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import {
  getActivityForSiteLocation,
  getActivityMyTasksForSiteLocation,
  getActivityVisitsForSiteLocation,
  getMemberEventStatusTypes,
  getActivityMyTasksCount
} from '@gym-graphql/queries';
import { GymActivity, MemberEventStatus as MemberEventStatus_Generated } from '@gym-src/API';
import { Activity, MemberEventStatus } from '@gym-particles/types/models';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  items: Array<{
    userId: number;
    siteLocationId: number;
    activityItems: { items: Activity[]; totalRecords: number };
    myTaskItems: { items: Activity[]; totalRecords: number };
    visitItems: { items: Activity[]; totalRecords: number };
  }>;
  isActivityLoading: boolean;
  memberEventStatus?: MemberEventStatus[];
  myTaskCount?: number;
} = {
  items: [],
  isActivityLoading: false,
  memberEventStatus: []
};

type paramTypeForMyActivity = {
  userId: number;
  siteLocationId: number;
  offset: number;
  pageSize: number;
  dateFrom: string;
  dateTo: string;
  stageId: number;
  eventType: string;
  platformAdmin: boolean;
};

type paramTypeForVisits = {
  userId: number;
  siteLocationId: number;
  offset: number;
  pageSize: number;
  platformAdmin: boolean;
};

type paramType = {
  userId: number;
  roleId: number;
  siteLocationId: number;
  offset: number;
  pageSize: number;
  closedInquiries: boolean;
  platformAdmin: boolean;
};

type countParamType = {
  gymId: number;
  userId: number;
  roleId: number;
  platformAdmin: boolean;
};

export const fetchActivity = createAsyncThunk(
  '/gym/fetchActivity',
  async (params: paramTypeForMyActivity) => {
    const response = await (API.graphql({
      query: getActivityForSiteLocation,
      variables: {
        userId: params.userId,
        siteLocationId: params.siteLocationId,
        offset: params.offset,
        pageSize: params.pageSize,
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        stageId: params.stageId,
        eventType: params.eventType,
        platformAdmin: params.platformAdmin
      }
    }) as Promise<{
      data: {
        getActivityForSiteLocation: {
          items: GymActivity[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const fetchActivityMyTasks = createAsyncThunk(
  '/gym/fetchActivityMyTasks',
  async (params: paramType) => {
    const response = await (API.graphql({
      query: getActivityMyTasksForSiteLocation,
      variables: {
        userId: params.userId,
        roleId: params.roleId,
        siteLocationId: params.siteLocationId,
        offset: params.offset,
        pageSize: params.pageSize,
        closedInquiries: params.closedInquiries,
        platformAdmin: params.platformAdmin
      }
    }) as Promise<{
      data: {
        getActivityMyTasksForSiteLocation: {
          items: GymActivity[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const fetchActivityVisits = createAsyncThunk(
  '/gym/fetchActivityVisits',
  async (params: paramTypeForVisits) => {
    const response = await (API.graphql({
      query: getActivityVisitsForSiteLocation,
      variables: {
        userId: params.userId,
        siteLocationId: params.siteLocationId,
        offset: params.offset,
        pageSize: params.pageSize,
        platformAdmin: params.platformAdmin
      }
    }) as Promise<{
      data: {
        getActivityVisitsForSiteLocation: {
          items: GymActivity[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const fetchMemberEventStatusTypes = createAsyncThunk(
  '/gym/fetchMemberEventStatusTypes',
  async () => {
    const response = await (API.graphql({
      query: getMemberEventStatusTypes
    }) as Promise<{
      data: {
        getMemberEventStatusTypes: MemberEventStatus_Generated[];
      };
    }>);
    return response;
  }
);

export const fetchMyTaskCount = createAsyncThunk(
  'gym/fetchMyTaskCount',
  async (params: countParamType) => {
    const response = await (API.graphql({
      query: getActivityMyTasksCount,
      variables: {
        userId: params.userId,
        roleId: params.roleId,
        siteLocationId: params.gymId,
        platformAdmin: params.platformAdmin
      }
    }) as Promise<{
      data: { getActivityMyTasksCount: { count: number } };
    }>);
    return response;
  }
);

export const ActivitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    incrementMyTaskCount: (state) => {
      if (state.myTaskCount) state.myTaskCount += 1;
      return state;
    },
    decrementMyTaskCount: (state) => {
      if (state.myTaskCount) state.myTaskCount -= 1;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActivity.fulfilled, (state, action) => {
      state.isActivityLoading = false;
      if (action.payload.data.getActivityForSiteLocation.items === null) {
        return;
      }
      const items = action.payload.data.getActivityForSiteLocation.items.map(
        (e: GymActivity): Activity => {
          return {
            memberEventId: e.memberEventId || 0,
            memberId: e.memberId || 0,
            memberEmail: e.memberEmail || '',
            eventId: e.eventId || 0,
            accessCardUID: e.accessCardUID || undefined,
            eventDescription: e.eventDescription || '',
            eventType: e.eventType || '',
            stageId: e.stageId || 0,
            stage: e.stage || '',
            createdTime: e.createdTime
              ? dayjs
                  .utc(e.createdTime || '')
                  .local()
                  .format('hh:mm A')
                  .toString()
              : '',
            isAutoAssigned: e.isAutoAssigned || false,
            memberEventStatusTypeId: e.memberEventStatusTypeId || 0,
            memberEventStatusType: e.memberEventStatusType || '',
            firstName: e.firstName || '',
            lastName: e.lastName || '',
            siteLocationId: e.siteLocationId || 0,
            locationName: e.locationName || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            activityDate: e?.createdTime
              ? dayjs
                  .utc(e?.createdTime || '')
                  .local()
                  .format('MM DD YYYY')
                  .toString()
              : '',
            assignedUserId: e.assignedUserId || 0,
            assignmentHistoryId: e.assignmentHistoryId || undefined,
            inquiryDescription: e.inquiryDescription || '',
            memberLocationId: e.memberLocationId || 0,
            informationReceivedEvent: e.informationReceivedEvent || false,
            language: e.language || 'en',
            userId: e.userId || 0,
            en: e.en || '',
            nor: e.nor || '',
            nl: e.nl || ''
          };
        }
      );

      const totalRecords = action.payload.data.getActivityForSiteLocation.totalRecords.totalRecords;

      const fetchedActivityInfo = {
        activityItems: { items: items, totalRecords: totalRecords },
        myTaskItems: {
          items:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.myTaskItems.items || [],
          totalRecords:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.myTaskItems.totalRecords || 0
        },
        visitItems: {
          items:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.visitItems.items || [],
          totalRecords:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.visitItems.totalRecords || 0
        },
        userId: action.meta.arg.userId,
        siteLocationId: action.meta.arg.siteLocationId
      };
      if (state.items.length === 0) {
        state.items.push(fetchedActivityInfo);
      } else {
        // Check if we are updating an already fetched or fetching new one
        const update = state.items.find(
          (activityInfo) =>
            activityInfo.userId === fetchedActivityInfo.userId &&
            activityInfo.siteLocationId === fetchedActivityInfo.siteLocationId
        );
        if (update) {
          const newState = state.items.map((activityInfo) => {
            if (
              activityInfo.userId === fetchedActivityInfo.userId &&
              activityInfo.siteLocationId === fetchedActivityInfo.siteLocationId
            ) {
              return fetchedActivityInfo;
            } else {
              return activityInfo;
            }
          });
          Object.assign(state.items, newState);
        } else {
          state.items.push(fetchedActivityInfo);
        }
      }

      return state;
    });

    builder.addCase(fetchActivity.pending, (state, action) => {
      state.isActivityLoading = true;
      return state;
    });

    builder.addCase(fetchActivity.rejected, (state, action) => {
      state.isActivityLoading = false;
      console.log('Get Activity Error: ', action);
      return state;
    });

    builder.addCase(fetchActivityMyTasks.fulfilled, (state, action) => {
      state.isActivityLoading = false;
      if (action.payload.data.getActivityMyTasksForSiteLocation.items === null) {
        return;
      }
      const items = action.payload.data.getActivityMyTasksForSiteLocation.items.map(
        (e: GymActivity): Activity => {
          return {
            memberEventId: e.memberEventId || 0,
            memberId: e.memberId || 0,
            memberEmail: e.memberEmail || '',
            eventId: e.eventId || 0,
            accessCardUID: e.accessCardUID || undefined,
            eventDescription: e.eventDescription || '',
            eventType: e.eventType || '',
            stageId: e.stageId || 0,
            stage: e.stage || '',
            createdTime: e.createdTime
              ? dayjs
                  .utc(e.createdTime || '')
                  .local()
                  .format('hh:mm A')
                  .toString()
              : '',
            isAutoAssigned: e.isAutoAssigned || false,
            memberEventStatusTypeId: e.memberEventStatusTypeId || 0,
            memberEventStatusType: e.memberEventStatusType || '',
            firstName: e.firstName || '',
            lastName: e.lastName || '',
            siteLocationId: e.siteLocationId || 0,
            locationName: e.locationName || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            activityDate: e?.createdTime
              ? dayjs
                  .utc(e?.createdTime || '')
                  .local()
                  .format('MM DD YYYY')
                  .toString()
              : '',
            assignedUserId: e.assignedUserId || 0,
            assignmentHistoryId: e.assignmentHistoryId || undefined,
            inquiryDescription: e.inquiryDescription || '',
            memberLocationId: e.memberLocationId || 0,
            informationReceivedEvent: e.informationReceivedEvent || false,
            language: e.language || 'en',
            en: e.en || '',
            nor: e.nor || '',
            nl: e.nl || ''
          };
        }
      );

      const totalRecords =
        action.payload.data.getActivityMyTasksForSiteLocation.totalRecords.totalRecords;

      const fetchedActivityInfo = {
        activityItems: {
          items:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.activityItems.items || [],
          totalRecords:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.activityItems.totalRecords || 0
        },
        myTaskItems: {
          items: items,
          totalRecords: totalRecords
        },
        visitItems: {
          items:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.visitItems.items || [],
          totalRecords:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.visitItems.totalRecords || 0
        },
        userId: action.meta.arg.userId,
        siteLocationId: action.meta.arg.siteLocationId
      };
      if (state.items.length === 0) {
        state.items.push(fetchedActivityInfo);
      } else {
        // Check if we are updating an already fetched or fetching new one
        const update = state.items.find(
          (activityInfo) =>
            activityInfo.userId === fetchedActivityInfo.userId &&
            activityInfo.siteLocationId === fetchedActivityInfo.siteLocationId
        );
        if (update) {
          const newState = state.items.map((activityInfo) => {
            if (
              activityInfo.userId === fetchedActivityInfo.userId &&
              activityInfo.siteLocationId === fetchedActivityInfo.siteLocationId
            ) {
              return fetchedActivityInfo;
            } else {
              return activityInfo;
            }
          });
          Object.assign(state.items, newState);
        } else {
          state.items.push(fetchedActivityInfo);
        }
      }

      return state;
    });

    builder.addCase(fetchActivityMyTasks.pending, (state, action) => {
      state.isActivityLoading = true;
      return state;
    });

    builder.addCase(fetchActivityMyTasks.rejected, (state, action) => {
      state.isActivityLoading = false;
      console.log('Get My Tasks Error: ', action);
      return state;
    });

    builder.addCase(fetchActivityVisits.fulfilled, (state, action) => {
      state.isActivityLoading = false;
      if (action.payload.data.getActivityVisitsForSiteLocation.items === null) {
        return;
      }
      const items = action.payload.data.getActivityVisitsForSiteLocation.items.map(
        (e: GymActivity): Activity => {
          return {
            memberEventId: e.memberEventId || 0,
            memberId: e.memberId || 0,
            memberEmail: e.memberEmail || '',
            eventId: e.eventId || 0,
            accessCardUID: e.accessCardUID || undefined,
            eventDescription: e.eventDescription || '',
            eventType: e.eventType || '',
            stageId: e.stageId || 0,
            stage: e.stage || '',
            createdTime: e.createdTime
              ? dayjs
                  .utc(e.createdTime || '')
                  .local()
                  .format('hh:mm A')
                  .toString()
              : '',
            isAutoAssigned: e.isAutoAssigned || false,
            memberEventStatusTypeId: e.memberEventStatusTypeId || 0,
            memberEventStatusType: e.memberEventStatusType || '',
            firstName: e.firstName || '',
            lastName: e.lastName || '',
            siteLocationId: e.siteLocationId || 0,
            locationName: e.locationName || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            activityDate: e?.createdTime
              ? dayjs
                  .utc(e?.createdTime || '')
                  .local()
                  .format('MM DD YYYY')
                  .toString()
              : '',
            assignedUserId: e.assignedUserId || 0,
            memberLocationId: e.memberLocationId || 0,
            informationReceivedEvent: e.informationReceivedEvent || false,
            language: e.language || 'en',
            userId: e.userId || 0,
            en: e.en || '',
            nor: e.nor || '',
            nl: e.nl || ''
          };
        }
      );

      const totalRecords =
        action.payload.data.getActivityVisitsForSiteLocation.totalRecords.totalRecords;

      const fetchedActivityInfo = {
        activityItems: {
          items:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.activityItems.items || [],
          totalRecords:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.activityItems.totalRecords || 0
        },
        myTaskItems: {
          items:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.myTaskItems.items || [],
          totalRecords:
            state.items.find(
              (i) =>
                i.userId === action.meta.arg.userId &&
                i.siteLocationId === action.meta.arg.siteLocationId
            )?.myTaskItems.totalRecords || 0
        },
        visitItems: { items: items, totalRecords: totalRecords },
        userId: action.meta.arg.userId,
        siteLocationId: action.meta.arg.siteLocationId
      };
      if (state.items.length === 0) {
        state.items.push(fetchedActivityInfo);
      } else {
        // Check if we are updating an already fetched or fetching new one
        const update = state.items.find(
          (activityInfo) =>
            activityInfo.userId === fetchedActivityInfo.userId &&
            activityInfo.siteLocationId === fetchedActivityInfo.siteLocationId
        );
        if (update) {
          const newState = state.items.map((activityInfo) => {
            if (
              activityInfo.userId === fetchedActivityInfo.userId &&
              activityInfo.siteLocationId === fetchedActivityInfo.siteLocationId
            ) {
              return fetchedActivityInfo;
            } else {
              return activityInfo;
            }
          });
          Object.assign(state.items, newState);
        } else {
          state.items.push(fetchedActivityInfo);
        }
      }

      return state;
    });

    builder.addCase(fetchActivityVisits.pending, (state, action) => {
      state.isActivityLoading = true;
      return state;
    });

    builder.addCase(fetchActivityVisits.rejected, (state, action) => {
      state.isActivityLoading = false;
      console.log('Get Visits Error: ', action);
      return state;
    });

    builder.addCase(fetchMemberEventStatusTypes.fulfilled, (state, action) => {
      const statuses: MemberEventStatus[] = [];
      action.payload.data.getMemberEventStatusTypes.forEach((status) => {
        if (status.id && status.description) {
          statuses.push({ id: status.id, description: status.description });
        }
      });
      state.memberEventStatus = statuses;
      return state;
    });

    builder.addCase(fetchMemberEventStatusTypes.rejected, (state, action) => {
      console.error('Get MemberEventStatus error:', action);
      return state;
    });

    builder.addCase(fetchMyTaskCount.fulfilled, (state, action) => {
      const count = action.payload.data.getActivityMyTasksCount.count;
      state.myTaskCount = count;
      return state;
    });

    builder.addCase(fetchMyTaskCount.rejected, (state, action) => {
      console.error('Get my task count error:', action);
      return state;
    });
  }
});

export const { incrementMyTaskCount, decrementMyTaskCount } = ActivitySlice.actions;

export default ActivitySlice.reducer;
