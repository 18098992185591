import Text, { TextSizes } from '@gym-atoms/Text/Text';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { useCallback, useEffect, useState } from 'react';
import Dropdown, { DropdownChangeEvent, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import {
  PricingRelationshipFormInput,
  PricingRelationshipTable
} from '@gym-particles/types/PricingRelationships';
import { API } from 'aws-amplify';
import { importPrograms } from '@gym-graphql/queries';
import { fetchPricingRelationships } from '@gym-redux/slices/pricingRelationshipSlice';
import { modifyPricingRelationships } from '@gym-organisms/PricingRelationships/PricingRelationshipList';
import { AddProgram, RemoveProgram } from '@gym-src/API';

const PricingRelationshipTemplate = (props: PricingRelationshipTemplateProps) => {
  const { t } = useTranslation();
  const [isInserted, setIsInserted] = useState(false);
  const [isImported, setIsImported] = useState(false);
  const { chainId } = useParams<{ chainId: string }>();
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const dispatch = useAppDispatch();
  const isPricingRelationshipLoading = useAppSelector(
    (state) => state.pricingRelationships.isPricingRelationshipLoading
  );
  const customerSiteId = useAppSelector((state) => state.gymChain.currentGymChain?.customerSiteId);
  const userId = useAppSelector((state) => state.user.userId);
  const [linkedProgramValues, setLinkedProgramValues] = useState<any[]>([]);
  const [orignalProgramValues, setOrignalProgramValues] = useState(0);
  const getTranslationKey = (e: string): string => {
    return props.type === 'new'
      ? 'ADD_NEW_PRICING_RELATIONSHIP.' + e
      : 'MODIFY_PRICING_RELATIONSHIP.' + e;
  };

  const templateTypeChangeHandler = useCallback(
    (state) => {
      if (props.templateType) {
        props.templateType(state);
      }
    },
    [props.templateType]
  );

  useEffect(() => {
    dispatch(fetchPricingRelationships(+chainId));
  }, []);
  const programRelationships = useAppSelector((state) => state.pricingRelationships.items);

  const [orignalProgramDropDownItems, setOrignalProgramDropDownItems] = useState<Array<SelectItem>>(
    []
  );
  const [linkedProgramDropDownItems, setLinkedProgramDropDownItems] = useState<Array<SelectItem>>(
    []
  );

  useEffect(() => {
    const orignalProgramDropdownData: SelectItem[] = programRelationships
      .filter((p) => {
        if (props.type === 'new') {
          return p.relatedPrograms.length === 0;
        } else {
          return true;
        }
      })
      .map((p) => {
        return {
          label: p.programName,
          value: p.id
        };
      });
    const linkedProgramDropdownData: SelectItem[] = programRelationships.map((p) => {
      return {
        label: p.programName,
        value: p.id
      };
    });

    setOrignalProgramDropDownItems(orignalProgramDropdownData);
    setLinkedProgramDropDownItems(linkedProgramDropdownData);
  }, [isPricingRelationshipLoading, props]);

  const programDefaultValues: PricingRelationshipFormInput = {
    orignalProgram: props.programRelationship?.id || 0,
    linkedPrograms: props.programRelationship?.relatedPrograms.map((r) => r.id) || []
  };

  const [formValues, setFormValues] = useState(programDefaultValues);
  const { register, handleSubmit, setValue, errors, reset } = useForm<PricingRelationshipFormInput>(
    {
      defaultValues: programDefaultValues
    }
  );

  const emptyValues: PricingRelationshipFormInput = {
    orignalProgram: 0,
    linkedPrograms: []
  };

  const importProgramRelationships = async () => {
    setIsImported(true);
    try {
      await API.graphql({
        query: importPrograms,
        variables: {
          siteId: chainId,
          customerSiteId: customerSiteId
        }
      });
      dispatch(fetchPricingRelationships(+chainId));
    } catch (err) {
      console.error('failed to import program relationships: ', err);
    }
    setIsImported(false);
  };

  useEffect(() => {
    setFormValues(programDefaultValues);
    setValue('orignalProgram', programDefaultValues.orignalProgram);
    setValue('linkedPrograms', programDefaultValues.linkedPrograms);
    setOrignalProgramValues(programDefaultValues.orignalProgram);
    setLinkedProgramValues(programDefaultValues.linkedPrograms);

    setLinkedProgramDropDownItems(
      programRelationships
        .map((p) => {
          return {
            label: p.programName,
            value: p.id
          };
        })
        .filter((a) => a.value !== programDefaultValues.orignalProgram)
    );
  }, [props.programRelationship]);

  const resetForm = () => {
    setFormValues(emptyValues);
    reset(emptyValues);
    setLinkedProgramValues([]);
    setOrignalProgramValues(0);
  };

  const dropdownChangeHandler = (e: DropdownChangeEvent) => {
    if (e.target.name === 'orignalProgram') {
      setLinkedProgramDropDownItems(
        programRelationships
          .map((p) => {
            return {
              label: p.programName,
              value: p.id
            };
          })
          .filter((a) => a.value !== e.value)
      );
      setValue('linkedPrograms', programDefaultValues.linkedPrograms);
      setFormValues({
        ...formValues,
        linkedPrograms: programDefaultValues.linkedPrograms
      });
      setLinkedProgramValues([]);
    }
    setValue(e.target.name as keyof PricingRelationshipFormInput, e.value);
    setFormValues({ ...formValues, [e.target.name]: e.value });
    if (e.target.name === 'orignalProgram') {
      setOrignalProgramValues(e.value);
    }
    if (e.target.name === 'linkedPrograms') {
      setLinkedProgramValues(e.value);
    }
  };

  useEffect(() => {
    register('orignalProgram', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('linkedPrograms', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
  }, [register]);

  const addProgramRecord = async (formData: PricingRelationshipFormInput) => {
    const addProgram = formData.linkedPrograms.map((p) => {
      const relatedProgramsFound = programRelationships.find((pr) => pr.id === +p);
      return {
        originalProgramId: +formData.orignalProgram,
        originalProgramImportedId:
          programRelationships.find((pr) => pr.id === +formData.orignalProgram)?.importedId || 0,
        relatedProgramId: +p,
        relatedProgramName: relatedProgramsFound?.programName || '',
        relatedProgramImportedId: relatedProgramsFound?.importedId || 0,
        relatedProgramScheduleType: relatedProgramsFound?.scheduleType || '',
        createdBy: userId,
        siteId: +chainId
      };
    });

    const response = await modifyPricingRelationships(addProgram, []);
    if (response.error) {
      console.error(response.errorMsg);
    } else {
      dispatch(fetchPricingRelationships(+chainId));
    }
    resetForm();
  };

  const modifyProgramRecord = async (formData: PricingRelationshipFormInput) => {
    const addArr: AddProgram[] = [];
    const removeArr: RemoveProgram[] = [];
    props.programRelationship?.relatedPrograms.forEach((r) => {
      if (!formData.linkedPrograms.find((a) => a === r.id)) {
        removeArr.push({
          originalProgramId: props.programRelationship?.id || 0,
          originalProgramImportedId: props.programRelationship?.importedId || 0,
          relatedProgramId: r.id,
          relatedProgramImportedId: r.importedId,
          lastModifiedBy: userId,
          siteId: +chainId
        });
      }
    });
    formData.linkedPrograms.forEach((f) => {
      if (!props.programRelationship?.relatedPrograms.find((x) => x.id === f)) {
        const program = programRelationships.find((p) => p.id === f);
        addArr.push({
          originalProgramId: props.programRelationship?.id || 0,
          originalProgramImportedId: props.programRelationship?.importedId || 0,
          relatedProgramId: program?.id || 0,
          relatedProgramName: program?.programName || '',
          relatedProgramImportedId: program?.importedId || 0,
          relatedProgramScheduleType: program?.scheduleType || '',
          createdBy: userId,
          siteId: +chainId
        });
      }
    });

    const response = await modifyPricingRelationships(addArr, removeArr);
    if (response.error) {
      console.error(response.errorMsg);
    } else {
      dispatch(fetchPricingRelationships(+chainId));
    }
    resetForm();
  };

  const onSubmitHandler = async (data: PricingRelationshipFormInput) => {
    setIsInserted(true);
    if (props.type === 'new') {
      await addProgramRecord(data);
    } else {
      await modifyProgramRecord(data);
      templateTypeChangeHandler(false);
    }
    dispatch(fetchPricingRelationships(+chainId));
    setIsInserted(false);
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}</Text>}
        />
      </div>
    );
  };

  return (
    <div className="ml30 mt10">
      <div className="d-flex">
        <div className="mr15">
          <Text bold={true} size={TextSizes.large} className="text-2xl">
            {props.heading}
          </Text>
        </div>

        <Button
          icon={isImported ? 'pi-spinner pi-spin' : ''}
          label={t(getTranslationKey('FORM_BTN_LABEL_IMPORT'))}
          size={ButtonSizes.medium}
          onClick={importProgramRelationships}
        />
      </div>
      <div className="col-md-6">
        <form className="input-mb20 py-4" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <div className="row mb40">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_ORIGNAL_PROGRAM_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                name="orignalProgram"
                options={orignalProgramDropDownItems}
                value={orignalProgramValues}
                onChange={dropdownChangeHandler}
                placeholder={t(getTranslationKey('FORM_INPUT_ORIGNAL_PROGRAM_PLACEHOLDER'))}
                error={errors.orignalProgram ? true : false}
                disabled={props.type === 'modify'}
              />
            </div>
            {errors.orignalProgram && (
              <Text className="p-error mt10">
                {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
              </Text>
            )}
          </div>
          <div className="row mb25">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_LINKED_PROGRAM_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                chips={true}
                name="linkedPrograms"
                options={linkedProgramDropDownItems}
                onChange={dropdownChangeHandler}
                value={linkedProgramValues}
                placeholder={t(getTranslationKey('FORM_INPUT_LINKED_PROGRAM_PLACEHOLDER'))}
                error={errors.linkedPrograms ? true : false}
                disabled={formValues.orignalProgram ? false : true}
              />
              {errors.linkedPrograms && (
                <Text className="p-error mt10">
                  {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                </Text>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="d-flex btn-min-w-110">
                <div className="mr15">
                  <Button
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                    label={t(getTranslationKey('FORM_BTN_LABEL_SAVE'))}
                    size={ButtonSizes.medium}
                    disabled={linkedProgramValues.length === 0}
                  />
                </div>
                <Button
                  label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
                  variant={ButtonVariants.textonly}
                  onClick={(e) => {
                    e.preventDefault();
                    resetForm();
                    templateTypeChangeHandler(false);
                  }}
                  disabled={isInserted}
                />
              </div>
              <ActionFailedDialog />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
type PricingRelationshipTemplateType = 'new' | 'modify';

export interface PricingRelationshipTemplateProps {
  /** Type of the template: new or modify */
  type: PricingRelationshipTemplateType;
  /** Heading of the template */
  heading?: string;
  /** form values*/
  programRelationship?: PricingRelationshipTable | undefined;
  /** template change function */
  templateType?: (e: boolean) => void;
}

export default PricingRelationshipTemplate;
