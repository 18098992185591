import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import Loader from '@gym-atoms/Loader/Loader';
import ActivityFeed from '@gym-molecules/ActivityFeed/ActivityFeed';
import EmptyState from '@gym-molecules/EmptyState/EmptyState';
import InquiriesFilters from '@gym-molecules/InquiriesFilters/InquiriesFilters';
import { UserRole } from '@gym-particles/types/User';
import { fetchActivityMyTasksForUser } from '@gym-redux/slices/homeActivitySlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const HomeDashboardTasks = (props: HomeDashboardTasksProps) => {
  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const { t } = useTranslation();
  const userId = useAppSelector((state) => state.user.userId);
  const userRole = useAppSelector((state) => state.user.userRole);
  const userRoleId = useAppSelector((state) => state.user.roleId);
  const [refresh, setRefresh] = useState(false);
  const [openInquiries, setOpenInquiries] = useState(true);
  const [closedInquiries, setClosedInquiries] = useState(false);
  const [closedFilter, setClosedFilter] = useState(false);
  const isActivityLoading = useAppSelector((state) => state.homeActivity.isActivityLoading);
  const totalActivityCount =
    useAppSelector(
      (state) => state.homeActivity.items.find((g) => g.userId === userId)?.myTaskItems.totalRecords
    ) || 0;

  const fetchTasks = () => {
    dispatch(
      fetchActivityMyTasksForUser({
        userId: userId,
        roleId: userRoleId,
        pageSize: pageSize,
        offset: offset,
        closedInquiries: closedFilter,
        platformAdmin: userRole === UserRole.SYSTEM_ADMIN
      })
    );
  };

  const openInquiriesChangeHandler = () => {
    setClosedFilter(false);
    setOpenInquiries(true);
    setClosedInquiries(false);
  };
  const closedInquiriesChangeHandler = () => {
    setClosedFilter(true);
    setOpenInquiries(false);
    setClosedInquiries(true);
  };

  useEffect(() => {
    fetchTasks();
  }, [userId, offset, pageSize, closedFilter, refresh]);

  const activities = useAppSelector(
    (state) => state.homeActivity.items.find((e) => e.userId === userId)?.myTaskItems.items
  );

  return (
    <>
      {isActivityLoading ? (
        <Loader shape="activity" />
      ) : (
        <>
          <div>
            <InquiriesFilters
              openInquiries={openInquiries}
              closedInquiries={closedInquiries}
              openInquiriesOnChange={openInquiriesChangeHandler}
              closedInquiriesOnChange={closedInquiriesChangeHandler}
            />
          </div>
          <div className="activty-feed">
            <ActivityFeed
              activities={activities || []}
              stage={'home'}
              refreshCallBack={() => setRefresh(!refresh)}
              countChange={props.countChange}
              isCountChanged={props.isCountChanged}></ActivityFeed>
          </div>
          <div className="text-center">
            {activities?.length === 0 ? (
              <EmptyState textItems={[t('HOME_PAGE.EMPTY_STATE_TEXT')]} />
            ) : (
              <div className="button-view-all">
                <Button
                  variant={ButtonVariants.textonlyslim}
                  label={t('HOME_PAGE.VIEW_PREVIOUS_ACTIVITY')}
                  icon="pi-angle-left"
                  iconPos="left"
                  disabled={offset === 0}
                  onClick={() => setOffset(offset - pageSize)}></Button>
                <Button
                  variant={ButtonVariants.textonlyslim}
                  label={t('HOME_PAGE.VIEW_NEXT_ACTIVITY')}
                  icon="pi-angle-right"
                  iconPos="right"
                  disabled={offset + pageSize >= totalActivityCount}
                  onClick={() => setOffset(offset + pageSize)}></Button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export interface HomeDashboardTasksProps {
  countChange?: (e: boolean) => void;
  isCountChanged?: boolean;
}

export default HomeDashboardTasks;
