import { ReactNode, RefObject } from 'react';
import Alert, { AlertType } from '@gym-atoms/Alert/Alert';
import styles from './TwoColTemplate.module.scss';
import Avatar from '@gym-atoms/Avatar/Avatar';
import Text from '@gym-atoms/Text/Text';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { sidebarPropsSlice } from '@gym-redux/slices/sidebarPropsSlice';

const TwoColTemplate = ({
  title,
  col1,
  col2,
  alertRef,
  backButtonHandler,
  buttonAriaLabel = '',
  gymChainId,
  showBackButton = true
}: TwoColTemplateProps) => {
  const gymChainInfo = useAppSelector((state) =>
    state.gymChain.items.find((g) => g.id === gymChainId)
  );
  const dispatch = useAppDispatch();
  const showHide = () => {
    dispatch(sidebarPropsSlice.actions.show());
  };
  return (
    <div className="page-content flex-grow-1">
      <div className="page-wrapper--inner">
        <div className="page-header">
          <div
            onClick={showHide}
            onKeyDown={showHide}
            aria-hidden="true"
            className="page-mobile-sidebarbtn d-flex d-lg-none">
            <i className="pi pi-align-left pi-24 p-mr-2"></i>
          </div>
          <div className="p-grid">
            <div className="p-col-8">
              <h2 data-cy={'two-col-page-header'} className="text-ellipsis text-2xl font-bold">
                {showBackButton && (
                  <button
                    aria-label={buttonAriaLabel}
                    onClick={backButtonHandler}
                    className={styles.backButton}>
                    <i className="pi pi-arrow-left font-weight-bold pi-24 p-mr-2"></i>
                  </button>
                )}
                {title}
              </h2>
            </div>
            <div className="p-col-4">
              {gymChainId && (
                <div className="d-flex align-items-center justify-content-end">
                  <div className="mr5">
                    <Avatar image={gymChainInfo?.avatar} size="default" />
                  </div>
                  <Text bold>{gymChainInfo?.name}</Text>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="row">
            <div className={col2 ? 'col-lg-8 col-xl-9' : 'col-lg-12 col-xl-12'}>
              <Alert refProp={alertRef} />
              {col1}
            </div>
            <div className="col-lg-4 col-xl-3">{col2}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface TwoColTemplateProps {
  /** Title to display on top of the page */
  title: string;
  /** First column */
  col1: ReactNode;
  /** Second column */
  col2?: ReactNode;
  /** Alert ref to show alerts */
  alertRef: RefObject<AlertType>;
  /** Routing function to execute on back button click */
  backButtonHandler: () => void;
  /** Adding aria lebal to button */
  buttonAriaLabel?: string;
  /**Show gym chain logo if available */
  gymChainId?: number;
  /**Show back button*/
  showBackButton?: boolean;
}

export default TwoColTemplate;
