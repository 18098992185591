const Image = ({ source, alt = '', width, height, circle = false, ...props }: ImageProps) => {
  return (
    <img
      src={source}
      alt={alt}
      width={width}
      height={height}
      className={circle ? 'rounded-circle' : ''}
      {...props}
    />
  );
};

export interface ImageProps {
  /** Source of the image. Path or URL. */
  source: string;
  /** Alt-text for the image */
  alt?: string;
  /** Width in pixels */
  width?: number;
  /** Height in pixels */
  height?: number;
  /** Whether this image is a circle */
  circle?: boolean;
}

export default Image;
