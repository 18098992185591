import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { DaySchedule as typeOfDaySchedule } from '@gym-particles/types/DaySchedules';
import { getDayScheduleLinkCount, getDaySchedules } from '@gym-graphql/queries';
import { DaySchedule } from '@gym-src/API';
import { PaginatedQueryParams } from '@gym-particles/types/Pagination';
import { removeDaySchedules } from '@gym-graphql/mutations';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  items: Array<typeOfDaySchedule>;
  totalRecords: number;
  isDayScheduleLoading: boolean;
  dayScheduleLinkCount?: number;
} = {
  items: [],
  totalRecords: 0,
  isDayScheduleLoading: false
};

export const fetchDaySchedules = createAsyncThunk(
  '/fetchDaySchedules',
  async (params: PaginatedQueryParams) => {
    const response = await (API.graphql({
      query: getDaySchedules,
      variables: {
        offset: params.offset,
        pageSize: params.pageSize,
        sortField: params.sortField,
        sortOrder: params.sortOrder === 1 ? 'ASC' : 'DESC',
        search: {
          searchField: params.search?.searchField,
          searchText: params.search?.searchText
        }
      }
    }) as Promise<{
      data: {
        getDaySchedules: {
          items: DaySchedule[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const deleteDaySchedule = createAsyncThunk(
  'DaySchedule/deleteDaySchedule',
  async (id: number) => {
    const response = await (API.graphql({
      query: removeDaySchedules,
      variables: {
        id: id
      }
    }) as Promise<{
      data: {
        removeDaySchedules: DaySchedule;
      };
    }>);
    return response;
  }
);

export const fetchDayScheduleLinkCount = createAsyncThunk(
  'DaySchedule/fetchDayScheduleLinkCount',
  async (dayScheduleId: number) => {
    const response = await (API.graphql({
      query: getDayScheduleLinkCount,
      variables: {
        dayScheduleId: dayScheduleId
      }
    }) as Promise<{
      data: { getDayScheduleLinkCount: { count: number } };
    }>);
    return response;
  }
);

export const DaySchedulesSlice = createSlice({
  name: 'daySchedule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDaySchedules.fulfilled, (state, action) => {
      state.isDayScheduleLoading = false;
      if (action.payload.data.getDaySchedules.items === null) {
        return;
      }
      state.items = action.payload.data.getDaySchedules.items.map(
        (e: DaySchedule): typeOfDaySchedule => {
          return {
            id: e.id || 0,
            name: e.name || '',
            arxReferenceId: e.arxReferenceId || '',
            startTime: e.startTime ? dayjs(e.startTime || '').format('HH:mm') : '',
            endTime: e.endTime ? dayjs(e.endTime || '').format('HH:mm') : '',
            status: e.status || '',
            weekdayId: e.weekdayId || 0,
            weekdayName: e.weekdayName || '',
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            createdBy: e.createdBy || 0,
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            displayStatus: e.status ? e.status.charAt(0).toUpperCase() + e.status.slice(1) : ''
          };
        }
      );

      state.totalRecords = action.payload.data.getDaySchedules.totalRecords.totalRecords;
      return state;
    });

    builder.addCase(fetchDaySchedules.pending, (state, action) => {
      state.isDayScheduleLoading = true;
      return state;
    });

    builder.addCase(fetchDaySchedules.rejected, (state, action) => {
      state.isDayScheduleLoading = false;
      return state;
    });

    builder.addCase(deleteDaySchedule.fulfilled, (state, action) => {
      state.isDayScheduleLoading = false;
      const filteredDaySchedules = state.items.filter((ds) => ds.id !== action.meta.arg);
      state.items = filteredDaySchedules;
      state.totalRecords = state.totalRecords - 1;
      return state;
    });

    builder.addCase(deleteDaySchedule.pending, (state, action) => {
      state.isDayScheduleLoading = true;
      return state;
    });

    builder.addCase(deleteDaySchedule.rejected, (state, action) => {
      state.isDayScheduleLoading = false;
      return state;
    });
    builder.addCase(fetchDayScheduleLinkCount.fulfilled, (state, action) => {
      const count = action.payload.data.getDayScheduleLinkCount.count;
      state.dayScheduleLinkCount = count;
      return state;
    });
    builder.addCase(fetchDayScheduleLinkCount.rejected, (state, action) => {
      console.error('Get DaySchedule Link Count error:', action);
      return state;
    });
  }
});

export default DaySchedulesSlice.reducer;
