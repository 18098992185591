import { useEffect, useRef } from 'react';
import { AlertType } from '@gym-atoms/Alert/Alert';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { Card } from 'primereact/card';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '@gym-redux/store';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import Status, { StatusVariants } from '@gym-atoms/Status/Status';
import Stepper from '@gym-atoms/Stepper/Stepper';
import { MenuItem } from '@gym-atoms/VerticalMenu/VerticalMenu';
import { useState } from 'react';
import GymSchedulesForm from '@gym-organisms/GymSchedules/GymSchedulesForm';
import {
  GymSchedulesAccessPointMapping,
  ScheduleEvent,
  GymSchedulesFormInput,
  GymSchedulesGymZoneMapping,
  GymScheduleStatus,
  ScheduleInternalStatus,
  GymScheduleZoneMapping,
  GymScheduleAccessCategoryMapping,
  GymScheduleAccessPointMapping
} from '@gym-particles/types/GymSchedules';
import GymZoneMapping from '@gym-organisms/GymSchedules/GymSchedulesZoneMapping';
import OpeningHoursScheduler from '@gym-organisms/GymSchedules/GymSchedulesScheduler';
import { fetchGyms } from '@gym-redux/slices/gymsSlice';
import { API } from 'aws-amplify';
import {
  getAccessPointsForSite,
  getZoneAccessCategoryMappingForGymSchedules
} from '@gym-graphql/queries';
import { SiteAccessPoint, ZoneAccessCategoryMapping } from '@gym-src/API';
import { getAllScheduleStatuses } from '@gym-graphql/queries';
import { ScheduleStatuses } from '@gym-src/API';
import { GymType } from '@gym-particles/types/Gym';
import { UserRole } from '@gym-particles/types/User';

export const Content = () => {
  const { t } = useTranslation();
  const userId = useAppSelector((state) => state.user.userId);
  const [activeIndex, setActiveIndex] = useState(0);
  const { chainId } = useParams<{ chainId: string }>();
  const [isBacked, setIsBacked] = useState(false);
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((state) => state.user.userRole);

  const emptyValues: GymSchedulesFormInput = {
    type: '',
    name: '',
    validFromDate: undefined,
    validToDate: undefined,
    status: '',
    createdBy: userId,
    lastModifiedBy: userId
  };

  const setActiveIndexWrapper = (e: number): void => {
    if (e < activeIndex) {
      setIsBacked(true);
    } else {
      setIsBacked(false);
    }
    setActiveIndex(e);
  };

  const [scheduleId, setScheduleId] = useState<number>(0);

  // To-Do: Add enums for type.
  const [scheduleType, setScheduleType] = useState<string>('opening hour schedule');

  const [scheduleForm, setScheduleForm] = useState(emptyValues);
  const [gymZoneMappings, setGymZoneMappings] = useState<GymSchedulesGymZoneMapping[]>([]);
  const [gymAccessPointMappings, setGymAccessPointMappings] = useState<
    GymSchedulesAccessPointMapping[]
  >([]);
  const [scheduleStatuses, setScheduleStatuses] = useState<Array<GymScheduleStatus>>([]);
  const [scheduleStatusId, setScheduleStatusId] = useState<number>(-1);

  const [schedules, setSchedules] = useState<Array<ScheduleEvent>>([]);

  useEffect(() => {
    setScheduleStatusId(
      scheduleStatuses.find((a) => a.name === ScheduleInternalStatus.Inactive)?.id || -1
    );
  }, [scheduleStatuses]);

  // State for maintaining selected records from List Boxes
  const [selectedGyms, setSelectedGyms] = useState<Array<GymType>>([]);
  const [selectedZones, setSelectedZones] = useState<GymScheduleZoneMapping[]>([]);
  const [selectedAccessCategory, setSelectedAccessCategory] = useState<
    GymScheduleAccessCategoryMapping[]
  >([]);
  const [selectedAccessPoint, setSelectedAccessPoint] = useState<GymScheduleAccessPointMapping[]>(
    []
  );

  const getScheduleStatuses = async () => {
    try {
      const response = (API.graphql({
        query: getAllScheduleStatuses
      }) as Promise<{
        data: { getAllScheduleStatuses: [ScheduleStatuses] };
      }>).then((e) => {
        const scheduleStatusItems: GymScheduleStatus[] = e.data.getAllScheduleStatuses.map(
          (data) => {
            return { name: data.name || '', id: data.id || 0 };
          }
        );
        setScheduleStatuses(scheduleStatusItems);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getScheduleStatuses();
  }, []);

  const scheduleStatusName = scheduleStatuses.find((a) => a.id === scheduleStatusId)?.name || '';

  const gyms = useAppSelector(
    (state) => state.gyms.items.find((g) => g.chainId === +chainId)?.items
  );

  const getAccessPoints = async () => {
    const response = await (API.graphql({
      query: getAccessPointsForSite,
      variables: {
        siteId: chainId
      }
    }) as Promise<{
      data: { getAccessPointsForSite: [SiteAccessPoint] };
    }>);

    const mappedResponse: GymSchedulesAccessPointMapping[] = [];

    response.data.getAccessPointsForSite.forEach((item) => {
      mappedResponse.push({
        locationAccessPointId: item.locationAccessPointId || -1,
        siteId: item.siteId || -1,
        siteLocationId: item.siteLocationId || -1,
        locationZoneId: item.locationZoneId || -1,
        locationZoneName: item.locationZoneName || '',
        zoneAccessPointId: item.zoneAccessPointId || -1,
        doorARXId: item.doorARXId || '',
        doorName: item.doorName || '',
        status: item.status || ''
      });
    });

    setGymAccessPointMappings(mappedResponse);
  };

  const getZoneMappings = async () => {
    const response = await (API.graphql({
      query: getZoneAccessCategoryMappingForGymSchedules,
      variables: {
        siteId: chainId
      }
    }) as Promise<{
      data: { getZoneAccessCategoryMappingForGymSchedules: [ZoneAccessCategoryMapping] };
    }>);

    const mappedResponse: GymSchedulesGymZoneMapping[] = [];

    response.data.getZoneAccessCategoryMappingForGymSchedules.forEach((item) => {
      mappedResponse.push({
        locationAccessCategoryId: item.locationAccessCategoryId || -1,
        locationAccessCategoryName: item.locationAccessCategoryName || '',
        locationAccessCategoryZoneId: item.locationAccessCategoryZoneId || -1,
        locationArxAccessCategoryId: item.locationArxAccessCategoryId || '',
        locationName: item.locationName || '',
        locationZoneId: item.locationZoneId || -1,
        membershipId: item.membershipId || -1,
        membershipName: item.membershipName || '',
        siteId: item.siteId || -1,
        siteLocationId: item.siteLocationId || -1,
        siteLocationMembershipId: item.siteLocationMembershipId || -1,
        zoneDescription: item.zoneDescription || '',
        zoneName: item.zoneName || ''
      });
    });

    setGymZoneMappings(mappedResponse);
  };

  useEffect(() => {
    dispatch(
      fetchGyms({
        id: +chainId,
        pagination: {
          offset: 0,
          pageSize: 0,
          sortField: 'siteId',
          sortOrder: 1,
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        }
      })
    );

    getZoneMappings();
    getAccessPoints();
  }, []);

  const stepperItems: MenuItem[] = [
    {
      label: t('ADD_NEW_GYM_SCHEDULES.STEPPER.GENERAL_DETAILS')
    },
    {
      label: t('ADD_NEW_GYM_SCHEDULES.STEPPER.GYM_ZONE_MAPPING')
    },
    {
      label: t('ADD_NEW_GYM_SCHEDULES.STEPPER.OPENING_HOURS')
    }
  ];

  return (
    <Card className="pt10 pl40 pr40 pb30">
      <div className="d-flex align-items-center">
        <Text bold={true} size={TextSizes.large} className="text-2xl">
          {t('ADD_NEW_GYM_SCHEDULES.PAGE_TITLE')}
        </Text>
        <div className="pl20 ">
          {scheduleStatuses.length > 0 && (
            <Status
              value={scheduleStatusName.charAt(0).toUpperCase() + scheduleStatusName.slice(1)}
              variant={
                scheduleStatusName === ScheduleInternalStatus.Inactive
                  ? StatusVariants.red
                  : scheduleStatusName === ScheduleInternalStatus.PartiallySaved
                  ? StatusVariants.yellow
                  : scheduleStatusName === ScheduleInternalStatus.Active
                  ? StatusVariants.green
                  : StatusVariants.blue
              }
            />
          )}
        </div>
      </div>

      <div className="pt50">
        <Stepper
          items={stepperItems}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndexWrapper}
          readonly={true}
        />
      </div>
      {activeIndex === 0 ? (
        <GymSchedulesForm
          type={'new'}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndexWrapper}
          setScheduleForm={setScheduleForm}
          gymSchedules={scheduleForm}
          setScheduleId={setScheduleId}
          scheduleId={scheduleId}
          setScheduleType={setScheduleType}
          isBacked={isBacked}
          scheduleStatuses={scheduleStatuses}
          setScheduleStatusId={setScheduleStatusId}
          scheduleStatusId={scheduleStatusId}
        />
      ) : activeIndex == 1 ? (
        <div>
          <GymZoneMapping
            type={'new'}
            gymChainId={+chainId}
            isDoorSchedule={scheduleForm.type === 'door unlock schedule'}
            gyms={gyms || []}
            gymZones={gymZoneMappings || []}
            gymAccessPoints={gymAccessPointMappings || []}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndexWrapper}
            scheduleId={scheduleId}
            scheduleType={scheduleType}
            isBacked={isBacked}
            selectedGyms={selectedGyms}
            setSelectedGyms={setSelectedGyms}
            selectedZones={selectedZones}
            setSelectedZones={setSelectedZones}
            selectedAccessCategory={selectedAccessCategory}
            setSelectedAccessCategory={setSelectedAccessCategory}
            selectedAccessPoint={selectedAccessPoint}
            setSelectedAccessPoint={setSelectedAccessPoint}
          />
        </div>
      ) : (
        <OpeningHoursScheduler
          type={'new'}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndexWrapper}
          scheduleId={scheduleId}
          schedules={schedules}
          setSchedules={setSchedules}
          scheduleStatuses={scheduleStatuses}
          setScheduleStatusId={setScheduleStatusId}
          scheduleStatusId={scheduleStatusId}
          isDoorSchedule={scheduleForm.type === 'door unlock schedule'}
          scheduleDate={scheduleForm.validFromDate}
        />
      )}
    </Card>
  );
};

const AddNewGymSchedulePage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { chainId } = useParams<{ chainId: string }>();

  const dispatch = useAppDispatch();

  const gymChainName = useAppSelector((state) => state.gymChain.currentGymChain?.name);

  let fetchFailed = false;
  if (gymChainName == undefined) {
    fetchFailed = true;
  }

  useEffect(() => {
    dispatch(getGymChainById(+chainId));
  }, []);

  useEffect(() => {
    if (fetchFailed) {
      history.push(`/gymChains/${chainId}/gymSchedules`);
    }
  }, [fetchFailed]);

  return (
    <TwoColTemplate
      title={(gymChainName as string) || t('COMMON.LOADING')}
      gymChainId={+chainId}
      col1={<Content />}
      alertRef={alertRef}
      backButtonHandler={() => history.push(`/gymChains/${chainId}/gymSchedules`)}
    />
  );
};

export default AddNewGymSchedulePage;
