import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { ScheduleRequest as typeOfScheduleRequests } from '@gym-particles/types/ScheduleRequest';
import { getScheduleRequests } from '@gym-graphql/queries';
import { ScheduleRequest } from '@gym-src/API';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  items: Array<typeOfScheduleRequests>;
  totalRecords: number;
  isScheduleRequestsLoading: boolean;
} = {
  items: [],
  totalRecords: 0,
  isScheduleRequestsLoading: false
};

export const fetchScheduleRequests = createAsyncThunk('/gym/fetchScheduleRequests', async () => {
  const response = await (API.graphql({
    query: getScheduleRequests
  }) as Promise<{
    data: {
      getScheduleRequests: {
        items: ScheduleRequest[];
        totalRecords: { totalRecords: number };
      };
    };
  }>);
  return response;
});

export const ScheduleRequestsSlice = createSlice({
  name: 'scheduleRequests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchScheduleRequests.fulfilled, (state, action) => {
      state.isScheduleRequestsLoading = false;
      if (action.payload.data.getScheduleRequests.items === null) {
        return;
      }
      state.items = action.payload.data.getScheduleRequests.items.map(
        (e: ScheduleRequest): typeOfScheduleRequests => {
          return {
            gymName: e.gymName || '',
            scheduleId: e.scheduleId || 0,
            scheduleName: e.scheduleName || '',
            gymChainName: e.gymChainName || '',
            status: e.status || '',
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            weekdayId: e.weekdayId || 0,
            startTime: e.startTime || '',
            endTime: e.endTime || '',
            gymChainId: e.gymChainId || 0,
            weekdayName: e.weekdayName || '',
            gymId: e.gymId || 0
          };
        }
      );

      state.totalRecords = action.payload.data.getScheduleRequests.totalRecords.totalRecords;
      return state;
    });

    builder.addCase(fetchScheduleRequests.pending, (state, action) => {
      state.isScheduleRequestsLoading = true;
      return state;
    });

    builder.addCase(fetchScheduleRequests.rejected, (state, action) => {
      state.isScheduleRequestsLoading = false;
      console.log('getAccounts', action);
      return state;
    });
  }
});

export default ScheduleRequestsSlice.reducer;
