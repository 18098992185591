import { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertType } from '@gym-atoms/Alert/Alert';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { useTranslation } from 'react-i18next';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { useAppSelector, useAppDispatch } from '@gym-redux/store';
import Loader from '@gym-atoms/Loader/Loader';
import { Account } from '@gym-particles/types/Account';
import { fetchAccounts } from '@gym-redux/slices/accountsSlice';
import AccountDetailsModal from '@gym-organisms/AccountDetailsModal/AccountDetailsModal';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Text from '@gym-atoms/Text/Text';
import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import { switchMobileApp } from '@gym-graphql/mutations';
import { API } from 'aws-amplify';
import { UserRole } from '@gym-particles/types/User';

const MainColumn = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userId = useAppSelector((state) => state.user.userId);
  const [selectedRow, setSelectedRow] = useState<Account>();
  const [sortField, setSortField] = useState('createdDate');
  const [sortOrder, setSortOrder] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [accountDetailsModalVisible, setAccountDetailsModalVisible] = useState(false);
  const isAccountsLoading = useAppSelector((state) => state.account.isAccountLoading);
  const mobileUserAccountList = useAppSelector((state) => state.account.items);
  const totalMobileUserCount = useAppSelector((state) => state.account.totalRecords);
  const [switchMobileAppDialogVisible, setSwitchMobileAppDialogVisible] = useState(false);
  const isMobileAppDisabled = selectedRow?.accessTypes.isMobileAppDisabled;
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);

  const fetchAccountsFromDb = () => {
    dispatch(
      fetchAccounts({
        offset: searchTerm !== '' ? 0 : offset,
        pageSize: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        search: { searchField: '', searchText: searchTerm },
        userId: userId
      })
    );
  };

  useEffect(() => {
    fetchAccountsFromDb();
  }, [sortField, sortOrder, offset, pageSize, searchTerm]);

  const detailsModalOnHide = () => {
    fetchAccountsFromDb();
    setAccountDetailsModalVisible(!accountDetailsModalVisible);
  };

  const accessTypeBodyTemplate = (row: Account) => {
    return (
      <div className="d-flex">
        {!row.accessTypes.isMobileAppDisabled && (
          <span
            title={t('MOBILE_USER_ACCOUNTS.MOBILE_ACCESS_TOOLTIP_TEXT')}
            className="modal-icon icon-mobile me-2"></span>
        )}
        {/* Will enable below icons later if needed  */}
        {/* {!row.accessTypes.isAccessCardDeactivated && (
          <span className="modal-icon icon-signal me-2"></span>
        )} */}
        {/* {!row.accessTypes.isJustTapRevoked && (
          <span
            title={t('MOBILE_USER_ACCOUNTS.JUST_TAP_ACCESS_TOOLTIP_TEXT')}
            className="modal-icon icon-credentials"></span>
        )} */}
      </div>
    );
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t('MOBILE_USER_ACCOUNTS.FORM_FAILURE_DIALOG_HEADER')}
          dialogFooter={
            <Button
              label={t('MOBILE_USER_ACCOUNTS.FORM_FAILURE_DIALOG_BUTTON')}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t('MOBILE_USER_ACCOUNTS.FORM_FAILURE_DIALOG_BODY')}</Text>}
        />
      </div>
    );
  };

  const SwitchMobileAppFromDb = (isDisable: boolean) => {
    return API.graphql({
      query: switchMobileApp,
      variables: {
        id: selectedRow?.id,
        isDisable: isDisable,
        updatedBy: userId
      }
    });
  };

  const SwitchMobileAppHandler = async () => {
    try {
      isMobileAppDisabled ? await SwitchMobileAppFromDb(false) : await SwitchMobileAppFromDb(true);
      fetchAccountsFromDb();
      setSwitchMobileAppDialogVisible(false);
    } catch (error) {
      setSwitchMobileAppDialogVisible(false);
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const SwitchMobileAppDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.CANCEL')}
          variant={ButtonVariants.textonly}
          onClick={() => setSwitchMobileAppDialogVisible(false)}
        />
        <Button
          label={t(isMobileAppDisabled ? 'COMMON.ACTIVATE' : 'COMMON.DEACTIVATE')}
          variant={ButtonVariants.danger}
          onClick={SwitchMobileAppHandler}
        />
      </div>
    );
  };

  const userRole = useAppSelector((state) => state.user.userRole);

  const tableProps: TableProps<Account> = {
    onRowClick: () => setAccountDetailsModalVisible(true),
    enableRowClick: true,
    exportFileName: t('MOBILE_USER_ACCOUNTS.EXPORT_EXCEL_FILE_NAME'),
    setPageSize: setPageSize,
    setOffset: setOffset,
    pageSize: pageSize,
    offset: offset,
    setSortField: setSortField,
    setSortOrder: setSortOrder,
    sortField: sortField,
    sortOrder: sortOrder,
    totalRecords: totalMobileUserCount,
    data: mobileUserAccountList,
    searchTerm: searchTerm,
    setSearchTerm: setSearchTerm,
    columns: [
      {
        field: 'name',
        header: t('MOBILE_USER_ACCOUNTS.TABLE_HEADER_NAME'),
        toggable: false,
        sortable: true,
        imageField: 'imageUrl'
      },
      {
        field: 'accessTypes',
        header: t('MEMBER_LIST.TABLE.HEADER_MEMBER_TYPE_OF_ACCESS'),
        toggable: true,
        truncateText: true,
        bodyTemplate: (data) => accessTypeBodyTemplate(data)
      },
      {
        field: 'email',
        header: t('MOBILE_USER_ACCOUNTS.TABLE_HEADER_EMAIL'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'phone',
        header: t('MOBILE_USER_ACCOUNTS.TABLE_HEADER_PHONE'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'displayStatus',
        header: t('MOBILE_USER_ACCOUNTS.TABLE_HEADER_STATUS'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'createdDate',
        header: t('MOBILE_USER_ACCOUNTS.TABLE_HEADER_SIGNED_UP_ON_GYMXS'),
        toggable: true,
        sortable: true,
        truncateText: true
      },
      {
        field: 'memberId',
        header: t('MOBILE_USER_ACCOUNTS.TABLE_HEADER_IS_MEMBER'),
        toggable: true,
        sortable: true,
        truncateText: true
      }
    ],
    searchPlaceholderText: t('MOBILE_USER_ACCOUNTS.SEARCH_PLACEHOLDER'),
    excelBtntext: t('MOBILE_USER_ACCOUNTS.EXPORT_EXCEL_BUTTON_LABEL'),
    selectedRow: selectedRow,
    setSelectedRow: setSelectedRow,
    isContextMenu: userRole === UserRole.GYM_USER ? true : false,
    menuItem:
      userRole === UserRole.GYM_USER
        ? [
            {
              label: t(
                isMobileAppDisabled
                  ? 'MOBILE_USER_ACCOUNTS.CONTEXT_MENU.ACTIVATE_MOBILE_APP'
                  : 'MOBILE_USER_ACCOUNTS.CONTEXT_MENU.DEACTIVATE_MOBILE_APP'
              ),
              command: () => setSwitchMobileAppDialogVisible(true)
            }
          ]
        : []
  };

  return (
    <div>
      <DialogBox
        dialogVisible={switchMobileAppDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setSwitchMobileAppDialogVisible(false)}
        dialogFooter={<SwitchMobileAppDialogFooter />}
        dialogClosable={false}
        dialogHeader={t(isMobileAppDisabled ? 'COMMON.ACTIVATE' : 'COMMON.DEACTIVATE')}
        content={
          <Text>
            {t(
              isMobileAppDisabled
                ? 'MOBILE_USER_ACCOUNTS.ACTIVATE_MODAL_CONFIRM'
                : 'MOBILE_USER_ACCOUNTS.DEACTIVATE_MODAL_CONFIRM'
            )}{' '}
            <span className="fw-bold">{selectedRow?.name}</span> ?
          </Text>
        }
      />
      {isAccountsLoading ? (
        <Loader shape="table" />
      ) : (
        <>
          {mobileUserAccountList && <Table {...tableProps} />}
          {selectedRow && (
            <AccountDetailsModal
              account={selectedRow}
              visible={accountDetailsModalVisible}
              modalOnHide={detailsModalOnHide}
            />
          )}
        </>
      )}
      <ActionFailedDialog />
    </div>
  );
};

const AccountsPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  return (
    <TwoColTemplate
      title={t('MOBILE_USER_ACCOUNTS.PAGE_TITLE')}
      col1={<MainColumn />}
      alertRef={alertRef}
      backButtonHandler={() => history.push('/dashboard')}
    />
  );
};

export default AccountsPage;
