import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertType } from '@gym-atoms/Alert/Alert';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { Card } from 'primereact/card';
import ScheduleRequestsList from '@gym-organisms/ScheduleRequests/ScheduleRequestsList';

const ScheduleRequestPage = () => {
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <TwoColTemplate
        backButtonHandler={() => history.push('/dashboard')}
        col1={
          <div>
            <Card className="p-card-w-table">
              <ScheduleRequestsList />
            </Card>
          </div>
        }
        title={t('DAY_SCHEDULE_REQUESTS.PAGE_TITLE')}
        alertRef={alertRef}
      />
    </>
  );
};

export default ScheduleRequestPage;
