import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import TabView, { OnTabChangeEvent } from '@gym-atoms/TabView/TabView';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { GymDetailsHeaderTabNames, GymDetailsHeaderRoutes } from '@gym-particles/types/GymDetails';
import MembersPage from '@gym-pages/MembersPage/MembersListPage';
import ZonesPage from '@gym-pages/ZonesPage/ZonesPage';
import AccessPointsPage from '@gym-pages/AccessPointsPage/AccessPointsPage';
import GymDashboardPage from '@gym-pages/GymDashboardPage/GymDashboardPage';
import GymAccessCategoryMappingPage from '@gym-pages/GymAccessCategoryMappingPage/GymAccessCategoryMappingPage';
import { useAppSelector } from '@gym-redux/store';
import { UserRole } from '@gym-particles/types/User';

const GymHeader = ({ currentGymId, currentGymChainId, currentTab }: GymHeaderProps) => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const userRole = useAppSelector((state) => state.user.userRole);

  const [activeTab, setActiveTab] = useState(GymDetailsHeaderRoutes[currentTab]);

  const tabViewItems = [
    { header: t('GYM_HEADER.DASHBOARD'), content: <GymDashboardPage /> },
    { header: t('GYM_HEADER.MEMBERS'), content: <MembersPage /> },
    {
      header: t('GYM_HEADER.MEMBERSHIPS'),
      content: <GymAccessCategoryMappingPage alertRef={alertRef} />
    },
    { header: t('GYM_HEADER.ZONES'), content: <ZonesPage alertRef={alertRef} /> },
    { header: t('GYM_HEADER.ACCESS_POINTS'), content: <AccessPointsPage alertRef={alertRef} /> }
  ];

  const gymUserTabViewItems = [
    { header: t('GYM_HEADER.DASHBOARD'), content: <GymDashboardPage /> }
  ];

  const routes = [
    `/gymChains/${currentGymChainId}/gyms/${currentGymId}/dashboard`,
    `/gymChains/${currentGymChainId}/gyms/${currentGymId}/members`,
    `/gymChains/${currentGymChainId}/gyms/${currentGymId}/memberships`,
    `/gymChains/${currentGymChainId}/gyms/${currentGymId}/zones`,
    `/gymChains/${currentGymChainId}/gyms/${currentGymId}/accesspoints`
  ];

  const onTabChangeHandler = (e: OnTabChangeEvent) => {
    setActiveTab(e.index);
    history.push(routes[e.index]);
  };

  return (
    <div>
      {userRole !== UserRole.GYM_USER || currentGymChainId === 4 ? (
        <div className="tab-view-align-top">
          <TabView items={tabViewItems} activeTab={activeTab} onTabChange={onTabChangeHandler} />
        </div>
      ) : (
        <div className="tab-view-align-top">
          <TabView
            items={gymUserTabViewItems}
            activeTab={activeTab}
            onTabChange={onTabChangeHandler}
          />
        </div>
      )}
    </div>
  );
};

export interface GymHeaderProps {
  currentGymId: number;
  currentGymChainId: number;
  currentTab: GymDetailsHeaderTabNames;
}

export default GymHeader;
