import { getPlatformSettings } from '@gym-graphql/queries';
import { PlatformSetting as typeOfPlatfromSetting } from '@gym-particles/types/PlatformSetting';
import { PlatformSetting } from '@gym-src/API';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

const initialState: Array<typeOfPlatfromSetting> = [];

export const fetchPlatformSettings = createAsyncThunk(
  '/platformSetting/getPlatformSettings',
  async () => {
    const response = await (API.graphql({
      query: getPlatformSettings
    }) as Promise<{
      data: { getPlatformSettings: PlatformSetting[] };
    }>);
    return response;
  }
);

export const platformSettingSlice = createSlice({
  name: 'platformSetting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlatformSettings.fulfilled, (state, action) => {
      if (action.payload.data.getPlatformSettings === null) {
        return;
      }
      state = action.payload.data.getPlatformSettings.map(
        (e: PlatformSetting): typeOfPlatfromSetting => {
          return {
            id: e.id || 0,
            setting: e.setting || '',
            value: e.value || ''
          };
        }
      );
      return state;
    });
  }
});

export default platformSettingSlice.reducer;
