import { useTranslation } from 'react-i18next';
import PricingRelationshipTemplate from '@gym-templates/PricingRelationship/PricingRelationshipTemplate';
import { PricingRelationshipTable } from '@gym-particles/types/PricingRelationships';

const PricingRelationshipForm = (props: PricingRelationshipTemplateProps) => {
  const { t } = useTranslation();

  return (
    <PricingRelationshipTemplate
      type={props.type}
      heading={t(`${props.type === 'new' ? 'ADD_NEW' : 'MODIFY'}_PRICING_RELATIONSHIP.PAGE_TITLE`)}
      programRelationship={props.programData}
      templateType={props.templateType}
    />
  );
};

export default PricingRelationshipForm;

type PricingRelationshipTemplateType = 'new' | 'modify';

export interface PricingRelationshipTemplateProps {
  type: PricingRelationshipTemplateType;
  programData?: PricingRelationshipTable;
  templateType?: (e: boolean) => void;
}
