export type Activity = {
  memberEventId: number;
  memberId: number;
  memberEmail: string;
  eventId: number;
  accessCardUID?: string;
  eventDescription: string;
  eventType: string;
  stageId: number;
  stage: string;
  createdTime: string;
  isAutoAssigned: boolean;
  memberEventStatusTypeId: number;
  memberEventStatusType: string;
  firstName: string;
  lastName: string;
  siteLocationId: number;
  locationName: string;
  lastModifiedBy: number;
  lastModifiedDate: string;
  activityDate: string;
  assignedUserId?: number;
  assignmentHistoryId?: number;
  inquiryDescription?: string;
  memberLocationId: number;
  informationReceivedEvent: boolean;
  language?: string;
  userId?: number;
  en?: string;
  nor?: string;
  nl?: string;
};

export type ActivityLog = {
  activityDate: string;
  activityTime: string;
  type: string;
  activity: string;
};

export type ActivityLogGroup = {
  date: string;
  activities: Array<ActivityLogGroupEntry>;
};

export type ActivityLogGroupEntry = {
  type: string;
  activitystatus: number;
  time: string;
  memberEventId: number;
  accessCardUID?: string;
  assignmentHistoryId?: number;
  inquiryDescription?: string;
  firstName: string;
  lastName: string;
  memberEmail: string;
  eventDescription: string;
  locationName: string;
  activity: string;
  assignedUserId: number;
  memberId: number;
  siteLocationId: number;
  memberLocationId: number;
  informationReceivedEvent: boolean;
  activityCreatedDate: string;
  language: string;
  userId?: number;
  en?: string;
  nor?: string;
  nl?: string;
};

export enum ActivityEventTypes {
  ERRORS = 'Errors',
  VISITS = 'Visit Activity',
  EVENTS = 'Events',
  INQUIRIES = 'Inquiry'
}
