import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { LocationZone } from '@gym-src/API';
import { Zone } from '@gym-particles/types/models';
import { getZonesForSiteLocation } from '@gym-graphql/queries';
import { removeZoneForSiteLocation } from '@gym-graphql/mutations';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isZonesLoading: boolean;
  items: Array<{
    siteLocationId: number;
    items: Zone[];
    totalRecords: number;
  }>;
} = {
  isZonesLoading: false,
  items: []
};

type paramType = {
  siteLocationId: number;
};
export type deleteParamType = {
  id?: number;
  userId: number;
};

export const fetchZones = createAsyncThunk('/gym/zones', async (params: paramType) => {
  const response = await (API.graphql({
    query: getZonesForSiteLocation,
    variables: {
      siteLocationId: params.siteLocationId
    }
  }) as Promise<{
    data: {
      getZonesForSiteLocation: {
        items: LocationZone[];
        totalRecords: { totalRecords: number };
      };
    };
  }>);
  return response;
});

export const deleteZone = createAsyncThunk('Zone/deleteZone', async (params: deleteParamType) => {
  const response = await (API.graphql({
    query: removeZoneForSiteLocation,
    variables: {
      id: params.id,
      userId: params.userId
    }
  }) as Promise<{
    data: {
      removeZoneForSiteLocation: number;
    };
  }>);
  return response;
});

export const ZonesSlice = createSlice({
  name: 'Zones',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchZones.fulfilled, (state, action) => {
      state.isZonesLoading = false;
      if (!action.payload.data.getZonesForSiteLocation.items) {
        return;
      }

      const items = action.payload.data.getZonesForSiteLocation.items.map(
        (e: LocationZone): Zone => {
          return {
            id: e.id || 0,
            siteLocationId: e.siteLocationId || 0,
            zoneName: e.zoneName || '',
            zoneDescription: e.zoneDescription || '',
            arxReferenceId: e.arxReferenceId || '',
            createdBy: e.createdBy || 0,
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate
              ? dayjs(e.lastModifiedDate || '').format('DD MMM YYYY')
              : '',
            status: e.status || '',
            displayStatus: e.status ? e.status.charAt(0).toUpperCase() + e.status.slice(1) : '',
            locationAccessCategoryZoneId: e.locationAccessCategoryZoneId || -1,
            locationAccessCategoryId: e.locationAccessCategoryId || -1,
            locationAccessCategoryName: e.locationAccessCategoryName || '',
            locationAccessCategoryGender: e.locationAccessCategoryGender || '',
            locationAccessCategoryArxRefId: e.locationAccessCategoryArxRefId || ''
          };
        }
      );
      const totalRecords = action.payload.data.getZonesForSiteLocation.totalRecords.totalRecords;

      const fetchedZonesInfo = {
        totalRecords: totalRecords,
        items: items,
        siteLocationId: action.meta.arg.siteLocationId
      };
      if (state.items.length === 0) {
        state.items.push(fetchedZonesInfo);
      } else {
        // Check if we are updating an already fetched or fetching new one
        const update = state.items.find(
          (ZonesInfo) => ZonesInfo.siteLocationId === fetchedZonesInfo.siteLocationId
        );
        if (update) {
          const newState = state.items.map((ZonesInfo) => {
            if (ZonesInfo.siteLocationId === fetchedZonesInfo.siteLocationId) {
              return fetchedZonesInfo;
            } else {
              return ZonesInfo;
            }
          });
          Object.assign(state.items, newState);
        } else {
          state.items.push(fetchedZonesInfo);
        }
      }
    });

    builder.addCase(fetchZones.pending, (state, action) => {
      state.isZonesLoading = true;
      return state;
    });

    builder.addCase(fetchZones.rejected, (state, action) => {
      state.isZonesLoading = false;
      console.log('Fetch Zones Error');
      // TO-Do: Add rejected flow here
      return state;
    });

    builder.addCase(deleteZone.fulfilled, (state, action) => {
      state.isZonesLoading = false;
      const zoneId = action.meta.arg.id;
      const filtered = state.items.map((zoneObj) => {
        const filtereZones = zoneObj.items.filter((zone) => zone.id !== zoneId);
        zoneObj.items = filtereZones;
        zoneObj.totalRecords = zoneObj.totalRecords - 1;
        return zoneObj;
      });
      state.items = filtered;
    });

    builder.addCase(deleteZone.pending, (state, action) => {
      state.isZonesLoading = true;
      return state;
    });

    builder.addCase(deleteZone.rejected, (state, action) => {
      state.isZonesLoading = false;
      console.error('deleteZones', action);
      return state;
    });
  }
});

export default ZonesSlice.reducer;
