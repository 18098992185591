import DateTimePicker, {
  DateTimePickerChangeEvent,
  DateTimePickerSizes
} from '@gym-atoms/DateTimePicker/DateTimePicker';
import Dropdown, {
  DropdownChangeEvent,
  DropdownSizes,
  SelectItem
} from '@gym-atoms/Dropdown/Dropdown';
import Pill, { PillSizes, PillVariants } from '@gym-atoms/Pill/Pill';
import i18n from '@gym-particles/i18next';

const ActivityFeedFilters = (props: ActivityFeedFilterProps) => {
  return (
    <div className="row">
      <div className="col-auto pl0 pr0">
        <Pill
          label={i18n.t('HOME_PAGE.ACTIVITY_TYPES.ALL_ACTIVITY')}
          variant={PillVariants.primary}
          checked={props.allActivity}
          onChange={props.allActivityOnChange}
          size={PillSizes.small}
        />
      </div>
      <div className="col-auto pl0 pr0">
        <Pill
          label={i18n.t('HOME_PAGE.ACTIVITY_TYPES.ERROR_ACTIVITY')}
          variant={PillVariants.error}
          checked={props.errorActivity}
          onChange={props.errorActivityOnChange}
          size={PillSizes.small}
        />
      </div>
      <div className="col-auto pl0 pr0">
        <Pill
          label={i18n.t('HOME_PAGE.ACTIVITY_TYPES.ACCESS_ACTIVITY')}
          variant={PillVariants.success}
          checked={props.accessActivity}
          onChange={props.accessActivityOnChange}
          size={PillSizes.small}
        />
      </div>

      <div className="col-auto mb10 pl0 pr5">
        <Dropdown
          placeholder="All stages"
          options={props.dropdownOptions}
          onChange={props.dropdownOnChange}
          value={props.selectedValue}
          size={DropdownSizes.small}
        />
      </div>
      <div className="col-auto pl0 pr5">
        <div className="d-flex">
          <div className="col-auto pl0 pr5">
            <DateTimePicker
              variant="date"
              name="startDate"
              placeholder={'Date from'}
              showIcon={false}
              value={props.dateFrom}
              onChange={props.dateFromOnChange}
              minDateDisable={true}
              size={DateTimePickerSizes.small}></DateTimePicker>
          </div>
          <div className="col-auto pl0 pr5">
            <DateTimePicker
              variant="date"
              name="endDate"
              placeholder={'Date To'}
              showIcon={false}
              value={props.dateTo}
              onChange={props.dateToOnChange}
              minDateDisable={true}
              size={DateTimePickerSizes.small}></DateTimePicker>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface ActivityFeedFilterProps {
  allActivity: boolean;
  allActivityOnChange?: () => void;
  errorActivity: boolean;
  errorActivityOnChange?: () => void;
  accessActivity: boolean;
  accessActivityOnChange?: () => void;
  dateFrom?: Date;
  dateFromOnChange?: (e: DateTimePickerChangeEvent) => void;
  dateTo?: Date;
  dateToOnChange?: (e: DateTimePickerChangeEvent) => void;
  dropdownOptions: Array<Partial<SelectItem>> | Array<string>;
  selectedValue: string | string[] | number | null;
  dropdownOnChange?: (e: DropdownChangeEvent) => void;
}

export default ActivityFeedFilters;
