import styles from './Status.module.scss';

export enum StatusVariants {
  green = 'green-variant',
  yellow = 'yellow-variant',
  red = 'red-variant',
  blue = 'blue-variant'
}

const Status = ({ value, variant = StatusVariants.green, bold = true, ...props }: StatusProps) => {
  const boldStyle = bold ? styles.textBold : styles.textNormal;

  const finalProps = {
    className: `${boldStyle} ${styles.status} ${styles[variant]} w-25`,
    ...props
  };

  return (
    <div {...finalProps}>
      <p>{value}</p>
    </div>
  );
};

export default Status;

export interface StatusProps {
  /** Value to display in the status */
  value: string;
  /* Bold by default. set false for normal */
  bold?: boolean;
  /* Add more colors in Status Variant*/
  variant?: StatusVariants;
}
