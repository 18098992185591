import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Input from '@gym-atoms/Input/Input';
import Text from '@gym-atoms/Text/Text';
import { openArxDoorDashboard, openSodvinDoorAnonymous } from '@gym-graphql/queries';
import { AccessPoints } from '@gym-particles/types/AccessPoints';
import { useAppSelector } from '@gym-redux/store';
import {
  OpenArxDoorDashboardQuery,
  OpenArxDoorDashboardQueryVariables,
  OpenSodvinDoorAnonymousQuery,
  OpenSodvinDoorAnonymousQueryVariables
} from '@gym-src/API';
import { API } from 'aws-amplify';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const DoorOpenModal = ({ visible, door, onHideCallBack, toastRef }: DoorOpenModalProps) => {
  const { t } = useTranslation();
  const { gymId, chainId } = useParams<{ gymId: string; chainId: string }>();
  const currentUser = useAppSelector((state) => state.user);
  const [comment, setcomment] = useState('');
  const [isOpening, setisOpening] = useState<boolean>(false);
  const [errorMsg, seterrorMsg] = useState(false);

  const providerId = useAppSelector((state) => {
    return state.gymChain.items.find((chain) => chain.id === +chainId)?.providerId;
  });

  const openDoor = async () => {
    setisOpening(true);
    seterrorMsg(false);

    if (providerId == 1) {
      await openArxDoors();
    }
    if (providerId == 2) {
      await openSodvinDoors();
    }
    setisOpening(false);
  };

  const openArxDoors = async () => {
    const requestData: OpenArxDoorDashboardQueryVariables = {
      doorId: door?.doorARXId || '',
      userId: currentUser.userId,
      siteLocationId: +gymId,
      comment: comment
    };

    try {
      const response = await (API.graphql({
        query: openArxDoorDashboard,
        variables: requestData
      }) as Promise<{
        data: OpenArxDoorDashboardQuery;
      }>);

      const responseData = response.data.openArxDoorDashboard;
      if (
        responseData == null ||
        !responseData ||
        responseData.error !== null ||
        responseData.error
      ) {
        seterrorMsg(true);
      } else {
        hideModal();
        seterrorMsg(false);
        toastRef.current.show({
          severity: 'success',
          summary: `${door?.doorName || ''} ${t('DOOR_OPEN_MODAL.ALERT_SUCCESS_MESSAGE')} `,
          life: 3000
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const openSodvinDoors = async () => {
    const requestData: OpenSodvinDoorAnonymousQueryVariables = {
      name: currentUser.userName,
      sodvinDoorId: door?.sodvinDoorId,
      sodvinDoorSecret: door?.sodvinDoorSecret,
      userId: currentUser.userId,
      siteLocationId: +gymId,
      comment: comment
    };

    try {
      const response = await (API.graphql({
        query: openSodvinDoorAnonymous,
        variables: requestData
      }) as Promise<{
        data: OpenSodvinDoorAnonymousQuery;
      }>);

      const responseData = response.data.openSodvinDoorAnonymous;

      if (
        responseData == null ||
        !responseData ||
        responseData.error !== null ||
        responseData.error
      ) {
        seterrorMsg(true);
      } else {
        hideModal();
        seterrorMsg(false);
        toastRef.current.show({
          severity: 'success',
          summary: `${door?.doorName || ''} ${t('DOOR_OPEN_MODAL.ALERT_SUCCESS_MESSAGE')} `,
          life: 3000
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onCommentChange = (e: FormEvent<HTMLTextAreaElement>) => {
    // prevent user from entering only spaces as the comment.
    if (e.currentTarget.value.trim() === '') {
      setcomment('');
    } else {
      setcomment(e.currentTarget.value);
    }
  };

  const dialogBoxContent = (
    <>
      <Input
        variant={'textarea'}
        label={t('DOOR_OPEN_MODAL.COMMENT_LABEL')}
        placeholder={t('DOOR_OPEN_MODAL.COMMENT_PLACEHOLDER')}
        value={comment || ''}
        onChangeTA={(e) => onCommentChange(e)}
        disabled={isOpening} //disable text area when opening a door
      />
      {errorMsg && <Text color={'red'}>{t('DOOR_OPEN_MODAL.OPEN_DOOR_ERROR')}</Text>}
    </>
  );

  const hideModal = () => {
    setisOpening(false);
    setcomment('');
    seterrorMsg(false);
    onHideCallBack();
  };
  return (
    <DialogBox
      variant={DialogBoxVariants.long}
      dialogVisible={visible}
      onHideCallback={() => hideModal()}
      dialogHeader={
        <>{`${t('DOOR_OPEN_MODAL.MODAL_TITLE_PART_ONE')} ${door ? door.doorName : ''} 
        ${t('DOOR_OPEN_MODAL.MODAL_TITLE_PART_TWO')}`}</>
      }
      dialogFooter={
        <div>
          <Button
            label={t('DOOR_OPEN_MODAL.CANCEL_BUTTON_TEXT')}
            variant={ButtonVariants.textonly}
            onClick={() => hideModal()}
          />
          <Button
            label={t('DOOR_OPEN_MODAL.OPEN_BUTTON_TEXT')}
            icon={isOpening ? 'pi pi-spin pi-spinner' : ''}
            onClick={openDoor}
            disabled={comment && comment.length !== 0 ? false : true}
          />
        </div>
      }
      dialogDismissableMask={true}
      dialogClosable={true}
      content={dialogBoxContent}
    />
  );
};

export default DoorOpenModal;

export interface DoorOpenModalProps {
  /* Modal visibility */
  visible: boolean;
  /* Door to open */
  door?: AccessPoints;
  /* on hide call back function */
  onHideCallBack: () => void;
  //useRef for toast
  toastRef?: any;
}
