import { Badge } from 'primereact/badge';

export type LabelSizes = 'default' | 'large' | 'xlarge';

export type LabelSeverity = 'success' | 'info' | 'warning' | 'danger';

const Label = ({ value, size = 'default', severity = 'info', ...props }: LabelProps) => {
  return <Badge value={value} size={size} severity={severity} {...props}></Badge>;
};

export interface LabelProps {
  /** Value to display in the label */
  value: string;
  /** Size of the label */
  size?: LabelSizes;
  /** Severity type of the badge */
  severity?: LabelSeverity;
}

export default Label;
