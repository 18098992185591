import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { Account as typeOfAccount } from '@gym-particles/types/Account';
import { getMobileUsers } from '@gym-graphql/queries';
import { MobileUser } from '@gym-src/API';
import { PaginatedQueryParams } from '@gym-particles/types/Pagination';
import i18n from '@gym-particles/i18next';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  items: Array<typeOfAccount>;
  totalRecords: number;
  isAccountLoading: boolean;
} = {
  items: [],
  totalRecords: 0,
  isAccountLoading: false
};

export const fetchAccounts = createAsyncThunk(
  '/gym/fetchAccounts',
  async (params: PaginatedQueryParams) => {
    if (params.sortField === 'name') {
      params.sortField = 'firstName';
    }
    const response = await (API.graphql({
      query: getMobileUsers,
      variables: {
        userId: params.userId,
        offset: params.offset,
        pageSize: params.pageSize,
        sortField: params.sortField,
        sortOrder: params.sortOrder === 1 ? 'ASC' : 'DESC',
        search: {
          searchField: params.search?.searchField,
          searchText: params.search?.searchText
        }
      }
    }) as Promise<{
      data: {
        getMobileUsers: {
          items: MobileUser[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const AccountsSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      state.isAccountLoading = false;
      if (action.payload.data.getMobileUsers.items === null) {
        return;
      }
      state.items = action.payload.data.getMobileUsers.items.map(
        (e: MobileUser): typeOfAccount => {
          return {
            id: e.id || 0,
            name: (e.firstName || '') + ' ' + (e.lastName || ''),
            firstName: e.firstName || '',
            lastName: e.lastName || '',
            email: e.email ? e.email : '',
            phone: e.phone || '',
            address: e.address || '',
            createdDate: e.createdDate || '',
            createdBy: e.createdBy || 0,
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            status: e.status ? e.status : false,
            displayStatus: e.status ? 'Active' : 'Inactive',
            accessTypes: {
              isMobileAppDisabled: e.isMobileAppDisabled || false,
              isJustTapRevoked: e.isJustTapRevoked || false
            },
            imageUrl: e.imageUrl || undefined,
            isMoreInfoRequested: e.isMoreInfoRequested || undefined,
            language: e.language || undefined,
            defaultLocationDetectionMethod: e.defaultLocationDetectionMethod || undefined,
            memberId: e.memberId ? i18n.t('COMMON.YES') : i18n.t('COMMON.NO')
          };
        }
      );

      state.totalRecords = action.payload.data.getMobileUsers.totalRecords.totalRecords;
      return state;
    });

    builder.addCase(fetchAccounts.pending, (state, action) => {
      state.isAccountLoading = true;
      return state;
    });

    builder.addCase(fetchAccounts.rejected, (state, action) => {
      state.isAccountLoading = false;
      console.log('getAccounts', action);
      return state;
    });
  }
});

export default AccountsSlice.reducer;
