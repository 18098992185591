import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { LocationOpeningHoursJoined } from '@gym-src/API';
import { OpeningHour } from '@gym-particles/types/models';
import { getAvailableLocationOpeningHours } from '@gym-graphql/queries';
import { deleteLocationOpeningHours as deleteLocationOpeningHoursMutation } from '@gym-graphql/mutations';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isOpeningHoursLoading: boolean;
  items: Array<{
    locationId: number;
    items: OpeningHour[];
    totalRecords: number;
  }>;
} = {
  isOpeningHoursLoading: false,
  items: []
};

type paramType = {
  locationId: number;
};

export const fetchOpeningHours = createAsyncThunk(
  '/gym/fetchOpeningHours',
  async (params: paramType) => {
    const response = await (API.graphql({
      query: getAvailableLocationOpeningHours,
      variables: {
        locationId: params.locationId
      }
    }) as Promise<{
      data: {
        getAvailableLocationOpeningHours: {
          items: LocationOpeningHoursJoined[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const deleteLocationOpeningHours = createAsyncThunk(
  'LocationOpeningHour/deleteOpeningHour',
  async (id: number) => {
    const response = await (API.graphql({
      query: deleteLocationOpeningHoursMutation,
      variables: {
        id: id
      }
    }) as Promise<{
      data: {
        getAvailableHourDetails: {
          items: LocationOpeningHoursJoined[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const OpeningHoursSlice = createSlice({
  name: 'OpeningHour',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOpeningHours.fulfilled, (state, action) => {
      state.isOpeningHoursLoading = false;
      if (!action.payload.data.getAvailableLocationOpeningHours.items) {
        return;
      }

      const items = action.payload.data.getAvailableLocationOpeningHours.items.map(
        (e: LocationOpeningHoursJoined): OpeningHour => {
          return {
            id: e.id || 0,
            locationId: e.locationId || 0,
            openingHourId: e.openingHourId || 0,
            name: e.name || '',
            validFromDate: e.validFromDate
              ? dayjs(e.validFromDate || '').format('DD MMM YYYY')
              : '',
            validToDate: e.validToDate ? dayjs(e.validToDate || '').format('DD MMM YYYY') : '',
            validFromDateTime: e.startDateTime
              ? dayjs(e.startDateTime || '').format('DD MMM YYYY HH:mm:ss')
              : '',
            validToDateTime: e.endDateTime
              ? dayjs(e.endDateTime || '').format('DD MMM YYYY HH:mm:ss')
              : '',
            createdBy: e.createdBy || 0,
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            status: e.status || '',
            dayOfWeek: e.dayOfWeek || '',
            displayStatus: e.status ? e.status.charAt(0).toUpperCase() + e.status.slice(1) : ''
          };
        }
      );
      const totalRecords =
        action.payload.data.getAvailableLocationOpeningHours.totalRecords.totalRecords;

      const fetchedOpeningHoursInfo = {
        totalRecords: totalRecords,
        items: items,
        locationId: action.meta.arg.locationId
      };
      if (state.items.length === 0) {
        state.items.push(fetchedOpeningHoursInfo);
      } else {
        // Check if we are updating an already fetched or fetching new one
        const update = state.items.find(
          (OpeningHourInfo) => OpeningHourInfo.locationId === fetchedOpeningHoursInfo.locationId
        );
        if (update) {
          const newState = state.items.map((OpeningHourInfo) => {
            if (OpeningHourInfo.locationId === fetchedOpeningHoursInfo.locationId) {
              return fetchedOpeningHoursInfo;
            } else {
              return OpeningHourInfo;
            }
          });
          Object.assign(state.items, newState);
        } else {
          state.items.push(fetchedOpeningHoursInfo);
        }
      }
    });

    builder.addCase(fetchOpeningHours.pending, (state, action) => {
      state.isOpeningHoursLoading = true;
      return state;
    });

    builder.addCase(deleteLocationOpeningHours.fulfilled, (state, action) => {
      state.isOpeningHoursLoading = false;
      const openingHourId = action.meta.arg;
      const filtered = state.items.map((openingHourObj) => {
        const filteredHours = openingHourObj.items.filter(
          (OpeningHour) => OpeningHour.openingHourId !== openingHourId
        );
        openingHourObj.items = filteredHours;
        openingHourObj.totalRecords = openingHourObj.totalRecords - 1;
        return openingHourObj;
      });
      state.items = filtered;
    });

    builder.addCase(deleteLocationOpeningHours.pending, (state, action) => {
      state.isOpeningHoursLoading = true;
      return state;
    });

    builder.addCase(deleteLocationOpeningHours.rejected, (state, action) => {
      state.isOpeningHoursLoading = false;
      console.error('deleteLocationOpeningHours', action);
      return state;
    });
  }
});

export default OpeningHoursSlice.reducer;
