import TabView, { OnTabChangeEvent } from '@gym-atoms/TabView/TabView';
import {
  GymDashboardDetailsHeaderTabNames,
  GymDashboardDetailsHeaderRoutes
} from '@gym-particles/types/GymDashboardDetails';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GymDashboardActivity from '@gym-organisms/GymDashboardActivity/GymDashboardActivity';
import GymDashboardTasks from '@gym-organisms/GymDashboardTasks/GymDashboardTasks';
import GymDashboardVisits from '@gym-organisms/GymDashboardVisits/GymDashboardVisits';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { fetchMyTaskCount } from '@gym-redux/slices/activitySlice';
import { UserRole } from '@gym-particles/types/User';

const GymDashboardHeader = ({
  currentGymId,
  currentGymChainId,
  currentTab,
  headerButtonOnClick
}: GymDashboardHeaderProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState(GymDashboardDetailsHeaderRoutes[currentTab]);

  const myTasksCount = useAppSelector((state) => state.activity.myTaskCount);
  const userId = useAppSelector((state) => state.user.userId);
  const userRole = useAppSelector((state) => state.user.userRole);
  const userRoleId = useAppSelector((state) => state.user.roleId);
  const [isCountChanged, setIsCountChanged] = useState(false);

  useEffect(() => {
    dispatch(
      fetchMyTaskCount({
        gymId: currentGymId,
        userId: userId,
        roleId: userRoleId,
        platformAdmin: userRole === UserRole.SYSTEM_ADMIN
      })
    );
  }, [isCountChanged]);

  const tabViewItems = [
    {
      header: t('GYM_DASHBOARD_HEADER.VISITS'),
      content: <GymDashboardVisits gymId={currentGymId}></GymDashboardVisits>
    },
    {
      header: t('GYM_DASHBOARD_HEADER.ACTIVITY'),
      content: (
        <GymDashboardActivity
          gymId={currentGymId}
          countChange={setIsCountChanged}
          isCountChanged={isCountChanged}></GymDashboardActivity>
      )
    },
    {
      header: t('GYM_DASHBOARD_HEADER.INQUIRIES'),
      content: (
        <GymDashboardTasks
          gymId={currentGymId}
          countChange={setIsCountChanged}
          isCountChanged={isCountChanged}></GymDashboardTasks>
      ),
      count: myTasksCount || undefined
    }
    //--Sodvin Release--
    // {
    //   header: 'Resync Gym Data',
    //   className: 'last-col-tab',
    //   headerButton: true
    // }
  ];

  const routes = [
    `/gymChains/${currentGymChainId}/gyms/${currentGymId}/dashboard/visits`,
    `/gymChains/${currentGymChainId}/gyms/${currentGymId}/dashboard/activity`,
    `/gymChains/${currentGymChainId}/gyms/${currentGymId}/dashboard/inquiries`
  ];

  const onTabChangeHandler = (e: OnTabChangeEvent) => {
    setActiveTab(e.index);
    history.push(routes[e.index]);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="tab-view-align-custom">
            <TabView
              headerClassName="dashboard-header-tabs"
              items={tabViewItems}
              activeTab={activeTab}
              onTabChange={onTabChangeHandler}
            />
          </div>
        </div>
        {/* <div className="col-3">
          <Button
            label={t('GYM_DASHBOARD_PAGE.UPPER_MAIN_COLUMN_HEADER_BUTTON_LABEL')}
            onClick={() => headerButtonOnClick()}></Button>
        </div> */}
      </div>
    </div>
  );
};

export interface GymDashboardHeaderProps {
  currentGymId: number;
  currentGymChainId: number;
  currentTab: GymDashboardDetailsHeaderTabNames;
  headerButtonOnClick: () => void;
}
export default GymDashboardHeader;
