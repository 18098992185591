import { useState, useEffect, FormEvent, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Input from '@gym-atoms/Input/Input';
import Dropdown, { DropdownChangeEvent, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { useForm } from 'react-hook-form';
import { AlertType, createAlert } from '@gym-atoms/Alert/Alert';
import {
  MembershipInput,
  SiteLocationMembershipInput,
  Membership as MembershipType,
  DeletableResponse
} from '@gym-src/API';
import { API } from 'aws-amplify';
import {
  addMembership as addMembershipMutation,
  addSiteLocationMembership as addSiteLocationMembershipMutation,
  modifyMembership as modifyMembershipMutation,
  removeSiteLocationMembership as removeSiteLocationMembershipMutation,
  updateSubscriptionMinusCrBalByMembershipId
} from '@gym-graphql/mutations';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Text from '@gym-atoms/Text/Text';
import { fetchMembershipsForSite, fetchMembershipTypes } from '@gym-redux/slices/membershipSlice';
import { Membership, partialGymType, SiteLocation } from '@gym-particles/types/models';
import { UserRole } from '@gym-particles/types/User';
import { getPartialGymsOfGymChain } from '@gym-particles/queries';
import Loader from '@gym-atoms/Loader/Loader';
import { getSiteLocationMemberships, isSiteLocationMembershipLinked } from '@gym-graphql/queries';
import { usePrevious } from '@gym-particles/hooks';

const MembershipInfoTemplate = (props: MembershipInfoTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { membershipId, chainId } = useParams<{ membershipId: string; chainId: string }>();
  const membershipTypes = useAppSelector((state) => state.membership.membershipTypes);
  const dispatch = useAppDispatch();
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const [gymsDropDownItems, setGymsDropDownItems] = useState<Array<SelectItem>>([]);
  const isMembershipLoading = useAppSelector((state) => state.membership.isMembershipsLoading);
  const [defaultSelectedGyms, setDefaultSelectedGyms] = useState<Array<SiteLocation>>([]);
  const [gymRemovableCheckLoading, setGymRemovableCheckLoading] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const userId = useAppSelector((state) => state.user.userId);
  const userRole = useAppSelector((state) => state.user.userRole);
  const [isInserted, setIsInserted] = useState(false);
  const membershipTypeDropDownItems: SelectItem[] =
    membershipTypes?.map((data) => {
      return { label: data.name, value: data.id };
    }) || [];
  const [isMinusCrBalChangedDialogVisible, setIsMinusCrBalChangedDialogVisible] = useState(false);
  const [shouldUpdateCrBalOfSubs, setShouldUpdateCrBalOfSubs] = useState(false);
  const minusCreditBalancePreviousVal = usePrevious(false);

  const providerId = useAppSelector((state) => {
    return state.gymChain.items.find((chain) => chain.id === +chainId)?.providerId;
  });

  const getTranslationKey = (e: string): string => {
    return props.type === 'new' ? 'ADD_NEW_MEMBERSHIP.' + e : 'MODIFY_MEMBERSHIP.' + e;
  };

  type FormInputs = {
    membershipId: number;
    name?: string;
    importedId?: string;
    membershipTypeId?: number;
    validityPeriodMeasure?: string;
    validityPeriodValue?: number;
    isMinusCreditBalanceApplied: 'No';
    gyms?: Array<string>;
    status?: string;
  };

  const defaultValues: FormInputs = {
    membershipId: props.membership?.id || 0,
    name: props.membership?.membershipName || '',
    importedId: props.membership?.importedId.toString() || '',
    membershipTypeId: props.membership?.membershipTypeId,
    // TO-DO [Suggestion] - Keep these values in one place as enums.
    validityPeriodMeasure: 'days',
    validityPeriodValue: props.membership?.validityPeriod,
    gyms: [],
    isMinusCreditBalanceApplied: 'No',
    status: props.membership?.status
  };

  const [formValues, setFormValues] = useState(defaultValues);

  const { register, handleSubmit, setValue, errors } = useForm<FormInputs>({
    defaultValues: defaultValues
  });

  const DeleteDialogFooter = () => {
    return (
      <div>
        {gymRemovableCheckLoading ? (
          <></>
        ) : (
          <Button
            label={t('COMMON.OK')}
            variant={ButtonVariants.basic}
            onClick={() => setIsDeleteDialogVisible(false)}
          />
        )}
      </div>
    );
  };
  const RemovableGymDialog = () => {
    return (
      <div>
        <DialogBox
          dialogVisible={isDeleteDialogVisible}
          variant={DialogBoxVariants.long}
          dialogDismissableMask={true}
          onHideCallback={() => setIsDeleteDialogVisible(false)}
          dialogFooter={<DeleteDialogFooter />}
          dialogClosable={false}
          dialogHeader={
            gymRemovableCheckLoading
              ? t('MEMBERSHIP_LIST.TABLE.CHECK_LINKED_GYMS')
              : t('MEMBERSHIP_LIST.TABLE.DELETE_MODAL_HEADER')
          }
          content={
            gymRemovableCheckLoading ? (
              <>
                <div className="mb-3">
                  <Loader shape="rectangle" width="full" />
                </div>
                <div className="mb-3">
                  <Loader shape="rectangle" width="full" />
                </div>
                <div className="mb-3">
                  <Loader shape="rectangle" width="half" />
                </div>
              </>
            ) : (
              <Text>{t('MEMBERSHIP_LIST.TABLE.DELETE_GYM_MODAL_CANNOT')}</Text>
            )
          }
        />
      </div>
    );
  };

  const onProceedingIsMinusCrBalChangeDialog = (acceptState = false) => {
    if (acceptState) {
      setShouldUpdateCrBalOfSubs(true);
    } else {
      setFormValues({
        ...formValues,
        isMinusCreditBalanceApplied: 'No'
      });
      setValue('isMinusCreditBalanceApplied', 'No');
    }
    setIsMinusCrBalChangedDialogVisible(false);
  };

  const MinusCreditBalanceChangeDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.CANCEL')}
          variant={ButtonVariants.textonly}
          onClick={() => onProceedingIsMinusCrBalChangeDialog()}
        />
        <Button
          label={t('COMMON.YES')}
          variant={ButtonVariants.basic}
          onClick={() => onProceedingIsMinusCrBalChangeDialog(true)}
        />
      </div>
    );
  };

  const MinusCreditBalUpdateDialog = () => {
    return (
      <div>
        <DialogBox
          dialogVisible={isMinusCrBalChangedDialogVisible}
          variant={DialogBoxVariants.long}
          dialogDismissableMask={false}
          dialogClosable={false}
          dialogFooter={<MinusCreditBalanceChangeDialogFooter />}
          dialogHeader={t('MODIFY_MEMBERSHIP.UPDATE_MINUS_CREDIT_BALANCE_HEADER')}
          content={<Text>{t('MODIFY_MEMBERSHIP.UPDATE_MINUS_CREDIT_BALANCE_BODY')}</Text>}
        />
      </div>
    );
  };

  const checkRemovableGyms = async (gyms: number[]) => {
    setIsDeleteDialogVisible(true);
    setGymRemovableCheckLoading(true);

    const selectedSiteLocationMembershipId = defaultSelectedGyms.find(
      (i) => i.siteLocationId === +gyms[0]
    )?.siteLocationMembershipId;

    try {
      const response = await (API.graphql({
        query: isSiteLocationMembershipLinked,
        variables: {
          id: selectedSiteLocationMembershipId,
          membershipId: membershipId
        }
      }) as Promise<{
        data: { isSiteLocationMembershipLinked: DeletableResponse[] };
      }>);

      setGymRemovableCheckLoading(false);
      if (
        response.data.isSiteLocationMembershipLinked.length === 1 &&
        response.data.isSiteLocationMembershipLinked[0].tableName === 'SiteLocationMembership'
      ) {
        setIsDeleteDialogVisible(false);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      setFailedDialogVisible(true);
      setGymRemovableCheckLoading(false);
      setIsDeleteDialogVisible(false);
    }
  };

  const getGyms = () => {
    try {
      // get a list of all gyms for gym chain
      const response = (API.graphql({
        query: getPartialGymsOfGymChain,
        variables: {
          id: chainId,
          offset: 0,
          pageSize: 1000,
          sortField: 'gymName',
          sortOrder: 'ASC',
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        }
      }) as Promise<{
        data: { getGymsOfGymChain: { items: partialGymType[] } };
      }>).then((e) => {
        const gymsDropDown: SelectItem[] = e.data.getGymsOfGymChain.items.map((gym) => {
          return { label: gym.gymName, value: String(gym.gymId) };
        });

        setGymsDropDownItems(gymsDropDown);
        props.type === 'modify' && getDefaultSelectedGyms();
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  /** Fetches gyms assigned to this membership */
  const getDefaultSelectedGyms = () => {
    try {
      (API.graphql({
        query: getSiteLocationMemberships,
        variables: {
          membershipId: +membershipId
        }
      }) as Promise<{
        data: { getSiteLocationMemberships: SiteLocation[] };
      }>).then((response) => {
        setDefaultSelectedGyms(response.data.getSiteLocationMemberships);
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.error('Failed to fetch selected gyms', error);
    }
  };

  useEffect(() => {
    dispatch(fetchMembershipTypes());
    getGyms();
    dispatch(
      fetchMembershipsForSite({
        siteId: props.membership?.siteId || 0,
        pagination: {
          offset: 0,
          pageSize: 10,
          sortField: 'membershipName',
          sortOrder: 1
        },
        isMindbody: providerId === 1
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      fetchMembershipsForSite({
        siteId: props.membership?.siteId || 0,
        pagination: {
          offset: 0,
          pageSize: 10,
          sortField: 'membershipName',
          sortOrder: 1
        },
        isMindbody: providerId === 1
      })
    );
  }, [providerId]);

  const [tempSelectionValues, setTempSelectionValues] = useState<string[] | undefined>(
    formValues.gyms
  );

  useEffect(() => {
    setFormValues({
      ...formValues,
      membershipId: props.membership?.id || 0,
      name: props.membership?.membershipName || '',
      importedId: props.membership?.importedId.toString() || '',
      membershipTypeId: props.membership?.membershipTypeId,
      // TO-DO [Suggestion] - Keep these values in one place as enums.
      validityPeriodMeasure: 'days',
      validityPeriodValue: props.membership?.validityPeriod,
      isMinusCreditBalanceApplied: 'No',
      status: props.membership?.status,
      gyms: defaultSelectedGyms.map((gym) => String(gym.siteLocationId))
    });
    setValue('name', props.membership?.membershipName);
    setValue('membershipTypeId', props.membership?.membershipTypeId);
    setValue('importedId', props.membership?.importedId);
    setValue('validityPeriodValue', props.membership?.validityPeriod);
    setValue('status', props.membership?.status);
    setValue(
      'gyms',
      defaultSelectedGyms.map((gym) => String(gym.siteLocationId))
    );
    setValue('isMinusCreditBalanceApplied', 'No');
    setTempSelectionValues(defaultSelectedGyms.map((gym) => String(gym.siteLocationId)));
  }, [props, defaultSelectedGyms]);

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}</Text>}
        />
      </div>
    );
  };

  const addSiteLocationMembership = async (gymId: number, membership: MembershipType) => {
    const addSiteLocationMembership: SiteLocationMembershipInput = {
      siteLocationId: gymId,
      membershipId: membership.id || 0,
      status: membership.status || '',
      createdBy: membership.createdBy || 0,
      lastModifiedBy: membership.lastModifiedBy || 0
    };

    try {
      await API.graphql({
        query: addSiteLocationMembershipMutation,
        variables: {
          input: addSiteLocationMembership
        }
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const removeSiteLocationMembership = async (gymId: number) => {
    try {
      await API.graphql({
        query: removeSiteLocationMembershipMutation,
        variables: {
          id: gymId
        }
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const addMembership = async (data: FormInputs) => {
    console.log('addMembership:', data);
    const addMembershipInput: MembershipInput = {
      siteId: +chainId,
      membershipName: data.name?.replace(/'/g, "''") || '',
      membershipTypeId: data.membershipTypeId || 0,
      importedId: +data.importedId || 0,
      isImported: false,
      // TO-DO [Suggestion] - Keep these values in one place as enums.
      validityPeriod:
        data.validityPeriodValue === 0 || !data.validityPeriodValue
          ? -1
          : data.validityPeriodMeasure === 'days'
          ? data.validityPeriodValue || 0
          : (data.validityPeriodValue || 0) * 30,
      isMinusCreditBalanceApplied: false,
      status: data.status || '',
      createdBy: userId,
      lastModifiedBy: userId
    };

    try {
      const addMembershipResponse = await (API.graphql({
        query: addMembershipMutation,
        variables: {
          input: addMembershipInput
        }
      }) as Promise<{
        data: { addMembership: MembershipType };
      }>);

      data.gyms?.forEach(async (gyms) => {
        await addSiteLocationMembership(+gyms, addMembershipResponse.data.addMembership);
      });

      createAlert(props.alertRef, {
        content: <p>{t(getTranslationKey('ALERT_MESSAGE_SUCCESS'))}</p>
      });
      setTimeout(() => history.push(`/gymChains/${chainId}/memberships`), 3000);
    } catch (e) {
      setFailedDialogVisible(true);
      console.log(e);
    }
  };

  const modifyMembership = async (data: FormInputs) => {
    const modifyMembershipInput: MembershipInput = {
      id: props.membership?.id,
      siteId: +chainId,
      membershipName: data.name?.replace(/'/g, "''") || '',
      membershipTypeId: data.membershipTypeId || 1,
      isImported: props.membership?.isImported || false,
      importedId: props.membership?.importedId ? props.membership?.importedId : 0 || 0,
      // TO-DO [Suggestion] - Keep these values in one place as enums.
      validityPeriod:
        data.validityPeriodValue === 0
          ? -1
          : data.validityPeriodMeasure === 'days'
          ? data.validityPeriodValue || -1
          : (data.validityPeriodValue || -1) * 30,
      isMinusCreditBalanceApplied: false,
      status: data.status || '',
      createdBy: props.membership?.createdBy || 1,
      lastModifiedBy: userId
    };

    try {
      const modifyMembershipResponse = await (API.graphql({
        query: modifyMembershipMutation,
        variables: {
          input: modifyMembershipInput
        }
      }) as Promise<{
        data: { modifyMembership: MembershipType };
      }>);

      const addedGyms = data.gyms?.filter(
        (x: string) => !defaultSelectedGyms.map((y: SiteLocation) => y.siteLocationId).includes(+x)
      );

      addedGyms?.forEach(async (g) => {
        await addSiteLocationMembership(+g, modifyMembershipResponse.data.modifyMembership);
      });
    } catch (e) {
      setFailedDialogVisible(true);
      console.log(e);
    }

    const removedGyms = defaultSelectedGyms
      .map((y: SiteLocation) => y.siteLocationId)
      .filter((x: number) => !data.gyms?.includes(String(x)));

    try {
      removedGyms?.forEach(async (g: number) => {
        const gymRemoved = defaultSelectedGyms.find((i: SiteLocation) => i.siteLocationId === g)
          ?.siteLocationMembershipId;

        await removeSiteLocationMembership(gymRemoved || 0);
      });

      createAlert(props.alertRef, {
        content: <p>{t(getTranslationKey('ALERT_MESSAGE_SUCCESS'))}</p>
      });

      history.push(`/gymChains/${chainId}/memberships`);
    } catch (e) {
      setFailedDialogVisible(true);
      console.log(e);
    }

    if (shouldUpdateCrBalOfSubs) {
      try {
        await API.graphql({
          query: updateSubscriptionMinusCrBalByMembershipId,
          variables: {
            membershipId: +membershipId,
            hasMinusCreditBalance: false
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  //Form submission handler
  const onSubmitHandler = async (data: FormInputs) => {
    console.log(data);
    setIsInserted(true);
    if (props.type === 'new') {
      await addMembership(data);
    } else if (props.type === 'modify' && props.membership) {
      await modifyMembership(data);
    }
    setIsInserted(false);
  };

  useEffect(() => {
    register('name', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      maxLength: { value: 255, message: 'required' as string }
    });
    register('importedId', {
      validate: (value) => {
        if (!(Number(value) >= 0 && Number.isInteger(Number(value)))) {
          return t(getTranslationKey('FORM_NUMBER_FIELD_ERROR')) as string;
        }
      }
    });
    register('membershipTypeId', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('validityPeriodMeasure', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('validityPeriodValue', {
      validate: (value) =>
        !value ||
        parseInt(value) > 0 ||
        (t(getTranslationKey('FORM_VALIDATE_ONLY_POSITIVE_NUMBERS')) as string)
    });
    register('gyms', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('isMinusCreditBalanceApplied', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('status', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
  }, [register]);

  const MembershipTypeDropdownHandler = (e: DropdownChangeEvent) => {
    setValue('membershipTypeId', e.value);
    setFormValues({ ...formValues, membershipTypeId: e.value });
  };

  // TO-DO [Suggestion] - Keep these values in one place as enums.
  const validityPeriodDropDownItems = [
    {
      label: t(getTranslationKey('FORM_VALIDITY_PERIOD_DROPDOWN_DAYS')),
      value: 'days'
    },
    {
      label: t(getTranslationKey('FORM_VALIDITY_PERIOD_DROPDOWN_MONTHS')),
      value: 'months'
    }
  ];
  const validityPeriodDropdownHandler = (e: DropdownChangeEvent) => {
    setValue('validityPeriodMeasure', e.value);
    setFormValues({ ...formValues, validityPeriodMeasure: e.value });
  };

  const gymsDropdownHandler = async (e: DropdownChangeEvent) => {
    const removedGyms = defaultSelectedGyms
      .map((y: SiteLocation) => y.siteLocationId)
      .filter((x: number) => !e.value.includes(String(x)));

    let response;

    if (removedGyms.length > 0) {
      if (tempSelectionValues && tempSelectionValues?.length > e.value.length) {
        response = await checkRemovableGyms(removedGyms);
      } else {
        response = true;
      }
    } else {
      response = true;
    }
    if (response) {
      setValue('gyms', e.value);
      setFormValues({ ...formValues, gyms: e.value });
    } else {
      return;
    }
    setTempSelectionValues(e.value);
  };

  // TO-DO [Suggestion] - Keep these values in one place as enums.
  const minusCreditBalDropDownItems = [
    {
      label: t(getTranslationKey('FORM_MINUS_CREDIT_BAL_YES')),
      value: 'Yes'
    },
    { label: t(getTranslationKey('FORM_MINUS_CREDIT_BAL_NO')), value: 'No' }
  ];
  const minusCreditBalHandler = (e: DropdownChangeEvent) => {
    if (
      props.type != 'new' &&
      ((e.value === 'Yes' && !minusCreditBalancePreviousVal) ||
        (e.value === 'No' && minusCreditBalancePreviousVal))
    ) {
      setIsMinusCrBalChangedDialogVisible(true);
    }
    setValue('isMinusCreditBalanceApplied', e.value);
    setFormValues({ ...formValues, isMinusCreditBalanceApplied: e.value });
  };

  const statusDropDownItems = [
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN_ACTIVE')),
      value: 'active'
    },
    { label: t(getTranslationKey('FORM_STATUS_DROPDOWN_INACTIVE')), value: 'inactive' }
  ];
  const statusDropdownHandler = (e: DropdownChangeEvent) => {
    setValue('status', e.value);
    setFormValues({ ...formValues, status: e.value });
  };

  const changeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof FormInputs, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
    console.log(formValues);
  };
  const loderElement = () => {
    return (
      <div className="loaderHeader">
        <div className="loaderContent">
          <Loader shape="rectangle" width="quarter" />
        </div>
        <div>
          <Loader shape="rectangle" width="full" />
        </div>
      </div>
    );
  };
  return (
    <div className="col-md-6">
      {isMembershipLoading ? (
        [...Array(5)].map((element, index) => {
          return <div key={index}> {loderElement()} </div>;
        })
      ) : (
        <form className="input-mb20" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <Input
            data-cy={'membership-info-temp-form-input-name'}
            label={t(getTranslationKey('FORM_INPUT_LABEL_NAME'))}
            variant="basic"
            placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_NAME'))}
            onChange={changeHandler}
            name="name"
            error={errors.name ? true : false}
            errorMessage={errors.name?.message}
            value={formValues.name}
            required={true}
          />

          <div className="mb25">
            <label className="fw-bold">
              {t(getTranslationKey('FORM_INPUT_LABEL_TYPE'))}
              <span className="p-error"> *</span>
            </label>
            <Dropdown
              name="membershipTypeId"
              placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_TYPE'))}
              value={formValues.membershipTypeId}
              options={membershipTypeDropDownItems}
              onChange={MembershipTypeDropdownHandler}
              error={errors.membershipTypeId ? true : false}
            />
            {errors.membershipTypeId && (
              <p data-cy={'membership-info-temp-form-dropdown-type-error'} className="p-error mb15">
                {t(getTranslationKey('FORM_TYPE_EMPTY_ERROR'))}
              </p>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_LABEL_VALIDITY_PERIOD'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                name="validityPeriodMeasure"
                placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_VALIDITY_PERIOD'))}
                value={formValues.validityPeriodMeasure}
                options={validityPeriodDropDownItems}
                onChange={validityPeriodDropdownHandler}
                error={errors.validityPeriodMeasure ? true : false}
              />
              {errors.validityPeriodMeasure && (
                <p className="p-error mb15">{t(getTranslationKey('FORM_PERIOD_EMPTY_ERROR'))}</p>
              )}
            </div>
            <div className="col-md-6">
              <div className="mt5">
                <Input
                  data-cy={'membership-info-temp-form-input-validity-period'}
                  variant="basic"
                  onChange={changeHandler}
                  name="validityPeriodValue"
                  placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_VALIDITY_PERIOD_COUNT'))}
                  error={errors.validityPeriodValue ? true : false}
                  errorMessage={errors.validityPeriodValue?.message}
                  value={
                    formValues.validityPeriodValue ? String(formValues.validityPeriodValue) : ''
                  }
                />
              </div>
            </div>
          </div>
          <div className="mb25">
            <label className="fw-bold">{t(getTranslationKey('FORM_INPUT_LABEL_GYMS'))}</label>
            <Dropdown
              chips={true}
              name="gyms"
              placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_GYMS'))}
              value={formValues.gyms}
              options={gymsDropDownItems}
              onChange={gymsDropdownHandler}
              error={errors.gyms ? true : false}
            />
            {errors.gyms && (
              <p data-cy={'membership-info-temp-form-dropdown-gyms-error'} className="p-error">
                {t(getTranslationKey('FORM_GYMS_EMPTY_ERROR'))}
              </p>
            )}
          </div>
          {providerId == 1 && (
            <div className="mb25" hidden>
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_LABEL_MINUS_CREDIT_BAL_APPLIED'))}
              </label>
              <Dropdown
                name="isMinusCreditBalanceApplied"
                placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_CREDIT_BAL_APPLIED'))}
                value={formValues.isMinusCreditBalanceApplied}
                options={minusCreditBalDropDownItems}
                onChange={minusCreditBalHandler}
              />
            </div>
          )}
          <div className="mb25">
            <label className="fw-bold">
              {t(getTranslationKey('FORM_INPUT_LABEL_STATUS'))}
              <span className="p-error"> *</span>
            </label>
            <Dropdown
              name="status"
              placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_STATUS'))}
              value={formValues.status}
              options={statusDropDownItems}
              onChange={statusDropdownHandler}
              error={errors.status ? true : false}
            />
            {errors.status && (
              <p
                data-cy={'membership-info-temp-form-dropdown-stutus-error'}
                className="p-error mb15">
                {t(getTranslationKey('FORM_STATUS_EMPTY_ERROR'))}
              </p>
            )}
          </div>
          {userRole === UserRole.SYSTEM_ADMIN && formValues.membershipTypeId === 4 && (
            <div className="mb25">
              <Input
                label={t(getTranslationKey('FORM_INPUT_LABEL_IMPORTED_ID'))}
                variant="basic"
                placeholder={t(getTranslationKey('FORM_INPUT_PLACEHOLDER_IMPORTED_ID'))}
                onChange={changeHandler}
                name="importedId"
                error={errors.importedId ? true : false}
                errorMessage={errors.importedId?.message}
                value={formValues.importedId !== '0' ? formValues.importedId : ''}
                required={false}
              />
            </div>
          )}
          <div className="d-flex flex-row mt35 btn-min-w-110">
            <div className="mr15">
              <Button
                data-cy={'membership-info-temp-form-btn-save'}
                icon={isInserted ? 'pi-spinner pi-spin' : ''}
                label={t(getTranslationKey('FORM_BTN_LABEL_SAVE'))}
                size={ButtonSizes.medium}
                disabled={isInserted}
              />
            </div>
            <Button
              data-cy={'membership-info-temp-form-btn-cancel'}
              label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
              variant={ButtonVariants.textonly}
              onClick={() => history.push(`/gymChains/${chainId}/memberships`)}
              disabled={isInserted}
            />
            <ActionFailedDialog />
            <RemovableGymDialog />
            <MinusCreditBalUpdateDialog />
          </div>
        </form>
      )}
    </div>
  );
};

export default MembershipInfoTemplate;

type MembershipInfoTemplateType = 'new' | 'modify';

export interface MembershipInfoTemplateProps {
  type: MembershipInfoTemplateType;
  membership?: Membership;
  alertRef: RefObject<AlertType>;
  alertMessage: string;
}
