import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Scheduler from '@gym-atoms/Scheduler/Scheduler';
import {
  addSiteScheduleDetails,
  removeSiteScheduleDetailRecord,
  resolveScheduleRequest,
  updateScheduleStatus,
  updateScheduleStatusId
} from '@gym-graphql/mutations';
import { fetchDaySchedules } from '@gym-redux/slices/daySchedulesSlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { getAllWeekDaysResponse, SiteScheduleDetailsInput } from '@gym-src/API';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DaySchedule } from '@gym-particles/types/DaySchedules';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Text from '@gym-atoms/Text/Text';
import {
  GymScheduleStatus,
  ScheduleEvent,
  ScheduleInternalStatus
} from '@gym-particles/types/GymSchedules';
import Alert, { AlertType, createAlert } from '@gym-atoms/Alert/Alert';
import { getAllWeekDays } from '@gym-graphql/queries';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';
import { fetchPlatformSettings } from '@gym-redux/slices/platformSettingSlice';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(utc);
dayjs.extend(weekday);
const GymSchedulesScheduler = (props: GymSchedulesOpeningHourProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { chainId } = useParams<{ chainId: string }>();
  const userId = useAppSelector((state) => state.user.userId);
  const dispatch = useAppDispatch();
  const dayScheduleList = useAppSelector((state) =>
    state.daySchedules.items.filter((i) => i.status === 'active')
  );
  const [submitButtonVisible, setSubmitButtonVisible] = useState(false);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const alertRef = useRef<AlertType>(null);
  const [weekDayIds, setWeekDayIds] = useState<{ [key: string]: number }>({});
  const [isUnavilableScheduleAvailable, setIsUnavilableScheduleAvailable] = useState(false);
  const [isInserted, setIsInserted] = useState(false);
  const unavailableSchedulesAlertRef = useRef<AlertType>(null);
  const assistanceSchedulesAlertRef = useRef<AlertType>(null);
  const [isDefault, setIsDefault] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [displaySchedules, setDisplaySchedules] = useState<ScheduleEvent[]>([]);

  const supportNumber = useAppSelector(
    (state) =>
      state.gymChain.currentGymChain?.supportPhoneNumber ||
      state.gymChain.currentGymChain?.phoneNumber
  );

  const providerId = useAppSelector((state) => state.gymChain.currentGymChain?.providerId);

  const stepperChangeHandler = useCallback(
    (state) => {
      props.setActiveIndex(state);
    },
    [props.setActiveIndex]
  );

  useEffect(() => {
    if (props.isDoorSchedule || providerId === 2) {
      const availableDoorSchedules: ScheduleEvent[] = [];

      if (props.schedules.length > 0) {
        props.schedules.forEach((e) => {
          (e.title = 'Schedule available'),
            (e.isAvailable = 'available'),
            (e.weekDayId = weekDayIds[dayjs(e.start).format('dddd').toString().toLowerCase()]),
            (e.status = 'active');
        });
      }
    }
  }, [props]);

  const dayScheduleMapping = (schedules: DaySchedule[]) => {
    const scheduleDictionary: { [key: string]: DaySchedule } = schedules.reduce(
      (prev, obj) => ({
        ...prev,
        [obj.startTime + '-' + obj.endTime + '-' + obj.weekdayName.toLowerCase()]: obj
      }),
      {}
    );

    return scheduleDictionary;
  };

  const getWeekdays = async () => {
    try {
      const response = (API.graphql({
        query: getAllWeekDays
      }) as Promise<{
        data: { getAllWeekDays: [getAllWeekDaysResponse] };
      }>).then((e) => {
        const weekDays: { [key: string]: number } = e.data.getAllWeekDays.reduce(
          (prev, obj) => ({
            ...prev,
            [obj.name?.toLowerCase() || '']: obj.id
          }),
          {}
        );
        setWeekDayIds(weekDays);
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const scheduleActivationNotificationDays = Number(
    useAppSelector(
      (state) =>
        state.platformSettings.find((setting) => {
          return setting.setting === 'scheduleActivationNotificationDays';
        })?.value || 0
    )
  );

  useEffect(() => {
    providerId === 1 &&
      dispatch(
        fetchDaySchedules({
          offset: 0,
          pageSize: 0,
          sortField: '',
          sortOrder: 1,
          search: { searchField: '', searchText: '' }
        })
      );
    dispatch(getGymChainById(+chainId));
    dispatch(fetchPlatformSettings());
    getWeekdays();
    createAlert(alertRef, {
      content: t('OPENING_HOURS_SCHEDULER.ALERT_MESSAGE_TOP'),
      sticky: true,
      closable: false,
      severity: 'info'
    });
  }, []);

  useEffect(() => {
    if (props.type === 'modify' && !props.isDoorSchedule) {
      findSchedules();
    }
    const isDraftScheduleAvailable = props.schedules.some((x) => {
      return x.isAvailable === 'draft' && props.schedules.length === 1;
    });
    if (isDraftScheduleAvailable) {
      setSubmitButtonVisible(false);
    }
  }, []);

  useEffect(() => {
    const isUnavailable = props.schedules.some((x) => {
      return x.isAvailable === 'unavailable' && x.scheduleStatusId != 3;
    });

    if (isUnavailable) {
      setIsUnavilableScheduleAvailable(true);
      createAlert(
        unavailableSchedulesAlertRef,
        {
          content: t('OPENING_HOURS_SCHEDULER.ALERT_MESSAGE_UNSCHEDULED_SCHEDULES'),
          sticky: true,
          closable: false,
          severity: 'warn'
        },
        'replace'
      );
      if (dayjs(props.scheduleDate).diff(dayjs(), 'day') < scheduleActivationNotificationDays) {
        createAlert(
          assistanceSchedulesAlertRef,
          {
            content: `${t(
              'OPENING_HOURS_SCHEDULER.ALERT_MESSAGE_ASSISTANCE_PREFIX'
            )} ${supportNumber} ${t('OPENING_HOURS_SCHEDULER.ALERT_MESSAGE_ASSISTANCE_SUFFIX')}`,
            sticky: true,
            closable: false,
            severity: 'error'
          },
          'replace'
        );
      }
    } else {
      setIsUnavilableScheduleAvailable(false);
      unavailableSchedulesAlertRef.current?.clear();
      assistanceSchedulesAlertRef.current?.clear();
    }
  }, [props.schedules]);

  const findSchedules = () => {
    // setIsDefault(false);
    setIsUnavilableScheduleAvailable(false);
    const mappedDaySchedules = dayScheduleMapping(dayScheduleList);
    const availableSchedules: ScheduleEvent[] = [];

    props.schedules.forEach((obj) => {
      const key =
        dayjs(obj.start).format('HH:mm').toString() +
        '-' +
        dayjs(obj.end).format('HH:mm').toString() +
        '-' +
        dayjs(obj.start).format('dddd').toString().toLowerCase();
      if (obj.isAvailable != 'draft') {
        if (Object.keys(mappedDaySchedules).includes(key)) {
          availableSchedules.push({
            dayScheduleId: mappedDaySchedules[key].id,
            title: 'Schedule available',
            start: obj.start,
            end: obj.end,
            isAvailable: 'available',
            weekDayId: mappedDaySchedules[key].weekdayId,
            status: 'active'
          });
        } else {
          setIsUnavilableScheduleAvailable(true);
          availableSchedules.push({
            title: 'Schedule unavailable',
            start: obj.start,
            end: obj.end,
            isAvailable: 'unavailable',
            status: 'inactive',
            weekDayId: weekDayIds[dayjs(obj.start).format('dddd').toString().toLowerCase()]
          });
        }
      } else {
        setSubmitButtonVisible(false);
        availableSchedules.push({
          title: '',
          start: obj.start,
          end: obj.end,
          isAvailable: 'draft',
          status: 'inactive',
          weekDayId: weekDayIds[dayjs(obj.start).format('dddd').toString().toLowerCase()]
        });
      }
    });

    props.setSchedules(availableSchedules);

    const isUnavailable = availableSchedules.some((x) => {
      return x.isAvailable === 'unavailable';
    });

    if (isUnavailable) {
      createAlert(
        unavailableSchedulesAlertRef,
        {
          content: t('OPENING_HOURS_SCHEDULER.ALERT_MESSAGE_UNSCHEDULED_SCHEDULES'),
          sticky: true,
          closable: false,
          severity: 'warn'
        },
        'replace'
      );
      if (dayjs(props.scheduleDate).diff(dayjs(), 'day') < scheduleActivationNotificationDays) {
        createAlert(
          assistanceSchedulesAlertRef,
          {
            content: `${t(
              'OPENING_HOURS_SCHEDULER.ALERT_MESSAGE_ASSISTANCE_PREFIX'
            )} ${supportNumber} ${t('OPENING_HOURS_SCHEDULER.ALERT_MESSAGE_ASSISTANCE_SUFFIX')}`,
            sticky: true,
            closable: false,
            severity: 'error'
          },
          'replace'
        );
      }
    } else {
      unavailableSchedulesAlertRef.current?.clear();
      assistanceSchedulesAlertRef.current?.clear();
    }
  };

  useEffect(() => {
    if (props.type === 'modify' && props.schedules.length > 0) {
      const isUnavailable = props.schedules.some((x) => {
        return x.isAvailable === 'unavailable' && x.scheduleStatusId != 3;
      });

      if (isUnavailable) {
        setIsUnavilableScheduleAvailable(true);
        createAlert(
          unavailableSchedulesAlertRef,
          {
            content: t('OPENING_HOURS_SCHEDULER.ALERT_MESSAGE_UNSCHEDULED_SCHEDULES'),
            sticky: true,
            closable: false,
            severity: 'warn'
          },
          'replace'
        );
        if (dayjs(props.scheduleDate).diff(dayjs(), 'day') < scheduleActivationNotificationDays) {
          createAlert(
            assistanceSchedulesAlertRef,
            {
              content: `${t(
                'OPENING_HOURS_SCHEDULER.ALERT_MESSAGE_ASSISTANCE_PREFIX'
              )} ${supportNumber} ${t('OPENING_HOURS_SCHEDULER.ALERT_MESSAGE_ASSISTANCE_SUFFIX')}`,
              sticky: true,
              closable: false,
              severity: 'error'
            },
            'replace'
          );
        }
      } else {
        setIsUnavilableScheduleAvailable(false);
        unavailableSchedulesAlertRef.current?.clear();
        assistanceSchedulesAlertRef.current?.clear();
      }
    }
  }, [props.type, props.schedules]);

  const removeScheduleDetails = async (eventId?: number) => {
    return API.graphql({
      query: removeSiteScheduleDetailRecord,
      variables: {
        id: eventId,
        userId: userId,
        siteId: +chainId
      }
    });
  };

  const modifyScheduleStatusId = async (scheduleStatusId: number) => {
    try {
      await (API.graphql({
        query: updateScheduleStatusId,
        variables: {
          siteScheduleId: props.scheduleId,
          scheduleStatusId: scheduleStatusId,
          userId: userId,
          siteId: chainId
        }
      }) as Promise<{
        data: { updateScheduleStatusId: number };
      }>);
    } catch (error) {
      console.log('modify schedule statusId error: ', error);
    }
  };

  const modifyScheduleStatus = async (status: string) => {
    try {
      await (API.graphql({
        query: updateScheduleStatus,
        variables: {
          siteScheduleId: props.scheduleId,
          status: status,
          userId: userId,
          siteId: chainId
        }
      }) as Promise<{
        data: { updateScheduleStatus: number };
      }>);
    } catch (error) {
      console.log('modify schedule status error: ', error);
    }
  };

  const handleScheduleStatus = async () => {
    if (props.schedules.some((a) => a.isAvailable === 'unavailable')) {
      await modifyScheduleStatusId(
        props.scheduleStatuses.find(
          (a) => a.name === ScheduleInternalStatus.PendingScheduleAllocation
        )?.id || -1
      );
      await modifyScheduleStatus(ScheduleInternalStatus.Inactive);
    } else {
      await modifyScheduleStatusId(
        props.scheduleStatuses.find((a) => a.name === ScheduleInternalStatus.Active)?.id || -1
      );
      await modifyScheduleStatus(ScheduleInternalStatus.Active);
    }
  };

  const addScheduleDetailsRecord = async (details: ScheduleEvent) => {
    const addScheduleDetailsInput: SiteScheduleDetailsInput = {
      siteScheduleId: props.scheduleId,
      weekdayId: details.weekDayId || 0,
      startTime: dayjs(details.start || '')
        .format('YYYY-MM-DD HH:mm:ss')
        .toString(),
      endTime: dayjs(details.end || '')
        .format('YYYY-MM-DD HH:mm:ss')
        .toString(),
      dayScheduleId: details.dayScheduleId,
      createdBy: userId,
      lastModifiedBy: userId,
      status: details.status || '',
      isRequested: details.isAvailable === 'unavailable' ? true : false
    };

    return API.graphql({
      query: addSiteScheduleDetails,
      variables: {
        input: addScheduleDetailsInput
      }
    });
  };

  const addScheduleDetails = async (schedules: ScheduleEvent[]) => {
    const siteSchedulePromises: Promise<any>[] = [];

    schedules.forEach(async (element) => {
      siteSchedulePromises.push(addScheduleDetailsRecord(element));
    });

    try {
      await Promise.all(siteSchedulePromises);
    } catch (error) {
      console.log(error);
    }
  };

  const modifyAvailableScheduleDetails = async (
    scheduleId: number,
    userId: number,
    dayScheduleId: number
  ) => {
    try {
      await API.graphql({
        query: resolveScheduleRequest,
        variables: {
          id: scheduleId,
          userId: userId,
          dayScheduleId: dayScheduleId
        }
      });
    } catch (err) {
      // setFailedDialogVisible(true);
      console.log('Add Day Schedule Error: ', err);
    }
  };

  const modifyScheduleDetails = async (schedules: ScheduleEvent[]) => {
    const addedEvents: ScheduleEvent[] = [];

    schedules.forEach((e) => {
      const matchingRecord = props.defaultValues?.find((i) => {
        return dayjs(i.start).isSame(e.start) && dayjs(i.end).isSame(e.end);
      });
      if (!matchingRecord) {
        addedEvents.push(e);
      }
    });

    const removedEvents: ScheduleEvent[] = [];

    props.defaultValues?.forEach((e) => {
      const matchingRecord = schedules.find((i) => {
        return dayjs(e.start).isSame(i.start) && dayjs(e.end).isSame(i.end);
      });
      if (!matchingRecord) {
        removedEvents.push(e);
      }
    });

    if (addedEvents) {
      addScheduleDetails(addedEvents);
    }
    if (removedEvents) {
      const removedEventsPromises: Promise<any>[] = [];
      removedEvents.forEach((scheduledEvent) => {
        removedEventsPromises.push(removeScheduleDetails(scheduledEvent.id));
      });
      try {
        await Promise.all(removedEventsPromises);
      } catch (error) {
        console.log(error);
      }
    }

    props.defaultValues?.forEach((e) => {
      const matchingRecord = schedules.find((i) => {
        return (
          e.isAvailable === 'unavailable' &&
          i.isAvailable === 'available' &&
          dayjs(e.start).format('dddd') === dayjs(i.start).format('dddd') &&
          dayjs(e.end).format('dddd') === dayjs(i.end).format('dddd') &&
          dayjs(e.start).format('HH') === dayjs(i.start).format('HH') &&
          dayjs(e.end).format('HH') === dayjs(i.end).format('HH') &&
          dayjs(e.start).format('mm') === dayjs(i.start).format('mm') &&
          dayjs(e.end).format('mm') === dayjs(i.end).format('mm')
        );
      });

      if (matchingRecord) {
        const r = e.id || 0;
        const p = matchingRecord.dayScheduleId || 0;
        modifyAvailableScheduleDetails(r, userId, p);
      }
    });
  };

  const onSubmitHandler = async () => {
    setIsInserted(true);

    const updateScheduleStatusPromises: Promise<any>[] = [];

    updateScheduleStatusPromises.push(handleScheduleStatus());

    try {
      if (props.type === 'new') {
        addScheduleDetails(props.schedules);
      } else if (props.type === 'modify') {
        modifyScheduleDetails(props.schedules);
      }

      await Promise.all(updateScheduleStatusPromises);
      history.push(`/gymChains/${chainId}/gymSchedules`);
      setIsInserted(false);
    } catch (error) {
      console.log(error);
      setFailedDialogVisible(true);
    }
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t('OPENING_HOURS_SCHEDULER.FORM_FAILURE_DIALOG_HEADER')}
          dialogFooter={
            <Button
              label={t('OPENING_HOURS_SCHEDULER.FORM_FAILURE_DIALOG_BUTTON')}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t('OPENING_HOURS_SCHEDULER.FORM_FAILURE_DIALOG_BODY')}</Text>}
        />
      </div>
    );
  };

  useEffect(() => {
    setIsDefault(false);
    let hasNonVerifiedSchedule = false;
    props.schedules.forEach((schedule) => {
      if (!schedule.isAvailable) {
        hasNonVerifiedSchedule = true;
      }
    });

    const isDraftScheduleAvailable = props.schedules.some((x) => {
      return x.isAvailable === 'draft' && props.schedules.length === 1;
    });

    if (isDraftScheduleAvailable) {
      setIsDefault(true);
    }

    hasNonVerifiedSchedule
      ? setSubmitButtonVisible(false)
      : props.schedules.length === 0
      ? setSubmitButtonVisible(false)
      : isDraftScheduleAvailable
      ? setSubmitButtonVisible(false)
      : setSubmitButtonVisible(true);

    /* 
      Since the scheduler is a calendar,
      gym schedule start date and end date must be updated to current calendar week.
      Otherwise the scheduler won't display the schedule next week.
      */
    const mappedSchedules = props.schedules.map((schedule) => {
      const day = dayjs(schedule.start).day();
      const startTime = dayjs(schedule.start).format('HH:m:ss');
      const endTime = dayjs(schedule.end).format('HH:m:ss');
      return {
        ...schedule,
        start: new Date(dayjs().weekday(day).format('YYYY-MM-DD ') + startTime),
        end: new Date(dayjs().weekday(day).format('YYYY-MM-DD ') + endTime)
      };
    });
    setDisplaySchedules(mappedSchedules);
  }, [props.schedules]);

  return (
    <div>
      <Alert refProp={alertRef} />
      <div className="mt30 mb30">
        <Scheduler
          events={displaySchedules}
          setEvents={props.setSchedules}
          maxHour={23}
          maxMinutes={59}
          minHour={0}
        />
      </div>
      {props.schedules.length > 0 && <Alert refProp={unavailableSchedulesAlertRef} />}
      {props.schedules.length > 0 && <Alert refProp={assistanceSchedulesAlertRef} />}
      <div>
        <div className="row mt40">
          <div className="col-12">
            <div className="d-flex btn-min-w-110">
              <div className="mr15">
                <Button
                  label={t('OPENING_HOURS_SCHEDULER.FORM_BTN_LABEL_PREVIOUS')}
                  size={ButtonSizes.medium}
                  onClick={() => {
                    stepperChangeHandler(props.activeIndex - 1);
                    setSubmitButtonVisible(false);
                  }}
                  disabled={isInserted}
                />
              </div>
              {submitButtonVisible && (
                <div className="mr15">
                  <Button
                    label={
                      isUnavilableScheduleAvailable && !props.isDoorSchedule
                        ? t('OPENING_HOURS_SCHEDULER.FORM_BTN_LABEL_SAVE')
                        : t('OPENING_HOURS_SCHEDULER.FORM_BTN_LABEL_COMPLETE')
                    }
                    size={ButtonSizes.medium}
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                    onClick={onSubmitHandler}
                    disabled={isInserted}
                  />
                </div>
              )}
              {!submitButtonVisible && (
                <div className="mr15">
                  <Button
                    label={t('OPENING_HOURS_SCHEDULER.FIND_SCHEDULES_BUTTON')}
                    onClick={findSchedules}
                    disabled={props.schedules.length === 0 || isDefault}
                  />
                </div>
              )}
              <Button
                label={t('OPENING_HOURS_SCHEDULER.FORM_BTN_LABEL_CANCEL')}
                variant={ButtonVariants.textonly}
                onClick={() => history.push(`/gymChains/${chainId}/gymSchedules`)}
                disabled={isInserted}
              />
            </div>
          </div>
        </div>
      </div>
      <ActionFailedDialog />
    </div>
  );
};

type GymSchedulesSchedulerType = 'new' | 'modify';

export interface GymSchedulesOpeningHourProps {
  type: GymSchedulesSchedulerType;
  activeIndex: number;
  setActiveIndex: (e: number) => void;
  scheduleId: number;
  schedules: ScheduleEvent[];
  setSchedules: (e: ScheduleEvent[]) => void;
  scheduleStatuses: GymScheduleStatus[];
  scheduleStatusId: number;
  setScheduleStatusId: (e: number) => void;
  defaultValues?: ScheduleEvent[];
  isDoorSchedule: boolean;
  scheduleDate?: Date;
}

export default GymSchedulesScheduler;
