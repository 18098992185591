import { getAllRoles } from '@gym-graphql/queries';
import { Role } from '@gym-particles/types/User';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

const initialState: Array<Role> = [];

export const fetchAllRoles = createAsyncThunk('/userRoles/fetchAllRoles', async () => {
  const response = await (API.graphql({
    query: getAllRoles
  }) as Promise<{
    data: { getAllRoles: Role[] };
  }>);
  return response;
});

export const userRolesSlice = createSlice({
  name: 'userRoles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllRoles.fulfilled, (state, action) => {
      if (action.payload.data.getAllRoles === null) {
        return;
      }
      state = action.payload.data.getAllRoles;
      return state;
    });
  }
});

export default userRolesSlice.reducer;
