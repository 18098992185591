import { useTranslation } from 'react-i18next';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { useRef } from 'react';
import GymAccessPointsTemplate from '@gym-templates/AccessPoints/GymAccessPointsTemplate';
import { AccessPoints } from '@gym-particles/types/models';

const AccessPointsForm = (props: AccessPointsTemplateProps) => {
  const alertRef = useRef<AlertType>(null);
  const { t } = useTranslation();
  const { type, accessPointsData, templateType, providerId } = props;

  return (
    <GymAccessPointsTemplate
      type={type}
      heading={t(`${type === 'new' ? 'ADD_NEW' : 'MODIFY'}_ACCESS_POINT.PAGE_TITLE`)}
      accessPoints={accessPointsData}
      templateType={templateType}
      providerId={providerId}
    />
  );
};

export default AccessPointsForm;

type AccessPointsTemplateType = 'new' | 'modify';

export interface AccessPointsTemplateProps {
  type: AccessPointsTemplateType;
  accessPointsData?: AccessPoints;
  templateType?: (e: boolean) => void;
  providerId: number | null | undefined;
}
