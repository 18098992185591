export type KisiDoorsFormInput = {
  kisiDoorId: number;
  kisiDoorName: string;
  kisiPlaceId: number;
  kisiPlaceName: string;
  createdBy: number;
  lastModifiedBy: number;
};

export type KisiDoors = {
  id: number;
  siteId: number;
  siteLocationId: number;
  kisiDoorId: number;
  kisiDoorName: string;
  kisiPlaceId: number;
  kisiPlaceName: string;
  createdBy: number;
  createdDate?: string;
  lastModifiedBy: number;
  lastModifiedDate?: string;
};

export const KisiDoorsDefaultValues = {
  id: 0,
  siteId: 0,
  siteLocationId: 0,
  kisiDoorId: 0,
  kisiDoorName: '',
  kisiPlaceId: 0,
  kisiPlaceName: '',
  createdBy: 0,
  createdDate: '',
  lastModifiedBy: 0,
  lastModifiedDate: 'string'
};
