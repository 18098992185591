export const GymDetailsHeaderRoutes = {
  dashboard: 0,
  members: 1,
  memberships: 2,
  zones: 3,
  accesspoints: 4,
  holidays: 5,
  openinghours: 6
};

export type GymDetailsHeaderTabNames = keyof typeof GymDetailsHeaderRoutes;
