import { AlertType } from '@gym-atoms/Alert/Alert';
import AccessPointsForm from '@gym-organisms/AccessPoints/AccessPointsForm';
import AccessPointsList from '@gym-organisms/AccessPoints/AccessPointsList';
import { AccessPoints } from '@gym-particles/types/models';
import { UserRole } from '@gym-particles/types/User';
import { useAppSelector } from '@gym-redux/store';
import { Card } from 'primereact/card';
import React, { RefObject, useState } from 'react';
import { useParams } from 'react-router-dom';

const AccessPointsPage = (props: { alertRef: RefObject<AlertType> }) => {
  const { gymId, chainId } = useParams<{ gymId: string; chainId: string }>();
  const [isModifyTemplate, setIsModifyTemplate] = useState(false);
  const [accessPoint, setAccessPoint] = useState<AccessPoints>();
  const providerId = useAppSelector(
    (state) => state.gymChain.items.find((gc) => gc.id === +chainId)?.providerId
  );
  const userRole = useAppSelector((state) => state.user.userRole);

  return (
    <>
      {(userRole === UserRole.SYSTEM_ADMIN ||
        userRole === UserRole.GYM_USER ||
        userRole === UserRole.GYM_CHAIN_USER) && (
        <Card className="p-card-w-table">
          <AccessPointsForm
            type={isModifyTemplate ? 'modify' : 'new'}
            accessPointsData={isModifyTemplate ? accessPoint : undefined}
            templateType={setIsModifyTemplate}
            providerId={providerId}
          />
        </Card>
      )}
      <Card className="p-card-w-table">
        <AccessPointsList
          gymId={+gymId}
          providerId={providerId}
          templateType={setIsModifyTemplate}
          templateZone={setAccessPoint}
        />
      </Card>
    </>
  );
};

export default AccessPointsPage;
