import { Steps } from 'primereact/steps';
import { MenuItem } from 'primereact/components/menuitem/MenuItem';

const Stepper = ({ activeIndex = 0, readonly = true, ...props }: StepperProps) => {
  return (
    <Steps
      model={props.items}
      activeIndex={activeIndex}
      onSelect={(e) => props.setActiveIndex(e.index)}
      readOnly={readonly}
    />
  );
};

export interface StepperProps {
  /** items of the stepper */
  items: MenuItem[];
  /** active index */
  activeIndex: number;
  /** function to set active index */
  setActiveIndex: (e: number) => void;
  /** property to allow onclick on a step */
  readonly?: boolean;
}
export default Stepper;
