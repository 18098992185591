import { Dialog } from 'primereact/dialog';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import DateTimePicker, {
  DateTimePickerChangeEvent
} from '@gym-atoms/DateTimePicker/DateTimePicker';
import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Checkbox, { CheckboxChangeEvent } from '@gym-atoms/Checkbox/Checkbox';
import dayjs from 'dayjs';
import { API } from 'aws-amplify';
import { getForcedAccess, getProviderForGym } from '@gym-graphql/queries';
import { expireForcedAccess, giveForcedAccess } from '@gym-graphql/mutations';
import { useAppSelector } from '@gym-redux/store';
import { ForcedAccess, ForcedAccessModalInput } from '@gym-particles/types/TemporaryAccess';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { GiveForcedAccessInput } from '@gym-src/API';
import { ProviderType } from '@gym-particles/types/ProviderType';

const TemporaryAccessModal = (props: TemporaryAccessModalProps) => {
  const defaultTimeValue = {
    startDate: undefined,
    startTime: undefined,
    endDate: undefined,
    endTime: undefined
  };
  const [dateTime, setDateTime] = useState<ForcedAccessModalInput>(defaultTimeValue);
  const [indefiniteChecked, setIndefiniteChecked] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [submitButtonEnable, setSubmitButtonEnable] = useState(false);
  const userId = useAppSelector((state) => state.user.userId);
  const [isInserted, setIsInserted] = useState(false);
  const { t } = useTranslation();
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const [hasForcedAccess, setHasForcedAccess] = useState(false);
  const [expireForcedAccessDialogVisible, setExpireForcedAccessDialogVisible] = useState(false);
  const [overrideLocationDetection, setOverrideLocationDetection] = useState(false);
  const [overrideClientStatuses, setOverrideAccessRules] = useState(false);
  const [providerInfo, setProviderInfo] = useState<ProviderType>();

  const formatDateAndTime = (time: Date | undefined, date: Date | undefined) => {
    return dayjs(date).format('YYYY-MM-DD') + ' ' + dayjs(time).format('HH:mm');
  };

  useEffect(() => {
    setHasError(false);
    getForcedAccesssData();
    getProvider();
  }, [props.memberId]);

  const provideAccessShowHide = () => {
    if (indefiniteChecked && dateTime.startDate && dateTime.startTime) {
      if (providerInfo?.providerId === 1 && (overrideLocationDetection || overrideClientStatuses)) {
        errorHandler();
        setSubmitButtonEnable(true);
      } else {
        errorHandler();
        setSubmitButtonEnable(false);
      }
      if (providerInfo?.providerId === 2) {
        errorHandler();
        setSubmitButtonEnable(true);
      }
    } else if (
      !indefiniteChecked &&
      dateTime.startDate &&
      dateTime.startTime &&
      dateTime.endDate &&
      dateTime.endTime
    ) {
      if (providerInfo?.providerId === 1 && (overrideLocationDetection || overrideClientStatuses)) {
        errorHandler();
        setSubmitButtonEnable(true);
      } else {
        errorHandler();
        setSubmitButtonEnable(false);
      }
      if (providerInfo?.providerId === 2) {
        errorHandler();
        setSubmitButtonEnable(true);
      }
    } else {
      setSubmitButtonEnable(false);
    }
  };

  useEffect(() => {
    provideAccessShowHide();
  }, [
    indefiniteChecked,
    overrideLocationDetection,
    overrideClientStatuses,
    providerInfo?.providerId,
    dateTime
  ]);

  const errorHandler = () => {
    setHasError(false);
    if (indefiniteChecked && dateTime.startDate && dateTime.startTime) {
      setHasError(false);
    } else if (
      !indefiniteChecked &&
      dateTime.startDate &&
      dateTime.startTime &&
      dateTime.endDate &&
      dateTime.endTime &&
      dayjs(dateTime.startDate).isAfter(dateTime.endDate)
    ) {
      setHasError(true);
    } else if (
      !indefiniteChecked &&
      dateTime.startDate &&
      dateTime.startTime &&
      dateTime.endDate &&
      dateTime.endTime &&
      dayjs(dateTime.startDate).isSame(dateTime.endDate, 'day') &&
      dayjs(dateTime.endTime).diff(dayjs(dateTime.startTime), 'm') <= 0
    ) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const getProvider = async () => {
    try {
      const response = await (API.graphql({
        query: getProviderForGym,
        variables: {
          siteLocationId: props.gymId
        }
      }) as Promise<{
        data: { getProviderForGym: ProviderType };
      }>);

      setProviderInfo(response.data.getProviderForGym);
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const getForcedAccesssData = async () => {
    try {
      const response = await (API.graphql({
        query: getForcedAccess,
        variables: {
          memberId: props.memberId,
          siteLocationId: props.gymId
        }
      }) as Promise<{
        data: { getForcedAccess: ForcedAccess };
      }>);

      if (response.data.getForcedAccess !== null) {
        setHasForcedAccess(true);
        setIndefiniteChecked(response.data.getForcedAccess.isIndefinite);
        setOverrideLocationDetection(response.data.getForcedAccess.overrideLocationDetection);
        setOverrideAccessRules(response.data.getForcedAccess.overrideClientStatuses);
        if (response.data.getForcedAccess.isIndefinite) {
          setDateTime({
            startDate: new Date(response.data.getForcedAccess.startingTime),
            startTime: new Date(response.data.getForcedAccess.startingTime)
          });
        } else {
          setDateTime({
            startDate: new Date(response.data.getForcedAccess.startingTime),
            startTime: new Date(response.data.getForcedAccess.startingTime),
            endDate: new Date(response.data.getForcedAccess.endingTime),
            endTime: new Date(response.data.getForcedAccess.endingTime)
          });
        }
      } else {
        setHasForcedAccess(false);
        setDateTime(defaultTimeValue);
        setIndefiniteChecked(false);
        setOverrideLocationDetection(false);
        setOverrideAccessRules(false);
      }
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const provideForcedAccess = async () => {
    setIsInserted(true);
    const forcedAccessInput: GiveForcedAccessInput = {
      memberId: props.memberId,
      userId: userId,
      siteLocationId: props.gymId,
      memberLocationId: props.memberLocationId,
      startingTime: formatDateAndTime(dateTime.startTime, dateTime.startDate),
      endingTime: indefiniteChecked ? '' : formatDateAndTime(dateTime.endTime, dateTime.endDate),
      isIndefinite: indefiniteChecked ? true : false,
      overrideClientStatuses: overrideClientStatuses,
      overrideLocationDetection: overrideLocationDetection
    };

    try {
      const response = await (API.graphql({
        query: giveForcedAccess,
        variables: {
          input: forcedAccessInput
        }
      }) as Promise<{
        data: { giveForcedAccess: number };
      }>);
      setIsInserted(false);
    } catch (error) {
      setIsInserted(false);
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const expireForcedAccessEntry = async () => {
    try {
      const response = await API.graphql({
        query: expireForcedAccess,
        variables: {
          memberId: props.memberId,
          userId: userId,
          siteLocationId: props.gymId
        }
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const submitHandler = async () => {
    if (!hasError) {
      await provideForcedAccess();
      setIsInserted(false);
      setDateTime(defaultTimeValue);
      setIndefiniteChecked(false);
      setOverrideLocationDetection(false);
      setOverrideAccessRules(false);
      props.onHideCallback();
    }
    setIsInserted(false);
  };

  const cancelHandler = () => {
    setDateTime(defaultTimeValue);
    setIndefiniteChecked(false);
    setOverrideLocationDetection(false);
    setOverrideAccessRules(false);
    props.onHideCallback();
  };

  const checkBoxChangeHandler = (e: CheckboxChangeEvent) => {
    switch (e.target.name) {
      case 'indefiniteAccess':
        setIndefiniteChecked(e.checked);
        break;
      case 'overrideLocationDetection':
        setOverrideLocationDetection(e.checked);
        break;
      case 'overrideAccessRules':
        setOverrideAccessRules(e.checked);
        break;
    }
  };

  const dateTimeChangeHandler = (e: DateTimePickerChangeEvent) => {
    const DateObj: ForcedAccessModalInput = {
      ...dateTime,
      [e.target.name]: e.value
    };
    setDateTime(DateObj);
  };

  const displayErrorMessageHandler = () => {
    return (
      <Text className="p-error mb15">{t('TEMPORARY_ACCESS_MODAL.INVALID_DATE_TIME_ERROR')}</Text>
    );
  };

  const ActionFailedDialog = (
    <div>
      <DialogBox
        variant={DialogBoxVariants.basic}
        dialogVisible={failedDialogVisible}
        onHideCallback={() => setFailedDialogVisible(false)}
        dialogHeader={t('TEMPORARY_ACCESS_MODAL.FORM_FAILURE_DIALOG_HEADER')}
        dialogFooter={
          <Button
            label={t('TEMPORARY_ACCESS_MODAL.FORM_FAILURE_DIALOG_BUTTON')}
            onClick={() => setFailedDialogVisible(false)}
          />
        }
        dialogDismissableMask={true}
        dialogClosable={false}
        content={<Text>{t('TEMPORARY_ACCESS_MODAL.FORM_FAILURE_DIALOG_BODY')}</Text>}
      />
    </div>
  );

  const ExpireForcedAccessDialogFooter = () => {
    const [isExpired, setIsExpired] = useState(false);

    const expireForcedAccessHandler = async () => {
      setIsExpired(true);
      await expireForcedAccessEntry();
      setDateTime(defaultTimeValue);
      setIndefiniteChecked(false);
      setOverrideLocationDetection(false);
      setOverrideAccessRules(false);
      setIsExpired(false);
      setExpireForcedAccessDialogVisible(false);
    };

    return (
      <div>
        <Button
          label={t('COMMON.CANCEL')}
          variant={ButtonVariants.textonly}
          onClick={() => setExpireForcedAccessDialogVisible(false)}
        />
        <Button
          label={t('TEMPORARY_ACCESS_MODAL.CANCEL_CONFIRMATION_BUTTON')}
          variant={ButtonVariants.danger}
          onClick={expireForcedAccessHandler}
          icon={isExpired ? 'pi-spinner pi-spin' : ''}
        />
      </div>
    );
  };

  const ExpireForcedAccessConfirmationDialog = (
    <div>
      <DialogBox
        variant={DialogBoxVariants.basic}
        dialogVisible={expireForcedAccessDialogVisible}
        onHideCallback={() => setExpireForcedAccessDialogVisible(false)}
        dialogHeader={t('COMMON.CANCEL')}
        dialogFooter={<ExpireForcedAccessDialogFooter />}
        dialogDismissableMask={true}
        dialogClosable={false}
        content={<Text>{t('TEMPORARY_ACCESS_MODAL.CANCEL_MODAL_CONFIRM')}</Text>}
      />
    </div>
  );

  const dialogFooter = (
    <div>
      <Button
        variant={ButtonVariants.textonly}
        label={t('COMMON.CANCEL')}
        onClick={cancelHandler}
        disabled={isInserted}
      />
      <Button
        variant={ButtonVariants.basic}
        label={t('TEMPORARY_ACCESS_MODAL.PROVIDE_ACCESS_BUTTON')}
        onClick={submitHandler}
        disabled={!submitButtonEnable}
        icon={isInserted ? 'pi-spinner pi-spin' : ''}
      />
      <Button
        variant={ButtonVariants.danger}
        label={t('TEMPORARY_ACCESS_MODAL.CANCEL_ACCESS_DIALOG_BUTTON')}
        onClick={() => setExpireForcedAccessDialogVisible(true)}
        disabled={!hasForcedAccess}
      />
    </div>
  );

  const content = (
    <div>
      <Text size={TextSizes.t14} color="gray">
        {t('TEMPORARY_ACCESS_MODAL.DESCRIPTION_TEXT')}
      </Text>
      <br />

      <div className="row mb25">
        <div className="col-md-6">
          <DateTimePicker
            variant="date"
            name="startDate"
            placeholder={'DD:MM:YYYY'}
            label={t('TEMPORARY_ACCESS_MODAL.START_DATE_TIME_LABEL')}
            onChange={dateTimeChangeHandler}
            value={dateTime.startDate}
          />
        </div>
        <div className="col-md-6">
          <div className="no-label no-label-datepicker">
            <DateTimePicker
              variant="time"
              name="startTime"
              placeholder={'12:00 AM'}
              onChange={dateTimeChangeHandler}
              value={dateTime.startTime}
            />
          </div>
        </div>
      </div>
      <div className="row mb25">
        <div className="col-md-6">
          <DateTimePicker
            variant="date"
            name="endDate"
            placeholder={'DD:MM:YYYY'}
            label={t('TEMPORARY_ACCESS_MODAL.END_DATE_TIME_LABEL')}
            onChange={dateTimeChangeHandler}
            value={dateTime.endDate}
            disabled={indefiniteChecked}
          />
          {hasError ? displayErrorMessageHandler() : <></>}
        </div>
        <div className="col-md-6">
          <div className="no-label no-label-datepicker">
            <DateTimePicker
              variant="time"
              name="endTime"
              placeholder={'12:00 AM'}
              onChange={dateTimeChangeHandler}
              value={dateTime.endTime}
              disabled={indefiniteChecked}
            />
          </div>
        </div>
      </div>
      <div className="mb25" hidden>
        <Checkbox
          label={t('TEMPORARY_ACCESS_MODAL.CHECK_BOX_LABEL')}
          value={'checked'}
          checked={indefiniteChecked}
          onChange={checkBoxChangeHandler}
          name="indefiniteAccess"
        />
      </div>
      {providerInfo?.providerId === 1 && (
        <>
          <div className="mb25">
            <Checkbox
              label={t('TEMPORARY_ACCESS_MODAL.OVERRIDE_LOCATION_DETECTION')}
              value={'checked'}
              checked={overrideLocationDetection}
              onChange={checkBoxChangeHandler}
              name="overrideLocationDetection"
            />
          </div>
          <div className="mb25">
            <Checkbox
              label={t('TEMPORARY_ACCESS_MODAL.OVERRIDE_ACCESS_RULES')}
              value={'checked'}
              checked={overrideClientStatuses}
              onChange={checkBoxChangeHandler}
              name="overrideAccessRules"
            />
          </div>
        </>
      )}
    </div>
  );
  return (
    <>
      <Dialog
        visible={props.visible}
        onHide={props.onHideCallback}
        footer={dialogFooter}
        closable={true}
        header={t('TEMPORARY_ACCESS_MODAL.DIALOG_HEADER')}
        dismissableMask={false}
        className={'temporary-access-modal'}>
        {content}
      </Dialog>
      {ActionFailedDialog}
      {ExpireForcedAccessConfirmationDialog}
    </>
  );
};

export interface TemporaryAccessModalProps {
  /** Modal variants e.g. assign, close */
  visible: boolean;
  /** Modal onHideCallback */
  onHideCallback: () => void;
  /** SiteLocation Id */
  gymId: number;
  /** Member Id */
  memberId: number;
  /** MemberLocation Id */
  memberLocationId: number;
}

export default TemporaryAccessModal;
