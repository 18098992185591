import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { AlertType } from '@gym-atoms/Alert/Alert';
import DashboardCounts from '@gym-molecules/DashboardCounts/DashboardCounts';
import TabView from '@gym-atoms/TabView/TabView';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Text from '@gym-atoms/Text/Text';
import { GymChain } from '@gym-particles/types/models';
import { UserRole } from '@gym-particles/types/User';
import { useAppSelector, useAppDispatch } from '@gym-redux/store';
import {
  deleteGymChain,
  fetchGymChains,
  fetchSidebarGymChains
} from '@gym-redux/slices/gymChainSlice';
import Loader from '@gym-atoms/Loader/Loader';
import { API } from 'aws-amplify';
import { isGymChainDeletable } from '@gym-graphql/queries';
import { DeletableResponse } from '@gym-src/API';
import { removeStaffAccount } from '@gym-graphql/mutations';

const MainColumn = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedRow, setSelectedRow] = useState<GymChain>();
  const gymChains = useAppSelector((state) => state.gymChain.items);
  const count = useAppSelector((state) => state.gymChain.totalRecords);
  const dispatch = useAppDispatch();
  const [sortField, setSortField] = useState('createdDate');
  const [sortOrder, setSortOrder] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [gymDeletableCheckLoading, setGymDeletableCheckLoading] = useState(false);
  const [gymDeletableStatus, setGymDeletableStatus] = useState(false);
  const isGymChainsLoading = useAppSelector((state) => state.gymChain.isGymchainsLoading);
  const [searchTerm, setSearchTerm] = useState('');
  const userId = useAppSelector((state) => state.user.userId);
  const [isDeleted, setIsDeleted] = useState(false);
  const userRole = useAppSelector((state) => state.user.userRole);

  useEffect(() => {
    dispatch(
      fetchGymChains({
        offset: searchTerm !== '' ? 0 : offset,
        pageSize: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        search: { searchField: '', searchText: searchTerm },
        userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
      })
    );
  }, [sortField, sortOrder, offset, pageSize, searchTerm, userId]);

  const tableProps: TableProps<GymChain> = {
    onRowClick: (e) => history.push(`gymChains/${e}/activity`),
    exportFileName: 'GymChainList',
    setPageSize: setPageSize,
    setOffset: setOffset,
    pageSize: pageSize,
    offset: offset,
    setSortField: setSortField,
    setSortOrder: setSortOrder,
    sortField: sortField,
    sortOrder: sortOrder,
    data: gymChains,
    totalRecords: count,
    isContextMenu: userRole === UserRole.SYSTEM_ADMIN ? true : false,
    enableRowClick: true,
    searchTerm: searchTerm,
    setSearchTerm: setSearchTerm,
    emptyStateTexts: [t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.EMPTY_STATE_TEXT')],
    columns: [
      {
        field: 'name',
        header: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.HEADER_NAME'),
        sortable: true,
        imageField: 'avatar',
        toggable: false,
        truncateText: true
      },
      {
        field: 'contactPerson',
        header: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.HEADER_CONTACT'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'phoneNumber',
        header: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.HEADER_PHONE'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'lastImportedDate',
        header: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.HEADER_LAST_IMPORT_TIME'),
        sortable: false,
        toggable: true
      },
      {
        field: 'displayStatus',
        header: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.HEADER_STATUS'),
        sortable: false,
        toggable: true
      },
      {
        field: 'createdDate',
        header: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.HEADER_CREATION_DATE'),
        sortable: false,
        toggable: true
      }
    ],
    searchPlaceholderText: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.SEARCH_PLACEHOLDER'),
    excelBtntext: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.BUTTON_EXPORT_LABEL'),
    headerBtns:
      userRole === UserRole.SYSTEM_ADMIN
        ? [
            <div key={1}>
              <Button
                data-cy="btn-add-new-gym-chain"
                icon="pi-plus"
                label={t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.BUTTON_ADD_LABEL')}
                onClick={() => history.push('/addGymChain')}
                size={ButtonSizes.small}
              />
            </div>
          ]
        : [],
    selectedRow: selectedRow,
    setSelectedRow: setSelectedRow,
    menuItem:
      userRole === UserRole.SYSTEM_ADMIN
        ? [
            {
              label: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.BUTTON_MODIFY'),
              command: () => history.push(`/gymChains/${selectedRow?.id}/edit`)
            },
            {
              label: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.BUTTON_DELETE'),
              command: () => checkGymChainDeletable()
            }
          ]
        : []
  };

  const deleteStaffAccount = async (chainId: number) => {
    try {
      const response = await API.graphql({
        query: removeStaffAccount,
        variables: {
          siteId: chainId,
          userId: userId
        }
      });
    } catch (error) {
      console.log('Remove Staff Account Error: ', error);
    }
  };

  const checkGymChainDeletable = async () => {
    setDialogVisible(true);
    setGymDeletableCheckLoading(true);
    try {
      const response = await (API.graphql({
        query: isGymChainDeletable,
        variables: {
          siteId: selectedRow?.id
        }
      }) as Promise<{
        data: { isGymChainDeletable: DeletableResponse[] };
      }>);

      response.data.isGymChainDeletable.length === 0
        ? setGymDeletableStatus(true)
        : setGymDeletableStatus(false);

      setGymDeletableCheckLoading(false);
    } catch (error) {
      console.log(error);
      setGymDeletableStatus(false);
      setGymDeletableCheckLoading(false);
    }
  };

  const deleteDialogHandler = async () => {
    setIsDeleted(true);
    selectedRow && dispatch(deleteGymChain(selectedRow.id));
    selectedRow && (await deleteStaffAccount(selectedRow.id));
    dispatch(fetchSidebarGymChains({ userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId }));
    setIsDeleted(false);
    setDialogVisible(false);
  };

  const deleteDialogFooter = (
    <div>
      {gymDeletableCheckLoading ? (
        <></>
      ) : !gymDeletableStatus ? (
        <Button
          label={t('COMMON.OK')}
          variant={ButtonVariants.basic}
          onClick={() => setDialogVisible(false)}
        />
      ) : (
        <>
          <Button
            data-cy={'dialog-btn-cancel'}
            label={t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.DELETE_MODAL_CANCEL')}
            variant={ButtonVariants.textonly}
            onClick={() => setDialogVisible(false)}
          />
          <Button
            data-cy={'dialog-btn-delete'}
            label={t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.DELETE_MODAL_DELETE')}
            variant={ButtonVariants.danger}
            onClick={deleteDialogHandler}
            icon={isDeleted ? 'pi-spinner pi-spin' : ''}
          />
        </>
      )}
    </div>
  );

  const GymChainsTable = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.long}
          dialogVisible={dialogVisible}
          onHideCallback={() => setDialogVisible(false)}
          dialogHeader={
            gymDeletableCheckLoading ? (
              <></>
            ) : !gymDeletableStatus ? (
              t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.DELETE_MODAL_HEADER_HAVE_GYMS')
            ) : (
              t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.DELETE_MODAL_HEADER')
            )
          }
          dialogFooter={deleteDialogFooter}
          dialogDismissableMask={true}
          dialogClosable={false}
          content={
            gymDeletableCheckLoading ? (
              <>
                <div className="mb-3">
                  <Loader shape="rectangle" width="full" />
                </div>
                <div className="mb-3">
                  <Loader shape="rectangle" width="full" />
                </div>
                <div className="mb-3">
                  <Loader shape="rectangle" width="half" />
                </div>
              </>
            ) : (
              <Text data-cy={'dialog-content'}>
                {gymDeletableStatus
                  ? t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.DELETE_MODAL_MESSAGE')
                  : t(
                      'GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.DELETE_MODAL_MESSAGE_HAVE_GYMS'
                    )}{' '}
                <span className="fw-bold">{selectedRow?.name}</span>
              </Text>
            )
          }
        />
        {isGymChainsLoading ? <Loader shape="table" /> : <Table {...tableProps} />}
      </div>
    );
  };

  const tabViewItems = [
    {
      header: t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TAB_VIEW.GYM_CHAINS'),
      content: <GymChainsTable />
    }
  ];
  return (
    <Card className="p-card-w-table">
      <TabView items={tabViewItems} />
    </Card>
  );
};

const SecondColumn = () => {
  const todayUserCount = 200;
  const activeUserCount = 300532;
  const errorCount = 30;

  return (
    <div>
      <DashboardCounts
        todayUserCount={todayUserCount}
        activeUserCount={activeUserCount}
        errorCount={errorCount}
      />
    </div>
  );
};

const GymChainListPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  return (
    <TwoColTemplate
      title={t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.PAGE_TITLE')}
      col1={<MainColumn />}
      //--Sodvin Release--
      //col2={<SecondColumn />}
      alertRef={alertRef}
      backButtonHandler={() => history.push('/dashboard')}
    />
  );
};

export default GymChainListPage;
