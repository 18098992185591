import 'primeflex/primeflex.css';
import { useRef, useState } from 'react';
import Avatar from '@gym-atoms/Avatar/Avatar';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import VerticalMenu, { VerticalMenuType } from '@gym-atoms/VerticalMenu/VerticalMenu';
import ResetPassword, { ResetPasswordFormValues } from '@gym-molecules/ResetPassword/ResetPassword';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import { useTranslation } from 'react-i18next';

const SidebarProfile = ({
  image,
  profileName,
  profileType,
  logOut,
  passwordReset
}: SidebarProfileProps) => {
  const menuRef = useRef<VerticalMenuType>(null);
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const modalToggle = () => setModalVisible(!modalVisible);

  const clickHandler = (e: React.MouseEvent) => {
    menuRef.current?.toggle(e);
  };

  const [displayLogoutAlert, setdisplayLogoutAlert] = useState(false);
  const onClick = () => setdisplayLogoutAlert(true);
  const onHide = () => setdisplayLogoutAlert(false);

  const onLogOut = () => {
    onHide();
    logOut();
  };

  const onResetPassword = (
    data: ResetPasswordFormValues,
    callback: (error: boolean, errorCode?: string) => void
  ) => {
    passwordReset(data, callback);
  };

  const renderFooter = () => {
    return (
      <div>
        <Button label={t('COMMON.NO')} icon="pi pi-times" onClick={onHide} />
        <Button label={t('SIDEBAR_PROFILE.LOGOUT')} icon="pi pi-check" onClick={onLogOut} />
      </div>
    );
  };
  const items = [
    { label: t('SIDEBAR_PROFILE.RESET_PASSWORD'), command: modalToggle },
    { label: t('SIDEBAR_PROFILE.LOGOUT'), command: onClick }
  ];

  return (
    <div className="sidebarProfile p-d-flex">
      <div className="sidebarProfile--image">
        <Avatar image={image} size="xlarge"></Avatar>
      </div>
      <div className="sidebarProfile--detail">
        <Text className="text-ellipsis" size={TextSizes.t16}>
          {profileName}
        </Text>
        <Text className="text-ellipsis" size={TextSizes.t14}>
          {profileType}
        </Text>
      </div>
      <div className="sidebarProfile--menu">
        <Button
          data-cy={'profile-menu-nutton'}
          icon="pi-ellipsis-h"
          onClick={clickHandler}
          size={ButtonSizes.small}
          variant={ButtonVariants.textonly}
        />
        <VerticalMenu popup refProp={menuRef} items={items} />

        <DialogBox
          variant={DialogBoxVariants.long}
          dialogVisible={displayLogoutAlert}
          onHideCallback={() => onHide()}
          dialogHeader={t('SIDEBAR_PROFILE.LOGOUT')}
          dialogFooter={renderFooter()}
          dialogDismissableMask={true}
          dialogClosable={false}
          content={t('SIDEBAR_PROFILE.LOGOUT_CONFIRMATION')}
        />
        <ResetPassword
          visible={modalVisible}
          onHide={modalToggle}
          onSubmit={(data, callback) => onResetPassword(data, callback)}
        />
      </div>
    </div>
  );
};

export interface SidebarProfileProps {
  /** Source of the image. Path or URL. */
  image: string;
  /** Profile Name to be displayed */
  profileName: string;
  /** Profile type to be displayed */
  profileType: string;
  /** Logout function */
  logOut: () => void;
  /** Reset password function */
  passwordReset: (
    data: ResetPasswordFormValues,
    callback: (error: boolean, errorCode?: string) => void
  ) => void;
}

export default SidebarProfile;
