import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import styles from './Dropdown.module.scss';

export enum DropdownSizes {
  small = 'p-dropdown-sm',
  medium = 'p-dropdown'
}

const Dropdown = ({
  label,
  disabled = false,
  showClear = false,
  boldItems = false,
  chips,
  grouped,
  options,
  multiselectLimit,
  panelHeaderTemplate,
  error = false,
  optionLabel,
  optionGroupLabel,
  optionGroupChildren,
  size = DropdownSizes.medium,
  ...props
}: DropdownProps) => {
  const disabledStyle = disabled ? styles.dropdownDisabled : null;
  const boldStyle = boldItems ? 'p-dropdown-bold' : undefined;
  const errorStyle = error ? 'p-invalid' : undefined;
  const finalClassName = `${boldStyle} ${errorStyle} ${size}`;

  const finalProps = {
    disabled: disabled,
    showClear: showClear,
    className: finalClassName,
    options: options,
    ...props
  };

  const labelProps = {
    className: `${disabledStyle} fw-bold`
  };

  return (
    <>
      <label {...labelProps}>{label}</label>
      {chips ? (
        <MultiSelect
          {...finalProps}
          display="chip"
          selectionLimit={multiselectLimit}
          panelHeaderTemplate={panelHeaderTemplate}
        />
      ) : grouped ? (
        <MultiSelect
          {...finalProps}
          optionLabel={optionLabel}
          optionGroupLabel={optionGroupLabel}
          optionGroupChildren={optionGroupChildren}
        />
      ) : (
        <PrimeDropdown {...finalProps} />
      )}
    </>
  );
};

/**
 * SelectItem API as defined by PrimeReact
 * (https://www.primefaces.org/primereact/showcase/#/dropdown)
 */
export type SelectItem = {
  label: string;
  value?: string | number; // added the number with an OR operator
  className?: string;
  title?: string;
  disabled?: boolean;
};

/**
 * This is PrimeReact's event object for onChange, taken from source
 * (primereact/components/dropdown/Dropdown.d.ts)
 */
export type DropdownChangeEvent = {
  originalEvent: Event;
  value: any;
  target: { name: string; id: string; value: any };
};

export interface DropdownProps {
  /** Placeholder when no value is selected */
  placeholder?: string;
  /**
   * Array of items to display in the list.
   * It can be an array of strings.
   * It can also be [SelectItem](https://www.primefaces.org/primereact/showcase/#/dropdown) instances
   * (see exported type `SelectItem`),
   * which basically means each item should be of the format `{label: 'sample label', value: 'sample value'}`.
   */
  options: Array<Partial<SelectItem>> | Array<string>;
  /** Disable selecting from this list */
  disabled?: boolean;
  /** Label to be shown alongside the list */
  label?: string;
  /** Whether to show an icon to clear current selection */
  showClear?: boolean;
  /** Name is needed when working with forms */
  name?: string;
  /** Sets the currently selected item */
  value?: string | string[] | number | null;
  /** onChange is needed when working with forms */
  onChange?: (e: DropdownChangeEvent) => void;
  /** boldItems is needed when working with forms */
  boldItems?: boolean;
  /** chips is needed when working with ChipDropdown */
  chips?: boolean;
  /** Number of selections allowed in multi select */
  multiselectLimit?: number;
  /** Option to override the panel header (select all button, etc) in MultiSelect */
  panelHeaderTemplate?: any;
  /** Whether this dropdown contains an error. Applies relevant styles */
  error?: boolean;
  /** Whether this dropdown is a grouped dropdown */
  grouped?: boolean;
  /** Label of the child element */
  optionLabel?: string;
  /** Label of the parent element */
  optionGroupLabel?: string;
  /** Children array name */
  optionGroupChildren?: string;
  /** size of the dropdown*/
  size?: DropdownSizes;
}

export default Dropdown;
