import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { KisiEvents, KisiEvents as typeOfKisiEvents } from '@gym-particles/types/KisiEvents';
import { getKisiEventsForSiteLocation } from '@gym-graphql/queries';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isKisiEventLoading: boolean;
  items: Array<{
    siteLocationId: number;
    items: typeOfKisiEvents[];
    totalRecords: number;
  }>;
} = {
  isKisiEventLoading: false,
  items: []
};

type kisiEventParamType = {
  siteLocationId: number;
};

export const fetchKisiEvents = createAsyncThunk(
  '/gym/fetchKisiEvents',
  async (params: kisiEventParamType) => {
    const response = await (API.graphql({
      query: getKisiEventsForSiteLocation,
      variables: {
        siteLocationId: params.siteLocationId
      }
    }) as Promise<{
      data: {
        getKisiEventsForSiteLocation: {
          items: KisiEvents[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const KisiEventsSlice = createSlice({
  name: 'KisiEvents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchKisiEvents.fulfilled, (state, action) => {
      state.isKisiEventLoading = false;
      if (action.payload.data.getKisiEventsForSiteLocation.items === null) {
        return;
      }
      const items = action.payload.data.getKisiEventsForSiteLocation.items.map(
        (e: KisiEvents): typeOfKisiEvents => {
          return {
            id: e.id || 0,
            siteId: e.siteId || 0,
            siteLocationId: e.siteLocationId || 0,
            businessName: e.businessName || '',
            kisiDoorId: e.kisiDoorId || 0,
            kisiDoorName: e.kisiDoorName || '',
            doorOpenMemberEmail: e.doorOpenMemberEmail || '',
            doorOpenedTime: e.doorOpenedTime
              ? dayjs(e.doorOpenedTime || '').format('ddd, MMM D, YYYY h:mm:ss A')
              : '',
            memberId: e.memberId || 0,
            memberName: e.memberName || '',
            memberUniqueId: e.memberUniqueId || '',
            memberClientId: e.memberClientId || '',
            memberAccountBalance: e.memberAccountBalance || 0,
            memberNote: e.memberNote || '',
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            createdBy: e.createdBy || 0,
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate
              ? dayjs(e.lastModifiedDate || '').format('DD MMM YYYY')
              : ''
          };
        }
      );
      console.log(items);

      const totalRecords =
        action.payload.data.getKisiEventsForSiteLocation.totalRecords.totalRecords;

      const fetchedKisiEventsInfo = {
        totalRecords: totalRecords,
        items: items,
        siteLocationId: action.meta.arg.siteLocationId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedKisiEventsInfo);
      } else {
        const update = state.items.find(
          (kisiEventInfo) => kisiEventInfo.siteLocationId === fetchedKisiEventsInfo.siteLocationId
        );
        if (update) {
          const newState = state.items.map((kisiEventInfo) => {
            if (kisiEventInfo.siteLocationId === fetchedKisiEventsInfo.siteLocationId) {
              return fetchedKisiEventsInfo;
            } else {
              return kisiEventInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedKisiEventsInfo);
        }
      }
    });

    builder.addCase(fetchKisiEvents.pending, (state, action) => {
      state.isKisiEventLoading = true;
      return state;
    });

    builder.addCase(fetchKisiEvents.rejected, (state, action) => {
      state.isKisiEventLoading = false;
      console.log('getKisiEvents', action);
      return state;
    });
  }
});

export default KisiEventsSlice.reducer;
