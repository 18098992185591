import { Avatar as PrimeAvatar } from 'primereact/avatar';

type AvatarSizes = 'default' | 'large' | 'xlarge' | 'custom';
type AvatarShape = 'circle' | 'square';

const Avatar = ({ image, size = 'default', shape = 'circle', label, ...props }: AvatarProps) => {
  if (size === 'custom') {
    return (
      <PrimeAvatar
        label={label}
        shape={shape}
        image={image}
        {...props}
        style={{
          height: props.height,
          width: props.width,
          fontSize: props.fontSize,
          background: props.color
        }}
      />
    );
  }

  if (!image) {
    return <PrimeAvatar label={label} icon="pi pi-user" size={size} shape={shape} {...props} />;
  } else {
    return <PrimeAvatar label={label} image={image} size={size} shape={shape} {...props} />;
  }
};

export interface AvatarProps {
  /** Path or URL to image. If no image is given, a default icon will be displayed */
  image?: string;
  /**
   * Size of the avatar.
   * If this is 'custom', size will be determined by props `height`, `width`,
   * and `fontSize`.
   */
  size: AvatarSizes;
  /** label fo the avatar if no image */
  label?: string;
  /** shape of the avatar */
  shape?: AvatarShape;
  /** Height for custom size avatars */
  height?: number;
  /** Width for custom size avatars */
  width?: number;
  /** Font size for custom size avatars */
  fontSize?: string | number;
  /** Background color for custom avatars */
  color?: string;
}

export default Avatar;
