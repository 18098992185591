import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { Member as MemberType } from '@gym-particles/types/Member';
import { getMemberByMemberId, getMembersForSiteLocation } from '@gym-graphql/queries';
import { SiteLocationMember } from '@gym-src/API';
import { PaginatedQueryParams } from '@gym-particles/types/Pagination';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isMembersLoading: boolean;
  items: Array<{
    locationId: number;
    items: MemberType[];
    totalRecords: number;
  }>;
} = {
  isMembersLoading: false,
  items: []
};

type paramType = {
  locationId: number;
  pagination: PaginatedQueryParams;
};

type FetchMemberParamType = {
  locationId: number;
  memberId: number;
  pagination: PaginatedQueryParams;
};

export const fetchMembers = createAsyncThunk('/gym/fetchMembers', async (params: paramType) => {
  if (params.pagination.sortField === 'memberFullName') {
    params.pagination.sortField = 'memberFirstName';
  }
  const response = await (API.graphql({
    query: getMembersForSiteLocation,
    variables: {
      siteLocationId: params.locationId,
      offset: params.pagination.offset,
      pageSize: params.pagination.pageSize,
      sortField: params.pagination.sortField,
      sortOrder: params.pagination.sortOrder === 1 ? 'ASC' : 'DESC',
      search: {
        searchField: params.pagination.search?.searchField,
        searchText: params.pagination.search?.searchText
      }
    }
  }) as Promise<{
    data: {
      getMembersForSiteLocation: {
        items: SiteLocationMember[];
        totalRecords: { totalRecords: number };
      };
    };
  }>);
  return response;
});

export const fetchMemberById = createAsyncThunk(
  '/gym/fetchMemberById',
  async (params: FetchMemberParamType) => {
    if (params.pagination.sortField === 'memberFullName') {
      params.pagination.sortField = 'memberFirstName';
    }
    const response = await (API.graphql({
      query: getMemberByMemberId,
      variables: {
        siteLocationId: params.locationId,
        memberId: params.memberId,
        offset: params.pagination.offset,
        pageSize: params.pagination.pageSize,
        sortField: params.pagination.sortField,
        sortOrder: params.pagination.sortOrder === 1 ? 'ASC' : 'DESC',
        search: {
          searchField: params.pagination.search?.searchField,
          searchText: params.pagination.search?.searchText
        }
      }
    }) as Promise<{
      data: {
        getMemberByMemberId: {
          items: SiteLocationMember[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const MemberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMembers.fulfilled, (state, action) => {
      state.isMembersLoading = false;
      if (!action.payload.data.getMembersForSiteLocation.items) {
        return;
      }
      const items = action.payload.data.getMembersForSiteLocation.items.map(
        (e: SiteLocationMember): MemberType => {
          return {
            id: e.memberId || 0,
            uniqueId: e.uniqueId || undefined,
            clientId: e.clientId || undefined,
            accountBalance: e.accountBalance || 0,
            memberFirstName: e.memberFirstName || '',
            memberLastName: e.memberLastName || '',
            memberFullName: e.memberFirstName + ' ' + e.memberLastName,
            accessTypes: {
              isMobileAppDisabled: e.isMobileAppDisabled != null ? e.isMobileAppDisabled : true,
              isJustTapRevoked: e.isJustTapRevoked != null ? e.isJustTapRevoked : true
            },
            signedupDate: e.signedUpDate ? dayjs(e.signedUpDate || '').format('DD MMM YYYY') : '',
            status: e.status || '',
            phoneNumber: e.phoneNumber || '',
            isBlocked: e.isBlocked || false,
            createdBy: e.createdBy || 0,
            createdDate: e.createdDate || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            imageUrl: e.profilePicturePath || '',
            email: e.email || undefined,
            defaultLocationDetectionMethod: e.defaultGymDetectionMethod || undefined,
            lastSyncDate: e.lastSyncDate || undefined,
            isTrial: e.isTrial || undefined,
            arxReferenceId: e.arxReferenceId || undefined,
            userId: e.userId || 0,
            linkedPhoneNumber: e.linkedPhoneNumber || '',
            hasAccessCard: e.hasAccessCard || false,
            hasForcedAccess: e.hasForcedAccess || false,
            displayHasForcedAccess: e.hasForcedAccess ? 'Yes' : 'No',
            hasManualAccess: e.hasManualAccess || false,
            displayHasManualAccess: e.hasManualAccess ? 'Yes' : 'No',
            isFreepassMember: e.isFreepassMember || false,
            displayIsFreepassMember: e.isFreepassMember ? 'Yes' : 'No',
            hasLocationOverride: e.hasLocationOverride || false,
            displayHasLocationOverride: e.hasLocationOverride ? 'Yes' : 'No',
            memberLocationId: e.memberLocationId || 0,
            language: e.language || 'en',
            displayIsImported: e.uniqueId ? 'True' : 'False',
            flag: e.flag === null || e.flag === undefined ? 2 : e.flag
          };
        }
      );

      const totalRecords = action.payload.data.getMembersForSiteLocation.totalRecords.totalRecords;
      const fetchedMembersInfo = {
        totalRecords: totalRecords,
        items: items,
        locationId: action.meta.arg.locationId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedMembersInfo);
      } else {
        const update = state.items.find(
          (memberInfo) => memberInfo.locationId === fetchedMembersInfo.locationId
        );
        if (update) {
          const newState = state.items.map((memberInfo) => {
            if (memberInfo.locationId === fetchedMembersInfo.locationId) {
              return fetchedMembersInfo;
            } else {
              return memberInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedMembersInfo);
        }
      }
    });

    builder.addCase(fetchMembers.pending, (state, action) => {
      state.isMembersLoading = true;
      return state;
    });

    builder.addCase(fetchMembers.rejected, (state, action) => {
      state.isMembersLoading = false;
      console.log('getMembersForSiteLocation', action);
      return state;
    });

    builder.addCase(fetchMemberById.fulfilled, (state, action) => {
      state.isMembersLoading = false;
      if (!action.payload.data.getMemberByMemberId.items) {
        return;
      }
      const items = action.payload.data.getMemberByMemberId.items.map(
        (e: SiteLocationMember): MemberType => {
          return {
            id: e.memberId || 0,
            uniqueId: e.uniqueId || undefined,
            clientId: e.clientId || undefined,
            accountBalance: e.accountBalance || undefined,
            memberFirstName: e.memberFirstName || '',
            memberLastName: e.memberLastName || '',
            memberFullName: e.memberFirstName + ' ' + e.memberLastName,
            accessTypes: {
              isMobileAppDisabled: e.isMobileAppDisabled != null ? e.isMobileAppDisabled : true,
              isJustTapRevoked: e.isJustTapRevoked != null ? e.isJustTapRevoked : true
            },
            signedupDate: e.signedUpDate ? dayjs(e.signedUpDate || '').format('DD MMM YYYY') : '',
            status: e.status || '',
            phoneNumber: e.phoneNumber || '',
            isBlocked: e.isBlocked || false,
            createdBy: e.createdBy || 0,
            createdDate: e.createdDate || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            imageUrl: e.profilePicturePath || '',
            email: e.email || undefined,
            defaultLocationDetectionMethod: e.defaultGymDetectionMethod || undefined,
            lastSyncDate: e.lastSyncDate || undefined,
            isTrial: e.isTrial || undefined,
            arxReferenceId: e.arxReferenceId || undefined,
            userId: e.userId || 0,
            linkedPhoneNumber: e.linkedPhoneNumber || '',
            hasAccessCard: e.hasAccessCard || false,
            hasForcedAccess: e.hasForcedAccess || false,
            displayHasForcedAccess: e.hasForcedAccess ? 'Yes' : 'No',
            memberLocationId: e.memberLocationId || 0,
            language: e.language || 'en',
            displayIsImported: e.uniqueId ? 'True' : 'False',
            flag: e.flag === null || e.flag === undefined ? 2 : e.flag
          };
        }
      );

      const totalRecords = action.payload.data.getMemberByMemberId.totalRecords.totalRecords;
      const fetchedMembersInfo = {
        totalRecords: totalRecords,
        items: items,
        locationId: action.meta.arg.locationId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedMembersInfo);
      } else {
        const update = state.items.find(
          (memberInfo) => memberInfo.locationId === fetchedMembersInfo.locationId
        );
        if (update) {
          const newState = state.items.map((memberInfo) => {
            if (memberInfo.locationId === fetchedMembersInfo.locationId) {
              return fetchedMembersInfo;
            } else {
              return memberInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedMembersInfo);
        }
      }
    });

    builder.addCase(fetchMemberById.pending, (state, action) => {
      state.isMembersLoading = true;
      return state;
    });

    builder.addCase(fetchMemberById.rejected, (state, action) => {
      state.isMembersLoading = false;
      console.log('getMemberByMemberId', action);
      return state;
    });
  }
});

export default MemberSlice.reducer;
