import UserManagementTemplate from '@gym-templates/UserManagement/UserManagementTemplate';
import { Card } from 'primereact/card';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TwoColTemplate from '../../templates/TwoColTemplate/TwoColTemplate';
import { AlertType } from '../../atoms/Alert/Alert';

const MainColumn = () => {
  return (
    <Card className="pt30 pl40 pr40 pb30">
      <UserManagementTemplate type="new" />
    </Card>
  );
};

const AddNewUserPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  return (
    <TwoColTemplate
      title={t('ADD_NEW_USER.PAGE_TITLE')}
      col1={<MainColumn />}
      alertRef={alertRef}
      backButtonHandler={() => history.push('/users')}
    />
  );
};

export default AddNewUserPage;
