import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { KisiDoors, KisiDoors as typeOfKisiDoors } from '@gym-particles/types/models';
import { getKisiDoorsForSiteLocation } from '@gym-graphql/queries';
import { deleteKisiDoor as deleteKisiDoorMutation } from '@gym-graphql/mutations';
import { KisiDoor } from '@gym-src/API';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isKisiDoorLoading: boolean;
  items: Array<{
    siteLocationId: number;
    items: typeOfKisiDoors[];
    totalRecords: number;
  }>;
} = {
  isKisiDoorLoading: false,
  items: []
};

type kisiDoorParamType = {
  siteLocationId: number;
};

export type deleteParamType = {
  id?: number;
  lastModifiedBy: number;
};

export const fetchKisiDoors = createAsyncThunk(
  '/gym/fetchKisiDoors',
  async (params: kisiDoorParamType) => {
    const response = await (API.graphql({
      query: getKisiDoorsForSiteLocation,
      variables: {
        siteLocationId: params.siteLocationId
      }
    }) as Promise<{
      data: {
        getKisiDoorsForSiteLocation: {
          items: KisiDoor[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const deleteKisiDoor = createAsyncThunk(
  'KisiDoor/deleteKisiDoor',
  async (params: deleteParamType) => {
    console.log(1);
    const response = await (API.graphql({
      query: deleteKisiDoorMutation,
      variables: {
        id: params.id,
        lastModifiedBy: params.lastModifiedBy
      }
    }) as Promise<{
      data: {
        deleteKisiDoor: KisiDoor;
      };
    }>);
    return response;
  }
);

export const KisiDoorsSlice = createSlice({
  name: 'KisiDoors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchKisiDoors.fulfilled, (state, action) => {
      state.isKisiDoorLoading = false;
      if (action.payload.data.getKisiDoorsForSiteLocation.items === null) {
        return;
      }
      const items = action.payload.data.getKisiDoorsForSiteLocation.items.map(
        (e: KisiDoors): typeOfKisiDoors => {
          return {
            id: e.id || 0,
            siteId: e.siteId || 0,
            siteLocationId: e.siteLocationId || 0,
            kisiDoorId: e.kisiDoorId || 0,
            kisiDoorName: e.kisiDoorName || '',
            kisiPlaceId: e.kisiPlaceId || 0,
            kisiPlaceName: e.kisiPlaceName || '',
            createdDate: e.createdDate ? dayjs(e.createdDate || '').format('DD MMM YYYY') : '',
            createdBy: e.createdBy || 0,
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate
              ? dayjs(e.lastModifiedDate || '').format('DD MMM YYYY')
              : ''
          };
        }
      );

      const totalRecords =
        action.payload.data.getKisiDoorsForSiteLocation.totalRecords.totalRecords;

      const fetchedKisiDoorsInfo = {
        totalRecords: totalRecords,
        items: items,
        siteLocationId: action.meta.arg.siteLocationId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedKisiDoorsInfo);
      } else {
        const update = state.items.find(
          (kisiDoorInfo) => kisiDoorInfo.siteLocationId === fetchedKisiDoorsInfo.siteLocationId
        );
        if (update) {
          const newState = state.items.map((kisiDoorInfo) => {
            if (kisiDoorInfo.siteLocationId === fetchedKisiDoorsInfo.siteLocationId) {
              return fetchedKisiDoorsInfo;
            } else {
              return kisiDoorInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedKisiDoorsInfo);
        }
      }
    });

    builder.addCase(fetchKisiDoors.pending, (state, action) => {
      state.isKisiDoorLoading = true;
      return state;
    });

    builder.addCase(fetchKisiDoors.rejected, (state, action) => {
      state.isKisiDoorLoading = false;
      console.log('getKisiDoors', action);
      return state;
    });

    builder.addCase(deleteKisiDoor.fulfilled, (state, action) => {
      state.isKisiDoorLoading = false;
      const kisiDoorId = action.meta.arg;
      const filtered = state.items.map((kisiDoorsObj) => {
        const filteredKisiDoors = kisiDoorsObj.items.filter(
          (kisiDoor) => kisiDoor.id !== kisiDoorId.id
        );
        kisiDoorsObj.items = filteredKisiDoors;
        kisiDoorsObj.totalRecords = kisiDoorsObj.totalRecords - 1;
        return kisiDoorsObj;
      });
      state.items = filtered;
    });

    builder.addCase(deleteKisiDoor.pending, (state, action) => {
      state.isKisiDoorLoading = true;
      return state;
    });

    builder.addCase(deleteKisiDoor.rejected, (state, action) => {
      state.isKisiDoorLoading = false;
      console.error('deleteKisiDoor', action);
      return state;
    });
  }
});

export default KisiDoorsSlice.reducer;
