import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { ScheduleRequest, ScheduleRequestTable } from '@gym-particles/types/ScheduleRequest';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { UserRole } from '@gym-particles/types/User';
import Loader from '@gym-atoms/Loader/Loader';
import { fetchScheduleRequests } from '@gym-redux/slices/scheduleRequestsSlice';
import Dropdown, { DropdownSizes, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import { GymChainDropdownItem } from '@gym-src/API';
import { API } from 'aws-amplify';
import { getAllGymChains } from '@gym-graphql/queries';
import { useHistory } from 'react-router';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import Text from '@gym-atoms/Text/Text';
import { removeScheduleRequest } from '@gym-graphql/mutations';

const ScheduleRequestsList = () => {
  const isScheduleRequestsLoading = useAppSelector(
    (state) => state.scheduleRequests.isScheduleRequestsLoading
  );
  const data = useAppSelector((state) => state.scheduleRequests.items);
  const totalScheduleRequests = useAppSelector((state) => state.scheduleRequests.totalRecords);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<ScheduleRequest>();
  const [sortField, setSortField] = useState('scheduleId');
  const [sortOrder, setSortOrder] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const userRole = useAppSelector((state) => state.user.userRole);
  const [gymChainDropDownItems, setGymChainDropDownItems] = useState<Array<SelectItem>>([]);
  const [gymChainSelected, setGymChainSelected] = useState<string | null>(null);
  const [selected, setSelected] = useState<number>(0);
  const [scheduleReuqeustList, setScheduleReuqeustList] = useState<ScheduleRequest[]>([]);
  const history = useHistory();
  const userId = useAppSelector((state) => state.user.userId);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchScheduleRequests());
  }, [sortField, sortOrder, offset, pageSize]);

  useEffect(() => {
    dispatch(fetchScheduleRequests());
  }, [selected]);

  useEffect(() => {
    if (selected === 0) {
      mapRequests(data);
    } else {
      filterByGymChains(data);
    }
  }, [data, selected]);

  const filterByGymChains = (data: ScheduleRequest[]) => {
    const newArray = data.filter((e) => {
      return e.gymChainId == selected;
    });
    mapRequests(newArray);
  };

  const mapRequests = (scheduleReuqeustList: ScheduleRequest[]) => {
    const mappedList: ScheduleRequestTable[] = [];

    scheduleReuqeustList.forEach((request) => {
      const index = mappedList.findIndex((mappedRequest) => {
        return mappedRequest.scheduleId === request.scheduleId;
      });

      if (index > -1) {
        if (!mappedList.find((e) => e.gymId === request.gymId)) {
          mappedList[index].requestGymInfo.push({
            gymName: request.gymName
          });
        }
      } else {
        mappedList.push({
          gymName: request.gymName,
          gymId: request.gymId,
          scheduleId: request.scheduleId,
          scheduleName: request.scheduleName,
          gymChainName: request.gymChainName,
          createdDate: request.createdDate,
          gymChainId: request.gymChainId,
          weekdayName: request.weekdayName,
          startTime: new Date(request.startTime).toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit'
          }),
          status: request.status,
          weekdayId: request.weekdayId,
          endTime: new Date(request.endTime).toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit'
          }),
          requestGymInfo: [
            {
              gymName: request.gymName
            }
          ]
        });
      }
    });
    setScheduleReuqeustList(mappedList);
  };

  const fetchGymChainss = async () => {
    try {
      const response = (API.graphql({
        query: getAllGymChains
      }) as Promise<{
        data: { getAllGymChains: [GymChainDropdownItem] };
      }>).then((e) => {
        if (e.data.getAllGymChains) {
          const gymChainDropDownItems: SelectItem[] = e.data.getAllGymChains.map((data) => {
            return { label: data?.gymChainName || '', value: data?.gymChainId || 0 };
          });
          setGymChainDropDownItems(gymChainDropDownItems);
          gymChainDropDownItems.unshift({ label: 'All Gym Chains', value: 0 });
          setGymChainDropDownItems(gymChainDropDownItems);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGymChainss();
  }, []);

  const [statusSelected, setStatusSelected] = useState<string | null>(null);
  const headerDropDowns = [
    <div className="d-flex mr5" key={1}>
      <Dropdown
        options={gymChainDropDownItems}
        placeholder={t('DAY_SCHEDULE_REQUESTS.HEADER_DROPDOWN_PLACEHOLDER')} // change placeholders accordingly
        onChange={(e) => {
          setSelected(e.value);
          setGymChainSelected(e.value);
        }}
        value={gymChainSelected}
        size={DropdownSizes.small}
      />
    </div>
  ];

  const tableProps: TableProps<ScheduleRequest> = {
    uniqueFieldName: 'scheduleId',
    exportFileName: t('DAY_SCHEDULE_REQUESTS.EXPORT_EXCEL_FILE_NAME'),
    data: scheduleReuqeustList || undefined,
    searchPlaceholderText: t('DAY_SCHEDULE_REQUESTS.SEARCH_PLACEHOLDER'),
    excelBtntext: t('DAY_SCHEDULE_REQUESTS.EXPORT_EXCEL_BUTTON_LABEL'),
    totalRecords: totalScheduleRequests,
    selectedRow: selectedRow,
    setSelectedRow: setSelectedRow,
    setSortField: setSortField,
    setSortOrder: setSortOrder,
    sortField: sortField,
    sortOrder: sortOrder,
    setPageSize: setPageSize,
    setOffset: setOffset,
    pageSize: pageSize,
    offset: offset,
    searchTerm: searchTerm,
    setSearchTerm: setSearchTerm,
    columns: [
      {
        field: 'gymChainName',
        header: t('DAY_SCHEDULE_REQUESTS.TABLE_HEADER_GYM_CHAIN_NAME'),
        toggable: true,
        sortable: true
      },
      {
        field: 'scheduleName',
        header: t('DAY_SCHEDULE_REQUESTS.TABLE_HEADER_REQUEST_NAME'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'weekdayName',
        header: t('DAY_SCHEDULE_REQUESTS.TABLE_HEADER_REQUEST_DAY'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'startTime',
        header: t('DAY_SCHEDULE_REQUESTS.TABLE_HEADER_START_TIME'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'endTime',
        header: t('DAY_SCHEDULE_REQUESTS.TABLE_HEADER_END_TIME'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'createdDate',
        header: t('DAY_SCHEDULE_REQUESTS.TABLE_HEADER_CREATED_DATE'),
        toggable: true,
        truncateText: true
      }
    ],
    expandedCols: [
      {
        field: 'gymName',
        header: `${t('DAY_SCHEDULE_REQUESTS.TABLE_HEADER_GYM_NAME')}`,
        toggable: true,
        truncateText: true
      }
    ],
    expandable: true,
    lazy: false,
    expandableField: 'requestGymInfo',
    isContextMenu: userRole === UserRole.SYSTEM_ADMIN ? true : false,
    headerText: t('DAY_SCHEDULE_REQUESTS.HEADER_TEXT'),
    emptyStateTexts: [t('DAY_SCHEDULE_REQUESTS.EMPTY_STATE_TEXT')],
    menuItem:
      userRole === UserRole.SYSTEM_ADMIN
        ? [
            {
              label: t('DAY_SCHEDULE_REQUESTS.CONTEXT_MENU_CREATE_DAY_SCHEDULE'),
              command: () => {
                history.push(`/daySchedules/${selectedRow?.scheduleId}`);
              } // add functionality
            },
            {
              label: t('DAY_SCHEDULE_REQUESTS.CONTEXT_MENU_REMOVE_REQUEST'),
              command: () => setDeleteDialogVisible(true) // add functionality
            }
          ]
        : [],
    headerDropDowns: headerDropDowns
  };

  const deleteDialogHandler = () => {
    selectedRow && deleteScheduleRequest();

    setDeleteDialogVisible(false);
  };

  const deleteScheduleRequest = async () => {
    try {
      await API.graphql({
        query: removeScheduleRequest,
        variables: {
          id: selectedRow?.scheduleId,
          userId: userId
        }
      });
      setTimeout(() => {
        dispatch(fetchScheduleRequests());
      }, 200);
    } catch (error) {
      console.log('Delete Schedule Request Error: ', error);
    }
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.CANCEL')}
          variant={ButtonVariants.textonly}
          onClick={() => setDeleteDialogVisible(false)}
        />
        <Button
          label={t('COMMON.DELETE')}
          variant={ButtonVariants.danger}
          onClick={deleteDialogHandler}
        />
      </div>
    );
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t('DAY_SCHEDULE_REQUESTS.FORM_FAILURE_DIALOG_HEADER')}
          dialogFooter={
            <Button
              label={t('DAY_SCHEDULE_REQUESTS.FORM_FAILURE_DIALOG_BUTTON')}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t('DAY_SCHEDULE_REQUESTS.FORM_FAILURE_DIALOG_BODY')}</Text>}
        />
      </div>
    );
  };

  return (
    <div>
      <DialogBox
        dialogVisible={deleteDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setDeleteDialogVisible(false)}
        dialogFooter={<DeleteDialogFooter />}
        dialogClosable={false}
        dialogHeader={t('COMMON.DELETE')}
        content={
          <Text>
            {t('DAY_SCHEDULE_REQUESTS.DELETE_MODAL_CONFIRM')}{' '}
            <span className="fw-bold">{selectedRow?.scheduleName}</span> ?
          </Text>
        }
      />
      {isScheduleRequestsLoading ? (
        <Loader shape="table" />
      ) : (
        <>{scheduleReuqeustList && <Table {...tableProps} />}</>
      )}
      <ActionFailedDialog />
    </div>
  );
};

export default ScheduleRequestsList;
