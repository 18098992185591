import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Text from '@gym-atoms/Text/Text';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { GymType } from '@gym-particles/types/Gym';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { deleteGym, fetchGyms } from '@gym-redux/slices/gymsSlice';
import Loader from '@gym-atoms/Loader/Loader';
import { API } from 'aws-amplify';
import { isGymDeletable } from '@gym-graphql/queries';
import { DeletableResponse } from '@gym-src/API';
import { UserRole } from '@gym-particles/types/User';

export const GymListTable = (props: GymListTableProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { gymChainId } = props;
  const { t } = useTranslation();

  const gyms = useAppSelector(
    (state) => state.gyms.items.find((g) => g.chainId === gymChainId)?.items
  );
  const totalGymCount =
    useAppSelector(
      (state) => state.gyms.items.find((g) => g.chainId === gymChainId)?.totalRecords
    ) || 0;
  const [isDeleting, setIsDeleting] = useState(false);
  const [gymsSortField, setGymsSortField] = useState('createdDate');
  const [gymsSortOrder, setGymsSortOrder] = useState(-1);
  const [gymsOffset, setGymsOffset] = useState(0);
  const [gymsPageSize, setGymsPageSize] = useState(10);
  const [selectedGymRow, setSelectedGymRow] = useState<GymType>();
  const [dialogVisible, setDialogVisible] = useState(false);
  const isGymsLoading = useAppSelector((state) => state.gyms.isGymsLoading);
  const [gymDeletableCheckLoading, setGymDeletableCheckLoading] = useState(false);
  const [gymDeletableStatus, setGymDeletableStatus] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const userRole = useAppSelector((state) => state.user.userRole);
  const userId = useAppSelector((state) => state.user.userId);

  useEffect(() => {
    dispatch(
      fetchGyms({
        id: gymChainId,
        pagination: {
          offset: searchTerm !== '' ? 0 : gymsOffset,
          pageSize: gymsPageSize,
          sortField: gymsSortField,
          sortOrder: gymsSortOrder,
          search: { searchField: '', searchText: searchTerm },
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        }
      })
    );
  }, [gymChainId, gymsSortField, gymsSortOrder, gymsOffset, gymsPageSize, searchTerm]);

  const menuItem = [
    {
      label: t('GYM_LIST.GYM_LIST_PAGE.TABLE.CONTEXT_MENU_EDIT_GYM_DETAILS'),
      command: () => {
        history.push(`/gymChains/${gymChainId}/gyms/${selectedGymRow?.gymId}/edit`);
      }
    },
    {
      label: t('GYM_LIST.GYM_LIST_PAGE.TABLE.CONTEXT_MENU_DELETE_GYM_DETAILS'),
      command: () => checkGymDeletable()
    }
  ];

  const tablePropsGyms: TableProps<GymType> = {
    onRowClick: (e) => {
      history.push(`/gymChains/${gymChainId}/gyms/${e}/dashboard`);
    },
    exportFileName: `Gyms${gymChainId}`,
    setPageSize: setGymsPageSize,
    setOffset: setGymsOffset,
    pageSize: gymsPageSize,
    offset: gymsOffset,
    setSortField: setGymsSortField,
    setSortOrder: setGymsSortOrder,
    sortField: gymsSortField,
    sortOrder: gymsSortOrder,
    totalRecords: totalGymCount || 0,
    uniqueFieldName: 'gymId',
    data: gyms,
    searchTerm: searchTerm,
    setSearchTerm: setSearchTerm,
    enableRowClick: true,
    columns: [
      {
        field: 'gymName',
        header: t('GYM_LIST.GYM_LIST_PAGE.TABLE.HEADER_GYM_NAME'),
        toggable: false,
        sortable: true,
        imageField: 'imageUrl',
        truncateText: true
      },
      {
        field: 'contactPerson',
        header: t('GYM_LIST.GYM_LIST_PAGE.TABLE.HEADER_CONTACT_PERSON'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'phoneNumber',
        header: t('GYM_LIST.GYM_LIST_PAGE.TABLE.HEADER_PHONE_NUMBER'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'lastImportedDate',
        header: t('GYM_LIST.GYM_LIST_PAGE.TABLE.HEADER_LAST_IMPORTED_DATE'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'displayStatus',
        header: t('GYM_LIST.GYM_LIST_PAGE.TABLE.HEADER_STATUS'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'createdDate',
        header: t('GYM_LIST.GYM_LIST_PAGE.TABLE.HEADER_CREATED_DATE'),
        toggable: true,
        truncateText: true
      }
    ],
    searchPlaceholderText: t('GYM_LIST.GYM_LIST_PAGE.SEARCH_FIELD_TEXT'),
    excelBtntext: t('GYM_LIST.GYM_LIST_PAGE.TABLE.BUTTON_EXPORT_LABEL'),
    headerBtns:
      userRole === UserRole.SYSTEM_ADMIN || userRole === UserRole.GYM_CHAIN_USER
        ? [
            <div key={1}>
              <Button
                data-cy={'gyms-add-new-gym-btn'}
                icon="pi-plus"
                label={t('GYM_LIST.GYM_LIST_PAGE.TABLE.BUTTON_ADD_LABEL')}
                onClick={() => history.push(`/gymChains/${gymChainId}/gyms/new`)}
                size={ButtonSizes.small}
              />
            </div>
          ]
        : [],

    selectedRow: selectedGymRow,
    setSelectedRow: setSelectedGymRow,
    isContextMenu:
      userRole === UserRole.SYSTEM_ADMIN
        ? true
        : userRole === UserRole.GYM_CHAIN_USER
        ? true
        : false,
    menuItem:
      userRole === UserRole.SYSTEM_ADMIN
        ? menuItem
        : userRole === UserRole.GYM_CHAIN_USER
        ? menuItem
        : [],
    emptyStateTexts: [t('GYM_LIST.GYM_LIST_PAGE.EMPTY_STATE_TEXT')]
  };

  const checkGymDeletable = async () => {
    setDialogVisible(true);
    setGymDeletableCheckLoading(true);
    try {
      const response = await (API.graphql({
        query: isGymDeletable,
        variables: {
          siteLocationId: selectedGymRow?.gymId
        }
      }) as Promise<{
        data: { isGymDeletable: DeletableResponse[] };
      }>);

      response.data.isGymDeletable.length === 0
        ? setGymDeletableStatus(true)
        : setGymDeletableStatus(false);

      setGymDeletableCheckLoading(false);
    } catch (error) {
      console.log(error);
      setGymDeletableStatus(false);
      setDialogVisible(false);
      setGymDeletableCheckLoading(false);
    }
  };

  const deleteDialogHandler = async () => {
    setIsDeleting(true);
    selectedGymRow && (await dispatch(deleteGym(selectedGymRow.gymId)));
    setIsDeleting(false);
    setDialogVisible(false);
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        {gymDeletableCheckLoading ? (
          <></>
        ) : !gymDeletableStatus ? (
          <Button
            label={t('COMMON.OK')}
            variant={ButtonVariants.basic}
            onClick={() => setDialogVisible(false)}
          />
        ) : (
          <>
            <Button
              data-cy={'gyms-dialog-cancel-btn'}
              label={t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.DELETE_MODAL_CANCEL')}
              variant={ButtonVariants.textonly}
              onClick={() => setDialogVisible(false)}
            />
            <Button
              data-cy={'gyms-dialog-delete-btn'}
              label={t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.DELETE_MODAL_DELETE')}
              variant={ButtonVariants.danger}
              onClick={deleteDialogHandler}
              icon={isDeleting ? 'pi-spinner pi-spin' : ''}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <DialogBox
        dialogVisible={dialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setDialogVisible(false)}
        dialogFooter={<DeleteDialogFooter />}
        dialogClosable={false}
        dialogHeader={
          gymDeletableCheckLoading ? (
            <></>
          ) : (
            t('GYM_CHAIN_MANAGEMENT.GYM_CHAIN_LIST_PAGE.TABLE.DELETE_MODAL_HEADER')
          )
        }
        content={
          gymDeletableCheckLoading ? (
            <>
              <div className="mb-3">
                <Loader shape="rectangle" width="full" />
              </div>
              <div className="mb-3">
                <Loader shape="rectangle" width="full" />
              </div>
              <div className="mb-3">
                <Loader shape="rectangle" width="half" />
              </div>
            </>
          ) : (
            <Text data-cy={'gyms-dialog-content'}>
              {gymDeletableStatus
                ? t('GYM_LIST.GYM_LIST_PAGE.DELETE_MODAL_CONFIRM')
                : t('GYM_LIST.GYM_LIST_PAGE.DELETE_MODAL_CANNOT')}
            </Text>
          )
        }
      />
      {isGymsLoading ? <Loader shape="table" /> : gyms && <Table {...tablePropsGyms} />}
    </div>
  );
};

interface GymListTableProps {
  gymChainId: number;
}
