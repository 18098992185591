import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { MembershipSiteTable } from '@gym-particles/types/models';
import { UserRole } from '@gym-particles/types/User';
import Text from '@gym-atoms/Text/Text';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { useHistory } from 'react-router';
import { useAppSelector, useAppDispatch } from '@gym-redux/store';
import {
  deleteMembership,
  fetchMembershipsForSite,
  toggleLoading
} from '@gym-redux/slices/membershipSlice';
import Loader from '@gym-atoms/Loader/Loader';
import { API } from 'aws-amplify';
import {
  importMemberships,
  importSodvinMemberships,
  isMembershipLinkedToGym
} from '@gym-graphql/queries';
import { updateImportMembershipSite } from '@gym-graphql/mutations';
import { fetchGymChains } from '@gym-redux/slices/gymChainSlice';
import { MembershipImport, DeletableResponse, SodvinMembershipImport } from '@gym-src/API';

enum siteProviderId {
  mindbody = 1,
  sodvin = 2
}

export const MembershipsListTable = (props: MembershipsListTableProps) => {
  const dispatch = useAppDispatch();
  const chainId = props.chain;

  const gymChainMembershipsImported = useAppSelector(
    (state) => state.gymChain.items.find((g) => g.id === +chainId)?.membershipsImported
  );

  const providerId = useAppSelector((state) => state.gymChain.currentGymChain?.providerId);
  const customerSideId = useAppSelector((state) => state.gymChain.currentGymChain?.customerSiteId);
  const userId = useAppSelector((state) => state.user.userId);
  const [membershipsImported, setMembershipsImported] = useState(gymChainMembershipsImported);
  const history = useHistory();
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [membershipSortField, setMembershipSortField] = useState('membershipName');
  const [membershipSortOrder, setMembershipSortOrder] = useState(1);
  const [membershipOffset, setMembershipOffset] = useState(0);
  const [membershipPageSize, setMembershipPageSize] = useState(10);
  const [selectedMembershipRow, setSelectedMembershipRow] = useState<MembershipSiteTable>();
  const [membershipDeletableCheckLoading, setMembershipDeletableCheckLoading] = useState(false);
  const [membershipDeletableStatus, setMembershipDeletableStatus] = useState(false);
  const membershipData = useAppSelector(
    (state) => state.membership.membershipsForSite.find((g) => g.chainId === chainId)?.items
  )?.map((item) => {
    return { ...item, validityPeriod: item.validityPeriod ? item.validityPeriod : '' };
  });
  const totalMembershipCount =
    useAppSelector(
      (state) =>
        state.membership.membershipsForSite.find((g) => g.chainId === chainId)?.totalRecords
    ) || 0;
  const isMembershipsLoading = useAppSelector((state) => state.membership.isMembershipsLoading);
  const [membershipImportTrigger, setMembershipImportTrigger] = useState(false);
  const [importErrorDialogVisible, setImportErrorDialogVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorType, setErrorType] = useState('ERROR');
  const userRole = useAppSelector((state) => state.user.userRole);
  useEffect(() => {
    setMembershipsImported(gymChainMembershipsImported);
  }, [gymChainMembershipsImported]);

  useEffect(() => {
    dispatch(
      fetchMembershipsForSite({
        siteId: chainId,
        pagination: {
          offset: searchTerm !== '' ? 0 : membershipOffset,
          pageSize: membershipPageSize,
          sortField:
            membershipSortField === 'displayStatus'
              ? 'status'
              : membershipSortField === 'displayHasRelatedAccessCategory'
              ? 'hasRelatedAccessCategory'
              : membershipSortField,
          sortOrder: membershipSortOrder,
          search: { searchField: '', searchText: searchTerm }
        },
        isMindbody: providerId === 1
      })
    );
  }, [
    chainId,
    membershipOffset,
    membershipPageSize,
    membershipSortField,
    membershipSortOrder,
    membershipImportTrigger,
    searchTerm,
    providerId
  ]);

  const menuItem = [
    {
      label: t('MEMBERSHIP_LIST.TABLE.CONTEXT_MENU_EDIT_MEMBERSHIP_DETAILS'), // TO-DO: Add Translation
      command: () => {
        history.push(`/gymChains/${chainId}/memberships/${selectedMembershipRow?.id}`);
      }
    },
    {
      label: t('MEMBERSHIP_LIST.TABLE.CONTEXT_MENU_DELETE_MEMBERSHIP_DETAILS'), // TO-DO: Add Translation
      command: () => checkMembershipDeletable()
    }
  ];

  const checkMembershipDeletable = async () => {
    setIsDeleteDialogVisible(true);
    setMembershipDeletableCheckLoading(true);
    try {
      const response = await (API.graphql({
        query: isMembershipLinkedToGym,
        variables: {
          membershipId: selectedMembershipRow?.id
        }
      }) as Promise<{
        data: { isMembershipLinkedToGym: DeletableResponse[] };
      }>);

      const index = response.data.isMembershipLinkedToGym.findIndex((res) => {
        return (
          res.tableName === 'SiteLocationMembership' ||
          res.tableName === 'SiteLocationAccessCategory'
        );
      });

      index > -1 ? setMembershipDeletableStatus(false) : setMembershipDeletableStatus(true);

      setMembershipDeletableCheckLoading(false);
    } catch (error) {
      console.log(error);
      setMembershipDeletableStatus(false);
      setMembershipDeletableCheckLoading(false);
    }
  };

  const importMembership = async () => {
    dispatch(toggleLoading());
    try {
      if (providerId === siteProviderId.mindbody) {
        const resp = await (API.graphql({
          query: importMemberships,
          variables: {
            siteId: chainId
          }
        }) as Promise<{
          data: { importMemberships: MembershipImport };
        }>);

        if (resp.data.importMemberships.error) {
          setErrorType(
            resp.data.importMemberships.errorType ? resp.data.importMemberships.errorType : 'ERROR'
          );
          setImportErrorDialogVisible(true);
        }
      } else if (providerId === siteProviderId.sodvin) {
        const response = await (API.graphql({
          query: importSodvinMemberships,
          variables: {
            siteId: chainId
          }
        }) as Promise<{
          data: { importSodvinMemberships: SodvinMembershipImport };
        }>);

        if (response.data.importSodvinMemberships.error) {
          setErrorType(
            response.data.importSodvinMemberships.errorMsg
              ? response.data.importSodvinMemberships.errorMsg
              : 'ERROR'
          );
          setImportErrorDialogVisible(true);
        }
      } else {
        console.log('Provider not set properly');
      }
    } catch (err) {
      setImportErrorDialogVisible(true);
      console.log('Import Memberships Error: ', err);
    }

    try {
      await API.graphql({
        query: updateImportMembershipSite,
        variables: {
          status: true,
          siteId: chainId
        }
      });

      setMembershipsImported(true);

      // To-Do: Check if this is the best way of doing it

      dispatch(
        fetchGymChains({
          offset: 0,
          pageSize: 500,
          sortField: 'id',
          sortOrder: 1,
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        })
      );
    } catch (err) {
      console.log('Update Import Memberships Status Error: ', err);
    }

    setMembershipImportTrigger(!membershipImportTrigger);
  };

  const tablePropsMemberships: TableProps<MembershipSiteTable> = {
    exportFileName: 'GymChainMemberships',
    setPageSize: setMembershipPageSize,
    setOffset: setMembershipOffset,
    pageSize: membershipPageSize,
    offset: membershipOffset,
    setSortField: setMembershipSortField,
    setSortOrder: setMembershipSortOrder,
    sortField: membershipSortField,
    sortOrder: membershipSortOrder,
    totalRecords: totalMembershipCount || 0,
    data: membershipData,
    searchTerm: searchTerm,
    setSearchTerm: setSearchTerm,
    columns: [
      {
        field: 'membershipName',
        exportHeader: 'Pricing Option',
        header: t('MEMBERSHIP_LIST.TABLE.HEADER_MEMBERSHIP_NAME'),
        toggable: false,
        sortable: true
      },
      {
        field: 'program',
        exportHeader: 'Service Category',
        header: t('MEMBERSHIP_LIST.TABLE.HEADER_MEMBERSHIP_PROGRAM'),
        toggable: true,
        truncateText: false,
        sortable: true
      },
      {
        field: 'scheduleType',
        exportHeader: 'Service Type',
        header: t('MEMBERSHIP_LIST.TABLE.HEADER_MEMBERSHIP_TYPE'),
        toggable: true,
        sortable: true
      },
      {
        field: 'importedId',
        exportHeader: 'Mindbody ID',
        header: t('MEMBERSHIP_LIST.TABLE.HEADER_MEMBERSHIP_IMPORTED_ID'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'displayIsImported',
        header: t('MEMBERSHIP_LIST.TABLE.HEADER_MEMBERSHIP_IMPORTED_FROM'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'displayHasRelatedAccessCategory',
        header: t('MEMBERSHIP_LIST.TABLE.HEADER_MEMBERSHIP_HAS_LINKED_ACCESS_CATEGORY'),
        toggable: true,
        truncateText: true,
        sortable: true
      },
      {
        field: 'siteLocations',
        header: t('MEMBERSHIP_LIST.TABLE.HEADER_MEMBERSHIP_SITE_LOCATIONS'),
        toggable: true,
        truncateText: false,
        sortable: true
      },
      {
        field: 'displayStatus',
        header: t('MEMBERSHIP_LIST.TABLE.HEADER_MEMBERSHIP_STATUS'),
        toggable: true,
        sortable: true,
        truncateText: true
      },
      {
        field: 'createdDate',
        header: t('MEMBERSHIP_LIST.TABLE.HEADER_MEMBERSHIP_CREATED_DATE'),
        toggable: true,
        truncateText: true,
        sortable: true
      }
    ],
    searchPlaceholderText: t('MEMBERSHIP_LIST.SEARCH_FIELD_TEXT_MEMBERSHIP'),
    excelBtntext: t('GYM_LIST.GYM_LIST_PAGE.TABLE.BUTTON_EXPORT_LABEL'),
    headerBtns:
      userRole === UserRole.SYSTEM_ADMIN || userRole === UserRole.GYM_CHAIN_USER
        ? [
            <div key={1}>
              <Button
                data-cy={'gym-chain-memberships-btn-import-membership'}
                label={t('MEMBERSHIP_LIST.TABLE.BUTTON_IMPORT_MEMBERSHIP')}
                onClick={importMembership}
                size={ButtonSizes.small}
              />
              {/* // TO-DO [Issue] - Have to fix Add membership button visibility when refreshing the page. */}
              {membershipsImported ? (
                <Button
                  data-cy={'gym-chain-memberships-btn-add-membership'}
                  icon="pi-plus"
                  label={t('MEMBERSHIP_LIST.TABLE.BUTTON_ADD_MEMBERSHIP')}
                  onClick={() => history.push(`/gymChains/${chainId}/memberships/new`)}
                  size={ButtonSizes.small}
                />
              ) : (
                <></>
              )}
            </div>
          ]
        : [],

    selectedRow: selectedMembershipRow,
    setSelectedRow: setSelectedMembershipRow,
    isContextMenu:
      userRole === UserRole.SYSTEM_ADMIN || userRole === UserRole.GYM_CHAIN_USER ? true : false,
    menuItem:
      userRole === UserRole.SYSTEM_ADMIN || userRole === UserRole.GYM_CHAIN_USER ? menuItem : [],
    emptyStateTexts: [t('MEMBERSHIP_LIST.EMPTY_STATE_TEXT')]
  };

  const deleteDialogHandler = async () => {
    setIsDeleting(true);
    selectedMembershipRow &&
      (await dispatch(
        deleteMembership({ id: selectedMembershipRow.id, gymChainId: selectedMembershipRow.siteId })
      ));
    setIsDeleting(false);
    setIsDeleteDialogVisible(false);
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        {membershipDeletableCheckLoading ? (
          <></>
        ) : !membershipDeletableStatus ? (
          <Button
            label={t('COMMON.OK')}
            variant={ButtonVariants.basic}
            onClick={() => setIsDeleteDialogVisible(false)}
          />
        ) : (
          <>
            <Button
              data-cy={'gym-chain-memberships-delete-dialog-btn-cancel'}
              label={t('MEMBERSHIP_LIST.TABLE.DELETE_MODAL_CANCEL')}
              variant={ButtonVariants.textonly}
              onClick={() => setIsDeleteDialogVisible(false)}
            />
            <Button
              data-cy={'gym-chain-memberships-delete-dialog-btn-delete'}
              label={t('MEMBERSHIP_LIST.TABLE.DELETE_MODAL_DELETE')}
              variant={ButtonVariants.danger}
              onClick={deleteDialogHandler}
              icon={isDeleting ? 'pi-spinner pi-spin' : ''}
            />
          </>
        )}
      </div>
    );
  };

  const ErrorDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.OK')}
          variant={ButtonVariants.textonly}
          onClick={() => setImportErrorDialogVisible(false)}
        />
      </div>
    );
  };

  return (
    <div>
      <DialogBox
        dialogVisible={isDeleteDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setIsDeleteDialogVisible(false)}
        dialogFooter={<DeleteDialogFooter />}
        dialogClosable={false}
        dialogHeader={
          membershipDeletableCheckLoading ? <></> : t('MEMBERSHIP_LIST.TABLE.DELETE_MODAL_HEADER')
        }
        content={
          membershipDeletableCheckLoading ? (
            <>
              <div className="mb-3">
                <Loader shape="rectangle" width="full" />
              </div>
              <div className="mb-3">
                <Loader shape="rectangle" width="full" />
              </div>
              <div className="mb-3">
                <Loader shape="rectangle" width="half" />
              </div>
            </>
          ) : (
            <Text data-cy={'gym-chain-memberships-delete-dialog-content'}>
              {membershipDeletableStatus
                ? `${t('MEMBERSHIP_LIST.TABLE.DELETE_MODAL_MESSAGE')} ${
                    selectedMembershipRow?.membershipName
                  }?`
                : t('MEMBERSHIP_LIST.TABLE.DELETE_MODAL_CANNOT')}
            </Text>
          )
        }
      />
      <DialogBox
        dialogVisible={importErrorDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setImportErrorDialogVisible(false)}
        dialogFooter={<ErrorDialogFooter />}
        dialogClosable={false}
        dialogHeader={t('IMPORT_MEMBERSHIP.IMPORT_ERROR_HEADER')}
        content={
          <Text>
            {errorType === 'PARTIAL_ERROR'
              ? t('IMPORT_MEMBERSHIP.IMPORT_PARTIAL_ERROR_MSG')
              : t('IMPORT_MEMBERSHIP.IMPORT_ERROR_MSG')}
          </Text>
        }
      />
      {isMembershipsLoading ? <Loader shape="table" /> : <Table {...tablePropsMemberships} />}
    </div>
  );
};

interface MembershipsListTableProps {
  chain: number;
  membershipsImported: boolean;
}
