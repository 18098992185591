import { Button as PrimeButton } from 'primereact/button';

import styles from './Button.module.scss';

export enum ButtonVariants {
  basic = 'p-button-basic',
  outline = 'p-button-outlined',
  textonly = 'p-button-text',
  success = 'p-button-success',
  warning = 'p-button-warning',
  danger = 'p-button-danger',
  textonlyslim = 'p-button-text-only-slim'
}

export enum ButtonSizes {
  small = 'p-button-sm',
  medium = 'p-button',
  large = 'p-button-lg'
}

const Button = ({
  label,
  variant = ButtonVariants.basic,
  size = ButtonSizes.medium,
  icon,
  iconPos,
  disabled = false,
  block = false,
  ariaLabel = label || '',
  ...props
}: ButtonProps) => {
  const blockStyle = block ? styles.blockButton : null;

  const finalProps = {
    label: label,
    className: `${variant} ${size} ${blockStyle}`,
    icon: icon && `pi ${icon}`,
    disabled: disabled,
    'aria-label': ariaLabel,
    iconPos,
    ...props
  };
  return <PrimeButton {...finalProps} />;
};

export interface ButtonProps {
  /** Label to be displayed */
  label?: string;
  /** Which variant to use. Use the exported ButtonVariants enum */
  variant?: ButtonVariants;
  /** Size of the button. Use the exported ButtonSizes enum. See `block` prop for full-width buttons. */
  size?: ButtonSizes;
  /**
   * Which icon to use from the PrimeIcons library. (Try: pi-search, pi-cog, pi-save)
   * For a complete list, see https://www.primefaces.org/primereact/showcase/#/icons
   */
  icon?: string;
  /** Icon Position. Default is left. You can make it "right" as well */
  iconPos?: string;
  /** Disable clicking this button */
  disabled?: boolean;
  /** Make the button expand to fill parent */
  block?: boolean;
  /** Click handler */
  onClick?: React.MouseEventHandler;
  /** Adding aria label to button  */
  ariaLabel?: string;
}

export default Button;
