import Image from '@gym-atoms/Image/Image';
import DashboardCounts from '@gym-molecules/DashboardCounts/DashboardCounts';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import Input from '@gym-atoms/Input/Input';
import { Divider } from 'primereact/divider';
import Button, { ButtonSizes } from '@gym-atoms/Button/Button';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { GetSearchedMembersForSiteLocationQueryVariables } from '@gym-src/API';
import { API } from 'aws-amplify';
import { getSearchedMembersForSiteLocation } from '@gym-graphql/queries';
import { useEffect, useRef, useState } from 'react';
import { fetchAccessPoints } from '@gym-redux/slices/accessPointsSlice';
import useDebounce from '@gym-particles/debounce';
import { MemberAccessCard } from '@gym-particles/types/Member';
import AccessCardDashboardView from '@gym-organisms/AccessCards/AccessCardDashboardView';
import AccessCardIssueModal from '@gym-organisms/AccessCards/AccessCardIssueModal';
import { AccessPoints } from '@gym-particles/types/AccessPoints';
import GymDashboardHeader from '@gym-organisms/GymDashboardHeader/GymDashboardHeader';
import { GymDashboardDetailsHeaderTabNames } from '@gym-particles/types/GymDashboardDetails';
import Loader from '@gym-atoms/Loader/Loader';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';
import DoorOpenModal from '@gym-organisms/DoorOpenModal/DoorOpenModal';
import EmptyState from '@gym-molecules/EmptyState/EmptyState';
import { Toast } from 'primereact/toast';
import { UserRole } from '@gym-particles/types/User';
import maleUserSearchLogo from '@gym-assets/images/Male-user-search-icon.png';

const UpperMainColumn = () => {
  const { chainId, gymId, tab } = useParams<{ chainId: string; tab: string; gymId: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const userRole = useAppSelector((state) => state.user.userRole);

  const resyncGymFn = () => {
    // TODO: resync gym function
    console.log('resync function');
  };

  return (
    <div>
      <Card>
        <div className="d-flex justify-content align-items-center gap-1">
          <Button
            label={t('UPPER_MAIN_COLUMN.CLASS_PORTAL_BUTTON')}
            onClick={() => history.push(`/classPortal/${chainId}/${gymId}`)}
          />
          <Button
            label={t('UPPER_MAIN_COLUMN.EMPLOYEE_DASHBOARD_BUTTON')}
            onClick={() => history.push(`/employeeDashboard/${chainId}/${gymId}`)}
          />
        </div>
      </Card>
      {userRole !== UserRole.GYM_USER || chainId === '4' ? (
        <div>
          <Card>
            <GymDashboardHeader
              currentGymChainId={+chainId}
              currentGymId={+gymId}
              currentTab={tab as GymDashboardDetailsHeaderTabNames}
              headerButtonOnClick={resyncGymFn}
            />
          </Card>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const UpperSecondColumn = () => {
  const todayUserCount = 200;
  const activeUserCount = 300532;
  const errorCount = 30;

  return (
    <div>
      <DashboardCounts
        todayUserCount={todayUserCount}
        activeUserCount={activeUserCount}
        errorCount={errorCount}
      />
    </div>
  );
};

const LowerMainColumn = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { gymId, chainId } = useParams<{ gymId: string; chainId: string }>();
  const toast = useRef<any>();

  useEffect(() => {
    dispatch(getGymChainById(+chainId));
  }, [chainId]);

  const accessPoints = useAppSelector(
    (state) => state.accessPoint.items.find((g) => g.siteLocationId === +gymId)?.items
  );

  const doors: AccessPoints[] = [];
  accessPoints?.forEach((point) => {
    if (point.accessPointType === 'door') {
      doors.push(point);
    }
  });

  useEffect(() => {
    dispatch(
      fetchAccessPoints({
        siteLocationId: +gymId
      })
    );
  }, [gymId]);
  const [selectedDoor, setselectedDoor] = useState<AccessPoints>();
  const [doorOpenModalVisible, setdoorOpenModalVisible] = useState(false);

  const openDoor = (id: number) => {
    setselectedDoor(doors[id]);
    setdoorOpenModalVisible(true);
  };

  return (
    <Card>
      <Text color="#2d3748" bold>
        {t('GYM_DASHBOARD_PAGE.LOWER_MAIN_COLUMN_HEADER')}
      </Text>
      <Divider />
      {doors.length === 0 ? (
        <>
          <EmptyState textItems={[t('GYM_DASHBOARD_PAGE.NO_DOORS_AVAILABLE')]} />
        </>
      ) : (
        <div>
          {doors.map((door) => (
            <div
              className="d-flex justify-content-between align-items-center mb15 "
              key={doors.indexOf(door)}>
              <Text bold size={TextSizes.t14}>
                {door.doorName}
              </Text>

              <Button
                onClick={() => openDoor(doors.indexOf(door))}
                label={t('GYM_DASHBOARD_PAGE.LOWER_MAIN_COLUMN_BUTTON_LABEL_OPEN')}
                size={ButtonSizes.small}></Button>
            </div>
          ))}
        </div>
      )}
      {
        <DoorOpenModal
          visible={doorOpenModalVisible}
          door={selectedDoor}
          onHideCallBack={() => setdoorOpenModalVisible(false)}
          toastRef={toast}
        />
      }
      <Toast ref={toast} />
    </Card>
  );
};

const LowerSecondColumn = () => {
  const { t } = useTranslation();
  const { gymId } = useParams<{ chainId: string; gymId: string }>();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [searchTextTerm, setSearchTextTerm] = useState<string | undefined>();
  const debouncedSearchTerm = useDebounce(searchTextTerm, 1000);
  const [searchedMembers, setSearchedMembers] = useState<MemberAccessCard[] | undefined>();
  const [selectedMember, setselectedMember] = useState<MemberAccessCard | undefined>();
  const [accessCardIssueModalVisible, setAccessCardIssueModalVisible] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState(false);

  const accesspoints = useAppSelector(
    (state) => state.accessPoint.items.find((gym) => gym.siteLocationId === +gymId)?.items
  );
  /* filter doors from access points. */
  const doors: AccessPoints[] = [];
  accesspoints?.forEach((point) => {
    if (point.accessPointType == 'door') {
      doors.push(point);
    }
  });

  useEffect(() => {
    if (debouncedSearchTerm) {
      if (searchTextTerm && searchTextTerm !== '') {
        setSearchText(debouncedSearchTerm);
      } else if (searchTextTerm === '') {
        setSearchText(undefined);
        setSearchedMembers(undefined);
      }
    }
  }, [debouncedSearchTerm, searchTextTerm, setSearchTextTerm]);

  useEffect(() => {
    if (searchText) {
      searchMembers();
    }
  }, [searchText, setSearchText]);

  const getMembers = async () => {
    const requestData: GetSearchedMembersForSiteLocationQueryVariables = {
      siteLocationId: +gymId,
      searchTerm: searchText
    };

    try {
      const result = await (API.graphql({
        query: getSearchedMembersForSiteLocation,
        variables: requestData
      }) as Promise<{
        data: {
          getSearchedMembersForSiteLocation: MemberAccessCard[];
        };
      }>);
      return result.data.getSearchedMembersForSiteLocation;
    } catch (e) {
      console.log(e);
    }
  };

  const searchMembers = async () => {
    if (searchText) {
      setisLoading(true);
      const response = await getMembers();
      if (response && response !== null) {
        setSearchedMembers(response);
      }
      setisLoading(false);
    }
  };

  const handleAccessCardCallBack = (memberId: number): void => {
    setselectedMember(searchedMembers?.find((member) => member.memberId === memberId));
    setAccessCardIssueModalVisible(true);
  };

  const defaultMember: MemberAccessCard = {
    memberId: -1,
    userId: -1,
    arxReferenceId: '',
    firstName: '',
    lastName: '',
    imageUrl: '',
    hasAccessCard: false
  };

  return (
    <Card>
      <Text color="#2d3748" bold>
        {t('GYM_DASHBOARD_PAGE.ISSUE_ACCESS_CARD')}
      </Text>
      <Input
        icon="pi pi-search"
        variant="icon_left"
        placeholder={t('GYM_DASHBOARD_PAGE.ISSUE_ACCESS_CARD_SEARCH_PLACEHOLDER')}
        value={searchTextTerm || ''}
        onChange={(e) => setSearchTextTerm(e.currentTarget.value)}
      />
      {!searchedMembers && !isLoading && (
        <div className="d-flex flex-column align-items-center">
          <div className="mb10">
            <Image width={50} source={maleUserSearchLogo} />
          </div>
          <Text bold>{t('GYM_DASHBOARD_PAGE.ISSUE_ACCESS_CARD_PLACEHOLDER')}</Text>
        </div>
      )}

      {searchedMembers && !isLoading && searchedMembers.length === 0 && (
        <div className="text-center mx-auto">
          <Text bold>{`${t('GYM_DASHBOARD_PAGE.ISSUE_ACCESS_CARD_EMPTY_SEARCH_TEXT')} "${
            searchText || ''
          }".`}</Text>
        </div>
      )}

      {isLoading && (
        <div>
          <div className="mb10">
            <Loader shape={'rectangle'} width={'quarter'} />
          </div>
          <div className="mb10">
            <Loader shape={'rectangle'} width={'full'} />
          </div>
          <div className="mb10">
            <Loader shape={'rectangle'} width={'full'} />
          </div>
          <div className="mb10">
            <Loader shape={'rectangle'} width={'full'} />
          </div>
        </div>
      )}

      {!isLoading && (
        <div>
          {searchedMembers &&
            searchedMembers.length !== 0 &&
            searchedMembers.map((member) => (
              <AccessCardDashboardView
                key={member.memberId}
                member={member || defaultMember}
                onClickCallBack={handleAccessCardCallBack}
              />
            ))}
        </div>
      )}

      {
        <AccessCardIssueModal
          visible={accessCardIssueModalVisible}
          onHideCallBack={() => setAccessCardIssueModalVisible(false)}
          member={selectedMember || defaultMember}
          accessPoints={doors}
        />
      }
    </Card>
  );
};

const GymDashboardPage = () => {
  const userRole = useAppSelector((state) => state.user.userRole);

  return (
    <div className="page-content flex-grow-1">
      <div className="page-wrapper--inner">
        <div className="">
          <div className="row">
            {/* --Sodvin Release-- */}
            {/* <div className="col-lg-8 col-xl-9"> */}
            <UpperMainColumn />
            {/* </div> */}
            {/* <div className="col-lg-4 col-xl-3">
              <UpperSecondColumn />
            </div> */}
          </div>
        </div>
        {userRole === UserRole.GYM_USER ? (
          <div>
            <div className="" hidden>
              <div className="row">
                <div className="col-lg-8 col-xl-9">
                  <LowerMainColumn />
                </div>
                <div className="col-lg-4 col-xl-3">
                  <LowerSecondColumn />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default GymDashboardPage;
