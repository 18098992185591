export const RESET_STAGE_USERNAME = {
  STAGE: 'USERNAME',
  HELPER_TEXT: 'AUTH_FORGOT_PASSWORD.ENTER_EMAIL_TO_RESET'
};

export const RESET_STAGE_CODE = {
  STAGE: 'RESET_CODE',
  HELPER_TEXT: 'AUTH_FORGOT_PASSWORD.ENTER_RESET_CODE_TO_SET_NEW_PASSWORD'
};

export const RESET_STAGE_NEW_PASSWORD = {
  STAGE: 'NEW_PASSWORD',
  HELPER_TEXT: 'AUTH_FORGOT_PASSWORD.ENTER_NEW_PASSWORD'
};
