import { scheduleType } from '@gym-atoms/Scheduler/Scheduler';

export interface GymScheduleType {
  siteId: number;
  siteName: string;
  siteScheduleId: number;
  siteScheduleName: string;
  siteScheduleType: string;
  validFrom: string;
  validTo: string;
  scheduleStatusId: number;
  scheduleStatusName: string;
  siteScheduleStatus: string;
  siteScheduleCreatedBy: number;
  siteScheduleCreatedDate: string;
  siteScheduleLastModifiedBy: number;
  siteScheduleLastModifiedDate: string;
  siteScheduleDetailId: number;
  weekdayId: number;
  weekdayName: string;
  startTime: string;
  endTime: string;
  dayScheduleId: number;
  siteScheduleDetailCreatedBy: number;
  siteLocationScheduleId: number;
  siteLocationId: number;
  siteLocationName: string;
  zoneId: number;
  zoneName: string;
  siteLocationAccessCategoryId: number;
  accessCategoryName: string;
  locationAccessPointId: number;
  locationAccessPointName: string;
  siteLocationScheduleCreatedBy: number;
  siteScheduleDetailStatus: string;
  imageUrl: string;
}

export interface GymScheduleTable {
  id: number;
  gymChainId: number;
  gymChainName: string;
  gymScheduleName: string;
  gymScheduleType: string;
  startingDate: string;
  endingDate: string;
  scheduleProgressionStatus: string;
  siteScheduleStatus: string;
  createdDate: string;
  scheduleDetails: [
    {
      gymId: number;
      gymName: string;
      zoneId: number;
      zone: string;
      accessCategory: [string];
      accessPoint: [string];
    }
  ];
}

export type GymSchedulesFormInput = {
  type: string;
  name: string;
  validFromDate: Date | undefined;
  validToDate: Date | undefined;
  status: string;
  createdBy: number;
  lastModifiedBy: number;
};

// TO-DO: Should consider removing

export type Gym = {
  gymName: string;
  imageUrl?: string;
};

export type Option = {
  name: string;
};

export type GymSchedulesAccessPointMapping = {
  locationAccessPointId: number;
  siteId: number;
  siteLocationId: number;
  locationZoneId: number;
  locationZoneName: string;
  zoneAccessPointId: number;
  doorARXId: string;
  doorName: string;
  status: string;
};

export type GymSchedulesGymZoneMapping = {
  locationAccessCategoryId: number;
  locationAccessCategoryName: string;
  locationAccessCategoryZoneId: number;
  locationArxAccessCategoryId: string;
  locationName: string;
  locationZoneId: number;
  membershipId: number;
  membershipName: string;
  siteId: number;
  siteLocationId: number;
  siteLocationMembershipId: number;
  zoneDescription: string;
  zoneName: string;
};

export type ZoneMapping = {
  siteLocationId: number;
  imageUrl?: string;
  zoneName: string;
  locationZoneId: number;
};

export type GymScheduleZoneMapping = {
  locationName: string;
  siteLocationId: number;
  zones: ZoneMapping[];
  locationZoneId: number;
};

export type AccessCategoriesMapping = {
  locationZoneId: number;
  siteLocationId: number;
  locationAccessCategoryId: number;
  locationAccessCategoryName: string;
  locationArxAccessCategoryId: string;
};

export type GymScheduleAccessCategoryMapping = {
  imageUrl?: string;
  zoneName: string;
  locationZoneId: number;
  accessCategories: AccessCategoriesMapping[];
};

export type AccessPointMapping = {
  locationZoneId: number;
  siteLocationId: number;
  doorARXId: string;
  doorName: string;
  locationAccessPointId: number;
};

export type GymScheduleAccessPointMapping = {
  imageUrl?: string;
  zoneName: string;
  locationZoneId: number;
  accessPoints: AccessPointMapping[];
};

export type SiteScheduleDetails = {
  weekdayId: number;
  startTime: string;
  endTime: string;
  dayScheduleId: number;
  createdBy: number;
  lastModifiedBy: number;
  status: string;
};

export type ScheduleEvent = {
  id?: number;
  dayScheduleId?: number;
  title: string;
  start: Date;
  end: Date;
  isAvailable?: scheduleType;
  weekDayId?: number;
  status?: string;
  scheduleStatusId?: number;
};

export type GymScheduleStatus = {
  id: number;
  name: string;
};

export enum ScheduleInternalStatus {
  Active = 'active',
  Inactive = 'inactive',
  PartiallySaved = 'partially saved',
  PendingScheduleAllocation = 'pending schedule allocation'
}
