const base64toFile = (url: string, fileName: string) => {
  const byteString = atob(url.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const mimeType = url.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/) || ['image/jpeg'];
  const newBolb = new Blob([ab], { type: mimeType[0] });
  const imageFile = new File([newBolb], fileName);
  return imageFile;
};

export default base64toFile;
