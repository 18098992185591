import { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import Dropdown, { DropdownChangeEvent, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import Upload from '@gym-atoms/Upload/Upload';
import Input from '@gym-atoms/Input/Input';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Text from '@gym-atoms/Text/Text';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import * as Validator from '@gym-particles/validations/Validator';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import {
  UserType,
  UserFormInput,
  groupedType,
  groupedChildrenType,
  SiteLocationUserType,
  UserRole,
  staffAccessMembershipGymType
} from '@gym-particles/types/User';
import {
  getAllRoles,
  getGymChains,
  getGymsOfGymChain,
  getSiteLocationUser
} from '@gym-graphql/queries';
import {
  Gym,
  Role,
  SiteLocationUser,
  UserManagementResponse,
  WebGymChain,
  WebUserInput,
  WebUserModifyInput
} from '@gym-src/API';
import { fetchUsers } from '@gym-redux/slices/userManagementSlice';
import { addWebUser, updateWebUser } from '@gym-graphql/mutations';
import Alert, { AlertType, clearAlert, createAlert } from '@gym-atoms/Alert/Alert';
import { logout } from '@gym-redux/slices/userSlice';

const UserManagementTemplate = (props: UserManagementTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [imgUploadStatus, setImgUploadStatus] = useState('NONE');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState<string | null>(null);
  const [roleDropDownItems, setRoleDropDownItems] = useState<Array<SelectItem>>([]);
  const [isInserted, setIsInserted] = useState(false);
  const [accessViaMembership, setAccessViaMembership] = useState(false);
  const userRole = useAppSelector((state) => state.user.userRole);
  const [selectedGymChains, setselectedGymChains] = useState<Array<string>>([]);
  const [selectedGroupedGymChains, setSelectedGroupedGymChains] = useState<Array<any>>([]);
  const [staffAccessMembershipGyms, setStaffAccessMembershipGyms] = useState<
    staffAccessMembershipGymType[]
  >([]);
  const alertRef = useRef<AlertType>(null);
  const roleAlertRef = useRef<AlertType>(null);
  const emailAlertRef = useRef<AlertType>(null);
  const languageAlertRef = useRef<AlertType>(null);
  const [groupedGymChains, setGroupedGymChains] = useState<Array<groupedType>>([]);
  const [gymchainDropDownItems, setGymchainDropDownItems] = useState<Array<SelectItem>>([]);

  const [siteLocationUsers, setSiteLocationUsers] = useState<Array<SiteLocationUserType>>([]);
  // To-Do: Check the loading logic when the loader is implemented
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [provideAccessViaMembership, setProvideAccessViaMembership] = useState(false);
  const [isGymsLoading, setIsGymsLoading] = useState<boolean>(false);
  const [isGymsChainsLoading, setIsGymChainsLoading] = useState<boolean>(false);
  const [isRolesLoading, setIsRolesLoading] = useState<boolean>(false);
  const gymsAlertRef = useRef<AlertType>(null);
  const [redirect, setRedirect] = useState<{ goBack: boolean; message?: string }>({
    goBack: false
  });
  const [gymsAlertVisible, setGymsAlertVisible] = useState(false);

  const getTranslationKey = (e: string): string => {
    return props.type === 'new' ? 'ADD_NEW_USER.' + e : 'MODIFY_USER.' + e;
  };

  const languageDropdownItems = [
    { label: t(getTranslationKey('FORM_DROPDOWN_LANGUAGE.EN')), value: 'EN' },
    {
      label: t(getTranslationKey('FORM_DROPDOWN_LANGUAGE.NL')),
      value: 'NL'
    }
  ];

  const countryCodeDropdownItems = [
    { label: '(+31) NL', value: '+31' },
    { label: '(+47) NO', value: '+47' },
    { label: '(+46) SE', value: '+46' },
    { label: '(+44) UK', value: '+44' },
    { label: '(+94) LK', value: '+94' }
  ];

  const extractCountryCode = (phone: string) => {
    if (phone.charAt(0) === '+') {
      return phone.substring(0, 3);
    } else {
      return '';
    }
  };

  const extractPhone = (phone: string) => {
    if (phone.charAt(0) === '+') {
      return phone.substring(3);
    } else {
      return phone;
    }
  };

  const defaultValues: UserFormInput = {
    firstName: props.user?.firstName || '',
    lastName: props.user?.lastName || '',
    email: props.user?.email || '',
    countryCode: extractCountryCode(props.user?.phone || '') || countryCodeDropdownItems[0].value,
    phone: extractPhone(props.user?.phone || '') || '',
    password: '',
    confirmPassword: '',
    role: props.user?.roleId || 0,
    gymChainId: selectedGymChains,
    gym: selectedGroupedGymChains,
    profilePicturePath: props.user?.profilePicturePath || '',
    accesViaMembership: props.user?.memberId ? false : true,
    language: props.user?.language || ''
  };

  const emptyValues: UserFormInput = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: countryCodeDropdownItems[0].value,
    phone: '',
    password: '',
    confirmPassword: '',
    role: -1,
    gymChainId: [],
    gym: [],
    profilePicturePath: '',
    accesViaMembership: false,
    language: props.user?.language || ''
  };

  const [formValues, setFormValues] = useState(defaultValues);

  const { register, handleSubmit, setValue, errors, reset, getValues } = useForm<UserFormInput>({
    defaultValues: defaultValues
  });

  const userInfo = useAppSelector((state) => state.userManagement.items).find(
    (e) => e.userId === props.user?.userId
  );

  const userId = useAppSelector((state) => state.user.userId);

  const [gymChains, setGymChains] = useState<Array<WebGymChain>>([]);

  const getAllGymChains = () => {
    const gymChainsDropDownItems = gymChains.map((data) => {
      return {
        label: data.customerName || '',
        value: String(data.id)
      };
    });

    setGymchainDropDownItems(gymChainsDropDownItems);
  };

  const getGyms = async () => {
    setIsGymsLoading(true);
    const selectedGyms: groupedType[] = [];
    try {
      for (let index = 0; index < selectedGymChains.length; index++) {
        const chainId = selectedGymChains[index];
        const response = await (API.graphql({
          query: getGymsOfGymChain,
          variables: {
            id: +chainId,
            offset: 0,
            pageSize: 0,
            sortField: 'siteId',
            sortOrder: 'ASC',
            userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
          }
        }) as Promise<{
          data: { getGymsOfGymChain: { items: Gym[]; totalRecords: { totalRecords: number } } };
        }>);
        let chainName = '';
        const groupedGyms: groupedChildrenType[] = response.data.getGymsOfGymChain.items.map(
          (data) => {
            chainName = data.gymChainName || '';

            const staffMembershipsGyms: staffAccessMembershipGymType = {
              siteId: data.gymChainId || 0,
              siteLocationId: data.gymId || 0,
              siteLocationName: data.gymName || '',
              staffAccessMembershipId: data.staffMembershipId || -1
            };
            const index = staffAccessMembershipGyms.findIndex(
              (x) =>
                x.siteLocationId === staffMembershipsGyms.siteLocationId &&
                x.siteId === staffMembershipsGyms.siteId
            );
            index === -1 ? staffAccessMembershipGyms.push(staffMembershipsGyms) : undefined;
            return {
              label: data.gymName || '',
              value: {
                siteId: data.gymChainId || 0,
                siteLocationId: data.gymId || 0
              }
            };
          }
        );

        const model: groupedType = {
          label: chainName,
          items: groupedGyms
        };
        selectedGyms.push(model);
      }
      clearAlert(gymsAlertRef);
      setGymsAlertVisible(false);
    } catch (error) {
      if (!gymsAlertVisible) {
        createAlert(
          gymsAlertRef,
          {
            content: t(getTranslationKey('ALERT_GYMS_FETCH_FAILED')),
            severity: 'error',
            sticky: true
          },
          'replace'
        );
        setGymsAlertVisible(true);
      }
      console.error(error);
    } finally {
      setIsGymsLoading(false);
    }
    setGroupedGymChains(selectedGyms);
  };

  const getSiteLocationUserRecords = async (userId: number) => {
    const response = await (API.graphql({
      query: getSiteLocationUser,
      variables: {
        userId: userId
      }
    }) as Promise<{
      data: { getSiteLocationUser: [SiteLocationUser] };
    }>);

    setSiteLocationUsers(response.data.getSiteLocationUser);
  };

  const setSelectedGymChainsAndGyms = () => {
    const defaultSelectedGymChains: string[] = siteLocationUsers.map((i) => {
      return String(i.siteId);
    });

    setselectedGymChains([...new Set(defaultSelectedGymChains)] || []);

    const defaultSelectedGyms = siteLocationUsers.map((i) => {
      return {
        siteId: i.siteId,
        siteLocationId: i.locationId
      };
    });

    setSelectedGroupedGymChains(defaultSelectedGyms || []);
  };

  const getRoles = async () => {
    setIsRolesLoading(true);
    setRedirect({ goBack: false });
    try {
      const rolesRes = await (API.graphql({
        query: getAllRoles
      }) as Promise<{
        data: { getAllRoles: [Role] };
      }>);

      const roleDropDownItems: SelectItem[] = rolesRes.data.getAllRoles.map((data) => {
        if (data && data.roleName && data.roleId) {
          const role =
            data.roleId == 1
              ? t('USER_ROLE.SYSTEM_ADMIN')
              : data.roleId == 2
              ? t('USER_ROLE.GYM_CHAIN_USER')
              : data.roleId == 4
              ? t('USER_ROLE.GYM_USER')
              : '';
          return {
            label: role.charAt(0).toUpperCase() + role.slice(1),
            value: data.roleId
          };
        } else {
          return { label: '', value: '' };
        }
      }) || [{ label: '', value: '' }];

      setRoleDropDownItems(roleDropDownItems);
    } catch (error) {
      if (!failedDialogVisible) {
        setRedirect({ goBack: true, message: t(getTranslationKey('USER_ROLES_FETCH_FAILED')) });
        setFailedDialogVisible(true);
      }
      console.error(error);
    } finally {
      setIsRolesLoading(false);
    }
  };

  useEffect(() => {
    if (props.type === 'new') {
      return;
    }

    if (userInfo && userInfo.userId > 0) {
      getSiteLocationUserRecords(userInfo.userId);
    }

    const accessViaMembership = props.user?.memberId ? true : false;
    setAccessViaMembership(accessViaMembership);
  }, []);

  useEffect(() => {
    fetchGymChains();
  }, [siteLocationUsers]);

  useEffect(() => {
    getAllGymChains();
  }, [gymChains]);

  const fetchGymChains = async () => {
    setIsGymChainsLoading(true);
    setRedirect({ goBack: false });
    try {
      const apiResponse = await (API.graphql({
        query: getGymChains,
        variables: {
          offset: 0,
          pageSize: 0,
          sortField: 'id',
          sortOrder: 1,
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        }
      }) as Promise<{
        data: { getGymChains: { items: WebGymChain[]; totalRecords: { totalRecords: number } } };
      }>).then((e) => {
        setGymChains(e.data.getGymChains.items);
        if (props.type === 'modify') {
          setSelectedGymChainsAndGyms();
        }
      });
    } catch (error) {
      console.error('fetch gym chains failed');
      if (!failedDialogVisible) {
        setRedirect({ goBack: true, message: t(getTranslationKey('GYM_CHAINS_FETCH_FAILED')) });
        setFailedDialogVisible(true);
      }
    } finally {
      setIsGymChainsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchUsers(userId));
  }, []);

  useEffect(() => {
    getGyms();
  }, [selectedGymChains]);

  const uploadHandler = (f: File | null) => {
    setValue('profilePicturePath', f?.toString());
  };

  useEffect(() => {
    getRoles();
  }, []);

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => {
            setFailedDialogVisible(false);
            redirect.goBack && history.push('/users');
          }}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => {
                setFailedDialogVisible(false);
                redirect.goBack && history.push('/users');
              }}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={
            <Text>
              {redirect.message
                ? redirect.message
                : dialogErrorMessage
                ? dialogErrorMessage
                : t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}
            </Text>
          }
        />
      </div>
    );
  };

  const addNewUser = async (data: UserFormInput) => {
    try {
      const siteLocationData = selectedGroupedGymChains.map((siteLocationInfo) => {
        return siteLocationInfo.siteLocationId;
      });

      const webUserInput: WebUserInput = {
        firstName: data.firstName.replace(/'/g, "''"),
        lastName: data.lastName.replace(/'/g, "''"),
        email: data.email,
        phone:
          data.phone.charAt(0) == '0'
            ? data.countryCode + data.phone.substring(1)
            : data.countryCode + data.phone,
        createdBy: userId,
        password: data.password.replace(/'/g, "''"),
        accessViaMembership: accessViaMembership,
        siteLocationData: siteLocationData,
        roleId: data.role,
        imageUrl: data.profilePicturePath || '',
        language: data.language || ''
      };

      const response = (await API.graphql({
        query: addWebUser,
        variables: {
          input: webUserInput
        }
      })) as { data: { addWebUser: UserManagementResponse } };

      if (!response.data.addWebUser.success) {
        // To-do: Set error message from the FE with translations.
        setDialogErrorMessage(
          response.data.addWebUser.errorMsg || 'Failed to add user. Please try again.'
        );
        setFailedDialogVisible(true);
        console.log('addUser err: ', response.data.addWebUser.errorMsg);

        return;
      }

      resetForm();

      history.push('/users');
    } catch (err: any) {
      setFailedDialogVisible(true);
      console.log('Add user error: ', err);
    }
  };

  const modifyUser = async (data: UserFormInput) => {
    try {
      const siteLocationData = selectedGroupedGymChains.map((siteLocationInfo) => {
        return siteLocationInfo.siteLocationId;
      });

      const userRoleChanged = props.user?.roleId === data.role ? false : true;
      const userEmailChanged = props.user?.email === data.email ? false : true;
      const userlanguageChanged = props.user?.language === data.language ? false : true;

      const webUserInput: WebUserModifyInput = {
        userId: props.user?.userId || -1,
        firstName: data.firstName.replace(/'/g, "''"),
        lastName: data.lastName.replace(/'/g, "''"),
        email: props.user?.email || '',
        updatedEmail: data.email,
        updatedPhone:
          data.phone.charAt(0) == '0'
            ? data.countryCode + data.phone.substring(1)
            : data.countryCode + data.phone,
        phone: props.user?.phone || '',
        lastModifiedBy: userId,
        memberId: props.user?.memberId || -1,
        accessViaMembership: accessViaMembership,
        siteLocationData: siteLocationData,
        roleId: data.role,
        userRoleChanged: userRoleChanged,
        imageUrl: data.profilePicturePath || '',
        language: data.language || '',
        memberArxReferenceId: props.user?.memberArxReferenceId || ''
      };

      const response = (await API.graphql({
        query: updateWebUser,
        variables: {
          input: webUserInput
        }
      })) as { data: { updateWebUser: UserManagementResponse } };

      if (!response.data.updateWebUser.success) {
        // To-do: Set error message from the FE with translations.
        setDialogErrorMessage(
          response.data.updateWebUser.errorMsg ||
            t(getTranslationKey('EMAIL_PASSWORD_EXISTS_ERROR_MESSAGE'))
        );

        setFailedDialogVisible(true);
        console.log('modify User err: ', response.data.updateWebUser.errorMsg);

        return;
      }

      resetForm();
      if (props.user?.userId === userId) {
        if (userRoleChanged || userEmailChanged || userlanguageChanged) {
          dispatch(logout());
          Auth.signOut();
          history.push('/login');
        }
      }

      history.push('/users');
    } catch (err) {
      setFailedDialogVisible(true);
      console.log('Modify user err: ', err);
    }
  };

  const onSubmitHandler = async (data: UserFormInput) => {
    setIsInserted(true);
    if (props.type === 'new') {
      await addNewUser(data);
    } else if (props.type === 'modify' && props.user) {
      await modifyUser(data);
    }
    setIsInserted(false);
  };

  const textFieldChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof UserFormInput, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
    if (e.currentTarget.name === 'email') {
      props.type === 'modify' &&
        userRoleAndEmailChangeValidation(undefined, e.currentTarget.value, undefined);
    }
  };

  const gymChainDropdownChangeHandler = (e: DropdownChangeEvent) => {
    setselectedGymChains(e.value);
  };

  const dropdownChangeHandler = (e: DropdownChangeEvent) => {
    setValue(e.target.name as keyof UserFormInput, e.value);
    setFormValues({ ...formValues, [e.target.name]: e.value });

    if (props.type === 'modify') {
      if (e.target.name === 'role') {
        userRoleAndEmailChangeValidation(undefined, undefined, e.value);
      }
      if (e.target.name === 'language') {
        userRoleAndEmailChangeValidation(e.value, undefined, undefined);
      }
    }
  };

  const userRoleAndEmailChangeValidation = (
    language?: string,
    email?: string,
    userRole?: number
  ) => {
    if (email && props.user?.userId === userId) {
      email != props.user?.email
        ? createAlert(
            emailAlertRef,
            {
              content: t(getTranslationKey('ALERT_EMAIL_UPDATED')),
              severity: 'warn',
              sticky: true
            },
            'replace'
          )
        : clearAlert(emailAlertRef);
    }
    if (userRole && props.user?.userId === userId) {
      userRole != props.user?.roleId
        ? createAlert(
            roleAlertRef,
            {
              content: t(getTranslationKey('ALERT_USER_ROLE_UPDATED')),
              severity: 'warn',
              sticky: true
            },
            'replace'
          )
        : clearAlert(roleAlertRef);
    }
    if (language && props.user?.userId === userId) {
      language != props.user?.language
        ? createAlert(
            languageAlertRef,
            {
              content: t(getTranslationKey('ALERT_USER_LANGUAGE_UPDATED')),
              severity: 'warn',
              sticky: true
            },
            'replace'
          )
        : clearAlert(languageAlertRef);
    }
  };

  useEffect(() => {
    register('firstName', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('lastName', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('email', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      pattern: {
        value: Validator.validEmailRegEx,
        message: t(getTranslationKey('FORM_EMAIL_INVALID_ERROR')) as string
      },
      maxLength: { value: 255, message: t(getTranslationKey('FORM_EMAIL_LENGTH_ERROR')) as string }
    });
    register('language', {
      validate: (value) => {
        if (value) {
          return true;
        } else {
          return t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string;
        }
      }
    });
    register('phone', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      pattern: {
        value: Validator.validPhoneNumberRegEx,
        message: t(getTranslationKey('FORM_PHONE_INVALID_ERROR')) as string
      },
      maxLength: {
        value: 20,
        message: t(getTranslationKey('FORM_PHONE_NUMBER_LENGTH_ERROR')) as string
      }
    });
    register('countryCode', {
      validate: (value) => {
        if (value) {
          return true;
        } else {
          return t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string;
        }
      }
    });
    register('password', {
      pattern: {
        value: Validator.validPasswordRegEx,
        message: t(getTranslationKey('FORM_PASSWORD_INVALID_ERROR')) as string
      },
      validate: (value) => {
        if (props.type === 'new') {
          if (value === '') {
            return t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    });
    register('confirmPassword', {
      pattern: {
        value: Validator.validPasswordRegEx,
        message: t(getTranslationKey('FORM_PASSWORD_INVALID_ERROR')) as string
      },
      validate: (value) => {
        if (props.type === 'new') {
          if (value === '') {
            return t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string;
          } else if (validatePasswordConfirmation()) {
            return t(getTranslationKey('FORM_CONFIRM_PASSWORD_INVALID_ERROR')) as string;
          }
        } else {
          return true;
        }
      }
    });
    register('role', {
      validate: (value) => {
        if (value > 0) {
          return true;
        } else {
          return t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string;
        }
      }
    });
    register('gymChainId', {
      validate: (value) => {
        if (value.length > 0) {
          return true;
        } else {
          return t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string;
        }
      }
    });
    register('gym', {
      validate: (value) => {
        if (value.length > 0) {
          return true;
        } else {
          return t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string;
        }
      }
    });
    register('accesViaMembership');
    register('profilePicturePath');
  }, [register]);

  useEffect(() => {
    setValue('gymChainId', selectedGymChains);

    if (selectedGroupedGymChains.length === 0) {
      return;
    }

    let filteredGyms: any = [];

    selectedGymChains.forEach((selectedGymChain) => {
      const filtered = selectedGroupedGymChains.filter((groupedGym) => {
        if (groupedGym.value) {
          return groupedGym.value.siteId == Number(selectedGymChain);
        } else {
          return groupedGym.siteId == Number(selectedGymChain);
        }
      });

      filteredGyms = filteredGyms.concat(filtered);
    });

    setSelectedGroupedGymChains(filteredGyms);
  }, [selectedGymChains]);

  useEffect(() => {
    setValue('gym', selectedGroupedGymChains);
  }, [selectedGroupedGymChains]);

  const validatePasswordConfirmation = () => {
    if (getValues('password') === getValues('confirmPassword')) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (imgUploadStatus === 'INPROGRESS') {
      setSubmitDisabled(true);
    } else if (imgUploadStatus === 'SUCCESS') {
      setSubmitDisabled(false);
    } else if (imgUploadStatus === 'NONE') {
      setSubmitDisabled(false);
    }
  }, [imgUploadStatus]);

  const resetForm = () => {
    setFormValues(emptyValues);
    reset(emptyValues);
  };

  useEffect(() => {
    if (accessViaMembership) {
      checkStaffAccessMembership(staffAccessMembershipGyms, selectedGroupedGymChains);
    } else {
      clearAlert(alertRef);
      setProvideAccessViaMembership(false);
    }
  }, [accessViaMembership, selectedGroupedGymChains]);

  const checkStaffAccessMembership = (
    staffAccessMembershipGym: staffAccessMembershipGymType[],
    selectedGymChains: any[]
  ) => {
    let gymNames = '';

    const selectedGyms = staffAccessMembershipGym.filter((gym) => {
      return selectedGymChains.find(
        (item) => item.siteLocationId === gym.siteLocationId && item.siteId === gym.siteId
      );
    });

    selectedGyms.map((gym) =>
      gym.staffAccessMembershipId === -1
        ? (gymNames = `${gym.siteLocationName}, ${gymNames}`)
        : undefined
    );

    if (selectedGyms.length > 0 && gymNames != '') {
      setProvideAccessViaMembership(true);
      createAlert(
        alertRef,
        {
          content: `${gymNames.replace(/,\s*$/, '')} ${t(
            getTranslationKey('ALERT_STAFF_ACCESS_MEMBERSHIP_SUFFIX')
          )}`,
          severity: 'warn',
          sticky: true
        },
        'replace'
      );
    } else {
      clearAlert(alertRef);
      setProvideAccessViaMembership(false);
    }
  };

  return (
    <div className="row">
      <div className="col-md-7">
        <div className="row">
          <div className="col">
            <form className="input-mb20 py-4" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
              <div className="mb25 flex-grow-1">
                <Input
                  label={t(getTranslationKey('FORM_FIRST_NAME'))}
                  variant="basic"
                  onChange={textFieldChangeHandler}
                  name="firstName"
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName?.message}
                  value={formValues.firstName}
                  required={true}
                />
              </div>
              <div className="mb25 flex-grow-1">
                <Input
                  label={t(getTranslationKey('FORM_LAST_NAME'))}
                  variant="basic"
                  onChange={textFieldChangeHandler}
                  name="lastName"
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName?.message}
                  value={formValues.lastName}
                  required={true}
                />
              </div>
              <div className="mb25">
                <div className="mb10">
                  <label className="fw-bold">
                    {t(getTranslationKey('FORM_LANGUAGE'))}
                    <span className="p-error"> *</span>
                  </label>
                  <Dropdown
                    name="language"
                    options={languageDropdownItems}
                    value={formValues.language}
                    onChange={dropdownChangeHandler}
                    error={errors.language ? true : false}
                  />
                </div>
                {errors.language && (
                  <Text className="p-error mb15">
                    {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                  </Text>
                )}
                <Alert refProp={languageAlertRef} />
              </div>
              <div className="mb25">
                <Input
                  label={t(getTranslationKey('FORM_EMAIL'))}
                  variant="basic"
                  onChange={textFieldChangeHandler}
                  name="email"
                  error={errors.email ? true : false}
                  errorMessage={errors.email?.message}
                  value={formValues.email}
                  required={true}
                />
                <Alert refProp={emailAlertRef} />
              </div>

              <div className="mb25">
                <div className="row">
                  <label className="fw-bold">
                    {t(getTranslationKey('FORM_PHONE'))}
                    <span className="p-error"> *</span>
                  </label>
                  <div className="col-md-5">
                    <div className="no-label">
                      <Dropdown
                        name="countryCode"
                        options={countryCodeDropdownItems}
                        value={formValues.countryCode}
                        onChange={dropdownChangeHandler}
                        error={errors.countryCode ? true : false}
                      />
                    </div>
                    {errors.countryCode && (
                      <Text className="p-error mb15">
                        {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                      </Text>
                    )}
                  </div>
                  <div className="col-md-7">
                    <div className="no-label">
                      <Input
                        label={t(getTranslationKey('FORM_PHONE'))}
                        variant="basic"
                        onChange={textFieldChangeHandler}
                        name="phone"
                        error={errors.phone ? true : false}
                        errorMessage={errors.phone?.message}
                        value={formValues.phone}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {props.type === 'new' && (
                <div className="mb25">
                  <Input
                    label={t(getTranslationKey('FORM_PASSWORD'))}
                    variant="password"
                    onChange={textFieldChangeHandler}
                    name="password"
                    error={errors.password ? true : false}
                    errorMessage={errors.password?.message}
                    value={formValues.password}
                    required={true}
                  />
                </div>
              )}
              {props.type === 'new' && (
                <div className="mb25">
                  <Input
                    label={t(getTranslationKey('FORM_CONFIRM_PASSWORD'))}
                    variant="password"
                    onChange={textFieldChangeHandler}
                    name="confirmPassword"
                    error={errors.confirmPassword ? true : false}
                    errorMessage={errors.confirmPassword?.message}
                    value={formValues.confirmPassword}
                    disabled={formValues.password === ''}
                    required={true}
                  />
                </div>
              )}
              <div className="mb25">
                <div className="mb10">
                  <label className="fw-bold">
                    {t(getTranslationKey('FORM_USER_ROLE'))}
                    <span className="p-error"> *</span>
                    {isRolesLoading && <div className="pi pi-spin pi-spinner ml10"></div>}
                  </label>
                  <Dropdown
                    name="role"
                    options={roleDropDownItems}
                    value={formValues.role}
                    onChange={dropdownChangeHandler}
                    disabled={isRolesLoading}
                    error={errors.role ? true : false}
                  />
                </div>
                {errors.role && (
                  <Text className="p-error mb15">
                    {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                  </Text>
                )}
                <Alert refProp={roleAlertRef} />
              </div>

              <div className="mb25">
                <div className="mb10">
                  <label className="fw-bold">
                    {t(getTranslationKey('FORM_GYM_CHAIN'))}
                    <span className="p-error"> *</span>
                    {isGymsChainsLoading && <div className="pi pi-spin pi-spinner ml10"></div>}
                  </label>
                  <Dropdown
                    options={gymchainDropDownItems}
                    value={selectedGymChains}
                    onChange={gymChainDropdownChangeHandler}
                    chips={true}
                    name="gymChainId"
                    disabled={isGymsChainsLoading}
                    error={errors.gymChainId ? true : false}
                  />
                </div>
                {errors.gymChainId && (
                  <Text className="p-error mb15">
                    {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                  </Text>
                )}
              </div>

              <div className="mb25">
                <div className="mb10">
                  <label className="fw-bold">
                    {t(getTranslationKey('FORM_GYM'))}
                    <span className="p-error"> *</span>
                    {isGymsLoading && <div className="pi pi-spin pi-spinner ml10"></div>}
                  </label>
                  <Dropdown
                    options={groupedGymChains}
                    value={isGymsLoading ? [] : selectedGroupedGymChains}
                    onChange={(e) => {
                      const filtered = e.value.map((filteredGym: any) => {
                        if (filteredGym.value) {
                          return {
                            siteId: filteredGym.value.siteId,
                            siteLocationId: filteredGym.value.siteLocationId
                          };
                        } else {
                          return filteredGym;
                        }
                      });

                      setSelectedGroupedGymChains(filtered);
                    }}
                    optionLabel="label"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    grouped={true}
                    disabled={selectedGymChains.length === 0 || isGymsLoading}
                    name="gym"
                    error={errors.gym ? true : false}
                  />
                  <Alert refProp={gymsAlertRef} />
                </div>
                {errors.gym && (
                  <Text className="p-error mb15">
                    {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                  </Text>
                )}
              </div>
              <Alert refProp={alertRef} />
              <div className="d-flex flex-row mt35 btn-min-w-110">
                <Text bold={true}>
                  {submitDisabled
                    ? imgUploadStatus === 'INPROGRESS'
                      ? t(getTranslationKey('FORM_UPLOAD_INPROGRESS'))
                      : imgUploadStatus === 'ERROR'
                      ? t(getTranslationKey('FORM_UPLOAD_ERROR'))
                      : t(getTranslationKey('FORM_UPLOAD_SUCCESS'))
                    : imgUploadStatus === 'SUCCESS'
                    ? t(getTranslationKey('FORM_UPLOAD_SUCCESS'))
                    : ''}
                </Text>
              </div>
              <div className="d-flex flex-row mt35 btn-min-w-110">
                <div className="mr15">
                  <Button
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                    label={t(getTranslationKey('FORM_BTN_LABEL_SAVE'))}
                    size={ButtonSizes.medium}
                    disabled={
                      submitDisabled || provideAccessViaMembership || isGymsLoading || isInserted
                    }
                  />
                </div>
                <Button
                  label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
                  variant={ButtonVariants.textonly}
                  onClick={() => history.push('/users')}
                  disabled={isInserted}
                />
                <ActionFailedDialog />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="d-flex justify-content-center">
          <Upload
            setUploadState={setImgUploadStatus}
            uploadHandler={uploadHandler}
            name="test"
            previewImg={formValues.profilePicturePath ? formValues.profilePicturePath : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default UserManagementTemplate;

type UserManagementTemplateType = 'new' | 'modify';

export interface UserManagementTemplateProps {
  /** Type of the template: new or modify */
  type: UserManagementTemplateType;
  user?: UserType;
}
