import { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { AlertType } from '@gym-atoms/Alert/Alert';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import DoorInfoTemplate from '@gym-templates/DoorInfo/DoorInfoTemplate';

const MainColumn = (props: { gymChainId: string; siteLocationId: string }) => {
  return (
    <Card className="pt30 pl40 pr40 pb30">
      <DoorInfoTemplate
        type="new"
        gymChainId={+props.gymChainId}
        siteLocationId={+props.siteLocationId}
      />
    </Card>
  );
};

const AddNewDoorPage = () => {
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { siteLocationId, gymChainId } = useParams<{
    siteLocationId: string;
    gymChainId: string;
  }>();
  const { t } = useTranslation();

  return (
    <TwoColTemplate
      title={t('EMPLOYEE_DASHBOARD_PAGE.ADD_NEW_KISI_DOOR.PAGE_TITLE')}
      col1={<MainColumn gymChainId={gymChainId} siteLocationId={siteLocationId} />}
      gymChainId={+gymChainId}
      alertRef={alertRef}
      backButtonHandler={() => history.push(`/employeeDashboard/${gymChainId}/${siteLocationId}`)}
    />
  );
};

export default AddNewDoorPage;
