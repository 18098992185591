import { AlertType } from '@gym-atoms/Alert/Alert';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { getGymChainById } from '@gym-redux/slices/gymChainSlice';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { Card } from 'primereact/card';
import Status, { StatusVariants } from '@gym-atoms/Status/Status';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import Stepper from '@gym-atoms/Stepper/Stepper';
import { MenuItem } from '@gym-atoms/VerticalMenu/VerticalMenu';
import GymSchedulesForm from '@gym-organisms/GymSchedules/GymSchedulesForm';
import {
  AccessCategoriesMapping,
  AccessPointMapping,
  GymScheduleAccessCategoryMapping,
  GymScheduleAccessPointMapping,
  GymSchedulesAccessPointMapping,
  GymSchedulesFormInput,
  GymSchedulesGymZoneMapping,
  GymScheduleStatus,
  GymScheduleType,
  GymScheduleZoneMapping,
  ScheduleEvent,
  ScheduleInternalStatus,
  ZoneMapping
} from '@gym-particles/types/GymSchedules';
import GymZoneMapping from '@gym-organisms/GymSchedules/GymSchedulesZoneMapping';
import OpeningHoursScheduler from '@gym-organisms/GymSchedules/GymSchedulesScheduler';
import { ScheduleStatuses, SiteAccessPoint, ZoneAccessCategoryMapping } from '@gym-src/API';
import {
  getAccessPointsForSite,
  getAllScheduleStatuses,
  getZoneAccessCategoryMappingForGymSchedules
} from '@gym-graphql/queries';
import { API } from 'aws-amplify';
import { fetchGyms } from '@gym-redux/slices/gymsSlice';
import { GymType } from '@gym-particles/types/Gym';
import { fetchGymSchedules } from '@gym-redux/slices/gymSchedulesSlice';
import Loader from '@gym-atoms/Loader/Loader';
import { UserRole } from '@gym-particles/types/User';
import { fetchDaySchedules } from '@gym-redux/slices/daySchedulesSlice';

export const Content = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isBacked, setIsBacked] = useState(false);
  const [scheduleId, setScheduleId] = useState<number>(0);
  const userId = useAppSelector((state) => state.user.userId);
  const { chainId, gymScheduleId } = useParams<{ chainId: string; gymScheduleId: string }>();
  const [scheduleStatuses, setScheduleStatuses] = useState<Array<GymScheduleStatus>>([]);
  const [scheduleStatusId, setScheduleStatusId] = useState<number>(-1);
  // To-Do: Add enums for type.
  const [scheduleType, setScheduleType] = useState<string>('door unlock schedule');
  const dispatch = useAppDispatch();
  const isGymScheduleLoading = useAppSelector((state) => state.gymSchedules.isGymSchedulesLoading);
  const userRole = useAppSelector((state) => state.user.userRole);
  const providerId = useAppSelector((state) => state.gymChain.currentGymChain?.providerId);

  useEffect(() => {
    dispatch(
      fetchGymSchedules({
        siteId: +chainId
      })
    );
    providerId === 1 &&
      dispatch(
        fetchDaySchedules({
          offset: 0,
          pageSize: 0,
          sortField: '',
          sortOrder: 1,
          search: { searchField: '', searchText: '' }
        })
      );
    setScheduleId(+gymScheduleId);
  }, [gymScheduleId]);

  const selectedGymSchedule =
    useAppSelector((state) =>
      state.gymSchedules.items
        .find((info) => info.siteId === +chainId)
        ?.items.filter((generalInfo) => generalInfo.siteScheduleId === +gymScheduleId)
    ) || [];

  useEffect(() => {
    getScheduleStatuses();
  }, []);

  const getScheduleStatuses = async () => {
    try {
      const response = (API.graphql({
        query: getAllScheduleStatuses
      }) as Promise<{
        data: { getAllScheduleStatuses: [ScheduleStatuses] };
      }>).then((e) => {
        const scheduleStatusItems: GymScheduleStatus[] = e.data.getAllScheduleStatuses.map(
          (data) => {
            return { name: data.name || '', id: data.id || 0 };
          }
        );
        setScheduleStatuses(scheduleStatusItems);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const selectedGymScheduleGeneralInfo = useAppSelector((state) =>
    state.gymSchedules.items
      .find((info) => info.siteId === +chainId)
      ?.items.find((i) => i.siteScheduleId === +gymScheduleId)
  );

  useEffect(() => {
    setScheduleStatusId(selectedGymScheduleGeneralInfo?.scheduleStatusId || 0);
  }, [scheduleStatuses]);

  const generalInfoValues: GymSchedulesFormInput = {
    type: selectedGymScheduleGeneralInfo?.siteScheduleType.toLowerCase() || '',
    name: selectedGymScheduleGeneralInfo?.siteScheduleName || '',
    validFromDate: new Date(selectedGymScheduleGeneralInfo?.validFrom || ''),
    validToDate: new Date(selectedGymScheduleGeneralInfo?.validTo || ''),
    status: selectedGymScheduleGeneralInfo?.siteScheduleStatus.toLowerCase() || '',
    createdBy: userId,
    lastModifiedBy: userId
  };

  const filteredEventIds: number[] = selectedGymSchedule.map(
    (record) => record.siteScheduleDetailId
  );

  const defaultEventData: GymScheduleType[] = selectedGymSchedule.filter(
    ({ siteScheduleDetailId }, index) => !filteredEventIds.includes(siteScheduleDetailId, index + 1)
  );

  const scheduleStatusName = scheduleStatuses.find((a) => a.id === scheduleStatusId)?.name || '';

  const defaultEventsList: ScheduleEvent[] = defaultEventData.map((val) => ({
    id: val.siteScheduleDetailId,
    title:
      val.scheduleStatusId === 1
        ? 'Schedule available'
        : val.scheduleStatusId === 3
        ? ''
        : 'Schedule unavailable',
    start: new Date(val.startTime),
    end: new Date(val.endTime),
    isAvailable:
      val.scheduleStatusId === 1
        ? 'available'
        : val.scheduleStatusId === 3
        ? 'draft'
        : 'unavailable',
    scheduleStatusId: val.scheduleStatusId
  }));

  const [scheduleForm, setScheduleForm] = useState<GymSchedulesFormInput>(generalInfoValues);
  const [gymZoneMappings, setGymZoneMappings] = useState<GymSchedulesGymZoneMapping[]>([]);
  const [gymAccessPointMappings, setGymAccessPointMappings] = useState<
    GymSchedulesAccessPointMapping[]
  >([]);
  const [schedules, setSchedules] = useState<ScheduleEvent[]>(defaultEventsList);

  const stepperItems: MenuItem[] = [
    {
      label: t('MODIFY_GYM_SCHEDULE.STEPPER.GENERAL_DETAILS')
    },
    {
      label: t('MODIFY_GYM_SCHEDULE.STEPPER.GYM_ZONE_MAPPING')
    },
    {
      label: t('MODIFY_GYM_SCHEDULE.STEPPER.OPENING_HOURS')
    }
  ];
  const setActiveIndexWrapper = (e: number): void => {
    if (e < activeIndex) {
      setIsBacked(true);
    } else {
      setIsBacked(false);
    }
    setActiveIndex(e);
  };

  const gyms = useAppSelector(
    (state) => state.gyms.items.find((g) => g.chainId === +chainId)?.items
  );

  const getAccessPoints = async () => {
    const response = await (API.graphql({
      query: getAccessPointsForSite,
      variables: {
        siteId: chainId
      }
    }) as Promise<{
      data: { getAccessPointsForSite: [SiteAccessPoint] };
    }>);

    const mappedResponse: GymSchedulesAccessPointMapping[] = [];

    response.data.getAccessPointsForSite.forEach((item) => {
      mappedResponse.push({
        locationAccessPointId: item.locationAccessPointId || -1,
        siteId: item.siteId || -1,
        siteLocationId: item.siteLocationId || -1,
        locationZoneId: item.locationZoneId || -1,
        locationZoneName: item.locationZoneName || '',
        zoneAccessPointId: item.zoneAccessPointId || -1,
        doorARXId: item.doorARXId || '',
        doorName: item.doorName || '',
        status: item.status || ''
      });
    });

    setGymAccessPointMappings(mappedResponse);
  };

  const getZoneMappings = async () => {
    const response = await (API.graphql({
      query: getZoneAccessCategoryMappingForGymSchedules,
      variables: {
        siteId: chainId
      }
    }) as Promise<{
      data: { getZoneAccessCategoryMappingForGymSchedules: [ZoneAccessCategoryMapping] };
    }>);

    const mappedResponse: GymSchedulesGymZoneMapping[] = [];

    response.data.getZoneAccessCategoryMappingForGymSchedules.forEach((item) => {
      mappedResponse.push({
        locationAccessCategoryId: item.locationAccessCategoryId || -1,
        locationAccessCategoryName: item.locationAccessCategoryName || '',
        locationAccessCategoryZoneId: item.locationAccessCategoryZoneId || -1,
        locationArxAccessCategoryId: item.locationArxAccessCategoryId || '',
        locationName: item.locationName || '',
        locationZoneId: item.locationZoneId || -1,
        membershipId: item.membershipId || -1,
        membershipName: item.membershipName || '',
        siteId: item.siteId || -1,
        siteLocationId: item.siteLocationId || -1,
        siteLocationMembershipId: item.siteLocationMembershipId || -1,
        zoneDescription: item.zoneDescription || '',
        zoneName: item.zoneName || ''
      });
    });

    setGymZoneMappings(mappedResponse);
  };

  useEffect(() => {
    dispatch(
      fetchGyms({
        id: +chainId,
        pagination: {
          offset: 0,
          pageSize: 0,
          sortField: 'siteId',
          sortOrder: 1,
          userId: userRole === UserRole.SYSTEM_ADMIN ? 0 : userId
        }
      })
    );

    getZoneMappings();
    getAccessPoints();
  }, []);

  const [selectedGyms, setSelectedGyms] = useState<Array<GymType>>([]);
  const [selectedZones, setSelectedZones] = useState<ZoneMapping[]>([]);
  const [selectedAccessCategory, setSelectedAccessCategory] = useState<AccessCategoriesMapping[]>(
    []
  );
  const [selectedAccessPoint, setSelectedAccessPoint] = useState<AccessPointMapping[]>([]);

  useEffect(() => {
    setDefaultValues();
  }, [gymZoneMappings, gymAccessPointMappings]);

  const setDefaultValues = () => {
    const selectedData = selectedGymSchedule || [];

    const selectedGymSet: GymType[] = [];

    selectedData.map((data) => {
      const matchingRecord = gyms?.find((x) => {
        return x.gymId === data.siteLocationId;
      });
      if (matchingRecord) {
        const matchedRecord = selectedGymSet.find((x) => {
          return x.gymId === matchingRecord.gymId;
        });
        if (!matchedRecord) {
          selectedGymSet.push(matchingRecord);
        }
      }
    });

    setSelectedGyms(selectedGymSet);

    const selectedZoneSet: ZoneMapping[] = [];

    selectedData.map((data) => {
      const matchingRecord = gymZoneMappings.find((x) => {
        return x.locationZoneId === data.zoneId;
      });
      if (matchingRecord) {
        const matchedRecord = selectedZoneSet.find((x) => {
          return x.locationZoneId === matchingRecord.locationZoneId;
        });
        if (!matchedRecord) {
          selectedZoneSet.push({
            imageUrl: data.imageUrl,
            locationZoneId: matchingRecord.locationZoneId,
            zoneName: matchingRecord.zoneName,
            siteLocationId: matchingRecord.siteLocationId
          });
        }
      }
    });

    setSelectedZones(selectedZoneSet);

    const accessCategories: AccessCategoriesMapping[] = [];

    selectedData.map((data) => {
      const matchingRecord = gymZoneMappings.find((x) => {
        return (
          x.locationAccessCategoryId === data.siteLocationAccessCategoryId &&
          x.locationZoneId === data.zoneId
        );
      });
      if (matchingRecord) {
        const matchedRecord = accessCategories.find((x) => {
          return (
            x.locationAccessCategoryId === matchingRecord.locationAccessCategoryId &&
            x.locationZoneId === matchingRecord.locationZoneId
          );
        });
        if (!matchedRecord) {
          accessCategories.push({
            locationAccessCategoryId: matchingRecord.locationAccessCategoryId,
            locationAccessCategoryName: matchingRecord.locationAccessCategoryName,
            locationArxAccessCategoryId: matchingRecord.locationArxAccessCategoryId,
            locationZoneId: matchingRecord.locationZoneId,
            siteLocationId: matchingRecord.siteLocationId
          });
        }
      }
    });

    setSelectedAccessCategory(accessCategories);

    const accessPoints: AccessPointMapping[] = [];

    selectedData.map((data) => {
      const matchingRecord = gymAccessPointMappings.find((x) => {
        return (
          x.locationAccessPointId === data.locationAccessPointId &&
          x.locationAccessPointId === data.locationAccessPointId
        );
      });
      if (matchingRecord) {
        const matchedRecord = accessPoints.find((x) => {
          return (
            x.locationAccessPointId === matchingRecord.locationAccessPointId &&
            x.locationAccessPointId === matchingRecord.locationAccessPointId
          );
        });
        if (!matchedRecord) {
          accessPoints.push({
            locationZoneId: matchingRecord.locationZoneId,
            siteLocationId: matchingRecord.siteLocationId,
            doorARXId: matchingRecord.doorARXId,
            doorName: matchingRecord.doorName,
            locationAccessPointId: matchingRecord.locationAccessPointId
          });
        }
      }
    });

    setSelectedAccessPoint(accessPoints);
  };

  const [gymSelections, setGymSelections] = useState<Array<GymType>>(selectedGyms || []);
  const [zoneSelections, setZoneSelections] = useState<GymScheduleZoneMapping[]>(
    // eslint-disable-next-line prettier/prettier
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    selectedZones || []
  );
  const [accessCategorySelections, setAccessCategorySelections] = useState<
    GymScheduleAccessCategoryMapping[]
  >(
    // eslint-disable-next-line prettier/prettier
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    selectedAccessCategory || []
  );
  const [accessPointSelections, setAccessPointSelections] = useState<
    GymScheduleAccessPointMapping[]
  >(
    // eslint-disable-next-line prettier/prettier
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    selectedAccessPoint || []
  );

  useEffect(() => {
    setGymSelections(selectedGyms);
    // eslint-disable-next-line prettier/prettier
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setZoneSelections(selectedZones);
    // eslint-disable-next-line prettier/prettier
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAccessCategorySelections(selectedAccessCategory);
    // eslint-disable-next-line prettier/prettier
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setAccessPointSelections(selectedAccessPoint);
  }, [selectedGyms, selectedZones, selectedAccessCategory, selectedAccessPoint]);

  return (
    <Card className="pt10 pl40 pr40 pb30">
      {isGymScheduleLoading ? (
        <Loader shape="activity" />
      ) : (
        <div>
          <div className="d-flex align-items-center">
            <Text bold={true} size={TextSizes.large} className="text-2xl">
              {t('MODIFY_GYM_SCHEDULE.PAGE_TITLE')}
            </Text>
            <div className="pl20 ">
              {scheduleStatuses.length > 0 && (
                <Status
                  value={scheduleStatusName.charAt(0).toUpperCase() + scheduleStatusName.slice(1)}
                  variant={
                    scheduleStatusName === ScheduleInternalStatus.Inactive
                      ? StatusVariants.red
                      : scheduleStatusName === ScheduleInternalStatus.PartiallySaved
                      ? StatusVariants.yellow
                      : scheduleStatusName === ScheduleInternalStatus.Active
                      ? StatusVariants.green
                      : StatusVariants.blue
                  }
                />
              )}
            </div>
          </div>
          <div className="pt50">
            <Stepper
              items={stepperItems}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndexWrapper}
              readonly={true}
            />
          </div>
          {activeIndex === 0 ? (
            <GymSchedulesForm
              type={'modify'}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndexWrapper}
              setScheduleForm={setScheduleForm}
              gymSchedules={scheduleForm}
              setScheduleId={setScheduleId}
              scheduleId={scheduleId}
              setScheduleType={setScheduleType}
              isBacked={isBacked}
              scheduleStatuses={scheduleStatuses}
              setScheduleStatusId={setScheduleStatusId}
              scheduleStatusId={scheduleStatusId}
            />
          ) : activeIndex == 1 ? (
            <div>
              <GymZoneMapping
                type={'modify'}
                gymChainId={+chainId}
                isDoorSchedule={scheduleForm?.type === 'door unlock schedule'}
                gyms={gyms || []}
                gymZones={gymZoneMappings || []}
                gymAccessPoints={gymAccessPointMappings || []}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndexWrapper}
                scheduleId={scheduleId}
                scheduleType={scheduleType}
                defaultSelectedGyms={selectedGyms}
                defaultSelectedZones={selectedZones}
                defaultSelectedAccessCategory={selectedAccessCategory}
                defaultSelectedAccessPoint={selectedAccessPoint}
                selectedGyms={gymSelections}
                setSelectedGyms={setGymSelections}
                selectedZones={zoneSelections}
                setSelectedZones={setZoneSelections}
                selectedAccessCategory={accessCategorySelections}
                setSelectedAccessCategory={setAccessCategorySelections}
                selectedAccessPoint={accessPointSelections}
                setSelectedAccessPoint={setAccessPointSelections}
              />
            </div>
          ) : (
            <OpeningHoursScheduler
              type={'modify'}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndexWrapper}
              scheduleId={scheduleId}
              schedules={schedules}
              setSchedules={setSchedules}
              defaultValues={defaultEventsList}
              scheduleStatuses={scheduleStatuses}
              setScheduleStatusId={setScheduleStatusId}
              scheduleStatusId={scheduleStatusId}
              isDoorSchedule={scheduleForm.type === 'door unlock schedule'}
              scheduleDate={scheduleForm.validFromDate}
            />
          )}
        </div>
      )}
    </Card>
  );
};

const ModifyGymSchedulesPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { chainId } = useParams<{ chainId: string }>();

  const dispatch = useAppDispatch();

  const gymChainName = useAppSelector((state) => state.gymChain.currentGymChain?.name);

  let fetchFailed = false;
  if (gymChainName == undefined) {
    fetchFailed = true;
  }

  useEffect(() => {
    dispatch(getGymChainById(+chainId));
  }, []);

  useEffect(() => {
    if (fetchFailed) {
      history.push(`/gymChains/${chainId}/gymSchedules`);
    }
  }, [fetchFailed]);
  return (
    <TwoColTemplate
      title={(gymChainName as string) || t('COMMON.LOADING')}
      gymChainId={+chainId}
      col1={<Content />}
      alertRef={alertRef}
      backButtonHandler={() => history.push(`/gymChains/${chainId}/gymSchedules`)}
    />
  );
};

export default ModifyGymSchedulesPage;
