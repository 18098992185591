import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Dropdown, { DropdownChangeEvent, SelectItem } from '@gym-atoms/Dropdown/Dropdown';
import Input from '@gym-atoms/Input/Input';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import { addAccessPoint, modifyAccessPoint } from '@gym-graphql/mutations';
import {
  getAllZonesForSiteLocation,
  getPriorityInfo,
  importBeaconIdByUniqueId
} from '@gym-graphql/queries';
import { AccessPoints, AccessPointsFormInput } from '@gym-particles/types/models';
import { fetchAccessPoints } from '@gym-redux/slices/accessPointsSlice';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import {
  AddAccessPointInput,
  LocationZoneResponse,
  ModifyAccessPointInput,
  PriorityInfo
} from '@gym-src/API';
import { API } from 'aws-amplify';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const GymAccessPointsTemplate = (props: GymAccessPointsTemplateProps) => {
  const isSodvin = props.providerId === 2;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isInserted, setIsInserted] = useState(false);
  const { chainId, gymId } = useParams<{ chainId: string; gymId: string }>();
  const [zoneDropDownItems, setZoneDropDownItems] = useState<Array<SelectItem>>([]);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const [accessPointPriority, setAccessPointPriority] = useState<Array<number>>([]);
  const [validatePriorityResponse, setValidatePriorityResponse] = useState<boolean>(true);
  const [importErrorMessage, setImportErrorMessage] = useState<string | null>(null);
  const [importing, setImporting] = useState(false);

  const getTranslationKey = (e: string): string => {
    return props.type === 'new' ? 'ADD_NEW_ACCESS_POINT.' + e : 'MODIFY_ACCESS_POINT.' + e;
  };
  const userId = useAppSelector((state) => state.user.userId);

  const existingAccessPoints = useAppSelector(
    (state) => state.accessPoint.items.find((gym) => gym.siteLocationId === +gymId)?.items
  );

  const handbackTime =
    useAppSelector((state) => state.gyms.items)
      .find((g) => g.chainId === +chainId)
      ?.items.find((a) => a.gymId === +gymId)?.handbackTime || 0;

  const templateTypeChangeHandler = useCallback(
    (state) => {
      if (props.templateType) {
        props.templateType(state);
      }
    },
    [props.templateType]
  );

  const getZones = async () => {
    try {
      const response = (API.graphql({
        query: getAllZonesForSiteLocation,
        variables: {
          siteLocationId: gymId
        }
      }) as Promise<{
        data: { getAllZonesForSiteLocation: LocationZoneResponse };
      }>).then((e) => {
        const zoneDropDownItems: SelectItem[] = e.data.getAllZonesForSiteLocation.items?.map(
          (data) => {
            return { label: data?.zoneName || '', value: data?.id || 0 };
          }
        ) || [{ label: '', value: '' }];
        setZoneDropDownItems(zoneDropDownItems);
      });
    } catch (error) {
      setFailedDialogVisible(true);
      console.log(error);
    }
  };

  const getPriority = async () => {
    try {
      const response = (API.graphql({
        query: getPriorityInfo,
        variables: {
          siteLocationId: gymId
        }
      }) as Promise<{
        data: { getPriorityInfo: PriorityInfo[] };
      }>).then((e) => {
        const priority: number[] = e.data.getPriorityInfo
          ?.map((i) => i.priority || '1')
          .map((val) => +val || 1);

        setAccessPointPriority(priority);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getZones();
    getPriority();
  }, []);

  useEffect(() => {
    if (accessPointPriority.length === 0) {
      setFormValues({
        ...formValues,
        priority: '1'
      });
      setValue('priority', '1');
    } else {
      setFormValues({
        ...formValues,
        priority: String(Math.max(...accessPointPriority) + 1)
      });
      setValue('priority', String(Math.max(...accessPointPriority) + 1));
    }
  }, [accessPointPriority]);

  const accessPointDefaultValues: AccessPointsFormInput = {
    accessPointType: props.accessPoints?.accessPointType || '',
    beaconUUID: props.accessPoints?.beaconUUID || '',
    beaconMajor: props.accessPoints?.beaconMajor || '',
    beaconMinor: props.accessPoints?.beaconMinor || '',
    doorARXId: props.accessPoints?.doorARXId || '',
    locationZoneId: props.accessPoints?.locationZoneId || -1,
    doorName: props.accessPoints?.doorName || '',
    doorDescription: props.accessPoints?.doorDescription || '',
    handbackTime: props.accessPoints?.handbackTime || handbackTime,
    doorCaption: props.accessPoints?.doorCaption || '',
    priority: props.accessPoints?.priority || '',
    status: props.accessPoints?.status || '',
    createdBy: props.accessPoints?.createdBy || -1,
    lastModifiedBy: userId,
    uniqueId: props.accessPoints?.uniqueId || '',
    sodvinDoorId: props.accessPoints?.sodvinDoorId || '',
    sodvinDoorSecret: props.accessPoints?.sodvinDoorSecret || ''
  };

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    reset,
    getValues
  } = useForm<AccessPointsFormInput>({
    defaultValues: accessPointDefaultValues
  });

  const [formValues, setFormValues] = useState(accessPointDefaultValues);

  useEffect(() => {
    setFormValues(accessPointDefaultValues);
    reset(accessPointDefaultValues);
  }, [props.accessPoints, props.accessPoints?.locationZoneId, handbackTime]);

  const statusDropDownItems = [
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN_ACTIVE')),
      value: 'active'
    },
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN_INACTIVE')),
      value: 'inactive'
    }
  ];

  const typeDropDownItems = [
    {
      label: t(getTranslationKey('FORM_STATUS_TYPE_DOOR')),
      value: 'door'
    },
    {
      label: t(getTranslationKey('FORM_STATUS_TYPE_ACCESS_BEACON')),
      value: 'access beacon'
    },
    {
      label: t(getTranslationKey('FORM_STATUS_TYPE_EXIT_BEACON')),
      value: 'exit'
    },
    {
      label: t(getTranslationKey('FORM_STATUS_TYPE_OPEN_SPACE_BEACON')),
      value: 'open space beacon'
    }
  ];

  const textFieldChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof AccessPointsFormInput, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
    if (e.currentTarget.name === 'priority') {
      setValidatePriorityResponse(validatePriority(e.currentTarget.value));
    }
    if (e.currentTarget.name === 'uniqueId') {
      setImportErrorMessage(' ');
    }
  };

  const dropdownChangeHandler = (e: DropdownChangeEvent) => {
    setValue(e.target.name as keyof AccessPointsFormInput, e.value);
    setFormValues({ ...formValues, [e.target.name]: e.value });
  };

  useEffect(() => {
    register('accessPointType', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('doorName', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('beaconUUID', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('beaconMajor', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('beaconMinor', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('doorARXId', {
      validate: (value) => {
        if (
          getValues('accessPointType') === 'door' ||
          getValues('accessPointType') === 'exit' ||
          getValues('accessPointType') === ''
        ) {
          if (value) {
            return true;
          } else {
            return t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string;
          }
        } else {
          return true;
        }
      }
    });
    register('locationZoneId', {
      validate: (value) => {
        if (value === -1) {
          return false;
        } else {
          return true;
        }
      }
    });
    register('handbackTime', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('doorCaption', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('priority', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      validate: (value) => validatePriority(value)
    });
    register('status', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    register('uniqueId', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });
    if (isSodvin) {
      register('sodvinDoorId', {
        required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
      });
      register('sodvinDoorSecret', {
        required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
      });
    }
  }, [register, accessPointPriority, isSodvin]);

  const validatePriority = (value: string) => {
    if (props.accessPoints?.priority === value) {
      return true;
    }

    return !accessPointPriority.includes(+value);
  };

  const addLocationAccessPoint = async (data: AccessPointsFormInput) => {
    const addAccessPointInput: AddAccessPointInput = {
      siteLocationId: +gymId,
      accessPointType: data.accessPointType,
      beaconUUID: data.beaconUUID,
      beaconMajor: data.beaconMajor,
      beaconMinor: data.beaconMinor,
      doorARXId: data.doorARXId.replace(/'/g, "''"),
      doorName: data.doorName.replace(/'/g, "''"),
      handbackTime: data.handbackTime,
      doorCaption: data.doorCaption.replace(/'/g, "''"),
      priority: data.priority.replace(/'/g, "''"),
      status: data.status,
      createdBy: userId,
      lastModifiedBy: userId,
      uniqueId: data.uniqueId,
      sodvinDoorId: data.sodvinDoorId || '',
      sodvinDoorSecret: data.sodvinDoorSecret || '',
      locationZoneId: data.locationZoneId
    };

    try {
      await API.graphql({
        query: addAccessPoint,
        variables: {
          input: addAccessPointInput
        }
      });

      resetForm();
    } catch (err) {
      setFailedDialogVisible(true);
      console.log('Add Door Access Point Error: ', err);
    }
  };

  const modifyLocationAccessPoint = async (data: AccessPointsFormInput) => {
    const modifyDoorAccessPointInput: ModifyAccessPointInput = {
      locationAccessPointId: props.accessPoints?.id || -1,
      siteLocationId: +gymId,
      accessPointType: data.accessPointType,
      beaconUUID: data.beaconUUID,
      beaconMajor: data.beaconMajor,
      beaconMinor: data.beaconMinor,
      doorARXId: data.doorARXId.replace(/'/g, "''"),
      doorName: data.doorName.replace(/'/g, "''"),
      handbackTime: data.handbackTime,
      doorCaption: data.doorCaption.replace(/'/g, "''"),
      priority: data.priority.replace(/'/g, "''"),
      status: data.status,
      createdBy: props.accessPoints?.createdBy || -1,
      lastModifiedBy: userId,
      uniqueId: data.uniqueId,
      sodvinDoorId: data.sodvinDoorId || '',
      sodvinDoorSecret: data.sodvinDoorSecret || '',
      locationZoneId: data.locationZoneId
    };

    try {
      await API.graphql({
        query: modifyAccessPoint,
        variables: {
          input: modifyDoorAccessPointInput
        }
      });

      resetForm();
    } catch (err) {
      setFailedDialogVisible(true);
      console.log('Modify Class Access Point Error: ', err);
    }
  };

  const onSubmitHandler = async (data: AccessPointsFormInput) => {
    setIsInserted(true);
    if (props.type === 'new') {
      await addLocationAccessPoint(data);
    } else {
      await modifyLocationAccessPoint(data);
      templateTypeChangeHandler(false);
    }
    dispatch(
      fetchAccessPoints({
        siteLocationId: +gymId
      })
    );
    getPriority();
    setIsInserted(false);
  };

  const emptyValues: AccessPointsFormInput = {
    accessPointType: '',
    beaconUUID: '',
    beaconMajor: '',
    beaconMinor: '',
    doorARXId: '',
    locationZoneId: 0,
    doorName: '',
    doorDescription: '',
    handbackTime: handbackTime,
    doorCaption: '',
    priority: String(Math.max(...accessPointPriority) + 1),
    status: '',
    createdBy: userId,
    lastModifiedBy: userId,
    uniqueId: '',
    sodvinDoorId: '',
    sodvinDoorSecret: ''
  };

  const clearForm = () => {
    resetForm();
    dispatch(
      fetchAccessPoints({
        siteLocationId: +gymId
      })
    );
  };

  const resetForm = () => {
    setFormValues(emptyValues);
    reset(emptyValues);
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t(getTranslationKey('FORM_FAILURE_DIALOG_HEADER'))}
          dialogFooter={
            <Button
              label={t(getTranslationKey('FORM_FAILURE_DIALOG_BUTTON'))}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t(getTranslationKey('FORM_FAILURE_DIALOG_BODY'))}</Text>}
        />
      </div>
    );
  };

  /** Runs when import button is clicked. If import fails, form data is cleared. */
  const importHandler = async (e: any) => {
    e.preventDefault();
    setImporting(true);

    const sameUniqueIds = existingAccessPoints?.filter(
      (point) => point.uniqueId === formValues.uniqueId.replaceAll(' ', '')
    );
    try {
      if (
        sameUniqueIds &&
        sameUniqueIds.length > 0 &&
        !(props.type === 'modify' && sameUniqueIds.find((p) => p.id === props.accessPoints?.id))
      ) {
        setImportErrorMessage(t(getTranslationKey('FORM_INPUT_UNIQUE_ID_DUPLICATE_ERROR')));
        setImporting(false);
        setFormValues({
          ...formValues,
          beaconUUID: '',
          beaconMajor: '',
          beaconMinor: ''
        });
        return;
      }

      const res = await (API.graphql({
        query: importBeaconIdByUniqueId,
        variables: {
          uniqueId: formValues.uniqueId
        }
      }) as Promise<{
        data: {
          importBeaconIdByUniqueId: {
            error: boolean;
            errorMsg: string;
            BeaconId: { uuid: string; major: string; minor: string };
          };
        };
      }>);

      if (res.data.importBeaconIdByUniqueId.error) {
        setImportErrorMessage(res.data.importBeaconIdByUniqueId.errorMsg);
        setImporting(false);
        setFormValues({
          ...formValues,
          beaconUUID: '',
          beaconMajor: '',
          beaconMinor: ''
        });
        return;
      }
      setImportErrorMessage('');
      const BeaconId = res.data.importBeaconIdByUniqueId.BeaconId;
      setFormValues({
        ...formValues,
        beaconUUID: BeaconId.uuid,
        beaconMajor: BeaconId.major,
        beaconMinor: BeaconId.minor
      });
      setValue('beaconUUID', BeaconId.uuid);
      setValue('beaconMajor', BeaconId.major);
      setValue('beaconMinor', BeaconId.minor);
      setImporting(false);
    } catch (e) {
      setImporting(false);
      console.log('Import error', e);
    }
  };

  return (
    <div className="ml30 mt10">
      <div>
        <Text
          data-cy={'access-point-form-header'}
          bold={true}
          size={TextSizes.large}
          className="text-2xl">
          {props.heading}
        </Text>
      </div>

      <div className="col-md-6">
        <form className="input-mb20 py-4" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          <div className="row mb20">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_ACCESS_TYPE_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                name="accessPointType"
                options={typeDropDownItems}
                value={formValues.accessPointType}
                onChange={dropdownChangeHandler}
                placeholder={t(getTranslationKey('FORM_INPUT_ACCESS_TYPE_PLACEHOLDER'))}
                error={errors.accessPointType ? true : false}
              />
            </div>
            {errors.accessPointType && (
              <Text data-cy={'access-point-form-dropdown-type-error'} className="p-error mt10">
                {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
              </Text>
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <Input
                data-cy={'access-point-form-input-name'}
                label={t(getTranslationKey('FORM_INPUT_DOOR_NAME_LABEL'))}
                variant="basic"
                name="doorName"
                value={formValues.doorName}
                onChange={textFieldChangeHandler}
                error={errors.doorName ? true : false}
                errorMessage={errors.doorName?.message}
                placeholder={t(getTranslationKey('FORM_INPUT_DOOR_NAME_PLACEHOLDER'))}
                required={true}
              />
            </div>
          </div>

          <div className="d-flex flex-xxl-row flex-column align-items-xxl-end">
            <div className="me-xxl-4 flex-grow-1 input-mb10">
              <Input
                label={t(getTranslationKey('FORM_INPUT_UNIQUE_ID_LABEL'))}
                placeholder={t(getTranslationKey('FORM_INPUT_UNIQUE_ID_PLACEHOLDER'))}
                onChange={textFieldChangeHandler}
                value={formValues.uniqueId}
                name="uniqueId"
                required={true}
                error={errors.uniqueId ? true : false}
                errorMessage={errors.uniqueId?.message}
              />
            </div>
            <div className={errors.uniqueId ? 'mb48' : 'mb25'}>
              <Button
                label={t(getTranslationKey('FORM_BTN_LABEL_IMPORT'))}
                size={ButtonSizes.large}
                icon={importing ? 'pi-spinner pi-spin' : ''}
                onClick={(e) => {
                  importHandler(e);
                }}
                disabled={formValues.uniqueId ? false : true}
              />
            </div>
          </div>
          <div className="row mb25">
            {importErrorMessage && <p className="p-error mb-15">{importErrorMessage}</p>}
          </div>

          <div className="row">
            <label className="fw-bold">
              {t(getTranslationKey('FORM_INPUT_BEACON_ID_LABEL'))}
              <span className="p-error"> *</span>
            </label>

            <div className="col-6">
              <div className="no-label">
                <Input
                  data-cy={'access-point-form-input-uuid'}
                  variant="basic"
                  name="beaconUUID"
                  value={formValues.beaconUUID}
                  onChange={textFieldChangeHandler}
                  error={errors.beaconUUID ? true : false}
                  errorMessage={errors.beaconUUID?.message}
                  placeholder={t(getTranslationKey('FORM_INPUT_BEACON_UUID_PLACEHOLDER'))}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col">
              <div className="no-label">
                <Input
                  data-cy={'access-point-form-input-major'}
                  variant="basic"
                  name="beaconMajor"
                  value={formValues.beaconMajor}
                  onChange={textFieldChangeHandler}
                  error={errors.beaconMajor ? true : false}
                  errorMessage={errors.beaconMajor?.message}
                  placeholder={t(getTranslationKey('FORM_INPUT_BEACON_MAJOR_PLACEHOLDER'))}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col">
              <div className="no-label">
                <Input
                  data-cy={'access-point-form-input-minor'}
                  variant="basic"
                  name="beaconMinor"
                  value={formValues.beaconMinor}
                  onChange={textFieldChangeHandler}
                  error={errors.beaconMinor ? true : false}
                  errorMessage={errors.beaconMinor?.message}
                  placeholder={t(getTranslationKey('FORM_INPUT_BEACON_MINOR_PLACEHOLDER'))}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Input
                data-cy={'access-point-form-input-door-id'}
                label={t(getTranslationKey('FORM_INPUT_DOOR_ID_LABEL'))}
                variant="basic"
                name="doorARXId"
                value={formValues.doorARXId}
                onChange={textFieldChangeHandler}
                error={errors.doorARXId ? true : false}
                errorMessage={errors.doorARXId?.message}
                placeholder={t(getTranslationKey('FORM_INPUT_DOOR_ID_PLACEHOLDER'))}
                required={
                  getValues('accessPointType') === 'door' ||
                  getValues('accessPointType') === 'exit' ||
                  getValues('accessPointType') === ''
                }
              />
            </div>
          </div>

          {isSodvin && (
            <div className="row">
              <div className="col-12">
                <Input
                  label={t(getTranslationKey('FORM_INPUT_SODVIN_DOOR_ID_LABEL'))}
                  variant="basic"
                  name="sodvinDoorId"
                  value={formValues.sodvinDoorId}
                  onChange={textFieldChangeHandler}
                  error={errors.sodvinDoorId ? true : false}
                  errorMessage={errors.sodvinDoorId?.message}
                  placeholder={t(getTranslationKey('FORM_INPUT_SODVIN_DOOR_ID_PLACEHOLDER'))}
                  required={true}
                />
              </div>
            </div>
          )}

          {isSodvin && (
            <div className="row">
              <div className="col-12">
                <Input
                  label={t(getTranslationKey('FORM_INPUT_SODVIN_DOOR_SECRET_LABEL'))}
                  variant="basic"
                  name="sodvinDoorSecret"
                  value={formValues.sodvinDoorSecret}
                  onChange={textFieldChangeHandler}
                  error={errors.sodvinDoorSecret ? true : false}
                  errorMessage={errors.sodvinDoorSecret?.message}
                  placeholder={t(getTranslationKey('FORM_INPUT_SODVIN_DOOR_SECRET_PLACEHOLDER'))}
                  required={true}
                />
              </div>
            </div>
          )}

          <div className="row mb20">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_ZONE_NAME_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                name="locationZoneId"
                options={zoneDropDownItems}
                value={formValues.locationZoneId}
                onChange={dropdownChangeHandler}
                placeholder={t(getTranslationKey('FORM_INPUT_ZONE_NAME_PLACEHOLDER'))}
                error={errors.locationZoneId ? true : false}
              />
            </div>
            {errors.locationZoneId && (
              <Text data-cy={'access-point-form-dropdown-zone-error'} className="p-error mt10">
                {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
              </Text>
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <Input
                data-cy={'access-point-form-input-button-caption'}
                label={t(getTranslationKey('FORM_INPUT_DOOR_CAPTION_LABEL'))}
                variant="basic"
                name="doorCaption"
                value={formValues.doorCaption}
                onChange={textFieldChangeHandler}
                error={errors.doorCaption ? true : false}
                errorMessage={errors.doorCaption?.message}
                placeholder={t(getTranslationKey('FORM_INPUT_DOOR_CAPTION_PLACEHOLDER'))}
                required={true}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <Input
                data-cy={'access-point-form-input-priority'}
                label={t(getTranslationKey('FORM_INPUT_PRIORITY_LABEL'))}
                variant="basic"
                name="priority"
                value={formValues.priority}
                onChange={textFieldChangeHandler}
                error={errors.priority ? true : false}
                errorMessage={errors.priority?.message}
                placeholder={t(getTranslationKey('FORM_INPUT_PRIORITY_PLACEHOLDER'))}
              />
              {errors.priority?.type === 'validate' || !validatePriorityResponse ? (
                <Text data-cy={'access-point-form-input-priority-error'} className="p-error mb15">
                  {t(getTranslationKey('FORM_PRIORITY_FIELD_EXISTS'))}
                </Text>
              ) : (
                ''
              )}
            </div>

            <div className="col-6">
              <Input
                data-cy={'access-point-form-input-handback'}
                label={t(getTranslationKey('FORM_INPUT_HANDBACK_TIME_LABEL'))}
                variant="basic"
                name="handbackTime"
                value={formValues.handbackTime.toString()}
                onChange={textFieldChangeHandler}
                error={errors.handbackTime ? true : false}
                errorMessage={errors.handbackTime?.message}
                placeholder={t(getTranslationKey('FORM_INPUT_HANDBACK_TIME_PLACEHOLDER'))}
              />
            </div>
          </div>

          <div className="row mb40">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_STATUS_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                name="status"
                options={statusDropDownItems}
                value={formValues.status}
                onChange={dropdownChangeHandler}
                placeholder={t(getTranslationKey('FORM_INPUT_STATUS_PLACEHOLDER'))}
                error={errors.status ? true : false}
              />
            </div>
            {errors.status && (
              <Text data-cy={'access-point-form-input-status-error'} className="p-error mt10">
                {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
              </Text>
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <div className="d-flex btn-min-w-110">
                <div className="mr15">
                  <Button
                    data-cy={'access-point-btn-save'}
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                    label={t(getTranslationKey('FORM_BTN_LABEL_SAVE'))}
                    size={ButtonSizes.medium}
                    disabled={importErrorMessage ? true : false || isInserted}
                  />
                </div>
                <Button
                  data-cy={'access-point-btn-cancel'}
                  label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
                  variant={ButtonVariants.textonly}
                  onClick={(e) => {
                    clearForm();
                    e.preventDefault();
                    getPriority();
                    templateTypeChangeHandler(false);
                  }}
                  disabled={isInserted}
                />
              </div>
              <ActionFailedDialog />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
type GymAccessPointsTemplateType = 'new' | 'modify';

export interface GymAccessPointsTemplateProps {
  /** Type of the template: new or modify */
  type: GymAccessPointsTemplateType;
  /** Heading of the template */
  heading?: string;
  /** form values*/
  accessPoints?: AccessPoints | undefined;
  /** template change function */
  templateType?: (e: boolean) => void;
  /** provider id */
  providerId: number | null | undefined;
}

export default GymAccessPointsTemplate;
