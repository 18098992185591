import { AlertType } from '@gym-atoms/Alert/Alert';
import ZoneForm from '@gym-organisms/Zones/ZoneForm';
import ZoneList from '@gym-organisms/Zones/ZoneList';
import { UserRole } from '@gym-particles/types/User';
import { ZoneTable } from '@gym-particles/types/Zone';
import { useAppSelector } from '@gym-redux/store';
import { Card } from 'primereact/card';
import React, { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const ZonesPage = (props: { alertRef: RefObject<AlertType> }) => {
  const [isModifyTemplate, setIsModifyTemplate] = useState(false);
  const { t } = useTranslation();
  const { gymId } = useParams<{ gymId: string }>();
  const [zone, setZone] = useState<ZoneTable>();
  const userRole = useAppSelector((state) => state.user.userRole);

  return (
    <>
      {(userRole === UserRole.SYSTEM_ADMIN ||
        userRole === UserRole.GYM_USER ||
        userRole === UserRole.GYM_CHAIN_USER) && (
        <Card className="p-card-w-table">
          <ZoneForm
            type={isModifyTemplate ? 'modify' : 'new'}
            zoneData={isModifyTemplate ? zone : undefined}
            templateType={setIsModifyTemplate}
          />
        </Card>
      )}
      <Card className="p-card-w-table">
        <ZoneList
          templateType={setIsModifyTemplate}
          gymId={+gymId}
          heading={t('ZONES.TABLE_HEADING_ZONES')}
          templateZone={setZone}
        />
      </Card>
    </>
  );
};

export default ZonesPage;
