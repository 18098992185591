import React, { FormEvent, useCallback, useEffect } from 'react';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import Dropdown, { DropdownChangeEvent } from '@gym-atoms/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import Input from '@gym-atoms/Input/Input';
import DateTimePicker, {
  DateTimePickerChangeEvent
} from '@gym-atoms/DateTimePicker/DateTimePicker';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useAppSelector } from '@gym-redux/store';
import {
  GymSchedulesFormInput,
  GymScheduleStatus,
  ScheduleInternalStatus
} from '@gym-particles/types/GymSchedules';
import { useState } from 'react';

dayjs.extend(customParseFormat);

const GymScheduleTemplate = (props: GymScheduleTemplateProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const userId = useAppSelector((state) => state.user.userId);
  const { chainId } = useParams<{ chainId: string }>();
  const getTranslationKey = (e: string): string => {
    return props.type === 'new' ? 'ADD_NEW_GYM_SCHEDULE.' + e : 'MODIFY_GYM_SCHEDULE.' + e;
  };
  const [isInserted, setIsInserted] = useState(false);

  const gymScheduleDefaultValues: GymSchedulesFormInput = {
    type: props.gymSchedule?.type || '',
    name: props.gymSchedule?.name || '',
    validFromDate: props.gymSchedule?.validFromDate,
    validToDate: props.gymSchedule?.validToDate,
    status: props.gymSchedule?.status || 'active',
    createdBy: props.gymSchedule?.createdBy || -1,
    lastModifiedBy: userId
  };

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    reset,
    getValues
  } = useForm<GymSchedulesFormInput>({
    defaultValues: gymScheduleDefaultValues
  });

  const [formValues, setFormValues] = useState(gymScheduleDefaultValues);
  useEffect(() => {
    setFormValues(gymScheduleDefaultValues);
    reset(gymScheduleDefaultValues);
  }, [props.gymSchedule]);

  // To-Do: Add enums for dropdown values.
  const typeDropDownItems = [
    {
      label: t(getTranslationKey('FORM_TYPE_DROPDOWN.OPENING_HOUR_SCHEDULE')),
      value: 'opening hour schedule'
    },
    {
      label: t(getTranslationKey('FORM_TYPE_DROPDOWN.HOLIDAY_SCHEDULE')),
      value: 'holiday schedule'
    },
    {
      label: t(getTranslationKey('FORM_TYPE_DROPDOWN.DOOR_UNLOCK_SCHEDULE')),
      value: 'door unlock schedule'
    }
  ];

  const statusDropDownItems = [
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN.ACTIVE')),
      value: 'active'
    },
    {
      label: t(getTranslationKey('FORM_STATUS_DROPDOWN.INACTIVE')),
      value: 'inactive'
    }
  ];

  const textFieldChangeHandler = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.name as keyof GymSchedulesFormInput, e.currentTarget.value);
    setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
  };

  const dropdownChangeHandler = (e: DropdownChangeEvent) => {
    setValue(e.target.name as keyof GymSchedulesFormInput, e.value);
    setFormValues({ ...formValues, [e.target.name]: e.value });
  };

  const dateTimeChangeHandler = (e: DateTimePickerChangeEvent) => {
    setValue(e.target.name as keyof GymSchedulesFormInput, e.target.value);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    register('type', {
      validate: (value) => {
        if (value === '') {
          return false;
        } else {
          return true;
        }
      }
    });

    register('name', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string
    });

    register('validFromDate', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      validate: () => validateDateRange()
    });

    register('validToDate', {
      required: t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string,
      validate: () => validateDateRange()
    });
    register('status', {
      validate: (value) => {
        if (props.type === 'new') {
          if (value === '') {
            return t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR')) as string;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    });
  }, [register]);

  const validateDateRange = () => {
    if (getValues('validToDate')) {
      if (
        dayjs(dayjs(getValues('validToDate')).format('YYYY-MM-DD')).isBefore(
          dayjs(getValues('validFromDate')).format('YYYY-MM-DD')
        )
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const emptyValues: GymSchedulesFormInput = {
    type: '',
    name: '',
    validFromDate: undefined,
    validToDate: undefined,
    status: '',
    createdBy: userId,
    lastModifiedBy: userId
  };

  const updateSchedulesState = useCallback(
    (state) => {
      if (props.setScheduleForm) {
        props.setScheduleForm(state);
      }
    },
    [props.setScheduleForm]
  );

  const onSubmitHandler = (data: GymSchedulesFormInput) => {
    setTimeout(() => {
      setIsInserted(true);
    }, 50);
    updateSchedulesState(data);
    props.submitHandler(data);
    setIsInserted(false);
  };

  return (
    <div className="ml30 mt10">
      <div>
        <Text
          data-cy={'gym-schedules-form-header'}
          bold={true}
          size={TextSizes.large}
          className="text-2xl">
          {props.heading}
        </Text>
      </div>

      <div className="col-md-6">
        <form className="input-mb20 py-4" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="row mb20">
            <div className="col-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_SCHEDULE_TYPE_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <Dropdown
                data-cy={'gym-schedules-form-type-dropdown'}
                name="type"
                options={typeDropDownItems}
                value={formValues.type}
                onChange={dropdownChangeHandler}
                placeholder={t(getTranslationKey('FORM_INPUT_SCHEDULE_TYPE_PLACEHOLDER'))}
                error={errors.type?.type ? true : false}
                disabled={props.type === 'modify' || props.isBacked}
              />
            </div>
            <div className="mt10">
              {errors.type && (
                <p data-cy={'gym-schedule-form-dropdown-type-error'} className="p-error mb15">
                  {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                </p>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Input
                data-cy={'gym-schedules-form-input-schedule-name'}
                label={t(getTranslationKey('FORM_INPUT_SCHEDULE_NAME_LABEL'))}
                variant="basic"
                name="name"
                value={formValues.name}
                error={errors.name ? true : false}
                errorMessage={errors.name?.message}
                onChange={textFieldChangeHandler}
                placeholder={t(getTranslationKey('FORM_INPUT_SCHEDULE_NAME_PLACEHOLDER'))}
                required={true}
              />
            </div>
          </div>

          <div className="row mb25">
            <div className="col-md-6 col-sm-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_SCHEDULE_VALID_FROM_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <div className="no-label">
                <DateTimePicker
                  data-cy={'gym-schedules-form-valid-from-date-picker'}
                  name={'validFromDate'}
                  variant={'date'}
                  placeholder={t(getTranslationKey('FORM_INPUT_SCHEDULE_DATE_PLACEHOLDER'))}
                  onChange={dateTimeChangeHandler}
                  value={formValues.validFromDate}
                />
                <div className="mt10">
                  {errors.validFromDate && (
                    <Text data-cy={'gym-shedule-form-date-invalid-error'} className="p-error ">
                      {errors.validFromDate?.type === 'required'
                        ? t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))
                        : t(getTranslationKey('FORM_INPUT_INVALID_DATE_VALIDAION'))}
                    </Text>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <label className="fw-bold">
                {t(getTranslationKey('FORM_INPUT_SCHEDULE_VALID_TO_LABEL'))}
                <span className="p-error"> *</span>
              </label>
              <div className="no-label">
                <DateTimePicker
                  data-cy={'gym-schedules-form-valid-to-date-picker'}
                  name={'validToDate'}
                  variant={'date'}
                  onChange={dateTimeChangeHandler}
                  value={formValues.validToDate}
                  placeholder={t(getTranslationKey('FORM_INPUT_SCHEDULE_DATE_PLACEHOLDER'))}
                />
                <div className="mt10">
                  {errors.validToDate && (
                    <Text data-cy={'gym-shedules-form-date-invalid-error'} className="p-error ">
                      {errors.validToDate?.type === 'required'
                        ? t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))
                        : t(getTranslationKey('FORM_INPUT_INVALID_DATE_VALIDAION'))}
                    </Text>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row mb20">
            <div className="col-12">
              {props.type === 'modify' &&
                props.scheduleStatusId ===
                  props.scheduleStatuses.find((a) => a.name === ScheduleInternalStatus.Active)
                    ?.id && (
                  <Dropdown
                    data-cy={'gym-schedules-form-status-dropdown'}
                    name="status"
                    label={t(getTranslationKey('FORM_INPUT_SCHEDULE_STATUS_LABEL'))}
                    options={statusDropDownItems}
                    value={formValues.status}
                    onChange={dropdownChangeHandler}
                    placeholder={t(getTranslationKey('FORM_STATUS_DROPDOWN.PLACEHOLDER'))}
                    error={errors.status?.type ? true : false}
                  />
                )}
            </div>
            <div className="mt10">
              {errors.status && (
                <Text data-cy={'access-point-form-dropdown-status-error'} className="p-error mb15">
                  {t(getTranslationKey('FORM_REQUIRED_FIELD_ERROR'))}
                </Text>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="d-flex btn-min-w-110">
                <div className="mr15">
                  <Button
                    data-cy={'gym-schedules-form-btn-nest'}
                    label={t(getTranslationKey('FORM_BTN_LABEL_NEXT'))}
                    icon={isInserted ? 'pi-spinner pi-spin' : ''}
                    size={ButtonSizes.medium}
                    disabled={isInserted}
                  />
                </div>
                <Button
                  data-cy={'gym-schedules-form-btn-cancel'}
                  label={t(getTranslationKey('FORM_BTN_LABEL_CANCEL'))}
                  variant={ButtonVariants.textonly}
                  onClick={() => history.push(`/gymChains/${chainId}/gymSchedules`)}
                  disabled={isInserted}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

type GymScheduleTemplateType = 'new' | 'modify';

export interface GymScheduleTemplateProps {
  type: GymScheduleTemplateType;
  heading?: string;
  gymSchedule?: GymSchedulesFormInput | undefined;
  templateType?: (e: boolean) => void;
  setScheduleForm?: (e: GymSchedulesFormInput) => void;
  submitHandler: (data: GymSchedulesFormInput) => void;
  scheduleStatusId: number;
  scheduleStatuses: GymScheduleStatus[];
  isBacked?: boolean;
}

export default GymScheduleTemplate;
