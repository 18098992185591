import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { GymSchedule } from '@gym-src/API';
import { GymScheduleType } from '@gym-particles/types/models';
import { getGymSchedules } from '@gym-graphql/queries';
import { removeSiteSchedules } from '@gym-graphql/mutations';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  isGymSchedulesLoading: boolean;
  items: Array<{ siteId: number; items: GymScheduleType[]; totalRecords: number }>;
} = {
  isGymSchedulesLoading: false,
  items: []
};

type paramType = {
  siteId: number;
};

type deleteParamType = {
  id: number;
  userId: number;
  siteId: number;
};

export const fetchGymSchedules = createAsyncThunk(
  '/gym/gymSchedules',
  async (params: paramType) => {
    const response = await (API.graphql({
      query: getGymSchedules,
      variables: {
        siteId: params.siteId
      }
    }) as Promise<{
      data: {
        getGymSchedules: {
          items: GymSchedule[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const deleteGymSchedule = createAsyncThunk(
  'gymchain/deleteGymSchedule',
  async (params: deleteParamType) => {
    const response = await (API.graphql({
      query: removeSiteSchedules,
      variables: {
        id: params.id,
        userId: params.userId,
        siteId: params.siteId
      }
    }) as Promise<{
      data: {
        removeGymSchedules: number;
      };
    }>);
    return response;
  }
);

export const GymSchedulesSlice = createSlice({
  name: 'GymSchedules',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGymSchedules.fulfilled, (state, action) => {
      state.isGymSchedulesLoading = false;
      if (!action.payload.data.getGymSchedules.items) {
        return;
      }

      const items = action.payload.data.getGymSchedules.items.map(
        (e: GymSchedule): GymScheduleType => {
          return {
            siteId: e.siteId || 0,
            siteName: e.siteName || '',
            siteScheduleId: e.siteScheduleId || 0,
            siteScheduleName: e.siteScheduleName || '',
            siteScheduleType: e.siteScheduleType
              ? e.siteScheduleType.charAt(0).toUpperCase() + e.siteScheduleType.slice(1)
              : '',
            validFrom: e.validFrom ? dayjs(e.validFrom || '').format('DD MMM YYYY') : '',
            validTo: e.validTo ? dayjs(e.validTo || '').format('DD MMM YYYY') : '',
            scheduleStatusId: e.scheduleStatusId || 0,
            scheduleStatusName: e.scheduleStatusName
              ? e.scheduleStatusName.charAt(0).toUpperCase() + e.scheduleStatusName.slice(1)
              : '',
            siteScheduleStatus: e.siteScheduleStatus
              ? e.siteScheduleStatus.charAt(0).toUpperCase() + e.siteScheduleStatus.slice(1)
              : '',
            siteScheduleCreatedBy: e.siteScheduleCreatedBy || 0,
            siteScheduleCreatedDate: e.siteScheduleCreatedDate
              ? dayjs(e.siteScheduleCreatedDate || '').format('DD MMM YYYY')
              : '',
            siteScheduleLastModifiedBy: e.siteScheduleLastModifiedBy || 0,
            siteScheduleLastModifiedDate: e.siteScheduleLastModifiedDate
              ? dayjs(e.siteScheduleLastModifiedDate || '').format('DD MMM YYYY')
              : '',
            siteScheduleDetailId: e.siteScheduleDetailId || 0,
            weekdayId: e.weekdayId || 0,
            weekdayName: e.weekdayName || '',
            startTime: e.startTime ? dayjs(e.startTime || '').format('DD MMM YYYY HH:mm') : '',
            endTime: e.endTime ? dayjs(e.endTime || '').format('DD MMM YYYY HH:mm') : '',
            dayScheduleId: e.dayScheduleId || 0,
            siteScheduleDetailCreatedBy: e.siteScheduleDetailCreatedBy || 0,
            siteLocationScheduleId: e.siteLocationScheduleId || 0,
            siteLocationId: e.siteLocationId || 0,
            siteLocationName: e.siteLocationName || '',
            zoneId: e.zoneId || 0,
            zoneName: e.zoneName || '',
            siteLocationAccessCategoryId: e.siteLocationAccessCategoryId || 0,
            accessCategoryName: e.accessCategoryName || '',
            locationAccessPointId: e.locationAccessPointId || 0,
            locationAccessPointName: e.locationAccessPointName || '',
            siteLocationScheduleCreatedBy: e.siteLocationScheduleCreatedBy || 0,
            siteScheduleDetailStatus: e.siteScheduleDetailStatus || '',
            imageUrl: e.imageUrl || ''
          };
        }
      );

      const totalRecords = action.payload.data.getGymSchedules.totalRecords.totalRecords;

      const fetchedGymSchedulesInfo = {
        totalRecords: totalRecords,
        items: items,
        siteId: action.meta.arg.siteId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedGymSchedulesInfo);
      } else {
        const update = state.items.find(
          (gymScheduleInfo) => gymScheduleInfo.siteId === fetchedGymSchedulesInfo.siteId
        );
        if (update) {
          const newState = state.items.map((scheduleInfo) => {
            if (scheduleInfo.siteId === fetchedGymSchedulesInfo.siteId) {
              return fetchedGymSchedulesInfo;
            } else {
              return scheduleInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedGymSchedulesInfo);
        }
      }
    });

    builder.addCase(fetchGymSchedules.pending, (state, aaction) => {
      state.isGymSchedulesLoading = true;
      return state;
    });

    builder.addCase(fetchGymSchedules.rejected, (state, action) => {
      state.isGymSchedulesLoading = false;
      console.log('Fetch gym schedules error');
      return state;
    });

    builder.addCase(deleteGymSchedule.fulfilled, (state, action) => {
      state.isGymSchedulesLoading = false;
      const filtered = state.items.map((gymSchedulesObj) => {
        const filteredGymSchedules = gymSchedulesObj.items.filter(
          (gs) => gs.siteScheduleId !== action.meta.arg.id
        );
        gymSchedulesObj.items = filteredGymSchedules;
        gymSchedulesObj.totalRecords = gymSchedulesObj.totalRecords - 1;
        return gymSchedulesObj;
      });
      state.items = filtered;
    });

    builder.addCase(deleteGymSchedule.pending, (state, action) => {
      state.isGymSchedulesLoading = true;
      return state;
    });

    builder.addCase(deleteGymSchedule.rejected, (state, action) => {
      state.isGymSchedulesLoading = false;
      return state;
    });
  }
});

export default GymSchedulesSlice.reducer;
