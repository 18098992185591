import { useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '@gym-redux/store';
import { Card } from 'primereact/card';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { GymType as Gym } from '@gym-particles/types/Gym';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import GymInfoTemplate from '@gym-templates/GymInfo/GymInfoTemplate';
import { getGymById } from '@gym-redux/slices/gymsSlice';
import Loader from '@gym-atoms/Loader/Loader';

const MainColumn = (props: { chainId: number; gym?: Gym }) => {
  if (props.gym) {
    return (
      <>
        <Card className="pt30 pl40 pr40 pb30">
          <GymInfoTemplate type="modify" gymChainId={props.chainId} gym={props.gym} />
        </Card>
      </>
    );
  } else {
    return (
      <Card className="pt30 pl40 pr40 pb30">
        <div className="mr15 mb18">
          <Loader shape="rectangle" />
        </div>
        <div className="mr15 mb18">
          <Loader shape="rectangle" />
        </div>
        <div className="mr15 mb18">
          <Loader shape="rectangle" />
        </div>
      </Card>
    );
  }
};

const ModifyGymPage = () => {
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  const { chainId, gymId } = useParams<{ chainId: string; gymId: string }>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedGym = useAppSelector((state) =>
    state.gyms.items.find((gymsInfo) => gymsInfo.chainId === +chainId)
  )?.items.find((gym) => gym.gymId === +gymId);

  const fetchFailed = useAppSelector((state) => state.gyms.fetchGymByIdFailed);

  useEffect(() => {
    dispatch(getGymById({ gymId: +gymId, gymChainId: +chainId }));
  }, []);

  /** When an invalid gym ID is entered, redirect to gyms list */
  useEffect(() => {
    if (fetchFailed) {
      history.push(`/gymChains/${chainId}/gyms`);
    }
  }, [fetchFailed]);

  return (
    <TwoColTemplate
      title={t('MODIFY_GYM.PAGE_TITLE')}
      col1={<MainColumn chainId={+chainId} gym={selectedGym} />}
      gymChainId={+chainId}
      alertRef={alertRef}
      backButtonHandler={() => history.push(`/gymChains/${chainId}/gyms`)}
    />
  );
};

export default ModifyGymPage;
