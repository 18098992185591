import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { GymChain, PaginatedQueryParams } from '../../particles/types/models';
import { getGymChains, getGymChainById as getGymChainByIdQuery } from '../../graphql/queries';
import { deleteGymChain as deleteGymChainMutation } from '../../graphql/mutations';
import { WebGymChain } from '../../API';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const initialState: {
  isGymchainsLoading: boolean;
  items: Array<GymChain>;
  sideBarGymChains: Array<GymChain>;
  currentGymChain?: GymChain;
  totalRecords: number;
  fetchGymChainByIdFailed: boolean;
  fetchGymChainByIdPending: boolean;
} = {
  isGymchainsLoading: false,
  items: [],
  sideBarGymChains: [],
  totalRecords: 0,
  fetchGymChainByIdFailed: false,
  fetchGymChainByIdPending: false
};

export const fetchGymChains = createAsyncThunk(
  'gymChain/fetchGymchains',
  async (params: PaginatedQueryParams) => {
    // accouting for differences between graphql type fields and React project type fields:
    if (params.sortField === 'name') {
      params.sortField = 'customerName';
    }

    const response = await (API.graphql({
      query: getGymChains,
      variables: {
        offset: params.offset,
        pageSize: params.pageSize,
        sortField: params.sortField,
        sortOrder: params.sortOrder === 1 ? 'ASC' : 'DESC',
        search: { searchField: params.search?.searchField, searchText: params.search?.searchText },
        userId: params.userId
      }
    }) as Promise<{
      data: { getGymChains: { items: WebGymChain[]; totalRecords: { totalRecords: number } } };
    }>);
    return response;
  }
);

export const fetchSidebarGymChains = createAsyncThunk(
  'gymChain/fetchSidebarGymChains',
  async (params: { userId: number }) => {
    // accouting for differences between graphql type fields and React project type fields:
    const response = await (API.graphql({
      query: getGymChains,
      variables: {
        offset: 0,
        pageSize: 10,
        sortField: 'createdDate',
        sortOrder: 'DESC',
        userId: params.userId
      }
    }) as Promise<{
      data: { getGymChains: { items: WebGymChain[]; totalRecords: { totalRecords: number } } };
    }>);
    return response;
  }
);

export const deleteGymChain = createAsyncThunk('gymChain/deleteGymchain', async (id: number) => {
  const response = await (API.graphql({
    query: deleteGymChainMutation,
    variables: {
      id: id
    }
  }) as Promise<{
    data: { deleteGymChain: WebGymChain };
  }>);
  return response;
});

export const getGymChainById = createAsyncThunk('gymChain/getGymChainById', async (id: number) => {
  const response = await (API.graphql({
    query: getGymChainByIdQuery,
    variables: {
      id: id
    }
  }) as Promise<{
    data: { getGymChainById: WebGymChain[] };
  }>);
  return response;
});

export const gymChainSlice = createSlice({
  name: 'gymChain',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGymChains.fulfilled, (state, action) => {
      state.isGymchainsLoading = false;
      if (action.payload.data.getGymChains.items === null) {
        return;
      }

      const newItems = action.payload.data.getGymChains.items.map(getFormattedGymChains);
      if (
        state.currentGymChain &&
        !newItems.find((chain) => chain.id === state.currentGymChain?.id)
      ) {
        newItems.push(state.currentGymChain);
      }
      state.items = newItems;
      state.totalRecords = action.payload.data.getGymChains.totalRecords.totalRecords;
      return state;
    });

    builder.addCase(fetchGymChains.pending, (state, action) => {
      state.isGymchainsLoading = true;
      return state;
    });

    builder.addCase(fetchSidebarGymChains.fulfilled, (state, action) => {
      if (action.payload.data.getGymChains.items === null) {
        return;
      }

      state.sideBarGymChains = action.payload.data.getGymChains.items.map(getFormattedGymChains);
      return state;
    });

    builder.addCase(deleteGymChain.fulfilled, (state, action) => {
      state.isGymchainsLoading = false;
      const filteredGymChains = state.items.filter((g) => g.id !== action.meta.arg);
      state.items = filteredGymChains;
      state.totalRecords = state.totalRecords - 1;
      return state;
    });
    builder.addCase(deleteGymChain.pending, (state, action) => {
      state.isGymchainsLoading = true;
      return state;
    });

    builder.addCase(getGymChainById.fulfilled, (state, action) => {
      state.fetchGymChainByIdPending = false;
      const fetchedData = action.payload.data.getGymChainById;
      const gymChainId = action.meta.arg;

      if (fetchedData.length === 0) {
        // TO-DO: inform user about the error (When alert system is implemented).
        state.fetchGymChainByIdFailed = true;
        console.error(`Failed to fetch gymchain with id: ${gymChainId}`);
        return state;
      } else {
        state.fetchGymChainByIdFailed = false;
      }

      const parsedGymChain = getFormattedGymChains(fetchedData[0]);

      // if its already in the store, replace it
      let gymChainReplaced = false;
      const newStore = state.items.map((gymChain) => {
        if (gymChain.id === gymChainId) {
          gymChainReplaced = true;
          return parsedGymChain;
        } else {
          return gymChain;
        }
      });

      // ...otherwise, push it
      if (!gymChainReplaced) {
        newStore.push(parsedGymChain);
      }

      state.items = newStore;
      state.currentGymChain = parsedGymChain;
      return state;
    });
    builder.addCase(getGymChainById.pending, (state, action) => {
      state.fetchGymChainByIdPending = true;
    });
    builder.addCase(getGymChainById.rejected, (state, action) => {
      state.fetchGymChainByIdPending = false;
      state.fetchGymChainByIdFailed = true;
      return state;
    });
  }
});

const getFormattedGymChains = (gymChain: WebGymChain): GymChain => {
  return {
    id: gymChain.id || 0,
    name: gymChain.customerName || 'Loading..',
    avatar: gymChain.imageUrl || undefined,
    gymCount: gymChain.gymCount || 0,
    contactPerson: gymChain.customerContactPerson || undefined,
    phoneNumber: gymChain.customerPhone || undefined,
    supportPhoneNumber: gymChain.supportPhone || undefined,
    maximumNegativeBalance: gymChain.maximumNegativeBalance || 0,
    kisiApiKey: gymChain.kisiApiKey || undefined,
    kisiNightlySyncApiKey: gymChain.kisiNightlySyncApiKey || undefined,
    lastModifiedDate: gymChain.lastModifiedDate || undefined,
    createdDate: gymChain.createdDate
      ? dayjs(gymChain.createdDate || '').format('DD MMM YYYY')
      : '',
    lastImportedDate: gymChain.lastImportedDate
      ? dayjs().to(dayjs.utc(gymChain.lastImportedDate || ''))
      : '',
    providerId: gymChain.providerId,
    customerSiteId: gymChain.customerSiteId,
    status: gymChain.status,
    membershipsImported: gymChain.membershipsImported,
    createdBy: gymChain.createdBy,
    lastModifiedBy: gymChain.lastModifiedBy,
    customerEmail: gymChain.customerEmail,
    customerAddress: gymChain.customerAddress,
    arxReferenceId: gymChain.arxReferenceId || undefined,
    providerConnected: gymChain.providerConnected || undefined,
    isSodvinCompany: gymChain.isSodvinCompany,
    displayStatus: gymChain.status
      ? gymChain.status.charAt(0).toUpperCase() + gymChain.status.slice(1)
      : '',
    allowConvertingAccessCards: gymChain.allowConvertingAccessCards || false,
    processSales: gymChain.processSales || false,
    processBookings: gymChain.processBookings || false,
    processAppointments: gymChain.processAppointments || false,
    fetchAccountBalance: gymChain.fetchAccountBalance || false
  };
};

export default gymChainSlice.reducer;
