import Button, { ButtonVariants } from '@gym-atoms/Button/Button';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Loader from '@gym-atoms/Loader/Loader';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { UserRole } from '@gym-particles/types/User';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@gym-atoms/Text/Text';
import { fetchPricingRelationships } from '@gym-redux/slices/pricingRelationshipSlice';
import { PricingRelationshipTable } from '@gym-particles/types/PricingRelationships';
import { AddProgram, LambdaResponse, RemoveProgram } from '@gym-src/API';
import { modifyProgramMapping } from '@gym-graphql/mutations';
import { API } from 'aws-amplify';

const PricingRelationshipList = (props: PricingRelationshipListProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState<PricingRelationshipTable>();
  const [sortField, setSortField] = useState('linkedCreatedDate');
  const [sortOrder, setSortOrder] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const userId = useAppSelector((state) => state.user.userId);
  const userRole = useAppSelector((state) => state.user.userRole);
  const [isDeleting, setIsDeleting] = useState(false);
  const isPricingRelationshipLoading = useAppSelector(
    (state) => state.pricingRelationships.isPricingRelationshipLoading
  );

  useEffect(() => {
    dispatch(fetchPricingRelationships(props.chainId));
  }, []);

  const templateTypeChangeHandler = useCallback(
    (state) => {
      props.templateType(state);
    },
    [props.templateType]
  );

  const templateProgramRelationshipHandler = useCallback(
    (state) => {
      props.templatePrograms(state);
    },
    [props.templatePrograms]
  );

  const programRelationships = useAppSelector((state) =>
    state.pricingRelationships.items.filter((p) => p.relatedPrograms.length > 0)
  );

  const menuItem = [
    {
      label: t('COMMON.MODIFY'),
      command: () => {
        templateTypeChangeHandler(true);
        templateProgramRelationshipHandler(selectedRow);
      }
    },
    {
      label: t('COMMON.DELETE'),
      command: () => {
        templateTypeChangeHandler(false);
        setDeleteDialogVisible(true);
      }
    }
  ];

  const tablePropsPricingRelationships: TableProps<PricingRelationshipTable> = {
    exportFileName: t('PRICING_RELATIONSHIPS.EXPORT_EXCEL_FILE_NAME'),
    setPageSize: setPageSize,
    setOffset: setOffset,
    pageSize: pageSize,
    offset: offset,
    setSortField: setSortField,
    setSortOrder: setSortOrder,
    sortField: sortField,
    sortOrder: sortOrder,
    uniqueFieldName: 'id',
    data: programRelationships,
    totalRecords: programRelationships.length,
    headerText: props.heading,
    columns: [
      {
        field: 'programName',
        header: t('PRICING_RELATIONSHIPS.TABLE.HEADER_PROGRAM_NAME'),
        toggable: false,
        truncateText: true,
        sortable: true
      },
      {
        field: 'scheduleType',
        header: t('PRICING_RELATIONSHIPS.TABLE.HEADER_SCHEDULE_TYPE'),
        toggable: true,
        truncateText: true,
        sortable: true
      },
      {
        field: 'createdDate',
        header: t('PRICING_RELATIONSHIPS.TABLE.HEADER_CREATED_DATE'),
        toggable: true,
        truncateText: true
      }
    ],
    expandable: true,
    expandableField: 'relatedPrograms',
    expandedCols: [
      {
        field: 'programName',
        header: t('PRICING_RELATIONSHIPS.TABLE.HEADER_PROGRAM_NAME'),
        toggable: false,
        truncateText: true
      },
      {
        field: 'scheduleType',
        header: t('PRICING_RELATIONSHIPS.TABLE.HEADER_SCHEDULE_TYPE'),
        toggable: true,
        truncateText: true
      },
      {
        field: 'createdDate',
        header: t('PRICING_RELATIONSHIPS.TABLE.HEADER_CREATED_DATE'),
        toggable: true,
        truncateText: true
      }
    ],
    searchPlaceholderText: t('PRICING_RELATIONSHIPS.SEARCH_FIELD_TEXT'),
    emptyStateTexts: [t('PRICING_RELATIONSHIPS.EMPTY_STATE_TEXT')],
    excelBtntext: t('PRICING_RELATIONSHIPS.EXPORT_EXCEL_BUTTON_LABEL'),
    selectedRow: selectedRow,
    setSelectedRow: setSelectedRow,
    isContextMenu: userRole === UserRole.SYSTEM_ADMIN ? true : false,
    menuItem: menuItem,
    lazy: false
  };

  const deleteDialogHandler = async () => {
    const removePrograms =
      selectedRow?.relatedPrograms.map((a) => {
        const rpObj: RemoveProgram = {
          originalProgramId: selectedRow.id,
          originalProgramImportedId: selectedRow.importedId,
          relatedProgramId: a.id,
          relatedProgramImportedId: a.importedId,
          lastModifiedBy: userId,
          siteId: props.chainId
        };
        return rpObj;
      }) || [];

    setIsDeleting(true);
    const response = await modifyPricingRelationships([], removePrograms);
    if (response.error) {
      setErrorDialogVisible(true);
    } else {
      dispatch(fetchPricingRelationships(props.chainId));
    }
    setIsDeleting(false);
    setDeleteDialogVisible(false);
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.CANCEL')}
          variant={ButtonVariants.textonly}
          onClick={() => setDeleteDialogVisible(false)}
        />
        <Button
          label={t('COMMON.DELETE')}
          variant={ButtonVariants.danger}
          onClick={deleteDialogHandler}
          icon={isDeleting ? 'pi-spinner pi-spin' : ''}
        />
      </div>
    );
  };

  const ErrorDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.OK')}
          variant={ButtonVariants.textonly}
          onClick={() => setErrorDialogVisible(false)}
        />
      </div>
    );
  };

  return (
    <div>
      <DialogBox
        dialogVisible={deleteDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setDeleteDialogVisible(false)}
        dialogFooter={<DeleteDialogFooter />}
        dialogClosable={false}
        dialogHeader={t('COMMON.DELETE')}
        content={
          <Text>
            {`${t('PRICING_RELATIONSHIPS.DELETE_MODAL_CONFIRM')} ${selectedRow?.programName}?`}
          </Text>
        }
      />
      <DialogBox
        dialogVisible={errorDialogVisible}
        variant={DialogBoxVariants.long}
        dialogDismissableMask={true}
        onHideCallback={() => setErrorDialogVisible(false)}
        dialogFooter={<ErrorDialogFooter />}
        dialogClosable={false}
        dialogHeader={t('PRICING_RELATIONSHIPS.ERROR_HEADER')}
        content={<Text>{t('PRICING_RELATIONSHIPS.ERROR_MESSAGE')}</Text>}
      />
      {isPricingRelationshipLoading ? (
        <Loader shape="table" />
      ) : (
        <Table {...tablePropsPricingRelationships} />
      )}
    </div>
  );
};

export default PricingRelationshipList;

export const modifyPricingRelationships = async (
  addProgram: AddProgram[],
  removeProgram: RemoveProgram[]
) => {
  const response = await (API.graphql({
    query: modifyProgramMapping,
    variables: {
      input: {
        add: addProgram,
        remove: removeProgram
      }
    }
  }) as Promise<{
    data: { modifyProgramMapping: LambdaResponse };
  }>);
  return response.data.modifyProgramMapping;
};

interface PricingRelationshipListProps {
  chainId: number;
  templateType: (e: boolean) => void;
  templatePrograms: (e: PricingRelationshipTable) => void;
  heading?: string;
}
