import { useEffect, useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import Text, { TextSizes } from '@gym-atoms/Text/Text';
import Avatar from '@gym-atoms/Avatar/Avatar';
import Image from '@gym-atoms/Image/Image';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import Alert, { AlertType, createAlert } from '@gym-atoms/Alert/Alert';
import { Account, AccountSubscription } from '@gym-particles/types/Account';
import { acronym } from '@gym-particles/acronym';
import {
  getActivityForMember,
  getEventByDescription,
  getMembersForUser,
  getMembershipsForUser
} from '@gym-graphql/queries';
import {
  addRequestInfoEvent,
  addUserInformationRequest,
  sendRequestMoreInformationEmail,
  updateDefaultLocationDetectionMethod
} from '@gym-graphql/mutations';
import styles from './AccountDetails.module.scss';
import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import Loader from '@gym-atoms/Loader/Loader';
import ActivityFeed from '@gym-molecules/ActivityFeed/ActivityFeed';
import EmptyState from '@gym-molecules/EmptyState/EmptyState';
import { DateTimePickerChangeEvent } from '@gym-atoms/DateTimePicker/DateTimePicker';
import ActivityFeedFilters from '@gym-molecules/ActivityFeedFilters/ActivityFeedFilters';
import {
  GetEventByDescriptionQuery,
  GymActivity,
  GymActivityResponse,
  MemberInquiry,
  MemberResponse,
  RequestInfoMemberEventInput
} from '@gym-src/API';
import { Activity } from '@gym-particles/types/ActivityFeed';
import { UserRole } from '@gym-particles/types/User';
import { Events } from '@gym-particles/types/Events';
import Checkbox, { CheckboxChangeEvent } from '@gym-atoms/Checkbox/Checkbox';

enum eventTypes {
  all = 'all',
  errors = 'Errors',
  visits = 'Visit Activity'
}

const AccountDetailsModalContent = (props: { account: Account }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [subscriptionData, setSubscriptionData] = useState<AccountSubscription[]>([]);
  const [
    subscriptionTableSelectedRow,
    setSubscriptionTableSelectedRow
  ] = useState<AccountSubscription>();
  const [requestingMoreInfo, setRequestingMoreInfo] = useState(false);
  const [informationRequested, setInformationRequested] = useState(
    props.account.isMoreInfoRequested
  );
  const [allActivity, setAllActivity] = useState(true);
  const [errorActivity, setErrorActivity] = useState(false);
  const [accessActivity, setAccessActivity] = useState(false);
  const [inquiry, setInquiry] = useState(false);
  const [startingDate, setStartingDate] = useState<Date>(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [endingDate, setEndingDate] = useState<Date>(new Date());
  const [selected, setSelected] = useState<number>(0);
  const [eventType, setEventType] = useState<string>('all');
  const [refresh, setRefresh] = useState(false);

  const alertRef = useRef<AlertType>(null);

  const userId = useAppSelector((state) => state.user.userId);
  const userRole = useAppSelector((state) => state.user.userRole);

  const [accountDetailsSortField, setAccountDetailsSortField] = useState('mlmId');
  const [accountDetailsSortOrder, setAccountDetailsSortOrder] = useState(-1);
  const [accountDetailsOffset, setAccountDetailsOffset] = useState(0);
  const [accountDetailsPageSize, setAccountDetailsPageSize] = useState(10);
  const [gpsChecked, setGpsChecked] = useState(false);
  const [bluetoothChecked, setBluetoothChecked] = useState(false);

  /** Update location detection to GPS */
  const onGpsCheckedChange = (event: CheckboxChangeEvent) => {
    API.graphql({
      query: updateDefaultLocationDetectionMethod,
      variables: {
        id: props.account.id,
        defaultLocationDetectionMethod: 'gps'
      }
    });
    setGpsChecked(true);
    setBluetoothChecked(false);
  };

  /** Update location detection to Bluetooth */
  const onBluetoothCheckedChange = (event: CheckboxChangeEvent) => {
    API.graphql({
      query: updateDefaultLocationDetectionMethod,
      variables: {
        id: props.account.id,
        defaultLocationDetectionMethod: 'bluetooth'
      }
    });
    setGpsChecked(false);
    setBluetoothChecked(true);
  };

  /** Setting the location detection value on the initial load */
  const getDefaultLocationDetection = async () => {
    const locationDetection = props.account.defaultLocationDetectionMethod;
    if (locationDetection?.toLocaleLowerCase() == 'gps') {
      setGpsChecked(true);
      setBluetoothChecked(false);
    } else if (locationDetection?.toLocaleLowerCase() == 'bluetooth') {
      setGpsChecked(false);
      setBluetoothChecked(true);
    } else {
      setGpsChecked(false);
      setBluetoothChecked(false);
    }
  };

  /** Fetches all subscriptions for this Member */
  const fetchSubscriptions = async (userId: number) => {
    const data = await (API.graphql({
      query: getMembershipsForUser,
      variables: {
        userId: userId
      }
    }) as Promise<{
      data: {
        getMembershipsForUser: AccountSubscription[];
      };
    }>);

    const results = data.data.getMembershipsForUser.map((acc, index) => ({
      ...acc,
      id: index,
      expiryDate: acc.expiryDate ? dayjs(acc.expiryDate).format('DD MMM YYYY') : 'N/A'
    }));
    setSubscriptionData(results);
  };

  useEffect(() => {
    fetchSubscriptions(props.account.id);
  }, []);

  /** Used to render the gym link in the table */
  const gymLink = (data: AccountSubscription) => {
    return (
      <button
        className={styles.gymLink}
        disabled={true}
        onClick={() =>
          history.push(
            `/gymChains/${data.gymChainId}/gyms/${data.gymId}/members?modal=${data.memberId}`
          )
        }>
        {`${data.memberName} - ${data.memberId}`}
      </button>
    );
  };

  const sendRequestingMoreInfoEmail = async (name?: string, email?: string, language?: string) => {
    try {
      const response = await (API.graphql({
        query: sendRequestMoreInformationEmail,
        variables: {
          input: {
            firstName: name,
            userEmail: email,
            language: language ? language.toLowerCase() : 'en'
          }
        }
      }) as Promise<{
        data: {
          sendRequestMoreInformationEmail: {
            error: boolean;
            errorMessage: string | null;
          };
        };
      }>);
    } catch (error) {
      // TODO: handle error scenario
      console.log('email sending failed', error);
    }
  };

  /** Handler for the request more info button */
  const requestMoreInfoHandler = async () => {
    setRequestingMoreInfo(true);
    const addEventRes = await addRequestMoreInfoEvent();
    const response = addEventRes
      ? await (API.graphql({
          query: addUserInformationRequest,
          variables: {
            userId: props.account.id,
            createdBy: userId
          }
        }) as Promise<{
          data: {
            addUserInformationRequest: number;
          };
        }>)
      : undefined;
    setRequestingMoreInfo(false);
    if (response && response.data.addUserInformationRequest === 1 && addEventRes) {
      await sendRequestingMoreInfoEmail(
        props.account.firstName,
        props.account.email,
        props.account.language
      );
      setInformationRequested(true);
      createAlert(alertRef, {
        content: t('ACCOUNT_DETAILS_MODAL.ALERT_REQUEST_INFO_SUCCESS'),
        severity: 'success'
      });
    } else {
      createAlert(alertRef, {
        content: t('ACCOUNT_DETAILS_MODAL.ALERT_REQUEST_INFO_FAILURE'),
        severity: 'error'
      });
    }
  };

  const addRequestMoreInfoEvent = async () => {
    try {
      const memberResponse = await (API.graphql({
        query: getMembersForUser,
        variables: {
          userId: props.account.id
        }
      }) as Promise<{ data: { getMembersForUser: MemberResponse[] } }>);

      if (
        !memberResponse.data.getMembersForUser ||
        memberResponse.data.getMembersForUser.length < 1
      ) {
        throw `member not found.`;
      }

      const eventResposne = await (API.graphql({
        query: getEventByDescription,
        variables: {
          description: Events.REQUEST_MORE_INFO
        }
      }) as Promise<{ data: GetEventByDescriptionQuery }>);

      if (!eventResposne || !eventResposne.data.getEventByDescription) {
        throw 'Event not found';
      }

      const input: RequestInfoMemberEventInput = {
        userId: props.account.id,
        createdBy: userId,
        lastModifiedBy: userId,
        memberId: memberResponse.data.getMembersForUser[0].id || -1,
        eventId: eventResposne.data.getEventByDescription.eventId,
        assignedBy: userId,
        assignedUserId: userId
      };
      await (API.graphql({
        query: addRequestInfoEvent,
        variables: { input: input }
      }) as Promise<{ data: { addRequestInfoEvent: MemberInquiry } }>);

      return true;
    } catch (e) {
      console.error(e);
    }
  };

  const subscriptionsTableProps: TableProps<AccountSubscription> = {
    exportFileName: `${t('ACCOUNT_DETAILS_MODAL.TABLE_EXPORT_EXCEL_FILE_NAME')}-${
      props.account.name
    }`,
    data: subscriptionData,
    totalRecords: subscriptionData.length,
    lazy: false,
    uniqueFieldName: 'mlmId',
    setPageSize: setAccountDetailsPageSize,
    setOffset: setAccountDetailsOffset,
    pageSize: accountDetailsPageSize,
    offset: accountDetailsOffset,
    setSortField: setAccountDetailsSortField,
    setSortOrder: setAccountDetailsSortOrder,
    sortField: accountDetailsSortField,
    sortOrder: accountDetailsSortOrder,
    columns: [
      {
        field: 'gymChainName',
        header: t('ACCOUNT_DETAILS_MODAL.TABLE_HEADER_GYM_CHAIN'),
        toggable: true
      },
      {
        field: 'gymName',
        header: t('ACCOUNT_DETAILS_MODAL.TABLE_HEADER_GYM'),
        toggable: true
      },
      {
        field: 'membershipName',
        header: t('ACCOUNT_DETAILS_MODAL.TABLE_HEADER_MEMBERSHIP'),
        toggable: false
      },
      {
        field: 'expiryDate',
        header: t('ACCOUNT_DETAILS_MODAL.TABLE_HEADER_EXPIRY_DATE'),
        toggable: true
      },
      { field: 'status', header: t('ACCOUNT_DETAILS_MODAL.TABLE_HEADER_STATUS'), toggable: true },
      { field: 'id', header: '', toggable: false, bodyTemplate: (data) => gymLink(data) }
    ],
    searchPlaceholderText: t('ACCOUNT_DETAILS_MODAL.TABLE_SEARCH_FIELD_TEXT'),
    excelBtntext: t('ACCOUNT_DETAILS_MODAL.TABLE_EXPORT_EXCEL_BUTTON_LABEL'),
    selectedRow: subscriptionTableSelectedRow,
    setSelectedRow: setSubscriptionTableSelectedRow
  };

  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [isActivityLoading, setIsActivityLoading] = useState(false);
  const [totalActivityCount, setTotalActivityCount] = useState(0);

  const [activities, setActivities] = useState<Activity[]>([]);

  const fetchActivities = () => {
    setIsActivityLoading(true);
    getDefaultLocationDetection();
    try {
      const response = (API.graphql({
        query: getActivityForMember,
        variables: {
          userId: props.account.id,
          pageSize: pageSize,
          offset: offset,
          dateFrom: `${dayjs(startingDate).format('YYYY-MM-DD')} 00:00:00`,
          dateTo: `${dayjs(endingDate).format('YYYY-MM-DD')} 23:59:59`,
          stageId: selected,
          eventType: eventType,
          platformAdmin: userRole === UserRole.SYSTEM_ADMIN
        }
      }) as Promise<{
        data: { getActivityForMember: GymActivityResponse };
      }>).then((i) => {
        setIsActivityLoading(false);
        const activities = i.data.getActivityForMember.items?.map(
          (e: GymActivity | null): Activity => {
            return {
              memberEventId: e?.memberEventId || 0,
              memberId: e?.memberId || 0,
              memberEmail: e?.memberEmail || '',
              eventId: e?.eventId || 0,
              accessCardUID: e?.accessCardUID || undefined,
              eventDescription: e?.eventDescription || '',
              eventType: e?.eventType || '',
              stageId: e?.stageId || 0,
              stage: e?.stage || '',
              createdTime: e?.createdTime
                ? dayjs
                    .utc(e?.createdTime || '')
                    .local()
                    .format('hh:mm A')
                    .toString()
                : '',
              isAutoAssigned: e?.isAutoAssigned || false,
              memberEventStatusTypeId: e?.memberEventStatusTypeId || 0,
              memberEventStatusType: e?.memberEventStatusType || '',
              firstName: e?.firstName || '',
              lastName: e?.lastName || '',
              siteLocationId: e?.siteLocationId || 0,
              locationName: e?.locationName || '',
              lastModifiedBy: e?.lastModifiedBy || 0,
              lastModifiedDate: e?.lastModifiedDate || '',
              activityDate: e?.createdTime
                ? dayjs
                    .utc(e?.createdTime || '')
                    .local()
                    .format('MM DD YYYY')
                    .toString()
                : '',
              assignedUserId: e?.assignedUserId || 0,
              memberLocationId: e?.memberLocationId || 0,
              informationReceivedEvent: e?.informationReceivedEvent || false,
              assignmentHistoryId: e?.assignmentHistoryId || 0,
              language: e?.language || 'en',
              inquiryDescription: e?.inquiryDescription || '',
              en: e?.en || '',
              nor: e?.nor || '',
              nl: e?.nl || ''
            };
          }
        );
        setActivities(activities || []);
        setTotalActivityCount(i.data.getActivityForMember.totalRecords?.totalRecords || 0);
      });
    } catch (error) {
      // TODO: handle error scenario
      console.log(error);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [
    props.account.id,
    offset,
    pageSize,
    allActivity,
    errorActivity,
    accessActivity,
    inquiry,
    startingDate,
    endingDate,
    selected,
    refresh
  ]);

  const allActivityChangeHandler = () => {
    setOffset(0);
    setEventType(eventTypes.all);
    setAllActivity(true);
    setErrorActivity(false);
    setAccessActivity(false);
    setInquiry(false);
  };
  const errorActivityChangeHandler = () => {
    setOffset(0);
    setEventType(eventTypes.errors);
    setAllActivity(false);
    setErrorActivity(true);
    setAccessActivity(false);
    setInquiry(false);
  };
  const accessActivityChangeHandler = () => {
    setOffset(0);
    setEventType(eventTypes.visits);
    setAllActivity(false);
    setErrorActivity(false);
    setAccessActivity(true);
    setInquiry(false);
  };
  const startDateChangeHandler = (e: DateTimePickerChangeEvent) => {
    setOffset(0);
    setStartingDate(e.target.value);
  };
  const endDateChangeHandler = (e: DateTimePickerChangeEvent) => {
    setOffset(0);
    setEndingDate(e.target.value);
  };

  const dropdownOptions = [
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.ALL'),
      value: 0
    },
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.LOGIN'),
      value: 1
    },
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.SIGNUP'),
      value: 2
    },
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.ACCESS_DOORS'),
      value: 3
    },
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.ACCOUNTS'),
      value: 4
    },
    {
      label: t('HOME_PAGE.STAGE_DROP_DOWN.SETTINGS'),
      value: 5
    }
  ];

  return (
    <>
      <div className="row pt-2">
        <Alert refProp={alertRef} />
        <div className="col-md-8 d-flex flex-column">
          <div className="d-flex flex-row mb25 align-items-center">
            <div className="col-md-3">
              <Text bold className={styles.accountModalLabel}>
                {t('ACCOUNT_DETAILS_MODAL.LABEL_EMAIL')}
              </Text>
            </div>
            <div className="col-md-3">
              <Text className="text-ellipsis">{props.account.email}</Text>
            </div>
            <div className="col">
              <div className={styles.requestButton}>
                <Button
                  label={
                    informationRequested
                      ? t('ACCOUNT_DETAILS_MODAL.BUTTON_REQUEST_INFO_PENDING')
                      : t('ACCOUNT_DETAILS_MODAL.BUTTON_REQUEST_INFO')
                  }
                  size={ButtonSizes.small}
                  onClick={requestMoreInfoHandler}
                  icon={requestingMoreInfo ? 'pi-spinner pi-spin' : undefined}
                  disabled={informationRequested}
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row mb25 align-items-center">
            <div className="col-md-3">
              <Text bold className={styles.accountModalLabel}>
                {t('ACCOUNT_DETAILS_MODAL.LABEL_USER_ID')}
              </Text>
            </div>
            <div className="col-md-3">
              <Text className="text-ellipsis">{props.account.id}</Text>
            </div>
          </div>

          <div className="d-flex flex-row mb35 align-items-center">
            <div className="col-md-3">
              <Text bold className={styles.accountModalLabel}>
                {t('ACCOUNT_DETAILS_MODAL.LABEL_PHONE')}
              </Text>
            </div>
            <div className="col">
              <Text>{props.account.phone}</Text>
            </div>
          </div>

          <div className="d-flex flex-row mb25 align-items-center">
            <div className="col-md-3">
              <Text bold className={styles.accountModalLabel}>
                {t('ACCOUNT_DETAILS_MODAL.LABEL_TYPE_OF_ACCESS')}
              </Text>
            </div>

            <div className="col d-flex flex-row">
              {!props.account.accessTypes.isMobileAppDisabled && (
                <div className="d-flex align-items-center me-4">
                  <span className="modal-icon icon-mobile"></span>
                  <Text className={styles.accountModalBlackText}>
                    {t('ACCOUNT_DETAILS_MODAL.TYPE_OF_ACCESS_PHONE_APP')}
                  </Text>
                </div>
              )}

              {/* {!props.account.accessTypes.isAccessCardDeactivated && (
                <div className="d-flex align-items-center me-4">
                  <span className="modal-icon icon-signal me-2"></span>
                  <Text className={styles.accountModalBlackText}>
                    {t('ACCOUNT_DETAILS_MODAL.TYPE_OF_ACCESS_ACCESS_CARD')}
                  </Text>
                </div>
              )} */}

              {/* Removing this since it's no longer needed (PROD-15) 
              {!props.account.accessTypes.isJustTapRevoked && (
                <div className="d-flex align-items-center">
                  <span className="modal-icon icon-credentials me-2"></span>
                  <Text className={styles.accountModalBlackText}>
                    {t('ACCOUNT_DETAILS_MODAL.TYPE_OF_ACCESS_JUST_TAP')}
                  </Text>
                </div>
              )} */}
            </div>
          </div>

          <div className="d-flex flex-row mb25 align-items-center">
            <div className="col-md-3">
              <Text bold className={styles.accountModalLabel}>
                {t('ACCOUNT_DETAILS_MODAL.LABEL_DEFAULT_LOCATION_DETECTION')}
              </Text>
            </div>
            <div className="col d-flex flex-row">
              <div className="d-flex align-items-center me-4">
                <Checkbox
                  data-cy={'accounts-accountdetails-modal-checkbox-bluetooth'}
                  label={t('ACCOUNT_DETAILS_MODAL.TYPE_OF_LOCATION_DETECTION_BLUETOOTH')}
                  smallText
                  checked={bluetoothChecked}
                  onChange={onBluetoothCheckedChange}
                />
              </div>
              <div className="d-flex align-items-center me-4">
                <Checkbox
                  data-cy={'accounts-accountdetails-modal-checkbox-gps'}
                  label={t('ACCOUNT_DETAILS_MODAL.TYPE_OF_LOCATION_DETECTION_GPS')}
                  smallText
                  checked={gpsChecked}
                  onChange={onGpsCheckedChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 text-end px-5 py-2">
          {!props.account.imageUrl ? (
            <Avatar
              size="custom"
              shape="circle"
              label={acronym(props.account.name || '')}
              width={150}
              height={150}
              fontSize="3em"
              color="#e0f4fe"
            />
          ) : (
            <div className={styles.imageCircle}>
              <Image source={props.account.imageUrl} width={150} height={150} circle />
            </div>
          )}
        </div>
      </div>
      <div className="row px-3">
        <div className="col-md-12">
          <Table {...subscriptionsTableProps} />
        </div>
      </div>
      <div className="mt25">
        <div className="mb20">
          <Text bold className={styles.accountModalBlackText1} size={TextSizes.large}>
            {t('ACCOUNT_DETAILS_MODAL.ACTIVITY_HEADING')}
          </Text>
        </div>
        {isActivityLoading ? (
          <Loader shape="activity" />
        ) : (
          <>
            <div>
              <ActivityFeedFilters
                allActivity={allActivity}
                errorActivity={errorActivity}
                accessActivity={accessActivity}
                dateFrom={startingDate}
                dateTo={endingDate}
                allActivityOnChange={allActivityChangeHandler}
                errorActivityOnChange={errorActivityChangeHandler}
                accessActivityOnChange={accessActivityChangeHandler}
                dateFromOnChange={startDateChangeHandler}
                dateToOnChange={endDateChangeHandler}
                dropdownOptions={dropdownOptions}
                selectedValue={selected}
                dropdownOnChange={(e) => setSelected(e.value)}
              />
            </div>
            <div className="activty-feed">
              <ActivityFeed
                activities={activities}
                stage={'account'}
                account={props.account}
                refreshCallBack={() => setRefresh(!refresh)}></ActivityFeed>
            </div>
            <div className="text-center">
              {activities?.length === 0 ? (
                <EmptyState textItems={[t('HOME_PAGE.EMPTY_STATE_TEXT')]} />
              ) : (
                <div className="d-flex button-view-all justify-content-center">
                  <div className="mr15">
                    <Button
                      variant={ButtonVariants.textonlyslim}
                      label={t('HOME_PAGE.VIEW_PREVIOUS_ACTIVITY')}
                      icon="pi-angle-left"
                      iconPos="left"
                      disabled={offset === 0}
                      onClick={() => setOffset(offset - pageSize)}></Button>
                  </div>
                  <div>
                    <Button
                      variant={ButtonVariants.textonlyslim}
                      label={t('HOME_PAGE.VIEW_NEXT_ACTIVITY')}
                      icon="pi-angle-right"
                      iconPos="right"
                      disabled={offset + pageSize >= totalActivityCount}
                      onClick={() => setOffset(offset + pageSize)}></Button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const AccountDetailsModal = (props: AccountDetailsModalProps) => {
  return (
    <Dialog
      draggable={false}
      modal
      dismissableMask
      visible={props.visible}
      onHide={props.modalOnHide}
      position="top"
      style={{ width: '75vw' }}
      header={props.account.name}>
      <AccountDetailsModalContent account={props.account} />
    </Dialog>
  );
};

export interface AccountDetailsModalProps {
  /** Controls the visibility of the modal */
  visible: boolean;
  /** Runs when modal is closed */
  modalOnHide: () => void;
  /** Account data to populate the modal */
  account: Account;
}

export default AccountDetailsModal;
