import { useAppDispatch, useAppSelector } from '@gym-redux/store';
import { useTranslation } from 'react-i18next';
import Table, { TableProps } from '@gym-molecules/Table/Table';
import { useEffect, useRef, useState } from 'react';
import Loader from '@gym-atoms/Loader/Loader';
import { UserRole, UserTableType, UserType } from '@gym-particles/types/User';
import { fetchUsers } from '@gym-redux/slices/userManagementSlice';
import TwoColTemplate from '@gym-templates/TwoColTemplate/TwoColTemplate';
import { AlertType } from '@gym-atoms/Alert/Alert';
import { useHistory } from 'react-router';
import Button, { ButtonSizes, ButtonVariants } from '@gym-atoms/Button/Button';
import { API } from 'aws-amplify';
import {
  deactivateAccessCardForUser,
  deleteWebUser,
  removeUserAccessCardOfPerson
} from '@gym-graphql/mutations';
import {
  ActiveAccessCardForUserResponse,
  DeactivateAccessCardForUserMutation,
  DeactivateAccessCardForUserMutationVariables,
  GetActiveAccessCardForUserQueryVariables,
  RemoveUserAccessCardOfPersonMutationVariables,
  UserManagementResponse
} from '@gym-src/API';
import { Card } from 'primereact/card';
import DialogBox, { DialogBoxVariants } from '@gym-atoms/Dialog/DialogBox';
import Text from '@gym-atoms/Text/Text';
import dayjs from 'dayjs';
import { getActiveAccessCardForUser } from '@gym-graphql/queries';

const MainColumn = () => {
  const dispatch = useAppDispatch();
  const [selectedRow, setSelectedRow] = useState<UserTableType>();
  const { t } = useTranslation();
  const [userManagementSortField, setUserManagementSortField] = useState('createdDate');
  const [userManagementSortOrder, setUserManagementSortOrder] = useState(-1);
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const data = useAppSelector((state) => state.userManagement.items);
  const userRole = useAppSelector((state) => state.user.userRole);
  const history = useHistory();
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const isUsersLoading = useAppSelector((state) => state.userManagement.isUserLoading);
  const currentUserID = useAppSelector((state) => state.user.userId);
  const [isInserted, setIsInserted] = useState(false);
  const [failedDialogVisible, setFailedDialogVisible] = useState(false);
  const [userList, setUserList] = useState<UserTableType[]>([]);

  const fetchUsersFromDb = () => {
    dispatch(fetchUsers(currentUserID));
  };

  useEffect(() => {
    fetchUsersFromDb();
  }, []);

  useEffect(() => {
    mapUsers(data);
  }, [data]);

  const mapUsers = (userList: UserType[]) => {
    const mappedList: UserTableType[] = [];

    userList.forEach((user) => {
      const index = mappedList.findIndex((mappedUser) => {
        return mappedUser.userId === user.userId;
      });

      if (index > -1) {
        mappedList[index].locationInfo.push({
          siteId: user.siteId,
          siteName: user.siteName,
          siteLocationId: user.siteLocationId,
          siteLocationName: user.siteLocationName
        });
      } else {
        const role =
          user.roleName == UserRole.SYSTEM_ADMIN
            ? t('USER_ROLE.SYSTEM_ADMIN')
            : user.roleName == UserRole.GYM_CHAIN_USER
            ? t('USER_ROLE.GYM_CHAIN_USER')
            : user.roleName == UserRole.GYM_USER
            ? t('USER_ROLE.GYM_USER')
            : '';
        mappedList.push({
          userId: user.userId,
          name: user.name,
          firstName: user.firstName,
          lastName: user.lastName,
          memberId: user.memberId,
          phone: user.phone,
          email: user.email,
          roleId: user.roleId,
          isActive: user.isActive,
          createdDate: user.createdDate,
          createdBy: user.createdBy,
          lastModifiedDate: user.lastModifiedDate,
          lastModifiedBy: user.lastModifiedBy,
          profilePicturePath: user.profilePicturePath,
          roleName: role.charAt(0).toUpperCase() + role.slice(1),
          locationInfo: [
            {
              siteId: user.siteId,
              siteName: user.siteName,
              siteLocationId: user.siteLocationId,
              siteLocationName: user.siteLocationName
            }
          ]
        });
      }
    });

    setUserList(mappedList);
  };

  const menuItem = [
    {
      label: t('USER_MANAGEMENT.TABLE_CONTEXT_MENU_EDIT'),
      command: () => {
        history.push(`/users/${selectedRow?.userId}`);
      }
    },
    {
      label: t('USER_MANAGEMENT.TABLE_CONTEXT_MENU_DELETE'),
      command: () => setDeleteDialogVisible(true)
    }
  ];

  const tableProps: TableProps<UserTableType> = {
    exportFileName: `UserManagement`,
    data: userList || undefined,
    searchPlaceholderText: t('USER_MANAGEMENT.SEARCH_PLACEHOLDER'),
    excelBtntext: t('USER_MANAGEMENT.EXPORT_EXCEL_BUTTON_LABEL'),
    totalRecords: userList.length,
    selectedRow: selectedRow,
    setSelectedRow: setSelectedRow,
    setSortField: setUserManagementSortField,
    setSortOrder: setUserManagementSortOrder,
    sortField: userManagementSortField,
    sortOrder: userManagementSortOrder,
    setPageSize: setPageSize,
    setOffset: setOffset,
    pageSize: pageSize,
    offset: offset,
    columns: [
      {
        field: 'name',
        header: `${t('USER_MANAGEMENT.TABLE_HEADER_NAME')}`,
        sortable: true,
        imageField: 'profilePicturePath',
        toggable: false,
        truncateText: true
      },
      {
        field: 'email',
        header: `${t('USER_MANAGEMENT.TABLE_HEADER_EMAIL')}`,
        sortable: true,
        toggable: true,
        truncateText: true
      },
      {
        field: 'roleName',
        header: `${t('USER_MANAGEMENT.TABLE_HEADER_ROLE')}`,
        sortable: true,
        toggable: true,
        truncateText: true
      },
      {
        field: 'phone',
        header: `${t('USER_MANAGEMENT.TABLE_HEADER_PHONE_NUMBER')}`,
        toggable: true,
        truncateText: true
      }
    ],
    lazy: false,
    uniqueFieldName: 'userId',
    expandedCols: [
      {
        field: 'siteName',
        header: `${t('USER_MANAGEMENT.TABLE_HEADER_GYMCHAIN')}`,
        toggable: true,
        truncateText: true
      },
      {
        field: 'siteLocationName',
        header: `${t('USER_MANAGEMENT.TABLE_HEADER_GYM')}`,
        toggable: true,
        truncateText: true
      }
    ],
    expandable: true,
    expandableField: 'locationInfo',
    isContextMenu:
      userRole === UserRole.SYSTEM_ADMIN || userRole === UserRole.GYM_CHAIN_USER ? true : false,
    menuItem:
      userRole === UserRole.SYSTEM_ADMIN || userRole === UserRole.GYM_CHAIN_USER ? menuItem : [],
    headerBtns:
      userRole === UserRole.SYSTEM_ADMIN || userRole === UserRole.GYM_CHAIN_USER
        ? [
            <div key={1}>
              <Button
                data-cy="btn-add-new-user"
                icon="pi-plus"
                label={t('USER_MANAGEMENT.ADD_NEW_USER_BUTTON_LABEL')}
                onClick={() => {
                  history.push('/users/new');
                }}
                size={ButtonSizes.small}
              />
            </div>
          ]
        : []
  };

  const DeleteDialogFooter = () => {
    return (
      <div>
        <Button
          label={t('COMMON.CANCEL')}
          variant={ButtonVariants.textonly}
          onClick={() => setDeleteDialogVisible(false)}
        />
        {selectedRow?.userId === currentUserID ? undefined : (
          <Button
            icon={isInserted ? 'pi-spinner pi-spin' : ''}
            disabled={isInserted}
            label={t('COMMON.DELETE')}
            variant={ButtonVariants.danger}
            onClick={deleteDialogHandler}
          />
        )}
      </div>
    );
  };

  const deleteDialogHandler = async () => {
    try {
      setIsInserted(true);
      selectedRow && (await deleteUser());
      fetchUsersFromDb();
      setDeleteDialogVisible(false);
    } catch (error) {
      setDeleteDialogVisible(false);
      setFailedDialogVisible(true);
      console.log(error);
    } finally {
      setIsInserted(false);
    }
  };

  const deleteUser = async () => {
    try {
      await deactivateUserAccessCard();
      const response = (await API.graphql({
        query: deleteWebUser,
        variables: {
          lastModifiedUserId: currentUserID,
          email: selectedRow?.email
        }
      })) as { data: { deleteWebUser: UserManagementResponse } };

      if (!response.data.deleteWebUser.success) {
        return Promise.reject(response.data.deleteWebUser.errorMsg);
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deactivateUserAccessCard = async () => {
    let activeCardForUser;
    const data: GetActiveAccessCardForUserQueryVariables = {
      userId: selectedRow?.userId
    };

    try {
      const resultActiveAccessCardForUser = await (API.graphql({
        query: getActiveAccessCardForUser,
        variables: data
      }) as Promise<{
        data: { getActiveAccessCardForUser: ActiveAccessCardForUserResponse[] };
      }>);
      activeCardForUser = resultActiveAccessCardForUser.data.getActiveAccessCardForUser[0];
    } catch (e) {
      console.log(e);
    }

    if (activeCardForUser) {
      const data: RemoveUserAccessCardOfPersonMutationVariables = {
        input: {
          arxPersonId: activeCardForUser?.memberArxReferenceId || '',
          cardFormat: activeCardForUser?.accessCardFormatName || '',
          cardNumber: activeCardForUser?.accessCardUID || '',
          isDeleted: true
        }
      };

      try {
        const result = await (API.graphql({
          query: removeUserAccessCardOfPerson,
          variables: data
        }) as Promise<{
          data: {
            error?: boolean | null;
            errorType?: string | null;
            errorMsg?: string | null;
            statusCode?: string | null;
            statusMessage?: string | null;
          };
        }>);

        if (!result.data.error) {
          await deactivateLinkedCardsFromDatabase();
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const deactivateLinkedCardsFromDatabase = async () => {
    const data: DeactivateAccessCardForUserMutationVariables = {
      userId: selectedRow?.userId,
      lastModifiedBy: currentUserID,
      deactivatedDateTime: dayjs(new Date()).format('YYYY-MM-DD HH:MM:ss')
    };

    try {
      await (API.graphql({
        query: deactivateAccessCardForUser,
        variables: data
      }) as Promise<{
        data: DeactivateAccessCardForUserMutation;
      }>);
    } catch (e) {
      console.log(e);
    }
  };

  const ActionFailedDialog = () => {
    return (
      <div>
        <DialogBox
          variant={DialogBoxVariants.basic}
          dialogVisible={failedDialogVisible}
          onHideCallback={() => setFailedDialogVisible(false)}
          dialogHeader={t('USER_MANAGEMENT.FORM_FAILURE_DIALOG_HEADER')}
          dialogFooter={
            <Button
              label={t('USER_MANAGEMENT.FORM_FAILURE_DIALOG_BUTTON')}
              onClick={() => setFailedDialogVisible(false)}
            />
          }
          dialogDismissableMask={true}
          dialogClosable={false}
          content={<Text>{t('USER_MANAGEMENT.FORM_FAILURE_DIALOG_BODY')}</Text>}
        />
      </div>
    );
  };

  return (
    <Card className="p-card-w-table">
      <div>
        <DialogBox
          dialogVisible={deleteDialogVisible}
          variant={DialogBoxVariants.long}
          dialogDismissableMask={true}
          onHideCallback={() => setDeleteDialogVisible(false)}
          dialogFooter={<DeleteDialogFooter />}
          dialogClosable={false}
          dialogHeader={t('COMMON.DELETE')}
          content={
            selectedRow?.userId === currentUserID ? (
              <Text>
                {t('USER_MANAGEMENT.LOGGED_IN_USER_DELETE_MESSAGE_PREFIX')}{' '}
                <span className="fw-bold">{selectedRow?.name}</span>{' '}
                {t('USER_MANAGEMENT.LOGGED_IN_USER_DELETE_MESSAGE_SUFFIX')}
              </Text>
            ) : (
              <Text>
                {t('USER_MANAGEMENT.DELETE_MODAL_CONFIRM')}{' '}
                <span className="fw-bold">{selectedRow?.name}</span> ?
              </Text>
            )
          }
        />
        {isUsersLoading ? <Loader shape="table" /> : <Table {...tableProps} />}
        <ActionFailedDialog />
      </div>
    </Card>
  );
};

const UserManagementPage = () => {
  const { t } = useTranslation();
  const alertRef = useRef<AlertType>(null);
  const history = useHistory();
  return (
    <TwoColTemplate
      title={t('USER_MANAGEMENT.PAGE_TITLE')}
      col1={<MainColumn />}
      alertRef={alertRef}
      backButtonHandler={() => history.push('/dashboard')}
    />
  );
};

export default UserManagementPage;
