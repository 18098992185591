/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onClassPortalUpdate = /* GraphQL */ `
  subscription OnClassPortalUpdate($siteLocationId: Int) {
    onClassPortalUpdate(siteLocationId: $siteLocationId) {
      id
      importedId
      name
      siteLocationId
      resourceId
      staffId
      startDateTime
      endDateTime
      maxCapacity
      totalBooked
      resourceMindbodyId
      resourceName
      resourceImageUrl
      staffMindbodyId
      staffName
      staffImageUrl
      imageUrl
    }
  }
`;
export const onEmployeeDashboardUpdate = /* GraphQL */ `
  subscription OnEmployeeDashboardUpdate($siteLocationId: Int) {
    onEmployeeDashboardUpdate(siteLocationId: $siteLocationId) {
      id
      siteId
      siteLocationId
      kisiDoorId
      kisiDoorName
      doorOpenMemberEmail
      doorOpenedTime
      memberId
      createdBy
      createdDate
      lastModifiedBy
      lastModifiedDate
      businessName
      memberName
      memberUniqueId
      memberClientId
      memberAccountBalance
      memberNote
    }
  }
`;
export const onItemAdded = /* GraphQL */ `
  subscription OnItemAdded {
    onItemAdded {
      id
      type
      price
    }
  }
`;
export const onItemDeleted = /* GraphQL */ `
  subscription OnItemDeleted {
    onItemDeleted {
      id
      type
      price
    }
  }
`;
export const onPushGymVisitEntry = /* GraphQL */ `
  subscription OnPushGymVisitEntry($siteLocationId: Int!) {
    onPushGymVisitEntry(siteLocationId: $siteLocationId) {
      memberEventId
      memberId
      memberEmail
      eventId
      eventDescription
      eventType
      stageId
      stage
      createdTime
      isAutoAssigned
      memberEventStatusTypeId
      memberEventStatusType
      firstName
      lastName
      siteLocationId
      assignedUserId
      assignmentHistoryId
      locationName
      lastModifiedBy
      lastModifiedDate
      inquiryDescription
      memberLocationId
      informationReceivedEvent
    }
  }
`;
