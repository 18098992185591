import { ToggleButton } from 'primereact/togglebutton';

import styles from './Pill.module.scss';

export enum PillVariants {
  primary = 'pill-primary',
  error = 'pill-error',
  success = 'pill-success',
  info = 'pill-info'
}

export enum PillSizes {
  small = 'p-pill-sm',
  medium = 'p-pill'
}

const Pill = ({ label, checked, bold, variant, size = PillSizes.medium, ...props }: PillProps) => {
  const boldStyle = bold ? styles.pillBold : null;

  const finalProps = {
    onLabel: label,
    offLabel: label,
    checked: checked,
    className: `p-button-rounded ${boldStyle} ${variant} ${size}`,
    ...props
  };

  return <ToggleButton {...finalProps} />;
};

export interface PillProps {
  /** Label to be displayed */
  label: string;
  /** Clicked or not */
  checked: boolean;
  /** Bold text */
  bold?: boolean;
  /** Callback to invoke on value change */
  onChange?(e: {
    originalEvent: Event;
    value: boolean;
    target: { type: string; name: string; id: string; value: boolean };
  }): void;
  /**
   * Variant (based on color). This can be also set by using the exported
   * PillVariants object. Should be `PillVariants.primary` or `'pill-primary'`, etc.
   */
  variant: PillVariants;
  /** size of the pill */
  size?: PillSizes;
}

export default Pill;
