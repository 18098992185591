import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import utc from 'dayjs/plugin/utc';
import { Activity } from '@gym-particles/types/models';
import {
  getActivityForUser,
  getActivityMyTasksCountForUser,
  getActivityMyTasksForUser
} from '@gym-graphql/queries';
import { GymActivity } from '@gym-src/API';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const initialState: {
  items: Array<{
    userId: number;
    activityItems: { items: Activity[]; totalRecords: number };
    myTaskItems: { items: Activity[]; totalRecords: number };
  }>;
  isActivityLoading: boolean;
  myTaskCount?: number;
} = {
  items: [],
  isActivityLoading: false
};

type paramType = {
  userId: number;
  offset: number;
  pageSize: number;
  dateFrom: string;
  dateTo: string;
  stageId: number;
  eventType: string;
  platformAdmin: boolean;
};

type paramTypeForMyTasks = {
  userId: number;
  roleId: number;
  offset: number;
  pageSize: number;
  closedInquiries: boolean;
  platformAdmin: boolean;
};

type countParamType = {
  userId: number;
  roleId: number;
  platformAdmin: boolean;
};

export const fetchActivityForUser = createAsyncThunk(
  '/home/fetchActivityForUser',
  async (params: paramType) => {
    const response = await (API.graphql({
      query: getActivityForUser,
      variables: {
        userId: params.userId,
        offset: params.offset,
        pageSize: params.pageSize,
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        stageId: params.stageId,
        eventType: params.eventType,
        platformAdmin: params.platformAdmin
      }
    }) as Promise<{
      data: {
        getActivityForUser: {
          items: GymActivity[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const fetchActivityMyTasksForUser = createAsyncThunk(
  '/home/fetchActivityMyTasksForUser',
  async (params: paramTypeForMyTasks) => {
    const response = await (API.graphql({
      query: getActivityMyTasksForUser,
      variables: {
        userId: params.userId,
        roleId: params.roleId,
        offset: params.offset,
        pageSize: params.pageSize,
        closedInquiries: params.closedInquiries,
        platformAdmin: params.platformAdmin
      }
    }) as Promise<{
      data: {
        getActivityMyTasksForUser: {
          items: GymActivity[];
          totalRecords: { totalRecords: number };
        };
      };
    }>);
    return response;
  }
);

export const fetchMyTaskCountForUser = createAsyncThunk(
  'gym/fetchMyTaskCountForUser',
  async (params: countParamType) => {
    const response = await (API.graphql({
      query: getActivityMyTasksCountForUser,
      variables: {
        userId: params.userId,
        roleId: params.roleId,
        platformAdmin: params.platformAdmin
      }
    }) as Promise<{
      data: { getActivityMyTasksCountForUser: { count: number } };
    }>);
    return response;
  }
);

export const homeActivitySlice = createSlice({
  name: 'homeActivity',
  initialState,
  reducers: {
    incrementMyTaskCount: (state) => {
      if (state.myTaskCount) state.myTaskCount += 1;
      return state;
    },
    decrementMyTaskCount: (state) => {
      if (state.myTaskCount) state.myTaskCount -= 1;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActivityForUser.fulfilled, (state, action) => {
      state.isActivityLoading = false;
      if (action.payload.data.getActivityForUser.items === null) {
        return;
      }
      const items = action.payload.data.getActivityForUser.items.map(
        (e: GymActivity): Activity => {
          return {
            memberEventId: e.memberEventId || 0,
            memberId: e.memberId || 0,
            memberEmail: e.memberEmail || '',
            eventId: e.eventId || 0,
            accessCardUID: e.accessCardUID || undefined,
            eventDescription: e.eventDescription || '',
            eventType: e.eventType || '',
            stageId: e.stageId || 0,
            stage: e.stage || '',
            createdTime: e.createdTime
              ? dayjs
                  .utc(e.createdTime || '')
                  .local()
                  .format('hh:mm A')
                  .toString()
              : '',
            isAutoAssigned: e.isAutoAssigned || false,
            memberEventStatusTypeId: e.memberEventStatusTypeId || 0,
            memberEventStatusType: e.memberEventStatusType || '',
            firstName: e.firstName || '',
            lastName: e.lastName || '',
            siteLocationId: e.siteLocationId || 0,
            locationName: e.locationName || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            activityDate: e?.createdTime
              ? dayjs
                  .utc(e?.createdTime || '')
                  .local()
                  .format('MM DD YYYY')
                  .toString()
              : '',
            assignedUserId: e.assignedUserId || 0,
            assignmentHistoryId: e.assignmentHistoryId || undefined,
            inquiryDescription: e.inquiryDescription || '',
            memberLocationId: e.memberLocationId || 0,
            informationReceivedEvent: e.informationReceivedEvent || false,
            language: e.language || 'en',
            userId: e.userId || 0,
            en: e.en || '',
            nor: e.nor || '',
            nl: e.nl || ''
          };
        }
      );

      const totalRecords = action.payload.data.getActivityForUser.totalRecords.totalRecords;

      const fetchedActivityInfo = {
        activityItems: { items: items, totalRecords: totalRecords },
        myTaskItems: {
          items:
            state.items.find((i) => i.userId === action.meta.arg.userId)?.myTaskItems.items || [],
          totalRecords:
            state.items.find((i) => i.userId === action.meta.arg.userId)?.myTaskItems
              .totalRecords || 0
        },
        userId: action.meta.arg.userId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedActivityInfo);
      } else {
        const update = state.items.find(
          (activityInfo) => activityInfo.userId === fetchedActivityInfo.userId
        );
        if (update) {
          const newState = state.items.map((activityInfo) => {
            if (activityInfo.userId === fetchedActivityInfo.userId) {
              return fetchedActivityInfo;
            } else {
              return activityInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedActivityInfo);
        }
      }
    });

    builder.addCase(fetchActivityForUser.pending, (state, action) => {
      state.isActivityLoading = true;
      return state;
    });

    builder.addCase(fetchActivityForUser.rejected, (state, action) => {
      state.isActivityLoading = false;
      console.log('Get home activity Error:', action);
      return state;
    });

    builder.addCase(fetchActivityMyTasksForUser.fulfilled, (state, action) => {
      state.isActivityLoading = false;
      if (action.payload.data.getActivityMyTasksForUser.items === null) {
        return;
      }
      const items = action.payload.data.getActivityMyTasksForUser.items.map(
        (e: GymActivity): Activity => {
          return {
            memberEventId: e.memberEventId || 0,
            memberId: e.memberId || 0,
            memberEmail: e.memberEmail || '',
            eventId: e.eventId || 0,
            accessCardUID: e.accessCardUID || undefined,
            eventDescription: e.eventDescription || '',
            eventType: e.eventType || '',
            stageId: e.stageId || 0,
            stage: e.stage || '',
            createdTime: e.createdTime
              ? dayjs
                  .utc(e.createdTime || '')
                  .local()
                  .format('hh:mm A')
                  .toString()
              : '',
            isAutoAssigned: e.isAutoAssigned || false,
            memberEventStatusTypeId: e.memberEventStatusTypeId || 0,
            memberEventStatusType: e.memberEventStatusType || '',
            firstName: e.firstName || '',
            lastName: e.lastName || '',
            siteLocationId: e.siteLocationId || 0,
            locationName: e.locationName || '',
            lastModifiedBy: e.lastModifiedBy || 0,
            lastModifiedDate: e.lastModifiedDate || '',
            activityDate: e?.createdTime
              ? dayjs
                  .utc(e?.createdTime || '')
                  .local()
                  .format('MM DD YYYY')
                  .toString()
              : '',
            assignedUserId: e.assignedUserId || 0,
            assignmentHistoryId: e.assignmentHistoryId || undefined,
            inquiryDescription: e.inquiryDescription || '',
            memberLocationId: e.memberLocationId || 0,
            informationReceivedEvent: e.informationReceivedEvent || false,
            language: e.language || 'en',
            en: e.en || '',
            nor: e.nor || '',
            nl: e.nl || ''
          };
        }
      );

      const totalRecords = action.payload.data.getActivityMyTasksForUser.totalRecords.totalRecords;

      const fetchedActivityInfo = {
        activityItems: {
          items:
            state.items.find((i) => i.userId === action.meta.arg.userId)?.activityItems.items || [],
          totalRecords:
            state.items.find((i) => i.userId === action.meta.arg.userId)?.activityItems
              .totalRecords || 0
        },
        myTaskItems: {
          items: items,
          totalRecords: totalRecords
        },
        userId: action.meta.arg.userId
      };

      if (state.items.length === 0) {
        state.items.push(fetchedActivityInfo);
      } else {
        const update = state.items.find(
          (activityInfo) => activityInfo.userId === fetchedActivityInfo.userId
        );
        if (update) {
          const newState = state.items.map((activityInfo) => {
            if (activityInfo.userId === fetchedActivityInfo.userId) {
              return fetchedActivityInfo;
            } else {
              return activityInfo;
            }
          });
          state.items = newState;
        } else {
          state.items.push(fetchedActivityInfo);
        }
      }
    });

    builder.addCase(fetchActivityMyTasksForUser.pending, (state, action) => {
      state.isActivityLoading = true;
      return state;
    });

    builder.addCase(fetchActivityMyTasksForUser.rejected, (state, action) => {
      state.isActivityLoading = false;
      console.log('Get home activity my tasks Error:', action);
      return state;
    });

    builder.addCase(fetchMyTaskCountForUser.fulfilled, (state, action) => {
      const count = action.payload.data.getActivityMyTasksCountForUser.count;
      state.myTaskCount = count;
      return state;
    });

    builder.addCase(fetchMyTaskCountForUser.rejected, (state, action) => {
      console.error('Get my task count error:', action);
      return state;
    });
  }
});

export const { incrementMyTaskCount, decrementMyTaskCount } = homeActivitySlice.actions;

export default homeActivitySlice.reducer;
